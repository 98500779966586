import React, { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'
import { UserContext } from 'stores/UserStore'
import LoginForm from 'containers/login/LoginForm'
import LanguageSelect from 'containers/common/LanguageSelect'
import { FlexView, Link, Card } from 'components/common'
import BackgroundImage from 'assets/images/login-background.jpg'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import ChangePasswordForm from 'containers/login/ChangePasswordForm'

const Background = styled.div`
  background: url(${BackgroundImage}) no-repeat center center fixed;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.background };
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.gray};
  font-weight: bold;
`

const Login = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { authState } = useContext(UserContext)

  const redirectIfAuthenticated = () => {
    let redirect = null
    if (authState === 'SIGNED_IN') {
      const redirectPath = (location.state && location.state.from) ? location.state.from : '/'
      redirect = <Redirect to={redirectPath} />
    }
    return redirect
  }

  const renderForm = () => {
    switch (authState) {
      case 'FORGOT_PASSWORD':
        return <ChangePasswordForm />
      default:
        return <LoginForm />
    }
  }

  return (
    <Background>
      {redirectIfAuthenticated()}
      <Card position="relative" width="460px" flexDirection="column" justifyContent="center" alignItems="center" elevation="medium" padding="16px 24px">
        <SwitchTransition>
          <CSSTransition
            key={authState}
            timeout={300}
            classNames="fade"
          >
            {renderForm()}
          </CSSTransition>
        </SwitchTransition>
      </Card>
      <FlexView flexDirection="row" width="524px" justifyContent="space-between" alignItems="center" margin="10px 0px">
        <LanguageSelect />
        <FlexView flexDirection="row" alignItems="center">
          <Link color="gray" margin="0px 10px">{t('Help')}</Link>
          <Link color="gray" margin="0px 10px">{t('Privacy')}</Link>
          <Link color="gray" margin="0px 10px">{t('Terms')}</Link>
        </FlexView>
      </FlexView>
    </Background>
  )
}

export default Login