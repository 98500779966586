/** @format */
import React, { useRef } from 'react'
import Card from 'components/common/Card'
import { useDrag, useDrop } from 'react-dnd'
import FlexView from 'components/common/FlexView'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Toggle } from 'components/form'

const DragCard = styled(Card)`
	border-right: 5px solid ${({ theme }) => theme.colors.light};
	&.error {
		border-color: ${({ theme }) => theme.colors.error};
	}

	&.success {
		border-color: ${({ theme }) => theme.colors.success};
	}

	&.warning {
		border-color: ${({ theme }) => theme.colors.warning};
	}
`

const DraggableAttribute = ({ index, id, desc, data, moveHandler, toggleHandler, disabled }) => {
	const { t } = useTranslation()
	const ref = useRef(null)

	const [, drop] = useDrop({
		accept: 'attribute',
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current ? ref.current.getBoundingClientRect() : {}
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			moveHandler(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		}
	})

	const [{ isDragging }, drag] = useDrag({
		item: { index, type: 'attribute' },
		canDrag: () => !disabled,
		/* eslint-disable no-unused-vars */
		end: (item, monitor) => {
			/* eslint-disable no-unused-vars */
			const dropResult = monitor.getDropResult()
		},
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	})

	const opacity = isDragging ? 0.4 : 1
	drag(drop(ref))

	const attrType = attr =>
		attr.isMandatory && !attr.isExtension ? t('wms:Mandatory') : attr.isExtension ? 'Cross Over' : t('wms:Optional')

	const attrTypeColor = attr =>
		attr.isMandatory && !attr.isExtension ? 'error' : attr.isExtension ? 'success' : 'warning'

	return (
		<DragCard
			ref={ref}
			flexDirection='row'
			alignItems='center'
			justifyContent='space-between'
			margin='8px 0px'
			padding='8px'
			width='calc(100% - 16px)'
			data-cy='list-item'
			fontSize='small'
			borderRadius='8px 2px 2px 8px'
			className={attrTypeColor(data)}
			backgroundColor={disabled ? 'rgb(238, 238, 238)' : data.order !== index ? 'rgb(250, 250, 250)' : 'white'}
			style={{ opacity }}>
			<FlexView fontSize='small' width='60%'>
				<FlexView
					margin='4px 0px'
					padding='0'
					flexDirection='column'
					fontSize='tiny'
					alignItems='center'
					color={attrTypeColor(data)}>
					{attrType(data)}
				</FlexView>
				{!disabled ? index : null} - {desc}
			</FlexView>

			<Toggle
				fontSize='tiny'
				state={!disabled}
				textOff='Off'
				textOn='On'
				onChange={value => toggleHandler(data.id)}
				data-cy='toggle-enabled-disabled-defects'
				alternate
			/>
		</DragCard>
	)
}

export default DraggableAttribute
