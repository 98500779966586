/** @format */

import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import { toast } from 'react-toastify'
import reports from '../api/reports'
import formatReportData from '../utils/dataformater'
import _ from 'lodash'
import instruments from '../api/instruments'
import ageManagement from '../api/ageManagement'
import { isReceiptNoteReport } from '../containers/reportEditor/businessRules'
import assetsThreadCompound from '../api/assetsThreadCompound'

export const ReportDataContext = React.createContext()

export const ReportDataProvider = ({ children }) => {
	const { warehouse } = useContext(WMSContext)
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [rejectReasonsList, setRejectReasonsList] = useState([])
	const [availableInstruments, setAvailableInstruments] = useState([])
	const [instrumentTypes, setInstrumentTypes] = useState([])
	const [threadCompoundTypes, setThreadCompoundTypes] = useState([])
	const [applicationMethods, setApplicationMethods] = useState([])

	const getReportRawData = (reportType, reportId) =>
		new Promise((resolve, reject) => {
			reports
				.getReportData(warehouse.id, reportType, reportId, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorGettingReportData`))
					reject(e)
				})
				.finally(() => {})
		})

	const instListReq = instCaseId =>
		new Promise((resolve, reject) => {
			instruments
				.getInstrumentCaseList(warehouse.id, instCaseId, token)
				.then(list => resolve({ id: instCaseId, list: list }))
				.catch(e => reject(e))
				.finally(() => {})
		})

	const getReportInstrumentData = reportData =>
		new Promise((resolve, reject) => {
			const idsList = _.uniqBy(reportData.items, r => r.instlistid)
				.map(r => r.instlistid)
				.filter(r => r > 0)
			const reqList = idsList.map(i => instListReq(i))
			Promise.allSettled(reqList)
				.then(promiseArray => {
					const instrumentList = promiseArray.map(promise => {
						if (promise.status === 'fulfilled') return promise.value
						else return null
					})
					resolve(instrumentList)
				})
				.catch(e => reject(e))
				.finally(() => {})
		})

	const getReportData = (reportType, reportId) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const hydrate = async () => {
				try {
					setIsLoadingData(true)
					const rawData = await getReportRawData(reportType, reportId)
					const formattedData = formatReportData(rawData, reportType, true)
					if (isReceiptNoteReport(reportType)) {
						resolve(formattedData)
					} else {
						const instrumentList = await getReportInstrumentData(formattedData)
						const formattedDataWithInstruments = {
							...formattedData,
							instrumentlist: instrumentList,
							items: formattedData.items.map(i => ({
								...i,
								instrumentlist:
									(i.instlistid !== null || i.instlistid > 0) && instrumentList.length !== 0
										? instrumentList.find(list => list.id === i.instlistid) !== undefined
											? instrumentList.find(list => list.id === i.instlistid).list
											: []
										: []
							}))
						}
						resolve(formattedDataWithInstruments)
					}
				} catch (e) {
					console.error(e)
				} finally {
					setIsLoadingData(false)
				}
			}
			hydrate()
		})

	const getAgeManagementReportData = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setIsLoadingData(true)
			ageManagement
				.getAgeManagementIssuedList(warehouse.id, reportId, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorGettingReportData`))
					reject(e)
				})
				.finally(() => {
					setIsLoadingData(false)
				})
		})

	const getRejectReasons = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				reports
					.getRejectReasons(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						toast.error(t(`wms:ErrorGettingDefectList`))
						reject(e)
					})
					.finally(() => setIsLoadingData(false))
		})

	const getAvailableInstruments = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(t('wms:WarehouseNotDefined')))
			} else {
				instruments
					.getAvailableInstruments(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingInstruments')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => setIsLoadingData(false))
			}
		})

	const getInstrumentsTypes = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(t('wms:WarehouseNotDefined')))
			} else {
				instruments
					.getInstrumentsTypes(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingInstrumentTypes')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => setIsLoadingData(false))
			}
		})

	const getThreadCompoundTypes = () =>
		new Promise((resolve, reject) => {
			warehouse.id &&
				assetsThreadCompound
					.getTypesList(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						toast.error(`${t(`wms:ErrorGetting`)} ${t(`wms:ThreadCompoundTypes`)} [ ${e.status} ] : ${e.data}`)
						reject(e)
					})
					.finally(() => {})
		})

	const getApplicationMethod = () =>
		new Promise((resolve, reject) => {
			warehouse.id &&
				assetsThreadCompound
					.getMethodsList(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						reject(e)
						toast.error(`${t(`wms:ErrorGetting`)} ${t(`wms:ApplicationMethods`)} [ ${e.data} ]: ${e.status}`)
					})
					.finally(() => {})
		})

	const rejectReport = (reportType, reportId, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const payload = {
				responsible: user.name,
				remarks: 'Edited on report editor',
				revision: revision
			}
			reports
				.rejectReport(warehouse.id, reportType, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorDialogMessage`))
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const rejectAgeMgmtReport = (reportId, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const payload = {
				responsible: user.name,
				remarks: 'Edited on report editor'
			}
			ageManagement
				.rejectReport(warehouse.id, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorDialogMessage`))
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const reopenMillReceipt = (reportId, remarks, revision) =>
		new Promise((resolve, reject) => {
			const payload = {
				responsible: user.name,
				remarks: remarks,
				revision: revision
			}
			reports
				.reopenReceiptNote(warehouse.id, reportId, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullyReopenedReport'))
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorReopeningReport')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const reopenReport = (reportType, reportId, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const payload = {
				responsible: user.name,
				remarks: 'Edited on report editor',
				revision: revision
			}
			reports
				.reopenReport(warehouse.id, reportType, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorDialogMessage`))
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const updateReport = (items, reportType, reportId, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const formattedItems = formatReportData(items, reportType, false)
			const payload = {
				items: formattedItems,
				responsible: user.name,
				remarks: 'Edited on report editor',
				revision: revision
			}
			reports
				.updateReport(warehouse.id, reportType, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorDialogMessage`))
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const validateReport = (reportType, reportId, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const payload = {
				responsible: user.name,
				remarks: 'Edited on report editor',
				revision: revision
			}
			reports
				.validateReport(warehouse.id, reportType, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorDialogMessage`))
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const validateAgeMgmtReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const payload = {
				responsible: user.name
			}
			ageManagement
				.validateReport(warehouse.id, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorDialogMessage`))
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const getNewInstrumentCase = instrumentIds =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}

			const payload = {
				uid: user.id,
				ids: instrumentIds
			}

			instruments
				.getNewInstrumentCase(warehouse.id, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorUpdatingEquipments`))
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const releaseInstruments = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}

			instruments
				.releaseInstruments(warehouse.id, user.id, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorUpdatingEquipments`))
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	useEffect(() => {
		getRejectReasons()
			.then(list => setRejectReasonsList(list))
			.catch(e => console.error(e))
		getAvailableInstruments()
			.then(list => setAvailableInstruments(list))
			.catch(e => console.error(e))
		getInstrumentsTypes()
			.then(list => setInstrumentTypes(list))
			.catch(e => console.error(e))
		getThreadCompoundTypes()
			.then(list => setThreadCompoundTypes(list))
			.catch(e => console.error(e))
		getApplicationMethod()
			.then(list => setApplicationMethods(list))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const renderStore = (
		<ReportDataContext.Provider
			value={{
				isLoadingData,
				getReportData,
				getAgeManagementReportData,
				rejectReasonsList,
				availableInstruments,
				instrumentTypes,
				getNewInstrumentCase,
				releaseInstruments,
				user,
				rejectReport,
				rejectAgeMgmtReport,
				reopenReport,
				updateReport,
				validateReport,
				validateAgeMgmtReport,
				threadCompoundTypes,
				setThreadCompoundTypes,
				applicationMethods,
				setApplicationMethods,
				reopenMillReceipt
			}}>
			{children}
		</ReportDataContext.Provider>
	)
	return renderStore
}
