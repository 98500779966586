/** @format */

import * as XLSX from 'xlsx'

const EXCEL_EXTENSION = '.xlsx'

/**
 * Receives data as a JSON and save it on an excel spreadsheet
 * @param {JSON} jsonData The data to be inserted on the excel spreadsheet
 * @param {string} fileName The name of the file
 */
export const exportAsExcelFile = (jsonData, fileName) => {
	const worksheet = XLSX.utils.json_to_sheet(jsonData)
	const workbook = { Sheets: { Data: worksheet }, SheetNames: ['Data'] }
	const options = { bookType: 'xlsx', type: 'array' }

	XLSX.writeFile(workbook, fileName + EXCEL_EXTENSION, options)
}
