/** @format */

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, Icon, Modal, LoadingOverlay } from 'components/common'
import { RadioGroup } from 'components/form'

const PrintModal = ({ isOpen, onOutsideClick, printersList, onSelectPrinter, loadingResponse = false }) => {
	const { t } = useTranslation()
	const [printer, setPrinter] = useState(null)

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card
				width='auto'
				data-cy='card-new-type-form-wrapper'
				position='relative'
				style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
				<LoadingOverlay visible={loadingResponse} borderRadius='card' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:Printers')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				{printersList.length ? (
					<FlexView width='100%' flexDirection='column' justifyContent='space-between'>
						<RadioGroup
							name='printerOption'
							label={`${t('wms:SelectPrinter')}`}
							value={printer}
							margin='0'
							data-cy='input-radio-printer'
							onChange={v => setPrinter(v)}
							options={printersList}
						/>
						<FlexView width='100%' justifyContent='center' margin='32px 0 0 0'>
							<Button
								margin='0px auto'
								padding='8px 16px'
								color='white'
								backgroundColor='success'
								data-cy='button-sync-pipe-data'
								onClick={() => onSelectPrinter(printer)}>
								{`${t('wms:PrintItems')}`}
								<Icon name='refresh' color='white' height='12px' width='12px' margin='0 0 0 8px' />
							</Button>
						</FlexView>
					</FlexView>
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						color='lightGray'
						margin='100px 0'
						alignItems='center'
						justifyContent='center'
						data-cy='empty-list'>
						{t('wms:NoAvailablePrinters')}
					</FlexView>
				)}
			</Card>
		</Modal>
	)
}

export default PrintModal
