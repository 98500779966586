/** @format */

import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FlexView, Button } from 'components/common'
import RigReturn from '../../assets/icons/rig-return.svg'
import PerInspection from '../../assets/icons/periodic-inspection.svg'
import ModProducts from '../../assets/icons/modified-products.svg'
import AgeManagement from '../../assets/icons/age-management.svg'
import Inventory from '../../assets/icons/inventory.svg'
import Content from '../../assets/icons/content.svg'
import BatchEntrance from '../../assets/icons/batch-entrance.svg'
import RecInspection from '../../assets/icons/rec-inspection.svg'
import Move from '../../assets/icons/move.svg'
import UsageRights from '../../assets/icons/usage-rights.svg'
import MatRequest from '../../assets/icons/mat-req.svg'
import Dispatch from '../../assets/icons/dispatch.svg'
import { UserContext } from 'stores/UserStore'

const NavCol = styled(FlexView)`
	width: 100%;
	display: grid;
	${props =>
		!!!props.cols
			? css`
					grid-template-columns: 1fr 1fr;
			  `
			: css`
					grid-template-columns: repeat(${props.cols}, 1fr);
			  `}
	grid-template-rows: auto;
	grid-column-gap: 16px;
	align-items: stretch;
`

const NavColHeader = styled(FlexView)`
	color: ${({ theme }) => theme.colors.secondary};
	padding: 8px;
	text-align: center;
	justify-content: center;
	width: calc(95% - 16px);
	font-size: 24px;
	flex-direction: row;
	margin: 0 auto 16px auto;
	border-bottom: 4px solid ${({ theme }) => theme.colors.secondary};
`

const ListCard = styled(Button)`
	margin: 0 0 16px 0;
	border-radius: 4px;
	padding: 16px;
	color: ${({ theme }) => theme.colors.gray};
	background: ${({ theme }) => theme.colors.white};
	white-space: break-spaces;
	flex-direction: column;

	> img {
		color: ${({ theme }) => theme.colors.secondary};
		height: 100px;
	}

	&.fill {
		grid-column-start: 1;
		grid-column-end: -1;
	}
`

const NavGrid = () => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)
	const history = useHistory()
	const redirectTo = path => {
		history.push(path)
	}

	return (
		<FlexView justifyContent='space-between' flexDirection='row' width='100%' height='100%' flexWrap='wrap'>
			<FlexView width='calc(32%)'>
				<NavColHeader>{t('wms:ReceiveMaterial')}</NavColHeader>
				<NavCol data-cy='nav-section'>
					<ListCard data-cy='nav-button' onClick={() => redirectTo('entrance/batch')} elevation='low'>
						<img src={BatchEntrance} alt='Entrance'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Entrance')}
						</FlexView>
					</ListCard>
					<ListCard data-cy='nav-button' onClick={() => redirectTo('entrance/rigreturn')} elevation='low'>
						<img src={RigReturn} alt='Rig Return'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:RigReturn')}
						</FlexView>
					</ListCard>
					<ListCard data-cy='nav-button' onClick={() => redirectTo('entrance/inspection/recinsp')} elevation='low'>
						<img src={RecInspection} alt='Receiving Inspection'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:ReceivingInspection')}
						</FlexView>
					</ListCard>
					<ListCard data-cy='nav-button' onClick={() => redirectTo('entrance/printing')} elevation='low'>
						{/* <img src={RecInspection} alt='Tag Printing'></img> */}
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:TagPrinting')}
						</FlexView>
					</ListCard>
					{user.roles.some(u => u === 'wmsadmin') && (
						<ListCard data-cy='nav-button' onClick={() => redirectTo('entrance/prepare')} elevation='low'>
							<img src={BatchEntrance} alt='Entrance'></img>
							<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
								{t('wms:PrepareEntrance')}
							</FlexView>
						</ListCard>
					)}
				</NavCol>
			</FlexView>
			<FlexView width='calc(32%)'>
				<NavColHeader>{t('wms:ManageWarehouse')}</NavColHeader>
				<NavCol data-cy='nav-section'>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('content/main')}
						elevation='low'
						disabled={false}
						title={t('wms:AvailableSoon')}>
						<img src={Content} alt='Content'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Content')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/inventory/check/')}
						elevation='low'
						disabled={false}>
						<img src={Inventory} alt='Inventory'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Inventory')}
						</FlexView>
					</ListCard>
					<ListCard data-cy='nav-button' onClick={() => redirectTo('manage/move')} elevation='low'>
						<img src={Move} alt='Move'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Move')}
						</FlexView>
					</ListCard>
					<ListCard data-cy='nav-button' onClick={() => redirectTo('manage/usagerights')} elevation='low'>
						<img src={UsageRights} alt='Ownership'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:UsageRights')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/inspection/perinsp')}
						elevation='low'
						disabled={false}>
						<img src={PerInspection} alt='Periodical Inspection'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:PeriodicInspection')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/pipeagemanagement')}
						elevation='low'
						disabled={false}>
						<img src={AgeManagement} alt='Pipe Age Management'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:AgeManagement')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('pipedata/modify')}
						elevation='low'
						disabled={false}>
						<img src={Content} alt='Content'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:ActualDataModif')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('pipedata/nominaldatamodif')}
						elevation='low'
						disabled={false}
						title={t('wms:AvailableSoon')}>
						<img src={Content} alt='Content'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:NominalDataModification')}
						</FlexView>
					</ListCard>
				</NavCol>
			</FlexView>
			<FlexView width='calc(32%)'>
				<NavColHeader>{t('wms:DispatchMaterial')}</NavColHeader>
				<NavCol data-cy='nav-section'>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('dispatch/manage')}
						elevation='low'
						disabled={false}>
						<img src={Dispatch} alt='Dispatch'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Dispatch')}
						</FlexView>
					</ListCard>
					<ListCard data-cy='nav-button' onClick={() => redirectTo('dispatch/matreq')} elevation='low'>
						<img src={MatRequest} alt='Material Requisition'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:MaterialRequisition')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('dispatch/modifproducts')}
						elevation='low'
						disabled={false}>
						<img src={ModProducts} alt='Modified Products'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:ModifiedProducts')}
						</FlexView>
					</ListCard>
				</NavCol>
			</FlexView>
			<FlexView width='calc(100%)'>
				<NavColHeader>{t('wms:Reports')}</NavColHeader>
				<NavCol data-cy='nav-section' cols={3}>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/review/mill_receipts')}
						elevation='low'>
						<FlexView fontSize='16px'>{t('wms:ReceiptNote')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/edit/inspection')}
						elevation='low'>
						<FlexView fontSize='16px'>{t('wms:InspectionReports')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/export/delivery_notes')}
						elevation='low'>
						<FlexView fontSize='16px'>{t('wms:DeliveryNotes')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/export/modif_products')}
						elevation='low'>
						<FlexView fontSize='16px'>{t('wms:ModifiedProducts')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/export/backload_manifest')}
						elevation='low'>
						<FlexView fontSize='16px'>{t('wms:BackloadManifest')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/export/dispatch')}
						elevation='low'>
						<FlexView fontSize='16px'>{t('wms:Dispatch')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/review/age_management')}
						elevation='low'>
						<FlexView fontSize='16px'>{t('wms:AgeManagement')}</FlexView>
					</ListCard>
				</NavCol>
			</FlexView>
		</FlexView>
	)
}

export default NavGrid
