/** @format */

import { ContentContext } from 'apps/wms/stores/ContentStore'
import { FlexView, Icon } from 'components/common'
import React, { useCallback, useContext, useMemo } from 'react'
import _ from 'lodash'

const Tag = ({ label, values, onClearClick, onTagClick }) => {
	const onClear = e => {
		e.stopPropagation()
		onClearClick()
	}

	return (
		<FlexView
			flexDirection='row'
			margin='0px 8px 8px 0px'
			fontSize='small'
			backgroundColor='whitesmoke'
			padding='8px'
			borderRadius='component'
			onClick={onTagClick}
			style={{ cursor: 'pointer' }}>
			<span>
				<strong>{label}:</strong>
				{values}
			</span>
			<Icon name='cross' color='error' width='16px' height='16px' onClick={onClear} margin='0px 0px 0px 8px' />
		</FlexView>
	)
}

const ContentFIlterDisplay = ({ options, onTagClick }) => {
	const { filters, setFilters } = useContext(ContentContext)

	const onClearClick = useCallback(
		accessor => () => {
			setFilters(currentFilters => ({
				...currentFilters,
				[accessor]: []
			}))
		},
		[setFilters]
	)

	const renderTags = useMemo(
		() =>
			_.chain(options)
				.filter(({ accessor }) => _.get(filters, accessor, []).length > 0)
				.map(({ accessor, label, formatter }) => {
					const values = _.map(filters[accessor], value => _.truncate(formatter ? formatter(value) : value))
					return (
						<Tag
							key={accessor}
							label={label}
							values={values}
							onTagClick={onTagClick}
							onClearClick={onClearClick(accessor)}
						/>
					)
				})
				.value(),
		[options, filters, onClearClick, onTagClick]
	)

	return (
		<FlexView flexDirection='row' flexWrap='wrap' margin='0px'>
			{renderTags}
		</FlexView>
	)
}

export default ContentFIlterDisplay
