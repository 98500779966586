/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getAttributes: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`attributes/${wid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.attributes)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAllAttributeValues: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`attributes/${wid}/value`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.attributevalues)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAttrValues: (wid, attrID, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`attributes/${wid}/value?attributeid=${attrID}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.attributevalues)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveAttribute: (wid, isNew, params = {}, attrID, token) =>
		new Promise((resolve, reject) => {
			if (isNew) {
				instance
					.post(`attributes/${wid}`, params, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response.data)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			} else {
				instance
					.put(`attributes/${wid}/${attrID}`, params, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response.data)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			}
		}),
	deleteAttribute: (wid, attrID, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`attributes/${wid}/${attrID}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve()
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	addAttrValue: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`attributes/${wid}/value`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveAttrValue: (wid, attrValueID, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`attributes/${wid}/value/${attrValueID}`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteAttrValue: (wid, attrValueID, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`attributes/${wid}/value/${attrValueID}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
