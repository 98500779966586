/** @format */

import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import dispatch from '../api/dispatch'
import common from '../api/common'

import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import usageRights from '../api/usageRights'
import _ from 'lodash'

export const DispatchContext = React.createContext()

export const DispatchProvider = ({ children }) => {
	const { warehouse, lastLevelsList, getContentByOwnership, getEnduserList, getMaterialsDescpritions } =
		useContext(WMSContext)
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const [dispatchList, setDispatchList] = useState([])
	const [materialList, setMaterialList] = useState([])
	const [materialsByEU, setMaterialsByEU] = useState([])
	const [vehiclesList, setVehiclesList] = useState([])
	const [endUserList, setEndUserList] = useState([])
	const [loadingDispatchList, setLoadingDispatchList] = useState(false)
	const [selectedDispatch, setSelectedDispatch] = useState(null)
	const [newDispatchModal, setNewDispatchModal] = useState(false)
	const [dispatchModal, setDispatchModal] = useState(false)
	const [updateDispatchList, setUpdateDispatchList] = useState(false)
	const [loadingDispatchInfo, setLoadingDispatchInfo] = useState(false)
	const [loadingLevels, setLoadingLevels] = useState(false)
	const [pipeData, setPipeData] = useState(null)
	const [openConfirmModal, setConfirmModal] = useState(false)

	const getDispatchList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingDispatchList(true)
			dispatch
				.getDispatchList(warehouse.id, token)
				.then(dispatchList => resolve(dispatchList))
				.catch(e => {
					toast.error(t(`wms:ErrorGettingDispatchingLists`))
					reject(e)
				})
				.finally(() => setLoadingDispatchList(false))
		})

	const getDispatchInfo = dispatchId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingDispatchInfo(true)
			dispatch
				.getDispatchInfo(warehouse.id, dispatchId, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					toast.error(t(`wms:ErrorGettingDispatchListContent`))
					reject(e)
				})
				.finally(() => setLoadingDispatchInfo(false))
		})

	const deleteDispatch = dispatchId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			dispatchId !== null &&
				dispatch
					.deleteDispatch(warehouse.id, dispatchId, token)
					.then(response => {
						toast.success(t(`wms:DispatchRemoved`))
						resolve(true)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorDeletingDispatchList`))
						reject(e)
					})
					.finally(() => {})
		})

	const getVehicles = () =>
		new Promise((resolve, reject) => {
			dispatch
				.getVehiclesList(warehouse.id, token)
				.then(vehicles => vehicles.length && resolve(vehicles))
				.catch(e => {
					toast.error(t(`wms:ErrorGettingDispatchingLists`))
					reject(e)
				})
				.finally(() => setLoadingDispatchList(false))
		})

	const getContent = sourceOwnership =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				sourceOwnership &&
					getContentByOwnership(sourceOwnership, false)
						.then(contentList => resolve(contentList))
						.catch(e => {
							toast.error(t(`wms:ErrorGettingContent`))
							reject(e)
						})
						.finally(() => {})
		})

	const getPipeData = valid =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				valid &&
					common
						.getPipeData(warehouse.id, valid, undefined, token)
						.then(pipeData => resolve(pipeData))
						.catch(e => {
							toast.error(t(`wms:ErrorGettingPipeData`))
							reject(e)
						})
						.finally(() => {})
		})

	const _getOwnershipByEnduser = (wid, enduser, token, t) =>
		new Promise((resolve, reject) => {
			setLoadingLevels(true)
			usageRights
				.getOwnershipsListByEndUser(wid, enduser, token)
				.then(response => {
					let lastLevel = Math.max.apply(
						Math,
						response.ownershipdescriptions.map(res => res.level)
					)
					let oData = response.ownershipdescriptions
					let ownershipList = []
					let lastLevelDesc = _.filter(oData, owner => {
						return owner.level === lastLevel
					})
					_.forEach(lastLevelDesc, owner => {
						ownershipList.push({ id: owner.id, label: owner.namepath.join('.') })
					})
					resolve(ownershipList)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
					reject(e)
					setLoadingLevels(false)
				})
				.finally(() => setLoadingLevels(false))
		})

	const getOwnershipByEnduser = useCallback(
		enduser => _getOwnershipByEnduser(warehouse.id, enduser, token, t),
		[warehouse, token, t]
	)

	const newDispatch = dispatchData =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			let payload = {
				expecteddate: dispatchData.expecteddate,
				destination: dispatchData.destination,
				vehicledata: { ...dispatchData.vehicle, properties: undefined },
				occupationfactor: dispatchData.occupationfactor,
				contents: dispatchData.content.map(ct => ({
					levelfullname: ct.levelfullname,
					mweightkg: ct.mweightkg,
					mid: ct.mid,
					mformatted: ct.mformatted,
					levelid: ct.levelid,
					quantity: ct.quantity,
					enduserid: ct.enduserid
				}))
			}
			dispatch
				.newDispatch(warehouse.id, payload, token)
				.then(response => {
					toast.success(t(`wms:DispatchCreated`))
					resolve(true)
				})
				.catch(e => {
					toast.error(t(`${e}`))
					reject(e)
				})
				.finally(() => {})
		})

	const executeDispatch = dispatchData =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}

			let items = dispatchData.bundles.filter(b => b.checked)

			let payload = {
				responsible: user.name,
				dispatchlistid: dispatchData.id,
				valids: items.map(i => i.valid)
			}

			dispatch
				.executeDispatch(warehouse.id, payload, token)
				.then(response => {
					toast.success(t(`wms:DispatchSuccessful`))
					resolve(true)
					setConfirmModal(false)
				})
				.catch(e => {
					toast.error(t(`wms:ErrorDispatching`))
					reject(e)
				})
				.finally(() => {
					setConfirmModal(false)
				})
		})

	useEffect(() => {
		getDispatchList()
			.then(DispatchList => setDispatchList(DispatchList))
			.catch(e => console.error(e))
		getVehicles()
			.then(vehicles => setVehiclesList(vehicles))
			.catch(e => console.error(e))
		getMaterialsDescpritions()
			.then(materialsList => setMaterialList(materialsList))
			.catch(e => console.error(e))
		getEnduserList()
			.then(endUsers => setEndUserList(endUsers))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		getDispatchList()
			.then(DispatchList => DispatchList.length && setDispatchList(DispatchList.sort((a, b) => a.id - b.id)))
			.catch(e => console.error(e))
		setUpdateDispatchList(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateDispatchList])

	useEffect(() => {
		selectedDispatch &&
			selectedDispatch.contents === undefined &&
			getDispatchInfo(selectedDispatch.id)
				.then(dispatchData => {
					if (dispatchData.bundles && !dispatchData.hasOwnProperty('level')) {
						const bundles = dispatchData.bundles.map(b => {
							return b.items.map(item => ({
								...item,
								checked: false,
								levelid: b.level.id,
								levelfullname: b.level.fullname,
								mid: b.material.id
							}))
						})
						dispatchData.displayitems = bundles.flat()
					}
					setSelectedDispatch({
						...selectedDispatch,
						contents: dispatchData.contents,
						bundles: dispatchData.displayitems
					})
				})
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDispatch])

	const renderStore = (
		<DispatchContext.Provider
			value={{
				dispatchList,
				loadingDispatchList,
				selectedDispatch,
				setSelectedDispatch,
				deleteDispatch,
				setUpdateDispatchList,
				materialList,
				lastLevelsList,
				loadingDispatchInfo,
				setLoadingDispatchInfo,
				vehiclesList,
				newDispatchModal,
				setNewDispatchModal,
				dispatchModal,
				setDispatchModal,
				getContent,
				newDispatch,
				executeDispatch,
				pipeData,
				setPipeData,
				getPipeData,
				endUserList,
				setEndUserList,
				getOwnershipByEnduser,
				loadingLevels,
				setLoadingLevels,
				setMaterialList,
				materialsByEU,
				setMaterialsByEU,
				openConfirmModal,
				setConfirmModal
			}}>
			{children}
		</DispatchContext.Provider>
	)
	return renderStore
}
