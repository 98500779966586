/** @format */

import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Table from '../../components/content/Table'

import { FlexView, Card, Button, Modal, Icon, LoadingOverlay } from 'components/common'
import { Checkbox } from 'components/form'

import { DispatchContext } from '../../stores/DispatchStore'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import theme from 'utils/theme'
import { STOCK_STATUS } from 'apps/wms/utils/enum'

const ExecuteDispatchModal = ({ isOpen, onOutsideClick, dispatchInfo }) => {
	const { lengthUnit, weightUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit } = useContext(WMSContext)

	const {
		lastLevelsList,
		materialList,
		executeDispatch,
		pipeData,
		setPipeData,
		getPipeData,
		openConfirmModal,
		setConfirmModal
	} = useContext(DispatchContext)
	const { t } = useTranslation()
	const [displayDispatch, setDisplayDispatch] = useState(false)
	const [dispatchData, setDispatchData] = useState(null)
	const [loadingDispatch, setLoadingDispatch] = useState(false)
	const [loadingPipeData, setLoadingPipeData] = useState(false)
	const [displayPipeData, setDisplayPipeData] = useState(false)
	const [listWidthPercentage, setListWidthPercentage] = useState(65)

	useEffect(() => {
		setDispatchData(null)
		if (dispatchInfo !== null && dispatchInfo.contents !== undefined) {
			setDispatchData({
				...dispatchInfo,
				contents: dispatchInfo.contents.map(c => ({ ...c, checked: false, qtyChecked: 0 })),
				bundles: dispatchInfo.bundles.map(b => ({ ...b, checked: false }))
			})
			setDisplayDispatch(true)
		} else setDisplayDispatch(false)
	}, [dispatchInfo])

	const handleCheckItem = item => {
		let dItem = dispatchData.bundles.find(b => b.valid === item.valid)
		let content = dispatchData.contents.find(c => c.mid === dItem.mid && c.levelid === dItem.levelid)
		if (dItem !== undefined) {
			if (content !== undefined) {
				if (dItem.checked) {
					dItem.checked = false
					content.checked = false
					content.qtyChecked--
				} else if (content.qtyChecked < content.quantity) {
					dItem.checked = true
					content.checked = true
					content.qtyChecked++
				}
				setDispatchData({ ...dispatchData })
			}
		}
	}

	const handleValidSelection = item => {
		var dItem = dispatchData.bundles.find(b => b.valid === item.valid)
		if (dItem !== undefined) {
			setLoadingPipeData(true)
			getPipeData(dItem.valid)
				.then(data => {
					setPipeData(data.pipedata)
					setListWidthPercentage(35)
					setDisplayPipeData(true)
				})
				.catch(e => console.error(e))
				.finally(() => setLoadingPipeData(false))
		}
	}

	const handleClosePipeData = () => {
		setDisplayPipeData(false)
		setListWidthPercentage(65)
	}

	const toFraction = number => {
		number = parseFloat(number * 0.03937).toFixed(3)
		let nDesc = 0
		let int
		if (Math.floor(number) !== number) nDesc = number.toString().split('.')[1].length || 0
		let denominator = Math.pow(10, nDesc)
		let numerator = number * denominator

		if (numerator > denominator) {
			int = Math.floor(numerator / denominator)
			numerator = numerator - int * denominator
		}
		for (let i = 1; i <= numerator; i++) {
			if (Number.isInteger(numerator / i) && Number.isInteger(denominator / i)) {
				numerator = numerator / i
				denominator = denominator / i
				i = 1
			}
		}
		return int.toString() + ' ' + numerator.toString() + '/' + denominator.toString() + ' "'
	}

	const fill = value => {
		return value === null || !value ? '*****' : value
	}

	const checkDisabled = item => {
		let isDisabled = true
		let content = dispatchData.contents.find(c => c.mid === item.mid && c.levelid === item.levelid)
		isDisabled = isDisabled && content !== undefined && content.qtyChecked === content.quantity

		return isDisabled
	}

	const itemColumns = useMemo(
		() => [
			{
				Header: t('wms:Rack'),
				accessor: 'levelfullname',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Item'),
				accessor: 'valid',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='14px'
						color={STOCK_STATUS.color[row.original.stockstatus]}
						onClick={() => handleValidSelection(row.original)}>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Verified'),
				accessor: 'checked',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<Checkbox
						checked={value}
						disabled={checkDisabled(row.original) && !value}
						onChange={() => handleCheckItem(row.original)}
					/>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatchData, checkDisabled, t]
	)

	const matFormatted = id => {
		return id !== null && materialList.length && materialList.find(mat => mat.mvid === id)
			? materialList.find(mat => mat.mvid === id).formatted
			: ''
	}

	const levelFullName = id => {
		return id !== null && lastLevelsList.length && lastLevelsList.find(level => level.id === id)
			? lastLevelsList.find(level => level.id === id).fullname
			: ''
	}

	const checkDispatchCompletion = () => {
		return dispatchData !== null &&
			dispatchData.contents.find(c => c.qtyChecked > 0) !== undefined &&
			dispatchData.contents.find(c => c.qtyChecked <= c.quantity) !== undefined
			? false
			: true
	}

	const handleDispatch = () => {
		setLoadingDispatch(true)
		executeDispatch(dispatchData)
			.then(response => {
				onOutsideClick()
				setLoadingDispatch(false)
			})
			.catch(e => console.error(e))
			.finally(() => setLoadingDispatch(false))
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='80%' height='85%' position='relative'>
				<LoadingOverlay visible={!displayDispatch || loadingDispatch} borderRadius='16px' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:Dispatch')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				{displayDispatch ? (
					<FlexView width='100%' flex='1' flexDirection='row'>
						<FlexView maxWidth='35%' minWidth='35%' minHeight='100%' maxHeight='100%' margin='0 16px 0 0'>
							<Card margin='0 0 4px 0' width='calc(100% - 48px)' flex='1'>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='8px 0'>
									{t('wms:Content')}
								</FlexView>
								{dispatchData !== null && dispatchData.contents ? (
									<FlexView
										width='100%'
										maxHeight={`calc(85vh - ${170}px)`}
										style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
										{dispatchData.contents.map((content, index) => (
											<FlexView
												key={index}
												width='100%'
												margin='0'
												flexDirection='row'
												flexWrap='wrap'
												padding='8px 0'
												style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
												<FlexView
													width='100%'
													fontSize='small'
													margin='0 0 16px 0'
													padding='0'
													flexDirection='column'>
													<FlexView
														width='100%'
														fontWeight='bold'
														fontSize='tiny'
														color='metalic'
														margin='0'>
														{t('wms:Material')}
													</FlexView>
													<FlexView
														width='100%'
														style={{
															borderLeft: `2px solid ${theme.colors.primary}`,
															lineHeight: '24px'
														}}
														padding='0 0 0 8px'>
														{matFormatted(content.mid)}
													</FlexView>
												</FlexView>
												<FlexView width='50%' margin='0' padding='0' flexDirection='column'>
													<FlexView
														width='100%'
														fontWeight='bold'
														fontSize='tiny'
														color='metalic'
														margin='0'>
														{t('wms:Rack')}
													</FlexView>
													<FlexView
														width='100%'
														style={{
															borderLeft: `2px solid ${theme.colors.primary}`,
															lineHeight: '24px'
														}}
														padding='0 0 0 8px'>
														{levelFullName(content.levelid)}
													</FlexView>
												</FlexView>
												<FlexView width='50%' margin='0' padding='0' flexDirection='column'>
													<FlexView
														width='100%'
														fontWeight='bold'
														fontSize='tiny'
														color='metalic'
														margin='0'>
														{t('wms:Quantity')}
													</FlexView>
													<FlexView
														width='100%'
														style={{
															borderLeft: `2px solid ${theme.colors.primary}`,
															lineHeight: '24px'
														}}
														padding='0 0 0 8px'>
														{content.qtyChecked}/{content.quantity}
													</FlexView>
												</FlexView>
											</FlexView>
										))}
									</FlexView>
								) : null}
							</Card>
						</FlexView>
						<FlexView width={`calc(${listWidthPercentage}% - 16px)`} minHeight='100%' maxHeight='100%' margin='0'>
							<Card margin='0 0 4px 0' width='calc(100% - 48px)' flex='1'>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='8px 0'>
									{t('wms:DispatchItemsLocation')}
								</FlexView>
								<Table
									margin='0'
									width='100%'
									textAlign='center'
									elevation='none'
									height={`calc(85vh - ${110}px)`}
									fontSize='14px'
									columns={itemColumns}
									data={dispatchData.bundles}
									sortable
									gridless
								/>
							</Card>
						</FlexView>
						{pipeData && displayPipeData ? (
							<FlexView
								maxWidth='30%'
								minWidth='30%'
								minHeight='100%'
								maxHeight='100%'
								margin='0 0 0 16px'
								style={{ position: 'relative' }}>
								<LoadingOverlay visible={loadingPipeData} borderRadius='16px' />

								<Card margin='0 0 4px 0' width='calc(100% - 48px)' flex='1'>
									<FlexView
										width='100%'
										fontSize='medium'
										flexDirection='row'
										justifyContent='space-between'
										fontWeight='bold'
										margin='8px 0'>
										{t('wms:PipeData')}
										<Button
											fontSize='tiny'
											margin='0'
											padding='8px'
											backgroundColor='error'
											disabled={false}
											onClick={handleClosePipeData}>
											<Icon name='cross-simple' color='white' height='8px' width='8px' margin='0' />
										</Button>
									</FlexView>
									<FlexView
										width='100%'
										maxHeight={`calc(85vh - ${170}px)`}
										style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
										<FlexView
											flexDirection='row'
											flexWrap='wrap'
											margin='0 0 16px 0'
											justifyContent='space-between'
											width='100%'>
											<FlexView margin='0 0 8px 0'>{t('wms:ItemId')}</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													Valid
												</FlexView>
												{pipeData.pipevalid}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													CAT ID
												</FlexView>
												{pipeData.catid}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:OrderNo')} VTSA
												</FlexView>
												{pipeData.vtsaordernumber}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:ItemNo')}
												</FlexView>
												{pipeData.vtsaitemnumber}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:CustomerPoNo')}
												</FlexView>
												{pipeData.customerponumber}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:ItemNo')}
												</FlexView>
												{pipeData.customeritem}
											</FlexView>
										</FlexView>

										<FlexView
											flexDirection='row'
											flexWrap='wrap'
											margin='0 0 16px 0'
											justifyContent='flex-start'
											alignItems='flex-start'
											width='100%'>
											<FlexView margin='0 0 4px 0' width='100%'>
												{t('wms:NominalData')}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:FamilyProduct')}
												</FlexView>
												{fill(pipeData.producttype)}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:StorageType')}
												</FlexView>
												{fill(pipeData.storagetype)}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:OD')} [in]
												</FlexView>
												{pipeData.od ? fill(toFraction(pipeData.od)) : null}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:Weight')} [lb]/[ft]
												</FlexView>
												{pipeData.weight ? pipeData.weight.toFixed(2) : '*****'}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:Grade')}
												</FlexView>
												{fill(pipeData.grade)}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:Connection')}
												</FlexView>
												{fill(pipeData.connection)}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:Length')}
												</FlexView>
												{fill(pipeData.length)}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:Drift')}
												</FlexView>
												{fill(pipeData.drift)}
											</FlexView>
										</FlexView>

										<FlexView
											flexDirection='row'
											flexWrap='wrap'
											margin='0 0 16px 0'
											justifyContent='space-between'
											width='100%'>
											<FlexView margin='0 0 4px 0' width='100%'>
												{t('wms:ActualData')}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:HeatNo')}
												</FlexView>
												{fill(pipeData.heat)}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													IPPN
												</FlexView>
												{fill(pipeData.pipepuuid)}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:ActualLength')}
												</FlexView>
												{fill(convertToCurrentLengthUnit(pipeData.actuallength, lengthUnit))} {lengthUnit}
											</FlexView>

											<FlexView
												flexDirection='column'
												width='49%'
												margin='0 0 8px 0'
												fontSize='small'
												style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
												<FlexView
													fontSize='tiny'
													color='metalic'
													fontWeight='bold'
													margin='0px 0px 4px 0px'>
													{t('wms:ActualWeight')}
												</FlexView>
												{fill(convertToCurrentWeightUnit(pipeData.actualweight, weightUnit))} {weightUnit}
											</FlexView>
										</FlexView>
									</FlexView>
								</Card>
							</FlexView>
						) : null}
					</FlexView>
				) : null}
				<FlexView width='100%' flexDirection='row' margin='16px 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='success'
						disabled={checkDispatchCompletion()}
						onClick={() => setConfirmModal(true)}>
						{t('wms:DispatchMaterial')}
						<Icon name='play' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
					<DialogModal
						isOpen={openConfirmModal}
						text={t(`wms:ConfirmItemsDispatch`)}
						onConfirm={handleDispatch}
						onCancel={() => setConfirmModal(false)}
						data-cy='dialog-confirm-change'
					/>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default ExecuteDispatchModal
