/** @format */

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import vehicle from '../api/assetsTransport'
import { toast } from 'react-toastify'
import { _ } from 'globalthis/implementation'

export const AssetsTransportContext = React.createContext()

export const AssetsTransportProvider = ({ children }) => {
	const { t } = useTranslation()
	const { warehouse, convertWeightToSend } = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const [vehicles, setVehicles] = useState(null)
	const [selectedVehicle, setSelectedVehicle] = useState(null)

	const [loadingVehicles, setLoadingVehicles] = useState(false)
	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [openAddModal, setOpenAddModal] = useState(false)

	const getVehicles = () =>
		new Promise((resolve, reject) => {
			vehicle
				.getVehicles(warehouse.id, token)
				.then(response => {
					setVehicles(response)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingVehicles')} [ ${e.status}]: ${e.data}`)
				})
		})

	const _createVehicle = (vehicleData, wid, token) =>
		new Promise((resolve, reject) => {
			let payload
			if (vehicleData && vehicleData.properties.length > 0) {
				payload = vehicleData
			}
			payload.maxweightkg = convertWeightToSend(payload.maxweightkg)
			vehicle
				.saveVehicle(wid, true, null, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:TransportCreatedSuccessful'))
					setSelectedVehicle(null)
					getVehicles()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorCreatingVehicle')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const createVehicle = useCallback(
		data => {
			return _createVehicle(data, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _updateVehicle = (vehicleData, wid, token) =>
		new Promise((resolve, reject) => {
			let vehicleId
			let payload
			if (vehicleData && vehicleData.properties.length > 0) {
				payload = vehicleData
				vehicleId = vehicleData.id
			}
			payload.maxweightkg = convertWeightToSend(payload.maxweightkg)
			vehicle
				.saveVehicle(wid, false, vehicleId, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:TransportUpdatedSuccessful'))
					getVehicles()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorUpdatingVehicle')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const toUpdateVehicle = useCallback(
		data => {
			return _updateVehicle(data, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const deleteVehicle = () =>
		new Promise((resolve, reject) => {
			vehicle
				.deleteVehicle(warehouse.id, selectedVehicle.id, token)
				.then(response => {
					_.remove(vehicles, v => v.id === selectedVehicle.id)
					setVehicles({ ...vehicles })
					resolve()
					toast.success(t('wms:TransportDeletedSuccessful'))
					setSelectedVehicle(null)
					getVehicles()
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorDeletingVehicle')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => {
					setOpenConfirmModal(false)
				})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingVehicles(true)
				await getVehicles()
			} catch (e) {
				setLoadingVehicles(false)
				console.error(e)
			} finally {
				setLoadingVehicles(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setSelectedVehicle(null)
	}, [warehouse])

	const renderStore = (
		<AssetsTransportContext.Provider
			value={{
				vehicles,
				setVehicles,
				selectedVehicle,
				setSelectedVehicle,
				loadingVehicles,
				setLoadingVehicles,
				openUpdateModal,
				setOpenUpdateModal,
				openConfirmModal,
				setOpenConfirmModal,
				openAddModal,
				setOpenAddModal,
				deleteVehicle,
				createVehicle,
				toUpdateVehicle
			}}>
			{children}
		</AssetsTransportContext.Provider>
	)
	return renderStore
}
