/** @format */

import { AssetsWarehouseContext } from 'apps/wms/stores/AssetsWarehouseStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { Input, RadioGroup, Toggle } from 'components/form'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Checkbox from 'apps/wms/components/forms/Checkbox'

const AddWarehouseModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { newWarehouse, setNewWarehouse, saving, setSaving, saveWarehouse } = useContext(AssetsWarehouseContext)

	const [displayAllInstruments, setDisplayAllInstruments] = useState(false)

	const displayOptions = [
		{
			value: 0,
			label: `${t('wms:Bundle')}`
		},
		{
			value: 1,
			label: 'VALID'
		},
		{
			value: 2,
			label: `${t('wms:Puuid')}`
		}
	]

	const addLevelDesc = (value, index) => {
		newWarehouse.levels[index].desc = value
		setNewWarehouse({ ...newWarehouse, levels: [...newWarehouse.levels] })
	}

	const changeMoveProp = (value, index) => {
		newWarehouse.levels[index].canmove = value
		setNewWarehouse({ ...newWarehouse, levels: [...newWarehouse.levels] })
	}

	const addLevel = () => {
		if (newWarehouse && newWarehouse.levels && newWarehouse.levels.length < 6) {
			newWarehouse.levels.push({
				desc: '',
				canmove: false
			})
		}
		setNewWarehouse({ ...newWarehouse })
	}

	const deleteLevel = index => {
		newWarehouse.levels && newWarehouse.levels.length > 0 && newWarehouse.levels.splice(index, 1)
		setNewWarehouse({ ...newWarehouse })
	}

	const handleSaveWarehouse = async () => {
		try {
			setSaving(true)
			await saveWarehouse(true, newWarehouse)
		} catch (e) {
			console.error(e)
			setSaving(false)
		} finally {
			setSaving(false)
		}
	}

	useEffect(() => {
		checkFields()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newWarehouse])

	const checkFields = () => {
		let isDisabled = true
		if (
			(newWarehouse.country === '' &&
				newWarehouse.plant === '' &&
				newWarehouse.name === '' &&
				newWarehouse.pairingsite === '') ||
			newWarehouse.levels.length === 0 ||
			_.some(newWarehouse.levels, level => level.desc === '')
		) {
			isDisabled = true
		} else {
			isDisabled = false
		}

		return isDisabled
	}

	const handleDisplayEquipments = value => {
		setDisplayAllInstruments(value)
		setNewWarehouse({ ...newWarehouse, displayallocatedinstruments: value })
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='65%' height='85%' maxHeight='85%' data-cy='card-add-warehouse'>
				<FlexView width='100%' fontSize='big' fontWeight='bold'>
					{t('wms:New')} {t('wms:Warehouse')}
				</FlexView>
				<FlexView width='100%' flexDirection='column' height='95%'>
					<FlexView width='100%' flexDirection='row'>
						<Input
							margin='16px 16px'
							type='text'
							label={t('wms:Country')}
							placeholder={t('wms:Country')}
							value={newWarehouse && newWarehouse.country}
							onChange={e => setNewWarehouse({ ...newWarehouse, country: e.target.value })}
							data-cy='input-warehouse-country'
						/>
						<Input
							margin='16px 16px'
							type='text'
							label={t('wms:Plant')}
							placeholder={t('wms:Plant')}
							value={newWarehouse && newWarehouse.plant}
							onChange={e => setNewWarehouse({ ...newWarehouse, plant: e.target.value })}
							data-cy='input-warehouse-plant'
						/>
						<Input
							margin='16px 16px'
							type='text'
							label={t('wms:Name')}
							placeholder={t('wms:Name')}
							value={newWarehouse && newWarehouse.name}
							onChange={e => setNewWarehouse({ ...newWarehouse, name: e.target.value })}
							data-cy='input-warehouse-name'
						/>
						<Input
							margin='16px 16px'
							type='text'
							label={t('wms:PairingSite')}
							placeholder={t('wms:PairingSite')}
							value={newWarehouse && newWarehouse.pairingsite}
							onChange={e => setNewWarehouse({ ...newWarehouse, pairingsite: e.target.value })}
							data-cy='input-warehouse-pairing-site'
						/>
					</FlexView>
					<FlexView flexDirection='row' width='100%'>
						<RadioGroup
							margin='8px 0 8px 8px'
							inline
							value={newWarehouse && newWarehouse.displayitem}
							label={t('wms:DisplayItem')}
							options={displayOptions}
							onChange={v => setNewWarehouse({ ...newWarehouse, displayitem: v })}
						/>
						<FlexView flexDirection='column' margin='8px 0 8px 16px'>
							<FlexView fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
								{t('wms:EquipmentManagement')}
							</FlexView>
							<Checkbox
								style={{ display: 'flex', whiteSpace: 'break-spaces' }}
								label={t('wms:DisplayAllInstruments')}
								checked={displayAllInstruments}
								onChange={v => handleDisplayEquipments(v)}
							/>
						</FlexView>
					</FlexView>

					<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 8px'>
						{t('wms:Levels')}
					</FlexView>
					<FlexView
						flexDirection='row'
						width='100%'
						height='45%'
						maxHeight='45%'
						style={{ overflowY: 'scroll', position: 'relative' }}
						flexWrap='wrap'
						margin='0 0 8px 0'>
						{newWarehouse &&
							_.map(newWarehouse.levels, (level, index) => (
								<Card key={index} data-cy='card-levels'>
									<FlexView fontSize='small' fontWeight='bold' color='metalic' width='100%'>
										{`${t('wms:Level')}`} {index}
									</FlexView>
									<Input
										type='text'
										label={t('wms:Description')}
										placeholder={t('wms:Description')}
										value={level.desc}
										onChange={e => addLevelDesc(e.target.value, index)}
										data-cy='input-level-description'
									/>
									<FlexView fontSize='medium' fontWeight='bold'>{`${t('wms:CanMove')}`}</FlexView>
									<Toggle
										textOff={t('wms:No')}
										textOn={t('wms:Yes')}
										alternate
										state={level.canmove}
										onChange={v => changeMoveProp(v, index)}></Toggle>
									<Icon
										name='trash'
										margin='auto 0 0 auto'
										width='14px'
										height='14px'
										color='error'
										fontWeight='bold'
										onClick={() => deleteLevel(index)}
									/>
								</Card>
							))}
					</FlexView>
					<FlexView
						flexDirection='row'
						width='100%'
						margin='auto auto 0 0'
						alignItems='flex-end'
						justifyContent='flex-end'>
						<Button
							disabled={newWarehouse && newWarehouse.levels.length >= 6}
							backgroundColor='secondary'
							color='white'
							margin='8px 8px 0 0'
							onClick={() => addLevel()}
							data-cy='button-add-level'>
							{t('wms:Add')} {t('wms:Level')}
							<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
						</Button>
						<Button
							disabled={checkFields()}
							margin='8px 8px 0 0'
							backgroundColor='success'
							color='white'
							onClick={() => handleSaveWarehouse()}
							data-cy='button-save-warehouse'>
							{t('wms:Save')}
							<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
						</Button>
						<LoadingOverlay visible={saving} borderRadius='card' />
					</FlexView>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default AddWarehouseModal
