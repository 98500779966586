/** @format */

import { PerInspContext } from 'apps/wms/stores/PerInspStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, FlexView, LoadingOverlay } from 'components/common'
import { Select } from 'components/form'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { _ } from 'globalthis/implementation'
import moment from 'moment'

const PeriodicInspectionFilters = () => {
	const {
		loadingFilters,
		dateSelected,
		setDateSelected,
		datesToFilter,
		rackName,
		inspections,
		setFilteredInspections,
		setSelectedInspection,
		setSummarized,
		now,
		nextThirtyDays
	} = useContext(PerInspContext)
	const { t, i18n } = useTranslation()

	const listDeadSpace = 337
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const handleIncomingInsp = () => {
		setSelectedInspection(null)
		setSummarized(null)
		let filtered = inspections
			? _.filter(inspections, insp => {
					let date = insp.nextinspdate
					return moment(date).format() < nextThirtyDays && moment(date).format() >= now
			  })
			: null

		setFilteredInspections(
			_.sortBy(filtered, insp => {
				return insp.entranceid
			})
		)
	}

	const handlePastDueInsp = () => {
		setSelectedInspection(null)
		setSummarized(null)
		let filtered = _.filter(inspections, insp => {
			let date = insp.nextinspdate
			return moment(date).format() < now
		})
		setFilteredInspections(
			_.sortBy(filtered, insp => {
				return insp.entranceid
			})
		)
	}

	const handleClick = () => {
		setFilteredInspections(null)
		setSelectedInspection(null)
		setSummarized(null)
		if (dateSelected) {
			let dateToFilter
			_.forEach(datesToFilter, dates => {
				let date = dates
				if (date.value === dateSelected) dateToFilter = moment(date.label).format()
			})
			let filteredByDate = _.filter(inspections, insp => {
				let date = insp.entrancedate
				return moment(date).format() <= dateToFilter
			})
			setFilteredInspections(filteredByDate)
		}
	}

	const handleClear = () => {
		setDateSelected(null)
	}

	const handleAllInsp = () => {
		setFilteredInspections(
			_.sortBy(inspections, insp => {
				return insp.entranceid
			})
		)
	}

	return (
		<FlexView
			width='20%'
			margin='0 16px 0 0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='flexview-perinsp-filters'>
			<LoadingOverlay visible={loadingFilters} />
			<FlexView width='100%' fontSize='subtitle' fontWeight='bold' margin='0 0 8px 0'>
				{t('wms:Filters')}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`}>
				<Button
					color='primary'
					width='100%'
					justifyContent='flex-start'
					backgroundColor='offWhite'
					margin='0px 0px 8px'
					padding='0'
					elevation='none'
					fontWeight='bold'
					fontSize='small'
					style={{ cursor: 'pointer' }}
					onClick={() => handleIncomingInsp()}
					data-cy='button-incoming-inspections'>
					{t('wms:IncomingInspections')}
					<FlexView margin='0 0 0 8px' fontSize='tiny'>{`[${t('wms:Next30Days')}]`}</FlexView>
				</Button>
				<Button
					color='primary'
					width='100%'
					justifyContent='flex-start'
					backgroundColor='offWhite'
					margin='0px 0px 8px'
					padding='0'
					elevation='none'
					fontWeight='bold'
					fontSize='small'
					onClick={() => handlePastDueInsp()}
					data-cy='button-pastdue-inspections'>
					{t('wms:PastDueInspections')}
				</Button>
				<Button
					color='primary'
					width='100%'
					justifyContent='flex-start'
					backgroundColor='offWhite'
					margin='0px 0px 8px'
					padding='0'
					elevation='none'
					fontWeight='bold'
					fontSize='small'
					onClick={() => handleAllInsp()}
					data-cy='button-allinspections'>
					{t('wms:AllInspections')}
				</Button>
				<FlexView fontSize='small' color='metalic' fontWeight='bold' margin='16px 0 8px'>
					{t('wms:EntranceDateThreshold')}
				</FlexView>
				<Select
					placeholder={t('wms:SelectDate')}
					value={dateSelected}
					options={
						datesToFilter
							? _.map(datesToFilter, dates => {
									return {
										value: dates.value,
										label: moment.locale(`${i18n.language}`) && moment(dates.label).format('L')
									}
							  })
							: []
					}
					onChange={value => setDateSelected(value)}
					width='calc(100%  - 4px)'
					margin='0'
					padding='0'
					searchable
					data-cy='select-date'></Select>
				<FlexView flexDirection='row' margin='8px 0' justifyContent='space-around' width='100%'>
					<Button
						style={{ alignSelf: 'center' }}
						fontSize='small'
						margin='0'
						onClick={() => handleClear()}
						data-cy='button-clear-filter'>
						{t('wms:ClearFilter')}
					</Button>
					<Button
						color='white'
						backgroundColor='secondary'
						style={{ alignSelf: 'center' }}
						fontSize='small'
						disabled={!(dateSelected || rackName)}
						onClick={() => handleClick()}
						data-cy='button-apply-filter'>
						{t('wms:ApplyFilter')}
					</Button>
				</FlexView>
			</FlexView>
		</FlexView>
	)
}

export default PeriodicInspectionFilters
