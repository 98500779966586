/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import settings from '../api/assetsSettings'
import { toast } from 'react-toastify'

export const AssetsSettingsContext = React.createContext()

export const AssetsSettingsProvider = ({ children }) => {
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const [loadingPerInspCfg, setLoadingPerInspCfg] = useState(false)
	const [savePerInsp, setSavePerInsp] = useState(false)
	const [perInspStg, setPerInspStg] = useState({
		frequence: null,
		sampling: null
	})

	const perInspConfig = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				settings
					.perInspConfig(warehouse.id, token)
					.then(response => {
						setPerInspStg({ frequence: response.frequence, sampling: response.sampling })
						resolve()
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorPerInspConfig')} [${e.status}]: ${e.data}`)
					})
		})

	const savePerInspConfig = () =>
		new Promise((resolve, reject) => {
			let payload = {
				frequence: perInspStg.frequence,
				sampling: perInspStg.sampling
			}
			settings
				.savePerInspConfig(warehouse.id, payload, token)
				.then(response => {
					toast.success(t('wms:SettingsSavePeriodic'))
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorSavingPeriodic')} [${e.status}]: ${e.data}`)
				})
		})

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingPerInspCfg(true)
				await perInspConfig()
			} catch (e) {
				setLoadingPerInspCfg(false)
				console.error(e)
			} finally {
				setLoadingPerInspCfg(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (savePerInsp) {
			const hydrate = async () => {
				try {
					await savePerInspConfig()
				} catch (e) {
					setSavePerInsp(false)
					console.error(e)
				} finally {
					setSavePerInsp(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savePerInsp])

	const renderStore = (
		<AssetsSettingsContext.Provider
			value={{
				perInspStg,
				setPerInspStg,
				loadingPerInspCfg,
				setLoadingPerInspCfg,
				savePerInsp,
				setSavePerInsp
			}}>
			{children}
		</AssetsSettingsContext.Provider>
	)
	return renderStore
}
