/** @format */

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'
import { Select } from 'components/form'
import DialogModal from 'apps/wms/components/forms/DialogModal'

import { MovementContext } from '../stores/MovementStore'
import PageTemplate from '../containers/common/PageTemplate'
import DualListSelect from '../components/forms/DualListSelect/DualListSelect'
import { useWindowSize } from '../utils/hooks'
import { INSPECTION_TYPE } from '../utils/enum'
import { WMSContext } from '../stores/WmsStore'
import { toast } from 'react-toastify'

const MovementPage = () => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const {
		sourceRack,
		setSourceRack,
		targetRack,
		setTargetRack,
		sourceRackList,
		availablePipes,
		selectedPipes,
		setSelectedPipes,
		loadingPipes,
		moveItems,
		movingItems,
		openConfirmModal,
		setConfirmModal,
		getRackName,
		rackOccupation,
		qntyTargetRack,
		loadingRackOccupation,
		selectedType,
		setSelectedType,
		reports,
		selectedReport,
		setSelectedReport,
		loadingReports,
		setAvailablePipes,
		materialList,
		selectedMaterial,
		setSelectedMaterial,
		filteredPipes,
		setFilteredPipes,
		targetRackList,
		filteredRacks,
		setFilteredRacks,
		filteredMaterials,
		setFilteredMaterials
	} = useContext(MovementContext)
	const [sourceFilter, setSourceFilter] = useState(null)

	const listDeadSpace = 160
	const colDeadSpace = 625
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const [cardHeight, setCardHeight] = useState(0)
	const [totalPipes, setTotalPipes] = useState(0)
	const listRef = useRef(null)
	const columnRef = useRef(null)

	useEffect(() => {
		setCardHeight(columnRef.current.offsetHeight - colDeadSpace)
	}, [columnRef, window])

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		setAvailablePipes([])
		setFilteredPipes([])
		setSelectedMaterial(null)
		setSelectedReport(null)
		setSelectedType(null)
		setSourceRack(null)
		setSelectedPipes([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceFilter])

	useEffect(() => {
		setSourceFilter(null)
	}, [warehouse])

	useEffect(() => {
		setAvailablePipes([])
		setSelectedReport(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType])

	const sourceType = [
		{
			value: 'RACK',
			label: t('wms:Rack')
		},
		{
			value: 'REPORTS',
			label: t('wms:Reports')
		}
	]

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Movement'),
				path: '/wms/manage/move'
			}
		],
		[t]
	)

	const clearFilter = () => {
		setSourceRack(null)
		setSelectedMaterial(null)
		setFilteredRacks([])
		setFilteredMaterials([])
		setFilteredPipes([])
	}

	const handleTypeOfFilterFieldChange = value => {
		setTotalPipes(0)
		setSourceFilter(value)
	}

	const handleMaterialFieldChange = value => {
		setTotalPipes(0)
		setSelectedMaterial(value)
	}

	const handleReportFieldChange = value => {
		setTotalPipes(0)
		setSelectedReport(value)
	}

	const handleReportTypeFieldChange = value => {
		setTotalPipes(0)
		setSelectedType(value)
	}

	const countSelectedPipes = value => {
		setSelectedPipes(value)
		setTotalPipes(value.length)
	}

	const movePipes = () => {
		sourceFilter === 'RACK'
			? moveItems(sourceFilter, sourceRack, targetRack, selectedPipes, null)
			: moveItems(sourceFilter, null, targetRack, selectedPipes, selectedReport)
	}

	const renderMessageError = () => {
		return selectedReport !== null && availablePipes.length ? null : toast.error(t('wms:NoAvailablePipesReport'))
	}

	return (
		<PageTemplate crumbs={crumbs} loadingOverlay={movingItems}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView flexDirection='row' minWidth='100%' fontSize='40px' fontWeight='bold'>
					{t('wms:Movement')}
					<Select
						width='max-content'
						inline
						placeholder={t('wms:TypeOfFilter')}
						label={t('wms:TypeOfFilter')}
						value={sourceFilter}
						options={sourceType}
						onChange={value => handleTypeOfFilterFieldChange(value)}
						margin='0 0 0 auto'
					/>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView flex='1' height='100%' maxWidth='450px' margin='0 8px 0 0' ref={columnRef}>
						<Card margin='0 0 16px 0' width='calc(100% - 48px)' data-cy='card-source-rack'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:Source')}
							</FlexView>
							<Select
								searchable
								label={`${t('wms:Rack')}:`}
								placeholder={t('wms:SelectRack')}
								value={sourceRack}
								options={
									filteredRacks.length
										? filteredRacks.map(rack => ({
												label: rack.fullname,
												value: rack.id
										  }))
										: sourceRackList.map(rack => ({
												label: rack.fullname,
												value: rack.id
										  }))
								}
								onChange={value => {
									setSourceRack(value)
								}}
								width='100%'
								margin='0'
								disabled={sourceFilter !== 'RACK'}
								data-cy='select-source-rack'
							/>
							{sourceFilter === 'RACK' && (
								<Select
									width='100%'
									searchable
									label={`${t('wms:Material')}:`}
									placeholder={t('wms:Material')}
									value={selectedMaterial}
									options={
										filteredMaterials && filteredMaterials.length
											? filteredMaterials.map(material => ({
													label: material.formatted,
													value: material.id
											  }))
											: materialList &&
											  materialList.map(material => ({
													label: material.formatted,
													value: material.id
											  }))
									}
									onChange={value => handleMaterialFieldChange(value)}
									margin='8px 0 0 0'
								/>
							)}
							<Select
								width='100%'
								searchable
								label={`${t('wms:ReportType')}:`}
								placeholder={t('wms:ReportType')}
								value={selectedType}
								options={INSPECTION_TYPE.translateKey.map((type, index) => ({
									value: index,
									label: t(type)
								}))}
								onChange={value => handleReportTypeFieldChange(value)}
								margin='8px 0 0 0'
								disabled={sourceFilter !== 'REPORTS'}
							/>
							<>
								<LoadingOverlay visible={loadingReports} borderRadius='card' />
								{reports && (
									<Select
										width='100%'
										searchable
										label={`${t('wms:Reports')}: `}
										placeholder={t('wms:Reports')}
										value={selectedReport}
										options={reports.map(report => ({
											value: report.id,
											label: report.name
										}))}
										onChange={value => handleReportFieldChange(value)}
										margin='0'
										disabled={selectedType == null || sourceFilter === 'RACK'}
									/>
								)}
							</>
							{sourceFilter === 'RACK' && (
								<FlexView width='100%' justifyContent='center' alignItems='center' margin='auto 0 0 0'>
									<Button margin='0' disabled={!sourceRack || !selectedMaterial} onClick={() => clearFilter()}>
										{t('wms:ClearFilter')}
										<Icon name='cross-simple' width='12px' height='12px' margin='0 0 0 8px' />
									</Button>
								</FlexView>
							)}
						</Card>
						<Card width='calc(100% - 48px)' margin='0 0 16px 0' data-cy='card-target-rack'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:Target')}
							</FlexView>
							<Select
								searchable
								placeholder={t('wms:SelectRack')}
								value={targetRack}
								options={targetRackList
									.filter(rack => rack.id !== sourceRack)
									.map(rack => ({
										label: rack.fullname,
										value: rack.id
									}))}
								onChange={value => {
									setTargetRack(value)
								}}
								width='100%'
								inline={true}
								margin='0'
								data-cy='select-target-rack'
							/>
						</Card>
						{targetRack !== null ? (
							<Card
								width='calc(100% - 48px)'
								margin='0'
								data-cy='card-occupation-rack'
								style={{ position: 'relative' }}>
								<LoadingOverlay visible={loadingRackOccupation} borderRadius='card' />
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
									{getRackName(targetRack)} - {t('wms:RackOccupation')}: {qntyTargetRack} {t('wms:Pipes')}
								</FlexView>
								<FlexView
									width='100%'
									fontSize='small'
									color='metalic'
									flexDirection='row'
									justifyContent='space-between'
									margin='0 0 16px 0'>
									<span>{t('wms:Material')}</span>
									<span>{t('wms:Quantity')}</span>
								</FlexView>
								<FlexView width='100%' height={`${cardHeight}px`} margin='0' style={{ overflowY: 'scroll' }}>
									{rackOccupation && rackOccupation.length
										? rackOccupation.map(item => (
												<FlexView key={item.mid} width='100%' flexDirection='row' margin='0 0 16px 0'>
													<FlexView fontSize='small' width='80%'>
														{item.formatted}
													</FlexView>
													<FlexView fontSize='small' width='20%' alignItems='flex-end'>
														{item.quantity}
													</FlexView>
												</FlexView>
										  ))
										: null}
								</FlexView>
							</Card>
						) : null}
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						<Card
							width='calc(100% - 48px)'
							flex='1'
							margin='0'
							style={{ position: 'relative' }}
							data-cy='card-pipes-list'
							ref={listRef}>
							<FlexView style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
									{t('wms:AvailablePipes')}
								</FlexView>
								<FlexView
									width='500px'
									fontSize='medium'
									fontWeight='bold'
									margin='0 0 16px 0'
									alignItems='flex-end'>
									{t('wms:TotalSelectedPipes') + `: ${totalPipes}`}
								</FlexView>
							</FlexView>
							<LoadingOverlay visible={loadingPipes} borderRadius='card' />
							{sourceFilter === 'RACK' && filteredPipes.length ? (
								<FlexView flex='1' margin='0' width='100%'>
									<DualListSelect
										searchable={true}
										width='100%'
										height={`${listHeight}px`}
										margin='0'
										selectedValues={selectedPipes}
										options={filteredPipes.map(pipe => ({
											label: pipe.id,
											value: pipe.id
										}))}
										onChange={value => {
											countSelectedPipes(value)
										}}
										data-cy='dualselect-pipes-list'
										leftLabel={t('wms:Source')}
										rightLabel={t('wms:Target')}
									/>
								</FlexView>
							) : sourceFilter === 'REPORTS' && availablePipes.length ? (
								<FlexView flex='1' margin='0' width='100%'>
									<DualListSelect
										searchable={true}
										width='100%'
										height={`${listHeight}px`}
										margin='0'
										selectedValues={selectedPipes}
										options={availablePipes.map(pipe => ({
											label: pipe.id,
											value: pipe.id
										}))}
										onChange={value => {
											countSelectedPipes(value)
										}}
										data-cy='dualselect-pipes-list'
										leftLabel={t('wms:Source')}
										rightLabel={t('wms:Target')}
									/>
								</FlexView>
							) : (
								<>
									{renderMessageError}
									<FlexView
										width='100%'
										fontWeight='bold'
										fontSize='24px'
										color='lightGray'
										margin='auto 0'
										alignItems='center'
										justifyContent='center'
										data-cy='placeholder-pipes-list'>
										{t('wms:NoAvailablePipes')}
									</FlexView>
								</>
							)}

							<Button
								fontSize='medium'
								margin='0 0 0 auto'
								color='white'
								backgroundColor='secondary'
								disabled={
									sourceFilter === 'RACK'
										? !(!!sourceRack && !!targetRack && selectedPipes.length !== 0)
										: !(!!targetRack && selectedPipes.length !== 0 && !!selectedReport)
								}
								onClick={() => {
									setConfirmModal(true)
								}}
								data-cy='button-move-pipes'>
								{t('wms:MoveItems')}
							</Button>
						</Card>
					</FlexView>
				</FlexView>
			</FlexView>
			<DialogModal
				isOpen={openConfirmModal}
				text={t(`wms:ConfirmMove`, { source: getRackName(sourceRack), target: getRackName(targetRack) })}
				onConfirm={movePipes}
				onCancel={() => setConfirmModal(false)}
				data-cy='dialog-confirm-move'
			/>
		</PageTemplate>
	)
}

export default MovementPage
