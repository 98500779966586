/** @format */

import { FlexView } from 'components/common'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Filters from '../containers/periodic/Filters'
import PageTemplate from '../containers/common/PageTemplate'
import PerInspList from '../containers/periodic/List'
import PerInspInfo from '../containers/periodic/Info'
import { PerInspContext } from '../stores/PerInspStore'
import { genParams, inspection } from '../utils/hubs'

const PerInspPage = () => {
	const { t } = useTranslation()
	const { summarized } = useContext(PerInspContext)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Inspection'),
				path: `/wms/inspection${genParams(inspection)}`
			},
			{
				name: t('wms:PeriodicInspection'),
				path: '/wms/manage/inspection/perinsp'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:PeriodicInspection')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<Filters />
					<PerInspList />
					{summarized && <PerInspInfo />}
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default PerInspPage
