/** @format */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Icon, FlexView } from 'components/common'
import { withPadding, withMargin, withFontSize, withColor } from '../../../../utils/styled-decorators'

const MessageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
	font-size: ${({ theme }) => theme.fontSizes.extraSmall};
	margin: 0px;
	font-family: 'Noto Sans';
	color: ${({ theme }) => theme.colors.gray};
	vertical-align: middle;

	.message-icon {
		${({ theme }) => css`
			min-width: ${theme.fontSizes.medium};
			width: ${theme.fontSizes.medium};
			min-height: ${theme.fontSizes.medium};
			height: ${theme.fontSizes.medium};
		`}
		margin-right: 8px;
	}
`

const TextInput = styled.input`
	${withMargin('8px 0px')}
	${withColor('black')}
  ${withFontSize('medium')}
  ${withPadding('8px 16px')}
  width: 100%;
	background-color: whitesmoke;
	color: black;
	min-height: 44px;
	border-radius: ${({ theme }) => theme.borderRadius.input};
	font-family: 'Noto Sans';
	outline: none;
	box-sizing: border-box;
	border: 2px solid ${({ theme }) => theme.colors.lightGray};
	transition: border 0.2s linear;
	${({ success, theme }) =>
		success &&
		css`
			border: 2px solid ${theme.colors.success};
		`}
	${({ warning, theme }) =>
		warning &&
		css`
			border: 2px solid ${theme.colors.warning};
		`}
  ${({ error, theme }) =>
		error &&
		css`
			border: 2px solid ${theme.colors.error};
		`}

  &::placeholder {
		color: ${({ theme }) => theme.colors.black};
		font-weight: normal;
	}
`

const Label = styled.label`
	white-space: nowrap;
	margin-right: 8px;
`

/**
 * Default input component
 */
const Input = React.forwardRef(({ label, inline, width, margin, message, messageType, ...rest }, ref) => {
	let iconName
	switch (messageType) {
		case 'error':
			iconName = 'cross'
			break
		case 'success':
			iconName = 'check'
			break
		case 'warning':
			iconName = 'warning'
			break
		case 'info':
			iconName = 'info'
			break
		default:
			iconName = ''
			break
	}

	return (
		<FlexView width={width} margin={margin}>
			<FlexView
				width={width}
				flexDirection={inline ? 'row' : 'column'}
				alignItems={inline ? 'center' : 'flex-start'}
				justifyContent='flex-start'
				fontSize='medium'
				fontWeight='bold'>
				{label && <Label>{label}</Label>}
				<TextInput {...rest} ref={ref} />
			</FlexView>
			{message && (
				<MessageWrapper>
					<Icon name={iconName} color={messageType} className='message-icon' />
					{message}
				</MessageWrapper>
			)}
		</FlexView>
	)
})

Input.propTypes = {
	/**
	 * Label that accompanies the input
	 */
	label: PropTypes.string,
	/**
	 * Defines if the label should be rendered in the same line as the input
	 */
	inline: PropTypes.bool,
	/**
	 * Auxiliary message positioned under the input
	 */
	message: PropTypes.string,
	/**
	 * Message type, responsible for adding the icon besides the message
	 */
	messageType: PropTypes.string,
	/**
	 * Color the border to indicate an error state
	 */
	error: PropTypes.bool,
	/**
	 * Color the border to indicate a warning state
	 */
	warning: PropTypes.bool,
	/**
	 * Color the border to indicate a success state
	 */
	success: PropTypes.bool,
	/**
	 * A color key defined in the theme
	 */
	color: PropTypes.string,
	/**
	 * Override CSS width property. Must be a valid CSS width value as a string. IMPORTANT: THE WIDTH IS SHARED WITH THE LABEL ON INLINE INPUTS
	 */
	width: PropTypes.string,
	/**
	 * Override CSS margin property. Must be a valid CSS margin value as a string
	 */
	margin: PropTypes.string,
	/**
	 * Override CSS padding property. Must be a valid CSS padding value as a string
	 */
	padding: PropTypes.string,
	/**
	 * A font size key defined in the theme
	 */
	fontSize: PropTypes.string
}

Input.defaultProps = {
	margin: '8px 0px'
}

export default Input
