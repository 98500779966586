/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import batch from '../api/batch'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { listStatus } from '../utils/enum'
import usageRights from '../api/usageRights'

const initialState = {
	endUser: undefined,
	material: undefined,
	customerOrder: undefined,
	customerItem: undefined,
	salesOrder: undefined,
	salesItem: undefined,
	newContent: []
}

export const BatchEntranceContext = React.createContext(initialState)

export const BatchEntranceProvider = ({ children }) => {
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const [batchList, setBatchList] = useState([])
	const [pageLoading, setPageLoading] = useState(false)
	const [batchEntrance, setBatchEntrance] = useState(null)
	const [batchContent, setBatchContent] = useState(null)
	const [expectedQuantity, setExpectedQuantity] = useState(0)
	const [endUsers, setEndUsers] = useState([])
	const [endUser, setEndUser] = useState(null)
	const [materials, setMaterials] = useState([])
	const [material, setMaterial] = useState(null)
	const [materialDesc, setMaterialDesc] = useState(null)
	const [newBatchEntrance, setNewBatchEntrance] = useState({})
	const [confirmUpdate, setConfirmUpdate] = useState(false)
	const [sourceType, setSourceType] = useState(null)
	const [customerOrder, setCustomerOrder] = useState(null)
	const [customerOrders, setCustomerOrders] = useState([])
	const [customerItems, setCustomerItems] = useState([])
	const [customerItem, setCustomerItem] = useState(null)
	const [filteredPoItems, setFilteredPoItems] = useState([])
	const [salesOrders, setSalesOrders] = useState([])
	const [salesOrder, setSalesOrder] = useState(null)
	const [salesItems, setSalesItems] = useState([])
	const [salesItem, setSalesItem] = useState(null)
	const [filteredSalesItems, setFilteredSalesItems] = useState([])
	const [newContent, setNewContent] = useState([])
	const [batchListName, setBatchListName] = useState('')
	const [expectedDate, setExpectedDate] = useState('')
	const [saveBatchList, setSaveBatchList] = useState(false)
	const [batchEntranceType, setBatchEntranceType] = useState('')
	const [batchId, setBatchId] = useState(undefined)
	const [deletedContent, setDeletedContent] = useState([])
	const [selectedBatch, setSelectedBatch] = useState(null)

	const [updateMaterial, setUpdateMaterial] = useState(false)
	const [openAddModal, setOpenAddModal] = useState(false)
	const [deleteMaterialList, setDeleteMaterialList] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)

	const [loadingEndUsers, setLoadingEndUsers] = useState(false)
	const [loadingMaterialList, setLoadingMaterialList] = useState(false)
	const [loadingOrders, setLoadingOrders] = useState(false)

	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [updateContent, setUpdateContent] = useState(null)
	const [updateContentCp, setUpdateContentCp] = useState(null)

	const [ownership, setOwnership] = useState(null)
	const [levels, setLevels] = useState(null)

	const getEndUsers = () =>
		new Promise((resolve, reject) => {
			batch
				.getEndUsers(warehouse.id, token)
				.then(response => {
					setEndUsers(response)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject()
					toast.error(t('wms:ErrorGettingEndUser'))
				})
		})

	const getMaterials = enduser =>
		new Promise((resolve, reject) => {
			batch
				.getMaterials(warehouse.id, enduser ? enduser : endUser.id, token)
				.then(response => {
					const aux = response.map(res => ({ value: res.id, label: res.desc }))
					resolve(_.uniqBy(aux, 'value'))
				})
				.catch(e => {
					console.error(e)
					reject()
					toast.error(t('wms:ErrorGettingMatListEndUser'))
				})
		})

	const getPoList = () =>
		new Promise((resolve, reject) => {
			batch
				.getPoList(warehouse.id, endUser.id, material, token)
				.then(response => {
					let poNumbers = []
					let poItems = []
					_.forEach(response, (res, i) => {
						let n = res.number
						if (!poItems[n]) {
							poNumbers.push({ value: i, label: res.number })
							poItems[n] = []
						}
						poItems[n].push({ value: i, label: res.item })
					})
					setCustomerOrders(poNumbers)
					setCustomerItems(poItems)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
				})
		})

	const getSalesList = () =>
		new Promise((resolve, reject) => {
			batch
				.getSalesList(warehouse.id, endUser.id, material, token)
				.then(response => {
					let salesNumbers = []
					let salesItems = []
					_.forEach(response, (res, i) => {
						let n = res.number
						if (!salesItems[n]) {
							salesNumbers.push({ value: i, label: n })
							salesItems[n] = []
						}
						salesItems[n].push({ value: i, label: res.item })
					})
					setSalesOrders(salesNumbers)
					setSalesItems(salesItems)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject()
				})
		})

	const getBatchEntranceList = () =>
		new Promise((resolve, reject) => {
			batch
				.getBatchEntranceLists(warehouse.id, token)
				.then(response => {
					let batch = []
					_.forEach(response, (res, i) => {
						batch.push({ id: res.id, expected: res.expected, name: res.name, content: res.content })
					})
					setBatchList(batch)
					setBatchEntrance(response)
					resolve()
				})
				.catch(e => {
					toast.error(t('wms:ErrorGettingEntranceLists'))
					reject(e)
				})
		})

	const getLevelsOwnership = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(t('wms:WarehouseNotDefined')))
			} else {
				usageRights
					.getOwnershipsList(warehouse.id, token)
					.then(response => {
						let levelList = []
						_.forEach(response.ownershipdescriptions, res => {
							res.level === 1
								? levelList.push({ id: res.idpath[0], label: res.namepath[0] })
								: _.forEach(res.idpath, (id, idx) => {
										levelList.push({ id: id, label: res.namepath[idx] })
								  })
						})
						setLevels(_.uniqBy(levelList, 'id').sort((a, b) => a.id - b.id))
						resolve()
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
					})
			}
		})

	const deleteMatList = () =>
		new Promise((resolve, reject) => {
			batch
				.deleteBatchList(warehouse.id, selectedBatch.id, token)
				.then(response => {
					_.remove(batchList, (batch, i) => batch.id === selectedBatch.id)
					setBatchList([...batchList])
					setBatchContent(null)
					resolve()
					toast.success(t('wms:SuccesfullyDeleteEntranceList'))
				})
				.catch(e => {
					console.error(e)
					toast.error(t('wms:ErrorDeletingEntranceList'))
					reject(e)
				})
				.finally(() => {
					setOpenConfirmModal(false)
				})
		})

	const reopenBatchList = () =>
		new Promise((resolve, reject) => {
			batch
				.reopenBatchList(warehouse.id, batchId, token)
				.then(response => {
					_.forEach(batchContent.content, (batch, i) => {
						if (batch.belcid === batchId) {
							batch.status = listStatus.standby
							return
						}
					})
					resolve()
					toast.success(t('wms:SuccessfullyReopenContent'))
				})
				.catch(e => {
					toast.error(t('wms:ErrorReopenContent'))
					reject(e)
				})
		})

	const saveBatchEntranceList = () =>
		new Promise((resolve, reject) => {
			batch
				.saveBatchList(
					warehouse.id,
					{
						name: batchListName,
						enduserid: endUser.id,
						mid: material,
						matdesc: materialDesc,
						customerpo: customerOrder,
						customeritem: customerItem,
						salesorder: salesOrder,
						salesitem: salesItem,
						expected: expectedDate._d,
						ownershipid: ownership,
						responsible: user.name,
						content: newContent
					},
					token
				)
				.then(response => {
					toast.success(t('wms:SuccessfullySaveEntranceList'))
					resolve()
					setOwnership(null)
				})
				.catch(e => {
					toast.error(t('wms:ErrorSavingEntranceList'))
					reject(e)
				})
		})

	const confirmUpdateBatchList = () =>
		new Promise((resolve, reject) => {
			let contentUpdated = []
			_.forEach(updateContent.content, c => {
				if (!c.belcid) contentUpdated.push(c)
			})
			_.forEach(deletedContent, c => {
				contentUpdated.push(c)
			})
			if (contentUpdated.length > 0) {
				batch
					.confirmUpdateBatchList(
						warehouse.id,
						{
							responsible: user.name,
							content: contentUpdated,
							revision: updateContent.revision,
							planrevision: updateContent.planrevision !== null ? updateContent.planrevision : 0
						},
						batchContent.id,
						token
					)
					.then(response => {
						toast.success(t('wms:SuccessfullyUpdateEntranceList'))
						resolve()
					})
					.catch(e => {
						toast.error(e.data)
						console.error(e)
						reject(e)
					})
			} else {
				toast.error(t('wms:ErrorAddNewPartEntrance'))
			}
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const batch = _.find(batchEntrance, (batch, i) => {
			return batch.id === selectedBatch.id
		})
		openUpdateModal && setUpdateContent({ ...batch, content: [...batch.content] })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUpdateModal])

	useEffect(() => {
		const batch = _.find(batchEntrance, (batch, i) => {
			return batch.id === selectedBatch.id
		})
		openUpdateModal && setUpdateContentCp(batch)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUpdateModal])

	useEffect(() => {
		setEndUser(null)
		if (sourceType) {
			const hydrate = async () => {
				try {
					setLoadingEndUsers(true)
					await getEndUsers()
				} catch (e) {
					setLoadingEndUsers(false)
					console.error(e)
				} finally {
					setLoadingEndUsers(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceType, warehouse])

	useEffect(() => {
		setMaterial(null)
		if (endUser) {
			const hydrate = async () => {
				try {
					setLoadingMaterialList(true)
					const materialList = await getMaterials()
					setMaterials(materialList)
				} catch (e) {
					setLoadingMaterialList(false)
					console.error(e)
				} finally {
					setLoadingMaterialList(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endUser])

	useEffect(() => {
		setCustomerOrder(null)
		if (material) {
			let mat = _.find(materials, m => {
				return m.value === material
			})
			setMaterialDesc(mat.label)
			const hydrate = async () => {
				try {
					setLoadingOrders(true)
					await getPoList()
				} catch (e) {
					setLoadingOrders(false)
					console.error(e)
				} finally {
					setLoadingOrders(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [material])

	useEffect(() => {
		setCustomerItem(null)
		setFilteredPoItems([])
		if (customerOrder) {
			setFilteredPoItems(customerItems[customerOrder])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerOrder])

	useEffect(() => {
		setSalesOrder(null)
		if (material) {
			const hydrate = async () => {
				try {
					setLoadingOrders(true)
					await getSalesList()
				} catch (e) {
					setLoadingOrders(false)
					console.error(e)
				} finally {
					setLoadingOrders(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [material])

	useEffect(() => {
		setSalesItems(null)
		setFilteredSalesItems([])
		if (salesOrder) {
			setFilteredSalesItems(salesItems[salesOrder])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [salesOrder])

	useEffect(() => {
		if (saveBatchList) {
			const hydrate = async () => {
				try {
					setPageLoading(true)
					await saveBatchEntranceList()
					setOpenAddModal(false)
					setSourceType(null)
					setNewContent([])
					setBatchListName('')
					setExpectedDate(null)
					setExpectedQuantity(0)
				} catch (e) {
					console.error(e)
					setSaveBatchList(false)
				} finally {
					setPageLoading(false)
					setSaveBatchList(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveBatchList])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setPageLoading(true)
				await getBatchEntranceList()
			} catch (e) {
				setPageLoading(false)
				console.error(e)
			} finally {
				setPageLoading(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setPageLoading(true)
				setSelectedBatch(null)
				setBatchContent(null)
				await getBatchEntranceList()
			} catch (e) {
				setPageLoading(false)
				console.error(e)
			} finally {
				setPageLoading(false)
			}
		}
		;(!saveBatchList || !confirmUpdate) && hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveBatchList, confirmUpdate, warehouse])

	useEffect(() => {
		if (batchId) {
			const hydrate = async () => {
				try {
					await reopenBatchList()
				} catch (e) {
					console.error(e)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [batchId])

	useEffect(() => {
		if (selectedBatch) {
			const batch = _.find(batchEntrance, (batch, i) => {
				return batch.id === selectedBatch.id
			})
			setBatchContent(batch)
		}
	}, [batchEntrance, selectedBatch])

	/* useEffect(() => {
        if(updatedContent && updatedContent.length > 0) {
            batchContent.content.push(updatedContent)
        }
    },[]) */

	useEffect(() => {
		if (confirmUpdate) {
			const hydrate = async () => {
				try {
					confirmUpdateBatchList()
				} catch (e) {
					setConfirmUpdate(false)
					console.error(e)
				} finally {
					setConfirmUpdate(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [confirmUpdate])

	useEffect(() => {
		setOwnership(null)
		setEndUser(null)
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getLevelsOwnership()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const renderStore = (
		<BatchEntranceContext.Provider
			value={{
				batchList,
				setBatchList,
				pageLoading,
				setPageLoading,
				batchContent,
				setBatchContent,
				expectedQuantity,
				setExpectedQuantity,
				confirmUpdate,
				setConfirmUpdate,
				sourceType,
				setSourceType,
				endUsers,
				setEndUsers,
				endUser,
				setEndUser,
				materials,
				setMaterials,
				material,
				setMaterial,
				customerOrder,
				setCustomerOrder,
				customerOrders,
				setCustomerOrders,
				customerItems,
				setCustomerItems,
				customerItem,
				setCustomerItem,
				filteredPoItems,
				setFilteredPoItems,
				salesOrder,
				setSalesOrder,
				salesItems,
				setSalesItems,
				salesItem,
				setSalesItem,
				salesOrders,
				setSalesOrders,
				filteredSalesItems,
				setFilteresSalesItems: setFilteredSalesItems,
				newBatchEntrance,
				setNewBatchEntrance,
				newContent,
				setNewContent,
				batchListName,
				setBatchListName,
				expectedDate,
				setExpectedDate,
				saveBatchList,
				setSaveBatchList,
				batchEntranceType,
				setBatchEntranceType,
				batchId,
				setBatchId,
				deletedContent,
				setDeletedContent,
				updateMaterial,
				setUpdateMaterial,
				openAddModal,
				setOpenAddModal,
				deleteMatList,
				deleteMaterialList,
				setDeleteMaterialList,
				openConfirmModal,
				setOpenConfirmModal,
				materialDesc,
				loadingEndUsers,
				loadingMaterialList,
				loadingOrders,
				updateContent,
				setUpdateContent,
				openUpdateModal,
				setOpenUpdateModal,
				updateContentCp,
				setUpdateContentCp,
				selectedBatch,
				setSelectedBatch,
				getEndUsers,
				ownership,
				setOwnership,
				getMaterials,
				levels,
				setLevels
			}}>
			{children}
		</BatchEntranceContext.Provider>
	)
	return renderStore
}
