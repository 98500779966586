/** @format */

import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { withElevation } from 'utils/styled-decorators'
import { useCallback } from 'react'
import { FlexView, Icon } from 'components/common'

const Wrapper = styled(FlexView)`
	${withElevation('low')}
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 8px 0px;
	border-radius: 8px;
	overflow: auto;
`

const Option = styled.div`
	padding: 8px;
	min-height: fit-content;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: all 0.2s ease;
	user-select: none;
	${({ theme, isSelected }) => isSelected && `background-color: ${theme.colors.lightGray};`}

	&:hover {
		background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.lightGray : theme.colors.background)};
	}
`

const Label = styled.label`
	font-size: ${({ theme }) => theme.fontSizes.medium};
	margin: 8px;
	padding: 0px 0px 4px 0px;
	white-space: nowrap;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.metalic};
	border-bottom: 1px solid;
	border-color: ${({ theme }) => theme.colors.lightGray};
	min-width: 33%;
	width: fit-content;
`

const List = ({ options, onCommit, selectedIndex, setSelectedIndex, label }) => {
	const wrapperRef = useRef(null)
	const currentOptionRef = useRef(null)

	const handleKeyDown = useCallback(
		e => {
			if (selectedIndex !== null) {
				switch (e.key) {
					case 'Escape':
						setSelectedIndex(null)
						break
					case 'Enter':
						const selectedValue = _.get(options, `${selectedIndex}.value`)
						selectedValue && onCommit(selectedValue)
						break
					case 'ArrowDown':
						setSelectedIndex(currentIndex => (options.length > currentIndex + 1 ? currentIndex + 1 : 0))
						break
					case 'ArrowUp':
						setSelectedIndex(currentIndex => (currentIndex > 0 ? currentIndex - 1 : options.length - 1))
						break
					default:
						selectedIndex >= options.length && setSelectedIndex(0)
						return
				}
				e.preventDefault()
			}
		},
		[options, selectedIndex, onCommit, setSelectedIndex]
	)

	const onOptionClick = index => () => {
		setSelectedIndex(index)
	}

	const onOptionDoubleClick = () => {
		const selectedValue = _.get(options, `${selectedIndex}.value`)
		selectedValue && onCommit(selectedValue)
	}

	useEffect(() => {
		const handleOutsideClick = e => {
			wrapperRef && !wrapperRef.current.contains(e.target) && setSelectedIndex(null)
		}

		document.addEventListener('click', handleOutsideClick)
		document.addEventListener('keydown', handleKeyDown, false)

		return () => {
			document.removeEventListener('click', handleOutsideClick)
			document.removeEventListener('keydown', handleKeyDown, false)
		}
	}, [wrapperRef, handleKeyDown, setSelectedIndex])

	useEffect(() => {
		currentOptionRef.current && currentOptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
	}, [selectedIndex])

	useEffect(() => {
		options && selectedIndex >= options.length && setSelectedIndex(options.length - 1)
	}, [selectedIndex, options, setSelectedIndex])

	return (
		<Wrapper alignItems='stretch' elevation='low' ref={wrapperRef}>
			{label && <Label>{label}</Label>}
			{_.map(options, ({ value, label }, index) => {
				const isSelected = selectedIndex === index

				return (
					<Option
						key={value}
						isSelected={isSelected}
						onClick={!isSelected ? onOptionClick(index) : undefined}
						onDoubleClick={isSelected ? onOptionDoubleClick : undefined}
						ref={isSelected ? currentOptionRef : null}
						onKeyDown={handleKeyDown}
						title={label}>
						{label}
					</Option>
				)
			})}
			{options.length === 0 && (
				<FlexView alignItems='center' justifyContent='center' height='100%'>
					<Icon name='box' color='lightGray' width='48px' height='48px' />
				</FlexView>
			)}
		</Wrapper>
	)
}

export default List
