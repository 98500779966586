/** @format */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay } from 'components/common'
import { REPORT_STATUS, REPORT_TYPE } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { isAgeManagementReport, isReceiptNoteReport } from '../reportEditor/businessRules'
import theme from 'utils/theme'

const ReviewReportInfo = ({
	reportInfo,
	reviewType,
	onCheckReport,
	onDownloadReport,
	loadingInfo = false,
	extractName,
	downloadingReport
}) => {
	const { t, i18n } = useTranslation()

	const handleDownloadReport = item => {
		item != null && onDownloadReport(item)
	}

	const handleCheckReport = item => {
		item != null && onCheckReport(item)
	}

	const getStatus = () => {
		let initialStatus = ''
		if (reportInfo.status === REPORT_STATUS.code.PENDING && reportInfo.validationdate)
			initialStatus = t(`wms:Updated`)
		else initialStatus = t(`${REPORT_STATUS.translateKey[reportInfo.status]}`)

		if (
			(reportInfo.status === REPORT_STATUS.code.PENDING && reportInfo.validationdate) ||
			reportInfo.status === REPORT_STATUS.code.VALIDATED ||
			reportInfo.status === REPORT_STATUS.code.REJECTED
		)
			initialStatus += ` ${t('wms:By')} ${reportInfo.validationresp} ${t('wms:On')} ${DATE_FORMAT(
				reportInfo.validationdate,
				i18n.language
			)}`
		return initialStatus
	}

	const renderButtons = item => (
		<>
			{item.status === REPORT_STATUS.code.VALIDATED ? (
				<Button
					width='100%'
					fontSize='tiny'
					margin='auto 0 0 0'
					color='white'
					backgroundColor='primary'
					disabled={false}
					onClick={() => {
						handleDownloadReport(item)
					}}
					data-cy='button-download-report'>
					{t('wms:DownloadReport')}
				</Button>
			) : null}
			{item.status === REPORT_STATUS.code.PENDING || item.status === REPORT_STATUS.code.REJECTED ? (
				<Button
					width='100%'
					fontSize='tiny'
					margin='auto 0 0 0'
					color='white'
					backgroundColor='secondary'
					title={isAgeManagementReport(reviewType) ? '' : t('wms:AvailableSoon')}
					onClick={() => {
						handleCheckReport(item.id)
					}}
					data-cy='button-check-report'>
					{t('wms:CheckReport')}
				</Button>
			) : null}
			<LoadingOverlay visible={downloadingReport} />
		</>
	)

	const ageManagementInfo = (
		<>
			<FlexView
				data-cy='flexview-age-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Id')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.id}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-age-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:ThresholdDate')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{DATE_FORMAT(reportInfo.extractiondate, i18n.language)}
				</FlexView>
			</FlexView>
		</>
	)

	const millReceiptInfo = (
		<>
			<FlexView
				data-cy='flexview-mill-info'
				width='66%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Name')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.name}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-mill-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Id')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.id}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-mill-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:EntranceDate')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{DATE_FORMAT(reportInfo.closuredate, i18n.language)}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-mill-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Revision')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.revision}
				</FlexView>
			</FlexView>
			{reportInfo && reportInfo.customerpo && reportInfo.customeritem ? (
				<>
					<FlexView
						data-cy='flexview-mill-info'
						width='66%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:CustomerOrder')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.customerpo}
						</FlexView>
					</FlexView>
					<FlexView
						data-cy='flexview-mill-info'
						width='33%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:CustomerItem')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.customeritem}
						</FlexView>
					</FlexView>
				</>
			) : null}
			{reportInfo && reportInfo.salesorder && reportInfo.salesitem ? (
				<>
					<FlexView
						data-cy='flexview-mill-info'
						width='66%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:SalesOrder')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.salesorder}
						</FlexView>
					</FlexView>
					<FlexView
						data-cy='flexview-mill-info'
						width='33%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:SalesItem')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.salesitem}
						</FlexView>
					</FlexView>
				</>
			) : null}
		</>
	)

	const info = () => {
		if (isReceiptNoteReport(reviewType)) return millReceiptInfo
		else return ageManagementInfo
	}

	return (
		<Card data-cy='card-info-wrapper' width='calc(100% - 8px)' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingInfo} />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:ReportDetails')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView
					width='100%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'
					fontWeight='bold'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{isReceiptNoteReport(reviewType) ? t('wms:Type') : t('wms:ExtractName')}
					</FlexView>
					<FlexView
						width='100%'
						fontWeight='bold'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{isReceiptNoteReport(reviewType) ? t(REPORT_TYPE.translateKey[reviewType]) : extractName}
					</FlexView>
				</FlexView>
				{info()}
				<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column' fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						Status
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{getStatus()}
					</FlexView>
				</FlexView>
			</FlexView>
			{renderButtons(reportInfo)}
		</Card>
	)
}

export default ReviewReportInfo
