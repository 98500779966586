/** @format */

import axios from 'axios'

import { MRType } from '../utils/enum'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getRigPrepList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/rigprep/${wid}/list`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.rigpreplist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getTPartyWorkList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/tpartywork/${wid}/list`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.tpartyworklist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMatModifList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/matmodif/${wid}/list`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.matmodiflist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPhysTransferList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/phystrans/${wid}/list`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.phystranslist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	uploadMRFile: (wid, file, mrType, token) =>
		new Promise((resolve, reject) => {
			let mrPath = MRType.path[mrType]
			instance
				.post(
					`/${mrPath}${wid}/savepdf`,
					{ mrfile: file },
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.filename)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	newMR: (wid, payload, mrType, token) =>
		new Promise((resolve, reject) => {
			let mrPath = MRType.path[mrType]
			instance
				.post(`/${mrPath}${wid}/list`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else if (response.status === 422) reject(new Error('wms.RigPrepListMultipleMaterials'))
					else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateMR: (wid, mrid, payload, mrType, token) =>
		new Promise((resolve, reject) => {
			let mrPath = MRType.path[mrType]
			instance
				.post(`/${mrPath}${wid}/list/${mrid}`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else if (response.status === 422) reject(new Error('wms.RigPrepListMultipleMaterials'))
					else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteMR: (wid, mrid, mrType, token) =>
		new Promise((resolve, reject) => {
			let mrPath = MRType.path[mrType]
			instance
				.delete(`/${mrPath}${wid}/list/${mrid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getReports: (wid, mrType, token) =>
		new Promise((resolve, reject) => {
			let mrPath = MRType.path[mrType]
			instance
				.get(`/${mrPath}${wid}/reports`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getDeliveryNotes: (wid, mrType, token) =>
		new Promise((resolve, reject) => {
			let mrPath = MRType.path[mrType]
			instance
				.get(`/${mrPath}${wid}/${MRType.notes[mrType]}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getDeliveryNotePdfData: (wid, mrType, reportId, ownershipId, language, poNumber, token) =>
		new Promise((resolve, reject) => {
			let mrPath = MRType.path[mrType]
			instance
				.get(`/${mrPath}${wid}/deliverynote/${reportId}/${ownershipId}/${language}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					params: {
						ponumber: poNumber,
						pdf: true
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenMR: (wid, payload, path, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/${path}${wid}/release`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	openMRFile: (mrPath, wid, mrid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`${mrPath}${wid}/downloadpdf/${mrid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
