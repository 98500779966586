/** @format */

import React, { useContext } from 'react'
import Select from 'components/form/Select'
import FlexView from 'components/common/FlexView'
import { useTranslation } from 'react-i18next'
import { Card, Icon, LoadingOverlay } from 'components/common'
import { Button } from 'components/common'
import _ from 'lodash'
import { ContentContext } from 'apps/wms/stores/ContentStore'
import { Input } from 'components/form'

const MaterialFilter = ({ height }) => {
	const {
		materials,
		setResult,
		loadingMatFilter,
		material,
		setMaterial,
		setErpResult,
		setFilteredMvid,
		setSearch,
		minLength,
		setMinLength,
		maxLength,
		setMaxLength,
		setShowTable,
		endUsers,
		endUserSelected,
		setEndUserSelected,
		filteredMaterials,
		setFilteredMaterials
	} = useContext(ContentContext)
	const { t } = useTranslation()

	const handleSearchClick = () => {
		setSearch(true)
	}

	const handleMinChange = e => {
		let minLength = parseFloat(e.target.value)
		setMinLength(minLength)
	}

	const handleMaxChange = e => {
		let maxLength = parseFloat(e.target.value)
		setMaxLength(maxLength)
	}

	const handleClearFilter = () => {
		setFilteredMvid([])
		setMaterial(undefined)
		setResult(undefined)
		setErpResult(undefined)
		setShowTable(false)
		setEndUserSelected(null)
		setFilteredMaterials([])
	}

	return (
		<Card
			width='calc(100% - 132px)'
			flex='1'
			height={height}
			margin='0 0 0 16px'
			padding='0'
			stlye={{ position: 'relative', overflowY: 'auto', overflowX: 'hidden' }}>
			<LoadingOverlay visible={loadingMatFilter} borderRadius='card' />
			<Select
				margin='16px'
				width='90%'
				label={t('wms:EndUser')}
				placeholder={t('wms:EndUser')}
				value={endUserSelected}
				options={_.map(endUsers, eu => ({
					value: eu.id,
					label: eu.name
				}))}
				onChange={value => setEndUserSelected(value)}
				searchable
			/>
			<Select
				searchable
				margin='16px'
				width='90%'
				label={t('wms:Material')}
				placeholder={t('wms:Material')}
				options={
					filteredMaterials && filteredMaterials.length
						? _.map(filteredMaterials, mat => ({ value: mat.mvid, label: mat.formatted }))
						: _.map(materials, mat => ({ value: mat.mvid, label: mat.formatted }))
				}
				onChange={value => setMaterial(value)}
				value={material}
			/>
			<Input
				margin='16px 8px 0px 16px'
				width='95%'
				minWidth='50%'
				type='number'
				min='0'
				max='9999999'
				label={t('wms:MinimumLength')}
				placeholder={t('wms:TypeToFilter')}
				value={minLength}
				onChange={handleMinChange}
			/>
			<Input
				margin='16px 8px 0px 16px'
				width='95%'
				minWidth='50%'
				type='number'
				min='0'
				max='9999999'
				label={t('wms:MaximumLength')}
				placeholder={t('wms:TypeToFilter')}
				value={maxLength}
				onChange={handleMaxChange}
			/>
			<FlexView flexDirection='row' width='100%' margin='auto 0 8px 0' justifyContent='center'>
				<Button
					fontSize='small'
					margin='0px 6px 0px 0px'
					backgroundColor='error'
					color='white'
					onClick={handleClearFilter}>
					{t('wms:ClearFilter')}
					<Icon name='cross-simple' color='white' margin='3px 0px 0px 8px' width='12px' height='12px' />
				</Button>
				<Button
					fontSize='small'
					margin='0'
					backgroundColor='secondary'
					color='white'
					onClick={() => handleSearchClick()}>
					{t('wms:Search')}
					<Icon name='search' color='white' margin='3px 0px 0px 8px' width='12px' height='12px' />
				</Button>
			</FlexView>
		</Card>
	)
}

export default MaterialFilter
