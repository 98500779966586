/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Modal, Card, LoadingOverlay, FlexView, Button, Icon } from 'components/common'
import { Select, Input, DatePicker } from 'components/form'
import { useTranslation } from 'react-i18next'
import { ModifiedProductContext } from '../../stores/ModifProductStore'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { WMSContext } from 'apps/wms/stores/WmsStore'

const AddModifProdListModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const {
		endUsers,
		loadingEndUser,
		levels,
		materials,
		sourceMaterial,
		setSourceMaterial,
		expectedQuantity,
		setExpectedQuantity,
		newContent,
		setNewContent,
		uploadMPFile,
		saveModifProductList,
		setEndUser,
		sourceMatList,
		targetMaterial,
		setTargetMaterial
	} = useContext(ModifiedProductContext)
	const { warehouse } = useContext(WMSContext)
	const [mpUpload, setMPUpload] = useState(false)
	const [matDesc, setMatDesc] = useState(null)
	const fileUploader = useRef(null)
	const [fileUpload, setFileUpload] = useState(false)
	const [mpFile, setMPFile] = useState(null)

	const baseMP = {
		name: '',
		enduserid: null,
		ownershipid: '',
		erref: '',
		erfile: null,
		expecteddate: null,
		responsible: null,
		content: []
	}
	const [mpData, setMPData] = useState(baseMP)

	const handleAddToModifList = () => {
		let content = { mid: targetMaterial, quantity: expectedQuantity }
		setNewContent(oldContent => [...oldContent, content])
	}

	const handleContentRemoval = index => {
		newContent.splice(index, 1)
		setNewContent([...newContent])
	}

	const handleExepctedQuantity = e => {
		setExpectedQuantity(e.target.value)
		let mat = _.find(materials, mat => {
			return mat.id === targetMaterial
		})
		setMatDesc(mat.desc)
	}

	const handleSave = async () => {
		try {
			setFileUpload(true)
			await uploadMPFile(mpFile)
				.then(fileName => {
					mpData.erfile = fileName
					setMPData({ ...mpData, erfile: fileName })
				})
				.finally(() => setFileUpload(false))
			setMPUpload(true)
			await saveModifProductList(mpData)
				.then(response => response)
				.finally(() => {
					setMPData(baseMP)
					setNewContent([])
					setMPFile(null)
					setExpectedQuantity(0)
					setMPUpload(false)
					setTargetMaterial(null)
				})
		} catch (e) {
			console.error(e)
		}
	}

	const handleDateChange = v => {
		if (v._d <= new Date()) return toast.error(t('wms:FutureExpectDate'))
		else setMPData({ ...mpData, expecteddate: v })
	}

	const inputMessage = () => {
		if (expectedQuantity.length < 0) return t('wms:NegativeNumber')
		else return t('wms:PositiveNumberAllowed')
	}

	const inputMessageType = () => {
		if (expectedQuantity.length < 0) return 'error'
		else return 'info'
	}

	useEffect(() => {
		setMPData(baseMP)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card
				width='85%'
				height='85%'
				minHeight='85%'
				maxHeight='85%'
				position='relative'
				data-cy='card-add-modifprod-list'>
				<LoadingOverlay visible={loadingEndUser} borderRadius='16px' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:NewModifiedProduct')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' maxHeight='calc(100% - 98px)' flex='1' flexDirection='row' margin='0 0 16px 0'>
					<FlexView maxWidth='40%' minWidth='40%' minHeight='100%' maxHeight='100%' margin='0 16px 0 0'>
						<Card
							margin='0'
							minWidth='calc(100% - 48px)'
							maxWidth='calc(100% - 48px)'
							flex='1'
							maxHeight='100%'
							style={{ overflowY: 'scroll' }}
							data-cy='card-type-source'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
								{t('wms:Source')}
							</FlexView>
							{endUsers && (
								<Select
									label={t('wms:EndUser')}
									placeholder={t('wms:EndUser')}
									value={mpData.enduserid}
									options={endUsers.map(eu => ({ value: eu.id, label: eu.name }))}
									onChange={value => {
										setEndUser(value)
										setMPData({ ...mpData, enduserid: value })
									}}
									width='100%'
									margin='16px 0 0 0'
									searchable
									data-cy='select-enduser'
								/>
							)}
							{levels && levels.length > 0 && (
								<Select
									width='100%'
									margin='16px 0 0 0'
									label={t('wms:Ownership')}
									placeholder={t('wms:Ownership')}
									value={mpData.ownershipid}
									options={_.map(levels, lv => ({
										value: lv.id,
										label: lv.label
									}))}
									onChange={v => setMPData({ ...mpData, ownershipid: v })}
									searchable
								/>
							)}
							<Select
								label={t('wms:SourceMaterial')}
								placeholder={t('wms:SelectOption')}
								value={sourceMaterial}
								options={
									sourceMatList
										? sourceMatList.map(mat => {
												return { value: mat.id, label: mat.desc }
										  })
										: []
								}
								onChange={value => setSourceMaterial(value)}
								width='100%'
								margin='16px 0 0 0'
								disabled={!mpData.ownershipid}
								searchable
								data-cy='select-material'
							/>
							<Select
								label={t('wms:TargetMaterial')}
								placeholder={t('wms:TargetMaterial')}
								value={targetMaterial}
								options={
									materials
										? materials.map(mat => {
												return { value: mat.id, label: mat.desc }
										  })
										: []
								}
								onChange={value => setTargetMaterial(value)}
								width='100%'
								margin='16px 0 0 0'
								disabled={!mpData.enduserid && !sourceMaterial}
								searchable
								data-cy='select-target-material'
							/>
							<Input
								message={inputMessage()}
								messageType={inputMessageType()}
								type='number'
								min='1'
								max='99999'
								label={t('wms:ExpectedQuantity')}
								value={expectedQuantity}
								onChange={e => handleExepctedQuantity(e)}
								width='100%'
								margin='16px 0 16px 0'
								disabled={!targetMaterial}
								data-cy='input-expected-quantity'
							/>
							<Button
								fontSize='medium'
								margin='auto'
								color='white'
								backgroundColor='secondary'
								disabled={expectedQuantity <= 0 || expectedQuantity.length >= 6}
								onClick={() => {
									handleAddToModifList()
								}}
								data-cy='button-add-modiflist'>
								{t('wms:AddMaterial')}
							</Button>
						</Card>
					</FlexView>
					<FlexView flex='1' maxWidth='calc(60% - 16px)' minWidth='calc(60% - 16px)' height='100%' margin='0'>
						<Card margin='0' width='calc(100% - 48px)' height='100%' flex='1' data-cy='card-modifprod-info'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:ModifiedProductInfo')}
							</FlexView>
							<FlexView flexDirection='row' width='100%' justifyContent='space-between' alignItems='center'>
								<Input
									info
									type='text'
									label={t('wms:Name')}
									placeholder={t('wms:Name')}
									value={mpData.name}
									onChange={e => {
										setMPData({ ...mpData, name: e.target.value })
									}}
									margin='16px 20px 0 0'
									minWidth='50%'
									max='20'
									data-cy='input-modifproduct-name'
								/>
								<Input
									info
									type='text'
									label={t('wms:MPReference')}
									placeholder={t('wms:MPReference')}
									value={mpData.erref}
									onChange={e => {
										setMPData({ ...mpData, erref: e.target.value })
									}}
									margin='16px 20px 0 0'
									minWidth='50%'
									max='20'
									data-cy='input-modifproduct-name'
								/>
								<DatePicker
									timePicker
									label={t('wms:ExpectedDate')}
									value={mpData.expecteddate}
									onChange={value => handleDateChange(value)}
									margin='16px 0 0 0'
									data-cy='date-picker'
								/>
							</FlexView>
							<FlexView width='100%' height='250px' style={{ overflowY: 'scroll', overflowX: 'auto' }}>
								{newContent && newContent.length
									? newContent.map((content, index) => (
											<FlexView
												key={index}
												width='100%'
												margin='0'
												flexDirection='row'
												padding='8px 0'
												style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
												<FlexView fontSize='small' margin='16px 16px 0 0' flexDirection='row' width='70%'>
													<strong>{matDesc}</strong>
												</FlexView>
												<FlexView fontSize='small' margin='16px 0 0 0' flexDirection='row' width='30%'>
													{`${t('wms:Quantity')}: ${content.quantity}`}
												</FlexView>
												<Button
													fontSize='small'
													margin='16px 0 auto auto'
													padding='8px 16px'
													color='white'
													backgroundColor='error'
													disabled={false}
													onClick={() => {
														handleContentRemoval(index)
													}}
													data-cy='button-remove-content'>
													<Icon
														name='trash'
														color='white'
														height='16px'
														width='16px'
														margin='0'
														tooltip={`${t('wms:RemoveMaterial')}`}
													/>
												</Button>
											</FlexView>
									  ))
									: null}
							</FlexView>
						</Card>
					</FlexView>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='4px 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0 8px 0 0'
						style={{ marginLeft: 'calc(40% + 16px)' }}
						color='white'
						backgroundColor='secondary'
						onClick={() => {
							fileUploader.current.click()
						}}
						data-cy='button-fileupload'>
						{t('wms:UploadMPFile')}
						<Icon name='upload' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
					<FlexView
						style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
						flex='1'
						margin='0 8px 0 0'
						flexDirection='row'>
						{mpFile && mpFile.name}
					</FlexView>
					<input
						style={{ display: 'none' }}
						id='mpFileInput'
						type='file'
						accept='application/pdf'
						ref={fileUploader}
						onChange={e => {
							setMPFile(e.target.files[0])
						}}
					/>
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='success'
						disabled={
							!(
								newContent.length > 0 &&
								mpData.name &&
								mpData.name.length <= 20 &&
								mpData.expecteddate &&
								mpFile &&
								mpData.expecteddate._d >= new Date()
							)
						}
						onClick={() => handleSave()}
						data-cy='button-save-modifproduct-list'>
						{t('wms:Save')}
						<LoadingOverlay visible={mpUpload || fileUpload} />
						<Icon name='save' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default AddModifProdListModal
