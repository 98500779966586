/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import _ from 'lodash'
import { FlexView, Card, Button } from 'components/common'
import { MRType, STATUS } from '../../utils/enum'
import { MRContext } from '../../stores/MRStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'
import theme from 'utils/theme'

const MrInfo = () => {
	const { selectedMR, materialList, lastLevelsList, openMRFile, handleReopen } = useContext(MRContext)
	const { t, i18n } = useTranslation()

	const listDeadSpace = 300
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)
	const { user } = useContext(UserContext)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const allowReopen = true

	const matFormatted = id => {
		return id !== null &&
			id !== undefined &&
			materialList &&
			materialList.length &&
			materialList.find(mat => mat.mvid === id)
			? materialList.find(mat => mat.mvid === id).formatted
			: ''
	}

	const levelFullName = id => {
		return id !== null && lastLevelsList.length ? lastLevelsList.find(level => level.id === id).fullname : ''
	}

	return (
		<Card width='calc(100% - 48px)' flex='1' margin='0' ref={listRef} style={{ position: 'relative' }}>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{selectedMR.type === MRType.code.RIGPREP && t('wms:RigPrepInfo')}
				{selectedMR.type === MRType.code.TPWORKSHOP && t('wms:ThirdPartyInfo')}
				{selectedMR.type === MRType.code.MATMODIF && t('wms:MaterialModificationInfo')}
				{selectedMR.type === MRType.code.PHYSTRANS && t('wms:MaterialPhysicalTransferInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:ReferenceMR')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedMR.mrref}
					</FlexView>
				</FlexView>
				{selectedMR.type === MRType.code.RIGPREP && (
					<>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:FieldName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.field}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:WellName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.well}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:RigName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.rig}
							</FlexView>
						</FlexView>
					</>
				)}
				{(selectedMR.type === MRType.code.TPWORKSHOP || selectedMR.type === MRType.code.MATMODIF) && (
					<>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:ThirdPartyName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.tpartyname}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:ThirdPartyLocation')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.tpartyloc}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:FieldName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.field}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:WellName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.well}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:RigName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.rig}
							</FlexView>
						</FlexView>
					</>
				)}
				{selectedMR.type === MRType.code.PHYSTRANS && (
					<>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:DeliveryLocation')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.tpartyloc}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:FieldName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.field}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:WellName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.well}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:RigName')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedMR.rig}
							</FlexView>
						</FlexView>
					</>
				)}
				<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:DispatchExpectedDate')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(selectedMR.expecteddate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:NumberLTPA')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedMR.ltpa}
					</FlexView>
				</FlexView>
			</FlexView>
			{selectedMR && selectedMR.planrev && selectedMR.planresp && selectedMR.plandate ? (
				<FlexView
					width='100%'
					margin='0'
					flexDirection='row'
					flexWrap='wrap'
					padding='0'
					justifyContent='space-between'>
					<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:Revision')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedMR.planrev}
						</FlexView>
					</FlexView>
					<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:UpdatedBy')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedMR.planresp}
						</FlexView>
					</FlexView>
					<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:On')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{DATE_FORMAT(selectedMR.plandate, i18n.language)}
						</FlexView>
					</FlexView>
				</FlexView>
			) : (
				''
			)}

			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'auto' }}>
				{selectedMR &&
					selectedMR.content.length &&
					selectedMR.content.map((content, index) => (
						<FlexView
							key={content[MRType.cid[selectedMR.type]]}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
							<FlexView flex='1' fontSize='small' margin='auto 0'>
								{matFormatted(content.mid)} <br />
								<strong>
									{`${levelFullName(content.levelid)} | ${t('wms:Quantity')}: ${content.quantity}`}
								</strong>
							</FlexView>

							<FlexView
								width='35%'
								margin='0'
								padding='0'
								flexDirection='row'
								flexWrap='wrap'
								justifyContent='center'>
								<FlexView
									fontSize='tiny'
									color={STATUS.color[content.status]}
									style={{
										borderLeft: `2px solid ${theme.colors[STATUS.color[content.status]]}`,
										lineHeight: '24px'
									}}
									margin='0 0 0 auto'
									padding='8px 16px'>
									{t(`wms:${STATUS.literal[content.status]}`)}
								</FlexView>
								{allowReopen &&
								content.status === STATUS.code.RUNNING &&
								_.find(user.roles, u => u === 'wmsadmin') ? (
									<Button
										fontSize='tiny'
										margin='0px 0 0 auto'
										color='white'
										backgroundColor='secondary'
										disabled={false}
										onClick={() => {
											handleReopen(content)
										}}>
										{t('wms:Reopen')}
									</Button>
								) : null}
							</FlexView>
						</FlexView>
					))}
			</FlexView>
			<Button
				fontSize='small'
				margin='auto 0 0 auto'
				color='white'
				backgroundColor='secondary'
				disabled={false}
				onClick={() => {
					openMRFile()
				}}>
				{`${t('wms:View')} ${t('wms:MRFile')}`}
			</Button>
		</Card>
	)
}

export default MrInfo
