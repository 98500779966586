/** @format */

import AlertModal from 'apps/wms/components/forms/Alert'
import { ReportDataContext } from 'apps/wms/stores/ReportDataStore'
import { REPORT_ITEM_STATUS, REPORT_STATUS, REPORT_TYPE } from 'apps/wms/utils/enum'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Card, FlexView, Modal } from 'components/common'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReportActions from './actions'
import { isReceiptNoteReport } from './businessRules'
import { shouldAllowReportReopen, isReportInspectionNotConsistent, isAgeManagementReport } from './businessRules'
import ReportHeader from './header'
import ReportTable from './table'
import ReportTitle from './title'

const ReportEditorModal = ({ isOpen, onOutsideClick, report, reportType, extractName }) => {
	const { t } = useTranslation()
	const {
		isLoadingData,
		getReportData,
		getAgeManagementReportData,
		rejectReasonsList,
		user,
		rejectReport,
		rejectAgeMgmtReport,
		reopenReport,
		updateReport,
		validateReport,
		validateAgeMgmtReport,
		threadCompoundTypes,
		applicationMethods,
		reopenMillReceipt
	} = useContext(ReportDataContext)

	const [consistencyStatusWarn, setConsistencyStatusWarn] = useState(false)
	const [allowEdit, setAllowEdit] = useState(false)
	const [allowReopen, setAllowReopen] = useState(false)
	const [hasChanges, setHasChanges] = useState(false)
	const [plannedQty, setPlannedQty] = useState(false)
	const [ltpa, setLtpa] = useState(null)
	const [contentData, setContentData] = useState([])
	const [reportItems, setReportItems] = useState([])
	const [instrumentList, setInstrumentList] = useState({})
	const [reportSummary, setReportSummary] = useState({})
	const [filteredRejectReasons, setFilteredRejectReasons] = useState([])
	const [headersZoom, setHeadersZoom] = useState(1)
	const [tableZoom, setTableZoom] = useState(() => {
		const storagedTableZoom = JSON.parse(localStorage.getItem('@Shalyn:wms:zoom'))
		return storagedTableZoom ? storagedTableZoom : 0.5
	})

	const modalDeadSpace = 100
	const window = useWindowSize()
	const [tableHeight, setTableHeight] = useState(0)
	const parentRef = useRef(null)
	const headerRef = useRef(null)

	const currentHeaderHeight = useMemo(
		() => (headerRef.current !== null ? headerRef.current.offsetHeight : 0),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[headerRef, instrumentList, ltpa]
	)

	useEffect(() => {
		const headzoom =
			window.height <= 540
				? 0.55
				: window.height <= 720
				? 0.85
				: window.height <= 960
				? 0.95
				: window.height <= 1080
				? 1
				: 1.2
		setHeadersZoom(headzoom)
	}, [window])
	//const window = useWindowSize()

	useEffect(() => {
		isOpen && setTableHeight(parentRef.current.offsetHeight - modalDeadSpace - currentHeaderHeight)
	}, [isOpen, parentRef, currentHeaderHeight, window])
	//const window = useWindowSize()

	useEffect(() => {
		setAllowEdit(report.status === REPORT_STATUS.code.PENDING)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report, reportType])

	useEffect(() => {
		const rejectList = rejectReasonsList.filter(rr => rr.inspectiontype === REPORT_TYPE.defectType[reportType])
		setFilteredRejectReasons(rejectList)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportType])

	useEffect(() => {
		reportType !== null &&
			report.id !== null &&
			(isAgeManagementReport(reportType)
				? getAgeManagementReportData(report.id).then(({ list, ltpa }) => {
						setReportItems(list)
						setLtpa(ltpa)
				  })
				: getReportData(reportType, report.id).then(({ content, items, instrumentlist, summary }) => {
						setReportItems(items)
						if (content) setContentData(content)
						setInstrumentList(instrumentlist && instrumentlist.sort((a, b) => a.id - b.id))
						setReportSummary(summary)
				  }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report])

	useEffect(() => {
		const aux = contentData.reduce((prev, curr) => prev + curr.quantity, 0)
		setPlannedQty(aux)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentData])

	useEffect(() => {
		const acceptedPipes = reportItems.filter(report => report.finalreport === REPORT_ITEM_STATUS.code.ACCEPT).length
		setAllowReopen(shouldAllowReportReopen(reportType, plannedQty, acceptedPipes))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportItems, plannedQty])

	const onDataChange = useCallback(
		report => {
			const i = reportItems.findIndex(item => item.valid === report.valid)
			if (i !== -1) {
				reportItems[i] = report
				setReportItems([...reportItems])
				setHasChanges(true)
			}
		},
		[reportItems]
	)

	const onAllDataChange = reportItems => {
		setReportItems([...reportItems])
		setHasChanges(true)
	}

	const onRejectReport = () => {
		isAgeManagementReport(reportType)
			? rejectAgeMgmtReport(report.id)
					.then(response => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: rejectReport(reportType, report.id, report.revision)
					.then(response => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
	}

	const onUpdateReport = () => {
		const inconsistentReport = reportItems.find(ri => isReportInspectionNotConsistent(ri, reportType))
		inconsistentReport === undefined
			? updateReport(reportItems, reportType, report.id, report.revision)
					.then(response => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: setConsistencyStatusWarn(true)
		return true
	}

	const onReopenReport = () => {
		isReceiptNoteReport(reportType)
			? reopenMillReceipt(report.id, report.remarks, report.revision)
					.then(response => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: reopenReport(reportType, report.id, report.validation_revision)
					.then(response => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
	}

	const onValidateReport = () => {
		isAgeManagementReport(reportType)
			? validateAgeMgmtReport(report.id)
					.then(response => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: validateReport(reportType, report.id, report.revision)
					.then(response => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
	}

	const onZoomChange = value => {
		localStorage.setItem('@Shalyn:wms:zoom', JSON.stringify(value))
		setTableZoom(value)
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card
				ref={parentRef}
				borderRadius='0'
				backgroundColor='#F8F8F9'
				width='calc(100%)'
				height='calc(100%)'
				flex='1'
				margin='0'
				padding='0'
				alignItems='center'
				style={{ position: 'relative' }}>
				<FlexView width='calc(100% - 5vw - 32px)' padding='16px'>
					<FlexView ref={headerRef} width='100%'>
						<ReportActions
							report={report}
							reportType={reportType}
							allowEdit={allowEdit}
							hasChanges={hasChanges}
							allowReopen={allowReopen}
							onLeftReportEditor={onOutsideClick}
							onRejectReport={onRejectReport}
							onUpdateReport={onUpdateReport}
							onReopenReport={onReopenReport}
							onValidateReport={onValidateReport}
							zoom={tableZoom}
							onZoomChange={onZoomChange}
						/>
						<ReportTitle
							reportType={reportType}
							reportData={report}
							allowEdit={allowEdit}
							zoom={headersZoom}
							extractName={extractName}
						/>
						<ReportHeader
							reportType={reportType}
							reportData={{ ...report, ...reportSummary, ...contentData[0] }}
							instrumentList={instrumentList}
							ltpa={ltpa}
							zoom={headersZoom}
						/>
					</FlexView>
					<ReportTable
						reportType={reportType}
						reportItems={reportItems}
						rejectReasons={filteredRejectReasons}
						allowEdit={allowEdit}
						loading={isLoadingData}
						supervisor={user.name}
						onDataChange={onDataChange}
						onAllDataChange={onAllDataChange}
						height={tableHeight}
						zoom={tableZoom}
						manufacturer={threadCompoundTypes}
						applicationMethodList={applicationMethods}
					/>
				</FlexView>
				<AlertModal
					isOpen={consistencyStatusWarn}
					title={`${t(`wms:UpdateError`)} `}
					text={t(`wms:UpdateErrorText`)}
					confirmText='Ok'
					onConfirm={() => setConsistencyStatusWarn(false)}
				/>
			</Card>
		</Modal>
	)
}

export default ReportEditorModal
