/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button } from 'components/common'
import { STATUS } from '../../utils/enum'
import { RigReturnContext } from '../../stores/RigReturnStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'
import _ from 'lodash'
import theme from 'utils/theme'
import { UserContext } from 'stores/UserStore'

const RigReturnInfo = () => {
	const { selectedRR, handleReopen, openRRFile, levels } = useContext(RigReturnContext)
	const { t, i18n } = useTranslation()

	const listDeadSpace = 320
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)
	const { user } = useContext(UserContext)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const ownershipDesc = id => {
		let ownership = _.find(levels, owner => owner.id === id)
		return id && ownership ? ownership.label : '*****'
	}

	const allowReopen = true

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-info-wrapper'>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:RigReturnInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:EndUser')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedRR.enduser.name}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:Ownership')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{ownershipDesc(selectedRR.ownershipid)}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:ReferenceRR')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedRR.rrref}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:FieldName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedRR.field}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:RigName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedRR.rig}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:WellName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedRR.well}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:DispatchExpectedDate')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(selectedRR.expecteddate, i18n.language)}
					</FlexView>
				</FlexView>
				{selectedRR && selectedRR.entrancedate && (
					<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:EntranceDate')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{DATE_FORMAT(selectedRR.entrancedate, i18n.language)}
						</FlexView>
					</FlexView>
				)}
			</FlexView>

			<FlexView
				width='100%'
				height={`${listHeight}px`}
				style={{ overflowY: 'scroll', overflowX: 'auto' }}
				data-cy='content-list'>
				{selectedRR &&
					selectedRR.content.length &&
					selectedRR.content.map((content, index) => (
						<FlexView
							key={content.rrlcid}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							data-cy='content-list-item'>
							<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
								<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
							</FlexView>
							<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
								{`${t('wms:Untraceable')}: ${content.ntquantity}`}
							</FlexView>
							<FlexView
								width='25%'
								margin='0'
								padding='0'
								flexDirection='row'
								justifyContent='center'
								flexWrap='wrap'>
								<FlexView
									fontSize='tiny'
									color={STATUS.color[content.status]}
									style={{
										borderLeft: `2px solid ${theme.colors[STATUS.color[content.status]]}`,
										lineHeight: '24px'
									}}
									margin='0 0 0 auto'
									padding='8px 16px'>
									{t(`wms:${STATUS.literal[content.status]}`)}
								</FlexView>
								{allowReopen &&
									content.status === STATUS.code.RUNNING &&
									_.find(user.roles, u => u === 'wmsadmin') && (
										<Button
											fontSize='tiny'
											margin='0 0 0 auto'
											color='white'
											backgroundColor='secondary'
											disabled={false}
											onClick={() => {
												handleReopen(content)
											}}>
											{t('wms:Reopen')}
										</Button>
									)}
							</FlexView>
						</FlexView>
					))}
			</FlexView>
			<Button
				fontSize='small'
				margin='auto 0 0 auto'
				color='white'
				backgroundColor='secondary'
				disabled={false}
				onClick={() => {
					openRRFile()
				}}>
				{`${t('wms:View')} ${t('wms:RRFile')}`}
			</Button>
		</Card>
	)
}

export default RigReturnInfo
