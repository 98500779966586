/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms/logs`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getLogTypes: token =>
		new Promise((resolve, reject) => {
			instance
				.get('/types', {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getLogGroups: token =>
		new Promise((resolve, reject) => {
			instance
				.get('/groups', {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getLogs: (token, group, date, type, filter, nextToken, limit) =>
		new Promise((resolve, reject) => {
			instance
				.get('', {
					headers: {
						Authorization: `Bearer ${token}`
					},
					params: {
						group,
						date,
						type,
						filter,
						nextToken,
						limit
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
