/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getRecInspList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/recinsp/${wid}/list`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.recinsplist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMaterialsByEU: (wid, euId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/materiallist/${wid}/${euId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.materiallist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getCOList: (wid, euId, mid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/polist/${wid}/${euId}/${mid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.polist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getSOList: (wid, euId, mid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/saleslist/${wid}/${euId}/${mid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.saleslist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAvailableQnt: (wid, euId, mid, order, item, isSales, token) =>
		new Promise((resolve, reject) => {
			let url = isSales
				? `content/itemquantity/${wid}/${euId}/${mid}?salesnumber=${encodeURIComponent(
						order
				  )}&salesitem=${encodeURIComponent(item)}`
				: `content/itemquantity/${wid}/${euId}/${mid}?ponumber=${encodeURIComponent(
						order
				  )}&poitem=${encodeURIComponent(item)}`
			instance
				.get(url, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.quantity)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	newRI: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/recinsp/${wid}/list`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else if (response.status === 422) reject(new Error('wms.DispatchListMultipleMaterials'))
					else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteRI: (wid, riid, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`/recinsp/${wid}/list/${riid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getRecInspReports: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/recinsp/${wid}/reports`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.recinsplist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPerInspReports: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/perinsp/${wid}/reports`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.perinsplist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenRecInsp: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/recinsp/${wid}/release`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
