/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/v2/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getConfigOverview: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/config/${wid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getStages: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/config/${wid}/stages`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMeasurements: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/config/${wid}/measurements`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	createMeasurementConfig: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`inspection/config/${wid}/measurements`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status >= 200 && response.status < 300) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),

	newInspectionType: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`inspection/config/${wid}/types`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status >= 200 && response.status < 300) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getInspections: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/config/${wid}/types?disabled=true`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveInspConfig: (wid, inspID, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`inspection/config/${wid}/types/${inspID}`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateInspConfig: (wid, inspID, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`inspection/config/${wid}/types/${inspID}`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	disableInspectionType: (wid, inspID, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`inspection/config/${wid}/types/${inspID}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getItems: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/config/${wid}/elements`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getDisabledItems: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/config/${wid}/elements?disabled=true`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 201 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveItems: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`inspection/config/${wid}/elements`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 201) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	disableItem: (wid, itemID, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`inspection/config/${wid}/elements/${itemID}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	enableItem: (wid, itemID, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`inspection/config/${wid}/elements/${itemID}?reactivate=true`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 201 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveInspItem: (wid, itemID, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`inspection/config/${wid}/elements/${itemID}`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getDefects: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/config/${wid}/defects`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 201) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getDisabledDefects: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/config/${wid}/defects?disabled=true`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 201 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	disableDefects: (wid, id, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`inspection/config/${wid}/defects/${id}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	enableDefects: (wid, id, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`inspection/config/${wid}/defects/${id}?reactivate=true`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 201 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveDefects: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`inspection/config/${wid}/defects`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 201) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
