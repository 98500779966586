/** @format */

import { _ } from 'globalthis/implementation'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UserContext } from 'stores/UserStore'
import common from '../api/common'
import inventory from '../api/inventory'
import { WMSContext } from './WmsStore'

export const InventoryContext = React.createContext()

export const InventoryProvider = ({ children }) => {
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse, bundlesOrItems, displayBundles } = useContext(WMSContext)
	const [reports, setReports] = useState(null)
	const [loadingReports, setLoadingReports] = useState(false)
	const [openDetailsModal, setOpenDetailsModal] = useState(false)
	const [loadingDetails, setLoadingDetails] = useState(false)
	const [openModalGhosts, setOpenModalGhosts] = useState(false)
	const [loadingGhosts, setLoadingGhosts] = useState(false)
	const [loadingPrinters, setLoadingPrinters] = useState(false)
	const [openPrint, setOpenPrint] = useState(false)
	const [inventoryId, setInventoryId] = useState(null)
	const [inventoryResult, setInventoryResult] = useState(null)
	const [items, setItems] = useState(null)
	const [printers, setPrinters] = useState(null)
	const [printerSelected, setPrinterSelected] = useState(null)
	const [itemSelected, setItemSelected] = useState(null)

	const getInventories = () =>
		new Promise((resolve, reject) => {
			inventory
				.getInventories(warehouse.id, token)
				.then(response => {
					let data = _.map(response, res => {
						return {
							rackId: res.level.id,
							fullname: res.level.fullname,
							id: res.result.id,
							lastinventorydate: res.result.lastinventorydate,
							nextinventorydate: res.nextinventorydate,
							responsible: res.result.responsible,
							deviation: res.result.errorpercentage
						}
					})
					setReports(data)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject()
					toast.error(t('wms:ErrorGettingInventories'))
				})
		})

	const loadInventory = () =>
		new Promise((resolve, reject) => {
			inventory
				.loadInventory(warehouse.id, inventoryId, token)
				.then(response => {
					setInventoryResult(response.data)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject()
					toast.error(t('wms:ErrorReadingInvDetails'))
				})
		})

	const loadGhosts = () =>
		new Promise((resolve, reject) => {
			let bundles = null
			inventory
				.loadGhosts(warehouse.id, token)
				.then(response => {
					bundles = bundlesOrItems(response.bundles)
					let data = _.map(bundles, items => {
						return {
							fullname: items.level.fullname,
							valid: items.valid,
							puuid: items.puuid,
							formatted: items.material.formatted,
							status: items.laststatus
						}
					})
					setItems(data)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(t('wms:ErrorReadingGhosts'))
				})
		})

	const getPrinters = () =>
		new Promise((resolve, reject) => {
			let p = []
			common
				.getPrinters(token)
				.then(response => {
					_.forEach(response, (res, i) => {
						p.push({ value: i, label: res })
					})
					setPrinters(p)
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(t('wms:ErrorReadingPrinters'))
				})
		})

	const putPrinters = () =>
		new Promise((resolve, reject) => {
			let p = _.find(printers, printer => {
				return printer.value === printerSelected
			})
			if (displayBundles()) {
				let payload = {
					printer: p.label,
					localids: [itemSelected.localid]
				}
				common
					.setPrintersByBundles(payload, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:Printing OK'))
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorPrinting')} [${e.status}]: ${e.data}`)
					})
			} else {
				let payload = {
					printer: p.label,
					items: [itemSelected]
				}
				common
					.setPrintersByItems(payload, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:Printing OK'))
						setPrinterSelected(null)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorPrinting')} [${e.status}]: ${e.data}`)
						setPrinterSelected(null)
					})
			}
		})

	const exportDetails = id =>
		new Promise((resolve, reject) => {
			inventory
				.exportDetails(warehouse.id, id, token)
				.then(response => {
					let fileName = `Extract - Inventory (${moment(new Date()).format('M-DD-YYYY')}).xlsx`
					let a = document.createElement('a')
					let file = new Blob([response], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let fileURL = window.URL.createObjectURL(file)
					a.href = fileURL
					a.target = '_blank'
					a.download = fileName
					a.click()
					resolve(true)
				})
				.catch(e => {
					toast.error(
						`${t('wms:ErrorDownloadingDetailsRack')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
					)
					reject(e)
					console.error(e)
				})
		})

	const exportGhost = () =>
		new Promise((resolve, reject) => {
			inventory
				.exportGhost(warehouse.id, token)
				.then(response => {
					let fileName = `Extract - Not Read (${moment(new Date()).format('M-DD-YYYY')}).xlsx`
					let a = document.createElement('a')
					let file = new Blob([response], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let fileURL = window.URL.createObjectURL(file)
					a.href = fileURL
					a.target = '_blank'
					a.download = fileName
					a.click()
					resolve(true)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorExportingGhost')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`)
					reject(e)
					console.error(e)
				})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingReports(true)
				await getInventories()
			} catch (e) {
				setLoadingReports(false)
				console.error(e)
			} finally {
				setLoadingReports(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (openDetailsModal) {
			const hydrate = async () => {
				try {
					setLoadingDetails(true)
					await loadInventory()
				} catch (e) {
					setLoadingDetails(false)
					console.error(e)
				} finally {
					setLoadingDetails(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openDetailsModal])

	useEffect(() => {
		if (openModalGhosts) {
			const hydrate = async () => {
				try {
					setLoadingGhosts(true)
					await loadGhosts()
				} catch (e) {
					setLoadingGhosts(false)
					console.error(e)
				} finally {
					setLoadingGhosts(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openModalGhosts])

	useEffect(() => {
		if (openPrint) {
			const hydrate = async () => {
				try {
					setLoadingPrinters(true)
					await getPrinters()
				} catch (e) {
					setLoadingPrinters(false)
					console.error(e)
				} finally {
					setLoadingPrinters(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openPrint])

	const renderStore = (
		<InventoryContext.Provider
			value={{
				reports,
				setReports,
				loadingReports,
				setLoadingReports,
				openDetailsModal,
				setOpenDetailsModal,
				inventoryId,
				setInventoryId,
				loadingDetails,
				setLoadingDetails,
				inventoryResult,
				setInventoryResult,
				openModalGhosts,
				setOpenModalGhosts,
				items,
				setItems,
				loadingGhosts,
				setLoadingGhosts,
				openPrint,
				setOpenPrint,
				loadingPrinters,
				setLoadingPrinters,
				printers,
				setPrinters,
				printerSelected,
				setPrinterSelected,
				itemSelected,
				setItemSelected,
				putPrinters,
				exportDetails,
				exportGhost
			}}>
			{children}
		</InventoryContext.Provider>
	)
	return renderStore
}
