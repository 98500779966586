/** @format */

import moment from 'moment'

export const DATE_FORMAT = (date, language) => {
	if (language === 'pt-BR') {
		return moment.locale(`${language}`) && moment(date).format('l HH:mm')
	} else {
		return moment.locale(`${language}`) && moment(date).format('l hh:mm a')
	}
}
