/** @format */

import { PerInspContext } from 'apps/wms/stores/PerInspStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ManagePartsModal from './ManagePartsModal'
import _ from 'lodash'
import { REPORT_TYPE, STATUS } from 'apps/wms/utils/enum'
import ReportEditorModal from '../reportEditor/wrapper'
import theme from 'utils/theme'
import { UserContext } from 'stores/UserStore'

const PerInspInfo = () => {
	const { t, i18n } = useTranslation()
	const {
		openManageParts,
		setOpenManageParts,
		selectedInspection,
		summarized,
		loadingInfo,
		isEditorOpen,
		setIsEditorOpen,
		getPerInspDetails,
		handleReopen
	} = useContext(PerInspContext)
	const { user } = useContext(UserContext)
	const [type, setType] = useState(null)
	const [periodicDetails, setPeriodicDetails] = useState(null)

	const allowReopen = true

	const listDeadSpace = 1200
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const openModal = () => {
		setOpenManageParts(true)
	}

	const handleClick = () => {
		if (
			(selectedInspection !== null || selectedInspection !== undefined) &&
			selectedInspection.lastinspdate !== null
		) {
			getPerInspDetails(selectedInspection.id).then(details => {
				setPeriodicDetails({ ...details, revision: selectedInspection.revision })
				setType(REPORT_TYPE.code.PERIODICAL)
				setIsEditorOpen(true)
			})
		}
	}

	return (
		<Card
			width='35%'
			height='95%'
			maxHeight='95%'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-perinsp-info'>
			<LoadingOverlay visible={loadingInfo} borderRadius='card' />
			{selectedInspection && (
				<FlexView flexDirection='column' width='100%' flex='1' justifyContent='space-between' height='90%'>
					<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px'>
						{t('wms:PeriodicInspectionInfo')}
					</FlexView>
					<FlexView
						width='100%'
						margin='0'
						flexDirection='row'
						flexWrap='wrap'
						pading='0'
						justifyContent='space-between'>
						<FlexView width='75%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:SalesOrder')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedInspection.salesorder ? selectedInspection.salesorder : '---'}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='25%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:SalesItem')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedInspection.salesitem ? selectedInspection.salesitem : '---'}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='75%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:CustomerOrder')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedInspection.customerpo ? selectedInspection.customerpo : '---'}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='25%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:CustomerItem')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedInspection.customeritem ? selectedInspection.customeritem : '---'}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='100%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:Material')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedInspection.material.formatted}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:EntranceDate')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{moment.locale(`${i18n.language}`) && moment(selectedInspection.entrancedate).format('L')}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:LastInspection')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedInspection.lastinspdate
										? moment.locale(`${i18n.language}`) && moment(selectedInspection.lastinspdate).format('L')
										: '---'}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:NextInspection')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{moment.locale(`${i18n.language}`) && moment(selectedInspection.nextinspdate).format('L')}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:InitialQuantity')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{summarized.initialqty}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:CurrentQuantity')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{summarized.todateqty}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{`${t('wms:Part')}s`}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedInspection.content.length}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView
							width='100%'
							margin='0'
							padding='0'
							height={`${listHeight}px`}
							flexDirection='column'
							style={{ overflowY: 'scroll', overflowX: 'auto' }}>
							<FlexView flexDirection='row' width='100%' margin='16px 0'>
								<FlexView
									flexDirection='column'
									width='100%'
									justifyContent='space-between'
									margin='0 0 20px 0'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
										{`${t('wms:Rack')}`}
									</FlexView>
									{summarized &&
										_.map(summarized.items, rack => (
											<FlexView
												key={rack.level.fullname}
												fontSize='small'
												width='100%'
												margin='0 8px 8px 0'
												data-cy='list-label'>
												<FlexView
													width='100%'
													style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
													padding='0 0 0 8px'>
													{rack.level.fullname}
												</FlexView>
											</FlexView>
										))}
								</FlexView>
								<FlexView flexDirection='column' width='50%' justifyContent='space-between' margin='0 0 20px 0'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
										{`${t('wms:QuantityByRack')}`}
									</FlexView>
									{summarized &&
										_.map(summarized.items, rack => (
											<FlexView
												key={rack.level.fullname}
												fontSize='small'
												margin='0 8px 8px 0'
												data-cy='list-label'>
												<FlexView
													width='100%'
													style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
													padding='0 0 0 8px'>
													{rack.qty}
												</FlexView>
											</FlexView>
										))}
								</FlexView>
							</FlexView>
						</FlexView>
					</FlexView>
					<FlexView width='100%' margin=' 8px 0 0 0'>
						{selectedInspection &&
							selectedInspection.content &&
							selectedInspection.content.map((content, index) => (
								<FlexView
									key={content.pilcid}
									width='100%'
									margin='0'
									flexDirection='row'
									padding='8px 0'
									style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
									<FlexView width='20%' fontSize='small' margin='auto 0' flexDirection='row'>
										<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
									</FlexView>
									<FlexView width='25%' fontSize='small' margin='auto 0' flexDirection='row'>
										{`${t('wms:Quantity')}: ${content.quantity}`}
									</FlexView>
									<FlexView
										width='55%'
										margin='0'
										padding='0'
										flexDirection='row'
										justifyContent='center'
										flexWrap='wrap'>
										<FlexView
											fontSize='tiny'
											color={STATUS.color[content.status]}
											style={{
												borderLeft: `2px solid ${theme.colors[STATUS.color[content.status]]}`,
												lineHeight: '24px'
											}}
											margin='0 0 0 auto'
											padding='8px 16px'>
											{t(`wms:${STATUS.literal[content.status]}`)}
										</FlexView>
										{allowReopen &&
											content.status === STATUS.code.RUNNING &&
											_.find(user.roles, u => u === 'wmsadmin') && (
												<Button
													fontSize='tiny'
													margin='0 0 0 auto'
													color='white'
													backgroundColor='secondary'
													disabled={false}
													onClick={() => {
														handleReopen(content)
													}}>
													{t('wms:Reopen')}
												</Button>
											)}
									</FlexView>
								</FlexView>
							))}
					</FlexView>
					<FlexView
						flexDirection='row'
						width='100%'
						justifyContent='space-around'
						margin='8px 0 0 0'
						alignItems='center'>
						<Button
							backgroundColor='#667EEA'
							color='white'
							fontSize='18px'
							onClick={openModal}
							data-cy='button-open-modal'>
							{t('wms:ManageParts')}
							<Icon
								name='low-priority'
								width='19px'
								height='22px'
								margin='0 0 0 8px'
								color='white'
								style={{ transform: 'rotate(180deg)' }}
							/>
						</Button>
						<Button
							backgroundColor='#667EEA'
							color='white'
							fontSize='18px'
							margin='0 0 0 8px'
							disabled={!selectedInspection.lastinspdate}
							onClick={() => handleClick()}>
							{t('wms:LastReport')}
							<Icon name='download' color='white' width='18px' height='18px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
				</FlexView>
			)}
			<ManagePartsModal isOpen={openManageParts} onOutsideClick={() => setOpenManageParts(false)} />
			{periodicDetails && isEditorOpen && (
				<ReportEditorModal
					isOpen={isEditorOpen}
					onOutsideClick={() => setIsEditorOpen(false)}
					report={periodicDetails}
					reportType={type}
				/>
			)}
		</Card>
	)
}

export default PerInspInfo
