import React, { useState, useEffect } from 'react'
import auth from 'api/auth'
import Cookies from 'js-cookie'
import { LoadingOverlay } from 'components/common'

const initialState = {
  user: null,
  token: ''
}

export const UserContext = React.createContext(initialState)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [authState, setAuthState] = useState(null)
  const [token, setToken] = useState('')
  const [isLoading, setLoading] = useState(true)

  const signIn = (username, password) => new Promise((resolve, reject) => {
    auth.signIn(username, password).then(data => {
      setUser(data.user)
      setToken(data.token)
      setAuthState('SIGNED_IN')
      Cookies.set('shalyn_token', data.token)
      Cookies.set('Authorization-Bearer', data.token)
    }).catch(error => {
      reject(error)
    })
  })

  const resetAuthState = () => {
    setAuthState('LOGIN')
    setUser(null)
  }

  const forgotPassword = () => {
    setAuthState('FORGOT_PASSWORD')
  }

  const changePassword = (username, password, newPassword) => new Promise((resolve, reject) => {
    auth.changePassword(username, password, newPassword).then(() => {
      resolve()
    }).catch(error => {
      reject(error)
    })
  })

  const signOut = () => {
    setUser(null)
    setToken('')
    setAuthState(null)
    Cookies.remove('shalyn_token')
    Cookies.remove('Authorization-Bearer')
  }

  const renewToken = () => new Promise((resolve, reject) => {
    token && auth.renew(token).then(data => {
      setUser(data.user)
      setToken(data.token)
      setAuthState('SIGNED_IN')
      Cookies.set('shalyn_token', data.token)
      Cookies.set('Authorization-Bearer', data.token)
      resolve()
    }).catch(error => {
      reject(error)
    })
  })

  useEffect(() => {
    const renewInterval = setInterval(() => {
      renewToken().then(() => console.log('user token renewed')).catch(e => console.log(e))
    }, 900000)

    return () => {
      clearInterval(renewInterval)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const hydrate = () => new Promise((resolve, reject) => {
      try {
        const token = Cookies.get('shalyn_token')
        token ? auth.renew(token).then(data => {
          setUser(data.user)
          setToken(data.token)
          setAuthState('SIGNED_IN')
          Cookies.set('shalyn_token', data.token)
          Cookies.set('Authorization-Bearer', data.token)
          resolve()
        }).catch(error => {
          reject(error)
        }) : resolve()
      }
      catch (e) {
        reject(e)
      }
    })

    hydrate().catch(err => {
      console.log(err)
    }).then(() => {
      setLoading(false)
    })
  }, [])

  const renderStore = isLoading ?
    <LoadingOverlay visible={true} />
  :
    <UserContext.Provider value={{
      user,
      token,
      authState,
      resetAuthState,
      signIn,
      changePassword,
      signOut,
      forgotPassword
    }}>
      {children}
    </UserContext.Provider>

  return renderStore
}
