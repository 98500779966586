/** @format */

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView } from 'components/common'

import { RigReturnContext } from '../stores/RigReturnStore'
import PageTemplate from '../containers/common/PageTemplate'
import RigReturnInfo from '../containers/rigReturn/Info'
import RigReturnModal from '../containers/rigReturn/Modal'
import RigReturnList from '../containers/rigReturn/List'
import { entrance, genParams } from '../utils/hubs'

const RigReturnPage = () => {
	const { t } = useTranslation()
	const { selectedRR, setUploadRRList, rrModal, setRRModal } = useContext(RigReturnContext)

	const closeOptionsModal = () => {
		setRRModal(false)
		setUploadRRList(true)
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Entrance'),
				path: `/wms/entrance${genParams(entrance)}`
			},
			{
				name: t('wms:RigReturn'),
				path: '/wms/entrance/rigreturn'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:RigReturn')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='55%' margin='0 8px 0 0'>
						<RigReturnList data-cy='rigreturn-list' />
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{selectedRR && <RigReturnInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
			<RigReturnModal isOpen={rrModal} onOutsideClick={closeOptionsModal} />
		</PageTemplate>
	)
}

export default RigReturnPage
