/** @format */

import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, FlexView } from 'components/common'

import { useHistory, useLocation } from 'react-router-dom'
import PageTemplate from '../containers/common/PageTemplate'
import styled from 'styled-components'

const HubCard = styled(Card)`
	border-left: 4px solid ${({ theme }) => theme.colors.secondary};
`

const HubPage = () => {
	const { t } = useTranslation()

	const [title, setTitle] = useState(null)
	const [routes, setRoutes] = useState([])

	const history = useHistory()
	const redirectTo = path => {
		history.push(path)
	}

	const paramString = useLocation().search
	const params = new URLSearchParams(paramString)

	useEffect(() => {
		setTitle(`wms:${params.get('t')}`)
		setRoutes([...params.getAll('r')].map(route => JSON.parse(route)))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramString])

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t(title),
				path: null
			}
		],
		[t, title]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView
					data-cy='flexview-page-title'
					minWidth='100%'
					fontSize='40px'
					fontWeight='bold'
					margin='0 0 24px 0'>
					{t(title)} {t('wms:Hub')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView
						width='100%'
						margin='32px 0 0 0'
						flexDirection='row'
						flexWrap='wrap'
						justifyContent='space-between'
						alignItems='strech'>
						{routes && routes.length
							? routes.map((route, index) =>
									route.n === 'InspectionSettings' ? null : (
										<HubCard
											key={index}
											margin='0 0 32px'
											width='calc(40% - 48px)'
											data-cy='card-link'
											onClick={() => redirectTo(route.r)}
											alignItems='center'>
											<FlexView
												fontSize='20px'
												color='metalic'
												alignSelf='flex-start'
												margin='16px 0 0 0'
												padding='0'>
												{t('wms:GoTo')}
											</FlexView>
											<FlexView
												width='100%'
												fontSize='28px'
												fontWeight='bold'
												margin='0'
												padding='8px 16px 16px 16px'>
												{t(`wms:${route.n}`)}
											</FlexView>
										</HubCard>
									)
							  )
							: null}
					</FlexView>
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default HubPage
