/** @format */

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexView } from 'components/common'

import { ReportsContext } from '../stores/ReportStore'
import TabsRow from '../components/commom/TabsRow'
import { REPORT_TYPE } from '../utils/enum'
import ExportReportList from '../containers/reports/ExportList'
import { useParams } from 'react-router-dom'
import PageTemplate from '../containers/common/PageTemplate'
import { useWindowSize } from '../utils/hooks'
import { WMSContext } from '../stores/WmsStore'

const ExportReportsPage = () => {
	const { t } = useTranslation()
	const {
		reportType,
		setReportType,
		loadingReports,
		exportReportsList,
		getDeliveryNotes,
		getDispatchReports,
		getBackloadManifests,
		getModifProdcutReports,
		downloadBackloadManifest,
		downloadDeliveryNote,
		downloadDeliveryNotePdf,
		downloadDispatchReport,
		downloadModifProductsReport,
		downloadingReport
	} = useContext(ReportsContext)
	const { warehouse } = useContext(WMSContext)

	const listDeadSpace = 0
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const [page, setPage] = useState(null)
	let { report } = useParams()

	useEffect(() => {
		setPage(report)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report])

	useEffect(() => {
		if (page === 'delivery_notes') {
			setReportType(REPORT_TYPE.code.RIGPREP)
			getDeliveryNotes(REPORT_TYPE.code.RIGPREP)
				.then(() => {})
				.catch(e => console.error(e))
				.finally(() => {})
		} else if (page === 'dispatch') {
			setReportType(REPORT_TYPE.code.DISPATCH)
			getDispatchReports()
				.then(() => {})
				.catch(e => console.error(e))
				.finally(() => {})
		} else if (page === 'backload_manifest') {
			setReportType(REPORT_TYPE.code.BACKLOAD_MANIFEST)
			getBackloadManifests()
				.then(() => {})
				.catch(e => console.error(e))
				.finally(() => {})
		} else if (page === 'modif_products') {
			setReportType(REPORT_TYPE.code.MODIF_PRODUCTS)
			getModifProdcutReports()
				.then(() => {})
				.catch(e => console.error(e))
				.finally(() => {})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, warehouse])

	const handleDownloadReport = useCallback(
		value => {
			if (page === 'delivery_notes') {
				value &&
					downloadDeliveryNote(value.id, value.ownershipId, value.poNumber, reportType)
						.then(() => {})
						.catch(e => console.error(e))
						.finally(() => {})
			} else if (page === 'dispatch') {
				value &&
					downloadDispatchReport(value)
						.then(() => {})
						.catch(e => console.error(e))
						.finally(() => {})
			} else if (page === 'backload_manifest') {
				value &&
					downloadBackloadManifest(value)
						.then(() => {})
						.catch(e => console.error(e))
						.finally(() => {})
			} else if (page === 'modif_products') {
				value &&
					downloadModifProductsReport(value)
						.then(() => {})
						.catch(e => console.error(e))
						.finally(() => {})
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[
			downloadDeliveryNote,
			downloadDispatchReport,
			downloadBackloadManifest,
			downloadModifProductsReport,
			page,
			reportType
		]
	)

	const handleDownloadReportPdf = useCallback(
		value => {
			if (page === 'delivery_notes') {
				downloadDeliveryNotePdf(reportType, value.id, value.ownershipId, value.poNumber)
					.then(() => {})
					.catch(e => console.error(e))
					.finally(() => {})
			}
		},
		[downloadDeliveryNotePdf, page, reportType]
	)

	const getPageName = useCallback(() => {
		return page === 'delivery_notes'
			? t('wms:DeliveryNotes')
			: page === 'dispatch'
			? t('wms:DispatchReports')
			: page === 'backload_manifest'
			? t('wms:BackloadManifest')
			: t('wms:ModifiedProductReports')
	}, [page, t])

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: getPageName(),
				path: null
			}
		],
		[getPageName]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView
					data-cy='flexview-page-title'
					minWidth='100%'
					fontSize='40px'
					fontWeight='bold'
					margin='0 0 24px 0'>
					{getPageName()}
				</FlexView>
				{page === 'delivery_notes' ? (
					<TabsRow
						tabsList={REPORT_TYPE.translateKey.slice(0, 4).map((item, index) => ({ value: index, label: item }))}
						selectedTab={reportType}
						setSelectedTab={setReportType}
						tabWidth={500}
						width={window.innerWidth - 320}
						data-cy='tabs-row'
					/>
				) : null}
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='80%' ref={listRef} margin='0 8px 0 0'>
						<ExportReportList
							loadingList={loadingReports}
							list={exportReportsList}
							reportType={reportType}
							onDownloadReport={value => handleDownloadReport(value)}
							onDownloadReportPdf={page === 'delivery_notes' && (value => handleDownloadReportPdf(value))}
							height={`${listHeight}px`}
							downloadingReport={downloadingReport}
							data-cy='export-report-list'
						/>
					</FlexView>
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default ExportReportsPage
