/** @format */

import { NominalDataContext } from 'apps/wms/stores/NominalDataModifStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Card, FlexView, LoadingOverlay } from 'components/common'
import { Select } from 'components/form'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Toggle from '../../components/commom/Toggle'
import Checkbox from '../../components/forms/Checkbox'

const SourceParts = () => {
	const { t } = useTranslation()
	const {
		parts,
		selectedPart,
		setSelectedPart,
		loadingItems,
		entranceList,
		entranceSelected,
		setEntranceSelected,
		loadingEntrance,
		modifAllEntrance,
		setModifAllEntrance,
		itemsToShow,
		setItemsToShow,
		selectedItem,
		setSelectedItem
	} = useContext(NominalDataContext)

	const listDeadSpace = 180
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	const handleShow = (value, part, list, i) => {
		part.show = value
		let idx = list.indexOf(part)
		idx > -1 ? list.splice(idx, 1) : list.push(part)
		setSelectedPart([...selectedPart])
	}

	const handleSelect = (value, part, index) => {
		let valid = []
		part.selectall = value
		setSelectedPart([...selectedPart])
		if (!part.selectall) {
			_.forEach(itemsToShow, (it, i) => {
				if (it.belcid === part.belcid) {
					selectedItem.splice(i)
					setSelectedItem([...selectedItem])
				}
			})
		} else {
			_.forEach(itemsToShow, (it, i) => {
				if (it.belcid === part.belcid) {
					valid.push(it)
				}
			})
			let array = _.concat(valid, selectedItem)
			array = _.uniqBy(array, 'valid')
			setSelectedItem(array)
		}
	}

	const checkProps = value => {
		let isDisabled = true
		isDisabled = isDisabled && value.show === false
		return isDisabled
	}

	const selectEntrance = value => {
		setEntranceSelected(value)
		setItemsToShow(null)
		setSelectedPart([])
		setSelectedItem([])
	}

	const existsItemsByPart = (part, list) => {
		return list.indexOf(part) > -1
	}

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		modifAllEntrance && setSelectedPart([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modifAllEntrance])

	useEffect(() => {
		setSelectedPart([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entranceSelected])

	return (
		<FlexView width='45%' flexDirection='column' ref={listRef} style={{ position: 'relative' }} height='100%'>
			<Card width='90%'>
				<LoadingOverlay visible={loadingEntrance} borderRadius='card' />
				{entranceList && (
					<FlexView width='100%' flexDirection='row' justifyContent='space-between'>
						<Select
							searchable
							width='50%'
							margin='0'
							label={t('wms:SourceEntrance')}
							placeholder={t('wms:SelectOption')}
							options={_.map(entranceList, entrance => {
								return { value: entrance.id, label: entrance.name }
							})}
							value={entranceSelected}
							onChange={v => selectEntrance(v)}
							data-cy='select-entrance'
						/>
						<FlexView flexDirection='column' width='50%' alignItems='center' margin='0 0 0 8px'>
							<FlexView
								fontSize='medium'
								fontWeight='bold'
								margin='0 0 8px 16px'
								width='100%'
								style={{ textAlign: 'center' }}
								alignItems='center'>{`${t('wms:ModifAll')} ?`}</FlexView>
							<Toggle
								margin='8px 0 0 16px'
								textOff={t('wms:No')}
								textOn={t('wms:Yes')}
								alternate
								state={modifAllEntrance}
								onChange={v => setModifAllEntrance(v)}
								disabled={!entranceSelected}
							/>
						</FlexView>
					</FlexView>
				)}
			</Card>
			<Card width='90%' height={`${listHeight}px`} style={{ overflowY: 'scroll' }}>
				<LoadingOverlay visible={loadingItems} borderRadius='card' />
				{modifAllEntrance === false && parts ? (
					<FlexView flexDirection='row' width='100%' justifyContent='space-between'>
						<FlexView flexDirection='column' width='33%'>
							<FlexView
								margin='0 0 8px 0'
								width='100%'
								fontSize='medium'
								fontWeight='bold'
								color='metalic'
								style={{ alignItems: 'center', textAlign: 'center' }}>
								{t('wms:Parts')}
							</FlexView>
							{parts &&
								_.map(parts, (part, index) => (
									<FlexView
										margin='2px 0 0 0'
										padding='4px 0 0 0'
										height='36px'
										key={index}
										width='100%'
										justifyContent='center'
										style={{ alignItems: 'center', textAlign: 'center' }}>{`${t('wms:Part')} ${
										index + 1
									}`}</FlexView>
								))}
						</FlexView>
						<FlexView flexDirection='column' width='33%'>
							<FlexView
								margin='0 0 8px 0'
								width='100%'
								fontSize='medium'
								fontWeight='bold'
								color='metalic'
								style={{ alignItems: 'center', textAlign: 'center' }}>
								{t('wms:Show')}
							</FlexView>
							{parts &&
								_.map(parts, (part, index) => (
									<FlexView
										margin='2px 0 0 0'
										padding='4px 0 0 0'
										key={index}
										width='100%'
										style={{ alignItems: 'center', textAlign: 'center' }}>
										<Checkbox
											value={selectedPart.show}
											checked={existsItemsByPart(part, selectedPart)}
											onChange={v => handleShow(v, part, selectedPart, index)}
										/>
									</FlexView>
								))}
						</FlexView>
						<FlexView flexDirection='column' width='33%'>
							<FlexView
								margin='0 0 8px 0'
								width='100%'
								fontSize='medium'
								fontWeight='bold'
								color='metalic'
								style={{ alignItems: 'center', textAlign: 'center' }}>
								{t('wms:SelectAll')}
							</FlexView>
							{parts &&
								_.map(parts, (part, index) => (
									<FlexView
										margin='2px 0 0 0'
										padding='4px 0 0 0'
										key={index}
										width='100%'
										style={{ alignItems: 'center', textAlign: 'center' }}>
										<Checkbox
											value={part.selectall}
											onChange={v => handleSelect(v, part, index)}
											disabled={checkProps(part, index)}
										/>
									</FlexView>
								))}
						</FlexView>
					</FlexView>
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='no-batch-list'>
						{t('wms:NoPartToShow')}
					</FlexView>
				)}
			</Card>
		</FlexView>
	)
}

export default SourceParts
