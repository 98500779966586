/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { toast } from 'react-toastify'
import modifprod from '../api/modifprod'
import common from '../api/common'
import batch from '../api/batch'
import { WMSContext } from './WmsStore'
import _ from 'lodash'

const initialState = {
	modifProdList: null,
	selectedList: null,
	updateContent: null
}

export const ModifiedProductContext = React.createContext(initialState)

export const ModifiedProductProvider = ({ children }) => {
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse, getLevelsData } = useContext(WMSContext)
	const [levels, setLevels] = useState()
	const [modifProdList, setModifProdList] = useState(null)
	const [materials, setMaterials] = useState(null)
	const [loadingMPList, setLoadingMPList] = useState(false)
	const [loadingMaterials, setLoadingMaterials] = useState(false)
	const [endUsers, setEndUsers] = useState([])
	const [selectedList, setSelectedList] = useState(null)
	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [openAddModal, setOpenAddModal] = useState(false)
	const [updateModifProduct, setUpdateModifProduct] = useState(false)
	const [expectedQuantity, setExpectedQuantity] = useState(0)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [modifProductInfo, setModifProductInfo] = useState(null)
	const [materialByList, setMaterialByList] = useState(null)
	const [endUser, setEndUser] = useState(null)
	const [loadingEndUsers, setLoadingEndUsers] = useState(false)
	const [level, setLevel] = useState(null)
	const [sourceMaterial, setSourceMaterial] = useState(null)
	const [targetMaterial, setTargetMaterial] = useState(null)
	const [newContent, setNewContent] = useState([])
	const [modifProductName, setModifProductName] = useState('')
	const [mpReference, setMpReference] = useState('')
	const [expectedDate, setExpectedDate] = useState('')
	const [saveMPList, setSaveMPList] = useState(false)
	const [selected, setSelected] = useState(false)
	const [updateContent, setUpdateContent] = useState(null)
	const [deletedContent, setDeletedContent] = useState([])
	const [erFile, setERFile] = useState(null)
	const [updateList, setUpdateList] = useState(false)
	const [backendFile, setBackendFile] = useState(undefined)
	const [sourceMatList, setSourceMatList] = useState(null)
	const [materialList, setMaterialList] = useState(null)
	const [matDesc, setMatDesc] = useState(null)

	const getModifiedProductLists = () =>
		new Promise((resolve, reject) => {
			modifprod
				.getModifiedProductLists(warehouse.id, token)
				.then(response => {
					setModifProdList(response)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject()
					toast.error(t('wms:ErrorGettingModifiedProductList'))
				})
		})

	const getMaterials = () =>
		new Promise((resolve, reject) => {
			common
				.getMaterials(warehouse.id, token)
				.then(response => {
					setMaterialList(response.materialdescriptions)
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingMaterialDsc')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getTargetMaterialList = () =>
		new Promise((resolve, reject) => {
			modifprod
				.getTargetMaterials(warehouse.id, endUser, token)
				.then(response => {
					setMaterials(response)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(t('wms:ErrorGettingMaterialDsc'))
				})
		})

	const getSourceMaterialList = () =>
		new Promise((resolve, reject) => {
			batch
				.getMaterials(warehouse.id, endUser, token)
				.then(response => {
					setSourceMatList(response)
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingMaterialDsc')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getEndUsers = () =>
		new Promise((resolve, reject) => {
			common
				.getEndUsers(warehouse.id, token)
				.then(response => {
					setEndUsers(response)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(t('wms:ErrorGettingEndUser'))
				})
		})

	const uploadMPFile = file =>
		new Promise((resolve, reject) => {
			if (window.FileReader) {
				const reader = new FileReader()
				reader.onload = event => {
					event.target.result &&
						modifprod
							.uploadFile(warehouse.id, event.target.result, token)
							.then(fileName => {
								setBackendFile(fileName)
								resolve(fileName)
							})
							.catch(e => {
								toast.error(t('wms:UploadFailure'))
								reject(e)
							})
							.finally(() => {})
				}
				reader.onerror = () => {
					toast.error(t('wms:ErrorReadingFile'))
				}
				reader.readAsDataURL(file)
			} else reject('Filereader not found')
		})

	const saveModifProductList = mpData =>
		new Promise((resolve, reject) => {
			let payload = {
				name: mpData.name,
				enduserid: mpData.enduserid,
				erref: mpData.erref,
				erfile: backendFile,
				expecteddate: mpData.expecteddate,
				responsible: user.name,
				content: newContent.map(content => ({
					ownershipid: mpData.ownershipid,
					mid: targetMaterial,
					midsource: sourceMaterial,
					quantity: content.quantity,
					uuid: ''
				}))
			}
			modifprod
				.saveModifiedProductList(warehouse.id, payload, token)
				.then(response => {
					toast.success(t('wms:ModifiedProductCreated'))
					resolve(response)
					setOpenAddModal(false)
					getModifiedProductLists()
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorCreatingModifiedProductList')} [ ${e.status} ]: ${e.data}`)
					reject(e)
					setOpenAddModal(false)
				})
				.finally(() => setOpenAddModal(false))
		})

	const deleteMPList = () =>
		new Promise((resolve, reject) => {
			modifprod
				.deleteModifiedProductList(warehouse.id, selectedList.id, token)
				.then(response => {
					_.remove(modifProdList, modif => modif.id === selectedList.id)
					setModifProdList([...modifProdList])
					setModifProductInfo(null)
					resolve()
					toast.success(t('wms:SuccessfullyDeleteModProdList'))
					getModifiedProductLists()
				})
				.catch(e => {
					console.error(e)
					toast.error(t('wms:ErrorDeletingModifiedProductList'))
					reject(e)
				})
				.finally(() => {
					setOpenConfirmModal(false)
					setModifProductInfo(null)
				})
		})

	const updateModifProductList = mpData =>
		new Promise((resolve, reject) => {
			let contentUpdated = []
			_.forEach(updateContent.content, c => {
				if (c.status === undefined) contentUpdated.push(c)
			})
			_.forEach(deletedContent, c => {
				contentUpdated.push(c)
			})
			let payload = {
				name: updateContent.name,
				enduserid: endUser ? endUser : updateContent.enduserid ? updateContent.enduserid : updateContent.enduser,
				erref: updateContent.erref,
				erfile: backendFile,
				expecteddate: updateContent.expecteddate,
				responsible: user.name,
				content: contentUpdated.map(ct => ({
					...ct,
					mid: ct.mid,
					midsource: ct.midsource,
					quantity: ct.quantity,
					ownershipid: updateContent.ownershipid
				}))
			}
			modifprod
				.updateModifiedProductList(warehouse.id, payload, selectedList.id, token)
				.then(response => {
					toast.success(t('wms:SuccesfullyUpdateModifList'))
					resolve()
					setSelectedList(null)
					setOpenUpdateModal(false)
					setUpdateContent(null)
					setBackendFile(null)
					setDeletedContent([])
					getModifiedProductLists()
				})
				.catch(e => {
					toast.error(e.data)
					console.error(e)
					reject(e)
				})
		})

	const handleReopen = content =>
		new Promise((resolve, reject) => {
			let payload = {
				mpcid: content.mpcid
			}
			modifprod
				.reopenModif(warehouse.id, payload, token)
				.then(response => {
					toast.success(t(`wms:SuccessfullyReopenContent`))
					resolve(true)
					getModifiedProductLists()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorReopenContent')} [ ${e.status} ]: ${e.data}`)
				})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingMPList(true)
				await getModifiedProductLists()
			} catch (e) {
				setLoadingMPList(false)
				console.error(e)
			} finally {
				setLoadingMPList(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (endUser) {
			const hydrate = async () => {
				try {
					setLoadingMaterials(true)
					await getTargetMaterialList()
				} catch (e) {
					setLoadingMaterials(false)
					console.error(e)
				} finally {
					setLoadingMaterials(false)
				}
			}
			hydrate()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endUser, warehouse])

	useEffect(() => {
		if (endUser) {
			const hydrate = async () => {
				try {
					setLoadingMaterials(true)
					await getSourceMaterialList()
				} catch (e) {
					setLoadingMaterials(false)
					console.error(e)
				} finally {
					setLoadingMaterials(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endUser, warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingEndUsers(true)
				await getEndUsers()
			} catch (e) {
				setLoadingEndUsers(false)
				console.error(e)
			} finally {
				setLoadingEndUsers(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		getLevelsData()
			.then(ownerships => setLevels(ownerships))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (selected) {
			const hydrate = async () => {
				try {
					let ct = []
					_.forEach(selectedList.content, content => {
						_.forEach(materialList, material => {
							if (content.mid === material.mvid)
								ct.push({
									makeuploss: content.makeuploss,
									mid: content.mid,
									midsource: content.midsource,
									mpcid: content.mpcid,
									quantity: content.quantity,
									status: content.status,
									uuid: content.uuid,
									material: material.formatted,
									ownership: content.ownership
								})
						})
					})
					setSelectedList({ ...selectedList, content: ct })
				} catch (e) {
					console.error(e)
					setSelected(false)
				} finally {
					setSelected(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected])

	useEffect(() => {
		if (openUpdateModal) {
			let modif = _.find(modifProdList, mod => {
				return mod.id === selectedList.id
			})
			setUpdateContent({ ...modif, content: [...modif.content] })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUpdateModal])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getMaterials()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		let material = _.find(materials, mat => mat.id === targetMaterial)
		targetMaterial && material && setMatDesc(material.desc)
	}, [targetMaterial, materials])

	useEffect(() => {
		setSourceMaterial(null)
		setTargetMaterial(null)
	}, [openAddModal, openUpdateModal])

	const renderStore = (
		<ModifiedProductContext.Provider
			value={{
				levels,
				setLevels,
				modifProdList,
				setModifProdList,
				materials,
				setMaterials,
				loadingMPList,
				setLoadingMPList,
				loadingMaterials,
				setLoadingMaterials,
				endUsers,
				setEndUsers,
				selectedList,
				setSelectedList,
				openUpdateModal,
				setOpenUpdateModal,
				openAddModal,
				setOpenAddModal,
				updateModifProduct,
				setUpdateModifProduct,
				expectedQuantity,
				setExpectedQuantity,
				openConfirmModal,
				setOpenConfirmModal,
				modifProductInfo,
				setModifProductInfo,
				materialByList,
				setMaterialByList,
				endUser,
				setEndUser,
				loadingEndUsers,
				setLoadingEndUsers,
				level,
				setLevel,
				sourceMaterial,
				setSourceMaterial,
				newContent,
				setNewContent,
				modifProductName,
				setModifProductName,
				mpReference,
				setMpReference,
				expectedDate,
				setExpectedDate,
				saveMPList,
				setSaveMPList,
				selected,
				setSelected,
				updateContent,
				setUpdateContent,
				deletedContent,
				setDeletedContent,
				erFile,
				setERFile,
				updateList,
				setUpdateList,
				backendFile,
				setBackendFile,
				uploadMPFile,
				saveModifProductList,
				updateModifProductList,
				deleteMPList,
				sourceMatList,
				setSourceMatList,
				targetMaterial,
				setTargetMaterial,
				materialList,
				setMaterialList,
				matDesc,
				setMatDesc,
				handleReopen
			}}>
			{children}
		</ModifiedProductContext.Provider>
	)
	return renderStore
}
