/** @format */

import { AssetsSettingsContext } from 'apps/wms/stores/AssetsSettingsStore'
import { Button, Card, FlexView } from 'components/common'
import { Input } from 'components/form'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const PerInspCard = () => {
	const { t } = useTranslation()
	const { perInspStg, setPerInspStg, setSavePerInsp } = useContext(AssetsSettingsContext)

	return (
		<FlexView flex='1' margin='0' height='fit-content' style={{ position: 'relative' }} alignItems='stretch'>
			<Card width='30%' flex='1' margin='0' style={{ position: 'relative' }} data-cy='card-perinsp'>
				<FlexView width='100%' fontSize='big' fontWeight='bold' margin='0 0 16px 0'>
					{t('wms:PeriodicInspection')}
				</FlexView>
				<Input
					type='number'
					min='1'
					placeholder={t('wms:InspectionFreq')}
					label={t('wms:InspectionFreq')}
					value={perInspStg.frequence}
					onChange={e => setPerInspStg({ ...perInspStg, frequence: parseInt(e.target.value) })}
					width='100%'
					minWidth='100%'
					margin='0 0 8px 0'
					data-cy='input-perinsp-frequency'
				/>
				<Input
					type='number'
					min='1'
					placeholder={t('wms:InspectionSampling')}
					label={t('wms:InspectionSampling')}
					value={perInspStg.sampling}
					onChange={e => setPerInspStg({ ...perInspStg, sampling: parseInt(e.target.value) })}
					width='100%'
					minWidth='100%'
					margin='0 0 8px 0'
					data-cy='input-perinsp-sampling'
				/>
				<FlexView width='100%' alignItems='center'>
					<Button
						disabled={!perInspStg.frequence || !perInspStg.sampling}
						backgroundColor='success'
						color='white'
						onClick={() => setSavePerInsp(true)}
						data-cy='button-save-perinsp'>
						{t('wms:Save')}
					</Button>
				</FlexView>
			</Card>
		</FlexView>
	)
}

export default PerInspCard
