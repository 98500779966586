/** @format */

import TabsView from 'apps/wms/components/commom/TabsView'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PerInspCard from '../assets/settings/PerInspCard'

const StyledTabs = styled(TabsView)`
	.react-tabs__tab--selected {
		background-color: ${({ theme }) => theme.colors.secondary};
		color: white;
	}
	.react-tabs__tab-list {
		margin: 0 0 16px 0;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}
	.react-tabs__tab {
		width: 30%;
		max-width: 30%;
		margin: 0px;
	}
	.react-tabs__tab-panel--selected {
		display: flex !important;
		height: 90% !important;
	}
`

const SettingsTabs = () => {
	const { t } = useTranslation()
	const { currentTab, setCurrentTab } = useContext(WMSContext)

	const settingsOptions = [
		{
			title: t('wms:Inspection'),
			content: <PerInspCard />,
			key: 'card'
		}
	]

	return (
		<StyledTabs
			selectedIndex={currentTab}
			onSelect={setCurrentTab}
			data={settingsOptions}
			margin='0'
			style={{ minHeight: '90%' }}
		/>
	)
}

export default SettingsTabs
