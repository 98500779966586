/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'
import { AssetsMaterialContext } from 'apps/wms/stores/AssetsMaterialStore'
import { useDrop } from 'react-dnd'
import DraggableAttribute from './DraggableAttribute'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import Checkbox from 'apps/wms/components/forms/Checkbox'
import styled from 'styled-components'

const CollapseBar = styled('div')`
	&#colapseBar {
		transition: width 1s ease;
		display: block;
		width: 60px;
	}
	&#colapseBar.active {
		width: 30vw;
	}
`

const MaterialConfig = ({ height }) => {
	const { t } = useTranslation()
	const { setWarehouse, warehouse } = useContext(WMSContext)
	const { isLoading, materialConfig, attributesList, updateMaterialConfig } = useContext(AssetsMaterialContext)
	const [attrSpam, setAttrSpam] = useState(false)
	const [listSpam, setListSpam] = useState(false)
	const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)

	const [attrUseCtrl, setAttrUseCtrl] = useState(() =>
		attributesList.map(a => ({
			isMandatory: a.mandatory,
			isExtension: a.mandatoryextension,
			name: a.name,
			id: a.id,
			isSelected: !!materialConfig.attributeids.find(attr => attr === a.id),
			attrIndex: materialConfig ? materialConfig.attributeids.findIndex(attr => attr === a.id) : 0
		}))
	)

	const [attrDisplayCtrl, setAttrDisplayCtrl] = useState([])
	const [attrListOrder, setAttrListOrder] = useState([])
	const [cpAttrListOrder, setCpAttrListOrder] = useState([])

	useEffect(() => {
		const attr = attributesList.map(a => ({
			isMandatory: a.mandatory,
			isExtension: a.mandatoryextension,
			name: a.name,
			id: a.id,
			isSelected: materialConfig ? !!materialConfig.attributeids.find(attr => attr === a.id) : false,
			attrIndex: materialConfig ? materialConfig.attributeids.findIndex(attr => attr === a.id) : 0
		}))
		setAttrUseCtrl(attr)
		let displayCtrl = attr.filter(a => a.isSelected).map(a => ({ ...a, isSelected: false, order: null }))
		const attrOrder = materialConfig
			? materialConfig.formatstring
					.split(/\{([0-9]+)\}/)
					.filter(c => c !== '' && c !== ' ')
					.map(c => parseInt(c))
			: []
		attrOrder.forEach((a, index) => (displayCtrl[a] = { ...displayCtrl[a], isSelected: true, order: index }))
		setAttrDisplayCtrl(displayCtrl)
		setAttrListOrder(displayCtrl.filter(a => a.isSelected).sort((a, b) => a.order - b.order))
		setCpAttrListOrder([...displayCtrl.filter(a => a.isSelected).sort((a, b) => a.order - b.order)])
	}, [attributesList, materialConfig])

	const toggleAttrDisplaySelection = aid => {
		const attrIndex = attrDisplayCtrl.findIndex(attr => attr.id === aid)
		if (attrIndex === -1) return
		const newData = {
			...attrDisplayCtrl[attrIndex],
			isSelected: !attrDisplayCtrl[attrIndex].isSelected,
			order: !attrDisplayCtrl[attrIndex].isSelected ? attrListOrder.length - 1 : 0
		}

		attrDisplayCtrl[attrIndex] = newData
		setAttrDisplayCtrl([...attrDisplayCtrl])

		newData.isSelected && setAttrListOrder([...attrListOrder, newData])

		const orderIndex = attrListOrder.findIndex(attr => attr.id === newData.id)
		if (orderIndex === -1) return
		attrListOrder.splice(orderIndex, 1)
		!newData.isSelected && setAttrListOrder([...attrListOrder])
	}

	const toggleAttrUseCtrlSelection = aid => {
		const attrIndex = attrUseCtrl.findIndex(attr => attr.id === aid)
		if (attrIndex === -1) return
		const newData = {
			...attrUseCtrl[attrIndex],
			isSelected: !attrUseCtrl[attrIndex].isSelected,
			order: !attrUseCtrl[attrIndex].isSelected ? attrListOrder.length - 1 : 0
		}
		attrUseCtrl[attrIndex] = newData
		setAttrUseCtrl([...attrUseCtrl])

		const orderIndex = cpAttrListOrder.findIndex(attr => attr.id === newData.id)
		if (orderIndex === -1) return
		cpAttrListOrder.splice(orderIndex, 1)
		!newData.isSelected && setCpAttrListOrder([...cpAttrListOrder])
	}

	const [, drop] = useDrop({
		accept: 'attribute',
		drop: () => ({ name: 'attrBackLog' })
	})

	const moveHandler = (dragIndex, hoverIndex) =>
		moveCardHandler(dragIndex, hoverIndex, attrListOrder, setAttrListOrder)

	const moveCardHandler = (dragIndex, hoverIndex, items, setFn) => {
		const dragItem = items[dragIndex]

		if (dragItem) {
			setFn(prevState => {
				const coppiedStateArray = [...prevState]
				const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem)
				coppiedStateArray.splice(dragIndex, 1, prevItem[0])
				return coppiedStateArray
			})
		}
	}

	const onOrderUpdate = async () => {
		try {
			setIsAwaitingResponse(true)
			const payload = {
				...materialConfig,
				formatstring: attrListOrder.reduce((prev, curr, index) => prev + `{${curr.attrIndex}} `, '')
			}
			await updateMaterialConfig(payload)
			setIsAwaitingResponse(false)
			setWarehouse({ ...warehouse })
		} catch (e) {
			setIsAwaitingResponse(false)
			console.error(e)
		}
	}

	const onAttributesUpdate = async () => {
		try {
			setIsAwaitingResponse(true)
			const payload = {
				...materialConfig,
				formatstring: cpAttrListOrder.reduce((prev, curr, index) => prev + `{${curr.attrIndex}} `, ''),
				attributeids: attrUseCtrl.filter(a => a.isSelected).map(a => a.id)
			}
			await updateMaterialConfig(payload)
			setIsAwaitingResponse(false)
			setWarehouse({ ...warehouse })
		} catch (e) {
			setIsAwaitingResponse(false)
			console.error(e)
		}
	}

	return (
		<FlexView flexDirection='column' width='fit-content' height='100%' minHeight='100%'>
			<FlexView width='fit-content' flexDirection='row' margin='0px' padding='0px' justifyContent='space-between'>
				<CollapseBar id='colapseBar' className={attrSpam ? 'active' : ''}>
					{!attrSpam ? (
						<Card
							data-cy='card-list-wrapper'
							width='calc(100% - 24px)'
							margin='0 8px 0 0'
							padding='16px 8px'
							style={{ position: 'relative', overflow: 'auto' }}
							height={`${height - 48}px`}
							justifyContent='space-between'
							alignItems='center'>
							<Button
								fontSize='tiny'
								margin='0 0 16px 0'
								padding='0'
								color='metalic'
								backgroundColor='white'
								onClick={() => setAttrSpam(!attrSpam)}>
								<Icon
									name='chevron-right'
									width='20px'
									height='20px'
									color='metalic'
									margin='0px'
									fontWeight='bold'
								/>
							</Button>
							<FlexView
								style={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}
								margin='auto 0'
								flexDirection='column'>
								{t('wms:AvailableAttributes')}
							</FlexView>
						</Card>
					) : (
						<>
							<Card
								data-cy='card-list-wrapper'
								width='calc(100% - 56px)'
								margin='0'
								style={{ position: 'relative', overflow: 'auto' }}
								height={`${height - 48}px`}>
								<LoadingOverlay borderRadius='card' visible={isLoading || isAwaitingResponse} />
								<FlexView
									width='100%'
									flexDirection='row'
									fontSize='medium'
									fontWeight='bold'
									margin='0 0 8px 0'>
									{t('wms:DefineWarehouseAttributes')}

									<Button
										fontSize='tiny'
										margin='0 0 0 auto'
										padding='0'
										color='metalic'
										backgroundColor='white'
										onClick={() => setAttrSpam(!attrSpam)}>
										<Icon
											name='chevron-left'
											width='20px'
											height='20px'
											color='metalic'
											margin='0px'
											fontWeight='bold'
										/>
									</Button>
								</FlexView>
								<FlexView flexDirection='row' flexWrap='wrap'>
									<FlexView
										width='100%'
										fontWeight='bold'
										fontSize='tiny'
										color='metalic'
										margin='16px 0 4px 0'>
										{t('wms:OptionalAttributes')}
									</FlexView>
									{attrUseCtrl && attrUseCtrl.length
										? attrUseCtrl
												.filter(a => !a.isExtension && !a.isMandatory)
												.map((a, index) => (
													<Checkbox
														width='50%'
														fontSize='small'
														key={index}
														checked={a.isSelected}
														onChange={v => toggleAttrUseCtrlSelection(a.id)}
														label={a.name}
													/>
												))
										: null}

									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
										{t('wms:MandatoryAttributes')}
									</FlexView>
									{attrUseCtrl && attrUseCtrl.length
										? attrUseCtrl
												.filter(a => a.isMandatory && !a.isExtension)
												.map((a, index) => (
													<Checkbox
														width='50%'
														fontSize='small'
														key={index}
														checked={a.isSelected}
														disabled={true}
														onChange={v => toggleAttrUseCtrlSelection(a.id)}
														label={a.name}
													/>
												))
										: null}
									<FlexView
										width='100%'
										fontWeight='bold'
										fontSize='tiny'
										color='metalic'
										margin='16px 0 4px 0'>
										{t('wms:CrossOverAttributes')}
									</FlexView>
									{attrUseCtrl && attrUseCtrl.length
										? attrUseCtrl
												.filter(a => a.isExtension)
												.map((a, index) => (
													<Checkbox
														width='50%'
														fontSize='small'
														key={index}
														checked={a.isSelected}
														disabled={true}
														onChange={v => toggleAttrUseCtrlSelection(a.id)}
														label={a.name}
													/>
												))
										: null}
								</FlexView>
							</Card>
							<Button
								fontSize='tiny'
								margin='16px 8px 0 auto'
								padding='8px 16px'
								color='white'
								backgroundColor='secondary'
								//disabled={!attrListOrder.find((a, index) => a.order !== index)}
								onClick={() => onAttributesUpdate()}>
								{`${t('wms:Save')} ${t('wms:WarehouseAttributes')}`}
								<Icon
									name='save'
									width='16px'
									height='16px'
									color='white'
									margin='0px 0px 0px 8px'
									fontWeight='bold'
								/>
							</Button>
						</>
					)}
				</CollapseBar>
				<CollapseBar id='colapseBar' className={listSpam ? 'active' : ''}>
					{!listSpam ? (
						<Card
							data-cy='card-list-wrapper'
							width='calc(100% - 24px)'
							margin='0 0 0 8px'
							padding='16px 8px'
							style={{ position: 'relative', overflow: 'auto' }}
							height={`${height - 48}px`}
							justifyContent='space-between'
							alignItems='center'>
							<Button
								fontSize='tiny'
								margin='0 0 16px 0'
								padding='0'
								color='metalic'
								backgroundColor='white'
								onClick={() => setListSpam(!listSpam)}>
								<Icon
									name='chevron-right'
									width='20px'
									height='20px'
									color='metalic'
									margin='0px'
									fontWeight='bold'
								/>
							</Button>
							<FlexView
								style={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}
								margin='auto 0'
								flexDirection='column'>
								{t('wms:FormatString')}
							</FlexView>
						</Card>
					) : (
						<>
							<Card
								data-cy='card-list-wrapper'
								width='calc(100% - 48px)'
								margin='0'
								style={{ position: 'relative', overflowY: 'scroll' }}
								height={`${height - 48}px`}>
								<LoadingOverlay borderRadius='card' visible={isLoading || isAwaitingResponse} />
								<FlexView
									width='100%'
									flexDirection='row'
									fontSize='medium'
									fontWeight='bold'
									margin='0 0 8px 0'>
									{t('wms:FormatString')}
									<Button
										fontSize='tiny'
										margin='0 0 0 auto'
										padding='0'
										color='metalic'
										backgroundColor='white'
										onClick={() => setListSpam(!listSpam)}>
										<Icon
											name='chevron-left'
											width='20px'
											height='20px'
											color='metalic'
											margin='0px'
											fontWeight='bold'
										/>
									</Button>
								</FlexView>

								<FlexView flexDirection='column' width='100%' ref={drop}>
									{attrListOrder
										.filter(a => a.isSelected)
										.map((attr, index) => (
											<DraggableAttribute
												key={attr.id}
												index={index}
												id={attr.id}
												desc={attr.name}
												data={attr}
												moveHandler={moveHandler}
												toggleHandler={toggleAttrDisplaySelection}
											/>
										))}
									{attrDisplayCtrl
										.filter(a => !a.isSelected)
										.map((attr, index) => (
											<DraggableAttribute
												key={attr.id}
												index={index}
												id={attr.id}
												desc={attr.name}
												data={attr}
												moveHandler={moveHandler}
												toggleHandler={toggleAttrDisplaySelection}
												disabled={true}
											/>
										))}
								</FlexView>
							</Card>
							<Button
								fontSize='tiny'
								margin='16px 0 0 auto'
								padding='8px 16px'
								color='white'
								backgroundColor='secondary'
								//disabled={!attrListOrder.find((a, index) => a.order !== index)}
								onClick={() => onOrderUpdate()}>
								{listSpam || attrSpam ? `${t('wms:Save')} ${t('wms:MaterialConfiguration')}` : null}
								<Icon
									name='save'
									width='16px'
									height='16px'
									color='white'
									margin='0px 0px 0px 8px'
									fontWeight='bold'
								/>
							</Button>
						</>
					)}
				</CollapseBar>
			</FlexView>
		</FlexView>
	)
}

export default MaterialConfig
