/** @format */

import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { Input } from 'components/form'
import React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const AddModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { newItem, setNewItem, saveItem, newDefect, setNewDefect, saveDefect, chosenDefects, chosenItems } =
		useContext(AssetsInspSettingsContext)
	const [savingItem, setSavingItem] = useState(false)
	const [savingDefect, setSavingDefect] = useState(false)

	const saveNewItem = () => {
		setSavingItem(true)
		saveItem(newItem)
			.then(() => {
				setNewItem('')
			})
			.catch(error => {
				console.error(error)
			})
			.finally(() => setSavingItem(false))
	}

	const saveNewDefect = () => {
		setSavingDefect(true)
		saveDefect(newDefect)
			.then(() => {
				setNewDefect('')
			})
			.catch(error => {
				console.error(error)
			})
			.finally(() => setSavingDefect(false))
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card height='25%' width='25%' data-cy='card-add-items'>
				<FlexView width='100%' fontSize='subtitle' fontWeight='bold'>
					{chosenItems
						? `${t('wms:Add')} ${t('wms:Items')}`
						: chosenDefects
						? `${t('wms:Add')} ${t('wms:Defects')}`
						: null}
				</FlexView>
				{chosenItems ? (
					<Input
						label={t('wms:Item')}
						placeholder={t('wms:Item')}
						width='100%'
						type='text'
						margin='auto'
						value={newItem}
						onChange={e => setNewItem(e.target.value)}
						data-cy='input-new-item'
					/>
				) : chosenDefects ? (
					<Input
						label={t('wms:Defect')}
						placeholder={t('wms:Defect')}
						width='100%'
						type='text'
						margin='auto'
						value={newDefect}
						onChange={e => setNewDefect(e.target.value)}
						data-cy='input-new-defect'
					/>
				) : null}
				<FlexView flexDirection='row' margin='auto 0 0 auto'>
					<Button
						backgroundColor='error'
						color='white'
						onClick={onOutsideClick}
						margin='0'
						data-cy='button-cancel-add'>
						{t('wms:Cancel')}
						<Icon name='cross-simple' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
					{chosenItems ? (
						<Button
							backgroundColor='success'
							color='white'
							onClick={() => saveNewItem()}
							margin='0 0 0 8px'
							disabled={!newItem}
							data-cy='button-save-new-item'>
							{t('wms:Save')}
							<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							<LoadingOverlay visible={savingItem} borderRadius='card' />
						</Button>
					) : chosenDefects ? (
						<Button
							backgroundColor='success'
							color='white'
							onClick={() => saveNewDefect()}
							margin='0 0 0 8px'
							disabled={!newDefect}
							data-cy='button-save-new-defect'>
							{t('wms:Save')}
							<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							<LoadingOverlay visible={savingDefect} borderRadius='card' />
						</Button>
					) : null}
				</FlexView>
			</Card>
		</Modal>
	)
}

export default AddModal
