/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import printer from '../api/assetsPrinter'
import { toast } from 'react-toastify'
import _ from 'lodash'

export const AssetsPrinterContext = React.createContext()

export const AssetsPrinterProvider = ({ children }) => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const [lastLevels, setLastLevels] = useState(null)
	const [data, setData] = useState(null)
	const [originalData, setOriginalData] = useState(null)
	const [printers, setPrinters] = useState(null)
	const [tagSizes, setTagSizes] = useState(null)
	const [size, setSize] = useState(null)
	const [templates, setTemplates] = useState(null)
	const [tempSelected, setTempSelected] = useState(null)
	const [loadingPrinters, setLoadingPrinters] = useState(false)
	const [savePrinter, setSavePrinter] = useState(false)
	const [testConfig, setTestConfig] = useState(false)
	const [newConfig, setNewConfig] = useState(null)
	const [printerTest, setPrinterTest] = useState(null)

	const getPrintersConfig = () =>
		new Promise((resolve, reject) => {
			printer
				.getPrintersConfig(token)
				.then(response => {
					let availableData = []
					let printers = Object.keys(response.data.printersconfig)
					setOriginalData(response.data)
					_.forEach(printers, (p, index) => {
						availableData.push({
							printer: p,
							tagname: response.data.printersconfig[p].tagname,
							tagsize: response.data.printersconfig[p].tagsize,
							rfid: response.data.printersconfig[p].rfid,
							tagtype: response.data.printersconfig[p].tagtype,
							templates: response.data.availabletemplates
						})
					})
					setData(availableData)
					setPrinters(Object.keys(response.data.printersconfig))
					setTemplates(response.data.availabletemplates)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingPrinterCfg')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getLastLevels = () =>
		new Promise((resolve, reject) => {
			printer
				.getLastLevels(warehouse.id, token)
				.then(response => {
					setLastLevels(response)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingLastLevels')} [${e.status}]: ${e.data}`)
				})
		})

	const saveConfig = () =>
		new Promise((resolve, reject) => {
			let payload = {
				tagsize: newConfig.printersize,
				printersize: newConfig.printersize,
				rfid: newConfig.rfid,
				tagname: newConfig.tagname,
				tagtype: newConfig.tagtype
			}
			printer
				.savePrinterConfig(newConfig.printer, payload, token)
				.then(response => {
					toast.success(t('wms:SuccessChangePrinters'))
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorSavingPrinterCfg')} [ ${e.status}]: ${e.data}`)
				})
		})

	const test = () =>
		new Promise((resolve, reject) => {
			if (printerTest.tagtype === 'Level') {
				let payload = {
					printer: printerTest.printer,
					levelsid: [lastLevels[0].id]
				}
				printer
					.testPrinter(warehouse.id, printerTest.tagtype, payload, token)
					.then(response => {
						resolve()
						toast.success(`${t('wms:SuccessSentCommand')} ${printerTest.printer}`)
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorTestingPrinter')} [ ${e.status}]: ${e.data}`)
					})
			} else if (printerTest.tagtype === 'Item') {
				let payload = {
					printer: printerTest.printer,
					items: [{ valid: 'A1B2C3D4E5F', puuid: 'PUUID' }]
				}
				printer
					.testPrinter(warehouse.id, printerTest.tagtype, payload, token)
					.then(response => {
						resolve()
						toast.success(`${t('wms:SuccessSentCommand')} ${printerTest.printer}`)
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorTestingPrinter')} [ ${e.status}]: ${e.data}`)
					})
			}
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingPrinters(true)
				await getPrintersConfig()
			} catch (e) {
				setLoadingPrinters(false)
				console.error(e)
			} finally {
				setLoadingPrinters(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getLastLevels()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (savePrinter) {
			const hydrate = async () => {
				try {
					await saveConfig()
				} catch (e) {
					console.error(e)
					setSavePrinter(false)
				} finally {
					setSavePrinter(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savePrinter])

	useEffect(() => {
		if (testConfig) {
			const hydrate = async () => {
				try {
					await test()
				} catch (e) {
					console.error(e)
					setTestConfig(false)
				} finally {
					setTestConfig(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [testConfig])

	const renderStore = (
		<AssetsPrinterContext.Provider
			value={{
				printers,
				setPrinters,
				data,
				setData,
				templates,
				setTemplates,
				loadingPrinters,
				setLoadingPrinters,
				tempSelected,
				setTempSelected,
				tagSizes,
				setTagSizes,
				size,
				setSize,
				originalData,
				setOriginalData,
				newConfig,
				setNewConfig,
				savePrinter,
				setSavePrinter,
				lastLevels,
				setLastLevels,
				testConfig,
				setTestConfig,
				printerTest,
				setPrinterTest
			}}>
			{children}
		</AssetsPrinterContext.Provider>
	)
	return renderStore
}
