/** @format */
import React, { useRef } from 'react'
import Card from 'components/common/Card'
import Button from 'components/common/Button'
import { Icon } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useDrag, useDrop } from 'react-dnd'
import FlexView from 'components/common/FlexView'
import { THREAD_COMPOUND_TYPE } from 'apps/wms/utils/enum'

const DraggableItem = ({ index, id, desc, state, data, onRemove, moveHandler }) => {
	const { t } = useTranslation()

	const ref = useRef(null)

	const [, drop] = useDrop({
		accept: state ? 'thread compound type' : 'application method',
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current ? ref.current.getBoundingClientRect() : {}
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			moveHandler(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		}
	})

	const [{ isDragging }, drag] = useDrag({
		item: { index, type: state ? 'thread compound type' : 'application method' },
		/* eslint-disable no-unused-vars */
		end: (item, monitor) => {
			/* eslint-disable no-unused-vars */
			const dropResult = monitor.getDropResult()
		},
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	})

	const opacity = isDragging ? 0.4 : 1
	drag(drop(ref))

	return (
		<Card
			ref={ref}
			key={id}
			flexDirection='row'
			alignItems='center'
			margin='8px 0px'
			width='calc(100% - 48px)'
			data-cy='list-item'
			style={{ opacity }}>
			<FlexView width='40%'>{desc}</FlexView>
			{state ? <FlexView> {t(THREAD_COMPOUND_TYPE.translateKey[state])} </FlexView> : null}
			<Button
				fontSize='small'
				margin='auto 0 auto auto'
				padding='8px 16px'
				color='white'
				backgroundColor='error'
				disabled={false}
				onClick={() => {
					onRemove(data)
				}}>
				<Icon
					name='trash'
					color='white'
					height='16px'
					width='16px'
					margin='0'
					tooltip={state ? `${t('wms:RemoveThreadCompoundType')}` : `${t('wms:RemoveApplicationMethod')}`}
				/>
			</Button>
		</Card>
	)
}

export default DraggableItem
