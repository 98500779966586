/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, LoadingOverlay, Icon } from 'components/common'
import Button from 'apps/wms/components/commom/Button'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import { MRType, STATUS } from '../../utils/enum'

import { MRContext } from '../../stores/MRStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'

const MrList = () => {
	const { t, i18n } = useTranslation()
	const {
		mrList,
		loadingMRList,
		selectedMR,
		setSelectedMR,
		deleteMR,
		setUploadMRList,
		setIsUpdate,
		setMrModal,
		setLevel
	} = useContext(MRContext)
	const [deleteWarning, setDeleteWarning] = useState(false)
	const [deleteID, setDeleteID] = useState(null)

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedMR && selectedMR.id && handleSelect(selectedMR.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mrList])

	const handleSelect = id => {
		id && setSelectedMR(mrList.find(item => item.id === id))
	}

	const handleUpdate = id => {
		if (id !== null) {
			handleSelect(id)
			setIsUpdate(true)
			setMrModal(true)
		}
	}

	const handleDelete = id => {
		if (id !== null) {
			handleSelect(id)
			setDeleteWarning(true)
			setDeleteID(id)
		}
	}

	const removeMR = () => {
		setDeleteWarning(false)
		let mrType = mrList.find(item => item.id === deleteID).type
		deleteID &&
			mrType !== null &&
			deleteMR(deleteID, mrType)
				.then(response => {
					setUploadMRList(true)
					setSelectedMR(null)
				})
				.catch(e => console.error(e))
				.finally(() => {})
	}

	const handleNewMR = () => {
		setIsUpdate(false)
		setMrModal(true)
		setLevel(null)
	}

	return (
		<Card width='calc(100% - 48px)' flex='1' ref={listRef} margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingMRList} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:MaterialRequisitionList')}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{mrList && mrList.length ? (
					mrList.map((item, index) => (
						<FlexView
							key={item.id}
							backgroundColor={selectedMR && selectedMR.id === item.id ? 'offWhite' : 'default'}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelect(item.id)}>
							<FlexView flex='1' margin='auto 0'>
								<FlexView
									margin='0'
									style={{ textTransform: 'uppercase' }}
									fontWeight='bold'
									color='secondary'
									fontSize='tiny'>{`${MRType.literal[item.type]}`}</FlexView>
								<FlexView
									fontSize='small'
									fontWeight='bold'
									margin='4px 0'>{`${item.id}  - ${item.mrref} `}</FlexView>
								<FlexView flexDirection='row' fontSize='tiny'>
									<Icon name='clock' width='10px' height='10px' margin='2px 4px 0px 0px' />
									{`${DATE_FORMAT(item.expecteddate, i18n.language)}`}
								</FlexView>
							</FlexView>
							<Button
								fontSize='tiny'
								margin='auto 0'
								color='secondary'
								backgroundColor='white'
								hoverBackgroundColor='secondary'
								hoverColor='white'
								disabled={false}
								onClick={() => {
									handleUpdate(item.id)
								}}>
								<Icon name='refresh' color='secondary' height='12px' width='12px' margin='0 8px 0 0' />
								{t('wms:Update')}
							</Button>
							<Button
								fontSize='tiny'
								margin='auto 0 auto 8px'
								color='white'
								backgroundColor='error'
								disabled={!!item.content.find(content => content.status !== STATUS.code.NOTSTARTED)}
								onClick={() => {
									handleDelete(item.id)
								}}>
								<Icon name='trash' color='white' height='12px' width='12px' margin='0 8px 0 0' />
								{t('wms:Delete')}
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'>
						{t('wms:NoMR')}
					</FlexView>
				)}
			</FlexView>
			<Button
				fontSize='small'
				margin='auto 0 0 auto'
				color='white'
				backgroundColor='secondary'
				disabled={false}
				onClick={handleNewMR}>
				{t('wms:Add')}
			</Button>
			<DialogModal
				isOpen={deleteWarning}
				text={t(`wms:ConfirmMaterialRequisitionListDeletion`)}
				onConfirm={removeMR}
				onCancel={() => setDeleteWarning(false)}
			/>
		</Card>
	)
}

export default MrList
