/** @format */

import { FlexView, LoadingOverlay } from 'components/common'
import React, { useContext, useState, useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SubHeader from '../containers/common/Subheader'
import { ContentContext } from '../stores/ContentStore'
import { CONTENT_TABS } from '../utils/enum'
import TabsRow from 'apps/wms/components/commom/TabsRow'
import ContentFilter from '../containers/content/ContentFilter'
import { useWindowSize } from '../utils/hooks'
import OwnershipFilter from '../containers/content/OwnershipFilter'
import ItemFilter from '../containers/content/ItemFilter'
import Dashboard from '../containers/content/Dashboard'
import ContentTable from '../containers/content/ContentTable'
import { useCallback } from 'react'

const ContentPage = () => {
	const { t } = useTranslation()
	const {
		attribute,
		currentTab,
		setCurrentTab,
		loadingTable,
		foundMaterial,
		setLevelId,
		setLevelContent,
		setMid,
		loadingItems,
		getLevelContents
	} = useContext(ContentContext)

	const [items, setItems] = useState([])

	const listDeadSpace = 410
	const windowSize = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, windowSize])

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Content'),
				path: '/wms/content/main'
			}
		],
		[t]
	)

	const handleGetLevelContents = useCallback(
		(rackid, mvid, ownership) => {
			getLevelContents(rackid, mvid, ownership)
				.then(list => setItems(list))
				.catch(e => console.error(e))
				.finally(() => {})
		},
		[getLevelContents]
	)

	return (
		<FlexView
			width='100%'
			maxWidth='100%'
			height='100%'
			flex='1'
			alignItems='center'
			margin='0'
			padding='0'
			backgroundColor='#F8F8F9'
			position='relative'
			ref={listRef}>
			<FlexView
				width='calc(100% - 16px)'
				fontSize='40px'
				flexDirection='row'
				alignItems='center'
				fontWeight='bold'
				padding='16px 0px 16px 16px'>
				<SubHeader crumbs={crumbs} />
			</FlexView>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView
					data-cy='flexview-page-title'
					minWidth='100%'
					fontSize='40px'
					fontWeight='bold'
					margin='0 0 24px 0'>
					{t('wms:Content')}
				</FlexView>
				<FlexView width='100%' maxWidth='100%' flexDirection='column' fontWeight='bold' padding='0 16px 0 16px'>
					<TabsRow
						tabsList={CONTENT_TABS.translateKey.map((item, index) => ({ value: index, label: item }))}
						selectedTab={currentTab}
						setSelectedTab={setCurrentTab}
						tabWidth={500}
						onChange={value => setCurrentTab(value)}
						width={window.innerWidth - 320}
						data-cy='tabs-row'
					/>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row' alignItems='stretch'>
					<FlexView
						height='100%'
						width={
							currentTab === CONTENT_TABS.code.DASHBOARD
								? '60%'
								: currentTab === CONTENT_TABS.code.FILTER_OWNERSHIP
								? '30%'
								: '40%'
						}
						margin='0 8px 0 0'
						flexDirection='row'>
						{currentTab === CONTENT_TABS.code.FILTER_MATERIAL && <ContentFilter height='100%' />}
						{currentTab === CONTENT_TABS.code.FILTER_OWNERSHIP && <OwnershipFilter />}
						{currentTab === CONTENT_TABS.code.FILTER_ITEM && <ItemFilter />}
						{currentTab === CONTENT_TABS.code.DASHBOARD && <Dashboard />}
					</FlexView>
					<LoadingOverlay visible={loadingTable} borderRadius='card' />
					{foundMaterial && foundMaterial.length ? (
						<FlexView
							width={currentTab === CONTENT_TABS.code.FILTER_OWNERSHIP ? '70%' : '60%'}
							position='relative'
							margin='0'>
							<ContentTable
								height={`${listHeight}px`}
								foundMaterial={foundMaterial}
								setLevelId={setLevelId}
								setLevelContent={setLevelContent}
								setMid={setMid}
								pageLoading={loadingItems}
								attribute={attribute}
								getLevelContents={(rackid, mvid, ownership) => handleGetLevelContents(rackid, mvid, ownership)}
								items={items}
							/>
						</FlexView>
					) : (!foundMaterial || !foundMaterial.length) && currentTab !== CONTENT_TABS.code.DASHBOARD ? (
						<FlexView
							width='60%'
							height='100%'
							fontWeight='bold'
							fontSize='24px'
							color='lightGray'
							margin='auto 0'
							alignItems='center'
							justifyContent='center'
							data-cy='placeholder-pipes-list'>
							{t('wms:NoAvailableMaterial')}
						</FlexView>
					) : null}
				</FlexView>
			</FlexView>
		</FlexView>
	)
}

export default ContentPage
