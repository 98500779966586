/** @format */

import { NominalDataContext } from 'apps/wms/stores/NominalDataModifStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Card, FlexView } from 'components/common'
import { Input } from 'components/form'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../components/forms/Checkbox'

const AvailableItems = () => {
	const { t } = useTranslation()
	const { itemsToShow, selectedItem, setSelectedItem, modifAllEntrance, setItemsToShow, selectedPart } =
		useContext(NominalDataContext)
	const [valid, setValid] = useState('')
	const [items, setItems] = useState(null)

	const listDeadSpace = 180
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	const selectItems = (value, selected, index) => {
		return selected.indexOf(value) > -1
	}

	const handleSelect = (value, selected, index) => {
		let i = selected.indexOf(value)
		i > -1 ? selected.splice(i, 1) : selected.push(value)
		setSelectedItem([...selectedItem])
	}

	const checkProps = () => {
		let isDisabled = true
		isDisabled = isDisabled && selectedPart.length > 0 && selectedPart[0].selectall === true
		return isDisabled
	}

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		setItems(itemsToShow)
	}, [itemsToShow])

	useEffect(() => {
		modifAllEntrance === true && setItems(null) && setItemsToShow(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modifAllEntrance])

	return (
		<FlexView width='30%' flexDirection='column' height='100%' ref={listRef} style={{ position: 'relative' }}>
			<Card width='90%' height='100%'>
				<FlexView flexDirection='column' width='100%' height='100%'>
					{items && items.length > 0 && (
						<FlexView flexDirection='row' justifyContent='space-between' width='100%'>
							<FlexView width='50%' fontSize='medium' fontWeight='bold'>{`${items.length} ${t(
								'wms:Availables'
							)}`}</FlexView>
							<FlexView width='50%' fontSize='medium' fontWeight='bold' margin='0 0 0 16px'>{`${
								selectedItem.length
							} VALID(s) ${t('wms:Selected')}(s)`}</FlexView>
						</FlexView>
					)}

					<FlexView flexDirection='column' width='100%' height='100%'>
						{itemsToShow && itemsToShow.length > 0 && (
							<Input
								type='text'
								margin='8px 0 0 0'
								placeholder={t('wms:TypeToFilter')}
								value={valid}
								onChange={e => {
									const filtered = itemsToShow.filter(item => {
										return item.valid.toUpperCase().includes(e.target.value.toUpperCase())
									})
									setItems(filtered)
									setValid(e.target.value)
								}}
								disabled={modifAllEntrance || itemsToShow.length === selectedItem.length}
							/>
						)}
						{modifAllEntrance === false && items && items.length > 0 ? (
							<FlexView
								margin='8px 0 0 0'
								flexDirection='column'
								width='100%'
								height={`${listHeight}px`}
								style={{ overflowY: 'scroll' }}>
								{_.map(items, (item, index) => (
									<Checkbox
										key={index}
										checked={selectItems(item, selectedItem, index)}
										onChange={v => handleSelect(item, selectedItem, index)}
										label={item.valid}
										disabled={checkProps()}
									/>
								))}
							</FlexView>
						) : (
							<FlexView
								height='100%'
								width='100%'
								fontWeight='bold'
								fontSize='24px'
								color='lightGray'
								margin='auto 0'
								alignItems='center'
								justifyContent='center'
								style={{ textAlign: 'center' }}
								data-cy='no-items-list'>
								{t('wms:NoItemsToShow')}
							</FlexView>
						)}
					</FlexView>
				</FlexView>
			</Card>
		</FlexView>
	)
}

export default AvailableItems
