import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { UserContext } from 'stores/UserStore'
import { AppContext } from 'stores/AppStore'
import Header from 'components/layout/Header'
import AppIcon from 'assets/icons/app-logo.svg'
import { getSideMenuSettings } from 'apps'

const verifyUserRoles = (sideMenuSettings, userRoles) => {
  const userHasRole = (optionRoles, userRoles) => !optionRoles || _.intersection(optionRoles, userRoles).length

  return _.chain(sideMenuSettings)
    .filter(({ roles }) => userHasRole(roles || [], userRoles))
    .map(option => ({
      ...option,
      items: option.items && _.filter(option.items, ({ roles }) => userHasRole(roles, userRoles))
    }))
    .value()
}

const AppHeader = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { pageTitle, isHeaderVisible } = useContext(AppContext)
  const { user, authState, signOut } = useContext(UserContext)

  const redirectTo = path => () => {
    history.push(path)
  }

  const sideMenuSettings = {
    appLogoSrc: AppIcon,
    itemGroups: [
      ...verifyUserRoles(getSideMenuSettings(user, redirectTo, t), (user && user.roles) || [])
    ]
  }

  const userSettings = {
    username: user ? user.name : '',
    options: [
      {
        key: 'sign-out',
        label: t('Sign Out'),
        onClick: signOut
      }
    ]
  }

  return isHeaderVisible && authState === 'SIGNED_IN' ?
    <Header title={pageTitle} appIconSrc={AppIcon} onAppIconClick={redirectTo('/wms/')} sideMenuSettings={sideMenuSettings} userSettings={userSettings} />
  : null
}

export default AppHeader