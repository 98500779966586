/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getInspections: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`perinsp/${wid}/list`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data.perinsplist)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getSummarizedInfo: (wid, piid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`perinsp/${wid}/summarized/${piid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data.perinsplist)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPeriodicDetails: (wid, piid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`perinsp/${wid}/reportdetails/${piid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.reportdetails)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	setNewQuantity: (wid, inspid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`perinsp/${wid}/contentupdate/${inspid}`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenPeriodic: (wid, payload = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`perinsp/${wid}/release`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
