/** @format */

import { PrepareEntranceContext } from 'apps/wms/stores/PrepareEntranceStore'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Input, Select } from 'components/form'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { WMSContext } from 'apps/wms/stores/WmsStore'

const MaterialSelection = () => {
	const { t } = useTranslation()
	const {
		endUsers,
		endUserSelected,
		setEndUserSelected,
		materialqty,
		setMaterialQty,
		newMatList,
		setNewMatList,
		newMaterial,
		erpRefList,
		erpSelected,
		setErpSelected,
		materialList,
		loadingErp,
		setStep
	} = useContext(PrepareEntranceContext)
	const {
		convertToCurrentLengthUnit,
		convertToCurrentWeightUnit,
		lengthUnit,
		weightUnit,
		getLengthDisplay,
		getWeightDisplay,
		warehouse
	} = useContext(WMSContext)

	const addMaterials = () => {
		if (materialqty <= 0 || materialqty === null || materialqty % 1 !== 0) return
		for (var i = 0; i < materialqty; i++) {
			newMatList.push({ ...newMaterial })
		}
		setNewMatList([...newMatList])
	}

	const selectMaterial = (value, index) => {
		let material = _.find(materialList, mat => mat.erpreference === value)
		newMatList[index].erp = material.erpreference
		newMatList[index].mvid = material.mvid
		newMatList[index].formatted = material.formatted
		newMatList[index].lengthmm = convertToCurrentLengthUnit(getLengthDisplay(warehouse, material.length), lengthUnit)
		newMatList[index].weightkg = convertToCurrentWeightUnit(
			getWeightDisplay(warehouse, material.weightkg),
			weightUnit
		)
		setNewMatList([...newMatList])
		erpSelected[index] = value
		setErpSelected([...erpSelected])
	}

	const renderMaterial = material => {
		return (
			<FlexView
				width='70%'
				fontSize='small'
				fontWeight='bold'
				color='metalic'
				margin='24px 0 0 16px'
				alignItems='center'
				style={{ textAlign: 'center' }}>
				{material}
			</FlexView>
		)
	}

	const removeMaterial = index => {
		newMatList.splice(index, 1)
		setNewMatList([...newMatList])
		erpSelected.splice(index, 1)
		setErpSelected([...erpSelected])
	}

	const goToNext = () => {
		setStep(2)
	}

	const checkFields = () => {
		let isDisabled = true
		let hasNoMaterial =
			newMatList.length === 0 || _.some(newMatList, mat => mat === null || mat.erp === null || mat.mvid === null)
		isDisabled = isDisabled && hasNoMaterial
		return isDisabled
	}

	return (
		<FlexView width='calc(100% - 48px)' height='100%'>
			<FlexView flexDirection='row' width='100%' alignItems='center' justifyContent='flex-start' margin='0 0 0 8px'>
				{endUsers && (
					<Select
						inline
						width='300px'
						margin='8px 0 0 0'
						label={`${t('wms:EndUser')}:`}
						placeholder={t('wms:EndUser')}
						value={endUserSelected}
						options={_.map(endUsers, eu => ({ value: eu.id, label: eu.name }))}
						onChange={v => setEndUserSelected(v)}
						searchable
						data-cy='select-end-user'
					/>
				)}
				<Input
					width='400px'
					inline
					label={`${t('wms:ManyMaterials')}:`}
					margin='8px 0 0 16px'
					type='number'
					pattern='/^-?[0-9][^\.]*$/'
					value={materialqty}
					placeholder={t('wms:Quantity')}
					onChange={e => setMaterialQty(e.target.value)}
					disabled={!endUserSelected}
					data-cy='input-material-quantity'
				/>
				<Button
					backgroundColor='secondary'
					color='white'
					onClick={() => addMaterials()}
					margin='8px 0 0 16px'
					disabled={materialqty <= 0}
					data-cy='button-add-material'>
					{t('wms:Add')}
					<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
			</FlexView>
			<Card width='100%' height='100%' data-cy='card-materials'>
				<FlexView flexDirection='column' width='100%' height='100%'>
					<LoadingOverlay visible={loadingErp} borderRadius='card' />
					{newMatList && newMatList.length > 0 ? (
						_.map(newMatList, (material, index) => (
							<FlexView
								flexDirection='row'
								width='100%'
								key={index}
								alignItems='center'
								justifyContent='space-between'>
								{erpRefList && (
									<Select
										width='250px'
										key={index}
										label={`${t('wms:Material')} ${index + 1}`}
										placeholder={t('wms:Material')}
										value={erpSelected[index]}
										options={_.map(erpRefList.erp, erp => {
											return { value: erp, label: erp }
										})}
										onChange={v => selectMaterial(v, index)}
										searchable
										data-cy='select-material'
									/>
								)}
								{material.formatted && renderMaterial(material.formatted)}
								<Button
									backgroundColor='error'
									onClick={() => removeMaterial(index)}
									margin='16px 0 0 16px'
									data-cy='button-remove-material'>
									<Icon name='trash' color='white' fontWeight='bold' width='14px' height='14px' />
								</Button>
							</FlexView>
						))
					) : (
						<FlexView
							width='100%'
							height='100%'
							fontWeight='bold'
							fontSize='24px'
							color='lightGray'
							margin='auto 0'
							alignItems='center'
							justifyContent='center'
							data-cy='no-material-list'>
							{t('wms:EmptyList')}. {t('wms:AddMaterialsToList')}
						</FlexView>
					)}
					<Button
						backgroundColor='primary'
						color='white'
						margin='auto 0 0 auto'
						onClick={() => goToNext()}
						disabled={checkFields()}
						data-cy='button-next-step'>
						{t('wms:Next')}
						<Icon name='arrow-right' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</FlexView>
	)
}

export default MaterialSelection
