/** @format */

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Table from 'apps/wms/components/content/Table'
import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'
import { REPORT_STATUS, REPORT_TYPE } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'

const ExportReportList = ({
	loadingList,
	list = [],
	reportType,
	onDownloadReport,
	onDownloadReportPdf,
	height,
	downloadingReport
}) => {
	const { t, i18n } = useTranslation()

	const handleDownloadReport = value => {
		value != null && onDownloadReport(value)
	}

	const handleDownloadReportPdf = value => {
		!!value && onDownloadReportPdf(value)
	}

	const dateTextByType = [
		t('wms:RigPrepDate'),
		t('wms:ThirdPartyDate'),
		t('wms:MaterialModificationDate'),
		t('wms:MaterialPhysicalTransferDate'),
		'',
		'',
		t('wms:RigReturnDate'),
		'',
		t('wms:ModifiedProductDate'),
		t('wms:DispatchDate'),
		t('wms:RigReturnDate')
	]

	const mrColumns = useMemo(
		() => [
			{
				Header: t('wms:ReferenceMR'),
				accessor: 'mrref',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Ownership'),
				accessor: 'ownership.name',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: dateTextByType[reportType],
				accessor: 'closuredate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Action'),
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<FlexView flexDirection='row' justifyContent='center'>
						<Button
							fontSize='tiny'
							margin='4px'
							backgroundColor='primary'
							disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
							elevation='none'
							onClick={() => {
								handleDownloadReport({
									id: value,
									ownershipId: row.original.ownership.id,
									poNumber: row.original.customerorder
								})
							}}
							data-cy='button-download-report'>
							<Icon
								name='csv'
								width='16px'
								height='16px'
								color='white'
								//tooltip={t('wms:DownloadReportExcel')}
							/>
						</Button>
						<Button
							fontSize='tiny'
							margin='4px'
							color='white'
							backgroundColor='tertiary'
							disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
							elevation='none'
							onClick={() => {
								handleDownloadReportPdf({
									id: value,
									ownershipId: row.original.ownership.id,
									poNumber: row.original.customerorder
								})
							}}
							data-cy='button-download-report-pdf'>
							<Icon
								name='pdf'
								width='16px'
								height='16px'
								color='white'
								//tooltip={t('wms:DownloadReportPdf')}
							/>
						</Button>
						<LoadingOverlay visible={downloadingReport} />
					</FlexView>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, reportType, t]
	)

	const backloadColumns = useMemo(
		() => [
			{
				Header: t('wms:ReferenceRR'),
				accessor: 'ref',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Name'),
				accessor: 'name',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: dateTextByType[reportType],
				accessor: 'closuredate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Action'),
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<>
						<Button
							width='100%'
							fontSize='tiny'
							margin='4px 0'
							color='white'
							backgroundColor='primary'
							disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
							elevation='none'
							onClick={() => {
								handleDownloadReport(value)
							}}
							data-cy='button-download-report'>
							{t('wms:DownloadReport')}
						</Button>
						<LoadingOverlay visible={downloadingReport} />
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, reportType, t]
	)

	const dispatchColumns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:DispatchDestination'),
				accessor: 'destination',
				width: '250px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: dateTextByType[reportType],
				accessor: 'created',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Action'),
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<>
						<Button
							width='100%'
							fontSize='tiny'
							margin='4px 0'
							color='white'
							backgroundColor='primary'
							disabled={false}
							elevation='none'
							onClick={() => {
								handleDownloadReport(row.original.id)
							}}
							data-cy='button-download-report'>
							{t('wms:DownloadReport')}
						</Button>
						<LoadingOverlay visible={downloadingReport} />
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, reportType, t]
	)

	const lists = () => {
		if (
			reportType === REPORT_TYPE.code.RIGPREP ||
			reportType === REPORT_TYPE.code.TPWORKSHOP ||
			reportType === REPORT_TYPE.code.MATMODIF ||
			reportType === REPORT_TYPE.code.PHYSTRANS
		)
			return mrColumns
		else if (reportType === REPORT_TYPE.code.BACKLOAD_MANIFEST || reportType === REPORT_TYPE.code.MODIF_PRODUCTS)
			return backloadColumns
		else return dispatchColumns
	}

	const getCy = () => {
		if (
			reportType === REPORT_TYPE.code.RIGPREP ||
			reportType === REPORT_TYPE.code.TPWORKSHOP ||
			reportType === REPORT_TYPE.code.MATMODIF ||
			reportType === REPORT_TYPE.code.PHYSTRANS
		)
			return 'list-reports-delivery-notes'
		else if (reportType === REPORT_TYPE.code.BACKLOAD_MANIFEST || reportType === REPORT_TYPE.code.MODIF_PRODUCTS)
			return 'list-reports-backload-manifest'
		else return 'list-reports-dispatch'
	}

	return (
		<Card data-cy='card-list-wrapper' width='calc(100% - 48px)' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingList} borderRadius='card' />
			{list && list.length ? (
				<Table
					margin='0'
					width='calc(100%)'
					textAlign='center'
					elevation='none'
					height={height}
					fontSize='14px'
					columns={lists()}
					data={list}
					sortable
					gridless
					data-cy={getCy()}
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='placeholder-reports-list'>
					{t('wms:NoReport')}
				</FlexView>
			)}
		</Card>
	)
}

export default ExportReportList
