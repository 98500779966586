/** @format */

import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTable, useSortBy, usePagination, useExpanded, useFilters, useGlobalFilter } from 'react-table'
import { Input, Select } from '../../../../components/form'
import Link from '../../../../components/common/Link'
import FlexView from '../../../../components/common/FlexView'
import styled, { css } from 'styled-components'
import {
	withBorderRadius,
	withElevation,
	withMargin,
	withWidth,
	withFontSize,
	withMaxWidth
} from 'utils/styled-decorators'
import { useWindowSize } from 'apps/wms/utils/hooks'

const TableWrapper = styled.div`
	${withFontSize('medium')}
	${withMargin()}
  ${withWidth('100%')}
  ${withBorderRadius('component')}
  ${withElevation('medium')};
	${withMaxWidth('100%')}

	overflow: overlay;

	.table-wrap {
		display: block;
		max-width: 100%;
		overflow-x: auto;
		overflow-y: auto;
		height: ${({ height }) => height || 'auto'};

		/* Customize website's scrollbar like Mac OS
		Not supports in Firefox and IE */

		/* total width */
		&::-webkit-scrollbar {
			background-color: #fff;
			width: 16px;
		}

		/* background of the scrollbar except button or resizer */
		&::-webkit-scrollbar-track {
			background-color: #fff;
		}

		/* scrollbar itself */
		&::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 4px solid #fff;
		}

		/* set button(top and bottom of the scrollbar) */
		&::-webkit-scrollbar-button {
			display: none;
		}
	}
	font-family: 'Noto Sans';
	color: ${({ theme }) => theme.colors.darkGray};

	table {
		width: 100%;
		border-spacing: 0;

		thead.stick-header th {
			position: sticky;
			top: 0;
			z-index: 1;
			background-color: white;
		}

		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		thead,
		tfoot {
			font-weight: bold;
			color: ${({ theme }) => theme.colors.metalic};
			text-align: center;
		}

		th.sortable {
			cursor: pointer;

			svg {
				margin-left: 8px;
				margin-bottom: 2px;
			}
		}

		th,
		td {
			margin: 0;
			padding: 8px;
			border: 0;
			white-space: ${({ whiteSpace }) => whiteSpace || 'normal'};
			text-align: ${({ textAlign }) => textAlign || 'left'};
			text-align: ${({ textAlign }) => (textAlign ? `-webkit-${textAlign}` : '')};

			:last-child {
				border-right: 0;
			}

			/* Each cell should grow equally */
			width: 1%;
			/* But "collapsed" cells should be as small as possible */
			&.collapse {
				width: 0.0000000001%;
			}
		}

		th {
			border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
		}

		tbody {
			tr {
				transition: background-color 0.4s ease;

				&:hover,
				&.selected {
					background-color: ${({ theme }) => theme.colors.offWhite};
				}
			}
		}
	}

	.pagination {
		padding: 8px 24px;
		border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	${({ gridless }) =>
		!gridless &&
		css`
			table {
				thead,
				tfoot {
					text-align: center;
				}

				th,
				td {
					border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
					border-right: 1px solid ${({ theme }) => theme.colors.lightGray};

					:last-child {
						border-right: 0;
					}
				}

				thead tr:last-child th {
					border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
				}

				tfoot {
					tr:first-child {
						td {
							border-top: 2px solid ${({ theme }) => theme.colors.lightGray};
						}
					}
				}
			}

			.pagination {
				border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
			}
		`}
`

const EnhancedTable = ({
	columns,
	data,
	hasFooter,
	sortable,
	paginated,
	borderRadius,
	width,
	height,
	maxHeight,
	fontSize,
	stickyHeader,
	zoom = 1,
	...rest
}) => {
	const { t } = useTranslation()
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize }
	} = useTable(
		{
			columns,
			data: data,
			getSubRows: row => row.children || [],
			paginateExpandedRows: false,
			autoResetExpanded: false,
			autoResetPage: false,
			autoResetSortBy: false,
			disableSortBy: !sortable
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination
	)
	const windowSize = useWindowSize()
	useEffect(() => {
		let height = windowSize.height
		/* height < 1080
			? setPageSize(5)
			: height < 1440
			? setPageSize(15)
			: height < 2160
			? setPageSize(25)
			: setPageSize(30) */
		height < 1080 ? setPageSize(5) : setPageSize(10)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize])

	const returnStyle = useCallback(() => {
		let style
		let h = !!height ? { height: `calc(${height} - 60px)` } : {}
		let max = !!maxHeight ? { maxHeight: `calc(${maxHeight} - 60px)` } : {}
		style = { ...h, ...max }
		return style
	}, [height, maxHeight])

	// Render the UI for your table
	return (
		<TableWrapper borderRadius={borderRadius} {...rest}>
			<div className='table-wrap' style={returnStyle()}>
				<table style={{ zoom: zoom }} {...getTableProps()}>
					<thead className={stickyHeader ? 'stick-header' : ''}>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column =>
									// Add the sorting props to control sorting. For this example
									// we can add them into the header props
									sortable ? (
										<th
											{...column.getHeaderProps(column.getSortByToggleProps())}
											className='sortable'
											style={{
												width: column.width,
												minWidth: column.minWidth ? column.minWidth : 'auto',
												maxWidth: column.maxWidth ? column.maxWidth : 'auto',
												fontSize: fontSize
											}}>
											{column.render('Header')}
											{/* Add a sort direction indicator */}
											<span>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<svg
															width='13'
															height='7'
															viewBox='0 0 13 7'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
															transform='rotate(180)'>
															<path d='M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z' fill='#777777' />
														</svg>
													) : (
														<svg
															width='13'
															height='7'
															viewBox='0 0 13 7'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'>
															<path d='M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z' fill='#777777' />
														</svg>
													)
												) : (
													''
												)}
											</span>
											<div>{column.canFilter ? column.render('Filter') : null}</div>
										</th>
									) : (
										<th {...column.getHeaderProps()} width={column.width}>
											{column.render('Header')}
										</th>
									)
								)}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{paginated
							? page.map(row => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()} {...row.original.props}>
											{row.cells.map(cell => {
												return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											})}
										</tr>
									)
							  })
							: rows.map((row, i) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()} {...row.original.props}>
											{row.cells.map(cell => {
												return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											})}
										</tr>
									)
							  })}
					</tbody>
					{hasFooter && (
						<tfoot>
							{footerGroups.map(group => (
								<tr {...group.getFooterGroupProps()}>
									{group.headers.map(column => (
										<td {...column.getFooterProps()}>{column.render('Footer')}</td>
									))}
								</tr>
							))}
						</tfoot>
					)}
				</table>
			</div>
			{paginated && (
				<div className='pagination'>
					<FlexView flexDirection='row' justifyContent='flex-start' alignItems='center'>
						<Input
							inline
							type='number'
							label={t('Go to page')}
							fontSize='small'
							margin='0px'
							padding='8px 4px'
							defaultValue={pageIndex + 1}
							onChange={e => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0
								gotoPage(page)
							}}
							width='180px'
						/>
					</FlexView>
					<FlexView flex='1' flexDirection='row' alignItems='center' justifyContent='center' width='100%'>
						<Link
							noDecoration
							color='gray'
							fontSize='big'
							fontWeight='bold'
							padding='4px'
							margin='0px 4px'
							onClick={() => gotoPage(0)}
							disabled={!canPreviousPage}>
							{'«'}
						</Link>{' '}
						<Link
							noDecoration
							color='gray'
							fontSize='big'
							fontWeight='bold'
							padding='4px'
							margin='0px 4px'
							onClick={() => previousPage()}
							disabled={!canPreviousPage}>
							{'‹'}
						</Link>{' '}
						<FlexView flexDirection='row' margin='0px 8px' fontWeight='bold'>
							{pageIndex + 1} {t('of')} {pageOptions.length}
						</FlexView>
						<Link
							noDecoration
							color='gray'
							fontSize='big'
							fontWeight='bold'
							padding='4px'
							margin='0px 4px'
							onClick={() => nextPage()}
							disabled={!canNextPage}>
							{'›'}
						</Link>{' '}
						<Link
							noDecoration
							color='gray'
							fontSize='big'
							fontWeight='bold'
							padding='4px'
							margin='0px 4px'
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}>
							{'»'}
						</Link>{' '}
					</FlexView>
					<FlexView flexDirection='row' justifyContent='flex-end' alignItems='center'>
						<Select
							margin='0px'
							value={pageSize}
							/* options={[5, 10, 20, 30, 40, 50].map(pageSize => ({
								value: pageSize,
								label: `${t('Show')} ${pageSize}`
							}))} */
							options={[5, 10].map(pageSize => ({
								value: pageSize,
								label: `${t('Show')} ${pageSize}`
							}))}
							onChange={value => {
								setPageSize(Number(value))
							}}
						/>
					</FlexView>
				</div>
			)}
		</TableWrapper>
	)
}

export default EnhancedTable
