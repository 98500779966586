/** @format */

import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay } from 'components/common'
import Table from '../../components/content/Table'
import { INSPECTION, REPORT_STATUS } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'

const InspReportList = ({
	loadingList,
	list = [],
	listType,
	onSelectReport,
	onDownloadReport,
	onCheckReport,
	height,
	downloadingReport
}) => {
	const { t, i18n } = useTranslation()
	const [selectedItem, setSelectedItem] = useState(null)

	const handleSelect = id => {
		if (id != null) {
			setSelectedItem(list.find(item => item.id === id))
			onSelectReport(id)
		}
	}

	const handleDownloadReport = id => {
		id != null && onDownloadReport(id)
	}

	const handleCheckReport = id => {
		id != null && onCheckReport(id)
	}

	const dateTextByType = [
		t('wms:RigPrepDate'),
		t('wms:ThirdPartyDate'),
		t('wms:MaterialModificationDate'),
		t('wms:MaterialPhysicalTransferDate'),
		t('wms:ReceivingInspectionDate'),
		null,
		t('wms:RigReturnDate'),
		t('wms:PeriodicInspectionDate'),
		t('wms:ModifiedProductDate')
	]

	const data = list.map((value, index) => ({
		...value,
		props: {
			'data-cy': 'list-item-reports',
			onClick: () => {
				handleSelect(value.id)
			},
			className: selectedItem !== null && value.id === selectedItem.id ? 'selected' : ''
		}
	}))

	const mrColumns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:ReferenceMR'),
				accessor: 'mrref',
				width: '80px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: dateTextByType[listType],
				accessor: 'closuredate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Status'),
				accessor: 'status',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='16px'
						fontWeight='bold'
						color={REPORT_STATUS.color[value]}>
						{t(`${REPORT_STATUS.translateKey[value]}`)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Action'),
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<>
						{row.original.status === REPORT_STATUS.code.VALIDATED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='primary'
								disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
								elevation='none'
								onClick={() => {
									handleDownloadReport(row.original.id)
								}}
								data-cy='button-download-report'>
								{t('wms:DownloadReport')}
							</Button>
						) : null}
						{row.original.status === REPORT_STATUS.code.PENDING ||
						row.original.status === REPORT_STATUS.code.REJECTED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='secondary'
								title={t('wms:AvailableSoon')}
								onClick={() => {
									handleCheckReport(row.original.id)
								}}
								data-cy='button-check-report'>
								{t('wms:CheckReport')}
							</Button>
						) : null}
						<LoadingOverlay visible={downloadingReport} />
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, listType, t, selectedItem]
	)

	const inspColumns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: listType === INSPECTION.code.RECINSP ? 'id' : 'entranceid',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Name'),
				accessor: listType === INSPECTION.code.RECINSP ? 'name' : 'lastinsprep',
				width: '100px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: dateTextByType[listType],
				accessor: 'closuredate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Status'),
				accessor: 'status',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='16px'
						fontWeight='bold'
						color={REPORT_STATUS.color[value]}>
						{t(`${REPORT_STATUS.translateKey[value]}`)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Action'),
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<>
						{row.original.status === REPORT_STATUS.code.VALIDATED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='primary'
								disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
								elevation='none'
								onClick={() => {
									handleDownloadReport(row.original.id)
								}}
								data-cy='button-download-report'>
								{t('wms:DownloadReport')}
							</Button>
						) : null}
						{row.original.status === REPORT_STATUS.code.PENDING ||
						row.original.status === REPORT_STATUS.code.REJECTED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='secondary'
								title={t('wms:AvailableSoon')}
								onClick={() => {
									handleCheckReport(row.original.id)
								}}
								data-cy='button-check-report'>
								{t('wms:CheckReport')}
							</Button>
						) : null}
						<LoadingOverlay visible={downloadingReport} />
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, listType, t, selectedItem]
	)

	const returnColumns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Reference'),
				accessor: listType === INSPECTION.code.RIGRETURN ? 'rrref' : 'erref',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Name'),
				accessor:
					listType === INSPECTION.code.RIGRETURN || listType === INSPECTION.code.MODIFPROD
						? 'name'
						: 'lastinsprep',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: dateTextByType[listType],
				accessor: 'closuredate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Status'),
				accessor: 'status',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='16px'
						fontWeight='bold'
						color={REPORT_STATUS.color[value]}>
						{t(`${REPORT_STATUS.translateKey[value]}`)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Action'),
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<>
						{row.original.status === REPORT_STATUS.code.VALIDATED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='primary'
								disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
								elevation='none'
								onClick={() => {
									handleDownloadReport(row.original.id)
								}}
								data-cy='button-download-report'>
								{t('wms:DownloadReport')}
							</Button>
						) : null}
						{row.original.status === REPORT_STATUS.code.PENDING ||
						row.original.status === REPORT_STATUS.code.REJECTED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='secondary'
								title={t('wms:AvailableSoon')}
								onClick={() => {
									handleCheckReport(row.original.id)
								}}
								data-cy='button-check-report'>
								{t('wms:CheckReport')}
							</Button>
						) : null}
						<LoadingOverlay visible={downloadingReport} />
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, listType, t, selectedItem]
	)

	const lists = () => {
		if (
			listType === INSPECTION.code.RIGPREP ||
			listType === INSPECTION.code.TPWORKSHOP ||
			listType === INSPECTION.code.MATMODIF ||
			listType === INSPECTION.code.PHYSTRANS
		)
			return mrColumns
		else if (listType === INSPECTION.code.RECINSP || listType === INSPECTION.code.PERIODICAL) return inspColumns
		else return returnColumns
	}

	const getDataCY = () => {
		return 'list-reports'
	}

	return (
		<Card data-cy='card-list-wrapper' width='calc(100% - 48px)' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingList} borderRadius='card' />
			{list && list.length ? (
				<Table
					margin='0'
					width='100%'
					maxWidth='100%'
					textAlign='center'
					elevation='none'
					height={height}
					fontSize='14px'
					columns={lists()}
					data={data}
					sortable
					gridless
					data-cy={getDataCY()}
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='placeholder-reports-list'>
					{t('wms:NoReport')}
				</FlexView>
			)}
		</Card>
	)
}

export default InspReportList
