/** @format */

import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { FlexView, LoadingOverlay } from 'components/common'
import { Select, Input } from 'components/form'

import { UsageRightsContext } from '../../stores/UsageRightsStore'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import _ from 'lodash'

const InfoInput = styled.div`
	> div div:last-child {
		font-size: 10px;
	}
`

const SourceFilters = props => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const {
		sourceType,
		levels,
		sourceOwnership,
		setSourceOwnership,
		sourceRack,
		setSourceRack,
		loadingRacks,
		valid,
		setValid,
		isValid,
		sourceEnduser,
		setSourceEnduser,
		endusers,
		loadingOrders,
		sourceCustomerOrder,
		setSourceCustomerOrder,
		sourceCustomerItem,
		setSourceCustomerItem,
		customerOrders,
		customerItems,
		sourceSalesOrder,
		setSourceSalesOrder,
		sourceSalesItem,
		setSourceSalesItem,
		salesOrders,
		salesItems,
		loadingOwnerships,
		getCustomerOrdersList,
		setCustomerOrders,
		getSalesOrdersList,
		setSalesOrders,
		setCustomerItems,
		setSalesItems,
		getOwnershipByOrder,
		setOwnershipList,
		sourceMaterialList,
		loadingMaterials,
		getOwnershipByMaterial,
		typeOfRight,
		setTotalSelectedPipes,
		getRackListByMaterial,
		sourceMaterial,
		setSourceMaterial
	} = useContext(UsageRightsContext)

	const [filteredMaterialList, setFilteredMaterialList] = useState([])
	const [ownershipsByMaterial, setOwnershipsByMaterial] = useState([])
	const [rackListByMaterial, setRackListByMaterial] = useState([])

	const filterMaterial = () => {
		let filtered = _.filter(sourceMaterialList, material => material.enduserid === sourceEnduser)
		setFilteredMaterialList(filtered)
	}

	useEffect(() => {
		sourceMaterial &&
			getOwnershipByMaterial(sourceMaterial)
				.then(list => setOwnershipsByMaterial(list))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceMaterial])

	useEffect(() => {
		sourceEnduser && filterMaterial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceEnduser])

	useEffect(() => {
		setSourceCustomerOrder(null)
		sourceEnduser &&
			sourceType === 'CUSTOMER_ITEM' &&
			getCustomerOrdersList()
				.then(orders => orders && orders.length && setCustomerOrders(orders))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceEnduser, warehouse])

	useEffect(() => {
		setSourceSalesOrder(null)
		sourceEnduser &&
			sourceType === 'SALES_ITEM' &&
			getSalesOrdersList()
				.then(sales => sales && sales.length && setSalesOrders(sales))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceEnduser])

	useEffect(() => {
		setSourceCustomerItem(null)
		sourceCustomerOrder
			? setCustomerItems(customerOrders.find(order => order.value === sourceCustomerOrder).items)
			: setCustomerItems([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceCustomerOrder])

	useEffect(() => {
		setSourceSalesItem(null)
		sourceSalesOrder
			? setSalesItems(salesOrders.find(sale => sale.value === sourceSalesOrder).items)
			: setSalesItems([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceSalesOrder])

	useEffect(() => {
		setSourceOwnership(null)
		;(sourceCustomerItem || sourceSalesItem) &&
			getOwnershipByOrder()
				.then(ownerships => ownerships && setOwnershipList(ownerships))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceCustomerItem, sourceSalesItem])

	useEffect(() => {
		setSourceOwnership(null)
		setSourceEnduser(null)
		setSourceCustomerOrder(null)
		setSourceSalesOrder(null)
		setSourceCustomerItem(null)
		setSourceSalesItem(null)
		setSourceMaterial(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, typeOfRight, sourceType])

	useEffect(() => {
		sourceMaterial &&
			getRackListByMaterial(sourceMaterial)
				.then(list => {
					setRackListByMaterial(list)
				})
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceMaterial])

	const inputMessage = () => {
		if (isValid === null) return t(`wms:ValidHelp`)
		else if (isValid) return t(`wms:ValidValidated`)
		else return t(`wms:InvalidValid`)
	}

	const inputMessageType = () => {
		if (isValid === null) return 'info'
		else if (isValid) return 'success'
		else return 'error'
	}

	return (
		<>
			{sourceType && sourceType === 'RACK' && (
				<>
					<LoadingOverlay visible={loadingMaterials} borderRadius='card' />
					<Select
						placeholder={t('wms:EndUser')}
						label={t('wms:EndUser')}
						options={_.map(endusers, eu => ({
							value: eu.id,
							label: eu.name
						}))}
						value={sourceEnduser}
						onChange={value => setSourceEnduser(value)}
						width='100%'
						margin='0 0 8px 0'
						searchable
					/>
					<Select
						searchable
						placeholder={t('wms:Material')}
						label={t('wms:Material')}
						options={
							filteredMaterialList && filteredMaterialList.length
								? _.map(filteredMaterialList, mat => ({ value: mat.mvid, label: mat.formatted }))
								: _.map(sourceMaterialList, mat => ({ value: mat.mvid, label: mat.formatted }))
						}
						value={sourceMaterial}
						onChange={value => setSourceMaterial(value)}
						width='100%'
						margin='0 0 8px 0'
					/>
					{sourceType === 'RACK' ? (
						<Select
							placeholder={t('wms:Ownership')}
							label={t('wms:Ownership')}
							options={_.map(ownershipsByMaterial, owners => ({ value: owners.id, label: owners.label }))}
							value={sourceOwnership}
							onChange={v => {
								setSourceOwnership(v)
								setTotalSelectedPipes(0)
							}}
							disabled={!sourceMaterial}
							width='100%'
							margin='0 0 8px 0'
							searchable
							data-cy='select-source-ownership'
						/>
					) : (
						<Select
							placeholder={t('wms:Ownership')}
							label={t('wms:Ownership')}
							options={
								levels &&
								_.map(levels, lv => {
									return { value: lv.id, label: lv.label }
								})
							}
							value={sourceOwnership}
							onChange={v => setSourceOwnership(v)}
							disabled={!levels || levels.length <= 0}
							width='100%'
							margin='0 0 8px 0'
							data-cy='select-source-ownership'
						/>
					)}
					<FlexView width='100%' margin='0' padding='0' position='relative'>
						<LoadingOverlay visible={loadingRacks} />
						<Select
							searchable
							label={t('wms:Rack')}
							placeholder={t('wms:SelectRack')}
							value={sourceRack}
							options={
								rackListByMaterial &&
								rackListByMaterial.map(rack => ({
									label: rack.fullname,
									value: rack.id
								}))
							}
							onChange={value => {
								setSourceRack(value)
								setTotalSelectedPipes(0)
							}}
							disabled={!sourceOwnership}
							width='100%'
							margin='0'
							data-cy='select-source-rack'
						/>
					</FlexView>
				</>
			)}
			{sourceType && (sourceType === 'CUSTOMER_ITEM' || sourceType === 'SALES_ITEM') && (
				<>
					<Select
						label={t(`wms:EndUser`)}
						placeholder={t(`wms:EndUser`)}
						value={sourceEnduser && sourceEnduser.id}
						options={endusers.map(({ id, name }) => ({
							value: id,
							label: name
						}))}
						onChange={value => {
							setSourceEnduser(endusers.find(eu => eu.id === value))
							setTotalSelectedPipes(0)
						}}
						width='100%'
						inline={false}
						margin='0'
						data-cy='select-source-enduser'
					/>
					{sourceType === 'CUSTOMER_ITEM' && (
						<FlexView width='100%' margin='0' padding='0' position='relative'>
							<LoadingOverlay visible={loadingOrders} />
							<Select
								disabled={!customerOrders.length}
								label={t(`wms:CustomerOrder`)}
								placeholder={t(`wms:CustomerOrder`)}
								value={sourceCustomerOrder}
								options={customerOrders.map(value => ({
									label: value.value,
									value: value.value
								}))}
								onChange={value => {
									setSourceCustomerOrder(value)
									setTotalSelectedPipes(0)
								}}
								width='100%'
								inline={false}
								margin='0'
								searchable
								data-cy='select-customer-order'
							/>
							<Select
								disabled={sourceCustomerOrder === null}
								label={t(`wms:CustomerItem`)}
								placeholder={t(`wms:CustomerItem`)}
								value={sourceCustomerItem}
								options={customerItems.map(value => ({
									label: value,
									value: value
								}))}
								onChange={value => {
									setSourceCustomerItem(value)
									setTotalSelectedPipes(0)
								}}
								width='100%'
								inline={false}
								margin='0'
								searchable
								data-cy='select-customer-item'
							/>
						</FlexView>
					)}
					{sourceType === 'SALES_ITEM' && (
						<FlexView width='100%' margin='0' padding='0' position='relative'>
							<LoadingOverlay visible={loadingOrders} />
							<Select
								disabled={!salesOrders.length}
								label={t(`wms:SalesOrder`)}
								placeholder={t(`wms:SalesOrder`)}
								value={sourceSalesOrder}
								options={salesOrders.map(value => ({
									label: value.value,
									value: value.value
								}))}
								onChange={value => {
									setSourceSalesOrder(value)
									setTotalSelectedPipes(0)
								}}
								width='100%'
								inline={false}
								margin='0'
								data-cy='select-sales-order'
							/>
							<Select
								disabled={sourceSalesOrder === null}
								label={t(`wms:SalesItem`)}
								placeholder={t(`wms:SalesItem`)}
								value={sourceSalesItem}
								options={salesItems.map(value => ({
									label: value,
									value: value
								}))}
								onChange={value => {
									setSourceSalesItem(value)
									setTotalSelectedPipes(0)
								}}
								width='100%'
								inline={false}
								margin='0'
								data-cy='select-sales-item'
							/>
						</FlexView>
					)}

					<FlexView width='100%' margin='0' padding='0' position='relative'>
						<LoadingOverlay visible={loadingOwnerships} />
						<Select
							searchable
							placeholder={t('wms:Ownership')}
							label={t('wms:Ownership')}
							options={_.map(levels, lv => {
								return { value: lv.id, label: lv.label }
							})}
							value={sourceOwnership}
							onChange={v => {
								setSourceOwnership(v)
								setTotalSelectedPipes(0)
							}}
							disabled={!levels || levels.length <= 0}
							width='100%'
							margin='0 0 8px 0'
							data-cy='select-source-ownership'
						/>
					</FlexView>
				</>
			)}
			{sourceType && sourceType === 'VALID' && (
				<InfoInput>
					<Input
						label='VALID'
						message={inputMessage()}
						messageType={inputMessageType()}
						placeholder={t(`wms:TypeValid`)}
						margin='0'
						minlength='11'
						maxlength='11'
						value={valid}
						onChange={e => {
							setValid(e.target.value)
							setTotalSelectedPipes(0)
						}}
						success={isValid}
						error={!isValid && isValid !== null}
						data-cy='input-valid'
					/>
				</InfoInput>
			)}
		</>
	)
}

export default SourceFilters
