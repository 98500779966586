/** @format */

import { UserContext } from 'stores/UserStore'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import commom from '../api/common'
import assetsAttr from '../api/assetsAttributes'
import batch from '../api/batch'
import { WMSContext } from './WmsStore'
import { toast } from 'react-toastify'
import _ from 'lodash'

export const PrepareEntranceContext = React.createContext()

export const PrepareEntranceProvider = ({ children }) => {
	const { t } = useTranslation()
	const { user, token } = useContext(UserContext)
	const { warehouse } = useContext(WMSContext)
	const [loadingMaterials, setLoadingMaterials] = useState(false)
	const [loadingErp, setLoadingErp] = useState(false)
	const [savingChanges, setSavingChanges] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [openRelocateModal, setOpenRelocateModal] = useState(false)
	const [materialList, setMaterialList] = useState(null)
	const [filteredMaterials, setFilteredMaterials] = useState(null)
	const [mandatoryAttributes, setMandatoryAttributes] = useState(null)
	const [mandatoryAttrList, setMandatoryAttrList] = useState(null)
	const [endUsers, setEndeUsers] = useState(null)
	const [endUserSelected, setEndUserSelected] = useState(null)
	const [erpRefList, setErpRefList] = useState(null)
	const [erpSelected, setErpSelected] = useState([])
	const [materialqty, setMaterialQty] = useState(0)
	const [newMatList, setNewMatList] = useState([])
	const [newMaterial, setNewMaterial] = useState({
		erpList: [],
		materialList: [],
		attributeFilters: [],
		erp: null,
		mvid: null,
		formatted: null,
		filter: false,
		lengthmm: null,
		weightkg: null,
		heat: null,
		quantity: '',
		pipes: [],
		salesorder: '',
		salesitem: '',
		customerorder: '',
		customeritem: '',
		consignmenttype: null,
		supplier: null
	})
	const [generatedVALID, setGeneratedVALID] = useState(null)
	const [step, setStep] = useState(1)
	const [suppliers, setSuppliers] = useState(null)

	const getMandatoryAttributes = () =>
		new Promise((resolve, reject) => {
			assetsAttr
				.getAttributes(warehouse.id, token)
				.then(response => {
					setMandatoryAttributes(response)
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingAttributes')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getMaterials = () =>
		new Promise((resolve, reject) => {
			commom
				.getMaterials(warehouse.id, token)
				.then(response => {
					resolve()
					setMaterialList(response.materialdescriptions)
					buildAttributesList(response.attributedescriptions, response.materialdescriptions)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingMaterialDsc')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const buildAttributesList = (attrDesc, materialsDesc) => {
		let mandatoryList = _.map(mandatoryAttributes, attribute => ({
			position: _.findIndex(attrDesc, attr => attr.toLowerCase() === attribute.description.toLocaleLowerCase()),
			description: _.findIndex(attrDesc, attr => attr.toLowerCase() === attribute.description.toLocaleLowerCase()),
			valuesMap: new Map()
		}))
		_.forEach(materialsDesc, material => {
			_.forEach(mandatoryList, attribute => {
				attribute.valuesMap.set(material.attributes[attribute.position], material.values[attribute.position])
			})
		})
		_.forEach(mandatoryList, mandAttr => {
			mandAttr.values = Array.from(mandAttr.valuesMap, ([value, name]) => ({
				name,
				value
			}))
		})
		setMandatoryAttrList(mandatoryList)
	}

	const getEndUsers = () =>
		new Promise((resolve, reject) => {
			batch
				.getEndUsers(warehouse.id, token)
				.then(response => {
					resolve()
					setEndeUsers(response)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingEndUser')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getMaterialsByEndUser = () =>
		new Promise((resolve, reject) => {
			batch
				.getMaterials(warehouse.id, endUserSelected, token)
				.then(response => {
					resolve()
					let mat = response
					let filtered = []
					_.forEach(mat, material => {
						_.forEach(materialList, mat => {
							material.id === mat.mvid && filtered.push(mat)
						})
					})
					setFilteredMaterials(filtered)
					let erp = _.map(filtered, material => material.erpreference)
					setErpRefList({ ...erpRefList, erp: _.filter(erp, (item, index) => erp.indexOf(item) === index) })
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingMatListEndUser')} [ ${e.status} ]: ${e.data}`)
					setErpRefList(null)
				})
		})

	const genValids = () =>
		new Promise((resolve, reject) => {
			setSavingChanges(true)
			let payload = {
				responsible: user.name,
				enduser: endUserSelected,
				items: _.map(newMatList, mat => ({
					mid: mat.mvid,
					quantity: mat.quantity,
					customerpo: mat.customerorder,
					customeritem: mat.customeritem,
					consignmenttype: mat.consignmenttype,
					salesorder: mat.salesorder,
					salesitem: mat.salesitem,
					supplierid: mat.supplier
				}))
			}
			batch
				.prepareEntrance(warehouse.id, payload, token)
				.then(response => {
					setGeneratedVALID(response)
					resolve()
					toast.success(t('wms:VALIDGenerationSuccessfull'))
					setSavingChanges(false)
					setOpenRelocateModal(true)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGeneratingVALID')} [ ${e.status} ]: ${e.data}`)
					setSavingChanges(false)
				})
				.finally(() => {
					setSavingChanges(false)
				})
		})

	const resetProcess = () => {
		setEndUserSelected(null)
		setNewMaterial({
			erpList: [],
			materialList: [],
			attributeFilters: [],
			erp: null,
			mvid: null,
			formatted: null,
			filter: false,
			lengthmm: null,
			weightkg: null,
			heat: null,
			quantity: '',
			pipes: [],
			salesorder: '',
			salesitem: '',
			customerorder: '',
			customeritem: '',
			consignmenttype: null,
			supplier: null
		})
		setNewMatList([])
		setErpSelected([])
		setMaterialQty(0)
		setOpenConfirmModal(false)
		setOpenRelocateModal(false)
		setStep(1)
	}

	const getSuppliers = () =>
		new Promise((resolve, reject) => {
			batch
				.getSupplierList(warehouse.id, token)
				.then(response => {
					resolve()
					setSuppliers(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingSuupliers')} [ ${e.status} ]: ${e.data}`)
				})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingMaterials(true)
				await getMaterials()
			} catch (e) {
				console.error(e)
				setLoadingMaterials(false)
			} finally {
				setLoadingMaterials(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getMandatoryAttributes()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		mandatoryAttributes && getMaterials()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mandatoryAttributes])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getEndUsers()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingErp(true)
				endUserSelected && (await getMaterialsByEndUser())
			} catch (e) {
				console.error(e)
				setLoadingErp(false)
			} finally {
				setLoadingErp(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endUserSelected])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getSuppliers()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const renderStore = (
		<PrepareEntranceContext.Provider
			value={{
				loadingMaterials,
				setLoadingMaterials,
				materialList,
				setMaterialList,
				filteredMaterials,
				setFilteredMaterials,
				mandatoryAttributes,
				setMandatoryAttributes,
				mandatoryAttrList,
				setMandatoryAttrList,
				endUsers,
				setEndeUsers,
				endUserSelected,
				setEndUserSelected,
				erpRefList,
				setErpRefList,
				materialqty,
				setMaterialQty,
				newMatList,
				setNewMatList,
				newMaterial,
				setNewMaterial,
				erpSelected,
				setErpSelected,
				loadingErp,
				setLoadingErp,
				generatedVALID,
				setGeneratedVALID,
				genValids,
				savingChanges,
				setSavingChanges,
				openConfirmModal,
				setOpenConfirmModal,
				openRelocateModal,
				setOpenRelocateModal,
				resetProcess,
				step,
				setStep,
				suppliers,
				setSuppliers
			}}>
			{children}
		</PrepareEntranceContext.Provider>
	)
	return renderStore
}
