/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getLevelsList: token =>
		new Promise((resolve, reject) => {
			instance
				.get(`config`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.warehouses)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getLastLevelsList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/levels/${wid}/lastlevels`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.lastlevels)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getLevelContent: (wid, levelId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/bundles/${wid}/at/${levelId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.bundles)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getOwnerships: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`ownership/${wid}/description`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMaterials: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`material/${wid}/description`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getReportsFromValid: (wid, valid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/${wid}/reports-by-valid?valid=${encodeURIComponent(valid)}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.reports)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPipeData: (wid, valid, puuid, token) =>
		new Promise((resolve, reject) => {
			if (valid) {
				instance
					.get(`content/${wid}/pipedata?valid=${encodeURIComponent(valid)}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(response => {
						if (response.status === 200) {
							resolve(response.data)
						} else {
							reject(new Error('CouldNotConnect'))
						}
					})
					.catch(err => {
						reject(err.response || err)
					})
			} else if (typeof puuid !== 'undefined') {
				instance
					.get(`content/${wid}/pipedata?puuid=${encodeURIComponent(puuid)}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(response => {
						if (response.status === 200) {
							resolve(response.data)
						} else {
							reject(new Error('CouldNotConnect'))
						}
					})
					.catch(err => {
						reject(err.response || err)
					})
			}
		}),
	getPrinters: token =>
		new Promise((resolve, reject) => {
			instance
				.get(`print`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.printers)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	setPrintersByBundles: (params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`print/bundles`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve()
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	setPrintersByItems: (params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`print/items`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve()
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMaterialsDescription: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/material/${wid}/description`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.materialdescriptions)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getEndUsers: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/enduserlist`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.enduserlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAppVersion: token =>
		new Promise((resolve, reject) => {
			instance
				.get(`config/appversion`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getReports: (wid, feature, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/${wid}/validated-reports?feature=${feature}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.reports)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getReportItems: (wid, reportID, feature, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inspection/${wid}/validated-list/${reportID}/items?feature=${feature}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.bundles)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	downloadApp: token =>
		new Promise((resolve, reject) => {
			instance
				.get('config/downloadapp', {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
