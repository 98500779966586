/** @format */

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexView } from 'components/common'
import { ReportsContext } from '../stores/ReportStore'
import InspReportList from '../containers/reports/InspList'
import InspReportInfo from '../containers/reports/InspInfo'
import { toast } from 'react-toastify'
import TabsRow from '../components/commom/TabsRow'
import { INSPECTION } from '../utils/enum'
import PageTemplate from '../containers/common/PageTemplate'
import { useWindowSize } from '../utils/hooks'
import ReportEditorModal from '../containers/reportEditor/wrapper'
import DailyReportFilter from '../containers/reports/DailyReportFilter'
import { WMSContext } from '../stores/WmsStore'

const InspectionReportsPage = () => {
	const { t } = useTranslation()
	const {
		inspType,
		setInspType,
		loadingReports,
		inspReportList,
		setSelectedReport,
		loadingDetails,
		reportDetails,
		downloadInspectionReport,
		getInspReportLists,
		isEditorOpen,
		setIsEditorOpen,
		setReportDetails,
		downloadingReport
	} = useContext(ReportsContext)
	const { warehouse } = useContext(WMSContext)

	const listDeadSpace = 0
	const windowSize = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		inspType !== INSPECTION.code.SPOT ? setListHeight(listRef.current.offsetHeight - listDeadSpace) : setListHeight(0)
	}, [listRef, inspType, windowSize])

	useEffect(() => {
		getInspReportLists()
			.then(() => {})
			.catch(e => console.error(e))
			.finally(() => {})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const handleSelectReport = useCallback(
		reportId => {
			reportId != null && setSelectedReport(inspReportList.find(item => item.id === reportId))
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[inspReportList]
	)

	const handleDownloadReport = useCallback(
		reportId => {
			reportId &&
				downloadInspectionReport(reportId)
					.then(() => {})
					.catch(error => {
						toast.error(`${t(error)}`)
					})
					.finally(() => {})
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[downloadInspectionReport]
	)

	const handleOpenReportEditor = useCallback(reportData => {
		setIsEditorOpen(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const closeReportEditorModal = reload => {
		setIsEditorOpen(false)
		setReportDetails(null)
		const hydrate = async () => {
			try {
				await getInspReportLists()
			} catch (e) {
				console.error(e)
			} finally {
			}
		}
		return reload ? hydrate() : null
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:InspectionReports'),
				path: '/wms/manage/reports/edit/inspection'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs} overflow={isEditorOpen ? 'hidden' : 'inherit'}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView
					data-cy='flexview-page-title'
					minWidth='100%'
					fontSize='40px'
					fontWeight='bold'
					margin='0 0 24px 0'>
					{t('wms:InspectionReports')}
				</FlexView>
				<TabsRow
					tabsList={INSPECTION.translateKey.map((item, index) => ({ value: index, label: item }))}
					selectedTab={inspType}
					setSelectedTab={setInspType}
					tabWidth={250}
					width={window.innerWidth - 320}
					onChange={value => setInspType(value)}
					data-cy='tabs-row'
				/>
				<FlexView width='100%' flex='1' flexDirection='row'>
					{inspType !== INSPECTION.code.SPOT ? (
						<FlexView height='100%' width='60%' ref={listRef} margin='0 8px 0 0'>
							<InspReportList
								loadingList={loadingReports}
								list={inspReportList}
								listType={inspType}
								onSelectReport={value => handleSelectReport(value)}
								onDownloadReport={value => handleDownloadReport(value)}
								onCheckReport={value => handleOpenReportEditor(value)}
								height={`${listHeight}px`}
								downloadingReport={downloadingReport}
								data-cy='inspection-report-list'
							/>
						</FlexView>
					) : (
						<DailyReportFilter />
					)}
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{reportDetails && (
							<InspReportInfo
								reportInfo={reportDetails}
								inspectionType={inspType}
								onDownloadReport={value => handleDownloadReport(value)}
								onCheckReport={value => handleOpenReportEditor(value)}
								loadingInfo={loadingDetails}
								data-cy='inspection-report-info'
							/>
						)}
					</FlexView>
				</FlexView>
			</FlexView>
			{reportDetails && isEditorOpen && (
				<ReportEditorModal
					isOpen={isEditorOpen}
					onOutsideClick={closeReportEditorModal}
					report={reportDetails}
					reportType={inspType}
				/>
			)}
		</PageTemplate>
	)
}

export default InspectionReportsPage
