/** @format */

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import common from 'apps/wms/api/common'

import { Card, Button, Modal, Icon, LoadingOverlay, FlexView } from 'components/common'
import { UserContext } from 'stores/UserStore'

import Android from '../../assets/images/android.gif'
import { toast } from 'react-toastify'

const AppDownload = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { token } = useContext(UserContext)
	const [isLoading, setIsLoading] = useState(false)
	const [downloading, setDownloading] = useState(false)
	const [appData, setAppData] = useState(null)

	useEffect(
		() => {
			if (isOpen) {
				setIsLoading(true)
				common
					.getAppVersion(token)
					.then(response => {
						var xml2js = require('xml2js')
						let parser = new xml2js.Parser()
						parser.parseString(response, (err, result) => {
							setAppData(result.update)
							console.log(result)
						})
					})
					.catch(e => console.error(e))
					.finally(() => setIsLoading(false))
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isOpen]
	)

	const codeToName = useCallback(code => {
		let major = Math.floor(code / 10000)
		let minor = Math.floor((code - major * 10000) / 100)
		let patch = code - major * 10000 - minor * 100
		return `${major}.${minor}.${patch}`
	}, [])

	const handleDownloadApp = useCallback(
		() => {
			setDownloading(true)
			common
				.downloadApp(token)
				.then(response => {
					let fileName = 'wms-app.apk'
					let a = document.createElement('a')
					let file = new Blob([response], {
						type: 'application/apk'
					})
					let fileURL = window.URL.createObjectURL(file)
					a.href = fileURL
					a.target = '_blank'
					a.download = fileName
					a.click()
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorDownloadingApp')} [ ${e.status} ]: ${e.data}`)
				})
				.finally(() => setDownloading(false))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[appData]
	)

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='280px' position='relative' alignItems='center'>
				<LoadingOverlay visible={isLoading} borderRadius='16px' />
				{appData !== null ? (
					<FlexView margin='16px' alignItems='center'>
						<FlexView width='100%' fontSize='big' fontWeight='bold' margin='0 0 4px 0'>
							{t('wms:FieldAppDownload')}
						</FlexView>
						<FlexView width='100%' fontSize='normal' margin='0 0 32px 0' alignItems='center'>
							{t('wms:Version')} {codeToName(appData.version[0])}
						</FlexView>
						<img src={Android} alt='Android'></img>
						<Button
							fontSize='medium'
							margin='32px auto 0 auto'
							color='white'
							backgroundColor='secondary'
							onClick={() => {
								handleDownloadApp()
							}}>
							<Icon name='download' color='white' width='20px' height='20px' margin='0px 12px 0 0' />
							{t('wms:Download')}
						</Button>
						<LoadingOverlay visible={downloading} borderRadius='16px' />
						<Button
							fontSize='tiny'
							margin='32px auto 0 auto'
							padding='0 16px 0 0'
							backgroundColor='white'
							color='darkgray'
							elevation='none'
							onClick={() => {
								onOutsideClick()
							}}>
							<Icon name='arrow-left' color='darkgray' width='12px' height='12px' margin='0px 8px' />
							{t('wms:GoTo')} WMS
						</Button>
					</FlexView>
				) : null}
			</Card>
		</Modal>
	)
}

export default AppDownload
