/** @format */

import React, { useState, useEffect, useContext } from 'react'
import { toInteger } from 'lodash'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, Modal, Icon, LoadingOverlay } from 'components/common'
import { Select, Input } from 'components/form'

import { STATUS } from '../../utils/enum'

import { RecInspContext } from '../../stores/RecInspStore'
import DatePicker from 'apps/wms/components/forms/DatePicker/DatePicker'
import theme from 'utils/theme'

const RIModal = ({ isOpen, onOutsideClick }) => {
	const {
		materialList,
		newRI,
		endusers,
		getMaterialsByEU,
		getCustormerOrdersList,
		getSalesOrdersList,
		getAvailableQty
	} = useContext(RecInspContext)
	const { t } = useTranslation()
	const [sourceType, setSourceType] = useState(null)
	const [sourceQty, setSourceQty] = useState(0)
	const [riUpload, setRIUpload] = useState(false)

	const [sourceEnduser, setSourceEnduser] = useState(null)
	const [sourceMaterial, setSourceMaterial] = useState(null)
	const [sourceMaterialList, setSourceMaterialList] = useState([])

	const [loadingOrders, setLoadingOrders] = useState(false)
	const [sourceCustomerOrder, setSourceCustomerOrder] = useState(null)
	const [sourceCustomerItem, setSourceCustomerItem] = useState(null)
	const [customerOrders, setCustomerOrders] = useState([])
	const [customerItems, setCustomerItems] = useState([])
	const [sourceSalesOrder, setSourceSalesOrder] = useState(null)
	const [sourceSalesItem, setSourceSalesItem] = useState(null)
	const [salesOrders, setSalesOrders] = useState([])
	const [salesItems, setSalesItems] = useState([])

	const [qntAvailable, setQntAvailable] = useState(0)
	const [loadingMaterials, setLoadingMaterials] = useState(false)

	const [loadingQty, setLoadingQty] = useState(false)

	const baseRI = {
		name: '',
		expecteddate: '',
		content: []
	}
	const [riData, setRIData] = useState(baseRI)

	useEffect(() => {
		setSourceEnduser(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceType])

	useEffect(() => {
		setRIData(baseRI)
		setSourceMaterial(null)
		setSourceMaterialList([])
		if (sourceEnduser !== null && sourceType !== null) {
			setLoadingMaterials(true)
			getMaterialsByEU(sourceEnduser.id)
				.then(materialList => {
					materialList.length ? setSourceMaterialList(materialList) : setSourceMaterialList([])
				})
				.catch(e => console.error(e))
				.finally(() => setLoadingMaterials(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceEnduser])

	useEffect(() => {
		setSourceCustomerOrder(null)
		setSourceCustomerItem(null)
		setSourceSalesOrder(null)
		setSourceSalesItem(null)
		if (sourceEnduser !== null && sourceMaterial !== null) {
			setLoadingOrders(true)
			sourceType === 'CUSTOMER_ITEM' &&
				getCustormerOrdersList(sourceEnduser.id, sourceMaterial)
					.then(customerOrders => {
						customerOrders.length ? setCustomerOrders(customerOrders) : setCustomerOrders([])
					})
					.catch(e => console.error(e))
					.finally(() => setLoadingOrders(false))
			sourceType === 'SALES_ITEM' &&
				getSalesOrdersList(sourceEnduser.id, sourceMaterial)
					.then(salesOrders => {
						salesOrders.length ? setSalesOrders(salesOrders) : setSalesOrders([])
					})
					.catch(e => console.error(e))
					.finally(() => setLoadingOrders(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceMaterial])

	useEffect(() => {
		setSourceSalesItem(null)
		setSourceCustomerItem(null)
		setSalesItems([])
		setCustomerItems([])
		if (sourceCustomerOrder !== null || sourceSalesOrder !== null) {
			sourceType === 'CUSTOMER_ITEM' &&
				setCustomerItems(customerOrders.find(co => co.value === sourceCustomerOrder).items)
			sourceType === 'SALES_ITEM' && setSalesItems(salesOrders.find(so => so.value === sourceSalesOrder).items)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceCustomerOrder, sourceSalesOrder])

	useEffect(() => {
		setSourceQty(0)
		setQntAvailable(null)
		if (sourceCustomerItem !== null || sourceSalesItem !== null) {
			setLoadingQty(true)
			sourceType === 'CUSTOMER_ITEM' &&
				getAvailableQty(sourceEnduser.id, sourceMaterial, sourceCustomerOrder, sourceCustomerItem, false)
					.then(quantity => setQntAvailable(quantity))
					.catch(e => console.error(e))
					.finally(() => setLoadingQty(false))
			sourceType === 'SALES_ITEM' &&
				getAvailableQty(sourceEnduser.id, sourceMaterial, sourceSalesOrder, sourceSalesItem, true)
					.then(quantity => setQntAvailable(quantity))
					.catch(e => console.error(e))
					.finally(() => setLoadingQty(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceCustomerItem, sourceSalesItem])

	const matFormatted = id => {
		return id !== null &&
			id !== undefined &&
			materialList &&
			materialList.length &&
			materialList.find(mat => mat.mvid === id)
			? materialList.find(mat => mat.mvid === id).formatted
			: ''
	}

	const addContent = () => {
		if (sourceMaterial && sourceQty !== 0) {
			setRIData({
				...riData,
				content: [
					...riData.content,
					{
						enduserid: sourceEnduser.id,
						enduser: sourceEnduser.name,
						mid: sourceMaterial,
						customerpo: sourceCustomerOrder,
						customeritem: sourceCustomerItem,
						salesorder: sourceSalesOrder,
						salesitem: sourceSalesItem,
						quantity: sourceQty
					}
				]
			})
			setQntAvailable(qntAvailable - sourceQty)
			setSourceQty(0)
		}
	}

	const removeContent = index => {
		if (index !== null && index < riData.content.length) {
			sourceMaterial === riData.content[index].mid && setQntAvailable(qntAvailable + riData.content[index].quantity)
			riData.content.splice(index, 1)
			setRIData({ ...riData, content: [...riData.content] })
		}
	}

	const validateData = () => {
		let isValid = true
		isValid = isValid && riData.name && riData.name.length <= 30 && riData.expecteddate && riData.content.length
		return isValid
	}

	const saveRI = async () => {
		try {
			setRIUpload(true)
			await newRI(riData, sourceType)
				.then(response => response && setSourceEnduser(null))
				.finally(() => setRIUpload(false))
		} catch (e) {
			console.error(e)
		}
	}

	const inputMessage = () => {
		if (riData.name.length <= 30) return t('wms:MaxCharacter')
		else return t('wms:MaxCharacterNotAllowed')
	}

	const inputMessageType = () => {
		if (riData.name.length <= 30) return 'info'
		else return 'error'
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='80%' height='85%' position='relative' data-cy='card-modal-wrapper'>
				<LoadingOverlay visible={riUpload} borderRadius='16px' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:NewReceivingInspection')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView maxWidth='40%' minWidth='40%' minHeight='100%' maxHeight='100%' margin='0 16px 0 0'>
						<FlexView flexDirection='row' width='100%'>
							<Card margin='0 8px 16px 0' minWidth='calc(100% - 48px)' maxWidth='calc(100% - 48px)'>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
									{t('wms:TypeOfSource')}
								</FlexView>
								<Select
									placeholder={t('wms:SourceOfRecInspection')}
									value={sourceType}
									options={[
										{
											value: 'CUSTOMER_ITEM',
											label: t(`wms:CustomerOrderAndItem`)
										},
										{
											value: 'SALES_ITEM',
											label: t(`wms:SalesOrderAndItem`)
										}
									]}
									onChange={value => {
										setSourceType(value)
									}}
									width='100%'
									inline={true}
									margin='0'
									data-cy='select-source-type'
								/>
							</Card>
						</FlexView>

						<Card
							margin='0 0 4px 0'
							width='calc(100% - 48px)'
							maxHeight='calc(85vh - 260px)'
							style={{ overflowY: 'scroll' }}
							flex='1'>
							<FlexView width='100%' margin='0 0 16px 0' padding='0' position='relative'>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
									{t('wms:EndUser')}
								</FlexView>
								<Select
									placeholder={t(`wms:EndUser`)}
									value={sourceEnduser && sourceEnduser.id}
									options={endusers.map(({ id, name }) => ({
										value: id,
										label: name
									}))}
									onChange={value => {
										setSourceEnduser(endusers.find(eu => eu.id === value))
									}}
									width='100%'
									inline={false}
									margin='0'
									disabled={riData.content.length !== 0 || sourceType === null}
									searchable
									data-cy='select-enduser'
								/>
							</FlexView>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
								{t('wms:Material')}
							</FlexView>
							<FlexView width='100%' margin='0 0 16px 0' padding='0' position='relative'>
								<LoadingOverlay visible={loadingMaterials} />
								<Select
									searchable
									placeholder={t('wms:SelectMaterial')}
									value={sourceMaterial}
									options={sourceMaterialList.map(material => ({
										label: material.formatted,
										value: material.id
									}))}
									onChange={value => {
										setSourceMaterial(value)
									}}
									width='100%'
									margin='0'
									disabled={riData.content.length !== 0 || sourceEnduser === null}
									data-cy='select-material'
								/>
							</FlexView>
							{sourceType === 'CUSTOMER_ITEM' && (
								<FlexView width='100%' margin='0 0 16px 0' padding='0' position='relative' flexDirection='row'>
									<LoadingOverlay visible={loadingOrders} />
									<Select
										disabled={riData.content.length !== 0 || sourceMaterial === null}
										label={t(`wms:CustomerOrder`)}
										placeholder={t(`wms:CustomerOrder`)}
										value={sourceCustomerOrder}
										options={customerOrders.map(value => ({
											label: value.value,
											value: value.value
										}))}
										onChange={value => {
											setSourceCustomerOrder(value)
										}}
										width='calc(50% - 8px)'
										inline={false}
										margin='0 8px 0 0'
										data-cy='select-customer-order'
									/>
									<Select
										disabled={riData.content.length !== 0 || sourceCustomerOrder === null}
										label={t(`wms:CustomerItem`)}
										placeholder={t(`wms:CustomerItem`)}
										value={sourceCustomerItem}
										options={customerItems.map(value => ({
											label: value,
											value: value
										}))}
										onChange={value => {
											setSourceCustomerItem(value)
										}}
										width='calc(50% - 8px)'
										inline={false}
										margin='0 0 0 8px'
										data-cy='select-customer-item'
									/>
								</FlexView>
							)}
							{sourceType === 'SALES_ITEM' && (
								<FlexView width='100%' margin='0 0 16px 0' padding='0' position='relative' flexDirection='row'>
									<LoadingOverlay visible={loadingOrders} />
									<Select
										disabled={riData.content.length !== 0 || sourceMaterial === null}
										label={t(`wms:SalesOrder`)}
										placeholder={t(`wms:SalesOrder`)}
										value={sourceSalesOrder}
										options={salesOrders.map(value => ({
											label: value.value,
											value: value.value
										}))}
										onChange={value => {
											setSourceSalesOrder(value)
										}}
										width='calc(50% - 8px)'
										inline={false}
										margin='0 8px 0 0'
									/>
									<Select
										disabled={riData.content.length !== 0 || sourceSalesOrder === null}
										label={t(`wms:SalesItem`)}
										placeholder={t(`wms:SalesItem`)}
										value={sourceSalesItem}
										options={salesItems.map(value => ({
											label: value,
											value: value
										}))}
										onChange={value => {
											setSourceSalesItem(value)
										}}
										width='calc(50% - 8px)'
										inline={false}
										margin='0 0 0 8px'
									/>
								</FlexView>
							)}

							<FlexView width='100%' margin='0' padding='0' position='relative'>
								<LoadingOverlay visible={loadingQty} />
								<Input
									label={`${t('wms:Quantity')} ${
										qntAvailable ? `[${t('wms:AvailablePipes')}: ${qntAvailable}]` : ''
									}`}
									placeholder={`${qntAvailable ? qntAvailable : ''}`}
									margin='0'
									min='1'
									max={qntAvailable}
									type='number'
									value={sourceQty}
									onChange={e => {
										setSourceQty(toInteger(e.target.value))
									}}
									success={sourceQty <= qntAvailable}
									error={sourceQty > qntAvailable}
									fontSize='small'
									width='100%'
									data-cy='input-available-qty'
								/>
							</FlexView>
							<FlexView flexDirection='row' width='100%' justifyContent='space-between' margin='auto 0 0 0'>
								{riData.content.length !== 0 ? (
									<Button
										fontSize='medium'
										margin='0'
										color='white'
										backgroundColor='warning'
										onClick={() => {
											setSourceEnduser(null)
										}}
										data-cy='button-reset'>
										<Icon name='refresh' color='white' height='16px' width='16px' margin='0 8px 0 0' />
										{t('wms:Restart')}
									</Button>
								) : null}
								<Button
									fontSize='medium'
									margin='auto 0 0 auto'
									color='white'
									backgroundColor='secondary'
									disabled={!(!!sourceMaterial && sourceQty !== 0)}
									onClick={() => {
										addContent()
									}}
									data-cy='button-add-part'>
									{t('wms:AddPart')}
								</Button>
							</FlexView>
						</Card>
					</FlexView>
					<FlexView width='calc(60% - 16px)' minHeight='100%' maxHeight='100%'>
						<Card margin='0 0 4px 0' width='calc(100% - 48px)' minHeight='inherit' flex='1'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:ReceivingInspectionInfo')}
							</FlexView>
							{sourceEnduser !== null ? (
								<FlexView flexDirection='row' width='100%' maxWidth='100%' flexWrap='wrap' margin='0 0 16px 0'>
									<FlexView width='calc(50% - 8px)' margin='0 8px 0 0'>
										<Input
											label={`${t('wms:Name')}`}
											placeholder={`${t('wms:Name')}`}
											margin='8px 0 0 0'
											value={riData.name}
											onChange={e => {
												setRIData({ ...riData, name: e.target.value })
											}}
											fontSize='small'
											width='100%'
											data-cy='input-name'
											message={inputMessage()}
											messageType={inputMessageType()}
										/>
									</FlexView>
									<DatePicker
										label={`${t('wms:DispatchExpectedDate')}`}
										placeholder={`${t('wms:SelectDate')}`}
										margin='8px 0 0 auto'
										timePicker={true}
										value={riData.expecteddate}
										onChange={e => {
											setRIData({ ...riData, expecteddate: e })
										}}
										fontSize='small'
										data-cy='datepicker'
										noPast={true}
									/>
								</FlexView>
							) : (
								<FlexView
									width='100%'
									fontWeight='bold'
									fontSize='24px'
									color='lightGray'
									margin='auto 0'
									alignItems='center'
									justifyContent='center'>
									{t('wms:SelectEnduser')}
								</FlexView>
							)}
							{riData.content.length !== 0 ? (
								<FlexView flexDirection='row' flexWrap='wrap' justifyContent='space-between'>
									<FlexView width='30%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:EndUser')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{sourceEnduser && sourceEnduser.name}
										</FlexView>
									</FlexView>
									{sourceType === 'CUSTOMER_ITEM' ? (
										<>
											<FlexView width='40%' margin='0 0 8px 0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:CustomerOrder')}
												</FlexView>
												<FlexView
													width='100%'
													style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
													padding='0 0 0 8px'>
													{sourceCustomerOrder}
												</FlexView>
											</FlexView>
											<FlexView width='20%' margin='0 0 8px 0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:CustomerItem')}
												</FlexView>
												<FlexView
													width='100%'
													style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
													padding='0 0 0 8px'>
													{sourceCustomerItem}
												</FlexView>
											</FlexView>
										</>
									) : null}
									{sourceType === 'SALES_ITEM' ? (
										<>
											<FlexView width='40%' margin='0 0 8px 0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:SalesOrder')}
												</FlexView>
												<FlexView
													width='100%'
													style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
													padding='0 0 0 8px'>
													{sourceSalesOrder}
												</FlexView>
											</FlexView>
											<FlexView width='20%' margin='0 0 8px 0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:SalesItem')}
												</FlexView>
												<FlexView
													width='100%'
													style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
													padding='0 0 0 8px'>
													{sourceSalesItem}
												</FlexView>
											</FlexView>
										</>
									) : null}
									<FlexView width='100%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:Material')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{matFormatted(sourceMaterial)}
										</FlexView>
									</FlexView>
								</FlexView>
							) : null}
							{sourceEnduser !== null ? (
								<FlexView
									width='100%'
									height='calc((85vh - 90px)*0.35)'
									style={{ overflowY: 'scroll', overflowX: 'auto' }}>
									{riData && riData.content.length ? (
										riData.content.map((content, index) => (
											<FlexView
												key={index}
												width='100%'
												margin='0'
												flexDirection='row'
												padding='8px 0'
												style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
												data-cy='content-list-item'>
												<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
													<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
												</FlexView>
												<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
													{`${t('wms:Quantity')}: ${content.quantity}`}
												</FlexView>

												<FlexView
													width='25%'
													height='100%'
													margin='0'
													padding='0'
													flexDirection='row'
													justifyContent='center'>
													<Button
														fontSize='small'
														margin='auto 0 auto auto'
														padding='8px 16px'
														color='white'
														backgroundColor='error'
														disabled={false}
														onClick={() => {
															removeContent(index)
														}}>
														<Icon
															name='trash'
															color='white'
															height='16px'
															width='16px'
															margin='0'
															//tooltip={`${t("wms:RemovePart")}`}
														/>
													</Button>
													{false && content.status === STATUS.code.RUNNING && (
														<Button
															fontSize='tiny'
															margin='4px 0 0 auto'
															color='white'
															backgroundColor='secondary'
															disabled={false}
															onClick={() => {}}>
															{t('wms:Reopen')}
														</Button>
													)}
												</FlexView>
											</FlexView>
										))
									) : (
										<></>
									)}
								</FlexView>
							) : null}
						</Card>
					</FlexView>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='16px 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='success'
						disabled={!validateData()}
						onClick={() => saveRI()}>
						{t('wms:Save')}
						<Icon name='save' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default RIModal
