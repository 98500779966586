import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Button, FlexView, Icon } from 'components/common'
import { Input } from 'components/form'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { UserContext } from 'stores/UserStore'

const BackArrow = styled(Icon)`
  position: absolute;
  left: 0;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.gray};
  font-weight: bold;
`

const ChangePasswordForm = () => {
  const { t } = useTranslation()
  const { changePassword, resetAuthState } = useContext(UserContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [formState, setFormState] = useState({
    username: {
      value: '',
      dirty: false
    },
    password: {
      value: '',
      dirty: false
    },
    newPassword: {
      value: '',
      dirty: false
    },
    confirmPassword: {
      value: '',
      dirty: false
    }
  })

  const handleInputChange = event => {
    event.persist()
    setFormState(currentFormState => ({
      ...currentFormState,
      [event.target.name]: {
        value: event.target.value,
        dirty: true
      }
    }))
  }

  const passwordMatchError = useMemo(() => {
    const { confirmPassword, newPassword } = formState
    return confirmPassword.dirty && (confirmPassword.value !== newPassword.value)
  }, [formState])

  const onSubmit = async e => {
    e.preventDefault()
    if (!disableSubmit) {
      setLoading(true)
      try {
        const { username, password, newPassword } = formState
        await changePassword(username.value, password.value, newPassword.value)
        toast.success(t('login.messages.passwordChanged'))
        resetAuthState()
      }
      catch (e) {
        setErrorMessage(e.data || t(`errors.${e.message}`))
        setLoading(false)
      }
    }
  }

  const disableSubmit = !(formState.username.value.length && formState.password.value.length && formState.newPassword.value.length >= 9 && formState.newPassword.value === formState.confirmPassword.value)

  return (
    <form style={{ width: '100%'}} onSubmit={onSubmit} autoComplete="off">
      <Wrapper>
        <FlexView margin="8px 0px 16px" alignItems="center" justifyContent="center" fontSize="subtitle" color="darkGray" fontWeight="bold" width="100%" position="relative">
          <BackArrow name="arrow-left" tooltip={t('Go Back')} onClick={resetAuthState} />
          {t('login.newPasswordLabel')}
        </FlexView>
        <Input
          name="username"
          label={t('Username')}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          type="text"
          width="100%"
          value={formState.username.value}
          onChange={handleInputChange}
          error={!!errorMessage}
          message={errorMessage}
          messageType="error"
          required
        />
        <Input
          type="password"
          name="password"
          label={t('Password')}
          width="100%"
          onChange={handleInputChange}
          required
        />
        <Input
          type="password"
          name="newPassword"
          label={t('New Password')}
          width="100%"
          autoComplete="new-password"
          onChange={handleInputChange}
          error={formState.newPassword.dirty && formState.newPassword.value.length < 9}
          messageType="error"
          message={(formState.newPassword.dirty && formState.newPassword.value.length < 9) ? t('login.errors.PasswordLengthError') : ''}
          required
        />
        <Input
          type="password"
          name="confirmPassword"
          label={t('Confirm Password')}
          width="100%"
          autoComplete="new-password"
          onChange={handleInputChange}
          error={passwordMatchError}
          messageType="error"
          message={passwordMatchError ? t('login.errors.PasswordMatchError') : ''}
          required
        />
        <FlexView flexDirection="row" alignItems="center" justifyContent="center" width="100%" margin="10px 0px">
          <Button type="submit" elevation="medium" backgroundGradient="main" color="white" margin="0px" width="40%" disabled={isLoading || disableSubmit} isLoading={isLoading}>
            {t('Change')}
          </Button>
        </FlexView>
      </Wrapper>
    </form>
  )
}

export default ChangePasswordForm