/** @format */

import { FlexView } from 'components/common'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Subheader from '../containers/common/Subheader'
import ModifiedProductInfo from '../containers/modifiedproduct/Info'
import ModifProductsList from '../containers/modifiedproduct/List'
import { ModifiedProductContext } from '../stores/ModifProductStore'
import { genParams, modifProducts } from '../utils/hubs'

const ModifiedProductPage = () => {
	const { t } = useTranslation()
	const { selectedList } = useContext(ModifiedProductContext)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:ModifiedProduct'),
				path: `/wms/modifproduct${genParams(modifProducts)}`
			},
			{
				name: t('wms:Manage'),
				path: '/wms/dispatch/modifproducts'
			}
		],
		[t]
	)

	return (
		<FlexView
			width='100%'
			maxWidth='100%'
			flex='1'
			alignItems='center'
			margin='0'
			padding='0'
			backgroundColor='#F8F8F9'
			position='relative'>
			<Subheader crumbs={crumbs} />
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:ModifiedProduct')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='55%' margin='0 8px 0 0'>
						<ModifProductsList />
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{selectedList && <ModifiedProductInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
		</FlexView>
	)
}

export default ModifiedProductPage
