/** @format */

import React, { useContext } from 'react'
import PrivateRoute from 'containers/router/PrivateRoute'
import { useHistory } from 'react-router-dom'
import { Switch } from 'react-router-dom'
import _ from 'lodash'

import { UserContext } from 'stores/UserStore'

import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { WMSProvider } from './stores/WmsStore'
import { UsageRightsProvider } from './stores/UsageRightsStore'
import { RigReturnProvider } from './stores/RigReturnStore'
import { RecInspProvider } from './stores/RecInspStore'
import { MovementProvider } from './stores/MovementStore'
import { MRProvider } from './stores/MRStore'
import { ContentProvider } from './stores/ContentStore'
import { ReportsProvider } from './stores/ReportStore'
import { BatchEntranceProvider } from './stores/BatchEntranceStore'
import { DispatchProvider } from './stores/DispatchStore'
import HomePage from './pages/HomePage'
import UsageRightsPage from './pages/UsageRightsPage'
import MovementPage from './pages/MovementPage'
import BatchEntrancePage from './pages/BatchEntrancePage'
import ContentPage from './pages/ContentPage'
import MatReqPage from './pages/MatReqPage'
import RecInspPage from './pages/RecInspPage'
import RigReturnPage from './pages/RigReturnPage'
import InspectionReportsPage from './pages/InspectionReportsPage'
import ExportReportsPage from './pages/ExportReportsPage'
import ReviewReportsPage from './pages/ReviewReportsPage'
import DispatchPage from './pages/DispatchPage'
import HubPage from './pages/Hubpage'
import FeaturesMapPage from './pages/FeaturesMapPage'

import './i18n'

import {
	genParams,
	entrance,
	materialReq,
	modifProducts,
	inspection,
	ageManagement,
	rigReturn,
	dispatch,
	assets,
	content
	//inventory
} from './utils/hubs'
import { ModifiedProductProvider } from './stores/ModifProductStore'
import ModifiedProductPage from './pages/ModifiedProductPage'
import { PerInspectionProvider } from './stores/PerInspStore'
import PerInspPage from './pages/PerInspPage'
import { InventoryProvider } from './stores/InventoryStore'
import InventoryPage from './pages/InventoryPage'
import { ReportDataProvider } from './stores/ReportDataStore'
import { AssetsSettingsProvider } from './stores/AssetsSettingsStore'
import AssetsSettingsPage from './pages/AssetsSettingsPage'
import { AssetsPrinterProvider } from './stores/AssetsPrinterStore'
import AssetsPrinterPage from './pages/AssetsPrinterPage'
import AssetsUsageRightsPage from './pages/AssetsUsageRightsPage'
import { AssetsUsageRightsProvider } from './stores/AssetsUsageRightsStore'
import PipeAgeManagementPage from './pages/PipeAgeManagementPage'
import { AgeManagementProvider } from './stores/PipeAgeManagementStore'
import { AssetsWarehouseProvider } from './stores/AssetsWarehouseStore'
import AssetsWarehousePage from './pages/AssetsWarehousePage'
import { AssetsTransportProvider } from './stores/AssetsTransportStore'
import AssetsTransportPage from './pages/AssetsTransportPage'
import { AssetsAgeMgmtProvider } from './stores/AssetsAgeMgmtStore'
import AssetsAgeMgmtPage from './pages/AssetsAgeMgmtPage'
import { AssetsReportRefProvider } from './stores/AssetsReportRefStore'
import AssetsReportRefPage from './pages/AssetsReportRefPage'
import { AssetsLevelsProvider } from './stores/AssetsLevelsStore'
import AssetsLevelsPage from './pages/AssetsLevelsPage'
import { ThreadCompoundProvider } from './stores/AssetsThreadCompoundStore'
import AssetsThreadCompoundPage from './pages/AssetsThreadCompoundPage'
import { AssetsInspSettingsProvider } from './stores/AssetsInspSettingsStore'
import AssetsInspSettingsPage from './pages/AssetsInspSettingsPage'
import { NominalDataProvider } from './stores/NominalDataModifStore'
import NominalDataModifPage from './pages/NominalDataModifPage'
import { AssetsAttributesProvider } from './stores/AssetsAttributesStore'
import AssetsAttributesPage from './pages/AssetsAttributesPage'
import AssetsMaterialPage from './pages/AssetsMaterialPage'
import { AssetsMaterialProvider } from './stores/AssetsMaterialStore'
import { ActualDataProvider } from './stores/ActualDataModifStore'
import ActualDataModifPage from './pages/ActualDataModifPage'
import { TagPrintingProvider } from './stores/TagPrintingStore'
import TagPrintingPage from './pages/TagPrintingPage'
import { PrepareEntranceProvider } from './stores/PrepareEntranceStore'
import PrepareEntrancePage from './pages/PrepareEntrancePage'
import TagServerPage from './pages/TagServerPage'
import { AssetsEquipmentsProvider } from './stores/AssetsEquipmentsStore'
import AssetsEquipmentPage from './pages/AssetsEquipmentsPage'
import CloudLogsPage from './pages/CloudLogsPage'
import { CloudLogsProvider } from './stores/CloudLogsStore'

export const getSideMenu = (user, redirectTo, translate) => [
	{
		key: 'WMS',
		label: translate('wms:WMS'),
		roles: ['administrators', 'anyOtherRoles', 'wmsadmin', 'wmsquality', 'wmslogistic', 'wmsoper'],
		items: [
			{
				key: 'wms-entrance',
				icon: '',
				label: translate('wms:Entrance'),
				roles: ['wmsquality'],
				onClick: redirectTo(`/wms/entrance${genParams(entrance)}`)
			},
			{
				key: 'wms-movement',
				icon: '',
				label: translate('wms:Movement'),
				roles: ['wmslogistic'],
				onClick: redirectTo('/wms/manage/move')
			},
			{
				key: 'wms-usage-rights',
				icon: '',
				label: translate('wms:UsageRights'),
				roles: ['wmsquality'],
				onClick: redirectTo('/wms/manage/usagerights')
			},
			{
				key: 'wms-content',
				icon: '',
				label: translate('wms:Content'),
				roles: ['wmsoper'],
				onClick: redirectTo(`/wms/content${genParams(content)}`)
			},
			{
				key: 'wms-inventory',
				icon: '',
				label: translate('wms:Inventory'),
				roles: ['wmslogistic'],
				onClick: redirectTo('/wms/manage/inventory/check')
			},
			{
				key: 'wms-agemanagement',
				icon: '',
				label: translate('wms:AgeManagement'),
				roles: ['wmsquality'],
				onClick: redirectTo(`/wms/pipeage${genParams(ageManagement)}`)
			},
			{
				key: 'wms-inspection',
				icon: '',
				label: translate('wms:Inspection'),
				roles: ['wmsquality'],
				onClick: redirectTo(`/wms/inspection${genParams(inspection)}`)
			},
			{
				key: 'wms-materialrequest',
				icon: '',
				label: translate('wms:MaterialRequisition'),
				roles: ['wmslogistic'],
				onClick: redirectTo(`/wms/matreq${genParams(materialReq)}`)
			},
			{
				key: 'wms-modifiedproducts',
				icon: '',
				label: translate('wms:ModifiedProduct'),
				roles: ['wmsquality'],
				onClick: redirectTo(`/wms/modifproduct${genParams(modifProducts)}`)
			},
			{
				key: 'wms-rigreturn',
				icon: '',
				label: translate('wms:RigReturn'),
				roles: ['wmslogistic'],
				onClick: redirectTo(`/wms/rigreturn${genParams(rigReturn)}`)
			},
			{
				key: 'wms-dispatch',
				icon: '',
				label: translate('wms:Dispatch'),
				roles: ['wmslogistic'],
				onClick: redirectTo(`/wms/dispatch${genParams(dispatch)}`)
			},
			{
				key: 'wms-features',
				icon: '',
				label: translate('wms:FeaturesMap'),
				roles: ['administrators'],
				onClick: redirectTo(`/wms/features`)
			} /*
      {
        key: 'wms-app',
        icon: '',
        label: translate('wms:App'),
        roles: ['wmsoper'],
        onClick: redirectTo('/wms/appdownload'),
      },*/,
			{
				key: 'wms-assets',
				icon: '',
				label: translate('wms:Assets'),
				roles: ['wmsadmin'],
				onClick: redirectTo(`/wms/assets${genParams(assets)}`)
			},
			{
				key: 'tag-server',
				icon: '',
				label: 'TagServer',
				roles: ['administrators'],
				onClick: redirectTo(`/tag-server/`)
			},
			{
				key: 'wms-cloud-logs',
				icon: '',
				label: translate('wms:CloudLogs'),
				roles: ['administrators'],
				onClick: redirectTo(`/wms/cloud-logs`)
			}
		]
	}
]

const Router = () => {
	const { user } = useContext(UserContext)
	const roles = ['administrators', 'wmsadmin', 'wmsquality', 'wmslogistic', 'wmsoper']
	const hasRole = !roles || (user && _.intersection(user.roles, roles).length)

	const history = useHistory()
	history.location.pathname === '/' && hasRole && history.push('/wms/')

	return (
		<>
			<WMSProvider>
				<DndProvider backend={HTML5Backend}>
					<Switch>
						<PrivateRoute
							roles={['administrators', 'wmsadmin', 'wmsquality', 'wmslogistic', 'wmsoper']}
							exact
							path='/wms/'>
							<HomePage />
						</PrivateRoute>
						<PrivateRoute
							exact
							path={[
								'/wms/entrance',
								'/wms/printing',
								'/wms/inventory',
								'/wms/pipeage',
								'/wms/inspection',
								'/wms/matreq',
								'/wms/modifproduct',
								'/wms/rigreturn',
								'/wms/dispatch',
								'/wms/assets',
								'/wms/content'
							]}>
							<HubPage />
						</PrivateRoute>
						<PrivateRoute roles={['administrators']} exact path='/wms/features'>
							<FeaturesMapPage />
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin', 'wmsoper']} exact path='/wms/content/main'>
							<ContentProvider>
								<ContentPage />
							</ContentProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin', 'wmsoper']} exact path='/wms/entrance/printing'>
							<TagPrintingProvider>
								<TagPrintingPage />
							</TagPrintingProvider>
						</PrivateRoute>
						<PrivateRoute roles={['wmsadmin']} exact path='/wms/pipedata/nominaldatamodif'>
							<BatchEntranceProvider>
								<NominalDataProvider>
									<NominalDataModifPage />
								</NominalDataProvider>
							</BatchEntranceProvider>
						</PrivateRoute>
						<PrivateRoute roles={['wmsadmin']} exact path='/wms/entrance/prepare'>
							<PrepareEntranceProvider>
								<PrepareEntrancePage />
							</PrepareEntranceProvider>
						</PrivateRoute>
						<PrivateRoute roles={['wmsadmin']} exact path='/wms/pipedata/modify'>
							<ActualDataProvider>
								<ActualDataModifPage />
							</ActualDataProvider>
						</PrivateRoute>
						<PrivateRoute
							roles={['administrators', 'wmsadmin', 'wmslogistic']}
							exact
							path='/wms/manage/usagerights'>
							<UsageRightsProvider>
								<UsageRightsPage />
							</UsageRightsProvider>
						</PrivateRoute>
						<PrivateRoute
							roles={['administrators', 'wmsadmin', 'wmsquality']}
							exact
							path='/wms/manage/pipeagemanagement'>
							<AgeManagementProvider>
								<PipeAgeManagementPage />
							</AgeManagementProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin', 'wmslogistic']} exact path='/wms/manage/move'>
							<MovementProvider>
								<MovementPage />
							</MovementProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin', 'wmsquality']} exact path='/wms/entrance/batch'>
							<BatchEntranceProvider>
								<BatchEntrancePage />
							</BatchEntranceProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin', 'wmsquality']} exact path='/wms/dispatch/matreq'>
							<MRProvider>
								<MatReqPage />
							</MRProvider>
						</PrivateRoute>
						<PrivateRoute
							roles={['administrators', 'wmsadmin', 'wmsquality']}
							exact
							path='/wms/entrance/inspection/recinsp'>
							<RecInspProvider>
								<RecInspPage />
							</RecInspProvider>
						</PrivateRoute>
						<PrivateRoute
							roles={['administrators', 'wmsadmin', 'wmslogistic']}
							exact
							path='/wms/entrance/rigreturn'>
							<RigReturnProvider>
								<RigReturnPage />
							</RigReturnProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin', 'wmslogistic']} exact path='/wms/dispatch/manage'>
							<DispatchProvider>
								<DispatchPage />
							</DispatchProvider>
						</PrivateRoute>
						<PrivateRoute
							roles={['administrators', 'wmsadmin', 'wmsquality']}
							exact
							path='/wms/dispatch/modifproducts'>
							<ModifiedProductProvider>
								<ModifiedProductPage />
							</ModifiedProductProvider>
						</PrivateRoute>
						<PrivateRoute
							roles={['administrators', 'wmsadmin', 'wmsquality']}
							exact
							path='/wms/manage/inspection/perinsp'>
							<ReportsProvider>
								<ReportDataProvider>
									<PerInspectionProvider>
										<PerInspPage />
									</PerInspectionProvider>
								</ReportDataProvider>
							</ReportsProvider>
						</PrivateRoute>
						<PrivateRoute
							roles={['administrators', 'wmsadmin', 'wmsquality']}
							exact
							path='/wms/manage/inventory/check'>
							<InventoryProvider>
								<InventoryPage />
							</InventoryProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/settings'>
							<AssetsSettingsProvider>
								<AssetsSettingsPage />
							</AssetsSettingsProvider>
						</PrivateRoute>
						<PrivateRoute
							roles={['administrators', 'wmsadmin', 'wmsquality', 'wmslogistic']}
							exact
							path='/wms/assets/printers'>
							<AssetsPrinterProvider>
								<AssetsPrinterPage />
							</AssetsPrinterProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/usagerights'>
							<AssetsUsageRightsProvider>
								<AssetsUsageRightsPage />
							</AssetsUsageRightsProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/warehouse'>
							<AssetsWarehouseProvider>
								<AssetsWarehousePage />
							</AssetsWarehouseProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/transport'>
							<AssetsTransportProvider>
								<AssetsTransportPage />
							</AssetsTransportProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/agemanagement'>
							<AssetsAgeMgmtProvider>
								<AssetsAgeMgmtPage />
							</AssetsAgeMgmtProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/reportsmanagement'>
							<AssetsReportRefProvider>
								<AssetsReportRefPage />
							</AssetsReportRefProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/levels'>
							<AssetsLevelsProvider>
								<AssetsLevelsPage />
							</AssetsLevelsProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/threadcompound'>
							<ThreadCompoundProvider>
								<AssetsThreadCompoundPage />
							</ThreadCompoundProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/inspsettings'>
							<AssetsInspSettingsProvider>
								<AssetsInspSettingsPage />
							</AssetsInspSettingsProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/attributes'>
							<AssetsAttributesProvider>
								<AssetsAttributesPage />
							</AssetsAttributesProvider>
						</PrivateRoute>
						<PrivateRoute roles={['administrators', 'wmsadmin']} exact path='/wms/assets/material'>
							<AssetsMaterialProvider>
								<AssetsMaterialPage />
							</AssetsMaterialProvider>
						</PrivateRoute>
						<PrivateRoute roles={['wmsoper']} exact path='/wms/assets/equipments'>
							<AssetsEquipmentsProvider>
								<AssetsEquipmentPage />
							</AssetsEquipmentsProvider>
						</PrivateRoute>
						<ReportsProvider>
							<ReportDataProvider>
								<PrivateRoute
									roles={['administrators', 'wmsadmin', 'wmsquality', 'wmslogistic']}
									exact
									path='/wms/manage/reports/edit/inspection'>
									<InspectionReportsPage />
								</PrivateRoute>
								<PrivateRoute
									roles={['administrators', 'wmsadmin', 'wmsquality', 'wmslogistic']}
									exact
									path='/wms/manage/reports/review/:report'>
									<ReviewReportsPage />
								</PrivateRoute>
								<PrivateRoute
									roles={['administrators', 'wmsadmin', 'wmsquality', 'wmslogistic']}
									exact
									path='/wms/manage/reports/export/:report'>
									<ExportReportsPage />
								</PrivateRoute>
							</ReportDataProvider>
						</ReportsProvider>
					</Switch>
				</DndProvider>
				<PrivateRoute roles={['administrators']} exact path='/wms/cloud-logs'>
					<CloudLogsProvider>
						<CloudLogsPage />
					</CloudLogsProvider>
				</PrivateRoute>
			</WMSProvider>
			<PrivateRoute roles={['administrators']} exact path='/tag-server/'>
				<TagServerPage />
			</PrivateRoute>
		</>
	)
}

export default Router
