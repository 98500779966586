/** @format */

import { Button, Card } from 'components/common'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'components/form'
import { TagPrintingContext } from '../../stores/TagPrintingStore'
import styled from 'styled-components'
import { DatePicker } from 'components/form'

const DateWrapper = styled.div`
	min-width: 200px;
	width: 15%;
	margin-right: 16px;
	> div {
		width: 100%;
		> div[label] {
			width: calc(100% - 28px);
		}
	}
`

const TagPrintingSubHeader = () => {
	const { endUsers, getPreparedEntrances, setPreparedEntrances, materialList } = useContext(TagPrintingContext)
	const { t } = useTranslation()

	const [endUser, setEndUser] = useState(null)
	const [fromDate, setFromDate] = useState()
	const [toDate, setToDate] = useState()
	const [material, setMaterial] = useState(null)

	const isButtonDisabled = () => endUser && fromDate && toDate

	const onDownloadEntrances = () => {
		getPreparedEntrances(endUser, fromDate, toDate)
			.then(response => {
				if (material) {
					const mat = materialList.find(m => m.mvid === material)
					const list = response.filter(p => p.erprefs.find(erp => mat.erpreference === erp))
					setPreparedEntrances(list)
				} else {
					setPreparedEntrances(response)
				}
			})
			.catch(e => console.error(e))
	}

	return (
		<Card padding='0px' margin='0 0 8px 0 ' width='100%' flexDirection='row'>
			<Select
				margin='10px 10px 10px 20px'
				width='20%'
				placeholder={endUser ? endUser.name : t('wms:SelectOption')}
				label={`${t(`wms:EndUser`)}:`}
				value={endUser && endUser.id}
				options={endUsers.map(eu => ({ value: eu.id, label: eu.name }))}
				onChange={value => setEndUser(endUsers.find(eu => eu.id === value))}
				fontSize='small'
				searchable
			/>
			<DateWrapper>
				<DatePicker
					margin='10px 10px 10px 10px'
					label={`${t(`wms:StartDate`)}`}
					placeholder={`${t(`wms:SelectDate`)}`}
					timePicker={false}
					value={fromDate}
					data-cy='date-picker'
					onChange={e => {
						setFromDate(e)
					}}
					fontSize='small'
				/>
			</DateWrapper>
			<DateWrapper>
				<DatePicker
					margin='10px 10px 10px 10px'
					label={`${t(`wms:EndDate`)}`}
					placeholder={`${t(`wms:EndDate`)}`}
					timePicker={false}
					value={toDate}
					onChange={e => {
						setToDate(e)
					}}
					fontSize='small'
				/>
			</DateWrapper>
			<Select
				margin='10px 10px 10px 10px'
				width='20%'
				label={t('wms:Material')}
				placeholder={t('wms:SelectOption')}
				value={material}
				options={
					materialList &&
					materialList.map(m => {
						return { value: m.mvid, label: m.formatted }
					})
				}
				onChange={value => setMaterial(value)}
				searchable
				data-cy='select-material'
			/>
			<Button
				fontSize='large'
				backgroundColor='secondary'
				margin='40px 0 8px 10px'
				onClick={e => {
					onDownloadEntrances()
				}}
				color='white'
				disabled={!isButtonDisabled()}>
				{`${t(`wms:DownloadList`)}`}
			</Button>
		</Card>
	)
}

export default TagPrintingSubHeader
