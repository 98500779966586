/** @format */

import { ModifiedProductContext } from 'apps/wms/stores/ModifProductStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { FlexView, Card, LoadingOverlay, Icon } from 'components/common'
import Button from 'apps/wms/components/commom/Button'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { STATUS } from '../../utils/enum'
import AddModifProdListModal from './Add'
import UpdateModifProdListModal from './Update'

const ModifProductsList = () => {
	const { t, i18n } = useTranslation()
	const {
		modifProdList,
		loadingMPList,
		selectedList,
		setSelectedList,
		openUpdateModal,
		setOpenUpdateModal,
		setUpdateModifProduct,
		setExpectedQuantity,
		deleteMPList,
		openConfirmModal,
		setOpenConfirmModal,
		openAddModal,
		setOpenAddModal
	} = useContext(ModifiedProductContext)

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedList && selectedList.id && handleSelect(selectedList.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modifProdList])

	const handleSelect = id => {
		let selected = id && _.find(modifProdList, modif => modif.id === id)
		selected && setSelectedList(selected)
	}

	const closeAddModal = () => {
		setOpenAddModal(false)
	}

	const onOutsideClick = () => {
		setOpenUpdateModal(false)
	}
	const handleUpdate = id => {
		if (id !== null) {
			handleSelect(id)
			setOpenUpdateModal(true)
			setUpdateModifProduct(true)
			setExpectedQuantity(0)
		}
	}

	const handleDelete = id => {
		if (id !== null) {
			handleSelect(id)
			setOpenConfirmModal(true)
		}
	}

	const checkContentStatus = useCallback(modif => {
		let isDisabled = false
		isDisabled = !!_.find(modif.content, (c, i) => c.status !== STATUS.code.NOTSTARTED)
		return isDisabled
	}, [])

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-modifprod-list'>
			<LoadingOverlay visible={loadingMPList} />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:ModifiedProductList')}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{modifProdList && modifProdList.length > 0 ? (
					_.map(modifProdList, (modif, index) => (
						<FlexView
							key={modif.id}
							backgroundColor={selectedList && selectedList.id === modif.id ? 'offWhite' : 'default'}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelect(modif.id)}
							data-cy='list-item'>
							<FlexView flex='1' fontSize='small' margin='auto 0'>
								{`${modif.id} (${DATE_FORMAT(modif.expecteddate, i18n.language)}) ${modif.name}`}
							</FlexView>
							<Button
								hoverBackgroundColor='secondary'
								hoverColor='white'
								backgroundColor='white'
								color='secondary'
								fontSize='tiny'
								margin='auto 8px auto 0'
								onClick={() => handleUpdate(modif.id)}>
								<Icon
									name='refresh'
									height='12px'
									width='12px'
									color='secondary'
									margin='0px 8px 0px 0'
									fontWeight='bold'
								/>
								{t('wms:Update')}
							</Button>
							<Button
								backgroundColor='error'
								color='white'
								onClick={() => handleDelete(modif.id)}
								disabled={checkContentStatus(modif)}
								fontSize='tiny'
								margin='auto 0'>
								<Icon
									name='trash'
									height='12px'
									width='12px'
									color='white'
									margin='0px 8px 0px 0'
									fontWeight='bold'
								/>
								{t('wms:Delete')}
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='no-modifproduct-list'>
						{t('wms:NoModifProdList')}
					</FlexView>
				)}
			</FlexView>
			<Button
				backgroundColor='secondary'
				color='white'
				margin='auto 0 0 auto'
				style={{ alignSelf: 'flex-end' }}
				fontSize='small'
				onClick={() => setOpenAddModal(true)}
				data-cy='button-add-modifproduct-list'>
				{t('wms:Add')}
				<Icon name='add' width='16px' height='16px' color='white' margin='0px 0px 0px 8px' fontWeight='bold' />
			</Button>
			<DialogModal
				isOpen={openConfirmModal}
				text={t('wms:ConfirmModifiedProductListDeletion')}
				onConfirm={deleteMPList}
				onCancel={() => setOpenConfirmModal(false)}
			/>
			<UpdateModifProdListModal
				isOpen={openUpdateModal}
				setOpenUpdateModal={setOpenUpdateModal}
				onOutsideClick={onOutsideClick}
				list={selectedList}
			/>
			<AddModifProdListModal isOpen={openAddModal} onOutsideClick={closeAddModal} />
		</Card>
	)
}

export default ModifProductsList
