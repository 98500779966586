/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'

import { DispatchContext } from '../../stores/DispatchStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'
import theme from 'utils/theme'
import _ from 'lodash'

const DispatchInfo = () => {
	const {
		selectedDispatch,
		materialList,
		lastLevelsList,
		loadingDispatchInfo,
		vehiclesList,
		setDispatchModal,
		endUserList
	} = useContext(DispatchContext)
	const { t, i18n } = useTranslation()

	const listDeadSpace = 260
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const matFormatted = id => {
		return id !== null &&
			id !== undefined &&
			materialList &&
			materialList.length &&
			materialList.find(mat => mat.mvid === id)
			? materialList.find(mat => mat.mvid === id).formatted
			: ''
	}

	const levelFullName = id => {
		return id !== null && lastLevelsList.length ? lastLevelsList.find(level => level.id === id).fullname : ''
	}

	const hasVehicleData = () => {
		if (!!selectedDispatch) {
			return (
				selectedDispatch.vehicledata !== null &&
				selectedDispatch.vehicledata !== undefined &&
				Object.keys(selectedDispatch.vehicledata).length !== 0
			)
		}
	}

	const getPropertyDesc = key => {
		if (key === 'Name') return 'Name'
		else if (key === 'Weight') return 'Weight'
		else if (vehiclesList && vehiclesList.length) {
			var propName = undefined
			vehiclesList.forEach(vehicle => {
				if (vehicle.properties && vehicle.properties.length) {
					vehicle.properties.forEach(prop => {
						if (prop.name === key) propName = prop.description
					})
				}
			})
			return propName
		} else return undefined
	}

	const handleDispatch = () => {
		setDispatchModal(true)
	}

	const formatEndUser = id => {
		let name = id && _.find(endUserList, eu => eu.id === id).name

		return name ? name : '*****'
	}

	return (
		<Card width='calc(100% - 48px)' flex='1' margin='0' ref={listRef} style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingDispatchInfo} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:DispatchInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				{selectedDispatch ? (
					<FlexView flexDirection='row' width='100%'>
						<FlexView width='40%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:DispatchExpectedDate')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{DATE_FORMAT(selectedDispatch.expecteddate, i18n.language)}
							</FlexView>
						</FlexView>
						<FlexView width='30%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:DispatchDestination')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedDispatch.destination}
							</FlexView>
						</FlexView>
						<FlexView width='30%' margin='0 0 16px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:DispatchOccupationFactor')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedDispatch.occupationfactor}
							</FlexView>
						</FlexView>
					</FlexView>
				) : null}
				{hasVehicleData() ? (
					<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' margin='0'>
							{t('wms:TransportData')}
						</FlexView>
					</FlexView>
				) : null}
				{hasVehicleData()
					? Object.keys(selectedDispatch.vehicledata).map(key => (
							<FlexView key={key} width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{getPropertyDesc(key)}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedDispatch.vehicledata[key]}
								</FlexView>
							</FlexView>
					  ))
					: null}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{selectedDispatch &&
					selectedDispatch.contents &&
					selectedDispatch.contents.map((content, index) => (
						<FlexView
							key={`${content.did}-${index}`}
							width='100%'
							margin='0'
							flexDirection='column'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
							<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:EndUser')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{formatEndUser(content.enduserid)}
								</FlexView>
							</FlexView>
							<FlexView flex='1' width='90%' fontSize='small' margin='auto 0'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{matFormatted(content.mid)} <br />
									<strong>
										{`${levelFullName(content.levelid)} | ${t('wms:Quantity')}: ${content.quantity}`}
									</strong>
								</FlexView>
							</FlexView>
						</FlexView>
					))}
			</FlexView>
			<Button
				fontSize='small'
				margin='auto 0 0 auto'
				color='white'
				backgroundColor='secondary'
				disabled={false}
				onClick={() => {
					handleDispatch()
				}}>
				<Icon name='play' color='white' height='12px' width='12px' margin='0 8px 0 0' />
				{`${t('wms:ExecuteDispatch')}`}
			</Button>
		</Card>
	)
}

export default DispatchInfo
