/** @format */

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView } from 'components/common'

import { RecInspContext } from '../stores/RecInspStore'
import PageTemplate from '../containers/common/PageTemplate'
import RecInspInfo from '../containers/recInsp/Info'
import RecInspModal from '../containers/recInsp/Modal'
import RecInspList from '../containers/recInsp/List'
import { entrance, genParams } from '../utils/hubs'

const RecInspPage = () => {
	const { t } = useTranslation()
	const { selectedRI, setUploadRIList, isUpdate, riModal, setRIModal } = useContext(RecInspContext)

	const closeOptionsModal = () => {
		setRIModal(false)
		setUploadRIList(true)
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Entrance'),
				path: `/wms/entrance${genParams(entrance)}`
			},
			{
				name: t('wms:ReceivingInspection'),
				path: '/wms/entrance/inspection/recinsp'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:ReceivingInspection')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='55%' margin='0 8px 0 0'>
						<RecInspList data-cy='recinsp-list' />
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{selectedRI && <RecInspInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
			<RecInspModal isOpen={riModal} onOutsideClick={closeOptionsModal} isUpdate={isUpdate} mrInfo={selectedRI} />
		</PageTemplate>
	)
}

export default RecInspPage
