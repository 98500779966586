/** @format */

import { ActualDataContext } from 'apps/wms/stores/ActualDataModifStore'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Card, FlexView, LoadingOverlay } from 'components/common'
import { Checkbox, Input } from 'components/form'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'

const PipeDataModif = () => {
	const { t } = useTranslation()
	const { lengthUnit, weightUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit } = useContext(WMSContext)
	const { pipeData, loadingData, loadingPipes, availablePipes, selectedPipes, setSelectedPipes } =
		useContext(ActualDataContext)
	const [valid, setValid] = useState('')
	const [items, setItems] = useState(null)
	const [isCheckAll, setIsCheckAll] = useState(false)

	const listDeadSpace = 160
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		availablePipes && setItems(availablePipes)
	}, [availablePipes])

	const selectItems = (value, selected) => {
		return selected.indexOf(value) > -1
	}

	const handleSelect = (value, selected) => {
		let i = selected.indexOf(value)
		i > -1 ? selected.splice(i, 1) : selected.push(value)
		setSelectedPipes([...selectedPipes])
	}

	const selectAllItems = v => {
		setIsCheckAll(v)
		if (v) {
			setSelectedPipes([...availablePipes])
		} else {
			setSelectedPipes([])
		}
	}

	return (
		<FlexView
			flexDirection='column'
			width='38%'
			height='100%'
			margin='0 0 0 8px'
			ref={listRef}
			style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingData ? loadingData : loadingPipes} borderRadius='card' />
			<Card width='calc(100% - 48px)' height='94%' margin='8px 0 0 8px' padding='16px' data-cy='card-pipes'>
				{pipeData ? (
					<FlexView flexDirection='column' width='100%'>
						<FlexView width='100%' fontSize='big' fontWeight='bold' margin='0 0 16px 0'>
							{t('wms:PipeData')}
						</FlexView>
						<FlexView flexDirection='row' flexWrap='wrap' width='100%' justifyContent='space-between'>
							<FlexView flexDirection='column' width='50%'>
								<FlexView
									fontSize='tiny'
									color='metalic'
									fontWeight='bold'
									margin='16px 16px 0 0'
									data-cy='list-label'>
									Valid
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.pipevalid ? pipeData.pipevalid : '****'}
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='50%'>
								<FlexView
									fontSize='tiny'
									color='metalic'
									fontWeight='bold'
									margin='16px 16px 0 0'
									data-cy='list-label'>
									IPPN
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.pipepuuid ? pipeData.pipepuuid : '****'}
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='100%'>
								<FlexView
									fontSize='tiny'
									color='metalic'
									fontWeight='bold'
									margin='16px 16px 0 0'
									data-cy='list-label'>
									{t('wms:ErpReference')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.catid ? pipeData.catid : '****'}
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='50%'>
								<FlexView
									fontSize='tiny'
									color='metalic'
									fontWeight='bold'
									margin='16px 16px 0 0'
									data-cy='list-label'>
									{t('wms:CustomerOrder')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.customerponumber ? pipeData.customerponumber : '****'}
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='50%'>
								<FlexView
									fontSize='tiny'
									color='metalic'
									fontWeight='bold'
									margin='16px 16px 0 0'
									data-cy='list-label'>
									{t('wms:CustomerItem')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.customeritem ? pipeData.customeritem : '****'}
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='50%'>
								<FlexView
									fontSize='tiny'
									color='metalic'
									fontWeight='bold'
									margin='16px 16px 0 0'
									data-cy='list-label'>
									{t('wms:SalesOrder')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.vtsaordernumber ? pipeData.vtsaordernumber : '****'}
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='50%'>
								<FlexView
									fontSize='tiny'
									color='metalic'
									fontWeight='bold'
									margin='16px 16px 0 0'
									data-cy='list-label'>
									{t('wms:SalesItem')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.vtsaitemnumber ? pipeData.vtsaitemnumber : '****'}
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='50%'>
								<FlexView
									fontSize='tiny'
									color='metalic'
									fontWeight='bold'
									margin='16px 16px 0 0'
									data-cy='list-label'>
									{`${t('wms:Length')} [${lengthUnit}]`}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{convertToCurrentLengthUnit(pipeData.actuallength, lengthUnit)}
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='50%'>
								<FlexView
									fontSize='tiny'
									color='metalic'
									fontWeight='bold'
									margin='16px 16px 0 0'
									data-cy='list-label'>
									{`${t('wms:Weight')} [${weightUnit}]`}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{convertToCurrentWeightUnit(pipeData.actualweight, weightUnit)}
								</FlexView>
							</FlexView>
						</FlexView>
					</FlexView>
				) : availablePipes ? (
					<FlexView flexDirection='column' width='100%'>
						<FlexView width='100%' fontSize='big' fontWeight='bold' margin='0'>
							{t('wms:AvailablePipes')}
						</FlexView>
						<FlexView
							flexDirection='column'
							width='100%'
							height={`${listHeight}px`}
							style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
							<Input
								type='text'
								margin='8px 0 8px 0'
								placeholder={t('wms:TypeToFilter')}
								value={valid}
								onChange={e => {
									const filtered = availablePipes.filter(item => {
										return item.valid.toUpperCase().includes(e.target.value.toUpperCase())
									})
									setItems(filtered)
									setValid(e.target.value)
								}}
								data-cy='input-filter-pipe'
							/>
							<Checkbox
								margin='8px 0 16px 0'
								id='selectAll'
								label={t('wms:SelectAll')}
								onChange={v => selectAllItems(v)}
								checked={isCheckAll}
								data-cy='checkbox-select-all'
							/>
							{items &&
								_.map(items, (item, index) => (
									<Checkbox
										key={index}
										checked={selectItems(item, selectedPipes)}
										onChange={() => handleSelect(item, selectedPipes)}
										label={item.valid}
										data-cy='checkbox-available-pipes'
									/>
								))}
						</FlexView>
					</FlexView>
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='no-available-pipes'>
						{t('wms:NoAvailablePipes')}
					</FlexView>
				)}
			</Card>
		</FlexView>
	)
}

export default PipeDataModif
