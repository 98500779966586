/** @format */

import React, { useState, useEffect, useContext, useRef } from 'react'
import _, { toInteger } from 'lodash'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, Modal, Icon, LoadingOverlay } from 'components/common'
import { Select, Input, DatePicker } from 'components/form'

import { MRType, STATUS } from '../../utils/enum'

import { MRContext } from '../../stores/MRStore'
import moment from 'moment'
import theme from 'utils/theme'

const DateWrapper = styled.div`
	width: 30%;
	> div {
		width: 100%;
	}
`

const MrModal = ({ isOpen, onOutsideClick, isUpdate, mrInfo }) => {
	const { lastLevelsList, materialList, getContent, uploadMRFile, newMR, updateMR, endusers, getOwnershipByEnduser } =
		useContext(MRContext)
	const { t } = useTranslation()
	const [mrType, setMRType] = useState(null)
	const [sourceEnduser, setSourceEnduser] = useState(null)
	const [sourceOwnership, setSourceOwnership] = useState(null)
	const [sourceMaterial, setSourceMaterial] = useState(null)
	const [sourceRack, setSourceRack] = useState(null)
	const [sourceQty, setSourceQty] = useState(0)

	const [ownershipSourceList, setOwnershipSourceList] = useState([])
	const [content, setContent] = useState([])
	const [sourceMaterialList, setSourceMaterialList] = useState([])
	const [rackList, setRacksList] = useState([])
	const [qntAvailable, setQntAvailable] = useState(0)
	const [loadingOwnerships, setLoadingOwnerships] = useState(false)
	const [loadingMaterials, setLoadingMaterials] = useState(false)
	const [loadingRacks, setLoadingRacks] = useState(false)

	const fileUploader = useRef(null)
	const [mrFile, setMrFile] = useState(null)

	const [fileUpload, setFileUpload] = useState(false)
	const [mrUpload, setMrUpload] = useState(false)

	const baseMR = {
		mrref: '',
		field: '',
		well: '',
		rig: '',
		tpartyname: '',
		tpartyloc: '',
		expecteddate: '',
		ltpa: '',
		file: '',
		content: []
	}
	const [mrData, setMRData] = useState(baseMR)

	useEffect(() => {
		isOpen && setMRType(null) && setSourceOwnership(null)
		if (isOpen && isUpdate) {
			setMRType(mrInfo.type)
			setMRData({
				mrref: mrInfo.mrref,
				field: mrInfo.field,
				well: mrInfo.well,
				rig: mrInfo.rig,
				tpartyloc: mrInfo.tpartyloc,
				tpartyname: mrInfo.tpartyname,
				expecteddate: moment(mrInfo.expecteddate),
				ltpa: mrInfo.ltpa,
				content: mrInfo.content,
				deletedcontent: []
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	useEffect(() => {
		setSourceEnduser(null)
		setContent([])
		!isUpdate && setMRData(baseMR)
		setMrFile(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mrType])

	useEffect(() => {
		if (content.length) {
			let aux = content.map(item => item.material)
			aux = _.uniqBy(aux, 'id')
			aux = aux.map(m => ({ ...matData(m.id), ...m })).filter(m => m.enduserid === sourceEnduser)
			setSourceMaterialList(aux.map(m => ({ ...matData(m.id), ...m })))
		} else setSourceMaterialList([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content])

	useEffect(() => {
		setSourceOwnership(null)
		if (sourceEnduser) {
			setLoadingOwnerships(true)
			getOwnershipByEnduser(sourceEnduser)
				.then(ownershipList => {
					setOwnershipSourceList(ownershipList.length ? ownershipList : [])
					setLoadingOwnerships(false)
				})
				.catch(e => console.error(e))
				.finally(() => setLoadingOwnerships(false))
		}
	}, [sourceEnduser, getOwnershipByEnduser])

	useEffect(() => {
		setSourceMaterial(null)
		if (sourceOwnership) {
			setLoadingMaterials(true)
			getContent(sourceOwnership, mrType)
				.then(contentList => {
					contentList.length ? setContent(contentList) : setContent([])
				})
				.catch(e => console.error(e))
				.finally(() => setLoadingMaterials(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceOwnership])

	useEffect(() => {
		setSourceRack(null)
		if (content.length && sourceMaterial) {
			setLoadingRacks(true)
			let aux = content
				.filter(item => item.material.id === sourceMaterial)
				.map(item => ({
					id: item.level.id,
					fullname: item.level.fullname,
					creationavg: item.creationavg,
					itemcount: item.itemcount
				}))
			mrType === MRType.code.PHYSTRANS
				? aux.sort((a, b) => {
						var dateA = new Date(a.creationavg)
						var dateB = new Date(b.creationavg)
						return dateA - dateB
				  })
				: aux.sort((a, b) => a.fullname - b.fullname)
			setRacksList(aux)
			setLoadingRacks(false)
		} else setRacksList([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceMaterial, content])

	useEffect(() => {
		setSourceQty(0)
		setQntAvailable(0)
		if (sourceRack && rackList.length) {
			let selectedRack = rackList.find(rack => rack.id === sourceRack)
			let decrease = 0
			mrData.content.forEach(
				content =>
					content.ownershipid === sourceOwnership &&
					content.mid === sourceMaterial &&
					content.levelid === selectedRack.id &&
					(decrease += content.quantity)
			)
			decrease
				? selectedRack && setQntAvailable(selectedRack.itemcount - decrease)
				: selectedRack && setQntAvailable(selectedRack.itemcount)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceRack])

	const matData = id =>
		id !== null && materialList.length && materialList.find(mat => mat.mvid === id)
			? materialList.find(mat => mat.mvid === id)
			: {}

	const matFormatted = id => {
		return id !== null && materialList.length && materialList.find(mat => mat.mvid === id)
			? materialList.find(mat => mat.mvid === id).formatted
			: ''
	}

	const levelFullName = id => {
		return id !== null && lastLevelsList.length && lastLevelsList.find(level => level.id === id)
			? lastLevelsList.find(level => level.id === id).fullname
			: ''
	}

	const addContent = () => {
		if (sourceEnduser && sourceOwnership && sourceMaterial && sourceRack && sourceQty !== 0) {
			setMRData({
				...mrData,
				content: [
					...mrData.content,
					{
						ownershipid: sourceOwnership,
						enduserid: sourceEnduser,
						mid: sourceMaterial,
						formatted: matFormatted(sourceMaterial),
						levelid: sourceRack,
						quantity: toInteger(sourceQty),
						status: 0
					}
				]
			})
			setQntAvailable(qntAvailable - sourceQty)
			setSourceQty(0)
		}
	}

	const removeContent = index => {
		if (index !== null && index < mrData.content.length) {
			sourceMaterial === mrData.content[index].mid &&
				sourceRack === mrData.content[index].levelid &&
				setQntAvailable(qntAvailable + mrData.content[index].quantity)
			let remContent = mrData.content.splice(index, 1)
			isUpdate && mrData.deletedcontent.push(...remContent)
			setMRData({ ...mrData, content: [...mrData.content] })
		}
	}

	const validateData = () => {
		let isValid = true
		if (mrType === MRType.code.RIGPREP) isValid = isValid && mrData.field && mrData.rig && mrData.well
		else if (mrType === MRType.code.MATMODIF || mrType === MRType.code.TPWORKSHOP)
			isValid = isValid && mrData.tpartyname && mrData.tpartyloc && mrData.field && mrData.rig && mrData.well
		else if (mrType === MRType.code.PHYSTRANS)
			isValid = isValid && mrData.tpartyloc && mrData.field && mrData.rig && mrData.well
		isValid =
			isValid &&
			mrData.ltpa &&
			mrData.expecteddate &&
			mrData.mrref &&
			!!!mrData.mrref.match(/[/?&$]/g) &&
			mrData.content.length
		if (!isUpdate) isValid = isValid && mrFile
		return isValid
	}

	const saveMr = async () => {
		try {
			setFileUpload(true)
			await uploadMRFile(mrFile, mrType)
				.then(fileName => {
					mrData.file = fileName
					setMRData({ ...mrData, file: fileName })
				})
				.finally(() => setFileUpload(false))
			setMrUpload(true)
			await newMR(mrData, mrType)
				.then(response => response && setMRType(null))
				.finally(() => setMrUpload(false))
		} catch (e) {
			console.error(e)
		}
	}

	const updateMRData = async () => {
		try {
			mrData.content = [
				...mrData.content.filter(content => content[MRType.cid[mrType]] === undefined),
				...mrData.deletedcontent
			]
			if (mrFile) {
				setFileUpload(true)
				await uploadMRFile(mrFile, mrType)
					.then(fileName => {
						mrData.file = fileName
						setMRData({ ...mrData, file: fileName })
					})
					.finally(() => setFileUpload(false))
			}
			setMrUpload(true)
			await updateMR(mrData, mrInfo.id, mrType)
				.then(response => response && setMRType(null))
				.finally(() => setMrUpload(false))
			onOutsideClick()
		} catch (e) {
			console.error(e)
		}
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='80%' height='85%' position='relative'>
				<LoadingOverlay visible={fileUpload || mrUpload} borderRadius='16px' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{isUpdate ? t('wms:UpdateMR') : t('wms:NewMR')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView maxWidth='35%' minWidth='35%' minHeight='100%' maxHeight='100%' margin='0 16px 0 0'>
						{!isUpdate && (
							<Card margin='0 0 16px 0' width='calc(100% - 48px)'>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
									{t('wms:MaterialRequisitionType')}
								</FlexView>
								<Select
									placeholder={t('wms:MaterialRequisitionType')}
									value={mrType}
									options={MRType.literal.map((type, index) => ({
										value: index,
										label: t(`wms:${type}`)
									}))}
									onChange={value => {
										setMRType(value)
									}}
									width='100%'
									inline={true}
									margin='0'
								/>
							</Card>
						)}
						<Card
							margin='0 0 4px 0'
							maxHeight={`calc(85vh - ${!isUpdate ? 260 : 90}px)`}
							style={{ overflowY: 'scroll' }}
							width='calc(100% - 48px)'
							flex='1'>
							{endusers && endusers.length > 0 && (
								<Select
									width='100%'
									label={t('wms:EndUser')}
									placeholder={t('wms:EndUser')}
									value={sourceEnduser}
									options={_.map(endusers, eu => ({
										value: eu.id,
										label: eu.name
									}))}
									onChange={v => setSourceEnduser(v)}
									searchable
								/>
							)}

							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='8px 0'>
								{t('wms:Ownership')}
							</FlexView>
							<FlexView width='100%' margin='0' padding='0' position='relative'>
								<LoadingOverlay visible={loadingOwnerships} />
								<Select
									placeholder={t('wms:Ownership')}
									value={sourceOwnership}
									options={_.map(ownershipSourceList, lv => ({
										value: lv.id,
										label: lv.label
									}))}
									onChange={v => setSourceOwnership(v)}
									width='100%'
									margin='0'
									searchable
								/>
							</FlexView>

							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='8px 0'>
								{t('wms:Material')}
							</FlexView>
							<FlexView width='100%' margin='0' padding='0' position='relative'>
								<LoadingOverlay visible={loadingMaterials} />
								<Select
									searchable
									placeholder={t('wms:SelectMaterial')}
									value={sourceMaterial}
									options={sourceMaterialList.map(material => ({
										label: material.formatted,
										value: material.id
									}))}
									onChange={value => {
										setSourceMaterial(value)
									}}
									width='100%'
									margin='0'
								/>
							</FlexView>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='8px 0'>
								{t('wms:SourceRack')}
							</FlexView>
							<FlexView width='100%' margin='0' padding='0' position='relative'>
								<LoadingOverlay visible={loadingRacks} />
								<Select
									searchable
									placeholder={t('wms:SelectRack')}
									value={sourceRack}
									options={rackList.map(rack => ({
										label: rack.fullname,
										value: rack.id
									}))}
									onChange={value => {
										setSourceRack(value)
									}}
									width='100%'
									margin='0'
								/>
							</FlexView>
							<Input
								label={`${t('wms:Quantity')} ${
									!!sourceRack ? `[${t('wms:AvailablePipes')}: ${qntAvailable}]` : ''
								}`}
								placeholder={`${qntAvailable}`}
								margin='8px 0 0 0'
								min='1'
								max={qntAvailable}
								type='number'
								value={sourceQty}
								onChange={e => {
									setSourceQty(toInteger(e.target.value))
								}}
								success={sourceQty <= qntAvailable}
								error={sourceQty > qntAvailable}
								fontSize='small'
								width='100%'
							/>
							<Button
								fontSize='medium'
								margin='auto auto 0 auto'
								color='white'
								backgroundColor='secondary'
								disabled={!(!!sourceOwnership && !!sourceMaterial && !!sourceRack && sourceQty !== 0)}
								onClick={() => {
									addContent()
								}}>
								{t('wms:AddPart')}
							</Button>
						</Card>
					</FlexView>
					<FlexView width='calc(65% - 16px)' minHeight='100%' maxHeight='100%'>
						<Card margin='0 0 4px 0' width='calc(100% - 48px)' minHeight='inherit' flex='1'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:MaterialRequisitionInfo')}
							</FlexView>
							{mrType !== null ? (
								<FlexView flexDirection='row' maxWidth='100%' flexWrap='wrap'>
									<Input
										label={`${t('wms:ReferenceMR')}`}
										placeholder={`${t('wms:ReferenceMR')}`}
										margin='8px 0 0 10px'
										value={mrData.mrref}
										onChange={e => {
											setMRData({ ...mrData, mrref: e.target.value })
										}}
										fontSize='small'
										minwidth='30%'
										error={!!mrData.mrref.match(/[/?&$]/g)}
									/>

									{mrType === MRType.code.RIGPREP && (
										<>
											<Input
												label={`${t('wms:FieldName')}`}
												placeholder={`${t('wms:FieldName')}`}
												margin='8px 0 0 10px'
												value={mrData.field}
												onChange={e => {
													setMRData({ ...mrData, field: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
											<Input
												label={`${t('wms:WellName')}`}
												placeholder={`${t('wms:WellName')}`}
												margin='8px 0 0 10px'
												value={mrData.well}
												onChange={e => {
													setMRData({ ...mrData, well: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
											<Input
												label={`${t('wms:RigName')}`}
												placeholder={`${t('wms:RigName')}`}
												margin='8px 0 0 10px'
												value={mrData.rig}
												onChange={e => {
													setMRData({ ...mrData, rig: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
										</>
									)}

									{(mrType === MRType.code.MATMODIF || mrType === MRType.code.TPWORKSHOP) && (
										<>
											<Input
												label={`${t('wms:ThirdPartyName')}`}
												placeholder={`${t('wms:ThirdPartyName')}`}
												margin='8px 0 0 10px'
												value={mrData.tpartyname}
												onChange={e => {
													setMRData({ ...mrData, tpartyname: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
											<Input
												label={`${t('wms:ThirdPartyLocation')}`}
												placeholder={`${t('wms:ThirdPartyLocation')}`}
												margin='8px 0 0 10px'
												value={mrData.tpartyloc}
												onChange={e => {
													setMRData({ ...mrData, tpartyloc: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
											<Input
												label={`${t('wms:FieldName')}`}
												placeholder={`${t('wms:FieldName')}`}
												margin='8px 0 0 10px'
												value={mrData.field}
												onChange={e => {
													setMRData({ ...mrData, field: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
											<Input
												label={`${t('wms:WellName')}`}
												placeholder={`${t('wms:WellName')}`}
												margin='8px 0 0 10px'
												value={mrData.well}
												onChange={e => {
													setMRData({ ...mrData, well: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
											<Input
												label={`${t('wms:RigName')}`}
												placeholder={`${t('wms:RigName')}`}
												margin='8px 0 0 10px'
												value={mrData.rig}
												onChange={e => {
													setMRData({ ...mrData, rig: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
										</>
									)}

									{mrType === MRType.code.PHYSTRANS && (
										<>
											<Input
												label={`${t('wms:DeliveryLocation')}`}
												placeholder={`${t('wms:DeliveryLocation')}`}
												margin='8px 0 0 10px'
												value={mrData.tpartyloc}
												onChange={e => {
													setMRData({ ...mrData, tpartyloc: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
											<Input
												label={`${t('wms:FieldName')}`}
												placeholder={`${t('wms:FieldName')}`}
												margin='8px 0 0 10px'
												value={mrData.field}
												onChange={e => {
													setMRData({ ...mrData, field: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
											<Input
												label={`${t('wms:WellName')}`}
												placeholder={`${t('wms:WellName')}`}
												margin='8px 0 0 10px'
												value={mrData.well}
												onChange={e => {
													setMRData({ ...mrData, well: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
											<Input
												label={`${t('wms:RigName')}`}
												placeholder={`${t('wms:RigName')}`}
												margin='8px 0 0 10px'
												value={mrData.rig}
												onChange={e => {
													setMRData({ ...mrData, rig: e.target.value })
												}}
												fontSize='small'
												minwidth='30%'
											/>
										</>
									)}
									<Input
										label={`${t('wms:NumberLTPA')}`}
										placeholder={`${t('wms:NumberLTPA')}`}
										margin='8px 0 0 10px'
										value={mrData.ltpa}
										onChange={e => {
											setMRData({ ...mrData, ltpa: e.target.value })
										}}
										fontSize='small'
										minwidth='30%'
									/>
									<DateWrapper>
										<DatePicker
											label={`${t('wms:DispatchExpectedDate')}`}
											placeholder={`${t('wms:SelectDate')}`}
											margin='8px 0 0 10px'
											timePicker={true}
											value={mrData.expecteddate}
											onChange={e => {
												setMRData({ ...mrData, expecteddate: e })
											}}
											fontSize='small'
											minwidth='30%'
										/>
									</DateWrapper>
								</FlexView>
							) : (
								<FlexView
									width='100%'
									fontWeight='bold'
									fontSize='24px'
									color='lightGray'
									margin='auto 0'
									alignItems='center'
									justifyContent='center'>
									{t('wms:ChooseMR')}
								</FlexView>
							)}
							{mrType !== null && (
								<FlexView
									width='100%'
									height='calc((85vh - 90px)*0.35)'
									style={{ overflowY: 'scroll', overflowX: 'auto' }}>
									{mrData && mrData.content.length ? (
										mrData.content.map((content, index) => (
											<FlexView
												key={index}
												width='100%'
												margin='0'
												flexDirection='row'
												padding='8px 0'
												style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
												<FlexView flex='1' fontSize='small' margin='auto 0'>
													{matFormatted(content.mid)} <br />
													<strong>
														{`${levelFullName(content.levelid)} | ${t('wms:Quantity')}: ${
															content.quantity
														}`}
													</strong>
												</FlexView>

												<FlexView
													width='25%'
													height='100%'
													margin='0'
													padding='0'
													flexDirection='row'
													justifyContent='center'>
													{isUpdate ? (
														<FlexView
															fontSize='tiny'
															color={STATUS.color[content.status]}
															style={{
																borderLeft: `2px solid ${theme.colors[STATUS.color[content.status]]}`,
																lineHeight: '24px'
															}}
															margin='auto 0 auto auto'
															padding='8px 16px'>
															{t(`wms:${STATUS.literal[content.status]}`)}
														</FlexView>
													) : (
														''
													)}
													{(!isUpdate || content.status === STATUS.code.NOTSTARTED) && (
														<Button
															fontSize='small'
															margin='auto 0 auto auto'
															padding='8px 16px'
															color='white'
															backgroundColor='error'
															disabled={false}
															onClick={() => {
																removeContent(index)
															}}>
															<Icon
																name='trash'
																color='white'
																height='16px'
																width='16px'
																margin='0'
																tooltip={`${t('wms:RemovePart')}`}
															/>
														</Button>
													)}
													{false && content.status === STATUS.code.RUNNING && (
														<Button
															fontSize='tiny'
															margin='4px 0 0 auto'
															color='white'
															backgroundColor='secondary'
															disabled={false}
															onClick={() => {}}>
															{t('wms:Reopen')}
														</Button>
													)}
												</FlexView>
											</FlexView>
										))
									) : (
										<></>
									)}
								</FlexView>
							)}
						</Card>
					</FlexView>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='16px 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0 8px 0 0'
						style={{ marginLeft: 'calc(30% + 16px)' }}
						color='white'
						backgroundColor='secondary'
						disabled={mrType === null}
						onClick={() => {
							fileUploader.current.click()
						}}>
						{t('wms:UploadMRFile')}
						<Icon name={'upload'} color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
					<FlexView
						style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
						flex='1'
						margin='0 8px 0 0'
						flexDirection='row'>
						{mrFile && mrFile.name}
					</FlexView>
					<input
						style={{ display: 'none' }}
						id='mrFileInput'
						type='file'
						accept='application/pdf'
						ref={fileUploader}
						onChange={e => {
							setMrFile(e.target.files[0])
						}}
					/>
					{!isUpdate ? (
						<Button
							fontSize='medium'
							margin='0 0 0 auto'
							color='white'
							backgroundColor='success'
							disabled={!validateData()}
							onClick={() => saveMr()}>
							{t('wms:Save')}
							<Icon name='save' color='white' height='16px' width='16px' margin='0 0 0 8px' />
						</Button>
					) : (
						<Button
							fontSize='medium'
							margin='0 0 0 auto'
							color='white'
							backgroundColor='secondary'
							disabled={!validateData()}
							onClick={() => updateMRData()}>
							{t('wms:Update')}
							<Icon name={'refresh'} color='white' height='16px' width='16px' margin='0 0 0 8px' />
						</Button>
					)}
				</FlexView>
			</Card>
		</Modal>
	)
}

export default MrModal
