/** @format */

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, Icon, Modal, LoadingOverlay } from 'components/common'
import { RadioGroup } from 'components/form'

const EnduserSelect = ({ isOpen, onOutsideClick, enduserList, onPipeDataSync, loadingResponse = false }) => {
	const { t } = useTranslation()
	const [enduser, setEnduser] = useState(null)

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='auto' data-cy='card-new-type-form-wrapper' position='relative'>
				<LoadingOverlay visible={loadingResponse} borderRadius='card' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:Enduser')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flexDirection='column' justifyContent='space-between'>
					<RadioGroup
						name='enduserOption'
						label={`${t('wms:SelectEnduser')}`}
						value={enduser}
						margin='0'
						data-cy='input-radio-enduser'
						onChange={v => setEnduser(v)}
						options={enduserList.map(eu => ({
							label: eu.name,
							value: eu
						}))}
					/>
					<FlexView width='100%' justifyContent='center' margin='32px 0 0 0'>
						<Button
							margin='0px auto'
							padding='8px 16px'
							color='white'
							backgroundColor='success'
							data-cy='button-sync-pipe-data'
							onClick={() => onPipeDataSync(enduser)}>
							{`${t('wms:SyncMaterialList')}`}
							<Icon name='refresh' color='white' height='12px' width='12px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default EnduserSelect
