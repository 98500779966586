/** @format */

import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import entrance from '../api/batch'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import _ from 'lodash'
import { BatchEntranceContext } from './BatchEntranceStore'

export const NominalDataContext = React.createContext()

export const NominalDataProvider = ({ children }) => {
	const { t } = useTranslation()
	const { getMaterials } = useContext(BatchEntranceContext)
	const { warehouse } = useContext(WMSContext)
	const { user, token } = useContext(UserContext)
	const [loadingEntrance, setLoadingEntrance] = useState(false)
	const [loadingItems, setLoadingItems] = useState(false)
	const [modifAllEntrance, setModifAllEntrance] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [changeEntrance, setChangeEntrance] = useState(false)
	const [savingChanges, setSavingChanges] = useState(false)
	const [entranceList, setEntranceList] = useState(null)
	const [entranceSelected, setEntranceSelected] = useState(null)
	const [items, setItems] = useState(null)
	const [selectedItem, setSelectedItem] = useState([])
	const [parts, setParts] = useState(null)
	const [selectedPart, setSelectedPart] = useState([])
	const [allPart, setAllPart] = useState([])
	const [materials, setMaterials] = useState(null)
	const [matSelected, setMatSelected] = useState(null)
	const [endUser, setEndUser] = useState(null)
	const [itemsToShow, setItemsToShow] = useState(null)
	const [entranceName, setEntranceName] = useState('')

	const getEntranceLists = () =>
		new Promise((resolve, reject) => {
			entrance
				.getEntranceList(warehouse.id, token)
				.then(response => {
					resolve()
					setEntranceList(response)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingEntranceLists')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getItems = () =>
		new Promise((resolve, reject) => {
			entrance
				.getEntranceParts(warehouse.id, entranceSelected, token)
				.then(response => {
					let p = response.content
					_.forEach(p, part => {
						part['show'] = false
						part['selectall'] = false
					})
					setParts(p)
					resolve()
					setItems(_.filter(response.items, res => res.belcid > 0))
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingEntranceItems')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const showItems = () => {
		let item = []
		_.forEach(items, (it, i) => {
			_.forEach(selectedPart, (select, j) => {
				it.belcid === select.belcid && item.push(it)
			})
		})
		item = item.sort((a, b) => b.belcid - a.belcid)
		setItemsToShow(item)
	}

	const modifyNominalData = () =>
		new Promise((resolve, reject) => {
			setSavingChanges(true)
			let content = []
			let entranceContent = _.find(entranceList, entrance => entrance.id === entranceSelected).content
			let payload
			let total = 0
			if (modifAllEntrance) {
				payload = {
					mid: matSelected,
					batchentranceid: entranceSelected,
					batchentrancecontents: null,
					batchentrancename: '',
					responsible: user.name,
					items: null
				}
			} else {
				_.forEach(selectedItem, select => {
					_.forEach(entranceContent, entrance => {
						return entrance.belcid === select.belcid && content.indexOf(select.belcid) === -1
							? content.push(select.belcid)
							: null
					})
				})
				_.forEach(entranceContent, p => {
					total += p.quantity
				})
				payload = {
					mid: matSelected,
					batchentranceid: selectedItem.length === total ? entranceSelected : 0,
					batchentrancecontents: selectedItem.length === total ? [] : content,
					batchentrancename: selectedItem.length === total ? '' : entranceName,
					responsible: user.name,
					items:
						selectedItem.length === total
							? null
							: selectedItem.map(item => {
									return item.valid
							  })
				}
			}
			entrance
				.modifyEntrance(warehouse.id, payload, token)
				.then(response => {
					setOpenConfirmModal(false)
					resolve(response)
					toast.success(t('wms:NominalDataModified'))
					setSelectedItem([])
					setEntranceSelected(null)
					setModifAllEntrance(false)
					setEntranceName('')
					setItemsToShow([])
					setMatSelected(null)
					setChangeEntrance(false)
					setParts(null)
					setSavingChanges(false)
					getEntranceLists()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorModifying')} [ ${e.status} ]: ${e.data}`)
					setSavingChanges(false)
				})
				.finally(() => {
					setSavingChanges(false)
				})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingEntrance(true)
				await getEntranceLists()
			} catch (e) {
				console.error(e)
				setLoadingEntrance(false)
			} finally {
				setLoadingEntrance(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingItems(true)
				entranceSelected && (await getItems())
			} catch (e) {
				console.error(e)
				setLoadingItems(false)
			} finally {
				setLoadingItems(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entranceSelected])

	useEffect(() => {
		endUser &&
			getMaterials(endUser.id)
				.then(matList => setMaterials(matList))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, endUser])

	useEffect(() => {
		entranceSelected && setEndUser(_.find(entranceList, batch => batch.id === entranceSelected).enduser)
	}, [entranceSelected, entranceList])

	useEffect(() => {
		;(selectedPart.length > 0 || modifAllEntrance === false) && showItems()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPart, modifAllEntrance])

	useEffect(() => {
		modifAllEntrance === true && setItemsToShow(null)
	}, [modifAllEntrance])

	useEffect(() => {
		setEntranceSelected(null)
		setParts(null)
		setEndUser(null)
		setMatSelected(null)
	}, [warehouse])

	const renderStore = (
		<NominalDataContext.Provider
			value={{
				loadingEntrance,
				setLoadingEntrance,
				loadingItems,
				setLoadingItems,
				entranceList,
				setEntranceList,
				entranceSelected,
				setEntranceSelected,
				items,
				setItems,
				selectedItem,
				setSelectedItem,
				materials,
				setMaterials,
				matSelected,
				setMatSelected,
				endUser,
				setEndUser,
				parts,
				setParts,
				selectedPart,
				setSelectedPart,
				itemsToShow,
				setItemsToShow,
				modifAllEntrance,
				setModifAllEntrance,
				allPart,
				setAllPart,
				entranceName,
				setEntranceName,
				openConfirmModal,
				setOpenConfirmModal,
				changeEntrance,
				setChangeEntrance,
				modifyNominalData,
				savingChanges,
				setSavingChanges
			}}>
			{children}
		</NominalDataContext.Provider>
	)
	return renderStore
}
