/** @format */

import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import rigreturn from '../api/rigreturn'

import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'

export const RigReturnContext = React.createContext()

export const RigReturnProvider = ({ children }) => {
	const { warehouse, getEnduserList, getLevelsData } = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const [rrList, setRRList] = useState([])
	const [loadingRRList, setLoadingRRList] = useState(false)
	const [selectedRR, setSelectedRR] = useState(null)
	const [uploadRRList, setUploadRRList] = useState(false)
	const [rrModal, setRRModal] = useState(false)
	const [endusers, setEndusers] = useState([])
	const [levels, setLevels] = useState([])

	const getRRList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingRRList(true)
			rigreturn
				.getRigReturnList(warehouse.id, token)
				.then(rigReturnList => resolve(rigReturnList))
				.catch(e => {
					toast.error(t(`wms:ErrorGettingRigReturn`))
					reject(e)
				})
				.finally(() => setLoadingRRList(false))
		})

	const deleteRR = rrId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			rrId !== null &&
				rigreturn
					.deleteRR(warehouse.id, rrId, token)
					.then(response => {
						toast.success(t(`wms:RigReturnRemoved`))
						resolve(true)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorDeletingRigReturnList`))
						reject(e)
					})
					.finally(() => {})
		})

	const uploadRRFile = file =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			if (window.FileReader) {
				const reader = new FileReader()
				reader.onload = event => {
					event.target.result &&
						rigreturn
							.uploadRRFile(warehouse.id, event.target.result, token)
							.then(fileName => resolve(fileName))
							.catch(e => {
								toast.error(t(`wms:UploadFailure`))
								reject(e)
							})
							.finally(() => {})
				}
				reader.onerror = () => {
					toast.error(t('wms:ErrorReadingFile'))
				}
				reader.readAsDataURL(file)
			} else reject('Filereader not found')
		})

	const newRR = rrData =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}

			let payload = {
				name: rrData.name,
				enduserid: rrData.enduser.id,
				enduser: rrData.enduser.name,
				ownershipid: rrData.ownershipid,
				rrref: rrData.rrref,
				field: rrData.field,
				well: rrData.well,
				rig: rrData.rig,
				rrfile: rrData.rrfile,
				expecteddate: rrData.expecteddate,
				contentquantity: rrData.contentquantity
			}

			rigreturn
				.newRR(warehouse.id, payload, token)
				.then(response => {
					toast.success(t(`wms:RigReturnCreated`))
					resolve(true)
				})
				.catch(e => {
					toast.error(t(`${e}`))
					reject(e)
				})
				.finally(() => {})
		})

	const handleReopen = content =>
		new Promise((resolve, reject) => {
			let payload = {
				rrlcid: content.rrlcid
			}
			rigreturn
				.reopenRigReturn(warehouse.id, payload, token)
				.then(response => {
					toast.success(t(`wms:SuccessfullyReopenContent`))
					resolve(true)
					setUploadRRList(true)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorReopenContent')} [ ${e.status} ]: ${e.data}`)
				})
				.finally(() => {})
		})

	useEffect(() => {
		setUploadRRList(true)
		getEnduserList()
			.then(endusersList => endusersList && endusersList.length && setEndusers(endusersList))
			.catch(e => console.error(e))
		getLevelsData()
			.then(levels => setLevels(levels))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		getRRList()
			.then(RRList => setRRList(RRList))
			.catch(e => console.error(e))
		setUploadRRList(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadRRList, warehouse])

	const openRRFile = () =>
		new Promise((resolve, reject) => {
			selectedRR &&
				selectedRR.id &&
				rigreturn
					.openRRFile(warehouse.id, selectedRR.id, token)
					.then(response => {
						let fileName = `RigReturnRR_${warehouse.id}_${selectedRR.id}.pdf`
						let a = document.createElement('a')
						let file = new Blob([response], {
							type: response.type
						})
						let fileUrl = window.URL.createObjectURL(file)
						a.href = fileUrl
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorOpeningRRFile')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`)
					})
		})

	const renderStore = (
		<RigReturnContext.Provider
			value={{
				selectedRR,
				setUploadRRList,
				rrModal,
				setRRModal,
				rrList,
				loadingRRList,
				setSelectedRR,
				deleteRR,
				endusers,
				uploadRRFile,
				newRR,
				openRRFile,
				levels,
				handleReopen
			}}>
			{children}
		</RigReturnContext.Provider>
	)
	return renderStore
}
