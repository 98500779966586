/** @format */

import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import inspection from '../api/inspection'

import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'

export const RecInspContext = React.createContext()

export const RecInspProvider = ({ children }) => {
	const { warehouse, getMaterialsDescpritions, getEnduserList } = useContext(WMSContext)
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const [riList, setRIList] = useState([])
	const [materialList, setMaterialList] = useState([])
	const [loadingRIList, setLoadingRIList] = useState(false)
	const [selectedRI, setSelectedRI] = useState(null)
	const [uploadRIList, setUploadRIList] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false)
	const [riModal, setRIModal] = useState(false)
	const [endusers, setEndusers] = useState([])

	const getRIList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingRIList(true)
			inspection
				.getRecInspList(warehouse.id, token)
				.then(recInspList => resolve(recInspList))
				.catch(e => {
					toast.error(t(`wms:ErrorGettingRecInspList`))
					reject(e)
				})
				.finally(() => setLoadingRIList(false))
		})

	const getMaterialsByEU = sourceEnduser =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			sourceEnduser &&
				inspection
					.getMaterialsByEU(warehouse.id, sourceEnduser, token)
					.then(materialList => resolve(materialList.map(mat => ({ id: mat.id, formatted: mat.desc }))))
					.catch(e => {
						toast.error(t(`wms:ErrorGettingMatListEndUser`))
						reject(e)
					})
					.finally(() => {})
		})

	const getCustormerOrdersList = (sourceEnduser, sourceMaterial) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			sourceEnduser !== null &&
				sourceMaterial !== null &&
				inspection
					.getCOList(warehouse.id, sourceEnduser, sourceMaterial, token)
					.then(response => {
						if (response.length) {
							let orders = []
							response.forEach(item => {
								let index = orders.findIndex(order => order.value === item.number)
								if (index === -1) {
									orders.unshift({ value: item.number, items: [] })
									index = 0
								}
								orders[index].items.push(item.item)
							})
							resolve(orders)
						} else resolve(response)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorGettingMatListEndUser`))
						reject(e)
					})
					.finally(() => {})
		})

	const getSalesOrdersList = (sourceEnduser, sourceMaterial) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			sourceEnduser !== null &&
				sourceMaterial !== null &&
				inspection
					.getSOList(warehouse.id, sourceEnduser, sourceMaterial, token)
					.then(response => {
						if (response.length) {
							let sales = []
							response.forEach(item => {
								let index = sales.findIndex(sale => sale.value === item.number)
								if (index === -1) {
									sales.unshift({ value: item.number, items: [] })
									index = 0
								}
								sales[index].items.push(item.item)
							})
							resolve(sales)
						} else resolve(response)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorGettingMatListEndUser`))
						reject(e)
					})
					.finally(() => {})
		})

	const getAvailableQty = (sourceEnduser, mid, order, item, isSales) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			sourceEnduser &&
				inspection
					.getAvailableQnt(warehouse.id, sourceEnduser, mid, order, item, isSales, token)
					.then(itemQnty => resolve(itemQnty))
					.catch(e => {
						toast.error(t(`wms:ErrorGettingItemsQuantity`))
						reject(e)
					})
					.finally(() => {})
		})

	const deleteRI = riId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			riId !== null &&
				inspection
					.deleteRI(warehouse.id, riId, token)
					.then(response => {
						toast.success(t(`wms:ReceivingInspectionRemoved`))
						resolve(true)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorDeletingRecInsp`))
						reject(e)
					})
					.finally(() => {})
		})

	const newRI = riData =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			let payload = {
				name: riData.name,
				customerpo: riData.content[0].customerpo,
				customeritem: riData.content[0].customerpo,
				salesorder: riData.content[0].customerpo,
				salesitem: riData.content[0].customerpo,
				expecteddate: riData.expecteddate,
				responsible: user.name,
				content: riData.content
			}

			inspection
				.newRI(warehouse.id, payload, token)
				.then(response => {
					toast.success(t(`wms:ReceivingInspectionCreated`))
					resolve(true)
				})
				.catch(e => {
					toast.error(t(`${e}`))
					reject(e)
				})
				.finally(() => {})
		})

	const handleReopen = rilcid =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			let payload = {
				rilcid: rilcid
			}
			inspection
				.reopenRecInsp(warehouse.id, payload, token)
				.then(response => {
					toast.success(t(`wms:SuccessfullyReopenContent`))
					setUploadRIList(true)
					resolve(true)
				})
				.catch(e => {
					toast.error(t(`${e}`))
					reject(e)
				})
				.finally(() => {})
		})

	useEffect(() => {
		setUploadRIList(true)
		getMaterialsDescpritions()
			.then(materialsList => setMaterialList(materialsList))
			.catch(e => console.error(e))
		getEnduserList()
			.then(endusersList => endusersList && endusersList.length && setEndusers(endusersList))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setUploadRIList(true)
		getMaterialsDescpritions()
			.then(materialsList => setMaterialList(materialsList))
			.catch(e => console.error(e))
		getEnduserList()
			.then(endusersList => endusersList && endusersList.length && setEndusers(endusersList))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		getRIList()
			.then(RIList => setRIList(RIList))
			.catch(e => console.error(e))
		setUploadRIList(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadRIList, warehouse])

	const renderStore = (
		<RecInspContext.Provider
			value={{
				selectedRI,
				setUploadRIList,
				isUpdate,
				riModal,
				setRIModal,
				riList,
				loadingRIList,
				setSelectedRI,
				deleteRI,
				setIsUpdate,
				materialList,
				endusers,
				getMaterialsByEU,
				getCustormerOrdersList,
				getSalesOrdersList,
				getAvailableQty,
				newRI,
				handleReopen
			}}>
			{children}
		</RecInspContext.Provider>
	)
	return renderStore
}
