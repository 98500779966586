/** @format */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, Icon, Modal, LoadingOverlay } from 'components/common'
import { AssetsMaterialContext } from 'apps/wms/stores/AssetsMaterialStore'
import Select from 'apps/wms/components/forms/Select'
import { Checkbox, Input } from 'components/form'
import styled from 'styled-components'
import { WMSContext } from 'apps/wms/stores/WmsStore'

const SInput = styled(Input)`
	min-height: unset;
	font-weight: normal;
	margin: 0px;
	border-width: 1px;
`

const MaterialHandlerModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { materialConfig, enduserList, attributesList, createMaterial, user } = useContext(AssetsMaterialContext)
	const { lengthUnit, weightUnit } = useContext(WMSContext)
	const [isLoading, setIsLoading] = useState(false)

	const attritbutesDataModel = useCallback(() => {
		let obj = {}
		attributesList.forEach(a => (obj[a.name] = 0))
		return obj
	}, [attributesList])

	const [newMaterialAttributes, setNewMaterialAttributes] = useState(() => attritbutesDataModel())

	useEffect(
		() => setNewMaterialAttributes(attritbutesDataModel()),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[attributesList]
	)

	const materialDataModel = useCallback(
		() => ({
			attributevalueids: [],
			formatstring: '',
			erpreference: '',
			erpreferencedesc: '',
			weightkg: '',
			lengthmm: '',
			ismodifiedproduct: false,
			modifiedproductdesc: '',
			enduser: null,
			responsible: user.name
		}),
		[user]
	)

	const [newMaterialData, setNewMaterialData] = useState(() => materialDataModel())

	const isCrossOver = useMemo(() => {
		const productTypeAttribute = materialConfig
			? attributesList.find(a => a.id === materialConfig.producttypeattributeid)
			: null
		return productTypeAttribute
			? newMaterialAttributes[productTypeAttribute.name] === materialConfig.crossoverattributevalue
			: false
	}, [materialConfig, attributesList, newMaterialAttributes])

	const isMandatory = attribute =>
		attribute.mandatory && (!attribute.mandatoryextension || (attribute.mandatoryextension && isCrossOver))

	const notNull = value => value !== null && value !== '' && value !== 0

	const isInfoValid = data =>
		notNull(data.erpreference) &&
		notNull(data.erpreferencedesc) &&
		notNull(data.weightkg) &&
		notNull(data.lengthmm) &&
		notNull(data.enduser) &&
		(data.ismodifiedproduct ? notNull(data.modifiedproductdesc) : true)

	const isAttrValid = (attrList, data) => {
		let flag = true
		attrList.forEach(attr => {
			if (isMandatory(attr)) flag = flag && notNull(data[attr.name])
		})
		return flag
	}

	const isDataValid = () => isInfoValid(newMaterialData) && isAttrValid(attributesList, newMaterialAttributes)

	const onCreateMaterial = async () => {
		try {
			setIsLoading(true)
			let attrValues = [...Object.values(newMaterialAttributes)]

			if (attrValues.length < materialConfig.attributeids.length) {
				const diff = materialConfig.attributeids.length - attrValues.length
				const diffArr = new Array(diff).fill(0)
				attrValues = [...attrValues, ...diffArr]
			}

			const payload = {
				...newMaterialData,
				formatstring: materialConfig.formatstring,
				attributevalueids: [...Object.values(newMaterialAttributes)]
			}
			await createMaterial(payload)
			setIsLoading(false)
			resetInputData()
			onOutsideClick()
		} catch (e) {
			setIsLoading(false)
			console.error(e)
		}
	}

	const resetInputData = () => {
		setNewMaterialData(materialDataModel())
		setNewMaterialAttributes(attritbutesDataModel())
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card
				borderRadius='0'
				backgroundColor='background'
				maxWidth='calc(30% - 32px)'
				height='calc(100% - 32px)'
				maxHeight='100vh'
				flex='1'
				margin='0 0 0 auto'
				padding='16px'
				alignItems='center'
				alignSelf='flex-end'
				style={{ position: 'relative', overflowY: 'scroll' }}
				data-cy='card-new-material-wrapper'>
				<LoadingOverlay visible={isLoading} />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:NewMaterial')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flexDirection='column' justifyContent='space-between'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 8px 0'>
						{t('wms:SelectEnduser')}
					</FlexView>
					<Select
						placeholder={t(`wms:EndUser`)}
						value={newMaterialData.enduser}
						options={enduserList.map(({ id, name }) => ({
							value: id,
							label: name
						}))}
						onChange={value => {
							setNewMaterialData({ ...newMaterialData, enduser: value })
						}}
						width='100%'
						inline={false}
						margin='0'
						searchable
						data-cy='select-source-enduser'
					/>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 8px 0'>
						{t('wms:InsertMaterialReference')}
					</FlexView>
					<SInput
						placeholder={t('wms:ErpReference')}
						margin='0 0 8px 0'
						type='text'
						value={newMaterialData.erpreference}
						onChange={e => {
							setNewMaterialData({ ...newMaterialData, erpreference: e.target.value })
						}}
						fontSize='small'
						width='100%'
						data-cy='input-erp-reference'
					/>
					<SInput
						placeholder={t('wms:ErpDescription')}
						margin='0'
						type='text'
						value={newMaterialData.erpreferencedesc}
						onChange={e => {
							setNewMaterialData({ ...newMaterialData, erpreferencedesc: e.target.value })
						}}
						fontSize='small'
						width='100%'
						data-cy='input-erp-reference'
					/>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 8px 0'>
						{t('wms:InsertMaterialDefaultData')}
					</FlexView>
					<FlexView flexDirection='row' width='100%' padding='0' margin='0' justifyContent='space-between'>
						<SInput
							placeholder={`${t('wms:Length')} [${lengthUnit}]`}
							margin='0 8px 8px 0'
							type='number'
							min='0'
							max='20000'
							value={newMaterialData.lengthmm}
							onChange={e => {
								parseInt(e.target.value) &&
									e.target.value >= 0 &&
									setNewMaterialData({ ...newMaterialData, lengthmm: e.target.value })
							}}
							fontSize='small'
							width='100%'
							data-cy='input-material-length'
						/>
						<SInput
							placeholder={`${t('wms:Weight')} [${weightUnit}]`}
							margin='0'
							type='number'
							min='0'
							max='20000'
							value={newMaterialData.weightkg}
							onChange={e => {
								parseInt(e.target.value) &&
									e.target.value >= 0 &&
									setNewMaterialData({ ...newMaterialData, weightkg: e.target.value })
							}}
							fontSize='small'
							width='100%'
							data-cy='input-material-weight'
						/>
					</FlexView>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 8px 0'>
						{t('wms:ModifiedProductData')}
					</FlexView>
					<Checkbox
						fontSize='small'
						margin='0 0 8px 0'
						label={t('wms:ModifiedProduct')}
						checked={newMaterialData.ismodifiedproduct}
						onChange={v =>
							setNewMaterialData({
								...newMaterialData,
								ismodifiedproduct: !newMaterialData.ismodifiedproduct
							})
						}
					/>
					<SInput
						placeholder={t('wms:ModifiedProductDesc')}
						margin='0'
						type='text'
						disabled={!newMaterialData.ismodifiedproduct}
						value={newMaterialData.modifiedproductdesc}
						onChange={e => {
							setNewMaterialData({ ...newMaterialData, modifiedproductdesc: e.target.value })
						}}
						fontSize='small'
						width='100%'
						data-cy='input-material-modif-prod-desc'
					/>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 8px 0'>
						{t('wms:SelectMandatoryAttributes')}
					</FlexView>

					<FlexView flexDirection='row' flexWrap='wrap' justifyContent='space-between'>
						{attributesList.length &&
							attributesList
								.filter(a => a.mandatory || a.mandatoryextension)
								.map((attr, index) =>
									isMandatory(attr) ? (
										<Select
											key={index}
											placeholder={attr.symbol ? `${attr.name} [${attr.symbol}]` : attr.name}
											fontSize='small'
											value={newMaterialAttributes[attr.name]}
											options={attr.values.map(({ id, value }) => ({
												value: id,
												label: value
											}))}
											onChange={value => {
												setNewMaterialAttributes({ ...newMaterialAttributes, [attr.name]: value })
											}}
											width='45%'
											inline={false}
											margin='0 0 8px 0'
											data-cy='select-source-enduser'
										/>
									) : null
								)}
					</FlexView>

					<Button
						fontSize='small'
						width='90%'
						margin='24px auto 0 auto'
						padding='8px 16px'
						color='white'
						backgroundColor='secondary'
						disabled={!isDataValid()}
						onClick={() => onCreateMaterial()}>
						{`${t('wms:CreateMaterial')}`}
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default MaterialHandlerModal
