/** @format */

import React, { useContext, useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import common from '../api/common'
import usageRights from '../api/usageRights'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { featureReport } from '../utils/enum'

export const WMSContext = React.createContext()

export const WMSProvider = ({ children }) => {
	const { token } = useContext(UserContext)
	const { i18n, t } = useTranslation()
	const history = useHistory()
	const lengthFeetRatio = 3.28084
	const weightFeetRatio = 2.2046

	const [lengthUnit, setLengthUnit] = useState(() => {
		const storagedLengthUnit = JSON.parse(localStorage.getItem('@Shalyn:wms:usrLengthUnit'))
		return storagedLengthUnit ? (storagedLengthUnit.lengthUnit ? storagedLengthUnit.lengthUnit : 'mm') : 'mm'
	})
	const [weightUnit, setWeightUnit] = useState(() => {
		const storagedWeightUnit = JSON.parse(localStorage.getItem('@Shalyn:wms:usrWeightUnit'))
		return storagedWeightUnit ? (storagedWeightUnit.weightUnit ? storagedWeightUnit.weightUnit : 'kg') : 'kg'
	})
	const [warehouse, setWarehouse] = useState(() => {
		const storagedWarehouse = JSON.parse(localStorage.getItem('@Shalyn:wms:usrWarehouse'))
		return storagedWarehouse ? (storagedWarehouse.warehouse ? storagedWarehouse.warehouse : {}) : {}
	})

	const [lastLevelsList, setLastLevelsList] = useState([])

	const formatNumberByCulture = useCallback(
		(value, digits) => {
			return new Intl.NumberFormat(i18n.language, { minimumFractionDigits: digits }).format(value)
		},
		[i18n.language]
	)

	const displayBundles = () => warehouse && warehouse.displayitem === 0
	const displayValids = () => warehouse && warehouse.displayitem === 1
	const displayPuuids = () => warehouse && warehouse.displayitem === 2

	const convertToCurrentLengthUnit = useCallback(
		(value, unit) => {
			if (value === null || value === undefined || value === '') {
				return 0
			}
			if (unit === 'mm') {
				return formatNumberByCulture(value, 3)
			} else if (unit === 'ft') {
				return formatNumberByCulture(value * (1 / 304.8), 3)
			}
			return formatNumberByCulture(value / (1 / 304.8), 3)
		},
		[formatNumberByCulture]
	)

	const convertToCurrentWeightUnit = useCallback(
		(value, unit) => {
			if (value === null || value === undefined || value === '') {
				return 0
			}
			if (unit === 'kg') {
				return formatNumberByCulture(value, 3)
			} else if (unit === 'lb') {
				return formatNumberByCulture(value * 2.2046, 3)
			}
			return formatNumberByCulture(value / 2.2046, 3)
		},
		[formatNumberByCulture]
	)

	const numConvertToCurrentWeightUnit = useCallback((value, unit) => {
		if (value === null || value === undefined || value === '') {
			return 0
		}
		if (unit === 'kg') {
			return value
		} else if (unit === 'lb') {
			return value * 2.2046
		}
		return value / 2.2046
	}, [])

	const convertLengthToSend = useCallback(
		length => {
			if (!isNaN(length)) {
				if (lengthUnit === 'mm') return length
				else return fromFeetToMilimeter(length, 'mm')
			} else return length
		},
		[lengthUnit]
	)

	const convertWeightToSend = useCallback(
		weight => {
			if (weightUnit === 'kg') return weight
			else return fromPoundToKilogram(weight)
		},
		[weightUnit]
	)

	const fromFeetToMilimeter = (value, prefix) => {
		let unitPrefix = 1
		if (prefix === 'mm') unitPrefix = 1000
		else if (prefix === 'cm') unitPrefix = 100
		else unitPrefix = 1
		return (value / lengthFeetRatio) * unitPrefix
	}

	const fromPoundToKilogram = value => {
		return value / weightFeetRatio
	}

	const getLastLevelsList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error(t('wms:WarehouseNotDefined')))
			} else
				common
					.getLastLevelsList(warehouse.id, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorGettingLastLevelWarehouse`))
						reject(e)
					})
					.finally(() => {})
		})

	const getLevelsList = () =>
		new Promise((resolve, reject) => {
			common
				.getLevelsList(token)
				.then(warehousesList => {
					resolve(warehousesList)
				})
				.catch(e => {
					toast.error(e.data || t(`wms:ErrorGettingWarehouse`))
					reject(e)
				})
				.finally(() => {})
		})

	const getLevelsData = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				usageRights
					.getOwnershipsList(warehouse.id, token)
					.then(response => {
						let lastLevel = Math.max.apply(
							Math,
							response.ownershipdescriptions.map(res => res.level)
						)
						let oData = response.ownershipdescriptions
						let level = []
						let lastLevelDesc = _.filter(oData, owner => {
							return owner.level === lastLevel
						})
						_.forEach(lastLevelDesc, owner => {
							level.push({ id: owner.id, label: owner.namepath.join('.') })
						})
						resolve(level)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => {})
		})

	const getContentByOwnership = (sourceOwnership, physTrans) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				usageRights
					.getContentByOwnership(warehouse.id, sourceOwnership, physTrans, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorGettingContent`))
						reject(e)
					})
					.finally(() => {})
		})

	const getContentByEndUser = (sourceEndUser, physTrans) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				usageRights
					.getContentByEndUser(warehouse.id, sourceEndUser, physTrans, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorGettingContent`))
						reject(e)
					})
					.finally(() => {})
		})

	const bundlesOrItems = useCallback(
		bundles => {
			if (!(warehouse && bundles && bundles.length)) return []
			if (displayBundles()) return bundles
			else {
				return bundles.flatMap(bundle => {
					let items
					if (bundle.hasOwnProperty('material')) {
						items = bundle.items.map(item => {
							item.material = bundle.material
							return item
						})
					}
					if (bundle.hasOwnProperty('level')) {
						items = bundle.items.map(item => {
							item.level = bundle.level
							return item
						})
					}
					if (bundle.hasOwnProperty('ownershipid')) {
						items = bundle.items.map(item => {
							item.ownershipid = bundle.ownershipid
							return item
						})
					}
					if (bundle.hasOwnProperty('enduserid')) {
						items = bundle.items.map(item => {
							item.enduserid = bundle.enduserid
							return item
						})
					}
					return items
				})
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse]
	)

	const getLevelContent = levelId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error(t('wms:WarehouseNotDefined')))
			} else
				common
					.getLevelContent(warehouse.id, levelId, token)
					.then(contentList => {
						let items = bundlesOrItems(contentList)
						items = items.map(item => {
							if (displayBundles())
								return { id: item.bundlelocalid, weight: 0, material: item.material, level: item.level }
							if (displayValids())
								return { id: item.valid, weight: item.weightkg, material: item.material, level: item.level }
							if (displayPuuids())
								return { id: item.puuid, weight: item.weightkg, material: item.material, level: item.level }
							return item
						})
						resolve(items)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorReadingContent`))
						reject(e)
					})
					.finally(() => {})
		})

	const getEnduserList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				usageRights
					.getEnduserList(warehouse.id, token)
					.then(response => response.length && resolve(response))
					.catch(e => {
						toast.error(t(`wms:ErrorGettingEndUser`))
						reject(e)
					})
					.finally(() => {})
		})

	const getMaterialsDescpritions = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				common
					.getMaterialsDescription(warehouse.id, token)
					.then(contentList => contentList && resolve(contentList))
					.catch(e => {
						toast.error(t(`wms:ErrorGettingMaterialDsc`))
						reject(e)
					})
					.finally(() => {})
		})

	const getReportList = selectedType =>
		new Promise((resolve, reject) => {
			let feature = featureReport(selectedType)
			feature &&
				common
					.getReports(warehouse.id, feature, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorGettingReports')} [ ${e.status} ]: ${e.data}`)
					})
		})

	const getReportItems = (selectedType, reportID) =>
		new Promise((resolve, reject) => {
			let feature = featureReport(selectedType)
			feature &&
				reportID &&
				common
					.getReportItems(warehouse.id, reportID, feature, token)
					.then(response => {
						let items = bundlesOrItems(response)
						items = items.map(item => {
							if (displayBundles())
								return { id: item.bundlelocalid, weight: 0, material: item.material, level: item.level }
							if (displayValids())
								return { id: item.valid, weight: item.weightkg, material: item.material, level: item.level }
							if (displayPuuids())
								return { id: item.puuid, weight: item.weightkg, material: item.material, level: item.level }
							return items
						})
						resolve(items)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorReadingContent')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => {})
		})

	const getWeightDisplay = useCallback((warehouse, weightKg) => {
		return weightKg
	}, [])

	const getLengthDisplay = useCallback((warehouse, lengthmm) => {
		return lengthmm
	}, [])

	const getWeightKg = useCallback((warehouse, weight) => {
		let v = parseFloat(weight)
		return isNaN(v) ? 0 : v
	}, [])

	const getLengthMm = useCallback((warehouse, length) => {
		let v = parseInt(length)
		return isNaN(v) ? 0 : v
	}, [])

	useEffect(() => {
		_.isEmpty(warehouse) && history.location.pathname !== '/wms/' && history.push('/wms/')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location])

	useEffect(() => {
		localStorage.setItem('@Shalyn:wms:usrWarehouse', JSON.stringify({ warehouse }))
	}, [warehouse])

	useEffect(() => {
		localStorage.setItem('@Shalyn:wms:usrLengthUnit', JSON.stringify({ lengthUnit }))
	}, [lengthUnit])

	useEffect(() => {
		localStorage.setItem('@Shalyn:wms:usrWeightUnit', JSON.stringify({ weightUnit }))
	}, [weightUnit])

	useEffect(() => {
		warehouse &&
			warehouse.id &&
			getLastLevelsList()
				.then(list => list.length && setLastLevelsList(list))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const renderStore = (
		<WMSContext.Provider
			value={{
				lengthUnit,
				weightUnit,
				convertToCurrentLengthUnit,
				convertToCurrentWeightUnit,
				setLengthUnit,
				setWeightUnit,
				numConvertToCurrentWeightUnit,
				setWarehouse,
				formatNumberByCulture,
				getLevelsList,
				warehouse,
				bundlesOrItems,
				displayBundles,
				displayValids,
				displayPuuids,
				getLastLevelsList,
				getLevelContent,
				getMaterialsDescpritions,
				lastLevelsList,
				getLevelsData,
				getContentByOwnership,
				getContentByEndUser,
				getEnduserList,
				getWeightDisplay,
				getLengthDisplay,
				getWeightKg,
				getLengthMm,
				convertLengthToSend,
				convertWeightToSend,
				getReportList,
				getReportItems
			}}>
			{children}
		</WMSContext.Provider>
	)
	return renderStore
}
