/** @format */

import React, { useContext, useRef, useState, useEffect } from 'react'
import { Modal, Card, LoadingOverlay, Icon, FlexView, Button } from 'components/common'
import { Input, DatePicker, Select } from 'components/form'
import { useTranslation } from 'react-i18next'
import { ModifiedProductContext } from '../../stores/ModifProductStore'
import { STATUS } from '../../utils/enum'
import moment from 'moment'
import { toast } from 'react-toastify'
import _ from 'lodash'
import theme from 'utils/theme'

const UpdateModifProdListModal = ({ isOpen, onOutsideClick, list }) => {
	const { t } = useTranslation()
	const {
		loadingUpdate,
		setEndUser,
		levels,
		materials,
		expectedQuantity,
		setExpectedQuantity,
		uploadMPFile,
		updateModifProductList,
		updateContent,
		deletedContent,
		setUpdateContent,
		sourceMatList,
		loadingMaterials,
		sourceMaterial,
		targetMaterial,
		endUsers,
		setSourceMaterial,
		setTargetMaterial
	} = useContext(ModifiedProductContext)
	const fileUploader = useRef(null)
	const [fileUpload, setFileUpload] = useState(false)
	const [mpUpload, setMpUpload] = useState(false)
	const [mpFile, setMPFile] = useState(undefined)

	const handleAddToModifList = () => {
		let targetMatDesc = null
		let sourceMatDesc = null
		if (updateContent.content.length > 0) {
			_.forEach(updateContent.content, ct => {
				targetMatDesc = _.find(materials, mat => mat.id === ct.mid).id
				_.forEach(sourceMatList, mat => {
					return mat.id === ct.midsource ? (sourceMatDesc = mat.id) : null
				})
			})
		} else if (updateContent.content.length === 0) {
			targetMatDesc = targetMaterial
			sourceMatDesc = sourceMaterial
		}

		let content = { midsource: sourceMatDesc, mid: targetMatDesc, quantity: parseInt(expectedQuantity) }
		updateContent.content.push(content)
		setUpdateContent({ ...updateContent })
		setExpectedQuantity(0)
	}

	const handleExepctedQuantity = e => {
		setExpectedQuantity(e.target.value)
	}

	const handleDelete = index => {
		let content = updateContent.content.splice(index, 1)
		if (content[0].status === STATUS.code.NOTSTARTED) deletedContent.push(...content)
		setUpdateContent({ ...updateContent, content: [...updateContent.content] })
	}

	const handleUpdate = async () => {
		try {
			setMpUpload(true)
			await updateModifProductList(updateContent)
				.then(response => response)
				.finally(() => {
					setMpUpload(false)
					setMPFile(undefined)
				})
			onOutsideClick()
		} catch (e) {
			console.error(e)
		}
	}

	const validateData = () => {
		let isDisabled = true
		isDisabled =
			isDisabled &&
			((updateContent && updateContent.content.length <= 0) ||
				(updateContent && (!updateContent.name || updateContent.name.length > 20)) ||
				(updateContent && updateContent.expecteddate < new Date()))
		return isDisabled
	}

	const handleDateChange = v => {
		if (v._d <= new Date()) return toast.error(t('wms:FutureExpectDate'))
		else setUpdateContent({ ...updateContent, expecteddate: v })
	}

	const uploadFile = async e => {
		setMPFile(e.target.files[0])
		try {
			setFileUpload(true)
			await uploadMPFile(e.target.files[0])
				.then(fileName => {
					updateContent.file = fileName
					setUpdateContent({ ...updateContent, file: fileName })
				})
				.finally(() => setFileUpload(false))
		} catch (e) {
			console.error(e)
		}
	}

	const inputMessage = () => {
		if (expectedQuantity.length < 0) return t('wms:NegativeNumber')
		else return t('wms:PositiveNumberAllowed')
	}

	const inputMessageType = () => {
		if (expectedQuantity.length < 0) return 'error'
		else return 'info'
	}

	useEffect(() => {
		updateContent && setEndUser(updateContent.enduserid)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateContent])

	const getSourceMaterial = midsource => {
		let sourceMatDesc = _.find(sourceMatList, mat => mat.id === midsource)
		return sourceMatDesc ? sourceMatDesc.desc : null
	}

	const getTargetMaterial = mid => {
		let targetMatDesc = _.find(materials, mat => mat.id === mid)
		return targetMatDesc ? targetMatDesc.desc : null
	}

	const selectEndUser = id => {
		setEndUser(id)
		let endUser = _.find(endUsers, eu => eu.id === id)
		endUser &&
			setUpdateContent({
				...updateContent,
				enduser: endUser
			})
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='85%' height='85%' position='relative' data-cy='card-update-modal'>
				<LoadingOverlay visible={loadingUpdate} borderRadius='16px' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:UpdateModifProd')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' height='calc(100% - 106px)' flex='1' flexDirection='row' margin='0 0 16px 0'>
					<FlexView maxWidth='60%' minWidth='40%' minHeight='100%' height='100%' margin='0 16px 0 0'>
						<LoadingOverlay visible={loadingMaterials} borderRadius='card' />
						<Card
							margin='0'
							flex='1'
							minWidth='calc(100% - 48px)'
							maxWidth='calc(100% - 48px)'
							maxHeight='100%'
							style={{ overflowY: 'scroll' }}
							data-cy='card-list-info'>
							{updateContent && updateContent.content.length === 0 ? (
								<>
									{endUsers && (
										<Select
											label={t('wms:EndUser')}
											placeholder={t('wms:EndUser')}
											value={updateContent.enduser.id}
											options={endUsers.map(eu => ({ value: eu.id, label: eu.name }))}
											onChange={value => {
												selectEndUser(value)
											}}
											width='100%'
											margin='16px 0 0 0'
											searchable
											data-cy='select-enduser'
										/>
									)}
									{levels && levels.length > 0 && (
										<Select
											width='100%'
											margin='16px 0 0 0'
											label={t('wms:Ownership')}
											placeholder={t('wms:Ownership')}
											value={updateContent.ownershipid}
											options={_.map(levels, lv => ({
												value: lv.id,
												label: lv.label
											}))}
											onChange={v => setUpdateContent({ ...updateContent, ownershipid: v })}
										/>
									)}
									<Select
										label={t('wms:SourceMaterial')}
										placeholder={t('wms:SelectOption')}
										value={sourceMaterial}
										options={
											sourceMatList
												? sourceMatList.map(mat => {
														return { value: mat.id, label: mat.desc }
												  })
												: []
										}
										onChange={value => setSourceMaterial(value)}
										width='100%'
										margin='16px 0 0 0'
										disabled={!updateContent.ownershipid}
										searchable
										data-cy='select-material'
									/>
									<Select
										label={t('wms:TargetMaterial')}
										placeholder={t('wms:TargetMaterial')}
										value={targetMaterial}
										options={
											materials
												? materials.map(mat => {
														return { value: mat.id, label: mat.desc }
												  })
												: []
										}
										onChange={value => setTargetMaterial(value)}
										width='100%'
										margin='16px 0 0 0'
										disabled={!updateContent.enduser.id && !sourceMaterial}
										data-cy='select-target-material'
									/>
									<Input
										message={inputMessage()}
										messageType={inputMessageType()}
										type='number'
										min='1'
										max='99999'
										label={t('wms:ExpectedQuantity')}
										value={expectedQuantity}
										onChange={e => handleExepctedQuantity(e)}
										width='100%'
										margin='16px 0 16px 0'
										disabled={!targetMaterial}
										data-cy='input-expected-quantity'
									/>
									<Button
										fontSize='medium'
										margin='auto'
										color='white'
										backgroundColor='secondary'
										disabled={expectedQuantity <= 0 || expectedQuantity.length >= 6}
										onClick={() => {
											handleAddToModifList()
										}}
										data-cy='button-add-modiflist'>
										{t('wms:AddMaterial')}
									</Button>
								</>
							) : (
								<FlexView
									width='100%'
									margin='0'
									flexDirection='row'
									flexWrap='wrap'
									padding='0'
									justifyContent='space-between'>
									<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:EndUser')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{updateContent && updateContent.enduser ? updateContent.enduser : '*****'}
										</FlexView>
									</FlexView>
									<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:Ownership')}:
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{updateContent && updateContent.content.length && updateContent.content[0].ownerhsip
												? updateContent.content[0].ownerhsip
												: '*****'}
										</FlexView>
									</FlexView>
									<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:SourceMaterial')}:
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{updateContent && updateContent.content.length && updateContent.content[0].midsource
												? getSourceMaterial(updateContent.content[0].midsource)
												: '*****'}
										</FlexView>
									</FlexView>
									<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:TargetMaterial')}:
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{updateContent && updateContent.content.length && updateContent.content[0].mid
												? getTargetMaterial(updateContent.content[0].mid)
												: '*****'}
										</FlexView>
									</FlexView>
									<Input
										message={inputMessage()}
										messageType={inputMessageType()}
										type='number'
										min='1'
										max='99999'
										label={t('wms:ExpectedQuantity')}
										value={expectedQuantity}
										onChange={e => handleExepctedQuantity(e)}
										width='100%'
										margin='16px 0 16px 0'
										data-cy='input-expected-quantity'
									/>
									<Button
										fontSize='medium'
										margin='auto auto 0 auto'
										color='white'
										backgroundColor='secondary'
										disabled={expectedQuantity <= 0 || expectedQuantity.length >= 6}
										onClick={() => {
											handleAddToModifList()
										}}
										data-cy='button-add-modiflist'>
										{t('wms:AddMaterial')}
									</Button>
								</FlexView>
							)}
						</Card>
					</FlexView>
					<FlexView
						maxWidth='calc(60% - 16px)'
						minWidth='calc(60% - 16px)'
						minHeight='100%'
						maxHeight='100%'
						margin='0'>
						<Card
							margin='0'
							width='calc(100% - 48px)'
							minHeight='calc(85% - 20px)'
							maxHeight='calc(85% - 20px)'
							flex='1'
							data-cy='card-modifprod-info'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:ModifiedProductInfo')}
							</FlexView>
							<FlexView width='100%' flexDirection='row' margin='8px 0' justifyContent='space-between'>
								<Input
									info
									type='text'
									label={t('wms:Name')}
									placeholder={t('wms:Name')}
									value={updateContent && updateContent.name}
									onChange={e => {
										setUpdateContent({ ...updateContent, name: e.target.value })
									}}
									margin='16px 20px 0 0'
									minWidth='30%'
									max='20'
									data-cy='input-modifproduct-name'
								/>
								<Input
									info
									type='text'
									label={t('wms:MPReference')}
									placeholder={t('wms:MPReference')}
									value={updateContent && updateContent.erref}
									onChange={e => {
										setUpdateContent({ ...updateContent, erref: e.target.value })
									}}
									margin='16px 20px 0 0'
									minWidth='30%'
									max='20'
									data-cy='input-modifproduct-name'
								/>
								<DatePicker
									timePicker
									label={t('wms:ExpectedDate')}
									value={updateContent && moment(updateContent.expecteddate)}
									onChange={value => handleDateChange(value)}
									margin='16px 0 0 0'
									data-cy='date-picker'
								/>
							</FlexView>
							<FlexView width='100%' height='250px' style={{ overflowY: 'scroll', overflowX: 'auto' }}>
								{updateContent && updateContent.content.length
									? updateContent.content.map((c, index) => (
											<FlexView
												key={index}
												width='100%'
												margin='0'
												flexDirection='row'
												padding='8px 0'
												style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
												<FlexView fontSize='small' margin='auto 0' flexDirection='row' width='50%'>
													{materials &&
														materials.map((mat, i) =>
															mat.id === c.mid ? <span key={i}>{mat.desc}</span> : null
														)}
												</FlexView>
												<FlexView fontSize='small' margin='auto auto' flexDirection='row' width='16%'>
													{`${t('wms:Quantity')}: ${c.quantity}`}
												</FlexView>
												<FlexView
													fontSize='tiny'
													color={STATUS.color[c.status]}
													style={{
														borderLeft: `2px solid ${theme.colors[STATUS.color[c.status]]}`,
														lineHeight: '24px'
													}}
													margin='auto 0 auto auto'
													padding='8px 16px'>
													{t(`wms:${STATUS.literal[c.status]}`)}
												</FlexView>
												<Button
													fontSize='small'
													margin='auto 0 auto auto'
													padding='8px 16px'
													color='white'
													backgroundColor='error'
													disabled={!(c.status === STATUS.code.NOTSTARTED || !c.status)}
													onClick={() => {
														handleDelete(index)
													}}
													data-cy='button-remove-content'>
													<Icon
														name='trash'
														color='white'
														height='16px'
														width='16px'
														margin='0'
														tooltip={`${t('wms:RemoveMaterial')}`}
													/>
												</Button>
											</FlexView>
									  ))
									: null}
							</FlexView>
						</Card>
					</FlexView>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='4px 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0 8px 0 0'
						style={{ marginLeft: 'calc(40% + 16px)' }}
						color='white'
						backgroundColor='secondary'
						onClick={() => {
							fileUploader.current.click()
						}}
						data-cy='button-fileupload'>
						{t('wms:UploadMPFile')}
						<Icon name='upload' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
					<FlexView
						style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
						flex='1'
						margin='0 8px 0 0'
						flexDirection='row'>
						{mpFile && mpFile.name}
					</FlexView>
					<input
						style={{ display: 'none' }}
						id='mpFileInput'
						type='file'
						accept='application/pdf'
						ref={fileUploader}
						onChange={e => {
							uploadFile(e)
						}}
					/>
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='success'
						disabled={validateData()}
						onClick={() => handleUpdate()}
						data-cy='button-update-modifproduct-list'>
						{t('wms:Update')}
						<LoadingOverlay visible={mpUpload || fileUpload} />
						<Icon name='save' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default UpdateModifProdListModal
