/** @format */

import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Select } from 'components/form'
import { ReportDataContext } from 'apps/wms/stores/ReportDataStore'
import Input from 'components/form/Input'
import DialogModal from 'apps/wms/components/forms/DialogModal'

const ActionButton = styled(Button)`
	border: none;
	&:hover {
		box-shadow: none;
	}
`

const InstrumentEditor = ({ isOpen, onOutsideClick, onInstrumentChange, report, allowEdit }) => {
	const { t } = useTranslation()
	const { availableInstruments, instrumentTypes, getNewInstrumentCase, releaseInstruments } =
		useContext(ReportDataContext)
	const [confirmInstrumentUpdate, setConfirmInstrumentUpdate] = useState(false)
	const [selectedInstruments, setSelectedInstruments] = useState([])
	const [currentAvailableList, setCurrentAvailableList] = useState([])
	const [instrumentType, setInstrumentType] = useState('null')
	const [instrumentSearchList, setInstrumentSearchList] = useState([])
	const [hasChanged, setHasChanged] = useState(false)
	const [searchkey, setSearchkey] = useState([])
	const [loading, setLoading] = useState(false)
	const parentRef = useRef(null)

	useEffect(() => {
		setSelectedInstruments([...report.instrumentlist])
		const uniq = availableInstruments.filter(
			avInst => report.instrumentlist.findIndex(currInst => avInst.id === currInst.id) === -1
		)
		setCurrentAvailableList([...uniq])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report])

	useEffect(() => {
		const alist =
			instrumentType !== 'null'
				? [...currentAvailableList.filter(inst => inst.type === instrumentType)]
				: [...currentAvailableList]
		const blist = alist.filter(al => al.reference.includes(searchkey))
		setInstrumentSearchList(blist)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instrumentType, searchkey, selectedInstruments])

	const onRemoveEquipment = instrument => {
		if (instrument.id !== null) {
			const index = selectedInstruments.findIndex(inst => inst.id === instrument.id)
			const inst = selectedInstruments.splice(index, 1)
			setSelectedInstruments([...selectedInstruments])
			setCurrentAvailableList([...currentAvailableList, ...inst])
			setHasChanged(true)
		}
	}

	const onAddEquipment = instrument => {
		if (instrument.id !== null) {
			const index = currentAvailableList.findIndex(inst => inst.id === instrument.id)
			const inst = currentAvailableList.splice(index, 1)
			setSelectedInstruments([...selectedInstruments, ...inst])
			setCurrentAvailableList([...currentAvailableList])
			setHasChanged(true)
		}
	}

	const onSetEquipmentType = value => {
		setInstrumentType(value)
	}

	const onSaveEquipList = () => {
		setConfirmInstrumentUpdate(true)
	}

	const changeInstruments = () => {
		setLoading(true)
		const instrumentIds = selectedInstruments.map(si => si.id)
		getNewInstrumentCase(instrumentIds)
			.then(response => onInstrumentChange(report, response, selectedInstruments) && releaseInstruments())
			.catch(e => console.error(e))
			.finally(setLoading(false))
		return true
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card
				ref={parentRef}
				borderRadius='0'
				backgroundColor='background'
				maxWidth='calc(30%)'
				height='calc(100%)'
				maxHeight='100vh'
				flex='1'
				margin='0 0 0 auto'
				padding='0'
				alignItems='center'
				alignSelf='flex-end'
				style={{ position: 'relative', overflowY: 'scroll' }}>
				<LoadingOverlay visible={loading} borderRadius='0' />

				<FlexView
					width='calc(100% - 32px)'
					fontWeight='bold'
					fontSize='24px'
					padding='16px 16px 8px'
					flexDirection='row'
					justifyContent='space-between'>
					{`${t('wms:EquipmentsUsed')}`}
					<ActionButton
						fontSize='tiny'
						padding='4px 0'
						outline='true'
						color='secondary'
						onClick={() => onOutsideClick()}
						margin='0 0 0 8px'>
						<Icon name='cross' color='secondary' height='24px' width='24px' margin='0' />
					</ActionButton>
				</FlexView>
				<FlexView width='calc(100% - 32px)' fontSize='16px' padding='0 16px 16px'>
					{t('wms:Item')}: {report.valid}
				</FlexView>
				<FlexView width='calc(100% - 32px)' fontSize='16px' padding='0 16px 16px'>
					<FlexView
						fontWeight='bold'
						flexDirection='row'
						justifyContent='space-between'
						width='100%'
						margin='8px 0 24px'>
						{t('wms:EquipmentsSelected')}
						{allowEdit ? (
							<Button
								fontSize='tiny'
								padding='4px 8px'
								outline='true'
								color='secondary'
								disabled={!hasChanged || !selectedInstruments.length > 0}
								onClick={() => onSaveEquipList()}
								margin='0 0 0 8px'>
								{t('wms:Update')}
								<Icon name='save' color='secondary' height='12px' width='12px' margin='0 0 0 8px' />
							</Button>
						) : null}
					</FlexView>
					{selectedInstruments.length ? (
						selectedInstruments.map(instrument => (
							<FlexView
								key={instrument.id}
								width='calc(100%)'
								flexDirection='row'
								justifyContent='space-between'
								fontSize='16px'
								padding='0'
								margin='0 0 16px 0'>
								<FlexView fontSize='small' fontWeight='bold' color='#303030' width='30%'>
									{instrument.reference}
								</FlexView>
								<FlexView fontSize='small' width='30%' alignItems='flex-start'>
									{instrument.type}
								</FlexView>
								{allowEdit ? (
									<ActionButton
										fontSize='tiny'
										padding='0'
										outline='true'
										color='error'
										onClick={() => onRemoveEquipment(instrument)}
										margin='0 0 0 8px'>
										<Icon name='cross' color='error' height='20px' width='20px' margin='0' />
									</ActionButton>
								) : null}
							</FlexView>
						))
					) : (
						<FlexView fontWeight='bold' width='100%' color='metalic' alignItems='center' margin='24px 0'>
							{t('wms:EquipmentsNotSelected')}
						</FlexView>
					)}
				</FlexView>
				{allowEdit ? (
					<FlexView width='calc(100% - 32px)' fontSize='16px' padding='16px 16px 16px'>
						<FlexView fontWeight='bold' width='100%' margin='8px 0'>
							{t('wms:EquipmentSelect')}
						</FlexView>

						<Select
							placeholder={t('wms:EquipmentType')}
							value={instrumentType}
							options={[
								{ label: t('wms:EquipmentAllTypes'), value: 'null' },
								...instrumentTypes.map(it => ({
									label: it,
									value: it
								}))
							]}
							onChange={value => onSetEquipmentType(value)}
							width='100%'
							inline={false}
							margin='4px 0'
							fontSize='small'
						/>
						<Input
							placeholder={`${t('wms:EquipmentSearch')}`}
							margin='4px 0 24px'
							value={searchkey}
							onChange={e => {
								setSearchkey(e.target.value)
							}}
							fontSize='small'
							width='100%'
						/>
						{instrumentSearchList.length ? (
							instrumentSearchList.map(instrument => (
								<FlexView
									key={instrument.id}
									width='calc(100%)'
									flexDirection='row'
									justifyContent='space-between'
									padding='0'
									margin='0 0 16px 0'>
									<FlexView fontSize='small' fontWeight='bold' color='#303030' width='30%'>
										{instrument.reference}
									</FlexView>
									<FlexView fontSize='small' width='30%' alignItems='flex-start'>
										{instrument.type}
									</FlexView>
									{allowEdit ? (
										<ActionButton
											fontSize='tiny'
											padding='0'
											outline='true'
											color='success'
											onClick={() => onAddEquipment(instrument)}
											margin='0 0 0 8px'>
											<Icon name='add' color='success' height='20px' width='20px' margin='0' />
										</ActionButton>
									) : null}
								</FlexView>
							))
						) : (
							<FlexView fontWeight='bold' width='100%' color='metalic' alignItems='center' margin='24px 0'>
								{t('wms:EquipmentsNotAvailable')}
							</FlexView>
						)}
					</FlexView>
				) : null}
			</Card>
			<DialogModal
				isOpen={confirmInstrumentUpdate}
				text={`${t(`wms:ConfirmEquipmentsUpdate`)} ${report.valid}`}
				onConfirm={() => changeInstruments() && setConfirmInstrumentUpdate(false)}
				onCancel={() => setConfirmInstrumentUpdate(false)}
			/>
		</Modal>
	)
}

export default InstrumentEditor
