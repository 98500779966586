/** @format */

import { FlexView } from 'components/common'
import React, { useMemo, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '../../../components/common'
import ReportsInfo from '../containers/assets/refReports/ReportsInfo'
import ReportsList from '../containers/assets/refReports/ReportsList'
import UploadLogoModal from '../containers/assets/refReports/UploadLogoModal'
import PageTemplate from '../containers/common/PageTemplate'
import { AssetsReportRefContext } from '../stores/AssetsReportRefStore'
import { assets, genParams } from '../utils/hubs'

const AssetsReportRefPage = () => {
	const { t } = useTranslation()
	const { selectedReport } = useContext(AssetsReportRefContext)
	const [isOpen, setIsOpen] = useState(false)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:ReportsManagement'),
				path: '/wms/assets/reportsmanagement'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' height='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='55%' margin='0 0 24px 0' flexDirection='row' alignItems='center' width='55%'>
					<FlexView justifyContent='space-between' fontSize='40px' fontWeight='bold' width='100%'>
						{t('wms:ReportsManagement')}
					</FlexView>
					<Button
						backgroundColor='secondary'
						color='white'
						fontSize='medium'
						title={t('wms:ManageLogoSubtitle')}
						margin='auto 8px auto 0'
						onClick={() => setIsOpen(true)}
						elevation='none'>
						{t('wms:ManageLogo')}
						<Icon name='add-image' width='24px' height='24px' margin='0 0 0 8px' color='white' />
					</Button>
				</FlexView>

				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='55%' margin='0 8px 0 0'>
						<ReportsList />
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{selectedReport && <ReportsInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
			<UploadLogoModal
				isOpen={isOpen}
				title={t('wms:ManageLogo')}
				subtitle={t('wms:ManageLogoSubtitle')}
				onOutsideClick={() => setIsOpen(false)}></UploadLogoModal>
		</PageTemplate>
	)
}

export default AssetsReportRefPage
