import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { Button, LoadingOverlay, FlexView, Link } from 'components/common'
import LogoImage from 'assets/images/logo.svg'
import { Input } from 'components/form'
import styled from 'styled-components'

const Logo = styled.img`
  width: 100px;
  margin: 16px auto;
`

const Version = styled.div`
  font-family: 'Noto Sans';
  margin: 10px 0px -5px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.lightGray};
  font-weight: bold;
`

const LoginForm = () => {
  const [formState, setFormState] = useState({
    username: '',
    password: ''
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { signIn, forgotPassword } = useContext(UserContext)

  const handleInputChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const loggedIn = signIn && await signIn(formState.username, formState.password)
      !loggedIn && setLoading(false)
    }
    catch (e) {
      setErrorMessage(e.data || t(`errors.${e.message}`))
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingOverlay borderRadius="card" visible={isLoading}/>
      <Logo src={LogoImage} alt="Application Logo" />
      <form style={{ width: '100%'}} onSubmit={onSubmit}>
        <FlexView flexDirection="column" margin="8px 0px" width="100%">
          <FlexView margin="8px 0px" fontSize="big" color="darkGray" fontWeight="bold">{t('login.label')}</FlexView>
          <FlexView margin="0px 0px 8px" fontSize="small" color="gray">{t('login.instruction')}</FlexView>
          <Input
            name="username"
            label={t('Username')}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            type="text"
            width="100%"
            value={formState.username}
            onChange={handleInputChange}
            error={!!errorMessage}
            message={errorMessage}
            messageType="error"
            required
          />
          <Input
            type="password"
            name="password"
            label={t('Password')}
            width="100%"
            onChange={handleInputChange}
            required
          />
        </FlexView>
        <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" width="100%" margin="10px 0px">
          <Link type="button" onClick={forgotPassword}>{t('Change Password')}</Link>
          <Button type="submit" elevation="medium" backgroundGradient="main" color="white" margin="0px" width="40%">
            {t('Login')}
          </Button>
        </FlexView>
      </form>
      <Version>v{process.env.REACT_APP_VERSION}</Version>
    </>
  )
}

export default LoginForm