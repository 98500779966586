import React from 'react'
import { FlexView } from './components/common'
import Header from './containers/layout/Header'
import Home from './pages/Home'
import Login from './pages/Login'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './containers/router/PrivateRoute'

const Router = () => {

  return (
    <FlexView height="100vh" width="100%">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path='/login'>
            <Login />
          </Route>
          <PrivateRoute path='/'>
            <Home />
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </FlexView>
  )
}

export default Router