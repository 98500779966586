/** @format */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Checkbox } from 'components/form'
import { useTranslation } from 'react-i18next'
import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { toast } from 'react-toastify'

const InspTypeCardConfig = () => {
	const { t } = useTranslation()

	const {
		setSelectedInspection,
		selectedInspection,
		updateInspectionType,
		measureConfig,
		newMeasurementConfig,
		triggerReload
	} = useContext(AssetsInspSettingsContext)

	const [isLoadingResponse, setIsLoadingResponse] = useState(false)
	const [inEditionData, setInEdtitionData] = useState(null)

	const availableProps = useMemo(
		() => [
			{ name: 'Name', enabled: false },
			{ name: 'MrRef', enabled: false },
			{ name: 'RrRef', enabled: false },
			{ name: 'ErRef', enabled: false },
			{ name: 'FieldName', enabled: false },
			{ name: 'RigName', enabled: false },
			{ name: 'WellName', enabled: false },
			{ name: 'TpartyName', enabled: false },
			{ name: 'TpartyLocation', enabled: false },
			{ name: 'LtpaNumber', enabled: false },
			{ name: 'DeliveryLocation', enabled: false }
		],
		[]
	)

	useEffect(() => {
		selectedInspection
			? setInEdtitionData({
					...selectedInspection,
					isMeasureEnabled: selectedInspection.config_measurement !== 0,
					config_measurement: measureConfig.find(m => m.id === selectedInspection.config_measurement),
					config_summary: selectedInspection.config_summary
						? selectedInspection.config_summary
						: availableProps.map(p => ({
								name: p.name,
								enabled: selectedInspection.config_summary
									? !!selectedInspection.config_summary.find(c => c.name === p.name)
									: false
						  }))
			  })
			: setInEdtitionData(null)
	}, [selectedInspection, availableProps, measureConfig])

	const toggleSummary = name => {
		const summIndex = inEditionData.config_summary.findIndex(summ => summ.name === name)
		if (summIndex === -1) return

		let config = [...inEditionData.config_summary]
		config[summIndex].enabled = !config[summIndex].enabled

		const newData = {
			...inEditionData,
			config_summary: [...config]
		}

		setInEdtitionData(newData)
	}

	const onUpdateInspectionTypeSummary = async () => {
		try {
			setIsLoadingResponse(true)
			await updateInspectionType({
				...inEditionData,
				config_measurement: inEditionData.config_measurement ? inEditionData.config_measurement.id : 0
			})
			setIsLoadingResponse(false)
			triggerReload()
			setSelectedInspection(null)
			toast.success(t('wms:InspectionTypeUpdatedSuccessfully'))
		} catch (error) {
			setIsLoadingResponse(false)
			console.error(error)
		}
	}

	const onUpdateInspectionTypeMeasureConfig = async () => {
		try {
			setIsLoadingResponse(true)

			const aux = measureConfig.find(
				m =>
					m.length === inEditionData.config_measurement.length &&
					m.out_diameter === inEditionData.config_measurement.out_diameter &&
					m.int_diameter === inEditionData.config_measurement.int_diameter &&
					m.wall_thickness === inEditionData.config_measurement.wall_thickness
			)

			const newMeasurementData = aux ? aux : await newMeasurementConfig(inEditionData.config_measurement)
			await updateInspectionType({ ...inEditionData, config_measurement: newMeasurementData.id })
			setIsLoadingResponse(false)
			triggerReload()
			setSelectedInspection(null)
			toast.success(t('wms:InspectionTypeUpdatedSuccessfully'))
		} catch (error) {
			setIsLoadingResponse(false)
			console.error(error)
		}
	}

	return (
		<Card
			margin='0'
			height='100%'
			width='calc(100% - 48px)'
			data-cy='types-list-card'
			style={{ overflowY: 'scroll', overflowX: 'hidden', position: 'relative' }}>
			<LoadingOverlay visible={isLoadingResponse} borderRadius='card' />

			<FlexView width='100%' flexDirection='row' fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
				{t('wms:InspectionOptions')}
			</FlexView>

			{inEditionData ? (
				<FlexView flexDirection='row' flexWrap='wrap' justifyContent='space-between'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 4px 0'>
						{t('wms:InspectionSummary')}
					</FlexView>
					{inEditionData.config_summary.map((a, index) => (
						<Checkbox
							width='50%'
							fontSize='small'
							key={index}
							checked={a.enabled}
							onChange={v => toggleSummary(a.name)}
							label={a.name}
						/>
					))}

					<FlexView width='100%' margin='0' padding='0'>
						<Button
							fontSize='small'
							margin='16px 0 0 auto'
							backgroundColor='success'
							color='white'
							onClick={() => onUpdateInspectionTypeSummary()}
							data-cy='button-save-config'>
							{t('wms:Save')} {t('wms:InspectionSummary')}
							<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
						</Button>
					</FlexView>

					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 4px 0'>
						{t('wms:InspectionMeasureConfig')}
					</FlexView>

					{inEditionData.config_measurement ? (
						<>
							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.config_measurement.length}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										config_measurement: { ...inEditionData.config_measurement, length: v }
									})
								}
								label='Length'
							/>
							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.config_measurement.int_diameter}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										config_measurement: { ...inEditionData.config_measurement, int_diameter: v }
									})
								}
								label='Internal Diameter'
							/>

							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.config_measurement.out_diameter}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										config_measurement: { ...inEditionData.config_measurement, out_diameter: v }
									})
								}
								label='Outer Diameter'
							/>
							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.config_measurement.wall_thickness}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										config_measurement: { ...inEditionData.config_measurement, wall_thickness: v }
									})
								}
								label='Wall Thickness'
							/>

							<FlexView width='100%' margin='0' padding='0'>
								<Button
									fontSize='small'
									margin='16px 0 0 auto'
									backgroundColor='success'
									color='white'
									onClick={() => onUpdateInspectionTypeMeasureConfig()}
									data-cy='button-save-config'>
									{t('wms:Save')} {t('wms:InspectionMeasureConfig')}
									<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
								</Button>
							</FlexView>
						</>
					) : (
						<FlexView
							width=' 100%'
							fontWeight='bold'
							color='lightGray'
							margin='32px 0'
							alignItems='center'
							style={{ alignContent: 'center', textAlign: 'center' }}
							justifyContent='center'
							data-cy='no-reports-list'>
							{t('wms:MeasurementDisabled')}
						</FlexView>
					)}
				</FlexView>
			) : (
				<FlexView
					width=' 100%'
					fontWeight='bold'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					style={{ alignContent: 'center' }}
					justifyContent='center'
					data-cy='no-reports-list'>
					{t('wms:NoInspectionTypeSelected')}
				</FlexView>
			)}
		</Card>
	)
}

export default InspTypeCardConfig
