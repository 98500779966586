/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getItemsByValid: (wid, searchValid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${wid}/byitem?valid=${encodeURIComponent(searchValid)}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.content)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getItemsByPuuid: (wid, searchPuuid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${wid}/byitem?puuid=${encodeURIComponent(searchPuuid)}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.content)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	generateDashaboard: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/dashboard/${wid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.dashboard)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getOwnership: (uri, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${uri}`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.content)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMaterialsBySearch: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`content/${wid}`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.content)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getOwnershipBySearch: (wid, lastOwershipId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${wid}/byownership/${lastOwershipId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.content)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getLevelContents: (wid, levelId, ownershipid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(
					ownershipid ? `bundles/${wid}/at/${levelId}?ownership=${ownershipid}` : `bundles/${wid}/at/${levelId}`,
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				)
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.bundles)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	exportExcel: (wid, levelId, owid, language, currentLength, currentWeight, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(
					`content/${wid}/export/${levelId}/${
						owid && owid.length > 0 ? owid[0] : owid
					}/${language}?unitlength=${encodeURIComponent(currentLength)}&unitweight=${encodeURIComponent(
						currentWeight
					)}`,
					params,
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				)
				.then(response => {
					if (response.status === 200) {
						resolve(response)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	exportItems: (wid, language, currentLength, currentWeight, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(
					`content/${wid}/export/${language}?unitlength=${encodeURIComponent(
						currentLength
					)}&unitweight=${encodeURIComponent(currentWeight)}`,
					{
						headers: {
							Authorization: `Bearer ${token}`
						},
						responseType: 'blob'
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	downloadCertificate: (params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post('content/download/certificate', params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	downloadReport: (url, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(url, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadLevelContent: (wid, local, fileName, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${wid}/download/${local}/excel/${fileName}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
