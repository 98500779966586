/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getConfig: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/material/${wid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMaterialDescription: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/material/${wid}/description`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.materialdescriptions)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMaterialEndusers: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/material/${wid}/enduserlist`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.enduserlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	importMaterials: (enduser, wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(
					`/material/${wid}/syncpipedatamaterials?enduser=${encodeURIComponent(enduser.id)}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	createMaterial: (payload, wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/material/${wid}/value`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateMaterial: (mid, payload, wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`/material/${wid}/value/${mid}`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteMaterial: (mid, wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`/material/${wid}/value/${mid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					resolve(response.data)
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateMaterialConfiguration: (payload, wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`/material/${wid}`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
