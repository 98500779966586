/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UserContext } from 'stores/UserStore'
import periodic from '../api/periodic'
import { WMSContext } from './WmsStore'
import _ from 'lodash'
import moment from 'moment'

export const PerInspContext = React.createContext()

export const PerInspectionProvider = ({ children }) => {
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const [dateSelected, setDateSelected] = useState(null)
	const [datesToFilter, setDatesToFilter] = useState(null)
	const [rackName, setRackName] = useState(null)
	const [inspections, setInspections] = useState(null)
	const [selectedInspection, setSelectedInspection] = useState(null)
	const [filteredInspections, setFilteredInspections] = useState(null)
	const [loadingPerInspList, setLoadingPerInspList] = useState(false)
	const [openManageParts, setOpenManageParts] = useState(false)
	const [searchInfo, setSearchInfo] = useState(false)
	const [updateParts, setUpdateParts] = useState(false)
	const [loadingNewPart, setLoadingNewPart] = useState(false)
	const [loadingInfo, setLoadingInfo] = useState(false)
	const [loadingFilters, setLoadingFilters] = useState(false)
	const [isEditorOpen, setIsEditorOpen] = useState(false)
	const [uploadPerList, setUploadPerList] = useState(false)
	const [parts, setParts] = useState('')
	const [summarized, setSummarized] = useState(null)
	const [now, setNow] = useState(moment().format())
	const [nextThirtyDays, setNextThirtyDays] = useState(moment(now).add('30', 'days').format())

	const getInspections = () =>
		new Promise((resolve, reject) => {
			periodic
				.getInspections(warehouse.id, token)
				.then(response => {
					let dates = _.map(response, res => {
						return { value: res.id, label: res.entrancedate }
					})
					let filtered = _.filter(response, insp => {
						let date = insp.nextinspdate
						return moment(date).format() < nextThirtyDays && moment(date).format() >= now
					})
					setFilteredInspections(filtered)
					setDatesToFilter(dates)
					setInspections(response)
					resolve()
				})
				.catch(e => {
					toast.error(t('wms:ErrorGettingPerInsp'))
					console.error(e)
					reject(e)
				})
		})

	const getSummarized = () =>
		new Promise((resolve, reject) => {
			periodic
				.getSummarizedInfo(warehouse.id, selectedInspection.id, token)
				.then(response => {
					setSummarized(response)
					resolve()
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingSummarized')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
					reject(e)
				})
		})

	const getPerInspDetails = id =>
		new Promise((resolve, reject) => {
			id &&
				periodic
					.getPeriodicDetails(warehouse.id, id, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorGettingInspDetails')} [ ${e.status} ]: ${e.data}`)
					})
		})

	const setNewQuantity = () =>
		new Promise((resolve, reject) => {
			let payload = {
				number: parts,
				content: selectedInspection.content
			}
			periodic
				.setNewQuantity(warehouse.id, selectedInspection.id, payload, token)
				.then(response => {
					setSelectedInspection(null)
					resolve(response)
					toast.success(`${t('wms:PeriodicInspectionSuccess')} ${selectedInspection.entranceid}`)
					setOpenManageParts(false)
					setFilteredInspections(null)
					setSummarized(null)
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorUpdatingParts')} [${e.status}]: ${e.data}`)
					reject(e)
					setSelectedInspection(null)
					setOpenManageParts(false)
					setFilteredInspections(null)
					setSummarized(null)
				})
		})

	const handleReopen = content =>
		new Promise((resolve, reject) => {
			let payload = {
				pilcid: content.pilcid
			}
			periodic
				.reopenPeriodic(warehouse.id, payload, token)
				.then(response => {
					toast.success(t(`wms:SuccessfullyReopenContent`))
					resolve(true)
					setUploadPerList(true)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorReopenContent')} [ ${e.status} ]: ${e.data}`)
				})
				.finally(() => {})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingPerInspList(true)
				setLoadingFilters(true)
				await getInspections()
			} catch (e) {
				console.error(e)
				setLoadingPerInspList(false)
				setLoadingFilters(false)
			} finally {
				setLoadingPerInspList(false)
				setLoadingFilters(false)
				setUploadPerList(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadPerList, warehouse])

	useEffect(() => {
		if (searchInfo) {
			const hydrate = async () => {
				try {
					setLoadingInfo(true)
					await getSummarized()
				} catch (e) {
					console.error(e)
					setSearchInfo(false)
					setLoadingInfo(false)
				} finally {
					setSearchInfo(false)
					setLoadingInfo(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchInfo])

	useEffect(() => {
		if (updateParts) {
			const hydrate = async () => {
				try {
					setLoadingNewPart(true)
					await setNewQuantity()
				} catch (e) {
					console.error(e)
					setLoadingNewPart(false)
					setOpenManageParts(false)
					setUpdateParts(false)
				} finally {
					setLoadingNewPart(false)
					setOpenManageParts(false)
					setUpdateParts(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateParts])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingPerInspList(true)
				await getInspections()
			} catch (e) {
				setLoadingPerInspList(false)
				console.error(e)
			} finally {
				setLoadingPerInspList(false)
			}
		}
		!updateParts && hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateParts, warehouse])

	useEffect(() => {
		setSearchInfo(false)
		setSelectedInspection(null)
		setSummarized(null)
	}, [uploadPerList, warehouse])

	const renderStore = (
		<PerInspContext.Provider
			value={{
				dateSelected,
				setDateSelected,
				datesToFilter,
				setDatesToFilter,
				rackName,
				setRackName,
				loadingPerInspList,
				setLoadingPerInspList,
				openManageParts,
				setOpenManageParts,
				parts,
				setParts,
				inspections,
				setInspections,
				selectedInspection,
				setSelectedInspection,
				filteredInspections,
				setFilteredInspections,
				summarized,
				setSummarized,
				searchInfo,
				setSearchInfo,
				updateParts,
				setUpdateParts,
				loadingNewPart,
				setLoadingNewPart,
				now,
				setNow,
				nextThirtyDays,
				setNextThirtyDays,
				loadingInfo,
				setLoadingInfo,
				loadingFilters,
				setLoadingFilters,
				setNewQuantity,
				getInspections,
				isEditorOpen,
				setIsEditorOpen,
				getPerInspDetails,
				handleReopen
			}}>
			{children}
		</PerInspContext.Provider>
	)
	return renderStore
}
