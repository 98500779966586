/** @format */

import { ModifiedProductContext } from 'apps/wms/stores/ModifProductStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView } from 'components/common'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { STATUS } from '../../utils/enum'
import _ from 'lodash'
import theme from 'utils/theme'
import { UserContext } from 'stores/UserStore'

const ModifiedProductInfo = () => {
	const { t, i18n } = useTranslation()
	const { selectedList, handleReopen } = useContext(ModifiedProductContext)
	const listDeadSpace = 390
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)
	const { user } = useContext(UserContext)

	const allowReopen = true

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			ref={listRef}
			margin='0'
			style={{ position: 'relative' }}
			data-cy='card-modifiedproduct-info'>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:ModifiedProductInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:ReferenceMP')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedList.erref}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:ExpectedDate')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(selectedList.expecteddate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:EndUser')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedList.enduser}
					</FlexView>
				</FlexView>
			</FlexView>
			{selectedList && selectedList.planrev && selectedList.planresp && selectedList.plandate ? (
				<FlexView
					width='100%'
					margin='0'
					flexDirection='row'
					flexWrap='wrap'
					padding='0'
					justifyContent='space-between'>
					<FlexView width='20%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:Revision')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedList.planrev}
						</FlexView>
					</FlexView>
					<FlexView width='30%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:UpdatedBy')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedList.planresp}
						</FlexView>
					</FlexView>
					<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:On')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{DATE_FORMAT(selectedList.plandate, i18n.language)}
						</FlexView>
					</FlexView>
				</FlexView>
			) : (
				''
			)}
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'auto' }}>
				<FlexView flexDirection='row'>
					<FlexView
						width='100%'
						margin='0'
						flexDirection='column'
						padding='8px 0'
						data-cy='modifproduct-list-item'>
						{selectedList &&
							selectedList.content.length > 0 &&
							selectedList.content.map((content, index) => (
								<FlexView key={index} flexDirection='row' width='100%' justifyContent='space-between'>
									<FlexView
										fontSize='small'
										width='60%'
										margin='0px'
										flexDirection='column'
										alignItems='flex-start'
										justifyContent='space-around'
										style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
										{content.material}
										<br />
										<strong style={{ padding: '8px 0 0 0' }}>
											{content.ownership
												? `${t('wms:Ownership')}: ${content.ownership} | ${t('wms:Quantity')}: ${
														content.quantity
												  }`
												: `${t('wms:Ownership')}: ***** | ${t('wms:Quantity')}: ${content.quantity}`}
										</strong>
									</FlexView>
									<FlexView
										width='35%'
										margin='0'
										padding='0'
										flexDirection='row'
										flexWrap='wrap'
										justifyContent='center'>
										<FlexView
											key={index}
											fontSize='tiny'
											color={STATUS.color[content.status]}
											style={{
												borderLeft: `2px solid ${theme.colors[STATUS.color[content.status]]}`,
												alignSelf: 'center',
												lineHeight: '24px'
											}}
											margin='0 0 0 auto'
											padding='8px 16px'>
											{t(`wms:${STATUS.literal[content.status]}`)}
										</FlexView>
										{allowReopen &&
										content.status === STATUS.code.RUNNING &&
										_.find(user.roles, u => u === 'wmsadmin') ? (
											<Button
												fontSize='tiny'
												margin='0 0 0 auto'
												color='white'
												backgroundColor='secondary'
												onClick={() => handleReopen(content)}>
												{t('wms:Reopen')}
											</Button>
										) : null}
									</FlexView>
								</FlexView>
							))}
					</FlexView>
				</FlexView>
			</FlexView>
		</Card>
	)
}

export default ModifiedProductInfo
