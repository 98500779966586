/** @format */

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView } from 'components/common'

import { MRContext } from '../stores/MRStore'
import PageTemplate from '../containers/common/PageTemplate'
import MrInfo from '../containers/MatReq/MrInfo'
import MrModal from '../containers/MatReq/MrModal'
import MrList from '../containers/MatReq/MrList'
import { genParams, materialReq } from '../utils/hubs'

const MatReqPage = () => {
	const { t } = useTranslation()
	const { selectedMR, setUploadMRList, isUpdate, mrModal, setMrModal } = useContext(MRContext)

	const closeOptionsModal = () => {
		setMrModal(false)
		setUploadMRList(true)
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:MaterialRequisition'),
				path: `/wms/modifproduct${genParams(materialReq)}`
			},
			{
				name: t('wms:Manage'),
				path: '/wms/dispatch/matreq'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:MaterialRequisition')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='55%' margin='0 8px 0 0'>
						<MrList />
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{selectedMR && <MrInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
			<MrModal isOpen={mrModal} onOutsideClick={closeOptionsModal} isUpdate={isUpdate} mrInfo={selectedMR} />
		</PageTemplate>
	)
}

export default MatReqPage
