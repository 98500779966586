/** @format */

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Spinner from 'components/common/Spinner'
import {
	withFlex,
	withWidth,
	withHeight,
	withMargin,
	withPadding,
	withBorderRadius,
	withElevation,
	withFontSize,
	withBackgroundColor,
	withBackgroundGradient,
	withColor
} from 'utils/styled-decorators'
import { useState } from 'react'
import { useEffect } from 'react'

const StyledButton = styled.button`
	-webkit-tap-highlight-color: transparent;
	font-family: 'Noto Sans';
	font-weight: bold;
	white-space: nowrap;
	${withBackgroundColor('lightGray')}
	${withBackgroundGradient('lightGray')}
  ${withWidth()}
  ${withHeight()}
  ${withFlex({
		flexDirection: 'horizontal',
		alignItems: 'center',
		justifyContent: 'center'
	})}
  ${withFontSize('medium')}
  ${withColor('black')}
  ${withBorderRadius('button')}
  ${withMargin('8px 0px')}
  ${withPadding('8px 16px')}
  ${withElevation('none')}
  outline: 0;
	cursor: pointer;
	user-select: none;
	${({ disabled, isLoading }) => disabled && !isLoading && 'opacity: 0.4;'}
	text-align: center;
	border: none;
	transition: all 0.1s linear;
	opacity: 1;
	${({ disabled }) => disabled && 'opacity: 0.4; cursor: not-allowed;'}
	${({ outline }) =>
		outline &&
		css`
			background: transparent;
			border: 2px solid ${({ theme, color }) => theme.colors[color] || theme.colors.metalic};
			${withColor('metalic')}
			box-shadow: none;

			&:hover {
				box-shadow: 0px 0px 4px ${({ theme, color }) => theme.colors[color] || theme.colors.metalic};
			}
		`}

  &:hover {
		background-color: ${({ theme, backgroundColor, hoverBackgroundColor }) =>
			theme.colors[hoverBackgroundColor] || theme.colors[backgroundColor]};
		color: ${({ theme, color, hoverColor }) => theme.colors[hoverColor] || theme.colors[color]};
		* {
			background-color: ${({ theme, color, hoverColor }) => theme.colors[hoverColor] || theme.colors[color]};
		}
	}

	&:active {
		opacity: 0.6;
	}
`

const Button = props => {
	const ref = useRef()
	const [state, setState] = useState({
		height: '',
		width: '',
		showSpinner: false
	})

	useEffect(() => {
		ref && props.isLoading
			? setState({
					width: `${ref.current.offsetWidth}px`,
					height: `${ref.current.offsetHeight}px`,
					showSpinner: true
			  })
			: setState(currentState => ({
					...currentState,
					showSpinner: false
			  }))
	}, [props.isLoading, ref])

	const maintainSize = props.isLoading ? { ...state } : {}

	return (
		<StyledButton ref={props.forwardRef || ref} {...props} {...maintainSize}>
			{state.showSpinner ? (
				<Spinner color={props.color || (props.outline ? 'metalic' : 'black')} size={props.fontSize || 'medium'} />
			) : (
				props.children
			)}
		</StyledButton>
	)
}

Button.propTypes = {
	/**
	 * Change the button to have no background, only an outline that is the same color of the text
	 */
	outline: PropTypes.bool,
	/**
	 * A color key defined in the theme
	 */
	backgroundColor: PropTypes.string,
	/**
	 * A color key defined in the theme
	 */
	hoverBackgroundColor: PropTypes.string,
	/**
	 * A gradient key defined in the theme. Has precedence over backgroundColor if
	 */
	backgroundGradient: PropTypes.string,
	/**
	 * A color key defined in the theme
	 */
	color: PropTypes.string,
	/**
	 * A color key defined in the theme
	 */
	hoverColor: PropTypes.string,
	/**
	 * Disables the button if true
	 */
	disabled: PropTypes.bool,
	/**
	 * If true, swap button content for a loading spinner that matches the defined color
	 */
	isLoading: PropTypes.bool,
	/**
	 * Override default border radius with CSS compliant value or a border key in the theme. Examples: card, button, 8px, 1rem, 40%, 6vw
	 */
	borderRadius: PropTypes.string,
	/**
	 * Override CSS width property. Must be a valid CSS width value as a string
	 */
	width: PropTypes.string,
	/**
	 * Override CSS height property. Must be a valid CSS height value as a string
	 */
	height: PropTypes.string,
	/**
	 * Override CSS margin property. Must be a valid CSS margin value as a string
	 */
	margin: PropTypes.string,
	/**
	 * Override CSS padding property. Must be a valid CSS padding value as a string
	 */
	padding: PropTypes.string,
	/**
	 * Override CSS flex property. Must be a valid CSS flex value as a string
	 */
	flex: PropTypes.string,
	/**
	 * Override CSS flex-wrap property. Must be a valid CSS flex-wrap value as a string
	 */
	flexWrap: PropTypes.string,
	/**
	 * Override CSS flex-direction property. Must be a valid CSS flex-direction value as a string
	 */
	flexDirection: PropTypes.string,
	/**
	 * Override CSS align-items property. Must be a valid CSS align-items value as a string
	 */
	alignItems: PropTypes.string,
	/**
	 * Override CSS align-self property. Must be a valid CSS align-self value as a string
	 */
	alignSelf: PropTypes.string,
	/**
	 * Override CSS justify-content property. Must be a valid CSS justify-content value as a string
	 */
	justifyContent: PropTypes.string,
	/**
	 * A font size key defined in the theme
	 */
	fontSize: PropTypes.string,
	/**
	 * An elevation key defined in the theme
	 */
	elevation: PropTypes.string
}

Button.defaultProps = {
	disabled: false,
	isLoading: false,
	outline: false
}

export default Button
