/** @format */

export const INSPECTION = {
	literal: [
		'Rig Prep',
		'Third Party Workshop',
		'Material Modification',
		'Material Physical Transfer',
		'Receiving Inspection',
		'Spot Inspection',
		'Rig Return',
		'Periodical',
		'Modified Products'
	],
	translateKey: [
		'wms:RigPreparation',
		'wms:ThirdPartyWorkshop',
		'wms:MaterialModification',
		'wms:MaterialPhysicalTransfer',
		'wms:ReceivingInspection',
		'wms:ActivityReport',
		'wms:RigReturn',
		'wms:PeriodicInspection',
		'wms:ModifiedProduct'
	],
	path: ['rigprep/', 'tpartywork/', 'matmodif/', 'phystrans/', 'recinsp/', '', 'rigreturn/', 'perinsp/', 'modifprod/'],
	code: {
		RIGPREP: 0,
		TPWORKSHOP: 1,
		MATMODIF: 2,
		PHYSTRANS: 3,
		RECINSP: 4,
		SPOT: 5,
		RIGRETURN: 6,
		PERIODICAL: 7,
		MODIFPROD: 8
	},
	abbreviation: ['RP', 'TW', 'MM', 'PT', 'R', '', 'RR', 'P', 'MP']
}

export const REPORT_TYPE = {
	literal: [],
	translateKey: [
		'wms:RigPreparation',
		'wms:ThirdPartyWorkshop',
		'wms:MaterialModification',
		'wms:MaterialPhysicalTransfer',
		'wms:ReceivingInspection',
		'wms:ActivityReport',
		'wms:RigReturn',
		'wms:PeriodicInspection',
		'wms:ModifiedProductReports',
		'wms:Dispatch',
		'wms:BackloadManifest',
		'wms:ReceiptNote'
	],
	path: [
		'rigprep/',
		'tpartywork/',
		'matmodif/',
		'phystrans/',
		'recinsp/',
		'',
		'rigreturn/',
		'perinsp/',
		'modifprod/',
		'dispatch/',
		'rigreturn/',
		'batchentrance/'
	],
	reportDownloadURL: [
		(wid, reportId, i18n) => `rigprep/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `tpartywork/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `matmodif/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `ptexcel/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `recinsp/${wid}/excel/${reportId}/${i18n.language}`,
		'',
		(wid, reportId, i18n) => `rigreturn/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `perinsp/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `modifprod/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `dispatch/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `rigreturn/${wid}/backload/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `batchentrance/${wid}/excel/${reportId}/${i18n.language}`
	],
	defectType: [1, 1, 1, 1, 4, 3, 2, 3, 1, 0, 0, 0, 0, 0, 0],
	code: {
		RIGPREP: 0,
		TPWORKSHOP: 1,
		MATMODIF: 2,
		PHYSTRANS: 3,
		RECINSP: 4,
		SPOT: 5,
		RIGRETURN: 6,
		PERIODICAL: 7,
		MODIF_PRODUCTS: 8,
		DISPATCH: 9,
		BACKLOAD_MANIFEST: 10,
		MILL_RECEIPT: 11,
		AGE_EXTRACTS: 12
	}
}

export const REPORT_STATUS = {
	literal: ['Not concluded', 'Pending', 'Validated', 'Rejected'],
	translateKey: ['wms:NotConcluded', 'wms:Pending', 'wms:Validated', 'wms:NotOk'],
	color: ['gray', 'warning', 'success', 'error'],
	code: {
		NOTCONCLUDED: 0,
		PENDING: 1,
		VALIDATED: 2,
		REJECTED: 3
	}
}

export const REPORT_ITEM_STATUS = {
	literal: ['Ok', 'Not Ok', 'Accept', 'Spare', 'Repair', 'Reject', 'N.A.'],
	translateKey: ['wms:Ok', 'wms:NotOk', 'wms:Accept', '', 'wms:Repair', 'wms:Reject', ''],
	color: ['success', 'error', 'success', '', 'warning', 'error', ''],
	code: {
		OK: 0,
		NOTOK: 1,
		ACCEPT: 2,
		SPARE: 3,
		REPAIR: 4,
		REJECT: 5,
		NA: 6
	}
}

export const REPORT_ITEM = {
	literal: [
		null,
		'Pin',
		'Box',
		'Int. Body',
		'Ext. Body',
		'Marking Legibility',
		'Drift',
		'Coating Varnish condition',
		'Thread Protectors'
	],
	accessor: [null, 'pin', 'box', 'intbody', 'extbody', 'marklegibility', 'drift', 'coatingvarnish', 'threadprot'],
	code: {
		PIN: 1,
		BOX: 2,
		INTBODY: 3,
		EXTBODY: 4,
		MARKLEGIBILITY: 5,
		DRIFT: 6,
		COATINGVARNISH: 7,
		THREADPROT: 8
	}
}

export const MRType = {
	literal: ['Rig Prep', 'Third Party Workshop', 'Material Modification', 'Material Physical Transfer'],
	path: ['rigprep/', 'tpartywork/', 'matmodif/', 'phystrans/'],
	notes: ['rigprepreports', 'tpartyworkreports', 'matmodifreports', 'phystransreports'],
	code: {
		RIGPREP: 0,
		TPWORKSHOP: 1,
		MATMODIF: 2,
		PHYSTRANS: 3
	},
	cid: ['rpcid', 'twcid', 'mmcid', 'ptcid']
}

export const STATUS = {
	literal: ['NotStarted', 'Running', 'StandBy', 'Done'],
	color: ['primary', 'success', 'warning', 'metalic'],
	code: {
		NOTSTARTED: 0,
		RUNNING: 1,
		STANDBY: 2,
		DONE: 3
	}
}

export const listStatus = {
	notstarted: 0,
	running: 1,
	standby: 2,
	done: 3
}

export const listStatusLiteral = status => {
	let e = listStatus
	switch (status) {
		case e.notstarted:
			return 'NOT STARTED'
		case e.running:
			return 'RUNNING'
		case e.standby:
			return 'STAND BY'
		case e.done:
			return 'DONE'
		default:
			return 'UNKNOWN'
	}
}

const listStatusKey = {
	toberepaired: 'ToBeRepaired',
	ready: 'Ready',
	inspected: 'Inspected',
	tobescrapped: 'ToBeScrapped',
	instock: 'InStock',
	underinspection: 'UnderInspection',
	notdefined: 'NotDefined',
	underreceiving: 'UnderReceiving',
	tobeinspected: 'ToBeInspected',
	dispatched: 'Dispatched',
	delivered: 'Delivered',
	WIPO: 'WIPO',
	scrapped: 'Scrapped',
	split: 'Split',
	assembled: 'Assembled'
}

export const listStatusTranslateKey = status => {
	let s = listStatusKey
	switch (status) {
		case s.toberepaired:
			return 'wms:StatusToBeRepaired'
		case s.ready:
			return 'wms:StatusReady'
		case s.inspected:
			return 'wms:StatusInspected'
		case s.tobescrapped:
			return 'wms:StatusToBeScrapped'
		case s.instock:
			return 'wms:StatusInStock'
		case s.underinspection:
			return 'wms:StatusUnderInspection'
		case s.underreceiving:
			return 'wms:StatusUnderReceiving'
		case s.tobeinspected:
			return 'wms:StatusToBeInspected'
		case s.dispatched:
			return 'wms:StatusDispatched'
		case s.delivered:
			return 'wms:StatusDelivered'
		case s.WIPO:
			return 'wms:StatusWIPO'
		case s.scrapped:
			return 'wms:StatusScrapped'
		case s.split:
			return 'wms:StatusSplit'
		case s.assembled:
			return 'wms:StatusAssembled'
		case s.notdefined:
			return 'wms:StatusNotDefined'
		default:
			return ''
	}
}

export const consignmentType = ['Closed End', 'Open End', 'NA']

export const ageManagementType = {
	prefix: ['sm', 'tt', 'pt', '', '', '', '', '', '', '', '', 'sm', 'tt', 'pt'],
	queryPath: ['smquery/', 'ttquery/', 'ptquery/'],
	issuePath: ['smissue/', 'ttissue/', 'ptissue/'],
	listPath: ['smlist', 'ttlist', 'ptlist', '', '', '', '', '', '', '', '', 'smlist', 'ttlist', 'ptlist'],
	checkPath: ['smcheck/', 'ttcheck/', 'ptcheck/', '', '', '', '', '', '', '', '', 'smcheck/', 'ttcheck/', 'ptcheck/'],
	translateKey: ['wms:SlowMovingItems', 'wms:TransferOfTitle', 'wms:MaterialPhysicalTransfer'],
	code: {
		SLOW_ITEMS: 0,
		TITLE_TRANSFER: 1,
		PHYSTRANS: 2
	},
	slowmoving: 1,
	transferoftitle: 2,
	matphysicaltransfer: 3
}

export const SETTINGS = {
	translateKey: ['wms:Inspection', 'wms:AgeManagement', 'wms:ModifiedProduct'],
	code: {
		Inspection: 1,
		AgeManagement: 2,
		ModifiedProduct: 3
	}
}

export const REPORT_TYPE_REF = {
	RIGRETURN: 1,
	AGEMANAGEMENT: 2,
	DISPATCH: 3,
	MILLRECEIPTS: 4,
	DELIVERYNOTE: 5,
	MODIFYPRODUCT: 6,
	MATERIALMODIFY: 7,
	THIRDPARTYWORK: 8,
	BACKLOADMANIFEST: 9,
	PERIODICALINSPECTION: 10,
	PHYSICALTRANSFER: 11,
	RECEIVINGINSPECTION: 12,
	RIGPREP: 13
}

export const reportTypeTranslateKey = type => {
	let s = REPORT_TYPE_REF
	switch (type) {
		case s.RIGRETURN:
			return 'wms:RigReturn'
		case s.AGEMANAGEMENT:
			return 'wms:AgeManagement'
		case s.DISPATCH:
			return 'wms:Dispatch'
		case s.MILLRECEIPTS:
			return 'wms:ReceiptNote'
		case s.DELIVERYNOTE:
			return 'wms:DeliveryNotes'
		case s.MODIFYPRODUCT:
			return 'wms:ModifiedProduct'
		case s.MATERIALMODIFY:
			return 'wms:MaterialModification'
		case s.THIRDPARTYWORK:
			return 'wms:ThirdPartyWorkshop'
		case s.BACKLOADMANIFEST:
			return 'wms:BackloadManifest'
		case s.PERIODICALINSPECTION:
			return 'wms:PeriodicInspection'
		case s.PHYSICALTRANSFER:
			return 'wms:MaterialPhysicalTransfer'
		case s.RECEIVINGINSPECTION:
			return 'wms:ReceivingInspection'
		case s.RIGPREP:
			return 'wms:RigPreparation'
		default:
			return undefined
	}
}

export const PERIOD_TYPE = {
	code: {
		Days: 'D',
		Weeks: 'W',
		Months: 'M',
		Years: 'Y'
	}
}

export const THREAD_COMPOUND_TYPE = {
	literal: ['Storage', 'Running'],
	translateKey: ['', 'wms:Storage', 'wms:Running'],
	code: {
		STORAGE: 1,
		RUNNING: 2
	}
}

export const REPORT_ELEMENTS_STATUS = {
	literal: ['Ok', 'Not Ok', 'N/A'],
	translateKey: ['wms:Ok', 'wms:NotOk', ''],
	code: {
		OK: 0,
		NA: -1,
		NOTOK: 1
	}
}
export const CONTENT_TABS = {
	literal: ['Filter Material', 'Filter Ownership', 'Filter Item', 'Dashboard'],
	translateKey: ['wms:FilterMaterial', 'wms:FilterOwnership', 'wms:FilterItem', 'wms:Dashboard'],
	code: {
		FILTER_MATERIAL: 0,
		FILTER_OWNERSHIP: 1,
		FILTER_ITEM: 2,
		DASHBOARD: 3
	}
}

export const INSPECTION_TYPE = {
	literal: [
		'Rig Prep',
		'Third Party Workshop',
		'Material Modification',
		'Material Physical Transfer',
		'Receiving Inspection',
		'Rig Return',
		'Periodical',
		'Modified Products',
		'Batch Entrance',
		'Pipe Age Management',
		'Dispatch'
	],
	translateKey: [
		'wms:RigPreparation',
		'wms:ThirdPartyWorkshop',
		'wms:MaterialModification',
		'wms:MaterialPhysicalTransfer',
		'wms:ReceivingInspection',
		'wms:RigReturn',
		'wms:PeriodicInspection',
		'wms:ModifiedProduct',
		'wms:ReceiptNote',
		'wms:AgeManagement',
		'wms:Dispatch'
	],
	feature: {
		RIGPREP: 'rigprep',
		TPWORKSHOP: 'tpartywork',
		MATMODIF: 'matmodif',
		PHYSTRANS: 'phystrans',
		RECINSP: 'recinsp',
		RIGRETURN: 'rigreturn',
		PERIODICAL: 'perinsp',
		MODIFPROD: 'modifprod',
		BATCHENTRANCE: 'batchentrance',
		AGEMANAGEMENT: 'extract',
		DISPATCH: 'dispatch'
	},
	code: {
		RIGPREP: 0,
		TPWORKSHOP: 1,
		MATMODIF: 2,
		PHYSTRANS: 3,
		RECINSP: 4,
		RIGRETURN: 5,
		PERIODICAL: 6,
		MODIFPROD: 7,
		BATCH: 8,
		AGEMANAGEMENT: 9,
		DISPATCH: 10
	}
}
export const featureReport = type => {
	let re = INSPECTION_TYPE.code
	switch (type) {
		case re.RIGPREP:
			return 'rigprep'
		case re.TPWORKSHOP:
			return 'tpartywork'
		case re.MATMODIF:
			return 'matmodif'
		case re.PHYSTRANS:
			return 'phystrans'
		case re.RECINSP:
			return 'recinsp'
		case re.RIGRETURN:
			return 'rigreturn'
		case re.PERIODICAL:
			return 'perinsp'
		case re.MODIFPROD:
			return 'modifprod'
		case re.BATCH:
			return 'batchentrance'
		case re.AGEMANAGEMENT:
			return 'extract'
		case re.DISPATCH:
			return 'dispatch'
		default:
			return null
	}
}
export const STOCK_STATUS = {
	literal: [
		'UnderReceiving',
		'ToBeInspected',
		'Ready',
		'Inspected',
		'ToBeRepaired',
		'ToBeScrapped',
		'Dispatched',
		'Delivered',
		'WIPO',
		'Scrapped',
		'Split',
		'Assembled',
		'InStock',
		'UnderInspection'
	],
	color: [
		'black',
		'black',
		'success',
		'black',
		'warning',
		'error',
		'black',
		'black',
		'warning',
		'error',
		'black',
		'black',
		'success',
		'black'
	],
	code: {
		NotDefined: -1,
		UnderReceiving: 0,
		ToBeInspected: 1,
		Ready: 2,
		Inspected: 3,
		ToBeRepaired: 4,
		ToBeScrapped: 5,
		Dispatched: 6,
		Delivered: 7,
		WIPO: 8,
		Scrapped: 9,
		Split: 10,
		Assembled: 11,
		InStock: 12,
		UnderInspection: 13
	}
}

export const GROUPING_BUTTONS = {
	literal: ['Group by Material', 'Group by Rack'],
	translateKey: ['wms:GroupByMaterial', 'wms:GroupByRack'],
	code: {
		GROUP_BY_MATERIAL: 0,
		GROUP_BY_RACK: 1
	}
}

export const ALLOWED_FILES = {
	types: ['pdf', 'png', 'jpg', 'ods', 'doc', 'docx', 'odt', 'ppt', 'pptx', 'xls', 'xlsx']
}

export const EQUIPMENT_STATUS = {
	code: {
		ACTIVE: 'Active',
		INACTIVE: 'Inactive'
	}
}
