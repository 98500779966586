/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { FlexView, Card, Button } from 'components/common'
import { Checkbox } from 'components/form'
import { useTranslation } from 'react-i18next'
import { TagPrintingContext } from '../../stores/TagPrintingStore'
import Icon from 'components/common/Icon'
import _ from 'lodash'
import styled from 'styled-components'
import { useWindowSize } from 'apps/wms/utils/hooks'
import PrintModal from '../common/PrintModal'

const FlexHover = styled(FlexView)`
	&:hover {
		background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.lightGray : theme.colors.background)};
	}
	cursor: pointer;
`

const PreparedEntranceList = () => {
	const { preparedEntrances, getPreparedEntranceData, printers, printItems } = useContext(TagPrintingContext)
	const { t } = useTranslation()

	const [entranceMaterials, setEntranceMaterials] = useState([])
	const [availableValids, setAvailableValids] = useState([])
	const [openPrintModal, setOpenPrintModal] = useState(false)

	const listDeadSpace = 80
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const onSelectReport = report => {
		report &&
			report.id &&
			getPreparedEntranceData(report.id)
				.then(response => {
					setEntranceMaterials(response.materials.length ? response.materials : [])
					setAvailableValids(response.valids.length ? response.valids : [])
				})
				.catch(e => console.error(e))
	}

	const selectedValids = () => availableValids.filter(v => v.checked)

	const composedFilter = value => entranceMaterials.filter(m => m.checked).find(m => m.erpref === value.erpref)

	const toggleMaterialCheck = erpref => {
		const index = entranceMaterials.findIndex(item => item.erpref === erpref)
		if (index !== -1) {
			const item = entranceMaterials[index]
			entranceMaterials[index] = { ...item, checked: !item.checked }
			if (!entranceMaterials[index].checked) {
				availableValids.forEach(i => {
					if (i.erpref === erpref) i.checked = false
				})
				setAvailableValids([...availableValids])
			}
			setEntranceMaterials([...entranceMaterials])
		}
	}

	const toggleItemCheck = valid => {
		const index = availableValids.findIndex(item => item.valid === valid)
		if (index !== -1) {
			const item = availableValids[index]
			availableValids[index] = { ...item, checked: !item.checked }
			setAvailableValids([...availableValids])
		}
	}

	const onSelectPrinter = selectedPrinter => {
		selectedPrinter &&
			printItems(
				selectedPrinter,
				availableValids.filter(i => i.checked)
			)
				.then(response => setOpenPrintModal(false))
				.catch(e => {
					console.error(e)
				})
	}

	return (
		<FlexView width='100%' flex='1' margin='0' padding='0' flexDirection='row'>
			<Card
				data-cy='list-item'
				width='60%'
				height='100%'
				ref={listRef}
				margin='8px 8px 0 0'
				maxHeight='calc(100% - 50px)'>
				<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
					{t('wms:PreparedEntrances')}
				</FlexView>
				<FlexView
					width='100%'
					height={`${listHeight}px`}
					style={{ overflowY: 'scroll', overflowX: 'hidden' }}
					maxHeight='88%'
					data-cy='list-item'>
					{preparedEntrances && preparedEntrances.length > 0 ? (
						_.map(preparedEntrances, (item, index) => (
							<FlexHover
								key={item.id}
								width='100%'
								flexDirection='row'
								maxHeight='20%'
								style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
								onClick={() => onSelectReport(item)}>
								<FlexView
									key={item.id}
									justifyContent='flex-start'
									width='100%'
									fontSize='small'
									fontWeight='bold'
									alignSelf='center'>
									{`${item.title} - ${item.loadowner} Quantity: ${item.amount}`}
								</FlexView>
								<Button
									fontSize='tiny'
									backgroundColor='secondary'
									color='white'
									onClick={() => onSelectReport(item)}>
									{`${t(`wms:PreparedValid`)}`}
								</Button>
							</FlexHover>
						))
					) : (
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='24px'
							color='lightGray'
							margin='auto 0'
							alignItems='center'
							justifyContent='center'
							data-cy='empty-list'>
							{t('wms:NoPreparedEntrances')}
						</FlexView>
					)}
				</FlexView>
			</Card>
			<FlexView flexDirection='column' width='40%' height='100%'>
				<Card
					width='calc(100% - 56px)'
					margin='8px 0 8px 8px'
					height='60%'
					data-cy='list-item'
					alignItems='stretch'
					minHeight='20%'>
					<FlexView width='100%' fontSize='medium' fontWeight='bold' height='25%'>
						{`${t(`wms:Materials`)}`}
					</FlexView>
					{entranceMaterials && entranceMaterials.length > 0
						? entranceMaterials.map(item => (
								<FlexView key={item.erpref} width='100%' flexDirection='column'>
									<Checkbox
										label={`${item.erpref} - ${item.erpdsc}`}
										checked={item.checked}
										backgroundColor='secondary'
										justifyContent='flex-start'
										onChange={e => toggleMaterialCheck(item.erpref)}></Checkbox>
								</FlexView>
						  ))
						: null}
				</Card>
				<Card
					height='calc(100% - 50px)'
					data-cy='list-item'
					alignItems='stretch'
					flexDirection='column'
					width='calc(100% - 56px)'
					margin='8px 0 8px 8px'>
					<FlexView width='100%' flexDirection='row'>
						<FlexView
							justifyContent='flex-start'
							width='100%'
							fontSize='medium'
							fontWeight='bold'
							alignSelf='center'>
							{`${t(`wms:PipeList`)}`}
						</FlexView>
						<Button
							fontSize='small'
							backgroundColor='secondary'
							color='white'
							alignSelf='center'
							small
							onClick={() => setOpenPrintModal(true)}
							disabled={selectedValids().length === 0}>
							{`${t(`wms:Print`)}`}
							<Icon name='print' color='white' width='12px' height='12px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
					{/* {validList && validList.length > 0 ? ( 
                                <FlexView >
                                    <Checkbox label='VALIDs' onChange={e => setSelectAll(e)} />
                                </FlexView>
                            ) : null} */}
					<FlexView
						flexDirection='column'
						height='150px'
						style={{ overflowY: 'scroll', overflowX: 'hidden' }}
						maxHeight='calc(100% - 50px)'>
						{availableValids && availableValids.length > 0 ? (
							availableValids
								.filter(i => composedFilter(i))
								.map(item => (
									<FlexView key={item.puuid} width='100%' flexDirection='column'>
										<Checkbox
											justifyContent='flex-start'
											checked={item.checked}
											backgroundColor='secondary'
											label={`${item.valid}`}
											onChange={e => toggleItemCheck(item.valid)}></Checkbox>
									</FlexView>
								))
						) : (
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='24px'
								color='lightGray'
								margin='auto 0'
								alignItems='center'
								justifyContent='center'
								data-cy='empty-list'>
								{t('wms:NoPreparedEntrances')}
							</FlexView>
						)}
					</FlexView>
				</Card>
			</FlexView>
			<PrintModal
				isOpen={openPrintModal}
				onOutsideClick={() => setOpenPrintModal(false)}
				printersList={printers}
				onSelectPrinter={onSelectPrinter}
			/>
		</FlexView>
	)
}

export default PreparedEntranceList
