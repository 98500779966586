/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getExtracts: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`agemgmt/${wid}/config`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMaterials: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`material/${wid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAttributes: (wid, attrId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`attributes/${wid}/value?attributeid=${encodeURIComponent(attrId)}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getErpReference: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`material/${wid}/description`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveExtract: (wid, isNew, params = {}, token) =>
		new Promise((resolve, reject) => {
			if (isNew) {
				instance
					.post(`agemgmt/${wid}/config/add`, params, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			} else {
				instance
					.post(`agemgmt/${wid}/config/update`, params, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			}
		}),
	deleteExtract: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`agemgmt/${wid}/config/delete`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
