/** @format */

import Select from 'apps/wms/components/forms/Select'
import { REPORT_ITEM, REPORT_ITEM_STATUS, THREAD_COMPOUND_TYPE } from 'apps/wms/utils/enum'
import { Button, FlexView, Icon } from 'components/common'
import { Input } from 'components/form'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ReportInput = styled(Input)`
	min-height: unset;
	font-weight: normal;
	margin: 0px;
	border-width: 1px;
	width: 80px;
`

const BorderlessButton = styled(Button)`
	border: none;
`

const getStatusColor = status => REPORT_ITEM_STATUS.color[status]

const inspItemStatus = [
	{ value: REPORT_ITEM_STATUS.code.OK, label: 'wms:Ok' },
	{ value: REPORT_ITEM_STATUS.code.NOTOK, label: 'wms:NotOk' },
	{ value: REPORT_ITEM_STATUS.code.NA, label: 'N.A.' }
]

const getReportItemColumn = (
	reportItem,
	{ t, allowEdit, onInspectionItemChange, onRejectReasonChange, rejectReasons }
) => ({
	Header: REPORT_ITEM.literal[reportItem],
	accessor: REPORT_ITEM.accessor[reportItem],
	width: '100px',
	Cell: ({ cell: { value, row } }) => {
		return allowEdit ? (
			<FlexView flexDirection='column' justifyContent='center' alignItems='center' margin='0' padding='0'>
				<Select
					value={value.report}
					options={inspItemStatus.map(i => ({ ...i, label: t(i.label) }))}
					color={getStatusColor(value.report)}
					onChange={value => {
						onInspectionItemChange(reportItem, value, row)
					}}
					width='100px'
					inline={true}
					margin='0 0 4px 0'
					padding='0'
					fontSize='12px'
				/>
				{value.report === REPORT_ITEM_STATUS.code.NOTOK ? (
					<Select
						value={value.defect}
						options={rejectReasons
							.filter(rr => rr.inspectionitem === reportItem)
							.map(rr => ({
								value: rr.id,
								label: rr.desc
							}))}
						onChange={value => {
							onRejectReasonChange(reportItem, value, row)
						}}
						width='100px'
						inline={true}
						margin='0 0 4px 0'
						padding='0'
						fontSize='12px'
					/>
				) : null}
			</FlexView>
		) : (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'
				color={getStatusColor(value.report)}>
				{t(REPORT_ITEM_STATUS.translateKey[value.report])}
			</FlexView>
		)
	},
	disableFilters: true
})

const InspStatusColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows } }) => {
	const { t } = useTranslation()
	const inspStatus = [
		{ value: null, label: t('wms:All') },
		{ value: REPORT_ITEM_STATUS.code.ACCEPT, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.ACCEPT] },
		{ value: REPORT_ITEM_STATUS.code.REJECT, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.REJECT] },
		{ value: REPORT_ITEM_STATUS.code.REPAIR, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.REPAIR] }
	]
	return (
		<Select
			width='100px'
			margin='0 0 4px 0'
			padding='0'
			fontSize='12px'
			value={filterValue}
			onChange={value => setFilter(value)}
			options={inspStatus.map(i => ({ ...i, label: t(i.label) }))}
		/>
	)
}

const MescColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows } }) => {
	const { t } = useTranslation()
	return (
		<Input
			width='100px'
			style={{ minHeight: '0px' }}
			padding='4px 8px'
			margin='0px'
			fontSize='12px'
			type='text'
			value={filterValue || ''}
			onChange={e => setFilter(e.target.value || undefined)}
			placeholder={t('wms:TypeToFilter')}
		/>
	)
}

export const validColumn = () => ({
	Header: 'VALID',
	accessor: 'valid',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value}
			</FlexView>
		)
	},
	disableFilters: true
})

export const markingLegibilityColumn = config => getReportItemColumn(REPORT_ITEM.code.MARKLEGIBILITY, config)

export const coatingVarnishColumn = config => getReportItemColumn(REPORT_ITEM.code.COATINGVARNISH, config)

export const threadProtColumn = config => getReportItemColumn(REPORT_ITEM.code.THREADPROT, config)

export const threadColumns = config => ({
	Header: 'Thread',
	columns: [pinColumn(config), boxColumn(config)]
})

export const pinColumn = config => getReportItemColumn(REPORT_ITEM.code.PIN, config)

export const boxColumn = config => getReportItemColumn(REPORT_ITEM.code.BOX, config)

export const pipeBodyColumns = config => ({
	Header: 'Pipe Body',
	columns: [intBodyColumn(config), extBodyColumn(config)]
})

export const intBodyColumn = config => getReportItemColumn(REPORT_ITEM.code.INTBODY, config)

export const extBodyColumn = config => getReportItemColumn(REPORT_ITEM.code.EXTBODY, config)

export const driftColumn = config => getReportItemColumn(REPORT_ITEM.code.DRIFT, config)

const inspStatus = [
	{ value: REPORT_ITEM_STATUS.code.ACCEPT, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.ACCEPT] },
	{ value: REPORT_ITEM_STATUS.code.REJECT, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.REJECT] },
	{ value: REPORT_ITEM_STATUS.code.REPAIR, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.REPAIR] }
]

export const inspStatusColumn = ({ t, allowEdit, onFinalStatusChange }) => ({
	Header: t('wms:FinalStatus'),
	accessor: 'finalreport',
	Filter: InspStatusColumnFilter,
	width: '120px',
	Cell: ({ cell: { value, row } }) => {
		return allowEdit ? (
			<FlexView flexDirection='column' justifyContent='center' alignItems='center' margin='0' padding='0'>
				<Select
					value={value}
					options={inspStatus.map(i => ({ ...i, label: t(i.label) }))}
					color={getStatusColor(value)}
					onChange={value => {
						onFinalStatusChange('finalreport', value, row)
					}}
					width='100%'
					inline={true}
					margin='0 0 4px 0'
					padding='0'
					fontSize='12px'
				/>
			</FlexView>
		) : (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				color={getStatusColor(value)}
				margin='0'
				padding='0'>
				{t(REPORT_ITEM_STATUS.translateKey[value])}
			</FlexView>
		)
	}
})

export const inspectorColumn = ({ t, allowEdit }) => ({
	Header: t('wms:Inspector'),
	accessor: 'responsible',
	width: '120px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value}
			</FlexView>
		)
	},
	disableFilters: true
})

export const threadCompoundColumns = config => ({
	Header: config.t('wms:ThreadCompoundApplication'),
	columns: [
		manufacturerColumn(config),
		typeColumn(config),
		batchNumberColumn(config),
		applicationMethodColumn(config),
		responsibleColumn(config)
	]
})

const renderManufacturerColumn = ({
	cell: { value, row },
	column: {
		config: { allowEdit, onManufacturerChange, manufacturer }
	}
}) =>
	allowEdit ? (
		<FlexView
			maxWidth='95%'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			margin='0'
			padding='0'>
			<Select
				value={value.id}
				options={manufacturer && manufacturer.map(m => ({ value: m.typeid, label: m.typedesc }))}
				onChange={value => onManufacturerChange('manufacturer', value, row)}
				inline={true}
				width='110px'
				margin='0 0 4px 0'
				padding='0'
				fontSize='12px'
			/>
		</FlexView>
	) : (
		<FlexView flexDirection='row' justifyContent='center' alignItems='center' fontSize='12px' margin='0' padding='0'>
			{value ? value.id : '*****'}
		</FlexView>
	)

export const manufacturerColumn = ({ t, allowEdit, onManufacturerChange, manufacturer }) => ({
	Header: t('wms:Manufacturer'),
	accessor: 'manufacturer',
	config: { allowEdit, onManufacturerChange, manufacturer },
	Cell: renderManufacturerColumn,
	disableFilters: true
})

export const typeColumn = ({ t }) => ({
	Header: t('wms:Type'),
	accessor: 'manufacturerstate',
	width: '120px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value ? t(THREAD_COMPOUND_TYPE.translateKey[value]) : '*****'}
			</FlexView>
		)
	},
	disableFilters: true
})

const renderBatchNumberColumn = ({
	cell: { value, row },
	column: {
		config: { allowEdit, onBatchNumberChange }
	}
}) =>
	allowEdit ? (
		<ReportInput
			margin='0'
			padding='4px'
			value={value}
			onChange={e => {
				onBatchNumberChange('batchnumber', e.target.value, row)
			}}
			fontSize='12px'
			width='100%'
		/>
	) : (
		<FlexView flexDirection='row' justifyContent='center' alignItems='center' fontSize='12px' margin='0' padding='0'>
			{value ? value : '*****'}
		</FlexView>
	)

export const batchNumberColumn = ({ t, allowEdit, onBatchNumberChange }) => ({
	Header: t('wms:BatchNumber'),
	accessor: 'batchnumber',
	width: '80px',
	config: { allowEdit, onBatchNumberChange },
	Cell: renderBatchNumberColumn,
	disableFilters: true
})

const renderApplicationMethodcolumn = ({
	cell: { value, row },
	column: {
		config: { allowEdit, onApplicationMethodChange, applicationMethodList }
	}
}) =>
	allowEdit ? (
		<FlexView flexDirection='column' justifyContent='center' alignItems='center' margin='0' padding='0'>
			<Select
				value={value.id}
				options={
					applicationMethodList &&
					applicationMethodList.map(app => ({ value: app.methodid, label: app.methoddesc }))
				}
				onChange={value => onApplicationMethodChange('applicationmethod', value, row)}
				width='100px'
				inline={true}
				margin='0 0 4px 0'
				padding='0'
				fontSize='12px'
			/>
		</FlexView>
	) : (
		<FlexView flexDirection='row' justifyContent='center' alignItems='center' fontSize='12px' margin='0' padding='0'>
			{value ? value.id : '*****'}
		</FlexView>
	)

export const applicationMethodColumn = ({ t, allowEdit, onApplicationMethodChange, applicationMethodList }) => ({
	Header: t('wms:ApplicationMethod'),
	accessor: 'applicationmethod',
	width: '90px',
	config: { allowEdit, onApplicationMethodChange, applicationMethodList },
	Cell: renderApplicationMethodcolumn,
	disableFilters: true
})

export const responsibleColumn = ({ t }) => ({
	Header: t('wms:Responsible'),
	accessor: 'tcresponsible',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value ? value : '*****'}
			</FlexView>
		)
	},
	disableFilters: true
})

const renderRemarksColumn = ({
	cell: { value, row },
	column: {
		config: { t, allowEdit, onReportChange }
	}
}) =>
	allowEdit ? (
		<ReportInput
			placeholder={t('wms:NoRemarks')}
			margin='0'
			padding='4px '
			value={value}
			onChange={e => {
				onReportChange('remarks', e.target.value, row)
			}}
			fontSize='12px'
			width='100%'
		/>
	) : (
		<FlexView flexDirection='row' justifyContent='center' alignItems='center' fontSize='12px' margin='0' padding='0'>
			{value}
		</FlexView>
	)

export const remarksColumn = ({ t, allowEdit, onReportChange }) => ({
	Header: t('wms:Remarks'),
	accessor: 'remarks',
	width: '150px',
	config: { t, allowEdit, onReportChange },
	Cell: renderRemarksColumn,
	disableFilters: true
})

export const measurementColumns = config => ({
	Header: config.t('wms:LengthMeasurement'),
	columns: [
		measuredLengthColumn(config),
		makeupLossColumn(),
		effectiveLengthColumn(config),
		intDiameterColumn(config),
		outDiameterColumn(config),
		measureResponsibleColumn(config)
	]
})

const renderMeasuredLengthColumn = ({
	cell: { value, row },
	column: {
		config: { allowEdit, onMeasurementChange }
	}
}) =>
	allowEdit ? (
		<ReportInput
			margin='0'
			padding='4px '
			value={value}
			onChange={e => {
				onMeasurementChange('measured', e.target.value, row)
			}}
			fontSize='12px'
			width='100%'
		/>
	) : (
		<FlexView flexDirection='row' justifyContent='center' alignItems='center' fontSize='12px' margin='0' padding='0'>
			{value}
		</FlexView>
	)

export const measuredLengthColumn = ({ t, allowEdit, onMeasurementChange }) => ({
	Header: t('wms:LengthMeasured'),
	accessor: 'measured',
	width: '80px',
	config: { allowEdit, onMeasurementChange },
	Cell: renderMeasuredLengthColumn,
	disableFilters: true
})

export const makeupLossColumn = () => ({
	Header: 'Make up loss',
	accessor: 'makeuploss',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value.toFixed(3)}
			</FlexView>
		)
	},
	disableFilters: true
})

export const effectiveLengthColumn = ({ t }) => ({
	Header: t('wms:EffectiveLength'),
	accessor: 'effective',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value.toFixed(3)}
			</FlexView>
		)
	},
	disableFilters: true
})

export const intDiameterColumn = ({ t }) => ({
	Header: t('wms:InternalDiameter'),
	accessor: 'intdiameter',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value.toFixed(3)}
			</FlexView>
		)
	},
	disableFilters: true
})

export const outDiameterColumn = ({ t }) => ({
	Header: t('wms:OutsideDiameter'),
	accessor: 'outdiameter',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value.toFixed(3)}
			</FlexView>
		)
	},
	disableFilters: true
})

export const measureResponsibleColumn = ({ t }) => ({
	Header: t('wms:InspectionResponsible'),
	accessor: 'measureresponsible',
	width: '120px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value}
			</FlexView>
		)
	},
	disableFilters: true
})

export const instrumentsColumn = ({ t, handleInstrumentCaseSelector }) => ({
	Header: t('wms:InstrumentCase'),
	accessor: 'instlistid',
	width: '120px',
	Cell: ({ cell: { value, row } }) => {
		return (
			<BorderlessButton
				fontSize='tiny'
				width='100%'
				height='100%'
				outline='true'
				margin='0'
				onClick={() => handleInstrumentCaseSelector(row)}>
				<FlexView
					flexDirection='row'
					flexWrap='wrap'
					justifyContent='center'
					alignItems='center'
					fontSize='12px'
					margin='0'
					padding='0'>
					{row.original.instrumentlist.length ? (
						row.original.instrumentlist.map(instrument => (
							<FlexView
								key={instrument.id}
								flexDirection='row'
								justifyContent='center'
								alignItems='center'
								fontSize='12px'
								margin='0 4px'
								padding='0'>
								{instrument.reference}
							</FlexView>
						))
					) : (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='12px'
							margin='0'
							padding='0'>
							{t('wms:NoEquipment')}
						</FlexView>
					)}
				</FlexView>
			</BorderlessButton>
		)
	},
	disableFilters: true
})

export const imageHandlerColumn = ({ t, allowEdit, handlePhotoSelector }) => ({
	Header: t('wms:Photos'),
	accessor: 'hasphoto',
	width: '80px',
	Cell: ({ cell: { value, row } }) => {
		return (
			<Button
				fontSize='tiny'
				padding='8px'
				outline='true'
				color={value ? 'success' : 'gray'}
				margin='0'
				width='100%'
				height='100%'
				disabled={!allowEdit && !value}
				onClick={() => handlePhotoSelector(row)}>
				<Icon name='edit' color={value ? 'success' : 'gray'} height='12px' width='12px' margin='0' />
			</Button>
		)
	},
	disableFilters: true
})

export const customerOrderColumn = ({ t }) => ({
	Header: t('wms:CustomerOrder'),
	accessor: 'customerpo',
	minWidth: '100px',
	maxWidth: '100px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	),
	disableFilters: true
})

export const customerItemColumn = ({ t }) => ({
	Header: t('wms:CustomerItem'),
	accessor: 'customeritem',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	),
	disableFilters: true
})

export const salesOrderColumn = ({ t }) => ({
	Header: t('wms:SalesOrder'),
	accessor: 'salesorder',
	minWidth: '100px',
	maxWidth: '100px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	),
	disableFilters: true
})

export const salesItemColumn = ({ t }) => ({
	Header: t('wms:SalesItem'),
	accessor: 'salesitem',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	),
	disableFilters: true
})

export const mescColumn = ({ t, reportType }) => ({
	Header: t('wms:ErpReference'),
	accessor: 'erpreference',
	minWidth: '100px',
	Filter: MescColumnFilter,
	maxWidth: '100px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	)
})

export const materialDescColumn = ({ t }) => ({
	Header: t('wms:Material'),
	accessor: 'materialdesc',
	minWidth: '300px',
	maxWidth: '300px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	),
	disableFilters: true
})

const getAttributeColumns = ({ filterColumns }) =>
	filterColumns.map((col, index) => ({
		Header: col.attributedesc,
		minWidth: '80px',
		maxWidth: '80px',
		Cell: ({ cell: { row } }) => (
			<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
				{row.original.filtercolumns[index] ? row.original.filtercolumns[index].attributevalue : '*****'}
			</FlexView>
		),
		disableFilters: true
	}))

export const attributeColumns = config => getAttributeColumns(config)

export const ownershipColumn = ({ t }) => ({
	Header: t('wms:Ownership'),
	accessor: 'ownershipdesc',
	minWidth: '100px',
	maxWidth: '100px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	),
	disableFilters: true
})

export const entranceDateColumn = ({ t, DATE_FORMAT, i18n }) => ({
	Header: t('wms:EntranceDate'),
	accessor: 'entrancedate',
	minWidth: '100px',
	maxWidth: '100px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{DATE_FORMAT(value, i18n.language)}
		</FlexView>
	),
	disableFilters: true
})

export const ageColumn = ({ t }) => ({
	Header: `${t('wms:Age')}[${t('wms:Months')}]`,
	accessor: 'age',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value.toFixed(3)}
		</FlexView>
	),
	disableFilters: true
})

export const jointsColumn = ({ t }) => ({
	Header: `${t('wms:Joints')}/PCS`,
	accessor: 'quantity',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	),
	disableFilters: true
})

export const lengthColumn = ({ t, convertToCurrentLengthUnit, lengthUnit }) => ({
	Header: `${t('wms:Length')} [${lengthUnit}]`,
	accessor: 'length',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{convertToCurrentLengthUnit(value, lengthUnit)} {lengthUnit}
		</FlexView>
	),
	disableFilters: true
})

export const weightColumn = ({ t, convertToCurrentWeightUnit, weightUnit }) => ({
	Header: `${t('wms:Weight')} [${weightUnit}]`,
	accessor: 'weight',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{convertToCurrentWeightUnit(value, weightUnit)} {weightUnit}
		</FlexView>
	),
	disableFilters: true
})
