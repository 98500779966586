/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	getRigReturnList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/rigreturn/${wid}/list`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.rigreturnlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	uploadRRFile: (wid, file, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(
					`/rigreturn/${wid}/savepdf`,
					{ rrfile: file },
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.filename)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	newRR: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/rigreturn/${wid}/list`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else if (response.status === 422) reject(new Error('wms.DispatchListMultipleMaterials'))
					else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteRR: (wid, rrlid, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`/rigreturn/${wid}/list/${rrlid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getReports: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/rigreturn/${wid}/reports`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.rigreturnlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenRigReturn: (wid, payload = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/rigreturn/${wid}/release`, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	openRRFile: (wid, rrid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`rigreturn/${wid}/downloadpdf/${rrid}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
