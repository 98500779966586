/** @format */

import { PerInspContext } from 'apps/wms/stores/PerInspStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import Table from '../../components/content/Table'
import { Card, FlexView, LoadingOverlay } from 'components/common'
import { _ } from 'globalthis/implementation'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const PerInspList = () => {
	const { t, i18n } = useTranslation()
	const { loadingPerInspList, filteredInspections, selectedInspection, setSelectedInspection, setSearchInfo } =
		useContext(PerInspContext)

	const listDeadSpace = 20
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedInspection && selectedInspection.id && handleSelect(selectedInspection.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredInspections])

	const handleSelect = useCallback(
		id => {
			if (id) {
				setSelectedInspection(_.find(filteredInspections, insp => insp.id === id))
				setSearchInfo(true)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filteredInspections]
	)

	const perInspData =
		filteredInspections &&
		filteredInspections.map(value => ({
			id: value.id,
			erpRef: value.material.formatted.substring(0, value.material.formatted.indexOf(' ')),
			entranceid: value.entranceid,
			customerpo: value.customerpo,
			customeritem: value.customeritem,
			salesorder: value.salesorder,
			salesitem: value.salesitem,
			entrancedate: value.entrancedate,
			nextinspdate: value.nextinspdate
		}))

	const perInspColumns = useMemo(
		() => [
			{
				Header: (
					<FlexView alignItems='center' fontWeight='bold' fontSize='12px' style={{ textAlign: 'center' }}>
						ID
					</FlexView>
				),
				accessor: 'entranceid',
				width: '30px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							width='15px'
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' fontWeight='bold' fontSize='12px' style={{ textAlign: 'center' }}>
						{t('wms:ErpReference')}
					</FlexView>
				),
				accessor: 'erpRef',
				width: '10px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							width='95px'
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView
						width='100%'
						fontSize='12px'
						fontWeight='bold'
						alignItems='center'
						style={{ textAlign: 'center' }}>
						{t('wms:CustomerOrder')}
					</FlexView>
				),
				accessor: 'customerpo',
				width: '150px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
							fontSize='12px'
							width='150px'
							justifyContent='center'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{value ? value : '*****'}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='12px'
						alignItems='center'
						style={{ textAlign: 'center' }}>
						{t('wms:CustomerItem')}
					</FlexView>
				),
				accessor: 'customeritem',
				width: '80px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
							fontSize='12px'
							width='80px'
							justifyContent='center'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{value ? value : '*****'}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='12px'
						alignItems='center'
						style={{ textAlign: 'center' }}>
						{t('wms:SalesOrder')}
					</FlexView>
				),
				accessor: 'salesorder',
				width: '80px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
							justifyContent='center'
							width='80px'
							fontSize='12px'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{value ? value : '*****'}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='12px'
						alignItems='center'
						style={{ textAlign: 'center' }}>
						{t('wms:SalesItem')}
					</FlexView>
				),
				accessor: 'salesitem',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{value ? value : '*****'}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='12px'
						alignItems='center'
						style={{ textAlign: 'center' }}>
						{t('wms:EntranceDate')}
					</FlexView>
				),
				accessor: 'entrancedate',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{moment.locale(`${i18n.language}`) && moment(value).format('L')}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='12px'
						alignItems='center'
						style={{ textAlign: 'center' }}>
						{t('wms:NextInspection')}
					</FlexView>
				),
				accessor: 'nextinspdate',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
							justifyContent='center'
							fontSize='12px'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{moment.locale(`${i18n.language}`) && moment(value).format('L')}
						</FlexView>
					)
				}
			}
		],
		[handleSelect, t, i18n.language]
	)

	return (
		<Card
			data-cy='card-list-wrapper'
			ref={listRef}
			height='95%'
			width='50%'
			margin='0 16px 0 0'
			style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingPerInspList} borderRadius='card' />
			{perInspData && perInspData.length > 0 ? (
				<Table
					width='100%'
					elevation='none'
					height={`${listHeight}px`}
					style={{ overflowY: 'auto', overflowX: 'hidden' }}
					columns={perInspColumns}
					data={perInspData}
					sortable
					gridless
					data-cy='table-periodic'
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='no-periodic-list'>
					{t('wms:NoPeriodicInspections')}
				</FlexView>
			)}
		</Card>
	)
}

export default PerInspList
