/** @format */

import React, { useContext } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import actual from '../api/pipeData'
import usageRights from '../api/usageRights'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import _ from 'lodash'
import { bundleOrItems } from '../utils/warehouse'

export const ActualDataContext = React.createContext()

export const ActualDataProvider = ({ children }) => {
	const { t } = useTranslation()
	const {
		warehouse,
		convertToCurrentLengthUnit,
		convertToCurrentWeightUnit,
		getWeightDisplay,
		getLengthDisplay,
		lengthUnit,
		weightUnit,
		convertWeightToSend,
		convertLengthToSend
	} = useContext(WMSContext)
	const { user, token } = useContext(UserContext)
	const [loadingData, setLoadingData] = useState(false)
	const [addCertificate, setAddCertificate] = useState(false)
	const [addCertificateVersion, setAddCertficateVersion] = useState(false)
	const [loadingOwnerships, setLoadingOwnerships] = useState(false)
	const [loadingPipes, setLoadingPipes] = useState(false)
	const [loadingCO, setLoadingCO] = useState(false)
	const [loadingSO, setLoadingSO] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [savingChanges, setSavingChanges] = useState(false)
	const [endUsers, setEndUsers] = useState(null)
	const [endUserSelected, setEndUserSelected] = useState(null)
	const [certificates, setCertificates] = useState(null)
	const [certificateVersions, setCertificateVersions] = useState([])
	const [poList, setPOList] = useState(null)
	const [poNumbers, setPONumbers] = useState([])
	const [customerOrder, setCustomerOrder] = useState(null)
	const [customerItem, setCustomerItem] = useState(null)
	const [poItems, setPOItems] = useState({})
	const [filteredPoItems, setFilteredPoItems] = useState([])
	const [valid, setValid] = useState('')
	const [ippn, setIPPN] = useState('')
	const [pipeData, setPipeData] = useState(null)
	const [selectedType, setSelectedType] = useState(null)
	const [mainSource, setMainSource] = useState(null)
	const [ownerships, setOwnerships] = useState(null)
	const [selectedOwnership, setSelectedOwnership] = useState(null)
	const [salesNumbers, setSalesNumbers] = useState([])
	const [salesItems, setSalesItems] = useState({})
	const [salesOrder, setSalesOrder] = useState(null)
	const [salesItem, setSalesItem] = useState(null)
	const [filteredSalesItems, setFilteredSalesItems] = useState(null)
	const [availablePipes, setAvailablePipes] = useState(null)
	const [selectedPipes, setSelectedPipes] = useState([])
	const [newInfo, setNewInfo] = useState({
		consignmenttype: null,
		heat: '',
		certificateref: null,
		certificateversion: null,
		length: '',
		weight: ''
	})
	const [rawCertificateList, setRawCertificateList] = useState([])
	const [selectedSource, setSelectedSource] = useState('VALID')

	const getCertificates = () =>
		new Promise((resolve, reject) => {
			actual
				.getCertificates(token)
				.then(response => {
					setRawCertificateList(response)
					let cert = []
					_.forEach(response, res => {
						let index = _.findIndex(cert, c => c.number === res.number)
						if (index === -1) {
							cert.unshift({ number: res.number, version: [] })
							index = 0
						}
						cert[index].version.push(res.version)
					})
					setCertificates(cert)
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingCertificates')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getEndUsers = () =>
		new Promise((resolve, reject) => {
			usageRights
				.getEnduserList(warehouse.id, token)
				.then(response => {
					resolve()
					setEndUsers(response)
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingEndUser')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getPOList = () =>
		new Promise((resolve, reject) => {
			usageRights
				.getCustomerOrdersListByEndUser(warehouse.id, endUserSelected, token)
				.then(response => {
					resolve()
					setPOList(_.filter(response, res => res.number !== null))
					let list = _.filter(response, res => res.number !== null)
					let pon = []
					let poi = []
					_.forEach(list, (res, i) => {
						let n = res.number
						if (!poi[n]) {
							pon.push({ value: i, label: res.number })
							poi[n] = []
						}
						poi[n].push({ value: i, label: res.item })
					})
					setPONumbers(pon)
					setPOItems(poi)
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingProductionOrder')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getSalesList = () =>
		new Promise((resolve, reject) => {
			usageRights
				.getSalesOrdersListByEndUser(warehouse.id, endUserSelected, token)
				.then(response => {
					resolve()
					let salesList = _.filter(response, res => res.number !== null)
					let son = []
					let soi = {}
					_.forEach(salesList, (res, i) => {
						let n = res.number
						if (!soi[n]) {
							son.push({ value: i, label: n })
							soi[n] = []
						}
						soi[n].push({ value: i, label: res.item })
					})
					setSalesNumbers(son)
					setSalesItems(soi)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingSalesOrder')} [ ${e.status}]: ${e.data}`)
				})
		})

	const getOwnershipByPipeData = () =>
		new Promise((resolve, reject) => {
			let req = null
			if (mainSource === 'CUSTOMER_ITEM' && endUserSelected && customerOrder && customerItem) {
				req = usageRights.getOwnershipsByPipedata(
					warehouse.id,
					endUserSelected,
					customerOrder,
					customerItem,
					0,
					token
				)
			} else if (mainSource === 'SALES_ITEM' && endUserSelected && salesOrder && salesItem) {
				req = usageRights.getOwnershipsByPipedata(warehouse.id, endUserSelected, salesOrder, salesItem, 1, token)
			} else {
				resolve(null)
			}
			req.then(response => {
				resolve()
				let lastLevel = Math.max.apply(
					Math,
					response.map(res => res.level)
				)
				let lastLevelDesc = _.filter(response, res => {
					return res.level === lastLevel
				})
				let level = []
				_.forEach(lastLevelDesc, owner => {
					level.push({ id: owner.id, label: owner.namepath.join('.') })
				})
				setOwnerships(level)
			}).catch(e => {
				reject(e)
				toast.error(`${t('wms:ErrorGettingOwnershipEndUser')} [ ${e.status}]: ${e.data}`)
				console.error(e)
			})
		})

	const searchPipe = () =>
		new Promise((resolve, reject) => {
			setLoadingData(true)
			if (valid) {
				actual
					.searchPipe(warehouse.id, valid, null, null, null, token)
					.then(response => {
						resolve()
						response.length = response.actuallength
						response.weight = response.actualweight
						handleCertificateVersions(response.certificateref)
						setPipeData(response)
						setLoadingData(false)
						setValid('')
					})
					.catch(e => {
						console.error(e)
						e.status === 404
							? toast.error(`${t('wms:MaterialNotFound')}`)
							: toast.error(`${t('wms:ErrorGettingContentByItem')} [ ${e.status}] ${e.data}`)
						setLoadingData(false)
					})
					.finally(() => {
						setLoadingData(false)
					})
			}
			if (ippn) {
				actual
					.searchPipe(warehouse.id, null, ippn, customerOrder, customerItem, token)
					.then(response => {
						resolve()
						response.length = convertToCurrentLengthUnit(
							getLengthDisplay(warehouse, response.actuallength),
							lengthUnit
						)
						response.weight = convertToCurrentWeightUnit(
							getWeightDisplay(warehouse, response.actualweight),
							weightUnit
						)
						response.length = parseFloat(response.length.replace(/\./g, '').replace(',', '.'))
						response.weight = parseFloat(response.weight.replace(/\./g, '').replace(',', '.'))
						handleCertificateVersions(response.certificateref)
						setPipeData(response)
						setLoadingData(false)
						setIPPN('')
						setEndUserSelected(null)
						setCustomerOrder(null)
						setCustomerItem(null)
					})
					.catch(e => {
						console.error(e)
						e.status === 404
							? toast.error(`${t('wms:MaterialNotFound')}`)
							: toast.error(`${t('wms:ErrorGettingContentByItem')} [ ${e.status}] ${e.data}`)
						setLoadingData(false)
					})
					.finally(() => {
						setLoadingData(false)
					})
			}
		})

	const getAvailablePipes = () =>
		new Promise((resolve, reject) => {
			let req = null
			if (mainSource === 'CUSTOMER_ITEM') {
				req = usageRights.getPipesByOrderItem(
					warehouse.id,
					endUserSelected,
					customerOrder,
					customerItem,
					selectedOwnership,
					0,
					token
				)
			} else if (mainSource === 'SALES_ITEM') {
				req = usageRights.getPipesByOrderItem(
					warehouse.id,
					endUserSelected,
					salesOrder,
					salesItem,
					selectedOwnership,
					1,
					token
				)
			}
			req.then(response => {
				let bundles = response
				let items = bundleOrItems(warehouse, bundles)
				resolve(items)
			}).catch(e => {
				reject(e)
				console.error(e)
				toast.error(`${t('wms:ErrorReadingContent')} [ ${e.status}]: ${e.data}`)
			})
		})

	const handleCertificateVersions = certificateRef => {
		let cert = _.find(certificates, c => c.number === certificateRef)
		if (cert === undefined) return
		setCertificateVersions(cert.version)
	}

	const getCertificateID = ({ certificateref, certificateversion }) =>
		deepNotNull(certificateref) && deepNotNull(certificateversion)
			? rawCertificateList.find(c => c.number === certificateref && c.version === certificateversion).id
			: null

	const saveCertificate = (params, file, type) =>
		new Promise((resolve, reject) => {
			if (window.FileReader) {
				const reader = new FileReader()
				let payload = {}
				reader.onload = event => {
					payload = {
						number: params.number,
						version: params.version,
						TypeFile: type,
						filestream: event.target.result,
						wid: warehouse.id
					}
					event.target.result &&
						actual
							.createCertificate(payload, token)
							.then(response => {
								resolve()
								toast.success(t('wms:CertificateCreated'))
								getCertificates()
								setAddCertificate(false)
							})
							.catch(e => {
								console.error(e)
								reject(e)
								toast.error(`${t('wms:ErrorCreatingCertificate')} [ ${e.status} ]: ${e.data}`)
							})
				}
				reader.onerror = () => {
					toast.error(t('wms:ErrorReadingFile'))
				}
				reader.readAsDataURL(file)
			} else reject('Filereader not found')
		})

	const modifPipes = () =>
		new Promise((resolve, reject) => {
			setSavingChanges(true)
			let payload = pipeData
				? {
						responsible: user.name,
						items: [
							{
								valid: pipeData.pipevalid,
								length: convertLengthToSend(pipeData.length),
								weight: convertWeightToSend(pipeData.weight),
								puuid: pipeData.pipepuuid,
								heat: pipeData.heat,
								consignmenttype: pipeData.consignmenttype,
								certificateid: getCertificateID(pipeData)
							}
						]
				  }
				: {
						responsible: user.name,
						items: selectedPipes.map(pipe => ({
							valid: pipe.valid,
							length: newInfo.length ? convertLengthToSend(newInfo.length) : convertLengthToSend(pipe.length),
							weight: newInfo.weight ? convertWeightToSend(newInfo.weight) : convertWeightToSend(pipe.weight),
							puuid: pipe.puuid,
							heat: newInfo.heat ? newInfo.heat : pipe.heat,
							consignmenttype: newInfo.consignmenttype ? newInfo.consignmenttype : pipe.consignmenttype,
							certificateid: getCertificateID(newInfo) ? getCertificateID(newInfo) : pipe.certificateid
						}))
				  }
			actual
				.modifyPipeOrBatch(warehouse.id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:PipeDataModified'))
					setNewInfo({
						consignmenttype: null,
						heat: '',
						certificateref: null,
						certificateversion: null,
						length: '',
						weight: ''
					})
					setEndUserSelected(null)
					setSelectedPipes([])
					setSelectedOwnership(null)
					setCustomerItem(null)
					setCustomerOrder(null)
					setSalesItem(null)
					setSalesOrder(null)
					setAvailablePipes(null)
					setSelectedType(null)
					setSavingChanges(false)
					setOpenConfirmModal(false)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorModifying')} [ ${e.status} ]: ${e.data}`)
					setSavingChanges(false)
				})
				.finally(() => {
					setSavingChanges(false)
				})
		})

	const deepNotNull = value => {
		return notNull(value) && notEmpty(value) && notUndefined(value)
	}

	const notNull = value => {
		return value !== null
	}

	const notEmpty = value => {
		return value !== ''
	}

	const notUndefined = value => {
		return value !== undefined
	}

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getEndUsers()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getCertificates()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingCO(true)
				endUserSelected && (await getPOList())
			} catch (e) {
				console.error(e)
				setLoadingCO(false)
			} finally {
				setLoadingCO(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, endUserSelected])

	useEffect(() => {
		setFilteredPoItems([])
		customerOrder && setFilteredPoItems(poItems[customerOrder])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerOrder])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingSO(true)
				endUserSelected && (await getSalesList())
			} catch (e) {
				console.error(e)
				setLoadingSO(false)
			} finally {
				setLoadingSO(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, endUserSelected])

	useEffect(() => {
		setFilteredSalesItems([])
		salesOrder && setFilteredSalesItems(salesItems[salesOrder])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [salesOrder])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingOwnerships(true)
				mainSource && (await getOwnershipByPipeData())
			} catch (e) {
				console.error(e)
				setLoadingOwnerships(false)
			} finally {
				setLoadingOwnerships(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, endUserSelected, customerOrder, customerItem, salesOrder, salesItem, mainSource])

	useEffect(() => {
		setPipeData(null)
		setAvailablePipes(null)
		setSelectedPipes([])
		setNewInfo({
			consignmenttype: null,
			heat: '',
			certificateref: null,
			certificateversion: null,
			length: '',
			weight: ''
		})
		setEndUserSelected(null)
		setSelectedOwnership(null)
		setCustomerItem(null)
		setCustomerOrder(null)
		setSalesOrder(null)
		setSalesItem(null)
		setMainSource(null)
	}, [selectedType])

	const renderStore = (
		<ActualDataContext.Provider
			value={{
				endUsers,
				setEndUsers,
				endUserSelected,
				setEndUserSelected,
				certificates,
				setCertificates,
				poList,
				setPOList,
				poNumbers,
				setPONumbers,
				customerOrder,
				setCustomerOrder,
				customerItem,
				setCustomerItem,
				poItems,
				setPOItems,
				filteredPoItems,
				setFilteredPoItems,
				valid,
				setValid,
				ippn,
				setIPPN,
				searchPipe,
				pipeData,
				setPipeData,
				loadingData,
				setLoadingData,
				addCertificate,
				setAddCertificate,
				certificateVersions,
				setCertificateVersions,
				addCertificateVersion,
				setAddCertficateVersion,
				selectedType,
				setSelectedType,
				loadingOwnerships,
				setLoadingOwnerships,
				mainSource,
				setMainSource,
				ownerships,
				setOwnerships,
				salesNumbers,
				setSalesNumbers,
				salesItems,
				setSalesItems,
				salesOrder,
				setSalesOrder,
				salesItem,
				setSalesItem,
				filteredSalesItems,
				setFilteredSalesItems,
				selectedOwnership,
				setSelectedOwnership,
				availablePipes,
				setAvailablePipes,
				selectedPipes,
				setSelectedPipes,
				loadingPipes,
				setLoadingPipes,
				getAvailablePipes,
				newInfo,
				setNewInfo,
				loadingCO,
				setLoadingCO,
				loadingSO,
				setLoadingSO,
				saveCertificate,
				rawCertificateList,
				setRawCertificateList,
				modifPipes,
				openConfirmModal,
				setOpenConfirmModal,
				savingChanges,
				setSavingChanges,
				selectedSource,
				setSelectedSource
			}}>
			{children}
		</ActualDataContext.Provider>
	)
	return renderStore
}
