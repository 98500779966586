import React from 'react'
import Status from './pages/Status'
import Logs from './pages/Logs'
import Users from './pages/Users'
import PrivateRoute from 'containers/router/PrivateRoute'
import { StatusProvider } from './stores/StatusStore'
import { LogsProvider } from './stores/LogsStore'
import { UsersProvider } from './stores/UsersStore'
import './i18n'

export const getSideMenu = (user, redirectTo, translate) => [
  {
    key: 'admin',
    label: translate('admin:Admin'),
    roles: ['administrators'],
    items: [
      {
        key: 'admin-status',
        icon: 'info',
        label: 'Status',
        roles: ['administrators','logs'],
        onClick: redirectTo('/admin/status')
      },
      {
        key: 'admin-logs',
        icon: 'calendar',
        label: 'Logs',
        roles: ['administrators','logs'],
        onClick: redirectTo('/admin/logs')
      },
      {
        key: 'admin-user',
        icon: 'user',
        label: 'Users',
        roles: ['administrators'],
        onClick: redirectTo('/admin/users')
      }
    ]
  }
]

const Router = () => {
  return (
    <>
      <PrivateRoute roles={['administrators','logs']} path='/admin'>
        <PrivateRoute roles={['administrators','logs']} path='/admin/status'>
          <StatusProvider>
            <Status />
          </StatusProvider>
        </PrivateRoute>
        <PrivateRoute roles={['administrators','logs']}path='/admin/logs'>
          <LogsProvider>
            <Logs />
          </LogsProvider>
        </PrivateRoute>
        <PrivateRoute roles={['administrators']} path='/admin/users'>
          <UsersProvider>
            <Users />
          </UsersProvider>
        </PrivateRoute>
      </PrivateRoute>
    </>
  )
}

export default Router
