/** @format */
const renderHeader = function (currentPage, pageCount, pageSize) {
	return [
		{
			text: 'Page ' + currentPage,
			alignment: 'right',
			marginTop: 30,
			marginRight: 56.692913386,
			fontSize: 10
		}
	]
}

const renderFooter = function (issuedOn, responsible) {
	return {
		columns: [
			{
				text: `Emitted on ${issuedOn} by ${responsible}`,
				marginTop: 10,
				marginLeft: 56.692913386,
				fontSize: 10,
				italics: true
			},
			{
				text: 'Form No. F-QMS-DN, Rev. 01',
				alignment: 'right',
				marginTop: 10,
				marginRight: 56.692913386,
				fontSize: 10,
				italics: true
			}
		]
	}
}

const renderIdentification = function (identification) {
	const identificationDefinition = [
		{
			columns: [{ text: identification.title.toUpperCase(), style: ['page_title'] }]
		},
		{
			columns: [
				{ text: identification.ownership, fontSize: 15 },
				{ text: 'Form No. F-QMS-DN, Rev. 01', style: ['doc_version'] }
			],
			marginTop: 9.448818898 // .25cm
		}
	]
	if (identification.logo) {
		identificationDefinition[0].columns.push({
			image: identification.logo,
			width: 125
		})
	}
	return identificationDefinition
}

const renderDetails = function (details) {
	const detailsDefinition = [{ text: 'DETAILS', style: ['section_title'] }]
	for (let i = 0; i < Math.round(details.length / 3); i++) {
		const columns = []
		for (let j = i * 3; j <= i * 3 + 2; j++) {
			columns.push(
				details[j]
					? {
							stack: [
								{ text: details[j].field.toUpperCase(), style: ['label'] },
								{ text: details[j].value, style: ['field'] }
							]
					  }
					: {}
			)
		}
		const row = {
			columns,
			columnGap: 20,
			marginBottom: 10
		}
		detailsDefinition.push(row)
	}
	return detailsDefinition
}

const renderOverallWeight = function (overallWeight) {
	return {
		layout: 'noBorders',
		table: {
			widths: ['auto', '*', '*', 'auto'],
			body: [
				[
					{ fillColor: '#E29E8D', text: '' },
					{
						fillColor: '#E29E8D',
						text: 'Overall Weight:',
						fontSize: 18
					},
					{
						fillColor: '#E29E8D',
						text: overallWeight,
						fontSize: 18,
						alignment: 'right'
					},
					{ fillColor: '#E29E8D', text: '' }
				]
			]
		}
	}
}

const renderMaterials = function (materials) {
	const materialsDefinition = [{ text: 'MATERIALS', style: ['section_title'] }]
	materialsDefinition.push(
		materials.map((m, i) => ({
			table: {
				widths: '*',
				body: [
					[
						{
							border: [true, true, true, false],
							text: `Item #${i + 1}`,
							fontSize: 10,
							bold: true,
							margin: [5, 5, 5, 0]
						}
					],
					[
						{
							border: [true, false, true, true],
							margin: [5, 3],
							layout: 'noBorders',
							table: {
								widths: '*',
								body: [
									[
										{
											layout: 'noBorders',
											table: {
												widths: ['60%', '*'],
												body: [
													[
														[
															{
																text: 'CUSTOMER PO#ITEM',
																style: ['card_label']
															},
															{ text: `${m.customerpo} #${m.customeritem}` }
														],
														[
															{
																text: 'SALES PO#ITEM',
																style: ['card_label']
															},
															{ text: `${m.salespo} #${m.salesitem}` }
														]
													]
												]
											}
										}
									],
									[
										{
											layout: 'noBorders',
											table: {
												widths: '*',
												body: [
													[
														[
															{
																text: 'ERP REFERENCE',
																style: ['card_label']
															},
															{ text: m.erpref }
														]
													]
												]
											}
										}
									],
									[
										{
											layout: 'noBorders',
											table: {
												widths: ['auto', '*', 'auto'],
												body: [
													[
														{ fillColor: '#B9D5DF', text: '' },
														{
															fillColor: '#B9D5DF',
															text: 'MATERIAL DESCRIPTION',
															fontSize: 12
														},
														{ fillColor: '#B9D5DF', text: '' }
													],
													[
														{ fillColor: '#B9D5DF', text: '' },
														{
															fillColor: '#B9D5DF',
															text: m.matdesc,
															fontSize: 16
														},
														{ fillColor: '#B9D5DF', text: '' }
													]
												]
											},
											margin: [0, 5]
										}
									],
									[
										{
											layout: 'noBorders',
											table: {
												widths: ['20%', '50%', '30%'],
												body: [
													[
														[
															{ text: 'TOTAL JOINTS', style: 'card_label' },
															{ text: m.totaljoints, fontSize: 12 }
														],
														[
															{
																text: 'TOTAL MEASURED LENGTH (ft)',
																style: 'card_label'
															},
															{ text: m.totalmeasuredlength, fontSize: 12 }
														],
														[
															{
																text: 'TOTAL WEIGHT (kg)',
																style: 'card_label'
															},
															{ text: m.totalweight, fontSize: 12 }
														]
													]
												]
											}
										}
									]
								]
							}
						}
					]
				]
			},
			marginBottom: 15
		}))
	)
	return materialsDefinition
}

const renderSignatures = function () {
	return [
		{ text: 'SHIPPER', style: ['signature_title'], pageBreak: 'before' },
		{
			columns: [
				{ width: '*', text: '' },
				{
					width: 'auto',
					layout: 'noBorders',
					table: {
						widths: ['*', 'auto'],
						body: [
							[
								{
									text: 'Name:',
									style: ['signature_label'],
									marginBottom: 15
								},
								{
									text: '________________________________________________________'
								}
							],
							[
								{
									text: 'Signature:',
									style: ['signature_label'],
									marginBottom: 15
								},
								{
									text: '________________________________________________________'
								}
							],
							[
								{
									text: 'Date:',
									style: ['signature_label'],
									marginBottom: 15
								},
								{
									text: '________________________________________________________'
								}
							]
						]
					}
				},
				{ width: '*', text: '' }
			]
		},
		{
			columns: [
				{ width: '*', text: '' },
				{
					width: 'auto',
					table: {
						widths: [400],
						body: [
							[
								{
									text: 'Stamp:',
									style: ['signature_label'],
									marginBottom: 100
								}
							]
						]
					}
				},
				{ width: '*', text: '' }
			]
		},
		{ text: 'RECEIVER', style: ['signature_title'], marginTop: 40 },
		{
			columns: [
				{ width: '*', text: '' },
				{
					width: 'auto',
					layout: 'noBorders',
					table: {
						widths: ['*', 'auto'],
						body: [
							[
								{
									text: 'Name:',
									style: ['signature_label'],
									marginBottom: 15
								},
								{
									text: '________________________________________________________'
								}
							],
							[
								{
									text: 'Signature:',
									style: ['signature_label'],
									marginBottom: 15
								},
								{
									text: '________________________________________________________'
								}
							],
							[
								{
									text: 'Date:',
									style: ['signature_label'],
									marginBottom: 15
								},
								{
									text: '________________________________________________________'
								}
							]
						]
					}
				},
				{ width: '*', text: '' }
			]
		},
		{
			columns: [
				{ width: '*', text: '' },
				{
					width: 'auto',
					table: {
						widths: [400],
						body: [
							[
								{
									text: 'Stamp:',
									style: ['signature_label'],
									marginBottom: 100
								}
							]
						]
					}
				},
				{ width: '*', text: '' }
			]
		}
	]
}

const docDefinition = function (deliveryNote) {
	const dd = {
		header: renderHeader,
		footer: renderFooter(deliveryNote.issuedOn, deliveryNote.responsible),
		content: [
			...renderIdentification(deliveryNote.identification),
			...renderDetails(deliveryNote.details),
			renderOverallWeight(deliveryNote.overallweight),
			...renderMaterials(deliveryNote.materials),
			...renderSignatures()
		],
		styles: {
			page_title: {
				bold: true,
				width: 'auto',
				fontSize: 24
			},
			doc_version: {
				fontSize: 14,
				alignment: 'right',
				marginTop: 4
			},
			section_title: {
				fontSize: 14,
				marginTop: 20,
				marginBottom: 10
			},
			label: {
				bold: true,
				fontSize: 12,
				marginBottom: 2
			},
			field: {
				fontSize: 16
			},
			card_label: {
				bold: true,
				fontSize: 12,
				marginBottom: 2
			},
			signature_title: {
				fontSize: 24,
				alignment: 'center',
				marginBottom: 18.897637795 // 0.5cm
			},
			signature_label: {
				fontSize: 16
			}
		},
		defaultStyle: {
			font: 'Calibri'
		},
		pageSize: 'A4',
		pageMargins: [56.692913386, 75.590551181, 56.692913386, 47.244094488] // 1.5cm, 2cm, 1.5cm, 1.25cm
	}
	return dd
}

export default docDefinition
