/** @format */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, Icon, Modal, LoadingOverlay } from 'components/common'
import { AssetsMaterialContext } from 'apps/wms/stores/AssetsMaterialStore'
import Select from 'apps/wms/components/forms/Select'
import { Input } from 'components/form'
import styled from 'styled-components'
import { WMSContext } from 'apps/wms/stores/WmsStore'

const SInput = styled(Input)`
	min-height: unset;
	font-weight: normal;
	margin: 0px;
	border-width: 1px;
`

const MaterialEditModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { materialConfig, attributesList, updateMaterial, user, materialToEdit } = useContext(AssetsMaterialContext)
	const { lengthUnit, weightUnit } = useContext(WMSContext)

	const [isLoading, setIsLoading] = useState(false)

	const getAttrValue = (attr, material) => {
		if (!material) return 0
		let valueIndex = material.descriptions.findIndex(desc => desc === attr.description)
		if (valueIndex === -1) return 0
		return material.attributes[valueIndex]
	}

	const attritbutesDataModel = useCallback((attributesList, material, materialConfig) => {
		let obj = {}
		attributesList
			.filter(a => (materialConfig ? materialConfig.attributeids.find(id => id === a.id) : true))
			.forEach(a => (obj[a.name] = getAttrValue(a, material)))
		return obj
	}, [])

	const [materialAttributes, setMaterialAttributes] = useState(() =>
		attritbutesDataModel(attributesList, materialToEdit, materialConfig)
	)

	useEffect(
		() => setMaterialAttributes(attritbutesDataModel(attributesList, materialToEdit, materialConfig)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[attributesList, materialToEdit]
	)

	const materialDataModel = useCallback(
		() => ({
			formatted: materialToEdit ? materialToEdit.formatted : '',
			attributevalueids: [],
			erpreference: materialToEdit ? materialToEdit.erpreference : '',
			erpreferencedesc: materialToEdit
				? materialToEdit.erpreferencedesc
					? materialToEdit.erpreferencedesc
					: ''
				: '',
			weightkg: materialToEdit ? materialToEdit.weightkg : '',
			lengthmm: materialToEdit ? materialToEdit.lengthmm : '',
			enduser: materialToEdit ? materialToEdit.enduserid : 0,
			enduserid: materialToEdit ? materialToEdit.enduserid : 0,
			responsible: user.name
		}),
		[materialToEdit, user]
	)

	const [materialData, setMaterialData] = useState(() => materialDataModel())

	useEffect(
		() => setMaterialData(materialDataModel()),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[materialToEdit]
	)

	const isCrossOver = useMemo(() => {
		const productTypeAttribute = materialConfig
			? attributesList.find(a => a.id === materialConfig.producttypeattributeid)
			: null
		return productTypeAttribute
			? materialAttributes[productTypeAttribute.name] === materialConfig.crossoverattributevalue
			: false
	}, [materialConfig, attributesList, materialAttributes])

	const isMandatory = attribute =>
		attribute.mandatory && (!attribute.mandatoryextension || (attribute.mandatoryextension && isCrossOver))

	const onEditMaterial = async () => {
		try {
			setIsLoading(true)
			const payload = {
				...materialData,
				weightkg: materialData.weightkg,
				lengthmm: materialData.lengthmm,
				attributevalueids: [...Object.values(materialAttributes)]
			}
			await updateMaterial(materialToEdit.mvid, payload)
			setIsLoading(false)
			onOutsideClick()
		} catch (e) {
			setIsLoading(false)
			console.error(e)
		}
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card
				borderRadius='0'
				backgroundColor='background'
				maxWidth='calc(30% - 32px)'
				height='calc(100% - 32px)'
				maxHeight='100vh'
				flex='1'
				margin='0 0 0 auto'
				padding='16px'
				alignItems='center'
				alignSelf='flex-end'
				style={{ position: 'relative', overflowY: 'scroll' }}
				data-cy='card-new-material-wrapper'>
				<LoadingOverlay visible={isLoading} />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:Update')} {t('wms:Material')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flexDirection='column' justifyContent='space-between'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 8px 0'>
						{t('wms:Description')}
					</FlexView>
					<FlexView width='100%' margin='0 0 8px 0'>
						{materialData.formatted}
					</FlexView>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 8px 0'>
						{t('wms:MaterialDefaultData')}
					</FlexView>
					<FlexView flexDirection='row' width='100%' justifyContent='space-between'>
						<FlexView
							fontSize='small'
							color='metalic'
							flexDirection='column'
							margin='0'
							padding='0'
							width='45%'
							justifyContent='space-between'>
							{`${t('wms:Length')} [${lengthUnit}]`}
							<SInput
								width='100%'
								margin='4px 0 0 0'
								type='number'
								min='0'
								max='20000'
								value={materialData.lengthmm}
								onChange={e => {
									parseInt(e.target.value) &&
										e.target.value >= 0 &&
										setMaterialData({ ...materialData, lengthmm: e.target.value })
								}}
								fontSize='small'
								data-cy='input-material-length'
							/>
						</FlexView>
						<FlexView
							fontSize='small'
							color='metalic'
							flexDirection='column'
							margin='0'
							padding='0'
							width='45%'
							justifyContent='space-between'>
							{`${t('wms:Weight')} [${weightUnit}]`}
							<SInput
								width='100%'
								margin='4px 0 0 0'
								type='number'
								min='0'
								max='20000'
								value={materialData.weightkg}
								onChange={e => {
									parseInt(e.target.value) &&
										e.target.value >= 0 &&
										setMaterialData({ ...materialData, weightkg: e.target.value })
								}}
								fontSize='small'
								data-cy='input-material-weight'
							/>
						</FlexView>
					</FlexView>

					{attributesList.length &&
					attributesList
						.filter(a => (materialConfig ? materialConfig.attributeids.find(id => id === a.id) : true))
						.filter(a => !a.mandatory && !a.mandatoryextension).length ? (
						<>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 8px 0'>
								{t('wms:OptionalAttributes')}
							</FlexView>
							<FlexView width='100%' flexDirection='row' flexWrap='wrap' justifyContent='space-between'>
								{attributesList.length &&
									attributesList
										.filter(a =>
											materialConfig ? materialConfig.attributeids.find(id => id === a.id) : true
										)
										.filter(a => !a.mandatory && !a.mandatoryextension)
										.map((attr, index) => (
											<FlexView
												key={attr.id}
												fontSize='small'
												color='metalic'
												flexDirection='column'
												margin='0 0 8px 0'
												padding='0'
												width='45%'
												justifyContent='space-between'>
												{attr.name}
												<Select
													key={index}
													placeholder={attr.symbol ? `${attr.name} [${attr.symbol}]` : attr.name}
													fontSize='small'
													value={materialAttributes[attr.name]}
													options={[
														{ value: 0, label: t('wms:HideValue') },
														...attr.values.map(({ id, value }) => ({
															value: id,
															label: value
														}))
													]}
													onChange={value => {
														setMaterialAttributes({ ...materialAttributes, [attr.name]: value })
													}}
													inline={false}
													width='100%'
													data-cy='select-source-enduser'
												/>
											</FlexView>
										))}
							</FlexView>
						</>
					) : null}

					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 8px 0'>
						{t('wms:MandatoryAttributes')}
					</FlexView>

					<FlexView flexDirection='row' flexWrap='wrap' justifyContent='space-between'>
						{attributesList.length &&
							attributesList
								.filter(a => a.mandatory || a.mandatoryextension)
								.map((attr, index) =>
									isMandatory(attr) ? (
										<FlexView
											key={attr.id}
											fontSize='small'
											color='metalic'
											flexDirection='column'
											margin='0 0 8px 0'
											padding='0'
											width='45%'
											justifyContent='space-between'>
											{attr.symbol ? `${attr.name} [${attr.symbol}]` : attr.name}
											<FlexView color='black'>
												{attr.values.find(a => a.id === materialAttributes[attr.name])
													? attr.values.find(a => a.id === materialAttributes[attr.name]).value
													: t('wms:NotSet')}
											</FlexView>
										</FlexView>
									) : null
								)}
					</FlexView>
					<Button
						fontSize='small'
						width='90%'
						margin='24px auto 0 auto'
						padding='8px 16px'
						color='white'
						backgroundColor='secondary'
						onClick={() => onEditMaterial()}>
						{`${t('wms:UpdateMaterialDescription')}`}
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default MaterialEditModal
