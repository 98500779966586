/** @format */

import { REPORT_ITEM, REPORT_ITEM_STATUS, REPORT_TYPE } from 'apps/wms/utils/enum'

export const shouldConfirmInspectionItemModification = currentReport => currentReport === REPORT_ITEM_STATUS.code.NOTOK
export const shouldConfirmRejectReasonModification = currentReport => currentReport >= 9

const changeReportInspectionResponsible = (report, supervisor) => ({ ...report, responsible: supervisor })
const changeReportModifyStatus = report => ({ ...report, modified: true })
const changeReportInstrumentCase = (report, caseid) => ({ ...report, instlistid: caseid })
const changeReportInstrumentList = (report, list) => ({ ...report, instrumentlist: list })

const setReportItemPhoto = (reportItem, photoData) => ({ ...reportItem, photo: photoData })
const resetReportItemPhoto = reportItem => ({ ...reportItem, photo: 'N' })
const resetReportItemRejectReason = (report, reportItem, reportValue) => ({
	...report,
	[REPORT_ITEM.accessor[reportItem]]: { photo: 'N', defect: 0, report: reportValue }
})
const changeReportInspectionStatus = (report, inspectionStatus) => ({ ...report, finalreport: inspectionStatus })
const setReportInspectionAccepted = report => changeReportInspectionStatus(report, REPORT_ITEM_STATUS.code.ACCEPT)
const setReportInspectionRepair = report => changeReportInspectionStatus(report, REPORT_ITEM_STATUS.code.REPAIR)
//const setReportInspectionRejected = (report) => changeReportInspectionStatus(report, REPORT_ITEM_STATUS.code.REJECT)
export const isReportItemOk = reportItemValue => reportItemValue === REPORT_ITEM_STATUS.code.OK
export const isReportItemNA = reportItemValue => reportItemValue === REPORT_ITEM_STATUS.code.NA
export const isReportItemAcceptable = reportItemValue =>
	isReportItemOk(reportItemValue) || isReportItemNA(reportItemValue)
export const isReportItemRejectionNotConsistent = reportItem =>
	reportItem.report === REPORT_ITEM_STATUS.code.NOTOK && reportItem.defect < 9
export const isStorageReport = reportType =>
	reportType === REPORT_TYPE.code.RECINSP || reportType === REPORT_TYPE.code.PERIODICAL

export const isReportInspectionStatusAcceptable = (report, reportType) => {
	return (
		isReportItemAcceptable(report.pin.report) &&
		isReportItemAcceptable(report.box.report) &&
		isReportItemAcceptable(report.intbody.report) &&
		isReportItemAcceptable(report.extbody.report) &&
		isReportItemAcceptable(report.drift.report) &&
		isReportItemAcceptable(report.marklegibility.report) &&
		isReportItemAcceptable(report.pin.report) &&
		(isStorageReport(reportType)
			? isReportItemAcceptable(report.coatingvarnish.report) && isReportItemAcceptable(report.threadprot.report)
			: true)
	)
}

export const isReportInspectionNotConsistent = (report, reportType) => {
	return (
		isReportItemRejectionNotConsistent(report.pin) ||
		isReportItemRejectionNotConsistent(report.box) ||
		isReportItemRejectionNotConsistent(report.intbody) ||
		isReportItemRejectionNotConsistent(report.extbody) ||
		isReportItemRejectionNotConsistent(report.drift) ||
		isReportItemRejectionNotConsistent(report.marklegibility) ||
		(isStorageReport(reportType)
			? isReportItemRejectionNotConsistent(report.coatingvarnish) ||
			  isReportItemRejectionNotConsistent(report.threadprot)
			: false)
	)
}

export const changeReportInspectionData = (currentReport, reportType, reportItem, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = resetReportItemRejectReason(newReport, reportItem, newReport[REPORT_ITEM.accessor[reportItem]].report)
	newReport = isReportInspectionStatusAcceptable(newReport, reportType)
		? setReportInspectionAccepted(newReport)
		: setReportInspectionRepair(newReport)
	return newReport
}

export const changeReportRejectReason = (currentReport, reportItem, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = {
		...newReport,
		[REPORT_ITEM.accessor[reportItem]]: resetReportItemPhoto(newReport[REPORT_ITEM.accessor[reportItem]])
	}
	return newReport
}

export const changeReportPhoto = (currentReport, reportItem, photoData, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = {
		...newReport,
		[REPORT_ITEM.accessor[reportItem]]: setReportItemPhoto(newReport[REPORT_ITEM.accessor[reportItem]], photoData)
	}
	return newReport
}

export const hasMeasureData = reportType =>
	reportType === REPORT_TYPE.code.RIGPREP ||
	reportType === REPORT_TYPE.code.TPWORKSHOP ||
	reportType === REPORT_TYPE.code.MATMODIF ||
	reportType === REPORT_TYPE.code.PHYSTRANS ||
	reportType === REPORT_TYPE.code.MODIF_PRODUCTS

const changeReportMeasureResponsible = (report, supervisor) => ({ ...report, measureresponsible: supervisor })
const changeReportEffectiveLength = report => ({ ...report, effective: report.measured - report.makeuploss })

export const changeReportMeasureData = (currentReport, reportType, supervisor) => {
	if (!hasMeasureData(reportType)) return currentReport
	var newReport = { ...currentReport }
	newReport = changeReportMeasureResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeReportEffectiveLength(newReport)
	return newReport
}

const isRigPrepReport = reportType => reportType === REPORT_TYPE.code.RIGPREP
const changeManufacturer = (report, manufacturer) => ({
	...report,
	manufacturer: { ...report.manufacturer, id: manufacturer }
})
const changeBatchNumber = (report, number) => ({ ...report, batchnumber: number })
const changeApplicationMethod = (report, method) => ({
	...report,
	applicationmethod: { ...report.applicationmethod, id: method }
})
const changeTCResponsible = (report, supervisor) => ({ ...report, tcresponsible: supervisor })

export const changeManufacturerData = (currentReport, reportType, supervisor, manufacturer) => {
	if (!isRigPrepReport(reportType)) return currentReport
	var newReport = { ...currentReport }
	newReport = changeTCResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeManufacturer(newReport, manufacturer)
	return newReport
}

export const changeBatchNumberData = (currentReport, reportType, supervisor, batchNumber) => {
	if (!isRigPrepReport(reportType)) return currentReport
	var newReport = { ...currentReport }
	newReport = changeTCResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeBatchNumber(newReport, batchNumber)
	return newReport
}

export const changeApplicationMethodData = (currentReport, reportType, supervisor, method) => {
	if (!isRigPrepReport(reportType)) return currentReport
	var newReport = { ...currentReport }
	newReport = changeTCResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeApplicationMethod(newReport, method)
	return newReport
}

const isReportFinalStatusAccepted = report => report.finalreport === REPORT_ITEM_STATUS.code.ACCEPT

export const isReportFinalStatusConsistent = (report, reportType) => {
	return isReportFinalStatusAccepted(report)
		? isReportInspectionStatusAcceptable(report, reportType)
		: !isReportInspectionStatusAcceptable(report, reportType)
}

export const changeReportFinalStatus = (currentReport, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	return newReport
}

export const changeReportData = (currentReport, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	return newReport
}

export const changeInstrumentsData = (currentReport, caseId, instrumentList, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportMeasureResponsible(newReport, supervisor)
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeReportInstrumentCase(newReport, caseId)
	newReport = changeReportInstrumentList(newReport, instrumentList)
	return newReport
}

export const isMR = reportType =>
	reportType === REPORT_TYPE.code.RIGPREP ||
	reportType === REPORT_TYPE.code.TPWORKSHOP ||
	reportType === REPORT_TYPE.code.MATMODIF ||
	reportType === REPORT_TYPE.code.PHYSTRANS

export const hasFewerItems = (plannedQty, actualQty) => actualQty < plannedQty

export const shouldAllowReportReopen = (reportType, plannedQty, actualQty) =>
	(hasFewerItems(plannedQty, actualQty) && isMR(reportType)) || isReceiptNoteReport(reportType)

export const isAgeManagementReport = reportType => reportType === REPORT_TYPE.code.AGE_EXTRACTS

export const isReceiptNoteReport = reportType => reportType === REPORT_TYPE.code.MILL_RECEIPT

export const isPeriodicInspReport = reportType => reportType === REPORT_TYPE.code.PERIODICAL
