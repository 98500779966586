/** @format */

import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import materialdata from '../api/assetsMaterial'
import attributesdata from '../api/assetsAttributes'

export const AssetsMaterialContext = React.createContext()

export const AssetsMaterialProvider = ({ children }) => {
	const { warehouse } = useContext(WMSContext)
	const { user, token } = useContext(UserContext)
	const { t } = useTranslation()

	const [materialConfig, setMaterialConfig] = useState(null)
	const [materialList, setMaterialList] = useState([])
	const [attributesList, setAttributesList] = useState([])
	const [isSyncOn, setIsSyncOn] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [enduserList, setEnduserList] = useState([])
	const [isMatHandlerOpen, setIsMatHandlerOpen] = useState(false)
	const [isMatEditorOpen, setIsMatEditorOpen] = useState(false)
	const [materialToEdit, setMaterialToEdit] = useState(null)

	const _importMaterial = (enduser, wid, token) =>
		new Promise((resolve, reject) => {
			materialdata
				.importMaterials(enduser, wid, token)
				.then(response => {
					toast.success(t(`wms:MaterialImported`))
					resolve(response)
				})
				.catch(e => {
					toast.error(t(`${t('wms:ErrorImportingMaterial')}. ${e.data}`))
					reject(e)
				})
				.finally(() => {})
		})

	const importMaterial = useCallback(
		enduser => {
			return _importMaterial(enduser, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const newMaterial = useCallback(
		data => {
			return _newMaterial(data, warehouse.id, user, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, user, token]
	)

	const _newMaterial = (data, wid, user, token) =>
		new Promise((resolve, reject) => {
			const payload = {
				attributevalueids: data.attributes,
				formatstring: data.formatstring,
				erpreference: data.erpreference,
				erpreferencedesc: data.erpdescription,
				weightkg: data.weightkg,
				lengthmm: data.lengthmm,
				ismodifiedproduct: data.ismodifiedproduct,
				modifiedproductdesc: data.modifiedproductdesc === null ? '' : data.modifiedproductdesc,
				enduser: data.enduser.id,
				responsible: user.name
			}
			materialdata
				.createMaterial(payload, wid, token)
				.then(response => {
					toast.success(t(`wms:MaterialCreated`))
					resolve(response)
				})
				.catch(e => {
					toast.error(t(`${t('wms:ErrorCreatingMaterial')} [ ${e.status} ]: ${e.data}`))
					reject(e)
				})
				.finally(() => {})
		})

	const _getMaterialList = (wid, token) =>
		new Promise((resolve, reject) => {
			materialdata
				.getMaterialDescription(wid, token)
				.then(list => (list.length ? resolve(list) : resolve([])))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGetting`)} ${t(`wms:MaterialList`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getMaterialList = useCallback(
		() => _getMaterialList(warehouse.id, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _getAttributesList = (wid, token) =>
		new Promise((resolve, reject) => {
			attributesdata
				.getAttributes(wid, token)
				.then(list => (list.length ? resolve(list) : resolve([])))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGetting`)} ${t(`wms:AttributesList`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getAttributesList = useCallback(
		() => _getAttributesList(warehouse.id, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _getAttributeValues = (wid, token) =>
		new Promise((resolve, reject) => {
			attributesdata
				.getAllAttributeValues(wid, token)
				.then(list => (list.length ? resolve(list) : resolve([])))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGetting`)} ${t(`wms:AttributeValues`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getAttributeValues = useCallback(
		() => _getAttributeValues(warehouse.id, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const loadMaterialList = () =>
		new Promise((resolve, reject) => {
			getMaterialList()
				.then(materialList => {
					setMaterialList(materialList)
				})
				.catch(e => {
					setMaterialList([])
					console.error(e)
				})
		})

	const getEnduserList = (wid, token) =>
		new Promise((resolve, reject) => {
			materialdata
				.getMaterialEndusers(wid, token)
				.then(list => (list.length ? resolve(list) : resolve([])))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGetting`)} ${t(`wms:EndUsers`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const _deleteMaterial = (mid, wid, token) =>
		new Promise((resolve, reject) => {
			materialdata
				.deleteMaterial(mid, wid, token)
				.then(response => {
					toast.success(t(`wms:MaterialRemoved`))
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t(`wms:ErrorDelegingMaterial`)}. ${e.data}`)
					reject(e)
				})
				.finally(() => {})
		})

	const deleteMaterial = useCallback(
		mid => {
			return _deleteMaterial(mid, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _getMaterialConfig = (wid, token) =>
		new Promise((resolve, reject) => {
			materialdata
				.getConfig(wid, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGetting`)} ${t(`wms:MaterialConfigData`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getMaterialConfig = useCallback(
		() => _getMaterialConfig(warehouse.id, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _createMaterial = (data, wid, token) =>
		new Promise((resolve, reject) => {
			const payload = { ...data }
			materialdata
				.createMaterial(payload, wid, token)
				.then(response => {
					toast.success(t('wms:MaterialCreated'))
					resolve(response)
				})
				.catch(e => {
					toast.error(t(`${t('wms:ErrorCreatingMaterial')} [ ${e.status} ]: ${e.data}`))
					reject(e)
				})
				.finally(() => {})
		})

	const createMaterial = useCallback(
		newMaterialData => _createMaterial(newMaterialData, warehouse.id, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _updateMaterial = (mid, data, wid, token) =>
		new Promise((resolve, reject) => {
			const payload = { ...data }
			materialdata
				.updateMaterial(mid, payload, wid, token)
				.then(response => {
					toast.success(t('wms:MaterialUpdated'))
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t(`wms:ErrorUpdatingMaterial`)}. ${e.data}`)
					reject(e)
				})
				.finally(() => {})
		})

	const updateMaterial = useCallback(
		(mid, materialData) => _updateMaterial(mid, materialData, warehouse.id, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _updateMaterialConfig = (data, wid, token) =>
		new Promise((resolve, reject) => {
			const payload = { ...data }
			materialdata
				.updateMaterialConfiguration(payload, wid, token)
				.then(response => {
					toast.success(t('wms:MaterialConfigurationUpdated'))
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t(`wms:ErrorUpdatingMaterialConfiguration`)}. ${e.data}`)
					reject(e)
				})
				.finally(() => {})
		})

	const updateMaterialConfig = useCallback(
		materialData => _updateMaterialConfig(materialData, warehouse.id, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	useEffect(() => {
		warehouse.id &&
			getMaterialConfig()
				.then(materialConfig => {
					setMaterialConfig(materialConfig)
				})
				.catch(e => console.error(e))
	}, [warehouse, getMaterialConfig])

	useEffect(() => {
		setIsLoading(true)
		warehouse.id &&
			getMaterialList()
				.then(materialList => {
					setMaterialList(materialList)
				})
				.catch(e => {
					setIsLoading(false)
					setMaterialList([])
					console.error(e)
				})
				.finally(() => setIsLoading(false))
	}, [warehouse, getMaterialList])

	useEffect(() => {
		warehouse.id &&
			Promise.allSettled([getAttributesList(), getAttributeValues()])
				.then(listArray => {
					let attributesList =
						listArray[0].status === 'fulfilled' && listArray[0].value.length ? listArray[0].value : []
					let attributeValues =
						listArray[1].status === 'fulfilled' && listArray[1].value.length ? listArray[1].value : []

					attributesList.forEach(a => (a.values = attributeValues.filter(av => av.aid === a.id)))
					setAttributesList(attributesList)
				})
				.catch(e => console.error(e))
	}, [warehouse, getAttributesList, getAttributeValues])

	useEffect(() => {
		warehouse.id &&
			getEnduserList(warehouse.id, token)
				.then(endusers => {
					setEnduserList(endusers)
				})
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, token])

	const renderStore = (
		<AssetsMaterialContext.Provider
			value={{
				materialConfig,
				materialList,
				setMaterialList,
				attributesList,
				importMaterial,
				newMaterial,
				deleteMaterial,
				isSyncOn,
				setIsSyncOn,
				enduserList,
				loadMaterialList,
				isMatHandlerOpen,
				setIsMatHandlerOpen,
				isMatEditorOpen,
				setIsMatEditorOpen,
				createMaterial,
				user,
				isLoading,
				materialToEdit,
				setMaterialToEdit,
				updateMaterial,
				updateMaterialConfig
			}}>
			{children}
		</AssetsMaterialContext.Provider>
	)
	return renderStore
}
