import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router'
import _ from 'lodash'
import { UserContext } from 'stores/UserStore'

const PrivateRoute = ({ children, roles, ...rest }) => {
  const { authState, user } = useContext(UserContext)
  const isAuthenticated = authState === 'SIGNED_IN'
  const hasRole = !roles || (user && _.intersection(user.roles, roles).length)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          hasRole ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location }
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  /**
   * Roles allowed to access the route. If null, no role is necessary
   */
  roles: PropTypes.arrayOf(PropTypes.string)
}

export default PrivateRoute
