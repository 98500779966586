/** @format */

import React, { useCallback, useContext } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import inspSettings from '../api/assetsInspSettings'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

export const AssetsInspSettingsContext = React.createContext()

export const AssetsInspSettingsProvider = ({ children }) => {
	const { t } = useTranslation()
	const { warehouse, setWarehouse } = useContext(WMSContext)
	const { token, user } = useContext(UserContext)

	const [loadingConfig, setLoadingConfig] = useState(false)
	const [loadingInsp, setLoadingInsp] = useState(false)
	const [loadingItems, setLoadingItems] = useState(false)
	const [openAddModal, setOpenAddModal] = useState(false)
	const [chosenDefects, setChosenDefects] = useState(false)
	const [chosenItems, setChosenItems] = useState(true)
	const [chosenInspections, setChoseInspections] = useState(false)
	const [loadingDefects, setLoadingDefects] = useState(false)
	const [inspections, setInspections] = useState(null)
	const [selectedInsp, setSelectedInsp] = useState(null)
	const [items, setItems] = useState(null)
	const [selectedItems, setSelectedItems] = useState([])
	const [newItem, setNewItem] = useState('')
	const [defects, setDefects] = useState(null)
	const [newDefect, setNewDefect] = useState('')
	const [selectedItem, setSelectedItem] = useState(null)
	const [selectedDefects, setSelectedDefects] = useState([])
	const [disabledDef, setDisabledDef] = useState(null)
	const [disabledItems, setDisabledItems] = useState(null)

	const [configOverview, setConfigOverview] = useState([])
	const [stagesList, setStagesList] = useState([])
	const [measureConfig, setMeasureConfig] = useState([])

	const [selectedInspection, setSelectedInspection] = useState(null)

	const getConfigOverview = (wid, token) =>
		new Promise((resolve, reject) => {
			inspSettings
				.getConfigOverview(wid, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingInspections')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getStagesList = (wid, token) =>
		new Promise((resolve, reject) => {
			inspSettings
				.getStages(wid, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingStages')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getMeasurements = (wid, token) =>
		new Promise((resolve, reject) => {
			inspSettings
				.getMeasurements(wid, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingStages')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getInspections = () =>
		new Promise((resolve, reject) => {
			inspSettings
				.getInspections(warehouse.id, token)
				.then(response => {
					const lastRevisions = _(response).sortBy('revision').reverse().uniqBy('name').value()
					setInspections(lastRevisions)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingInspConfig')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getItems = () =>
		new Promise((resolve, reject) => {
			inspSettings
				.getItems(warehouse.id, token)
				.then(response => {
					setItems(_.filter(response, res => res.enabled === true))
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingItems')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getDisabledItems = () =>
		new Promise((resolve, reject) => {
			inspSettings
				.getDisabledItems(warehouse.id, token)
				.then(response => {
					resolve()
					setDisabledItems(_.filter(response, res => res.enabled === false))
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingDisabledItems')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getDefects = () =>
		new Promise((resolve, reject) => {
			inspSettings
				.getDefects(warehouse.id, token)
				.then(response => {
					resolve()
					setDefects(_.filter(response, res => res.enabled === true))
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingDefects')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getDisabledDefects = () =>
		new Promise((resolve, reject) => {
			inspSettings
				.getDisabledDefects(warehouse.id, token)
				.then(response => {
					resolve()
					setDisabledDef(_.filter(response, res => res.enabled === false))
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingDisabledDefs')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const saveItem = name =>
		new Promise((resolve, reject) => {
			let payload = {
				name: name,
				defects: [1, 3],
				enabled: true,
				mandatory_photo: true,
				responsible: user.name
			}
			inspSettings
				.saveItems(warehouse.id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullySavedItem'))
					setOpenAddModal(false)
					getItems()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorSavingItem')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const _newInspectionType = (wid, token, data, items, measureConfig, user, t) =>
		new Promise((resolve, reject) => {
			const payload = {
				...data,
				warehouse: wid,
				elements: [items.find(i => i.enabled).id],
				config_measurement:
					data.isMeasureEnabled && measureConfig.find(m => m.enabled) ? measureConfig.find(m => m.enabled).id : 0,
				responsible: user.name
			}
			inspSettings
				.newInspectionType(wid, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorSavingConfig')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const newInspectionType = useCallback(
		data => _newInspectionType(warehouse.id, token, data, items, measureConfig, user, t),
		[warehouse, token, items, measureConfig, user, t]
	)

	const _updateInspectionType = (wid, token, data, measureConfig, user, t) =>
		new Promise((resolve, reject) => {
			const payload = {
				...data,
				config_measurement: data.isMeasureEnabled
					? data.config_measurement
						? data.config_measurement
						: measureConfig.find(m => m.enabled).id
					: 0,
				responsible: user.name
			}
			inspSettings
				.updateInspConfig(wid, data.id, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorSavingConfig')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const updateInspectionType = useCallback(
		data => _updateInspectionType(warehouse.id, token, data, measureConfig, user, t),
		[warehouse, token, measureConfig, user, t]
	)

	const _enableInspectionType = (wid, payload, token, t) =>
		new Promise((resolve, reject) => {
			inspSettings
				.updateInspConfig(wid, payload.id, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorSavingConfig')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const enableInspectionType = useCallback(
		payload => _enableInspectionType(warehouse.id, payload, token, t),
		[warehouse, token, t]
	)

	const _disableInspectionType = (wid, inspTypeId, token, t) =>
		new Promise((resolve, reject) => {
			inspSettings
				.disableInspectionType(wid, inspTypeId, token)
				.then(response => resolve(response))
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorSavingConfig')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const disableInspectionType = useCallback(
		inspTypeId => _disableInspectionType(warehouse.id, inspTypeId, token, t),
		[warehouse, token, t]
	)

	const _newMeasurementConfig = (wid, data, token, user, t) =>
		new Promise((resolve, reject) => {
			const payload = {
				length: data.length,
				length_unit: 'mm',
				out_diameter: data.out_diameter,
				od_unit: 'in',
				int_diameter: data.int_diameter,
				id_unit: 'in',
				wall_thickness: data.wall_thickness,
				wt_unit: 'mm',
				responsible: user.name
			}
			inspSettings
				.createMeasurementConfig(wid, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorSavingConfig')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const newMeasurementConfig = useCallback(
		data => _newMeasurementConfig(warehouse.id, data, token, user, t),
		[warehouse, token, user, t]
	)

	const saveInspSettings = () =>
		new Promise((resolve, reject) => {
			let payload = {
				id: selectedInsp,
				warehouse: warehouse.id,
				name: _.find(inspections, insp => insp.id === selectedInsp).name,
				stage: _.find(inspections, insp => insp.id === selectedInsp).stage,
				elements: selectedItems,
				mandatory_validation: _.find(inspections, insp => insp.id === selectedInsp).mandatory_validation,
				config_measurement: _.find(inspections, insp => insp.id === selectedInsp).config_measurement,
				config_summary: _.find(inspections, insp => insp.id === selectedInsp).config_summary,
				responsible: user.name,
				revision: _.find(inspections, insp => insp.id === selectedInsp).revision
			}
			inspSettings
				.saveInspConfig(warehouse.id, selectedInsp, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullySavedConfig'))
					getInspections()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorSavingConfig')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const disableItem = id =>
		new Promise((resolve, reject) => {
			inspSettings
				.disableItem(warehouse.id, id, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullyDisabledItem'))
					getItems()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorDisablingItem')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const enableItem = id =>
		new Promise((resolve, reject) => {
			let payload = {
				id: id,
				warehouse: warehouse.id,
				name: _.find(disabledItems, it => it.id === id).name,
				defects: _.find(disabledItems, it => it.id === id).defects,
				mandatory_photo: _.find(disabledItems, it => it.id === id).mandatory_photo,
				revision: _.find(disabledItems, it => it.id === id).revision,
				enabled: true,
				responsible: _.find(disabledItems, it => it.id === id).responsible
			}
			inspSettings
				.enableItem(warehouse.id, id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullyEnabledItem'))
					getItems()
					getDisabledItems()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorEnablingItem')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const saveInspItems = () =>
		new Promise((resolve, reject) => {
			let payload = {
				id: selectedItem,
				warehouse: warehouse.id,
				name: _.find(items, it => it.id === selectedItem).name,
				defects: selectedDefects,
				mandatory_photo: _.find(items, it => it.id === selectedItem).mandatory_photo,
				revision: _.find(items, it => it.id === selectedItem).revision,
				enabled: _.find(items, it => it.id === selectedItem).enabled,
				responsible: user.name
			}
			inspSettings
				.saveInspItem(warehouse.id, selectedItem, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullyUpdatedItem'))
					getItems()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorUpdatingItem')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const disableDefects = id =>
		new Promise((resolve, reject) => {
			inspSettings
				.disableDefects(warehouse.id, id, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullyDisabledDefect'))
					getDefects()
					getDisabledDefects()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorDisablingDefect')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const enableDefects = id =>
		new Promise((resolve, reject) => {
			let payload = {
				id: id,
				description: _.find(disabledDef, def => def.id === id).description,
				criticity: _.find(disabledDef, def => def.id === id).criticity,
				responsible: _.find(disabledDef, def => def.id === id).responsible,
				enabled: true
			}
			inspSettings
				.enableDefects(warehouse.id, id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullyEnabledDefect'))
					getDefects()
					getDisabledDefects()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorEnablingDefect')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const saveDefect = desc =>
		new Promise((resolve, reject) => {
			let payload = {
				description: desc,
				responsible: user.name
			}
			inspSettings
				.saveDefects(warehouse.id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullySavedDefect'))
					setOpenAddModal(false)
					getDefects()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorSavingDefect')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const triggerReload = () => setWarehouse({ ...warehouse })

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(
		() => {
			const hydrate = async () => {
				try {
					setLoadingConfig(true)
					const config = await getConfigOverview(warehouse.id, token)
					const stages = await getStagesList(warehouse.id, token)
					const measureconfig = await getMeasurements(warehouse.id, token)
					setLoadingConfig(false)
					setConfigOverview(config)
					setStagesList(stages)
					setMeasureConfig(measureconfig)
				} catch (e) {
					setLoadingConfig(false)
					console.error(e)
				}
			}
			hydrate()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingInsp(true)
				await getInspections()
			} catch (e) {
				setLoadingInsp(false)
				console.error(e)
			} finally {
				setLoadingInsp(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingItems(true)
				await getItems()
			} catch (e) {
				setLoadingItems(false)
				console.error(e)
			} finally {
				setLoadingItems(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingItems(true)
				await getDisabledItems()
			} catch (e) {
				setLoadingItems(false)
				console.error(e)
			} finally {
				setLoadingItems(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingDefects(true)
				await getDefects()
			} catch (e) {
				setLoadingDefects(false)
				console.error(e)
			} finally {
				setLoadingDefects(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingDefects(true)
				await getDisabledDefects()
			} catch (e) {
				setLoadingDefects(false)
				console.error(e)
			} finally {
				setLoadingDefects(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const renderStore = (
		<AssetsInspSettingsContext.Provider
			value={{
				loadingInsp,
				setLoadingInsp,
				inspections,
				setInspections,
				selectedInsp,
				setSelectedInsp,
				items,
				setItems,
				selectedItems,
				setSelectedItems,
				openAddModal,
				setOpenAddModal,
				newItem,
				setNewItem,
				loadingItems,
				setLoadingItems,
				saveItem,
				saveInspSettings,
				disableItem,
				chosenDefects,
				setChosenDefects,
				chosenItems,
				setChosenItems,
				defects,
				setDefects,
				newDefect,
				setNewDefect,
				disableDefects,
				saveDefect,
				chosenInspections,
				setChoseInspections,
				selectedItem,
				setSelectedItem,
				selectedDefects,
				setSelectedDefects,
				saveInspItems,
				loadingDefects,
				setLoadingDefects,
				disabledDef,
				setDisabledDef,
				enableDefects,
				disabledItems,
				setDisabledItems,
				enableItem,
				configOverview,
				stagesList,
				newInspectionType,
				disableInspectionType,
				enableInspectionType,
				updateInspectionType,
				selectedInspection,
				setSelectedInspection,
				loadingConfig,
				measureConfig,
				newMeasurementConfig,
				triggerReload
			}}>
			{children}
		</AssetsInspSettingsContext.Provider>
	)
	return renderStore
}
