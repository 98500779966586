/** @format */

import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import warehouseApi from '../api/assetsWarehouse'
import { toast } from 'react-toastify'

export const AssetsWarehouseContext = React.createContext()

export const AssetsWarehouseProvider = ({ children }) => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const [save, setSave] = useState(false)
	const [saving, setSaving] = useState(false)
	const [openAddModal, setOpenAddModal] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [warehouseUpdated, setWarehouseUpdated] = useState(null)
	const [newWarehouse, setNewWarehouse] = useState({
		country: '',
		displayitem: 1,
		name: '',
		pairingsite: '',
		plant: '',
		displayallocatedinstruments: false,
		levels: []
	})

	const saveWarehouse = (isNew, payload) =>
		new Promise((resolve, reject) => {
			payload &&
				warehouseApi
					.saveWarehouse(isNew, payload, warehouse.id, token)
					.then(response => {
						resolve(response)
						toast.success(isNew ? t('wms:WarehouseCreatedSuccessful') : t('wms:WarehouseUpdatedSuccessful'))
						setOpenAddModal(false)
						setNewWarehouse({
							country: '',
							displayitem: 1,
							name: '',
							pairingsite: '',
							plant: '',
							displayallocatedinstruments: false,
							levels: []
						})
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(
							isNew
								? `${t('wms:ErrorCreatingWarehouse')} [ ${e.status}]: ${e.data}`
								: `${t('wms:ErrorUpdatingWarehouse')} [ ${e.status}]: ${e.data}`
						)
						setOpenAddModal(false)
						setNewWarehouse({
							country: '',
							displayitem: 1,
							name: '',
							pairingsite: '',
							plant: '',
							displayallocatedinstruments: false,
							levels: []
						})
					})
		})

	const deleteWarehouse = () =>
		new Promise((resolve, reject) => {
			warehouseApi
				.deleteWarehouse(warehouse.id, token)
				.then(response => {
					resolve()
					toast.success(t('wms:WarehouseDeletedSuccessful'))
					setWarehouseUpdated(Object.assign(warehouse))
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorDeletingWarehouse')} [ ${e.status} ]: ${e.data}`)
				})
				.finally(() => {
					setOpenConfirmModal(false)
				})
		})

	const renderStore = (
		<AssetsWarehouseContext.Provider
			value={{
				save,
				setSave,
				openAddModal,
				setOpenAddModal,
				saving,
				setSaving,
				newWarehouse,
				setNewWarehouse,
				openConfirmModal,
				setOpenConfirmModal,
				warehouseUpdated,
				setWarehouseUpdated,
				deleteWarehouse,
				saveWarehouse
			}}>
			{children}
		</AssetsWarehouseContext.Provider>
	)
	return renderStore
}
