/** @format */

import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ageManagement from '../api/ageManagement'
import entrance from '../api/entrance'
import MR from '../api/MR'
import dispatch from '../api/dispatch'
import inspection from '../api/inspection'
import rigreturn from '../api/rigreturn'
import modifprod from '../api/modifprod'
import reports from '../api/reports'
import ageExtract from '../api/assetsAgeMgmt'

import { toast } from 'react-toastify'

import { INSPECTION, REPORT_TYPE } from '../utils/enum'

import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import moment from 'moment'

import pdfMake from '../utils/pdfMake/pdfMake'
import deliveryNoteDD from '../utils/pdfMake/docDefinitions/deliveryNoteDD'

export const ReportsContext = React.createContext()

export const ReportsProvider = ({ children }) => {
	const { warehouse } = useContext(WMSContext)
	const { token, user } = useContext(UserContext)
	const { i18n, t } = useTranslation()

	const [inspType, setInspType] = useState(0)
	const [reportType, setReportType] = useState(null)
	const [reviewType, setReviewType] = useState(REPORT_TYPE.code.SLOW_ITEMS)
	const [loadingReports, setLoadingReports] = useState(false)
	const [allInspReports, setAllInspReports] = useState([])
	const [inspReportList, setInspReportList] = useState([])
	const [selectedReport, setSelectedReport] = useState(null)
	const [reportDetails, setReportDetails] = useState(null)
	const [loadingDetails, setLoadingDetails] = useState(false)
	const [allDeliveryNotes, setAllDeliveryNotes] = useState([])
	const [exportReportsList, setExportReportsList] = useState([])
	const [allAgeReports, setAllAgeReports] = useState([])
	const [reviewReportsList, setReviewReportsList] = useState([])
	const [selectedRevReport, setSelectedRevReport] = useState(null)
	const [isEditorOpen, setIsEditorOpen] = useState(false)
	const [extracts, setExtracts] = useState(null)
	const [selectedExtract, setSelectedExtract] = useState(null)
	const [endUsers, setEndUsers] = useState(null)
	const [downloadingReport, setDownloadingReport] = useState(false)

	const getRigPrepList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			MR.getReports(warehouse.id, INSPECTION.code.RIGPREP, token)
				.then(response => resolve(response.rigpreplist.map(item => item.result).sort((a, b) => a.id - b.id)))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingRigPrep`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getTPWorkshopList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			MR.getReports(warehouse.id, INSPECTION.code.TPWORKSHOP, token)
				.then(response => resolve(response.tpartyworklist.map(item => item.result).sort((a, b) => a.id - b.id)))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingThirdParty`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getMatModifList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			MR.getReports(warehouse.id, INSPECTION.code.MATMODIF, token)
				.then(response => resolve(response.matmodiflist.map(item => item.result).sort((a, b) => a.id - b.id)))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingMatModification`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getPhysTransList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			MR.getReports(warehouse.id, INSPECTION.code.PHYSTRANS, token)
				.then(response => resolve(response.phystranslist.map(item => item.result).sort((a, b) => a.id - b.id)))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGetting`)} Material Physical Transfer`)
					reject(e)
				})
				.finally(() => {})
		})

	const getRecInspList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			inspection
				.getRecInspReports(warehouse.id, token)
				.then(response => resolve(response.map(item => item.result).sort((a, b) => a.id - b.id)))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingRecInsp`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getPerInspList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			inspection
				.getPerInspReports(warehouse.id, token)
				.then(response => resolve(response.map(item => item.result).sort((a, b) => a.id - b.id)))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingPerInsp`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getRigReturnList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			rigreturn
				.getReports(warehouse.id, token)
				.then(response => resolve(response.map(item => item.result).sort((a, b) => a.id - b.id)))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingRigReturn`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getModifProdList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			modifprod
				.getReports(warehouse.id, token)
				.then(response =>
					resolve(
						response
							.map(item => ({ ...item.result, id: item.result.entranceid || item.result.id }))
							.sort((a, b) => a.id - b.id)
					)
				)
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingModifiedProduct`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getInspReportLists = () =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			Promise.allSettled([
				getRigPrepList(),
				getTPWorkshopList(),
				getMatModifList(),
				getPhysTransList(),
				getRecInspList(),
				getRigReturnList(),
				getPerInspList(),
				getModifProdList()
			])
				.then(listArray => {
					let preList = []
					listArray.forEach(
						(list, index) => list.status === 'fulfilled' && list.value.length && (preList[index] = list.value)
					)
					preList.splice(5, 0, undefined)
					preList.length && (setAllInspReports(preList) || setInspReportList(preList[inspType]))
					resolve(preList)
				})
				.catch(e => reject(e))
				.finally(() => {
					setLoadingReports(false)
				})
		})

	const getReportDetails = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			selectedReport !== null &&
				reports
					.getInspectionReportDetails(warehouse.id, selectedReport.id, inspType, token)
					.then(response => response && resolve(response))
					.catch(e => {
						toast.error(`${t(`wms:ErrorGettingInspDetails`)}`)
						reject(e)
					})
					.finally(() => {})
		})

	const downloadInspectionReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setDownloadingReport(true)
			reportId !== null &&
				inspType !== null &&
				reports
					.downloadInspectionReports(INSPECTION.path[inspType], warehouse.id, reportId, i18n.language, token)
					.then(response => {
						let fileName = `${INSPECTION.abbreviation[inspType]}-IR ${reportId}.xlsx`
						let a = document.createElement('a')
						let file = new Blob([response], {
							type: response.type
						})
						let fileUrl = window.URL.createObjectURL(file)
						a.href = fileUrl
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(
							`${t('wms:ErrorDownloadingInspReport')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
						)
						setDownloadingReport(false)
					})
					.finally(() => setDownloadingReport(false))
		})

	const downloadBackloadManifest = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setDownloadingReport(true)
			reportId !== null &&
				reports
					.downloadBackload(warehouse.id, reportId, i18n.language, token)
					.then(response => {
						let fileName = `RR ${reportId}.xlsx`
						let a = document.createElement('a')
						let file = new Blob([response], {
							type: response.type
						})
						let fileUrl = window.URL.createObjectURL(file)
						a.href = fileUrl
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(
							`${t('wms:ErrorDownloadingBackloadReport')} [ ${e.status} ]: ${
								e.statusText ? e.statusText : e.data
							}`
						)
						setDownloadingReport(false)
					})
					.finally(() => setDownloadingReport(false))
		})

	const downloadDeliveryNote = (reportId, ownershipId, poNumber, reportType) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setDownloadingReport(true)
			reportId !== null &&
				reportType !== null &&
				ownershipId !== null &&
				poNumber !== null &&
				reports
					.downloadDeliveryNote(
						INSPECTION.path[reportType],
						warehouse.id,
						reportId,
						ownershipId,
						i18n.language,
						poNumber,
						token
					)
					.then(response => {
						let fileName = `DN ${reportId}-001.xlsx`
						let a = document.createElement('a')
						let file = new Blob([response], {
							type: response.type
						})
						let fileUrl = window.URL.createObjectURL(file)
						a.href = fileUrl
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(
							`${t('wms:ErrorDownloadingDNReport')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
						)
						setDownloadingReport(false)
					})
					.finally(() => setDownloadingReport(false))
		})

	const downloadDeliveryNotePdf = (reportType, reportId, ownershipId, poNumber) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t('wms:WarehouseNotDefined'))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			reportId !== null &&
				reportType !== null &&
				MR.getDeliveryNotePdfData(warehouse.id, reportType, reportId, ownershipId, i18n.language, poNumber, token)
					.then(response => {
						const docDefinition = deliveryNoteDD({
							...response,
							issuedOn: new Date().toDateString(),
							responsible: user.name
						})
						pdfMake.createPdf(docDefinition).open()
						resolve(true)
					})
					.catch(e => {
						toast.error(`${t(`wms:ErrorGettingReportData`)} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => {})
		})

	const downloadDispatchReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setDownloadingReport(true)
			reportId !== null &&
				reports
					.downloadDispatchReport(warehouse.id, reportId, i18n.language, token)
					.then(response => {
						let fileName = `DR ${reportId}.xlsx`
						let a = document.createElement('a')
						let file = new Blob([response], {
							type: response.type
						})
						let fileUrl = window.URL.createObjectURL(file)
						a.href = fileUrl
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(
							`${t('wms:ErrorDownloadingDispatchReport')} [ ${e.status} ]: ${
								e.statusText ? e.statusText : e.data
							}`
						)
						setDownloadingReport(false)
					})
					.finally(() => setDownloadingReport(false))
		})

	const downloadModifProductsReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setDownloadingReport(true)
			reportId !== null &&
				reports
					.downloadModifProdReports(warehouse.id, reportId, i18n.language, token)
					.then(response => {
						let fileName = `MP ${reportId}.xlsx`
						let a = document.createElement('a')
						let file = new Blob([response], {
							type: response.type
						})
						let fileUrl = window.URL.createObjectURL(file)
						a.href = fileUrl
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(
							`${t('wms:ErrorDownloadingModifProdReport')} [ ${e.status} ]: ${
								e.statusText ? e.statusText : e.data
							}`
						)
						setDownloadingReport(false)
					})
					.finally(() => setDownloadingReport(false))
		})

	const downloadAgeManagementReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(t('wms:WarehouseNotDefined')))
			} else {
				setDownloadingReport(true)
				reportId !== null &&
					reports
						.downloadAgeMgmtReport(warehouse.id, reportId, i18n.language, token)
						.then(response => {
							let fileName = `Extract-${reportId}.xlsx`
							let a = document.createElement('a')
							let file = new Blob([response], {
								type: response.type
							})
							let fileUrl = window.URL.createObjectURL(file)
							a.href = fileUrl
							a.target = '_blank'
							a.download = fileName
							a.click()
							resolve(true)
						})
						.catch(e => {
							toast.error(
								`${t('wms:ErrorDownloadingAgeMgmt')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
							)
							reject(e)
							setDownloadingReport(false)
						})
						.finally(() => setDownloadingReport(false))
			}
		})

	const downloadMillReceipt = (reportName, reportId) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setDownloadingReport(true)
			reportId !== null &&
				reportName !== null &&
				reports
					.downloadMillReceiptReport(warehouse.id, reportId, i18n.language, token)
					.then(response => {
						let fileName = `RN ${reportId}.xlsx`
						let a = document.createElement('a')
						let file = new Blob([response], {
							type: response.type
						})
						let fileUrl = window.URL.createObjectURL(file)
						a.href = fileUrl
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						toast.error(
							`${t('wms:ErrorDownloadingReceiptNote')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
						)
						reject(e)
						setDownloadingReport(false)
					})
					.finally(() => setDownloadingReport(false))
		})

	const downloadDailyReport = payload =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			payload !== null &&
				reports
					.generateDailyReport(warehouse.id, i18n.language, payload, token)
					.then(response => {
						let fileName = `ActivityReport_${moment(new Date()).format('M-DD-YYYY')}.xlsx`
						let a = document.createElement('a')
						let file = new Blob([response.data], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						})
						let fileURL = window.URL.createObjectURL(file)
						a.href = fileURL
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						toast.error(`${t(`wms:ErrorGettingInspReport`)} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => {})
		})

	const getRigPrepNotes = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			MR.getDeliveryNotes(warehouse.id, INSPECTION.code.RIGPREP, token)
				.then(response =>
					resolve(
						response.rigprepreports
							.map(item => ({
								...item.rigpreplist,
								ownership: item.ownership,
								customerorder: item.customerorder
							}))
							.sort((a, b) => a.id - b.id)
					)
				)
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingRigPrep`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getTPWorkshopNotes = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			MR.getDeliveryNotes(warehouse.id, INSPECTION.code.TPWORKSHOP, token)
				.then(response =>
					resolve(
						response.tpartyworkreports
							.map(item => ({
								...item.tpartyworklist,
								ownership: item.ownership,
								customerorder: item.customerorder
							}))
							.sort((a, b) => a.id - b.id)
					)
				)
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingThirdParty`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getMatModifNotes = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			MR.getDeliveryNotes(warehouse.id, INSPECTION.code.MATMODIF, token)
				.then(response =>
					resolve(
						response.matmodifreports
							.map(item => ({
								...item.matmodiflist,
								ownership: item.ownership,
								customerorder: item.customerorder
							}))
							.sort((a, b) => a.id - b.id)
					)
				)
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingMatModification`)}`)
					console.error(e)
					reject(e)
				})
				.finally(() => {})
		})

	const getPhysTransNotes = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			MR.getDeliveryNotes(warehouse.id, INSPECTION.code.PHYSTRANS, token)
				.then(response =>
					resolve(
						response.phystransreports
							.map(item => ({
								...item.phystranslist,
								ownership: item.ownership,
								customerorder: item.customerorder
							}))
							.sort((a, b) => a.id - b.id)
					)
				)
				.catch(e => {
					toast.error(`${t(`wms:ErrorGetting`)} Material Physical Transfer`)
					reject(e)
				})
				.finally(() => {})
		})

	const getDeliveryNotes = reportType =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			Promise.allSettled([getRigPrepNotes(), getTPWorkshopNotes(), getMatModifNotes(), getPhysTransNotes()])
				.then(listArray => {
					let preList = []
					listArray.forEach(
						(list, index) => list.status === 'fulfilled' && list.value.length && (preList[index] = list.value)
					)(setAllDeliveryNotes(preList) || setExportReportsList(preList[reportType]))
					resolve(preList)
				})
				.catch(e => reject(e))
				.finally(() => {
					setLoadingReports(false)
				})
		})

	const getDispatchReports = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			dispatch
				.getReports(warehouse.id, token)
				.then(
					response =>
						response &&
						(setExportReportsList(response.map(item => item.result)) ||
							resolve(response.map(item => item.result)))
				)
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingInspDetails`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getBackloadManifests = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			rigreturn
				.getReports(warehouse.id, token)
				.then(
					response =>
						setExportReportsList(
							response.map(item => ({ ...item.result, ref: item.result.rrref })).sort((a, b) => a.id - b.id)
						) || resolve(response)
				)
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingRigReturn`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getModifProdcutReports = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			modifprod
				.getReports(warehouse.id, token)
				.then(response => {
					setExportReportsList(
						response
							.map(item => ({ ...item.result, id: item.result.id, ref: item.result.erref }))
							.sort((a, b) => a.id - b.id) || resolve(response)
					)
				})
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingModifiedProduct`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getAgeManagementReports = enduser =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			ageManagement
				.getReports(warehouse.id, selectedExtract, enduser, token)
				.then(response => {
					setAllAgeReports(response)
					setReviewReportsList(response)
				})
				.catch(e => reject(e))
				.finally(() => {
					setLoadingReports(false)
				})
		})

	const getBatchRecipts = enduser =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			entrance
				.getBatchReceipts(warehouse.id, enduser, token)
				.then(response => response && resolve(response))
				.catch(e => {
					toast.error(`${t(`wms:ErrorGettingMillReceiptsList`)}`)
					reject(e)
				})
				.finally(() => {})
		})

	const getMillReceipts = enduser =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			Promise.allSettled([getBatchRecipts(enduser)])
				.then(listArray => {
					let preList = []
					listArray.forEach(
						(list, index) => list.status === 'fulfilled' && list.value.length && preList.push(...list.value)
					)
					setReviewReportsList(preList.sort((a, b) => a.id - b.id))
					resolve(preList)
				})
				.catch(e => reject(e))
				.finally(() => {
					setLoadingReports(false)
				})
		})

	const getExtracts = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				ageExtract
					.getExtracts(warehouse.id, token)
					.then(response => {
						setExtracts(response)
						resolve()
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingExtracts')} [ ${e.status} ]: ${e.data}`)
					})
		})

	const getEndUsers = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				reports
					.getEndUsers(warehouse.id, token)
					.then(response => {
						resolve()
						setEndUsers(response)
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingEndUsers')} [ ${e.status} ]: ${e.data}`)
					})
		})

	useEffect(() => {
		setInspReportList(allInspReports[inspType])
		setReportDetails(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspType, warehouse])

	useEffect(() => {
		setExportReportsList(allDeliveryNotes[reportType])
		setReportDetails(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportType])

	useEffect(() => {
		setReviewReportsList(allAgeReports[reviewType])
		setSelectedRevReport(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewType])

	useEffect(() => {
		setLoadingDetails(true)
		selectedReport !== null &&
			getReportDetails()
				.then(incommingDetails => setReportDetails({ ...selectedReport, ...incommingDetails }))
				.catch(e => {
					setReportDetails(null)
					console.error(e)
				})
				.finally(() => {
					setLoadingDetails(false)
				})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getExtracts()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getEndUsers()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const renderStore = (
		<ReportsContext.Provider
			value={{
				inspType,
				setInspType,
				loadingReports,
				inspReportList,
				setSelectedReport,
				loadingDetails,
				reportDetails,
				downloadInspectionReport,
				downloadDailyReport,
				getInspReportLists,
				getDeliveryNotes,
				getDispatchReports,
				getBackloadManifests,
				getModifProdcutReports,
				getAgeManagementReports,
				getMillReceipts,
				reportType,
				setReportType,
				exportReportsList,
				downloadBackloadManifest,
				downloadDeliveryNote,
				downloadDeliveryNotePdf,
				downloadDispatchReport,
				downloadModifProductsReport,
				downloadAgeManagementReport,
				downloadMillReceipt,
				reviewType,
				setReviewType,
				reviewReportsList,
				selectedRevReport,
				setSelectedRevReport,
				isEditorOpen,
				setIsEditorOpen,
				extracts,
				setExtracts,
				selectedExtract,
				setSelectedExtract,
				endUsers,
				setEndUsers,
				getEndUsers,
				setReportDetails,
				downloadingReport,
				setDownloadingReport
			}}>
			{children}
		</ReportsContext.Provider>
	)
	return renderStore
}
