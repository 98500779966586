/** @format */

import { REPORT_STATUS, REPORT_TYPE } from 'apps/wms/utils/enum'
import { FlexView } from 'components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ReportTitle = ({ reportType, reportData, allowEdit, zoom, extractName }) => {
	const { t } = useTranslation()
	return (
		<FlexView
			style={{ zoom: zoom }}
			flexDirection='row'
			justifyContent='space-between'
			width='100%'
			margin='16px 0 24px'>
			<FlexView flexDirection='row' fontWeight='bold' fontSize='36px' alignItems='flex-end'>
				{reportType === REPORT_TYPE.code.AGE_EXTRACTS ? extractName : t(REPORT_TYPE.translateKey[reportType])}
				<FlexView
					fontSize='tiny'
					padding='4px 8px'
					margin='0 0 8px 8px'
					borderRadius='16px'
					color='white'
					backgroundColor={REPORT_STATUS.color[reportData.status]}>
					{t(REPORT_STATUS.translateKey[reportData.status])}
				</FlexView>
			</FlexView>
			<FlexView alignItems='flex-end'>
				<FlexView fontSize='medium'>{allowEdit ? t('wms:Editing') : t('wms:Viewing')}</FlexView>
				<FlexView fontWeight='bold' fontSize='big'>
					ID: {reportData.id} | {t('wms:Revision')}: {!!reportData.revision ? reportData.revision : '0'}
				</FlexView>
			</FlexView>
		</FlexView>
	)
}

export default ReportTitle
