/** @format */

import { InventoryContext } from 'apps/wms/stores/InventoryStore'
import { Button, FlexView, LoadingOverlay } from 'components/common'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import InventoryDetailsModal from './DetailsModal'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import GhostModal from './GhostModal'
import Table from '../../components/inventory/Table'

const InventoryTable = () => {
	const { t, i18n } = useTranslation()
	const {
		reports,
		loadingReports,
		openDetailsModal,
		setOpenDetailsModal,
		setInventoryId,
		openModalGhosts,
		setOpenModalGhosts,
		setOpenPrint
	} = useContext(InventoryContext)

	const [rackName, setRackName] = useState(null)

	const data = useMemo(
		() =>
			_.chain(reports)
				.groupBy('fullname')
				.map((value, key) => ({
					rackId: value[0].rackId,
					fullname: key,
					id: value[0].id,
					lastinventorydate: value[0].lastinventorydate,
					nextinventorydate: value[0].nextinventorydate,
					responsible: value[0].responsible,
					deviation: value[0].deviation
				}))
				.value(),
		[reports]
	)

	const handleClick = useCallback(
		original => e => {
			e.stopPropagation()
			if (original.id !== 0) {
				setRackName(original.fullname)
				setInventoryId(original.id)
				setOpenDetailsModal(true)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const columns = useMemo(
		() => [
			{
				Header: (
					<FlexView alignItems='center' fontWeight='bold' style={{ textAlign: 'center' }}>
						{t('wms:Rack')}
					</FlexView>
				),
				accessor: 'fullname',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							color={row.original.id !== 0 ? 'primary' : 'gray'}
							fontWeight='bold'
							alignItems='center'
							style={{ cursor: row.original.id !== 0 ? 'pointer' : 'default' }}
							onClick={handleClick(row.original)}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' fontWeight='bold' style={{ textAlign: 'center' }}>
						{t('wms:Last')}
					</FlexView>
				),
				accessor: 'lastinventorydate',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							alignItems='center'
							justifyContent='center'
							margin='0'
							padding='0'
							style={{ textAlign: 'center' }}>
							{new Date(Date.parse(value)).getFullYear() > 2010 ? DATE_FORMAT(value, i18n.language) : null}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' fontWeight='bold' style={{ textAlign: 'center' }}>
						{t('wms:Next')}
					</FlexView>
				),
				accessor: 'nextinventorydate',
				Cell: ({ cell: { value } }) => {
					let now = new Date()
					return (
						<FlexView
							alignItems='center'
							justifyContent='center'
							margin='0'
							padding='0'
							color={
								new Date(Date.parse(value)).getMonth() < now.getMonth() ||
								new Date(Date.parse(value)).getFullYear() < now.getFullYear()
									? 'error'
									: 'success'
							}
							style={{ textAlign: 'center' }}>
							{DATE_FORMAT(value, i18n.language)}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' fontWeight='bold' style={{ textAlign: 'center' }}>
						{t('wms:Responsible')}
					</FlexView>
				),
				accessor: 'responsible',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView fontWeight='bold' justifyContent='center' alignItems='center' color='metalic'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' fontWeight='bold' style={{ textAlign: 'center' }}>
						{t('wms:Deviation')}
					</FlexView>
				),
				accessor: 'deviation',
				Cell: ({ cell: { value, row } }) => {
					return row.original.deviation < 0 ? (
						<FlexView width='100%' fontWeight='bold' color='metalic' justifyContent='center' alignItems='center'>
							{t('wms:NotInventoried')}
						</FlexView>
					) : (
						<FlexView
							width='100%'
							alignItems='center'
							fontWeight='bold'
							color={value > 0 ? 'error' : 'success'}
							justifyContent='center'
							style={{ textAlign: 'center' }}>
							{`${value} %`}
						</FlexView>
					)
				}
			}
		],
		[t, i18n.language, handleClick]
	)

	const showGhosts = () => {
		setOpenModalGhosts(true)
	}

	const onOutsideClick = () => {
		setOpenModalGhosts(false)
		setOpenPrint(false)
	}

	return (
		<FlexView
			flexDirection='column'
			width='100%'
			style={{ position: 'relative' }}
			height={data.length === 0 ? '100%' : 'auto'}>
			<LoadingOverlay visible={loadingReports} borderRadius='card' />
			<Button
				backgroundColor='white'
				color='metalic'
				elevation='low'
				style={{ alignSelf: 'flex-end' }}
				onClick={() => showGhosts()}
				disabled={data.length === 0}
				data-cy='button-show-ghosts'>
				{t('wms:InventoryGhosts')}
			</Button>
			{data.length > 0 ? (
				<Table
					margin='8px'
					width='100%'
					style={{ backgroundColor: 'white', elevation: 'low', alignSelf: 'center' }}
					columns={columns}
					data={data}
					paginated
					sortable
					data-cy='table-inventory'
					height='fit-content'
				/>
			) : (
				<FlexView
					backgroundColor='white'
					borderRadius='8px'
					height='100%'
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='no-batch-list'>
					{t('wms:NoAvailableRack')}
				</FlexView>
			)}
			<InventoryDetailsModal
				isOpen={openDetailsModal}
				name={rackName}
				onOutsideClick={() => setOpenDetailsModal(false)}
			/>
			<GhostModal isOpen={openModalGhosts} onOutsideClick={onOutsideClick} />
		</FlexView>
	)
}

export default InventoryTable
