/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import { STATUS } from '../../utils/enum'
import { RigReturnContext } from '../../stores/RigReturnStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'

const RigReturnList = () => {
	const { t, i18n } = useTranslation()
	const { rrList, loadingRRList, selectedRR, setSelectedRR, deleteRR, setUploadRRList, setRRModal } =
		useContext(RigReturnContext)
	const [deleteWarning, setDeleteWarning] = useState(false)
	const [deleteID, setDeleteID] = useState(null)

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedRR && selectedRR.id && handleSelect(selectedRR.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rrList])

	const handleSelect = id => {
		id && setSelectedRR(rrList.find(item => item.id === id))
	}

	const handleDelete = id => {
		if (id !== null) {
			handleSelect(id)
			setDeleteWarning(true)
			setDeleteID(id)
		}
	}

	const removeRR = () => {
		setDeleteWarning(false)
		deleteID &&
			deleteRR(deleteID)
				.then(response => {
					setUploadRRList(true)
					setSelectedRR(null)
				})
				.catch(e => console.error(e))
				.finally(() => {})
	}

	const handleNewRR = () => {
		setRRModal(true)
	}

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-list-wrapper'>
			<LoadingOverlay visible={loadingRRList} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:RigReturnList')}
			</FlexView>
			<FlexView
				width='100%'
				height={`${listHeight}px`}
				style={{ overflowY: 'scroll', overflowX: 'hidden' }}
				data-cy='list-wrapper'>
				{rrList && rrList.length ? (
					rrList.map((item, index) => (
						<FlexView
							key={item.id}
							backgroundColor={selectedRR && selectedRR.id === item.id ? 'offWhite' : 'default'}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelect(item.id)}
							data-cy='list-item'>
							<FlexView flex='1' fontSize='small' margin='auto 0'>
								{`${item.id} (${DATE_FORMAT(item.expecteddate, i18n.language)}) ${item.name}`}
							</FlexView>
							<Button
								fontSize='tiny'
								margin='0 0 0 8px'
								color='white'
								backgroundColor='error'
								disabled={!!item.content.find(content => content.status !== STATUS.code.NOTSTARTED)}
								onClick={() => {
									handleDelete(item.id)
								}}>
								<Icon name='trash' color='white' height='12px' width='12px' margin='0 8px 0 0' />
								{t('wms:Delete')}
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='nolist-placeholder'>
						{t('wms:NoRR')}
					</FlexView>
				)}
			</FlexView>
			<Button
				fontSize='small'
				margin='auto 0 0 auto'
				color='white'
				backgroundColor='secondary'
				disabled={false}
				onClick={handleNewRR}
				data-cy='list-add-button'>
				{t('wms:Add')}
			</Button>
			<DialogModal
				isOpen={deleteWarning}
				text={t(`wms:ConfirmRigReturnListDeletion`)}
				onConfirm={removeRR}
				onCancel={() => setDeleteWarning(false)}
				data-cy='modal-delete-warn'
			/>
		</Card>
	)
}

export default RigReturnList
