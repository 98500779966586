/** @format */

import { REPORT_ITEM, REPORT_ITEM_STATUS } from 'apps/wms/utils/enum'
import { Button, Card, FlexView, Icon, Modal } from 'components/common'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isStorageReport } from './businessRules'
import styled from 'styled-components'
import DialogModal from 'apps/wms/components/forms/DialogModal'

const ActionButton = styled(Button)`
	border: none;
	&:hover {
		box-shadow: none;
	}
`

const ImageWrapper = styled.div`
	position: relative;
	padding-top: 100px;
	min-width: 100px;

	img {
		position: absolute;
		max-width: 95%;
		max-height: 95%;
		top: 50%;
		margin-top: -47.5%;
		left: 50%;
		margin-left: -78.5%;
	}

	button {
		position: absolute;
		margin: 0px;
		top: 0;
		right: 0;
	}
`

const ReportImageGallery = ({
	isOpen,
	onOutsideClick,
	onImageChange,
	report,
	reportType,
	allowEdit,
	rejectReasons
}) => {
	const { t } = useTranslation()
	const [confirmImageUpdate, setConfirmImageUpdate] = useState(false)
	const [dialogData, setDialogData] = useState(null)
	const [resizeImg, setResizeImg] = useState(false)
	const [currentPhoto, setCurrentPhoto] = useState(null)
	const [currentInfo, setCurrentInfo] = useState({
		reportItem: null,
		defect: null
	})

	const parentRef = useRef(null)

	const reportItemCodes = useMemo(
		() =>
			isStorageReport(reportType) ? Object.values(REPORT_ITEM.code) : Object.values(REPORT_ITEM.code).slice(0, 6),
		[reportType]
	)

	//const hasDefectSet = (reportItem) => (reportItem.defect > 9)
	const isReportNOK = reportItem => reportItem.report === REPORT_ITEM_STATUS.code.NOTOK
	const getReportItem = (report, itemCode) => report[REPORT_ITEM.accessor[itemCode]]
	//const getReportItemReport = (report, itemCode) => (getReportItem(report, itemCode).report)
	const getReportItemDefect = (report, itemCode) => getReportItem(report, itemCode).defect
	const getReportItemPhoto = (report, itemCode) => getReportItem(report, itemCode).photo
	const getReportPhotoURL = (report, itemCode) => {
		const photoURL = getReportItemPhoto(report, itemCode)
		return photoURL.startsWith('data') ? photoURL : `/api/wms/inspection/photo?path=${photoURL}`
	}
	const isDefectSet = (report, itemCode) => getReportItemDefect(report, itemCode) > 9
	const getRejectReason = rejectReasonCode => rejectReasons.find(rr => rr.id === rejectReasonCode).desc

	const storeDialogData = useCallback((item, value, report) => {
		setDialogData({
			item: item,
			value: value,
			report: report
		})
	}, [])

	const handleAddImage = (itemCode, imageEvent, report) => {
		const reader = new FileReader()
		reader.onload = event => {
			const encoded = event.target.result
			storeDialogData(itemCode, encoded, report)
			setConfirmImageUpdate(true)
		}
		imageEvent.target.files.length > 0 && reader.readAsDataURL(imageEvent.target.files[0])
	}

	const handleRemoveImage = (itemCode, report) => {
		storeDialogData(itemCode, 'N', report)
		setConfirmImageUpdate(true)
	}

	const changeImage = (itemCode, imageEvent, report) => onImageChange(itemCode, imageEvent, report)

	const enlargeImg = (report, itemCode) => {
		const photoURL = getReportItemPhoto(report, itemCode)
		setCurrentInfo({
			reportItem: REPORT_ITEM.literal[itemCode],
			defect: isDefectSet(report, itemCode)
				? getRejectReason(getReportItemDefect(report, itemCode))
				: t('wms:NoDefectSet')
		})
		photoURL.startsWith('data')
			? setCurrentPhoto(photoURL)
			: setCurrentPhoto(`/api/wms/inspection/photo?path=${photoURL}`)
		setResizeImg(true)
	}

	const itemInfo = useCallback(
		itemCode =>
			isReportNOK(getReportItem(report, itemCode)) && (
				<FlexView
					width='calc(100% - 32px)'
					padding='8px 0px'
					margin='16px 0 0 0'
					flexDirection='row'
					justifyContent='space-between'
					key={itemCode}>
					<FlexView
						width='calc(100% - 32px)'
						flexDirection='column'
						fontSize='small'
						fontWeight='bold'
						padding='0px'>
						{REPORT_ITEM.literal[itemCode]}
						<FlexView width='calc(100% - 32px)' fontSize='small' margin='4px 0 0 0'>
							{isDefectSet(report, itemCode)
								? getRejectReason(getReportItemDefect(report, itemCode))
								: t('wms:NoDefectSet')}
						</FlexView>
					</FlexView>
					{getReportItemPhoto(report, itemCode) === 'N' ? (
						<Button
							title={t('wms:AddPicture')}
							fontSize='tiny'
							padding='0'
							outline='true'
							color='secondary'
							onClick={e => e.stopPropagation()}
							margin='0 0 0 8px'
							disabled={!allowEdit || !isDefectSet(report, itemCode)}>
							<label htmlFor={`add-${REPORT_ITEM.accessor}-photo`} style={{ cursor: 'pointer' }}>
								<Icon name='add' color='secondary' height='24px' width='24px' margin='16px' />
							</label>
							<input
								type='file'
								fileread={getReportItemPhoto(report, itemCode)}
								id={`add-${REPORT_ITEM.accessor}-photo`}
								accept='image/png, image/jpeg'
								style={{ display: 'none' }}
								onChange={e => {
									handleAddImage(itemCode, e, report)
								}}
							/>
						</Button>
					) : (
						<ImageWrapper>
							<img
								src={getReportPhotoURL(report, itemCode)}
								alt=''
								id='img'
								onClick={() => enlargeImg(report, itemCode)}
								style={{ cursor: 'pointer' }}
							/>
							<ActionButton
								fontSize='tiny'
								backgroundColor='error'
								padding='4px'
								color='white'
								margin='0 0 0 8px'
								onClick={() => handleRemoveImage(itemCode, report)}>
								<Icon name='trash' color='white' height='12px' width='12px' margin='0' />
							</ActionButton>
						</ImageWrapper>
					)}
				</FlexView>
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[report]
	)

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			{resizeImg ? (
				<FlexView
					flexDirection='column'
					width='calc(100% - 48px)'
					height='calc(100% - 48px)'
					padding='24px'
					margin='0px'>
					<Card
						flexDirection='row'
						width='calc(100% - 48px)'
						margin='0px'
						padding='8px 24px'
						backgroundColor='background'
						justifyContent='space-between'
						borderRadius='4px'
						style={{ alignItems: 'center' }}>
						<FlexView
							width='calc(100% - 32px)'
							flexDirection='column'
							fontSize='small'
							fontWeight='bold'
							padding='0px'>
							{currentInfo && currentInfo.reportItem}
							<FlexView width='calc(100% - 32px)' fontSize='small' margin='4px 0 0 0'>
								{currentInfo && currentInfo.defect}
							</FlexView>
						</FlexView>
						<Icon
							name='cross-simple'
							width='14px'
							height='14px'
							style={{ alignSelf: 'center' }}
							onClick={() => setResizeImg(false)}
						/>
					</Card>
					<img
						src={currentPhoto}
						alt=''
						style={{
							cursor: 'pointer',
							width: '80%',
							margin: 'auto',
							objectFit: 'contain',
							objectPosition: '50% 50%',
							height: 'auto',
							maxHeight: '80%'
						}}
					/>
				</FlexView>
			) : (
				<Card
					ref={parentRef}
					borderRadius='0'
					backgroundColor='background'
					maxWidth='calc(30%)'
					height='calc(100%)'
					maxHeight='100vh'
					flex='1'
					margin='0 0 0 auto'
					padding='0'
					alignItems='center'
					alignSelf='flex-end'
					style={{ position: 'relative', overflowY: 'scroll' }}>
					<FlexView
						width='calc(100% - 32px)'
						fontWeight='bold'
						fontSize='24px'
						padding='16px 16px 8px'
						flexDirection='row'
						justifyContent='space-between'>
						{`${t('wms:PhotosOfDefects')}`}
						<ActionButton
							fontSize='tiny'
							padding='4px 0'
							outline='true'
							color='secondary'
							onClick={() => onOutsideClick()}
							margin='0 0 0 8px'>
							<Icon name='cross' color='secondary' height='24px' width='24px' margin='0' />
						</ActionButton>
					</FlexView>
					<FlexView width='calc(100% - 32px)' fontSize='16px' padding='0 16px 16px'>
						{t('wms:Item')}: {report.valid}
					</FlexView>
					{reportItemCodes.map(ri => itemInfo(ri))}
				</Card>
			)}
			{dialogData !== null && (
				<DialogModal
					isOpen={confirmImageUpdate}
					title={dialogData.value === 'N' ? t(`wms:ConfirmPhotoRemoval`) : t('wms:ConfirmPhotosUpdate')}
					text={t(`wms:ProceedModifyingPhoto`)}
					onConfirm={() =>
						changeImage(dialogData.item, dialogData.value, dialogData.report) && setConfirmImageUpdate(false)
					}
					onCancel={() => setConfirmImageUpdate(false)}
				/>
			)}
		</Modal>
	)
}

export default ReportImageGallery
