/** @format */

import { inspStatusColumn } from './columns'
import { remarksColumn } from './columns'
import { threadProtColumn } from './columns'
import { measurementColumns } from './columns'
import { threadColumns } from './columns'
import { instrumentsColumn } from './columns'
import { customerItemColumn } from './columns'
import { salesItemColumn } from './columns'
import { materialDescColumn } from './columns'
import { ownershipColumn } from './columns'
import { ageColumn } from './columns'
import { lengthColumn } from './columns'
import { coatingVarnishColumn } from './columns'
import { threadCompoundColumns } from './columns'
import { driftColumn } from './columns'
import { pipeBodyColumns } from './columns'
import { markingLegibilityColumn } from './columns'
import { weightColumn } from './columns'
import { jointsColumn } from './columns'
import { entranceDateColumn } from './columns'
import { attributeColumns } from './columns'
import { mescColumn } from './columns'
import { salesOrderColumn } from './columns'
import { customerOrderColumn } from './columns'
import { imageHandlerColumn } from './columns'
import { inspectorColumn } from './columns'
import { validColumn } from './columns'

const RIG_PREP = function (config) {
	return [
		validColumn(),
		markingLegibilityColumn(config),
		threadColumns(config),
		pipeBodyColumns(config),
		driftColumn(config),
		inspStatusColumn(config),
		inspectorColumn(config),
		remarksColumn(config),
		imageHandlerColumn(config),
		instrumentsColumn(config),
		threadCompoundColumns(config),
		measurementColumns(config)
	]
}

const TP_WORKSHOP = function (config) {
	return [
		validColumn(),
		markingLegibilityColumn(config),
		threadColumns(config),
		pipeBodyColumns(config),
		driftColumn(config),
		inspStatusColumn(config),
		inspectorColumn(config),
		remarksColumn(config),
		imageHandlerColumn(config),
		instrumentsColumn(config),
		measurementColumns(config)
	]
}

const MAT_MODIFICATION = function (config) {
	return [
		validColumn(),
		markingLegibilityColumn(config),
		threadColumns(config),
		pipeBodyColumns(config),
		driftColumn(config),
		inspStatusColumn(config),
		inspectorColumn(config),
		remarksColumn(config),
		imageHandlerColumn(config),
		instrumentsColumn(config),
		measurementColumns(config)
	]
}

const PHYSICAL_TRANSFER = function (config) {
	return [
		validColumn(),
		markingLegibilityColumn(config),
		threadColumns(config),
		pipeBodyColumns(config),
		driftColumn(config),
		inspStatusColumn(config),
		inspectorColumn(config),
		remarksColumn(config),
		imageHandlerColumn(config),
		instrumentsColumn(config),
		measurementColumns(config)
	]
}

const RECEIVING_INSPECTION = function (config) {
	return [
		validColumn(),
		markingLegibilityColumn(config),
		coatingVarnishColumn(config),
		threadProtColumn(config),
		threadColumns(config),
		pipeBodyColumns(config),
		driftColumn(config),
		inspStatusColumn(config),
		inspectorColumn(config),
		remarksColumn(config),
		imageHandlerColumn(config),
		instrumentsColumn(config)
	]
}

const PERIODICAL_INSPECTION = function (config) {
	return [
		validColumn(),
		markingLegibilityColumn(config),
		coatingVarnishColumn(config),
		threadProtColumn(config),
		threadColumns(config),
		pipeBodyColumns(config),
		driftColumn(config),
		inspStatusColumn(config),
		inspectorColumn(config),
		remarksColumn(config),
		imageHandlerColumn(config),
		instrumentsColumn(config)
	]
}

const RIG_RETURN = function (config) {
	return [
		validColumn(),
		markingLegibilityColumn(config),
		threadColumns(config),
		pipeBodyColumns(config),
		driftColumn(config),
		inspStatusColumn(config),
		inspectorColumn(config),
		remarksColumn(config),
		imageHandlerColumn(config),
		instrumentsColumn(config)
	]
}

const MODIF_PRODUCT = function (config) {
	return [
		validColumn(config),
		markingLegibilityColumn(config),
		threadColumns(config),
		pipeBodyColumns(config),
		driftColumn(config),
		inspStatusColumn(config),
		inspectorColumn(config),
		remarksColumn(config),
		imageHandlerColumn(config),
		instrumentsColumn(config)
	]
}

const AGE_MANAGEMENT_COLUMNS = function (config) {
	return [
		customerOrderColumn(config),
		customerItemColumn(config),
		salesOrderColumn(config),
		salesItemColumn(config),
		mescColumn(config),
		materialDescColumn(config),
		...attributeColumns(config),
		ownershipColumn(config),
		entranceDateColumn(config),
		ageColumn(config),
		jointsColumn(config),
		lengthColumn(config),
		weightColumn(config)
	]
}

const RECEIPT_NOTE = function (config) {
	return [mescColumn(config), materialDescColumn(config), validColumn(), lengthColumn(config), weightColumn(config)]
}

const reports = [
	RIG_PREP,
	TP_WORKSHOP,
	MAT_MODIFICATION,
	PHYSICAL_TRANSFER,
	RECEIVING_INSPECTION,
	PERIODICAL_INSPECTION, //Spot inspection has the same config as Periodical inspection
	RIG_RETURN,
	PERIODICAL_INSPECTION,
	MODIF_PRODUCT,
	'wms:Dispatch',
	'wms:BackloadManifest',
	RECEIPT_NOTE,
	AGE_MANAGEMENT_COLUMNS,
	AGE_MANAGEMENT_COLUMNS
]

export const getReportColumns = config => reports[config.reportType](config)
