/** @format */

import { Card, FlexView, Icon, LoadingOverlay, Table } from 'components/common'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentContext } from 'apps/wms/stores/ContentStore'
import { listStatusTranslateKey } from 'apps/wms/utils/enum'
import moment from 'moment'

const Dashboard = () => {
	const { statusDash, total, exportDashboard, setExportItemsDash, exportItemsDash } = useContext(ContentContext)
	const { t } = useTranslation()

	const columns = [
		{
			Header: 'Status',
			accessor: 'status',
			Cell: ({ cell: { value } }) => t(listStatusTranslateKey(value))
		},
		{
			Header: (
				<FlexView flexDirection='row' justifyContent='space-between' fontWeight='bold'>
					{t('wms:Quantity')}
					<Icon
						tooltip={t('wms:ExportExcel')}
						name='download'
						width='18px'
						height='18px'
						margin='0 8px 0 0'
						color='secondary'
						onClick={() => handleExport()}
					/>
					<LoadingOverlay visible={exportItemsDash} borderRadius='card' />
				</FlexView>
			),
			accessor: 'quantity',
			Cell: ({ cell: { value } }) => (
				<FlexView
					alignItems='center'
					justifyContent='center'
					width='60px'
					margin='0px'
					style={{ textAlign: 'center' }}>
					{value}
				</FlexView>
			),
			Footer: () => <FlexView fontWeight='bold'>Total: {total}</FlexView>,
			customCellProps: {
				style: {
					textAlign: 'center'
				}
			}
		}
	]
	const handleExport = () => {
		setExportItemsDash(true)
		exportDashboard()
			.then(response => {
				let fileName = `Stock Level Internal Report (${moment(new Date()).format('M-DD-YYYY')}).xlsx`
				let a = document.createElement('a')
				let file = new Blob([response], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				})
				let fileURL = window.URL.createObjectURL(file)
				a.href = fileURL
				a.target = '_blank'
				a.download = fileName
				a.click()
			})
			.catch(e => {
				console.error(e)
			})
			.finally(() => {
				setExportItemsDash(false)
			})
	}

	return (
		<Card width='100%' margin='0 0 0 16px' data-cy='card-dashboard-status'>
			<Table
				margin='0'
				width='100%'
				maxWidth='100%'
				columns={columns}
				data={statusDash}
				elevation='none'
				hasFooter
				data-cy='table-dashboard-status'
			/>
		</Card>
	)
}

export default Dashboard
