/** @format */

import React from 'react'
import PropTypes from 'prop-types'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { Icon } from 'components/common'
import styled from 'styled-components'

const StyledTabs = styled(Tabs)`
	width: 100%;

	.react-tabs {
		-webkit-tap-highlight-color: transparent;
	}

	.react-tabs__tab-list {
		padding: 0px;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.react-tabs__tab {
		outline: none;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1 0 100px;
		overflow: hidden;
		border-radius: 5px;
		margin: 5px 10px 5px 0px;
		list-style: none;
		padding: 6px 12px;
		font-family: 'Noto Sans';
		font-weight: bold;
		font-size: ${({ theme }) => theme.fontSizes.medium};
		cursor: pointer;
		user-select: none;
		background-color: ${({ theme }) => theme.colors.lightGray};
		transition: background-color 0.2s, color 0.2s, box-shadow 0.4s;

		&:hover {
			.img-close {
				opacity: 1;
			}
		}

		span {
			display: inline-block;
			min-width: 0px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.img-close {
			background-color: white;
			border-radius: 100%;
			position: absolute;
			right: 9px;
			top: calc(50% - 9px);
			opacity: 0;
			transition: opacity 0.2s;
		}
	}

	.react-tabs__tab--selected {
		background-color: ${({ theme }) => theme.colors.primary};
		color: white;
	}

	.react-tabs__tab--disabled {
		opacity: 0.6;
		background-color: ${({ theme }) => theme.colors.lightGray};
		cursor: not-allowed;
	}

	.react-tabs__tab-panel {
		display: none;
	}

	.react-tabs__tab-panel--selected {
		display: block;
	}
`

const TabsView = ({ data, ...rest }) => {
	const handleClose = onClose => event => {
		event.preventDefault()
		event.stopPropagation()
		onClose && onClose()
	}

	return (
		<StyledTabs {...rest}>
			<TabList>
				{data.map((item, index) => (
					<Tab key={`tab-${index}`}>
						<span>{item.title}</span>
						{item.closable && (
							<div className='img-close'>
								<Icon
									name='cross'
									width='18px'
									height='18px'
									color='error'
									onClick={handleClose(item.onClose)}
								/>
							</div>
						)}
					</Tab>
				))}
			</TabList>

			{data.map((item, index) => (
				<TabPanel key={`tab-panel-${index}`}>{item.content}</TabPanel>
			))}
		</StyledTabs>
	)
}

TabsView.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			closable: PropTypes.bool,
			onClose: PropTypes.func,
			content: PropTypes.node
		})
	),
	defaultIndex: PropTypes.number,
	selectedIndex: PropTypes.number,
	onSelect: PropTypes.func // (index: number, lastIndex: number, event: Event) => boolean | void
}

export default TabsView
