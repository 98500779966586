/** @format */

import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Input, Select } from 'components/form'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { AssetsUsageRightsContext } from 'apps/wms/stores/AssetsUsageRightsStore'

const OwnershipSettings = () => {
	const { t } = useTranslation()
	const {
		loadingOwnerships,
		ownerLevelSelected,
		setOwnerLevelSelected,
		item,
		setItem,
		addOwnershipValues,
		deleteOwnershipValues,
		newLevel,
		setNewLevel,
		create,
		levelDesc,
		levelSelected,
		setLevelSelected,
		removeLevel,
		setRemoveLevel,
		createLevels,
		levels,
		setLevels,
		ownerValueSelected,
		setOwnerValueSelected,
		filteredLevels,
		setFilteredLevels,
		firstLevel,
		setFirstLevel
	} = useContext(AssetsUsageRightsContext)

	const clearFilter = () => {
		setOwnerValueSelected(null)
		_.forEach(levels, lv => {
			lv.selectedOption = -1
		})
		setLevels([...levels])
		firstLevel.selectedOption = -1
		setFilteredLevels([])
		setOwnerLevelSelected(firstLevel)
	}

	const addItems = () => {
		addOwnershipValues()
	}

	const handleDelete = owner => {
		deleteOwnershipValues(owner)
	}

	const createLevel = async () => {
		try {
			await createLevels(newLevel).then(() => {})
		} catch (e) {
			console.error(e)
		}
	}

	const deleteLevel = () => {
		setRemoveLevel(true)
	}

	const selectFirstLevel = value => {
		setFirstLevel({ ...firstLevel, selectedOption: value })
		let filteredOptions = []
		const level = levels.filter(lv => lv.parentLevel === firstLevel.level)
		_.forEach(level, lv => {
			filteredOptions.push({
				id: lv.id,
				description: lv.description,
				level: lv.level,
				values: _.filter(lv.values, v => {
					let idx = v.parentIds.indexOf(value)
					return (
						idx !== -1 && {
							id: v.id,
							parentIds: v.parentIds,
							label: v.label
						}
					)
				}),
				parentLevel: lv.parentLevel,
				selectedOption: lv.selectedOption
			})
		})
		setFilteredLevels(filteredOptions)
		setOwnerLevelSelected(...filteredOptions)
		setOwnerValueSelected(value)
	}

	const selectLevel = (value, idx, levelId) => {
		let levelSelected = filteredLevels.find(lv => lv.id === levelId)
		const owner = levels.filter(lv => lv.parentLevel === levelSelected.level)
		let isDuplicatedLevel = owner && owner.length && _.some(filteredLevels, lv => lv.id === owner[0].id)
		filteredLevels[idx].selectedOption = value
		let filteredOwner = []
		owner &&
			owner.length &&
			_.forEach(owner, ow => {
				filteredOwner.push({
					id: ow.id,
					level: ow.level,
					description: ow.description,
					parentLevel: ow.parentLevel,
					selectedOption: ow.selectedOption,
					values: _.filter(ow.values, v => {
						let idx = v.parentIds.indexOf(value)
						return (
							idx !== -1 && {
								id: v.id,
								parentIds: v.parentIds,
								label: v.label
							}
						)
					})
				})
			})
		value !== -1 && !isDuplicatedLevel && filteredLevels.length <= levels.length && owner.length > 0
			? setFilteredLevels([...filteredLevels, ...filteredOwner])
			: setFilteredLevels([...filteredLevels])
		setOwnerLevelSelected(...filteredOwner)
		setOwnerValueSelected(value)
	}

	const checkFilters = () => {
		let isDisabled = true
		let isFirstLevelSelected = firstLevel && firstLevel.selectedOption !== -1
		let subLevelsSelected = _.some(filteredLevels, lv => lv.selectedOption !== -1)
		isDisabled = isDisabled && !isFirstLevelSelected && !subLevelsSelected
		return isDisabled
	}

	return (
		<FlexView width='100%' height='100%' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingOwnerships} borderRadius='card' />
			<FlexView width='100%' height='95%' flexDirection='row' alignItems='stretch' margin='0'>
				<Card width='40%' height='100%' data-cy='card-create-ownership'>
					<FlexView height='100%' width='100%' flexDirection='column' alignItems='center'>
						<Card width='90%' padding='16px' margin='16px'>
							<FlexView width='100%' flexDirection='row' alignItems='center' justifyContent='space-around'>
								<Input
									type='text'
									label={t('wms:CreateLevel')}
									placeholder={t('wms:NewLevel')}
									value={newLevel}
									onChange={e => setNewLevel(e.target.value)}
									data-cy='input-new-level'
								/>
								<Button
									margin='20px 0 0 8px'
									backgroundColor='success'
									color='white'
									onClick={() => createLevel()}
									disabled={!newLevel}
									data-cy='button-create-level'>
									{t('wms:Save')}
									<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
								</Button>
								<LoadingOverlay visible={create} borderRadius='16px' />
							</FlexView>
						</Card>
						<Card width='90%' padding='16px' margin='16px' data-cy='card-delete-level'>
							<FlexView width='100%' flexDirection='row' alignItems='center' justifyContent='space-around'>
								<Select
									width='100%'
									style={{ alignSelf: 'center' }}
									label={`${t('wms:Delete')} ${t('wms:Level')}`}
									placeholder={t('wms:SelectOption')}
									value={levelSelected}
									options={
										levelDesc && _.map(levelDesc, level => ({ value: level.id, label: level.description }))
									}
									onChange={v => setLevelSelected(v)}
									margin='0'
									data-cy='select-level'></Select>
								<Button
									margin='20px 0 0 8px'
									backgroundColor='error'
									color='white'
									onClick={() => deleteLevel()}
									disabled={!levelSelected}
									data-cy='button-delete-level'>
									{t('wms:Delete')}
									<Icon name='trash' color='white' width='14px' height='14px' margin='0 0 0 8px' />
									<LoadingOverlay visible={removeLevel} borderRadius='16px' />
								</Button>
							</FlexView>
						</Card>
					</FlexView>
				</Card>
				<Card width='calc(100% - 48px)' height='100%' alignItems='stretch' data-cy='card-ownership-settings'>
					<FlexView width='100%' fontSize='big' fontWeight='bold' margin='0 0 16px 0'>
						{t('wms:OwnershipSettings')}
					</FlexView>
					<FlexView width='100%' height='20%' maxHeight='20%'>
						<FlexView flexDirection='row' width='100%' flexWrap='wrap'>
							{firstLevel && Object.keys(firstLevel).length > 0 && (
								<Select
									width='30%'
									margin='8px'
									label={firstLevel.description}
									options={firstLevel.values.map(opt => ({
										value: opt.id,
										label: t(`wms:${opt.label}`)
									}))}
									placeholder={firstLevel.description}
									onChange={value => selectFirstLevel(value)}
									value={firstLevel.selectedOption}
									searchable
									data-cy='select-sublevels-ownership'
								/>
							)}
							{filteredLevels && filteredLevels.length
								? _.map(
										filteredLevels,
										(ownership, index) =>
											index < levels.length - 1 && (
												<Select
													width='30%'
													margin='8px'
													style={{ alignSelf: 'center' }}
													key={index}
													label={ownership.description}
													placeholder={t('wms:SelectOption')}
													value={ownership.selectedOption}
													options={
														ownership.values &&
														_.map(ownership.values, owner => ({ value: owner.id, label: owner.label }))
													}
													onChange={v => selectLevel(v, index, ownership.id)}
													data-cy='select-ownership'
												/>
											)
								  )
								: null}
						</FlexView>
					</FlexView>
					{levels !== null && (
						<FlexView width='100%' alignItems='center'>
							<Button disabled={checkFilters()} onClick={() => clearFilter()} data-cy='button-clear-filter'>
								{t('wms:ClearFilter')}
							</Button>
						</FlexView>
					)}
					<FlexView
						width='100%'
						flexDirection='column'
						margin='16px 0'
						style={{ borderTop: '1px solid whitesmoke' }}>
						<FlexView
							width='100%'
							flexDirection='row'
							alignItems='flex-end'
							justifyContent='flex-end'
							margin='8px 0 0 0'>
							<Input
								margin='0'
								type='text'
								label={t('wms:NewItem')}
								placeholder={t('wms:Item')}
								value={item}
								onChange={e => setItem(e.target.value)}
								disabled={levelDesc && levelDesc.length <= 0}
								data-cy='input-items'
							/>
							<Button
								margin='20px 0 0 8px'
								disabled={!item}
								backgroundColor='secondary'
								color='white'
								onClick={() => addItems()}
								alignSelf='center'
								data-cy='button-add-items'>
								{t('wms:Add')}
								<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							</Button>
						</FlexView>
						{ownerLevelSelected && ownerLevelSelected.description ? (
							<FlexView maxHeight='90%' height='90%' width='90%' style={{ overflowY: 'auto' }}>
								<FlexView fontSize='big' fontWeight='bold'>
									{ownerLevelSelected.description}
								</FlexView>
								<FlexView
									width='100%'
									flexDirection='row'
									margin='16px 0 0 0'
									padding='0'
									alignItems='center'
									justifyContent='flex-start'
									style={{ flexWrap: 'wrap' }}>
									{ownerValueSelected
										? ownerLevelSelected.values.map((owner, index) => {
												return (
													<Card width='35%' key={index}>
														<FlexView flexDirection='row' width='100%' justifyContent='space-between'>
															<FlexView
																width='max-content'
																style={{ alignSelf: 'center', textAlign: 'center' }}
																fontSize='small'
																fontWeight='bold'
																margin='8px 8px 0 0'
																padding='8px 16px'>
																{owner.label}
															</FlexView>
															<Button
																fontSize='small'
																margin='8px 8px 0 0'
																style={{ alignSelf: 'center' }}
																padding='8px 16px'
																color='white'
																backgroundColor='error'
																onClick={() => {
																	handleDelete(owner)
																}}>
																<Icon
																	name='trash'
																	color='white'
																	height='14px'
																	width='14px'
																	margin='0'
																	tooltip={t('wms:RemoveItem')}
																/>
															</Button>
														</FlexView>
													</Card>
												)
										  })
										: ownerLevelSelected.values.map((owner, index) => (
												<Card width='35%' key={index}>
													<FlexView flexDirection='row' width='100%' justifyContent='space-between'>
														<FlexView
															width='max-content'
															style={{ alignSelf: 'center', textAlign: 'center' }}
															fontSize='small'
															fontWeight='bold'
															margin='8px 8px 0 0'
															padding='8px 16px'>
															{owner.label}
														</FlexView>
														<Button
															fontSize='small'
															margin='8px 8px 0 0'
															style={{ alignSelf: 'center' }}
															padding='8px 16px'
															color='white'
															backgroundColor='error'
															onClick={() => {
																handleDelete(owner)
															}}>
															<Icon
																name='trash'
																color='white'
																height='14px'
																width='14px'
																margin='0'
																tooltip={t('wms:RemoveItem')}
															/>
														</Button>
													</FlexView>
												</Card>
										  ))}
								</FlexView>
							</FlexView>
						) : (
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='24px'
								color='lightGray'
								margin='24px 0 0 0'
								alignItems='center'
								justifyContent='center'
								data-cy='placeholder-pipes-list'>
								{`${t('wms:NoAvailableItems')}. ${t('wms:PleaseCreateLevel')}.`}
							</FlexView>
						)}
					</FlexView>
				</Card>
			</FlexView>
		</FlexView>
	)
}

export default OwnershipSettings
