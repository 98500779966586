/** @format */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay } from 'components/common'
import { REPORT_STATUS, INSPECTION } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import theme from 'utils/theme'

const InspReportInfo = ({ reportInfo, inspectionType, onCheckReport, onDownloadReport, loadingInfo = false }) => {
	const { t, i18n } = useTranslation()

	const handleDownloadReport = id => {
		id != null && onDownloadReport(id)
	}

	const handleCheckReport = id => {
		id != null && onCheckReport(id)
	}

	const getStatus = () => {
		let initialStatus = ''
		if (reportInfo.status === REPORT_STATUS.code.PENDING && reportInfo.validationdate)
			initialStatus = t(`wms:Updated`)
		else initialStatus = t(`${REPORT_STATUS.translateKey[reportInfo.status]}`)

		if (
			(reportInfo.status === REPORT_STATUS.code.PENDING && reportInfo.validationdate) ||
			reportInfo.status === REPORT_STATUS.code.VALIDATED ||
			reportInfo.status === REPORT_STATUS.code.REJECTED
		)
			initialStatus += ` ${t('wms:By')} ${reportInfo.validationresp} ${t('wms:On')} ${DATE_FORMAT(
				reportInfo.validationdate,
				i18n.language
			)}`
		return initialStatus
	}

	const getRevisionResp = reportInfo =>
		reportInfo.validationresp && reportInfo.validationdate
			? `- ${t('wms:CreatedBy')} ${reportInfo.validationresp} ${t('wms:On')}
		${DATE_FORMAT(reportInfo.validationdate, i18n.language)}`
			: ''

	const renderButtons = item => (
		<>
			{item.status === REPORT_STATUS.code.VALIDATED ? (
				<Button
					width='100%'
					fontSize='tiny'
					margin='auto 0 0 0'
					color='white'
					backgroundColor='primary'
					disabled={false}
					onClick={() => {
						handleDownloadReport(item.id)
					}}
					data-cy='button-download-report'>
					{t('wms:DownloadReport')}
				</Button>
			) : null}
			{item.status === REPORT_STATUS.code.PENDING || item.status === REPORT_STATUS.code.REJECTED ? (
				<Button
					width='100%'
					fontSize='tiny'
					margin='auto 0 0 0'
					color='white'
					backgroundColor='secondary'
					disabled={false}
					title={t('wms:AvailableSoon')}
					onClick={() => {
						handleCheckReport(item.id)
					}}
					data-cy='button-check-report'>
					{t('wms:CheckReport')}
				</Button>
			) : null}
		</>
	)

	const mrInfo = () => (
		<>
			<FlexView
				data-cy='flexview-mr-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:ReferenceMR')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.mrref}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-mr-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:FieldName')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.field}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-mr-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:WellName')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.well}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-mr-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:RigName')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.rig}
				</FlexView>
			</FlexView>
			{(inspectionType === INSPECTION.code.TPWORKSHOP || inspectionType === INSPECTION.code.MATMODIF) && (
				<>
					<FlexView
						data-cy='flexview-mr-info'
						width='50%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:ThirdPartyName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.tpartyname}
						</FlexView>
					</FlexView>
					<FlexView
						data-cy='flexview-mr-info'
						width='50%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:ThirdPartyLocation')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.tpartyloc}
						</FlexView>
					</FlexView>
				</>
			)}
			{inspectionType === INSPECTION.code.PHYSTRANS && (
				<>
					<FlexView
						data-cy='flexview-mr-info'
						width='33%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:DeliveryLocation')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.tpartyloc}
						</FlexView>
					</FlexView>
				</>
			)}
			<FlexView
				data-cy='flexview-mr-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:NumberLTPA')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.ltpa}
				</FlexView>
			</FlexView>
		</>
	)

	const recInspInfo = () => (
		<>
			<FlexView
				data-cy='flexview-rec-insp-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Name')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.name}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-rec-insp-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					MESC/CATID
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.catid}
				</FlexView>
			</FlexView>
		</>
	)

	const rigReturnInfo = () => (
		<>
			<FlexView
				data-cy='flexview-rig-return-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Name')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.name}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-rig-return-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Reference')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.rrref}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-rig-return-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:FieldName')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.field}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-rig-return-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:WellName')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.well}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-rig-return-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:RigName')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.rig}
				</FlexView>
			</FlexView>
		</>
	)

	const modifProdInfo = () => (
		<>
			<FlexView
				data-cy='flexview-modid-prod-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Name')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.name}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-modid-prod-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:ReferenceMP')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.erref}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-modid-prod-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					MESC/CATID
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.catid}
				</FlexView>
			</FlexView>
		</>
	)

	const dateTextByType = [
		t('wms:RigPrepDate'),
		t('wms:ThirdPartyDate'),
		t('wms:MaterialModificationDate'),
		t('wms:MaterialPhysicalTransferDate'),
		t('wms:ReceivingInspectionDate'),
		null,
		t('wms:RigReturnDate'),
		t('wms:PeriodicInspectionDate'),
		t('wms:ModifiedProductDate')
	]

	const renderInfo = () => {
		if (
			inspectionType === INSPECTION.code.RIGPREP ||
			inspectionType === INSPECTION.code.TPWORKSHOP ||
			inspectionType === INSPECTION.code.MATMODIF ||
			inspectionType === INSPECTION.code.PHYSTRANS
		)
			return mrInfo()
		else if (inspectionType === INSPECTION.code.RECINSP) return recInspInfo()
		else if (inspectionType === INSPECTION.code.PERIODICAL) return null
		else if (inspectionType === INSPECTION.code.MODIFPROD) return modifProdInfo()
		else if (inspectionType === INSPECTION.code.RIGRETURN) return rigReturnInfo()
	}

	return (
		<Card data-cy='card-info-wrapper' width='calc(100% - 48px)' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingInfo} />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:ReportDetails')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView
					data-cy='flexview-common-info'
					width='33%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:Name')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportInfo.id}
					</FlexView>
				</FlexView>
				<FlexView
					data-cy='flexview-common-info'
					width='33%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{dateTextByType[inspectionType]}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportInfo.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView
					data-cy='flexview-common-info'
					width={reportInfo.planresp && reportInfo.plandate ? '100%' : '33%'}
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:Revision')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportInfo.revision} {getRevisionResp(reportInfo)}
					</FlexView>
				</FlexView>
				<FlexView
					data-cy='flexview-common-info'
					width='100%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						Status
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{getStatus()}
					</FlexView>
				</FlexView>
				<FlexView
					data-cy='flexview-common-info'
					width='66%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportInfo.copurchaseorder}
					</FlexView>
				</FlexView>
				<FlexView
					data-cy='flexview-common-info'
					width='33%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportInfo.copurchaseitem}
					</FlexView>
				</FlexView>
				<FlexView
					data-cy='flexview-common-info'
					width='66%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:SalesOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportInfo.numsalesorder}
					</FlexView>
				</FlexView>
				<FlexView
					data-cy='flexview-common-info'
					width='33%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:SalesItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportInfo.numsalesitem}
					</FlexView>
				</FlexView>
				<FlexView
					data-cy='flexview-common-info'
					width='100%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:Material')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportInfo.materialdescription}
					</FlexView>
				</FlexView>
				{renderInfo()}
			</FlexView>
			{renderButtons(reportInfo)}
		</Card>
	)
}

export default InspReportInfo
