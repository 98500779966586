/** @format */

import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { Checkbox, Input, Select } from 'components/form'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { toast } from 'react-toastify'

const InspTypeModal = ({ isOpen, onOutsideClick, data, editMode }) => {
	const { t } = useTranslation()
	const { stagesList, newInspectionType, triggerReload, updateInspectionType } = useContext(AssetsInspSettingsContext)

	const availableProps = useMemo(
		() => [
			{ name: 'Name', enabled: false },
			{ name: 'MrRef', enabled: false },
			{ name: 'RrRef', enabled: false },
			{ name: 'ErRef', enabled: false },
			{ name: 'FieldName', enabled: false },
			{ name: 'RigName', enabled: false },
			{ name: 'WellName', enabled: false },
			{ name: 'TpartyName', enabled: false },
			{ name: 'TpartyLocation', enabled: false },
			{ name: 'LtpaNumber', enabled: false },
			{ name: 'DeliveryLocation', enabled: false }
		],
		[]
	)

	const inspectionModel = useMemo(
		() => ({
			warehouse: null,
			name: '',
			enabled: true,
			stage: null,
			elements: [],
			config_summary: availableProps,
			mandatory_validation: true,
			isMeasureEnabled: false,
			config_measurement: null,
			responsible: null
		}),
		[availableProps]
	)

	const [inspectionData, setInspectionData] = useState(inspectionModel)
	const [loadingResponse, setLoadingReponse] = useState(false)

	useEffect(
		() => {
			editMode && data
				? setInspectionData({ ...data, isMeasureEnabled: data.config_measurement !== 0 })
				: setInspectionData(inspectionModel)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data, editMode]
	)

	const onSaveInspectionType = async () => {
		try {
			setLoadingReponse(true)
			await newInspectionType(inspectionData)
			setLoadingReponse(false)
			toast.success(t('wms:InspectionTypeCreatedSuccessfully'))
			triggerReload()
			setInspectionData(inspectionModel)
			onOutsideClick()
		} catch (error) {
			setLoadingReponse(false)
			console.error(error)
		}
	}

	const onUpdateInspectionType = async () => {
		try {
			setLoadingReponse(true)
			await updateInspectionType(inspectionData)
			setLoadingReponse(false)
			toast.success(t('wms:InspectionTypeUpdatedSuccessfully'))
			triggerReload()
			setInspectionData(inspectionModel)
			onOutsideClick()
		} catch (error) {
			setLoadingReponse(false)
			console.error(error)
		}
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='25%' data-cy='card-add-items' style={{ position: 'relative' }}>
				<LoadingOverlay visible={loadingResponse} borderRadius='card' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 8px 0'
					justifyContent='space-between'>
					{data ? t('wms:Edit') : t('wms:New')} {t('wms:InspectionType')}
					<Button
						fontSize='tiny'
						margin='0'
						backgroundColor='error'
						padding='8px'
						onClick={() => onOutsideClick()}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>

				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='8px 0px'>
					{t('wms:SelectStage')}
				</FlexView>
				<Select
					placeholder={t('wms:Stage')}
					fontSize='small'
					value={inspectionData.stage}
					options={stagesList.map(({ id, name }) => ({
						value: id,
						label: name
					}))}
					onChange={value => {
						setInspectionData({ ...inspectionData, stage: value })
					}}
					width='100%'
					inline={false}
					margin='0 0 8px 0'
					data-cy='select-source-stage'
				/>

				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='8px 0px'>
					{t('wms:InsertInspectionTypeName')}
				</FlexView>
				<Input
					placeholder={t('wms:InspectionTypeName')}
					type='text'
					value={inspectionData.name}
					onChange={e => setInspectionData({ ...inspectionData, name: e.target.value })}
					margin='0'
					width='100%'
					minWidth='100%'
					data-cy='input-inspection-name'
					disabled={editMode}
				/>

				<Checkbox
					width='100%'
					fontSize='small'
					checked={inspectionData.isMeasureEnabled}
					onChange={v =>
						setInspectionData({ ...inspectionData, isMeasureEnabled: !inspectionData.isMeasureEnabled })
					}
					label={t('wms:DoesInspectionContainMeasurements')}
				/>

				<Button
					backgroundColor='success'
					color='white'
					onClick={() => (editMode ? onUpdateInspectionType() : onSaveInspectionType())}
					margin='24px 0 0 auto'
					data-cy='button-save-new-inspection-type'>
					{t('wms:Save')}
					<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
			</Card>
		</Modal>
	)
}

export default InspTypeModal
