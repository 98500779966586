/** @format */

import { ActualDataContext } from 'apps/wms/stores/ActualDataModifStore'
import { ALLOWED_FILES } from 'apps/wms/utils/enum'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { Input, Select } from 'components/form'
import _ from 'lodash'
import React from 'react'
import { useRef } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const NewCertificateModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const [newCertificate, setNewCertificate] = useState({
		number: '',
		version: ''
	})
	const [number, setNumber] = useState('')
	const [fileCertif, setFileCertif] = useState(null)
	const fileUploader = useRef(null)
	const { certificates, saveCertificate } = useContext(ActualDataContext)
	const [savingCertif, setSavingCertif] = useState(false)

	const createCertificate = async () => {
		let payload = {
			number: newCertificate.number ? newCertificate.number : number,
			version: newCertificate.version
		}
		try {
			setSavingCertif(true)
			await saveCertificate(payload, fileCertif, fileCertif.name.split('.').pop())
				.then(() => {
					setSavingCertif(false)
					setNewCertificate({
						number: '',
						version: ''
					})
					setNumber('')
					setFileCertif(null)
				})
				.catch(e => {
					console.error(e)
					setSavingCertif(false)
				})
		} catch (e) {
			console.error(e)
			setSavingCertif(false)
		} finally {
			setSavingCertif(false)
		}
	}

	const checkFields = () => {
		let isDisabled = true
		number
			? (isDisabled = isDisabled && (!newCertificate.version || !fileCertif))
			: (isDisabled = isDisabled && (!newCertificate.number || !newCertificate.version || !fileCertif))

		return isDisabled
	}

	const checkTypeFiles = (file, type) => {
		let isAllowed = _.some(ALLOWED_FILES.types, t => t === type)
		return isAllowed ? setFileCertif(file) : toast.error(t('wms:FileTypeNotAllowed'))
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card height='70%' width='30%' data-cy='card-new-certificate'>
				<FlexView
					width='-webkit-fill-available'
					flexDirection='row'
					justifyContent='center'
					backgroundColor='info'
					fontSize='title'
					fontWeight='bold'
					color='white'
					margin='-16px -24px 8px'
					padding='8px 16px'
					borderRadius='8px 8px 0 0'>
					{`${t('wms:Create')} ${t('wms:OR')} ${t('wms:Update')} ${t('wms:Certificate')}`}
				</FlexView>
				<FlexView flexDirection='column' width='100%' margin='16px 0 0 0' height='100%'>
					<FlexView
						flexDirection='row'
						width='100%'
						alignItems='center'
						justifyContent='center'
						style={{ alignSelf: 'center' }}>
						{certificates && (
							<Select
								width='100%'
								label={t('wms:CertificateNumber')}
								placeholder={t('wms:CertificateNumber')}
								value={newCertificate.number}
								options={
									certificates &&
									_.map(certificates, cert => {
										return { value: cert.number, label: cert.number }
									})
								}
								onChange={v => setNewCertificate({ ...newCertificate, number: v })}
								searchable
								disabled={number !== ''}
								data-cy='select-certificate-number'
							/>
						)}
						<Button
							backgroundColor='error'
							color='white'
							onClick={() => setNewCertificate({ ...newCertificate, number: '' })}
							margin='18px 0 0 16px'
							disabled={!newCertificate.number}
							data-cy='button-clear-variable'>
							{t('wms:Clear')}
						</Button>
					</FlexView>
					<Input
						label={t('wms:CertificateNumber')}
						placeholder={t('wms:CertificateNumber')}
						type='text'
						width='100%'
						value={newCertificate.number ? newCertificate.number : number}
						onChange={e => setNumber(e.target.value)}
						disabled={newCertificate.number}
						data-cy='input-certificate-number'
					/>
					<Input
						label={t('wms:CertificateVersion')}
						placeholder={t('wms:CertificateVersion')}
						type='text'
						margin='16px 0 0 0'
						width='100%'
						value={newCertificate.version}
						onChange={e => setNewCertificate({ ...newCertificate, version: e.target.value })}
						data-cy='input-certificate-version'
					/>
					<FlexView
						flexDirection='column'
						width='100%'
						margin='16px 0 0 0'
						alignItems='center'
						justifyContent='center'>
						<Button
							backgroundColor='secondary'
							color='white'
							onClick={() => {
								fileUploader.current.click()
							}}
							data-cy='button-upload-file'>
							{t('wms:UploadCertificateFile')}
							<Icon name='upload' color='white' width='14px' height='14px' margin='0 0 0 8px' />
						</Button>
						<FlexView
							style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
							flex='1'
							margin='0 8px 0 0'
							flexDirection='row'>
							{fileCertif && fileCertif.name}
						</FlexView>
						<input
							style={{ display: 'none' }}
							id='certifInput'
							type='file'
							accept='.pdf, .png, .jpg, .ods, .doc, .docx, .odt, .ppt, .pptx, .xls, .xlsx'
							ref={fileUploader}
							onChange={e => {
								checkTypeFiles(e.target.files[0], e.target.files && e.target.files[0].name.split('.').pop())
							}}
						/>
					</FlexView>
					<Button
						backgroundColor='success'
						color='white'
						margin='auto auto 0 auto'
						onClick={() => createCertificate()}
						disabled={checkFields()}
						data-cy='button-create-certificate'>
						{number
							? t('wms:CreateCertificate')
							: newCertificate.number
							? t('wms:UpdateCertificate')
							: t('wms:CreateCertificate')}
						<LoadingOverlay visible={savingCertif} borderRadius='card' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default NewCertificateModal
