/** @format */

import React, { useState, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, Modal, Icon, LoadingOverlay } from 'components/common'
import { Select, Input, DatePicker } from 'components/form'

import { RigReturnContext } from '../../stores/RigReturnStore'

const RRModal = ({ isOpen, onOutsideClick }) => {
	const { newRR, endusers, levels, uploadRRFile } = useContext(RigReturnContext)
	const { t } = useTranslation()

	const [rrUpload, setRRUpload] = useState(false)
	const [sourceEnduser, setSourceEnduser] = useState(null)
	const [sourceOwnership, setSourceOwnership] = useState(null)
	const [rrFile, setRRFile] = useState(null)
	const [fileUpload, setFileUpload] = useState(false)
	const fileUploader = useRef(null)

	const baseRR = {
		name: '',
		enduser: '',
		ownershipid: '',
		rrref: '',
		field: '',
		well: '',
		rig: '',
		rrfile: null,
		expecteddate: null,
		contentquantity: 0
	}
	const [rrData, setRRData] = useState(baseRR)

	const validateData = () => {
		let isValid = true
		isValid =
			isValid &&
			rrData.name &&
			sourceEnduser &&
			sourceOwnership &&
			rrData.rrref &&
			rrData.field &&
			rrData.well &&
			rrData.rig &&
			rrFile &&
			rrData.expecteddate &&
			rrData.contentquantity
		return isValid
	}

	const allNull = () => {
		return (
			rrData.name === '' &&
			sourceEnduser === null &&
			sourceOwnership === null &&
			rrData.rrref === '' &&
			rrData.field === '' &&
			rrData.well === '' &&
			rrData.rig === '' &&
			rrFile === null &&
			rrData.expecteddate === null &&
			rrData.contentquantity === 0
		)
	}

	const saveRR = async () => {
		try {
			setFileUpload(true)
			await uploadRRFile(rrFile)
				.then(fileName => {
					rrData.rrfile = fileName
					setRRData({ ...rrData, rrfile: fileName })
				})
				.finally(() => setFileUpload(false))
			setRRUpload(true)
			rrData.ownershipid = sourceOwnership
			rrData.enduser = sourceEnduser
			setRRData({ ...rrData, ownershipid: sourceOwnership, enduser: sourceEnduser })
			await newRR(rrData)
				.then(response => response && setSourceEnduser(null))
				.finally(() => setRRUpload(false))
			resetFields()
		} catch (e) {
			console.error(e)
		}
	}

	const resetFields = () =>
		new Promise((resolve, reject) => {
			setRRData(baseRR)
			setSourceEnduser(null)
			setSourceOwnership(null)
			setRRFile(null)
			fileUploader.current.value = null
		})

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='80%' height='65%' position='relative' data-cy='card-modal-wrapper'>
				<LoadingOverlay visible={fileUpload || rrUpload} borderRadius='16px' />
				<FlexView
					maxWidth='100%'
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:NewRigReturn')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView maxWidth='30%' minWidth='30%' minHeight='100%' maxHeight='100%' margin='0 16px 0 0'>
						<Card margin='0 0 4px 0' width='calc(100% - 48px)' flex='1'>
							<FlexView width='100%' margin='0 0 16px 0' padding='0' position='relative'>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
									{t('wms:EndUser')}
								</FlexView>
								<Select
									placeholder={t(`wms:EndUser`)}
									value={sourceEnduser && sourceEnduser.id}
									options={endusers.map(({ id, name }) => ({
										value: id,
										label: name
									}))}
									onChange={value => {
										setSourceEnduser(endusers.find(eu => eu.id === value))
									}}
									width='100%'
									inline={false}
									margin='0'
									searchable
									data-cy='select-source-enduser'
								/>
							</FlexView>
							{levels && levels.length > 0 && (
								<Select
									label={t('wms:SourceOwnership')}
									placeholder={t(`wms:SourceOwnership`)}
									value={sourceOwnership}
									options={levels.map(value => ({
										label: value.label,
										value: value.id
									}))}
									onChange={value => {
										setSourceOwnership(value)
									}}
									width='100%'
									inline={false}
									margin='0'
									searchable
									data-cy='select-source-ownership'
								/>
							)}
							<FlexView flexDirection='row' width='100%' justifyContent='space-between' margin='auto 0 0 0'>
								<Button
									fontSize='medium'
									margin='0'
									color='white'
									backgroundColor='warning'
									disabled={allNull()}
									onClick={() => {
										resetFields()
									}}
									data-cy='button-reset'>
									<Icon name='refresh' color='white' height='16px' width='16px' margin='0 8px 0 0' />
									{t('wms:Restart')}
								</Button>
							</FlexView>
						</Card>
					</FlexView>
					<FlexView width='calc(70% - 16px)' minHeight='100%' maxHeight='100%'>
						<Card margin='0 0 4px 0' width='calc(100% - 48px)' minHeight='inherit' flex='1'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:RigReturnInfo')}
							</FlexView>
							{sourceEnduser !== null ? (
								<FlexView
									flexDirection='row'
									width='100%'
									maxWidth='100%'
									flexWrap='wrap'
									justifyContent='space-between'
									margin='0 0 16px 0'>
									<FlexView width='48%' margin='0'>
										<Input
											label={`${t('wms:Name')}`}
											placeholder={`${t('wms:Name')}`}
											margin='8px 0 0 0'
											value={rrData.name}
											onChange={e => {
												setRRData({ ...rrData, name: e.target.value })
											}}
											fontSize='small'
											width='100%'
											data-cy='input-name'
										/>
									</FlexView>
									<FlexView width='48%' margin='0'>
										<Input
											label={`${t('wms:ReferenceRR')}`}
											placeholder={`${t('wms:ReferenceRR')}`}
											margin='8px 0 0 0'
											value={rrData.rrref}
											onChange={e => {
												setRRData({ ...rrData, rrref: e.target.value })
											}}
											fontSize='small'
											width='100%'
											data-cy='input-rrref'
										/>
									</FlexView>

									<FlexView width='32%' margin='0'>
										<Input
											label={`${t('wms:FieldName')}`}
											placeholder={`${t('wms:FieldName')}`}
											margin='8px 0 0 0'
											value={rrData.field}
											onChange={e => {
												setRRData({ ...rrData, field: e.target.value })
											}}
											fontSize='small'
											width='100%'
											data-cy='input-fieldname'
										/>
									</FlexView>
									<FlexView width='32%' margin='0'>
										<Input
											label={`${t('wms:RigName')}`}
											placeholder={`${t('wms:RigName')}`}
											margin='8px 0 0 0'
											value={rrData.rig}
											onChange={e => {
												setRRData({ ...rrData, rig: e.target.value })
											}}
											fontSize='small'
											width='100%'
											data-cy='input-rigname'
										/>
									</FlexView>
									<FlexView width='32%' margin='0'>
										<Input
											label={`${t('wms:WellName')}`}
											placeholder={`${t('wms:WellName')}`}
											margin='8px 0 0 0'
											value={rrData.well}
											onChange={e => {
												setRRData({ ...rrData, well: e.target.value })
											}}
											fontSize='small'
											width='100%'
											data-cy='input-wellname'
										/>
									</FlexView>

									<DatePicker
										label={`${t('wms:DispatchExpectedDate')}`}
										placeholder={`${t('wms:SelectDate')}`}
										margin='8px 0 0 0'
										timePicker={true}
										value={rrData.expecteddate}
										onChange={e => {
											setRRData({ ...rrData, expecteddate: e })
										}}
										fontSize='small'
										data-cy='datepicker'
									/>

									<FlexView width='32%' margin='0'>
										<Input
											label={`${t('wms:NumberParts')}`}
											margin='8px 0 0 0'
											min='1'
											type='number'
											value={rrData.contentquantity}
											onChange={e => {
												setRRData({ ...rrData, contentquantity: e.target.value })
											}}
											fontSize='small'
											width='100%'
											data-cy='input-partsqty'
										/>
									</FlexView>
								</FlexView>
							) : (
								<FlexView
									width='100%'
									fontWeight='bold'
									fontSize='24px'
									color='lightGray'
									margin='auto 0'
									alignItems='center'
									justifyContent='center'>
									{t('wms:SelectEnduser')}
								</FlexView>
							)}
						</Card>
					</FlexView>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='16px 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0 8px 0 0'
						style={{ marginLeft: 'calc(30% + 16px)' }}
						color='white'
						backgroundColor='secondary'
						onClick={() => {
							fileUploader.current.click()
						}}
						data-cy='button-fileupload'>
						{t('wms:UploadRRFile')}
						<Icon name={'upload'} color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
					<FlexView
						style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
						flex='1'
						margin='0 8px 0 0'
						flexDirection='row'>
						{rrFile && rrFile.name}
					</FlexView>
					<input
						style={{ display: 'none' }}
						id='rrFileInput'
						type='file'
						accept='application/pdf'
						ref={fileUploader}
						onChange={e => {
							setRRFile(e.target.files[0])
						}}
					/>
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='success'
						disabled={!validateData()}
						onClick={() => saveRR()}
						data-cy='button-save'>
						{t('wms:Save')}
						<Icon name='save' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default RRModal
