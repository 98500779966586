/** @format */

import { AssetsLevelsContext } from 'apps/wms/stores/AssetsLevelsStore'
import { Card, FlexView } from 'components/common'
import Button from 'components/common/Button'
import LoadingOverlay from 'components/common/LoadingOverlay'
import Modal from 'components/common/Modal'
import { RadioGroup } from 'components/form'
import React, { useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const PrintModal = ({ level, isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { printers, setPrinterSelected, isPrinting, printLevels } = useContext(AssetsLevelsContext)
	const [value, setValue] = useState(null)

	const handleChange = e => {
		setValue(e)
		setPrinterSelected(e)
	}

	const confirmPrint = level => {
		printLevels(level)
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card data-cy='card-printers'>
				<FlexView fontSize='24px'>{t('wms:SelectPrinter')}</FlexView>
				{printers && (
					<RadioGroup name='printers' options={printers} value={value} onChange={evt => handleChange(evt)} />
				)}
				<FlexView flexDirection='row' width='100%' justifyContent='flex-end'>
					<Button backgroundColor='error' color='white' onClick={() => onOutsideClick()}>
						{t('wms:Cancel')}
					</Button>
					<Button
						disabled={!value}
						margin='8px 0 0 16px'
						backgroundColor='success'
						color='white'
						onClick={() => confirmPrint(level)}>
						{t('wms:Confirm')}
						<LoadingOverlay visible={isPrinting} />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default PrintModal
