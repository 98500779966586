/** @format */

import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Movement from '../api/Movement'
import _ from 'lodash'
import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import content from '../api/content'

export const MovementContext = React.createContext()

export const MovementProvider = ({ children }) => {
	const {
		warehouse,
		displayBundles,
		displayValids,
		getLastLevelsList,
		getLevelContent,
		getReportList,
		getReportItems,
		getMaterialsDescpritions
	} = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const [sourceRack, setSourceRack] = useState(null)
	const [targetRack, setTargetRack] = useState(null)
	const [sourceRackList, setSourceRackList] = useState([])
	const [targetRackList, setTargetRackList] = useState([])
	const [loadingPipes, setLoadingPipes] = useState(false)
	const [availablePipes, setAvailablePipes] = useState([])
	const [selectedPipes, setSelectedPipes] = useState([])
	const [movingItems, setMovingItems] = useState(false)
	const [openConfirmModal, setConfirmModal] = useState(false)
	const [rackOccupation, setRackOccupation] = useState([])
	const [qntyTargetRack, setQntyTargetRack] = useState(null)
	const [loadingRackOccupation, setLoadingRackOccupation] = useState(false)
	const [selectedType, setSelectedType] = useState(null)
	const [reports, setReports] = useState(null)
	const [loadingReports, setLoadingReports] = useState(false)
	const [selectedReport, setSelectedReport] = useState(null)
	const [materialList, setMaterialList] = useState([])
	const [selectedMaterial, setSelectedMaterial] = useState(null)
	const [filteredPipes, setFilteredPipes] = useState([])
	const [filteredRacks, setFilteredRacks] = useState([])
	const [filteredMaterials, setFilteredMaterials] = useState([])

	const getRackOccupation = useCallback(availablePipes => {
		let group = _.groupBy(availablePipes, 'material.id')
		let keys = Object.keys(group)
		let materialGroup = []
		keys.forEach(key => {
			materialGroup.push({
				mid: group[key][0].material.id,
				formatted: group[key][0].material.formatted,
				quantity: group[key].length
			})
		})
		return materialGroup.length ? materialGroup : []
	}, [])

	const getFilteredRackList = mid =>
		new Promise((resolve, reject) => {
			content
				.getMaterialsBySearch(
					warehouse.id,
					{
						filtermaterialids: [mid],
						minlengthmm: null,
						maxlengthmm: null
					},
					token
				)
				.then(response => {
					setFilteredRacks(
						_.map(response, res => ({
							id: res.level.id,
							fullname: res.level.fullname
						}))
					)
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingLevels')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const filterMaterialList = list => {
		let filteredMaterialList = _.filter(list, material => material.level.id === sourceRack).map(mat => ({
			id: mat.material.id,
			formatted: mat.material.formatted
		}))
		setFilteredMaterials(_.uniqBy(filteredMaterialList, materials => materials.id))
	}

	const filterAvailablePipes = () => {
		if (selectedMaterial && sourceRack) {
			let filteredPipesList = _.filter(
				availablePipes,
				pipe => pipe.material.id === selectedMaterial && pipe.level.id === sourceRack
			)
			setFilteredPipes(filteredPipesList)
		}
	}

	useEffect(() => {
		selectedMaterial && sourceRack && availablePipes && filterAvailablePipes()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMaterial, sourceRack, availablePipes])

	useEffect(() => {
		getMaterialsDescpritions()
			.then(response => {
				setMaterialList(
					response.map(materials => ({
						id: materials.mvid,
						formatted: materials.formatted
					}))
				)
			})
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (selectedMaterial) {
			const hydrate = async () => {
				try {
					await getFilteredRackList(selectedMaterial)
				} catch (e) {
					console.error(e)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMaterial])

	useEffect(() => {
		rackOccupation && rackOccupation.length
			? setQntyTargetRack(rackOccupation.reduce((a, b) => a + b.quantity, 0))
			: setQntyTargetRack(0)
	}, [rackOccupation])

	useEffect(() => {
		getLastLevelsList()
			.then(lastLevelsList => {
				setTargetRackList(
					lastLevelsList.sort((a, b) => (a.fullname > b.fullname ? 1 : b.fullname > a.fullname ? -1 : 0))
				)
				setSourceRackList(
					lastLevelsList.sort((a, b) => (a.fullname > b.fullname ? 1 : b.fullname > a.fullname ? -1 : 0))
				)
			})
			.catch(e => {
				console.error(e)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (sourceRack != null) {
			sourceRack === targetRack && setTargetRack(null)
			setSelectedPipes([])
			setLoadingPipes(true)
			setAvailablePipes([])
			getLevelContent(sourceRack)
				.then(contentList => {
					if (contentList && contentList.length) {
						setAvailablePipes(contentList)
						filterMaterialList(contentList)
					}
				})
				.catch(e => {})
				.finally(() => {
					setLoadingPipes(false)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceRack, getLevelContent])

	useEffect(() => {
		if (targetRack != null) {
			setLoadingRackOccupation(true)
			getLevelContent(targetRack)
				.then(contentList => {
					if (contentList && contentList.length) setRackOccupation(getRackOccupation(contentList))
				})
				.catch(e => {
					setRackOccupation([])
				})
				.finally(() => {
					setLoadingRackOccupation(false)
				})
		} else setRackOccupation([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetRack, getLevelContent])

	useEffect(() => {
		if (selectedType != null) {
			setLoadingReports(true)
			getReportList(selectedType)
				.then(list => {
					if (list && list.length) setReports(list)
					else {
						setReports([])
						toast.error(t('wms:NoAvailableReports'))
					}
				})
				.catch(e => {
					console.error(e)
					setLoadingReports(false)
				})
				.finally(() => setLoadingReports(false))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType])

	useEffect(() => {
		if (selectedType != null && selectedReport != null) {
			setLoadingPipes(true)
			getReportItems(selectedType, selectedReport)
				.then(list => setAvailablePipes(list))
				.catch(e => {
					setLoadingPipes(false)
					console.error(e)
				})
				.finally(() => setLoadingPipes(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType, selectedReport])

	useEffect(() => {
		setAvailablePipes([])
		setSelectedReport(null)
	}, [selectedType])

	useEffect(() => {
		setAvailablePipes([])
		setSelectedType(null)
		setSelectedReport(null)
		setSourceRack(null)
	}, [warehouse])

	const moveItems = (sourceFilter, sourceRack, targetRack, pipes, report) =>
		new Promise((resolve, reject) => {
			setConfirmModal(false)
			setMovingItems(true)
			if (sourceFilter === 'RACK' && !(!!sourceRack && !!targetRack && pipes.length !== 0)) {
				setMovingItems(false)
				toast.error(t(`wms:InvalidMoveSelection`))
				return
			} else if (sourceFilter === 'REPORTS' && !(!!report && !!targetRack && pipes.length !== 0)) {
				setMovingItems(false)
				toast.error(t(`wms:InvalidMoveSelection`))
				return
			}
			let payload = { secondsago: 0 }
			if (displayBundles()) payload.bundlelocalids = pipes
			else if (displayValids()) payload.valids = pipes
			else payload.puuids = pipes
			Movement.moveItems(warehouse.id, targetRack, payload, token)
				.then(response => {
					setSelectedPipes([])
					setSourceRack(null)
					setTargetRack(null)
					setSelectedMaterial(null)
					setAvailablePipes([])
					setFilteredMaterials([])
					setFilteredRacks([])
					setFilteredPipes([])
					toast.success(t(`wms:MoveSuccessful`))
					resolve()
				})
				.catch(e => {
					console.error(e)
					toast.error(t(`${e.data}`) || t(`wms:ErrorReadingContent`))
					reject(e)
				})
				.finally(() => {
					setMovingItems(false)
				})
		})

	const getRackName = useCallback(
		rackId => {
			let rack = sourceRackList.find(rack => rack.id === rackId)
			return rack ? rack.fullname : ''
		},
		[sourceRackList]
	)

	const renderStore = (
		<MovementContext.Provider
			value={{
				sourceRack,
				setSourceRack,
				targetRack,
				setTargetRack,
				sourceRackList,
				setSourceRackList,
				targetRackList,
				setTargetRackList,
				availablePipes,
				selectedPipes,
				setSelectedPipes,
				loadingPipes,
				moveItems,
				movingItems,
				openConfirmModal,
				setConfirmModal,
				getRackName,
				rackOccupation,
				qntyTargetRack,
				loadingRackOccupation,
				selectedType,
				setSelectedType,
				reports,
				setReports,
				loadingReports,
				setLoadingReports,
				selectedReport,
				setSelectedReport,
				setAvailablePipes,
				materialList,
				setMaterialList,
				selectedMaterial,
				setSelectedMaterial,
				filteredPipes,
				setFilteredPipes,
				filteredRacks,
				setFilteredRacks,
				filteredMaterials,
				setFilteredMaterials
			}}>
			{children}
		</MovementContext.Provider>
	)
	return renderStore
}
