/** @format */

import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import MR from '../api/MR'
import usageRights from '../api/usageRights'
import _ from 'lodash'
import { MRType } from '../utils/enum'

import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'

export const MRContext = React.createContext()

export const MRProvider = ({ children }) => {
	const { warehouse, lastLevelsList, getLevelsData, getEnduserList, getContentByOwnership, getMaterialsDescpritions } =
		useContext(WMSContext)
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const [mrList, setMrList] = useState([])
	const [materialList, setMaterialList] = useState([])
	const [loadingMRList, setLoadingMRList] = useState(false)
	const [selectedMR, setSelectedMR] = useState(null)
	const [updateModal, setUpdateModal] = useState(false)
	const [levels, setLevels] = useState([])
	const [uploadMRList, setUploadMRList] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false)
	const [mrModal, setMrModal] = useState(false)
	const [level, setLevel] = useState(null)
	const [endusers, setEndusers] = useState([])

	const getRigPrepList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				MR.getRigPrepList(warehouse.id, token)
					.then(list => {
						list.length && list.map(item => (item.type = MRType.code.RIGPREP))
						resolve(list)
					})
					.catch(e => {
						toast.error(`${t(`wms:ErrorGetting`)} Rig Prep ${t(`wms:Lists`)}`)
						reject(e)
					})
					.finally(() => {})
		})

	const getTPWorkshopList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				MR.getTPartyWorkList(warehouse.id, token)
					.then(list => {
						list.length && list.map(item => (item.type = MRType.code.TPWORKSHOP))
						resolve(list)
					})
					.catch(e => {
						toast.error(`${t(`wms:ErrorGetting`)} Third Party Workshop ${t(`wms:Lists`)}`)
						reject(e)
					})
					.finally(() => {})
		})

	const getMatModifList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				MR.getMatModifList(warehouse.id, token)
					.then(list => {
						list.length && list.map(item => (item.type = MRType.code.MATMODIF))
						resolve(list)
					})
					.catch(e => {
						toast.error(`${t(`wms:ErrorGetting`)} Material Modification ${t(`wms:Lists`)}`)
						reject(e)
					})
					.finally(() => {})
		})

	const getPhysTransList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				MR.getPhysTransferList(warehouse.id, token)
					.then(list => {
						list.length && list.map(item => (item.type = MRType.code.PHYSTRANS))
						resolve(list)
					})
					.catch(e => {
						toast.error(`${t(`wms:ErrorGetting`)} Material Physical Transfer ${t(`wms:Lists`)}`)
						reject(e)
					})
					.finally(() => {})
		})

	const getMRLists = () =>
		new Promise((resolve, reject) => {
			setLoadingMRList(true)
			Promise.allSettled([getRigPrepList(), getTPWorkshopList(), getMatModifList(), getPhysTransList()])
				.then(listArray => {
					let preList = []
					listArray.forEach(
						list => list.status === 'fulfilled' && list.value.length && (preList = preList.concat(list.value))
					)
					preList.sort((a, b) => a.id - b.id)
					resolve(preList.map(l => ({ ...l, content: l.content.map(c => ({ ...c, enduserid: l.enduserid })) })))
				})
				.catch(e => reject(e))
				.finally(() => setLoadingMRList(false))
		})

	const _getOwnershipByEnduser = (wid, enduser, token, t) =>
		new Promise((resolve, reject) => {
			usageRights
				.getOwnershipsListByEndUser(wid, enduser, token)
				.then(response => {
					let lastLevel = Math.max.apply(
						Math,
						response.ownershipdescriptions.map(res => res.level)
					)
					let oData = response.ownershipdescriptions
					let ownershipList = []
					let lastLevelDesc = _.filter(oData, owner => {
						return owner.level === lastLevel
					})
					_.forEach(lastLevelDesc, owner => {
						ownershipList.push({ id: owner.id, label: owner.namepath.join('.') })
					})
					resolve(ownershipList)
				})
				.catch(e => {
					toast.error(t(`wms:UploadFailure`))
					reject(e)
				})
				.finally(() => {})
		})

	const getOwnershipByEnduser = useCallback(
		enduser => _getOwnershipByEnduser(warehouse.id, enduser, token, t),
		[warehouse, token, t]
	)

	const getContent = (sourceOwnership, mrType) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				sourceOwnership &&
					getContentByOwnership(sourceOwnership, mrType === MRType.code.PHYSTRANS)
						.then(contentList => resolve(contentList))
						.catch(e => {
							toast.error(t(`wms:ErrorGettingContent`))
							reject(e)
						})
						.finally(() => {})
		})

	const deleteMR = (mrId, mrType) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			mrType !== null &&
				MR.deleteMR(warehouse.id, mrId, mrType, token)
					.then(response => {
						toast.success(t(`wms:MaterialRequisitionRemoved`))
						resolve(true)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorDeletingMatReqList`))
						reject(e)
					})
					.finally(() => {})
		})

	const uploadMRFile = (file, mrType) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			if (window.FileReader) {
				const reader = new FileReader()
				reader.onload = event => {
					event.target.result &&
						mrType !== null &&
						MR.uploadMRFile(warehouse.id, event.target.result, mrType, token)
							.then(fileName => resolve(fileName))
							.catch(e => {
								toast.error(t(`wms:UploadFailure`))
								reject(e)
							})
							.finally(() => {})
				}
				reader.onerror = () => {
					toast.error(t('wms:ErrorReadingFile'))
				}
				reader.readAsDataURL(file)
			} else reject('Filereader not found')
		})

	const newMR = (mrData, mrType) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			let payload = {
				name: mrData.name,
				mrref: mrData.mrref,
				ltpa: mrData.ltpa,
				field: mrData.field,
				well: mrData.well,
				rig: mrData.rig,
				mrfile: mrData.file,
				expecteddate: mrData.expecteddate,
				closuredate: mrData.closuredate,
				enduserid: mrData.content[0].enduserid,
				content: mrData.content,
				responsible: user.name
			}

			if (mrType !== MRType.code.RIGPREP) {
				payload.tpartyname = mrData.tpartyname
				payload.tpartyloc = mrData.tpartyloc
			}
			mrType !== null &&
				MR.newMR(warehouse.id, payload, mrType, token)
					.then(response => {
						toast.success(t(`wms:MaterialRequisitionCreated`))
						resolve(true)
					})
					.catch(e => {
						toast.error(t(`${e}`))
						reject(e)
					})
					.finally(() => {})
		})

	const updateMR = (mrData, mrid, mrType) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			let payload = {
				name: mrData.name,
				mrref: mrData.mrref,
				ltpa: mrData.ltpa,
				field: mrData.field,
				well: mrData.well,
				rig: mrData.rig,
				mrfile: mrData.file,
				expecteddate: mrData.expecteddate,
				closuredate: mrData.closuredate,
				enduserid: mrData.content[0].enduserid,
				content: mrData.content,
				responsible: user.name
			}

			if (mrType !== MRType.code.RIGPREP) {
				payload.tpartyname = mrData.tpartyname
				payload.tpartyloc = mrData.tpartyloc
			}
			mrType !== null &&
				MR.updateMR(warehouse.id, mrid, payload, mrType, token)
					.then(response => {
						toast.success(t(`wms:MaterialRequisitionUpdated`))
						resolve(true)
					})
					.catch(e => {
						toast.error(t(`${e}`))
						reject(e)
					})
					.finally(() => {})
		})

	const handleReopen = content =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const mrId = MRType.cid[selectedMR.type]
			const path = MRType.path[selectedMR.type]
			var payload = {}
			payload[mrId] = content[mrId]

			MR.reopenMR(warehouse.id, payload, path, token)
				.then(response => {
					toast.success(t(`wms:SuccessfullyReopenContent`))
					setUploadMRList(true)
					resolve(true)
				})
				.catch(e => {
					toast.error(t(`${e}`))
					reject(e)
				})
				.finally(() => {})
		})

	const openMRFile = () =>
		new Promise((resolve, reject) => {
			selectedMR &&
				selectedMR.id &&
				MR.openMRFile(MRType.path[selectedMR.type], warehouse.id, selectedMR.id, token)
					.then(response => {
						let fileName = `${MRType.literal[selectedMR.type]}MR_${warehouse.id}_${selectedMR.id}_0.pdf`
						let a = document.createElement('a')
						let file = new Blob([response], {
							type: response.type
						})
						let fileUrl = window.URL.createObjectURL(file)
						a.href = fileUrl
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorOpeningMRFile')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`)
					})
		})

	useEffect(() => {
		setUploadMRList(true)
		getMaterialsDescpritions()
			.then(materialsList => setMaterialList(materialsList))
			.catch(e => console.error(e))
		getEnduserList()
			.then(endusersList => endusersList && setEndusers(endusersList))
			.catch(e => console.error(e))
		getLevelsData()
			.then(levels => setLevels(levels))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		getMRLists()
			.then(MRList => MRList.length && setMrList(MRList))
			.catch(e => console.error(e))
		setUploadMRList(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadMRList])

	useEffect(() => {
		getMRLists()
			.then(MRList => setMrList(MRList))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setSelectedMR(null)
	}, [warehouse])

	const renderStore = (
		<MRContext.Provider
			value={{
				mrList,
				loadingMRList,
				selectedMR,
				setSelectedMR,
				updateModal,
				setUpdateModal,
				lastLevelsList,
				openMRFile,
				handleReopen,
				levels,
				getContent,
				uploadMRFile,
				newMR,
				updateMR,
				deleteMR,
				setUploadMRList,
				materialList,
				isUpdate,
				setIsUpdate,
				mrModal,
				setMrModal,
				level,
				setLevel,
				endusers,
				getOwnershipByEnduser
			}}>
			{children}
		</MRContext.Provider>
	)
	return renderStore
}
