/** @format */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Card, FlexView, Button, Icon } from 'components/common'
import { useTranslation } from 'react-i18next'
import Input from './Input'

const ActionDialog = ({
	isOpen,
	onConfirm,
	onCancel,
	onAction,
	title,
	text,
	confirmationText,
	confirmText,
	actionText,
	cancelText,
	danger
}) => {
	const [confirmation, setConfirmation] = useState('')
	const { t } = useTranslation()

	const onConfirmationChange = e => {
		setConfirmation(e.target.value)
	}

	const onInputKeyPress = e => {
		e.key === 'Enter' && confirmation === confirmationText && onConfirm()
	}

	useEffect(() => {
		!isOpen && setConfirmation('')
	}, [isOpen])

	return (
		<Modal isOpen={isOpen} onOutsideClick={onCancel}>
			<Card minWidth='300px' minHeight='130px' maxWidth='800px'>
				<FlexView
					width='100%'
					justifyContent='space-between'
					flexDirection='row'
					alignItems='center'
					margin='0 0 8px 0'>
					<FlexView width='100%' justifyContent='center' alignItems='center' fontSize='24px' fontWeight='bold'>
						{title || t('Confirm')}
					</FlexView>
					<Icon
						name='cross-simple'
						width='16px'
						height='16px'
						onClick={onCancel}
						style={{ alignItems: 'center', alignSelf: 'center' }}
					/>
				</FlexView>
				<FlexView flexDirection='column' alignSelf='center' alignItems='center'>
					{text.split('\n').map((t, index) => (
						<FlexView key={index} fontSize='20px' style={{ textAlign: 'center' }}>
							{t}
							<br />
						</FlexView>
					))}
				</FlexView>
				{confirmationText && (
					<Input
						value={confirmation}
						onChange={onConfirmationChange}
						placeholder={confirmationText}
						onKeyPress={onInputKeyPress}
						width='100%'
						margin='8px 0px 0px'
					/>
				)}
				<FlexView flexDirection='row' alignSelf='stretch' justifyContent='center' margin='16px 0px 0px'>
					<Button
						margin='0px'
						onClick={onCancel}
						style={{ borderRadius: '4px', boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,0.13)', height: '45px' }}>
						{cancelText || t('No')}
					</Button>
					<Button
						margin='0px 0px 0px 16px'
						backgroundColor='secondary'
						color='white'
						onClick={onAction}
						style={{ borderRadius: '4px', boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,0.13)', height: '45px' }}>
						{actionText || t('Action')}
					</Button>
					<Button
						margin='0px 0px 0px 16px'
						backgroundColor={danger ? 'error' : 'success'}
						color='white'
						disabled={confirmationText && confirmationText !== confirmation}
						onClick={onConfirm}
						style={{ borderRadius: '4px', boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,0.13)', height: '45px' }}>
						{confirmText || t('Yes')}
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

ActionDialog.propTypes = {
	/**
	 * Boolean to control if the dialog is open or not
	 */
	isOpen: PropTypes.bool,
	/**
	 * Function called when the user confirms the dialog
	 */
	onConfirm: PropTypes.func,
	/**
	 * Function called when the user cancel the dialog or clicks outside of it
	 */
	onCancel: PropTypes.func,
	/**
	 * Dialog modal title
	 */
	title: PropTypes.string,
	/**
	 * Dialog modal text
	 */
	text: PropTypes.string,
	/**
	 * Confirmation text that the user must input before confirming the dialog
	 */
	confirmationText: PropTypes.string,
	/**
	 * Text that will appear in the confirm button
	 */
	confirmText: PropTypes.string,
	/**
	 * Text that will appear in the cancel button
	 */
	cancelText: PropTypes.string,
	/**
	 * Change confirmation button color to indicate danger
	 */
	danger: PropTypes.bool
}

ActionDialog.defaultProps = {
	isOpen: false
}

export default ActionDialog
