/** @format */

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { Button, Card, FlexView, Icon, Modal, LoadingOverlay } from 'components/common'
import UploadBox from '../../../components/upload/UploadBox'
import { AssetsReportRefContext } from '../../../stores/AssetsReportRefStore'

const UploadLogoModal = ({ isOpen, title, subtitle, onOutsideClick, onCancel }) => {
	const { t } = useTranslation()
	const { uploadedFile, setUploadedFile, onLogoUpload, setImgBase64, pageLoading, setPageLoading, currentLogo } =
		useContext(AssetsReportRefContext)

	const outsideClick = async () => {
		setUploadedFile([])
		setImgBase64('')
		onOutsideClick()
	}

	const onUpload = async () => {
		setPageLoading(true)
		await onLogoUpload()
		outsideClick()
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={outsideClick} onModalExit={onCancel}>
			<Card data-cy='card-update-reference-report' width='500px'>
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 8px 0'
					justifyContent='space-between'>
					{t(title)}
					<Button
						fontSize='tiny'
						margin='0 0 0 16px'
						backgroundColor='error'
						disabled={false}
						onClick={outsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='80%' fontSize='medium' flexDirection='row' margin='0px 0px 10px 0px'>
					<i> {t(subtitle)} </i>
				</FlexView>
				<FlexView flexDirection='column' width='100%' padding='20px 0px 0px 0px'>
					<FlexView
						fontSize='medium'
						fontWeight='bold'
						width='100%'
						flexDirection='row'
						justifyContent='flex-start'>
						<i> {t('wms:CurrentLogo')} </i>
					</FlexView>
					{!currentLogo ? (
						<>
							<FlexView padding='10px' width='100%' flexDirection='row' justifyContent='center'>
								<Icon name='no-image' color='#ddd' width='100px' height='100px' margin='0 0 0 8px' />
							</FlexView>
							<FlexView justifyContent='center' width='100%' flexDirection='row'>
								<i> {t('wms:NoLogoAssociated')} </i>
							</FlexView>
						</>
					) : (
						<img src={currentLogo} alt='' width='500px' height='auto' />
					)}
				</FlexView>
				<UploadBox
					fontSize='5'
					fileTypes='.jpg'
					setFile={setUploadedFile}
					setBase64={setImgBase64}
					text={!_.isEmpty(uploadedFile) ? uploadedFile.name : t('wms:UploadImageBoxMessage')}
					height='50'
					maxSize='400000'></UploadBox>
				<Button
					backgroundColor='primary'
					color='white'
					margin='auto 0 0 auto'
					onClick={() => onUpload()}
					disabled={!uploadedFile || uploadedFile.length === 0}
					data-cy='button-upload-image'>
					{t('wms:Save').toUpperCase()}
					<Icon name='save' color='white' width='16px' height='16px' margin='0 0 0 8px' />
				</Button>
				<LoadingOverlay visible={pageLoading} borderRadius='card' />
			</Card>
		</Modal>
	)
}

export default UploadLogoModal
