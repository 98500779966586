/** @format */

import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { DateRangePicker, MultiSelect, Select } from 'components/form'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PERIOD_TYPE } from '../../utils/enum'
import _ from 'lodash'
import { ReportsContext } from 'apps/wms/stores/ReportStore'
import { toast } from 'react-toastify'

const DailyReportFilter = () => {
	const { t } = useTranslation()

	const { endUsers, downloadDailyReport } = useContext(ReportsContext)

	const [loadingDailyReport, setLoadingDailyReport] = useState(false)
	const [period, setPeriod] = useState(null)
	const [endUser, setEndUser] = useState(null)
	const [years, setYears] = useState(null)
	const [selectedYear, setSelectedYear] = useState(null)
	const [multipleYears, setMultipleYears] = useState([])
	const [months, setMonths] = useState(null)
	const [selectedMonth, setSelectedMonth] = useState([])
	const [weeks, setWeeks] = useState(null)
	const [multipleWeeks, setMultipleWeeks] = useState([])
	const [startValue, setStartValue] = useState(null)
	const [endValue, setEndValue] = useState(null)

	const periodType = [
		{ value: PERIOD_TYPE.code.Days, label: 'wms:Days' },
		{ value: PERIOD_TYPE.code.Weeks, label: 'wms:Weeks' },
		{ value: PERIOD_TYPE.code.Months, label: 'wms:Months' },
		{ value: PERIOD_TYPE.code.Years, label: 'wms:Years' }
	]

	const changePeriodType = value => {
		setPeriod(value)
		setStartValue(null)
		setEndValue(null)
		setSelectedYear(null)
		setMultipleYears([])
		setMultipleWeeks([])
	}

	const getYears = () => {
		let max = new Date().getFullYear()
		let min = max - 5
		let yearArray = []
		for (var i = max; i >= min; i--) {
			yearArray.push(i)
		}
		setYears(yearArray)
	}

	const getMonths = () => {
		let firstDay = new Date(selectedYear, 0, 1)
		let lastDay = new Date(selectedYear, 11, 31)
		let firstMonth = new Date(firstDay).getMonth()
		let lastMonth = new Date(lastDay).getMonth()
		let monthArray = []
		for (var i = firstMonth; i <= lastMonth; i++) {
			monthArray.push(i + 1)
		}
		setMonths(monthArray)
	}

	const getWeeks = () => {
		let firstDay = new Date(selectedYear, 0, 1)
		let lastDay = new Date(selectedYear, 11, 31)
		let numberOfDays = Math.floor((lastDay - firstDay) / (24 * 60 * 60 * 1000))
		let numberOfWeeks = Math.ceil(numberOfDays / 7)
		let weekArray = []
		for (var i = 1; i <= numberOfWeeks; i++) {
			weekArray.push(i)
		}
		setWeeks(weekArray)
	}

	const handleDailyReport = () => {
		setLoadingDailyReport(true)
		let payload = {}
		period === 'D'
			? (payload = {
					enduser: endUser,
					periodtype: period,
					days: {
						startDate: moment(startValue).format('YYYY-MM-DD'),
						endDate: moment(endValue).format('YYYY-MM-DD')
					}
			  })
			: period === 'W'
			? (payload = {
					enduser: endUser,
					periodtype: period,
					weeks: multipleWeeks,
					years: [selectedYear]
			  })
			: period === 'M'
			? (payload = {
					enduser: endUser,
					periodtype: period,
					months: selectedMonth,
					years: [selectedYear]
			  })
			: period === 'Y'
			? (payload = {
					enduser: endUser,
					periodtype: period,
					years: multipleYears
			  })
			: (payload = null)
		downloadDailyReport(payload)
			.then(() => {
				toast.success(t('wms:DownloadSuccessful'))
				setSelectedMonth([])
				setMultipleWeeks([])
				setMultipleYears([])
				setEndUser(null)
				setPeriod(null)
				setSelectedYear(null)
			})
			.catch(error => {
				console.error(error)
			})
			.finally(() => setLoadingDailyReport(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	const checkProperties = () => {
		let isDisabled = true
		isDisabled =
			isDisabled &&
			(!endUser ||
				!period ||
				(period === 'D' && (startValue === null || endValue === null)) ||
				(period === 'W' && multipleWeeks.length === 0) ||
				(period === 'M' && selectedMonth.length === 0) ||
				(period === 'Y' && multipleYears.length === 0))

		return isDisabled
	}

	useEffect(() => {
		endUser && getYears()
	}, [endUser])

	useEffect(() => {
		selectedYear && getMonths()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedYear])

	useEffect(() => {
		selectedYear && getWeeks()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedYear])

	return (
		<Card
			data-cy='card-wrapper-spot-report'
			margin='0'
			justifySelf='center'
			width='100%'
			style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingDailyReport} borderRadius='16px' />
			<FlexView flexDirection='row' width='100%' justifyContent='center'>
				{endUsers && (
					<Select
						label={t('wms:EndUser')}
						placeholder={t('wms:EndUser')}
						options={_.map(endUsers, eu => {
							return { value: eu.id, label: eu.name }
						})}
						value={endUser}
						onChange={v => setEndUser(v)}
						searchable
						data-cy='select-end-user'
					/>
				)}
				<Select
					margin='8px 0 0 24px'
					label={t('wms:PeriodType')}
					placeholder={t('wms:PeriodType')}
					options={_.map(periodType, p => ({ ...p, label: t(p.label) }))}
					value={period}
					onChange={v => changePeriodType(v)}
					disabled={!endUser}
					data-cy='select-period-type'
				/>
				{period === 'D' && (
					<DateRangePicker
						margin='8px 0 0 24px'
						startValue={startValue}
						onStartChange={v => setStartValue(v)}
						endValue={endValue}
						onEndChange={v => setEndValue(v)}
						label={t('wms:SelectDateRange')}
					/>
				)}
				{(period === 'W' || period === 'M') && (
					<Select
						margin='8px 0 0 24px'
						label={t('wms:Year')}
						placeholder={t('wms:Year')}
						options={_.map(years, y => {
							return { value: y, label: `${y}` }
						})}
						value={selectedYear}
						onChange={v => setSelectedYear(v)}
						data-cy='select-year'
					/>
				)}
				{period === 'W' && weeks && (
					<MultiSelect
						width='25%'
						maxWidth='50%'
						margin='8px 0 0 24px'
						label={t('wms:Weeks')}
						selectedValues={multipleWeeks}
						options={
							weeks &&
							_.map(weeks, w => {
								return { value: w, label: `${w}` }
							})
						}
						onChange={v => setMultipleWeeks(v)}
						data-cy='multiselect-weeks'
					/>
				)}
				{period === 'M' && months && (
					<MultiSelect
						width='25%'
						maxWidth='50%'
						margin='8px 0 0 24px'
						label={t('wms:Months')}
						placeholder={t('wms:Months')}
						selectedValues={selectedMonth}
						options={
							months &&
							_.map(months, m => {
								return { value: m, label: `${m}` }
							})
						}
						onChange={v => setSelectedMonth(v)}
						data-cy='multiselect-months'
					/>
				)}
				{period === 'Y' && years && (
					<MultiSelect
						width='25%'
						maxWidth='50%'
						margin='8px 0 0 24px'
						label={t('wms:Years')}
						placeholder={t('wms:Years')}
						selectedValues={multipleYears}
						options={
							years &&
							_.map(years, y => {
								return { value: y, label: `${y}` }
							})
						}
						onChange={v => setMultipleYears(v)}
						data-cy='multiselect-years'
					/>
				)}
			</FlexView>
			<Button
				backgroundColor='secondary'
				color='white'
				margin='0 auto'
				fontSize='small'
				onClick={handleDailyReport}
				disabled={checkProperties()}
				data-cy='button-generate-spot-report'>
				<Icon name='csv' color='white' height='24px' width='24px' margin='0 8px 0 0' />
				{t('wms:GenerateReport')}
			</Button>
		</Card>
	)
}

export default DailyReportFilter
