/** @format */

import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'

import { FlexView, Button, Icon } from 'components/common'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'
import _ from 'lodash'

const Row = styled(FlexView)`
	position: relative;
	flex-direction: row;
	flex-wrap: nowrap;
	width: fit-content;
	height: 60px;
	max-width: 100%;
	margin-bottom: 16px;
	border-radius: 4px;
	overflow-x: hidden;

	div.scroll-area {
		transition: all ease 0.2s;
	}
`

const TabButton = styled(Button)`
	border-radius: 4px;
	padding: 12px 16px;
	font-size: 18px;
	height: 45px;
	font-family: Roboto-Medium;
	line-height: 21px;

	&:not(:focus):hover {
		background-color: rgba(102, 126, 234, 0.08);
		font-family: Roboto-Medium;
		color: ${theme.colors.darkGray};
	}

	&:focus {
		outline: none;
	}
`

const OptionsWrapper = styled(FlexView)`
	font-family: 'Roboto';
	display: flex;
	position: absolute;
	top: 220px;
	right: 160px;
	left: auto;
	width: 200px;
	opacity: ${({ open }) => (open ? '1' : '0')};
	visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
	flex-direction: column;
	margin: 8px 0px;
	background: #ffffff;
	box-shadow: ${({ theme }) => theme.boxShadows.high};
	overflow-y: auto;
	border-radius: 4px;
	z-index: 999;
	transition: all 0.2s ease;
	transform: ${({ open }) => `${open ? 'translateY(0)' : 'scale(0)'} !important`};
	will-change: transform;
`

const Option = styled(FlexView)`
	height: 32px;
	width: 184px;
	padding: 4px 0 0 16px;
	font-size: 16px;
	display: flex;
	justify-content: 4;
	line-height: 24px;
	cursor: pointer;

	&:not(:focus):hover {
		background-color: rgba(102, 126, 234, 0.08);
		font-family: Roboto;
		color: ${theme.colors.darkGray};
	}

	&:focus {
		outline: none;
	}
`

const TabsRow = ({ tabsList, selectedTab, setSelectedTab, tabWidth = 250, width, onChange }) => {
	const { t } = useTranslation()
	const [currentTabs, setCurrentTabs] = useState([])
	const [moreTabs, setMoreTabs] = useState([])
	const [isActive, setIsActive] = useState(false)
	const dropdownRef = useRef(null)

	const getSelectedOption = useCallback(() => {
		const selected = _.find(moreTabs, m => m.value === selectedTab)
		return selected && selected.label ? `${t(selected.label)}` : ''
	}, [selectedTab, moreTabs, t])

	const toggleSelect = () => setIsActive(isActive => !isActive)

	const onSelect = selectedValue => () => {
		onChange(selectedValue)
		setSelectedTab(selectedValue)
		toggleSelect()
	}

	const hiddenInfo = () => {
		let unchecked = _.some(moreTabs, tab => tab.value === selectedTab)
		return unchecked
	}

	useEffect(() => {
		if (tabsList && tabsList.length * tabWidth > width) {
			let tabs = []

			for (var i = 0; i < tabsList.length; i++) {
				if (i * tabWidth < width) {
					tabs.push(tabsList[i])
				}
			}
			let others = _.difference(tabsList, tabs)
			setCurrentTabs(tabs)
			setMoreTabs(others)
		} else setCurrentTabs(tabsList)
	}, [tabsList, tabWidth, width])

	useEffect(() => {
		const pageClickEvent = e => {
			if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
				setIsActive(!isActive)
			}
		}
		if (isActive) window.addEventListener('click', pageClickEvent)

		return () => {
			window.removeEventListener('click', pageClickEvent)
		}
	}, [isActive])

	return (
		<FlexView flexDirection='column' width='100%'>
			<FlexView flexDirection='row' width='100%'>
				<Row data-cy='nav-tabs-row' elevation='low'>
					<FlexView
						backgroundColor='white'
						className='scroll-area'
						flexDirection='row'
						height='100%'
						style={{
							width: '100%'
						}}>
						{currentTabs &&
							currentTabs.map((item, index) => (
								<TabButton
									key={item.value}
									margin={
										!index
											? '8px 0 8px 8px'
											: index === currentTabs.length - 1
											? '8px 8px 8px 0'
											: '8px 0 8px 0'
									}
									onClick={() => {
										setSelectedTab(item.value)
									}}
									backgroundColor={selectedTab === item.value ? '#667EEA' : theme.colors.white}
									color={selectedTab === item.value ? theme.colors.white : theme.colors.gray}
									width={`${tabWidth}px`}
									data-cy='button-nav-tab'>
									{t(item.label)}
								</TabButton>
							))}
					</FlexView>
				</Row>
				{moreTabs && moreTabs.length > 0 ? (
					<FlexView flexDirection='column'>
						<FlexView
							width='60px'
							height='60px'
							margin=' 0 0 0 16px'
							elevation='low'
							borderRadius='4px'
							backgroundColor={moreTabs.some(tab => tab.value === selectedTab) ? '#667EEA' : theme.colors.white}>
							<Icon
								name='more-vert'
								width='24px'
								height='24px'
								margin='18px'
								tooltip={t('wms:MoreOptions')}
								tooltipPosition='bottom'
								style={{
									alignItems: 'center',
									alignSelf: 'center',
									backgroundColor: moreTabs.some(tab => tab.value === selectedTab)
										? theme.colors.white
										: theme.colors.gray
								}}
								onClick={() => setIsActive(!isActive)}
							/>
						</FlexView>
						<OptionsWrapper open={isActive} ref={dropdownRef}>
							{isActive &&
								moreTabs &&
								moreTabs.map(options => (
									<Option
										key={options.value}
										backgroundColor={selectedTab === options.value ? '#667EEA' : theme.colors.white}
										onClick={onSelect(options.value)}
										color={selectedTab === options.value ? theme.colors.white : theme.colors.black}>
										{t(options.label)}
									</Option>
								))}
						</OptionsWrapper>
					</FlexView>
				) : null}
			</FlexView>
			{selectedTab != null && hiddenInfo() && (
				<FlexView
					margin='0 0 8px 0'
					width='100%'
					justifyContent='flex-end'
					alignItems='flex-end'
					alignSelf='flex-end'
					fontSize='16px'
					fontFamily='Roboto'
					flexDirection='row'
					color='gray'>
					{`${t('wms:CurrentlyDisplaying')}:`}
					<FlexView fontSize='16px' fontFamily='Roboto' color='#667EEA' margin='0 0 0 4px'>
						{getSelectedOption()}
					</FlexView>
				</FlexView>
			)}
		</FlexView>
	)
}

export default TabsRow
