/** @format */

const { REPORT_ITEM_STATUS, REPORT_TYPE, listStatus } = require('./enum')

const formatCommon = data => ({
	valid: data.valid,
	sourcevalid: data.sourcevalid,
	puuid: data.puuid,
	length: data.length,
	modified: false
})

const formatMeasurement = data => ({
	measurestatus: data.measurestatus,
	intdiameter: data.measure.intdiameter,
	outdiameter: data.measure.outdiameter,
	measured: data.measure.measured,
	makeuploss: data.measure.makeuploss,
	effective: data.measure.effective,
	measureresponsible: data.measure.responsible
})

const hasPhoto = (data, reportType) => {
	const isStorageReport = reportType === REPORT_TYPE.code.RECINSP || reportType === REPORT_TYPE.code.PERIODICAL

	const hasPhoto =
		data.inspection.marklegibilityphoto !== 'N' ||
		data.inspection.pinphoto !== 'N' ||
		data.inspection.boxphoto !== 'N' ||
		data.inspection.intbodyphoto !== 'N' ||
		data.inspection.extbodyphoto !== 'N' ||
		data.inspection.driftphoto !== 'N' ||
		(isStorageReport ? data.inspection.coatingvarnishphoto !== 'N' || data.inspection.threadprotphoto !== 'N' : false)
	return { hasphoto: hasPhoto }
}

const formatInspection = (data, reportType) => {
	let prop =
		reportType === REPORT_TYPE.code.RECINSP || reportType === REPORT_TYPE.code.PERIODICAL
			? { ...formatCoatingVarnish(data), ...formatThreadProtector(data) }
			: {}

	if (reportType === REPORT_TYPE.code.RIGRETURN) {
		prop = { ...prop, status: data.status, levelid: data.levelid }
	}

	return {
		inspstatus: data.inspstatus,
		finalreport: data.inspection.finalreport,
		responsible: data.inspection.responsible,
		remarks: data.inspection.remarks,
		instlistid: data.instlistid > 0 ? data.instlistid : null,
		...hasPhoto(data, reportType),
		...formatMarkLeg(data),
		...formatPin(data),
		...formatBox(data),
		...formatIntBody(data),
		...formatExtBody(data),
		...formatDriftBody(data),
		...prop
	}
}

const formatMarkLeg = data => ({
	marklegibility: {
		report:
			data.inspection.marklegibilityreport >= 9
				? REPORT_ITEM_STATUS.code.NOTOK
				: data.inspection.marklegibilityreport,
		photo: data.inspection.marklegibilityphoto,
		defect: data.inspection.marklegibilityreport
	}
})

const formatPin = data => ({
	pin: {
		report: data.inspection.pinreport >= 9 ? REPORT_ITEM_STATUS.code.NOTOK : data.inspection.pinreport,
		photo: data.inspection.pinphoto,
		defect: data.inspection.pinreport
	}
})

const formatBox = data => ({
	box: {
		report: data.inspection.boxreport >= 9 ? REPORT_ITEM_STATUS.code.NOTOK : data.inspection.boxreport,
		photo: data.inspection.boxphoto,
		defect: data.inspection.boxreport
	}
})

const formatIntBody = data => ({
	intbody: {
		report: data.inspection.intbodyreport >= 9 ? REPORT_ITEM_STATUS.code.NOTOK : data.inspection.intbodyreport,
		photo: data.inspection.intbodyphoto,
		defect: data.inspection.intbodyreport
	}
})

const formatExtBody = data => ({
	extbody: {
		report: data.inspection.extbodyreport >= 9 ? REPORT_ITEM_STATUS.code.NOTOK : data.inspection.extbodyreport,
		photo: data.inspection.extbodyphoto,
		defect: data.inspection.extbodyreport
	}
})

const formatDriftBody = data => ({
	drift: {
		report: data.inspection.driftreport >= 9 ? REPORT_ITEM_STATUS.code.NOTOK : data.inspection.driftreport,
		photo: data.inspection.driftphoto,
		defect: data.inspection.driftreport
	}
})

const formatCoatingVarnish = data => ({
	coatingvarnish: {
		report:
			data.inspection.coatingvarnishreport >= 9
				? REPORT_ITEM_STATUS.code.NOTOK
				: data.inspection.coatingvarnishreport,
		photo: data.inspection.coatingvarnishphoto,
		defect: data.inspection.coatingvarnishreport
	}
})

const formatThreadProtector = data => ({
	threadprot: {
		report: data.inspection.threadprotreport >= 9 ? REPORT_ITEM_STATUS.code.NOTOK : data.inspection.threadprotreport,
		photo: data.inspection.threadprotphoto,
		defect: data.inspection.threadprotreport
	}
})

const formatThreadCompound = data => ({
	batchnumber: data.threadcompound.batchnumber ? data.threadcompound.batchnumber : null,
	applicationmethod: {
		id: data.threadcompound.method && data.threadcompound.method.id ? data.threadcompound.method.id : null
	},
	manufacturer: {
		id: data.threadcompound.type && data.threadcompound.type.id ? data.threadcompound.type.id : null
	},
	manufacturerstate:
		data.threadcompound.type && data.threadcompound.type.state ? data.threadcompound.type.state : null,
	tcresponsible: data.threadcompound.responsible ? data.threadcompound.responsible : null
})

const formatRigPrep = function (data) {
	const items = data.bundles
		.filter(bundle => bundle.items[0].rpcid !== 0)
		.filter(
			bundle => bundle.items[0].inspstatus === listStatus.done && bundle.items[0].measurestatus === listStatus.done
		)
		.map(bundle => ({
			cid: bundle.items[0].rpcid,
			...formatCommon(bundle.items[0]),
			...formatInspection(bundle.items[0], REPORT_TYPE.code.RIGPREP),
			...formatMeasurement(bundle.items[0]),
			...formatThreadCompound(bundle.items[0])
		}))
	const content = data.content
	const summary = { totaljoints: items.length }
	return { content, items, summary }
}

const formatMatModif = function (data) {
	const items = data.bundles
		.filter(bundle => bundle.items[0].mmcid !== 0)
		.filter(
			bundle => bundle.items[0].inspstatus === listStatus.done && bundle.items[0].measurestatus === listStatus.done
		)
		.map(bundle => ({
			cid: bundle.items[0].mmcid,
			...formatCommon(bundle.items[0]),
			...formatInspection(bundle.items[0], REPORT_TYPE.code.MATMODIF),
			...formatMeasurement(bundle.items[0])
		}))
	const content = data.content
	const summary = { totaljoints: items.length }
	return { content, items, summary }
}

const formatTParty = function (data) {
	const items = data.bundles
		.filter(bundle => bundle.items[0].tpcid !== 0)
		.filter(
			bundle => bundle.items[0].inspstatus === listStatus.done && bundle.items[0].measurestatus === listStatus.done
		)
		.map(bundle => ({
			cid: bundle.items[0].tpcid,
			...formatCommon(bundle.items[0]),
			...formatInspection(bundle.items[0], REPORT_TYPE.code.TPWORKSHOP),
			...formatMeasurement(bundle.items[0])
		}))
	const content = data.content
	const summary = { totaljoints: items.length }
	return { content, items, summary }
}

const formatPhysTrans = function (data) {
	const items = data.bundles
		.filter(bundle => bundle.items[0].ptcid !== 0)
		.filter(
			bundle => bundle.items[0].inspstatus === listStatus.done && bundle.items[0].measurestatus === listStatus.done
		)
		.map(bundle => ({
			cid: bundle.items[0].ptcid,
			...formatCommon(bundle.items[0]),
			...formatInspection(bundle.items[0], REPORT_TYPE.code.PHYSTRANS),
			...formatMeasurement(bundle.items[0])
		}))
	const content = data.content
	const summary = { totaljoints: items.length }
	return { content, items, summary }
}

const formatRigReturn = function (data) {
	const items = data.items
		.filter(item => item.rrlcid !== 0)
		.filter(item => item.status === listStatus.done)
		.map(item => ({
			cid: item.rrlcid,
			...formatCommon(item),
			...formatInspection(item, REPORT_TYPE.code.RIGRETURN)
		}))
	const content = data.content
	const summary = { totaljoints: items.length }
	return { content, items, summary }
}

const formatRecInspection = function (data) {
	const items = data.bundles
		.filter(bundle => bundle.items[0].rilcid !== 0)
		.filter(bundle => bundle.items[0].inspstatus === listStatus.done)
		.map(bundle => ({
			cid: bundle.items[0].rilcid,
			...formatCommon(bundle.items[0]),
			...formatInspection(bundle.items[0], REPORT_TYPE.code.RECINSP)
		}))
	const content = data.content
	const summary = { totaljoints: items.length }
	return { content, items, summary }
}

const formatPerInspection = function (data) {
	const items = data.bundles
		.filter(bundle => bundle.items[0].pilcid !== 0)
		.filter(bundle => bundle.items[0].inspstatus === listStatus.done)
		.map(bundle => ({
			cid: bundle.items[0].pilcid,
			...formatCommon(bundle.items[0]),
			...formatInspection(bundle.items[0], REPORT_TYPE.code.PERIODICAL)
		}))
	const content = data.content
	const summary = { totaljoints: items.length }
	return { content, items, summary }
}

const formatModifProd = function (data) {
	const items = data.items
		.filter(item => item.mplcid !== 0)
		.filter(item => item.inspstatus === listStatus.done)
		.map(item => ({
			cid: item.mplcid,
			...formatCommon(item),
			...formatInspection(item, REPORT_TYPE.code.MODIF_PRODUCTS),
			...formatMeasurement(item)
		}))
	const content = data.content
	const summary = {
		totaljoints: content.reduce((prev, curr) => prev + curr.quantity, 0),
		totaluntraceable: content.reduce((prev, curr) => prev + curr.ntquantity, 0)
	}
	return { content, items, summary }
}

const formatReceiptNote = function (data) {
	const items = data.items.map(item => ({
		erpreference: item.catid,
		materialdesc: item.materialdescription,
		valid: item.valid,
		length: item.pipelength,
		weight: item.pipeweight
	}))
	return { items, summary: data.summary }
}

const downloadFormatters = [
	formatRigPrep,
	formatTParty,
	formatMatModif,
	formatPhysTrans,
	formatRecInspection,
	formatPerInspection, //Spot inspection has the same config as Periodical inspection
	formatRigReturn,
	formatPerInspection,
	formatModifProd,
	'wms:Dispatch',
	'wms:BackloadManifest',
	formatReceiptNote,
	'wms:SlowMovingItems',
	'wms:TransferOfTitle',
	'wms:ReceiptNote'
]

const uploadFormatCommon = data => ({
	valid: data.valid,
	puuid: data.puuid,
	secondsago: 0
})

const uploadFormatMeasurement = data => ({
	measured: data.measured,
	makeuploss: data.makeuploss,
	effective: data.effective,
	measurestatus: data.measurestatus,
	measureresponsible: data.measureresponsible,
	intdiameter: data.intdiameter,
	outdiameter: data.outdiameter
})

const uploadFormatInspection = (data, reportType) => {
	let prop =
		reportType === REPORT_TYPE.code.RECINSP || reportType === REPORT_TYPE.code.PERIODICAL
			? {
					...uploadFormatCoatingVarnish(data),
					...uploadFormatThreadProtector(data)
			  }
			: {}
	return {
		inspstatus: data.inspstatus,
		finalreport: data.finalreport === undefined ? -1 : data.finalreport,
		inspresponsible: data.responsible,
		remarks: data.remarks,
		usedlistid: data.instlistid,
		...uploadFormatMarkLeg(data),
		...uploadFormatPin(data),
		...uploadFormatBox(data),
		...uploadFormatIntBody(data),
		...uploadFormatExtBody(data),
		...uploadFormatDriftBody(data),
		...prop
	}
}

const reportOrDefect = item => {
	if (item.report === undefined) return -1
	else {
		if (item.report === REPORT_ITEM_STATUS.code.NOTOK) return item.defect
		else return item.report
	}
}

const dataURLOrPath = url => {
	if (url.startsWith('data:')) return url
	else if (url.startsWith('/home')) return 'Y'
	else return 'N'
}

const uploadFormatMarkLeg = data => ({
	marklegibilityreport: reportOrDefect(data.marklegibility),
	marklegibilityphoto: dataURLOrPath(data.marklegibility.photo)
})

const uploadFormatPin = data => ({
	pinreport: reportOrDefect(data.pin),
	pinphoto: dataURLOrPath(data.pin.photo)
})

const uploadFormatBox = data => ({
	boxreport: reportOrDefect(data.box),
	boxphoto: dataURLOrPath(data.box.photo)
})

const uploadFormatIntBody = data => ({
	intbodyreport: reportOrDefect(data.intbody),
	intbodyphoto: dataURLOrPath(data.intbody.photo)
})

const uploadFormatExtBody = data => ({
	extbodyreport: reportOrDefect(data.extbody),
	extbodyphoto: dataURLOrPath(data.extbody.photo)
})

const uploadFormatDriftBody = data => ({
	driftreport: reportOrDefect(data.drift),
	driftphoto: dataURLOrPath(data.drift.photo)
})

const uploadFormatCoatingVarnish = data => ({
	coatingvarnishreport: reportOrDefect(data.coatingvarnish),
	coatingvarnishphoto: dataURLOrPath(data.coatingvarnish.photo)
})

const uploadFormatThreadProtector = data => ({
	threadprotreport: reportOrDefect(data.threadprot),
	threadprotphoto: dataURLOrPath(data.threadprot.photo)
})

const uploadFormatThreadCompound = data => ({
	threadcompoundtypeid: data.manufacturer.id,
	threadcompoundmethodid: data.applicationmethod.id,
	threadcompoundbatchnumber: data.batchnumber,
	threadcompoundresp: data.tcresponsible
})

const uploadFormatRigPrep = function (data) {
	const items = data
		.filter(item => item.modified === true)
		.map(item => ({
			rpcid: item.cid,
			...uploadFormatCommon(item),
			...uploadFormatInspection(item, REPORT_TYPE.code.RIGPREP),
			...uploadFormatMeasurement(item),
			...uploadFormatThreadCompound(item)
		}))
	return items
}

const uploadFormatMatModif = function (data) {
	const items = data
		.filter(item => item.modified === true)
		.map(item => ({
			mmcid: item.cid,
			...uploadFormatCommon(item),
			...uploadFormatInspection(item, REPORT_TYPE.code.MATMODIF),
			...uploadFormatMeasurement(item)
		}))
	return items
}

const uploadFormatTParty = function (data) {
	const items = data
		.filter(item => item.modified === true)
		.map(item => ({
			tpcid: item.cid,
			...uploadFormatCommon(item),
			...uploadFormatInspection(item, REPORT_TYPE.code.TPWORKSHOP),
			...uploadFormatMeasurement(item)
		}))
	return items
}

const uploadFormatPhysTrans = function (data) {
	const items = data
		.filter(item => item.modified === true)
		.map(item => ({
			ptcid: item.cid,
			...uploadFormatCommon(item),
			...uploadFormatInspection(item, REPORT_TYPE.code.PHYSTRANS),
			...uploadFormatMeasurement(item)
		}))
	return items
}

const uploadFormatRigReturn = function (data) {
	const items = data
		.filter(item => item.modified === true)
		.map(item => ({
			...uploadFormatCommon(item),
			...uploadFormatInspection(item, REPORT_TYPE.code.RIGRETURN),
			rrlcid: item.cid,
			status: item.status,
			levelid: item.levelid,
			responsible: item.responsible
		}))
	return items
}

const uploadFormatRecInspection = function (data) {
	const items = data
		.filter(item => item.modified === true)
		.map(item => ({
			rilcid: item.cid,
			status: item.inspstatus,
			responsible: item.responsible,
			...uploadFormatCommon(item),
			...uploadFormatInspection(item, REPORT_TYPE.code.RECINSP)
		}))
	return items
}

const uploadFormatPerInspection = function (data) {
	const items = data
		.filter(item => item.modified === true)
		.map(item => ({
			pilcid: item.cid,
			status: item.inspstatus,
			responsible: item.responsible,
			...uploadFormatCommon(item),
			...uploadFormatInspection(item, REPORT_TYPE.code.PERIODICAL)
		}))
	return items
}

const uploadFormatModifProd = function (data) {
	const items = data
		.filter(item => item.modified === true)
		.map(item => ({
			mplcid: item.cid,
			...uploadFormatCommon(item),
			...uploadFormatInspection(item, REPORT_TYPE.code.MODIF_PRODUCTS),
			...uploadFormatMeasurement(item)
		}))
	return items
}

const uploadFormatters = [
	uploadFormatRigPrep,
	uploadFormatTParty,
	uploadFormatMatModif,
	uploadFormatPhysTrans,
	uploadFormatRecInspection,
	uploadFormatPerInspection, //Spot inspection has the same config as Periodical inspection
	uploadFormatRigReturn,
	uploadFormatPerInspection,
	uploadFormatModifProd,
	'wms:Dispatch',
	'wms:BackloadManifest',
	'wms:SlowMovingItems',
	'wms:TransferOfTitle',
	'wms:ReceiptNote'
]

export default function formatReportData(data, reportType, isDownload) {
	return isDownload ? downloadFormatters[reportType](data) : uploadFormatters[reportType](data)
}
