/** @format */

import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'

import assetsRefReports from '../api/assetsReportRef'

export const AssetsReportRefContext = React.createContext()

export const AssetsReportRefProvider = ({ children }) => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const { token, user } = useContext(UserContext)
	const [reports, setReports] = useState(null)
	const [selectedReport, setSelectedReport] = useState(null)
	const [updateReport, setUpdateReport] = useState(null)
	const [reference, setReference] = useState('')
	const [loadingReports, setLoadingReports] = useState(false)
	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [update, setUpdate] = useState(false)
	const [uploadedFile, setUploadedFile] = useState([])
	const [imgBase64, setImgBase64] = useState('')
	const [pageLoading, setPageLoading] = useState(false)
	const [currentLogo, setCurrentLogo] = useState('')

	const getReports = () =>
		new Promise((resolve, reject) => {
			assetsRefReports
				.getReports(warehouse.id, token)
				.then(response => {
					setReports(response.list)
					resolve()
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingReports')} [ ${e.status}]: ${e.data}`)
					console.error(e)
				})
		})

	const updateRefReport = () =>
		new Promise((resolve, reject) => {
			let payload = {
				reporttype: updateReport.reporttype,
				reportreference: reference,
				responsible: user.name
			}
			assetsRefReports
				.updateReport(warehouse.id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:ReferenceReportUpdated'))
					setUpdateReport(null)
					setOpenUpdateModal(false)
					setReference('')
					setSelectedReport(null)
					getReports()
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorUpdatingReference')} [ ${e.status} ]: ${e.data}`)
					reject(e)
					setUpdateReport(null)
					setOpenUpdateModal(false)
					setReference('')
					setSelectedReport(null)
				})
		})

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingReports(true)
				await getReports()
			} catch (e) {
				setLoadingReports(false)
				console.error(e)
			} finally {
				setLoadingReports(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (update) {
			const hydrate = async () => {
				try {
					await updateRefReport()
				} catch (e) {
					console.error(e)
					setUpdate(false)
				} finally {
					setUpdate(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [update])

	useEffect(() => {
		const rep = _.find(reports, r => {
			return r.reporttype === selectedReport.reporttype
		})
		openUpdateModal && setUpdateReport(rep)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUpdateModal])

	const onLogoUpload = async () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				assetsRefReports
					.uploadWarehouseLogo(
						warehouse.id,
						{
							data: imgBase64
						},
						token
					)
					.then(list => {
						getCurrentWarehouseLogo()
						toast.success(`${t('wms:LogoUploadSuccessful') + '!'}`)
						resolve(list)
					})
					.catch(e => {
						toast.error(`${t(`wms:ErrorUploadingLogo`) + '!'}`)
						reject(e)
					})
					.finally(() => {
						setPageLoading(false)
					})
		})

	const getCurrentWarehouseLogo = async () =>
		new Promise((resolve, reject) => {
			assetsRefReports
				.getCurrentWarehouseLogo(warehouse.id, token)
				.then(response => {
					setCurrentLogo(response)
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingLogo')} [ ${e.status}]: ${e.data}`)
					console.error(e)
				})
		})

	useEffect(() => {
		getCurrentWarehouseLogo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setSelectedReport(null)
	}, [warehouse])

	const renderStore = (
		<AssetsReportRefContext.Provider
			value={{
				reports,
				setReports,
				reference,
				setReference,
				loadingReports,
				setLoadingReports,
				selectedReport,
				setSelectedReport,
				openUpdateModal,
				setOpenUpdateModal,
				update,
				setUpdate,
				updateRefReport,
				uploadedFile,
				setUploadedFile,
				onLogoUpload,
				imgBase64,
				setImgBase64,
				pageLoading,
				setPageLoading,
				currentLogo
			}}>
			{children}
		</AssetsReportRefContext.Provider>
	)
	return renderStore
}
