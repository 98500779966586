/** @format */

import { PrepareEntranceContext } from 'apps/wms/stores/PrepareEntranceStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const ConfirmVALIDGenModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { savingChanges, genValids, openRelocateModal, resetProcess, setOpenConfirmModal, setOpenRelocateModal } =
		useContext(PrepareEntranceContext)
	const history = useHistory()

	const confirmGeneration = () => {
		genValids()
	}

	const restartProcess = () => {
		resetProcess()
	}

	const goToLandingPage = () => {
		setOpenConfirmModal(false)
		setOpenRelocateModal(false)
		history.push('/wms/')
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='30%' data-cy='card-confirm-generation'>
				{openRelocateModal ? (
					<FlexView flexDirection='column' width='100%'>
						<FlexView
							width='100%'
							fontSize='big'
							fontWeight='bold'
							margin='0 0 16px 0'
							alignItems='center'
							style={{ textAlign: 'center' }}>
							{t('wms:VALIDsGenerated')}
						</FlexView>
						<FlexView
							width='100%'
							fontSize='medium'
							fontWeight='bold'
							style={{ textAlign: 'center' }}
							margin='0 0 16px 0'>
							{t('wms:VALIDsGeneratedMessage')}
						</FlexView>
						<FlexView flexDirection='row' margin='auto 0 0 auto'>
							<Button
								backgroundColor='secondary'
								color='white'
								onClick={() => restartProcess()}
								data-cy='button-restart-process'>
								{t('wms:Restart')} {t('wms:Process')}
								<Icon name='refresh' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							</Button>
							<Button
								backgroundColor='success'
								color='white'
								onClick={() => goToLandingPage()}
								margin='8px 0 0 8px'
								data-cy='button-go-to-pipe-data'>
								{t('wms:Ok')}
								<Icon name='check' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							</Button>
						</FlexView>
					</FlexView>
				) : (
					<FlexView flexDirection='column' width='100%'>
						<FlexView
							width='100%'
							fontSize='big'
							fontWeight='bold'
							margin='0 0 16px 0'
							style={{ textAlign: 'center' }}>
							{t('wms:ConfirmVALIDGenMessage')}
						</FlexView>
						<FlexView flexDirection='row' margin='auto 0 0 auto'>
							<Button
								backgroundColor='error'
								color='white'
								onClick={onOutsideClick}
								data-cy='button-cancel-generation'>
								{t('wms:Cancel')}
								<Icon name='cross-simple' color='white' width='14px' height='14px' margin='2px 0 0 8px' />
							</Button>
							<Button
								backgroundColor='success'
								color='white'
								margin='8px 0 0 8px'
								onClick={() => confirmGeneration()}
								data-cy='button-confirm-valid-generation'>
								{t('wms:Confirm')}
								<Icon name='check' color='white' width='14px' height='14px' margin='2px 0 0 8px' />
							</Button>
							<LoadingOverlay visible={savingChanges} borderRadius='card' />
						</FlexView>
					</FlexView>
				)}
			</Card>
		</Modal>
	)
}

export default ConfirmVALIDGenModal
