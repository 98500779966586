/** @format */

import { ContentContext } from 'apps/wms/stores/ContentStore'
import { Button, Card, FlexView, Icon, Modal } from 'components/common'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Select } from 'components/form'

const ContentFilterModal = ({ isOpen, onOutsideClick, options }) => {
	const { t } = useTranslation()
	const { tableData, filters, setFilters, setShowTable, setSearch } = useContext(ContentContext)
	const [state, setState] = useState({})

	useEffect(
		() =>
			setState(
				_.chain(options)
					.keyBy(({ accessor }) => accessor)
					.mapValues((value, key) => _.get(filters, key, []))
					.value()
				// eslint-disable-next-line react-hooks/exhaustive-deps
			),
		[options, filters]
	)

	const onInputChange = useCallback(
		accessor => value =>
			setState(currentState => ({
				...currentState,
				[accessor]: value
				// eslint-disable-next-line react-hooks/exhaustive-deps
			})),
		[]
	)

	const renderForm = useMemo(() => {
		return _.map(options, ({ accessor, label }) => {
			const availableOptions = _.chain(tableData)
				.map(item => {
					const value = _.get(item, accessor)
					return {
						value,
						label: value
					}
				})
				.orderBy('label', 'asc')
				.filter(({ label }) => typeof label === 'string' || typeof label === 'number')
				.uniqBy('value')
				.map(({ value, label }) => ({
					value,
					label: label
				}))
				.value()
			return (
				availableOptions.length > 0 && (
					<Select
						margin='8px 0px'
						width='100%'
						key={accessor}
						label={label}
						value={state[accessor]}
						options={availableOptions}
						onChange={onInputChange(accessor)}
						searchable
					/>
				)
			)
		})
	}, [options, state, tableData, onInputChange])

	const onFilterClick = () => {
		setFilters(state)
		setShowTable(true)
		setSearch(true)
		onOutsideClick()
	}

	const onClearFilters = () => {
		setFilters({})
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card padding='16px' maxWidth='80%' width='80%'>
				<FlexView
					fontSize='subtitle'
					fontWeight='bold'
					flexDirection='row'
					justifyContent='space-between'
					alignItems='center'
					alignSelf='stretch'>
					<span>{t('wms:FilterMaterial')}</span>
					<Icon name='cross' color='error' width='24px' height='24px' onClick={onOutsideClick} />
				</FlexView>
				<FlexView
					alignSelf='stretch'
					margin='8px 0px'
					maxHeight='70vh'
					width='95%'
					maxWidth='95%'
					style={{ overflow: 'auto', whiteSpace: 'break-spaces', padding: '8px' }}>
					{renderForm}
				</FlexView>
				<FlexView flexDirection='row' justifyContent='flex-end' alignItems='center' alignSelf='stretch'>
					<Button margin='8px' outline onClick={onClearFilters}>
						{t('wms:Clear')}
					</Button>
					<Button
						margin='8px 0px 8px'
						color='white'
						backgroundColor='secondary'
						height='39px'
						onClick={onFilterClick}>
						{t('wms:Apply')}
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default ContentFilterModal
