/** @format */

import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay } from 'components/common'
import Table from '../../components/content/Table'
import { REPORT_STATUS } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { isReceiptNoteReport } from '../reportEditor/businessRules'

const ReviewReportList = ({
	loadingList,
	list = [],
	reviewType,
	onSelectReport,
	onCheckReport,
	onDownloadReport,
	height,
	downloadingReport
}) => {
	const { t, i18n } = useTranslation()
	const [selectedItem, setSelectedItem] = useState(null)

	const handleSelect = id => {
		if (id != null) {
			setSelectedItem(list.find(item => item.id === id))
			onSelectReport(id)
		}
	}

	const handleDownloadReport = value => {
		value != null && onDownloadReport(value)
	}

	const handleCheckReport = id => {
		id != null && onCheckReport(id)
	}

	const data = list.map((value, index) => ({
		...value,
		props: {
			'data-cy': 'list-item-reports',
			onClick: () => {
				handleSelect(value.id)
			},
			className: selectedItem !== null && value.id === selectedItem.id ? 'selected' : ''
		}
	}))

	const ageColumns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:ThresholdDate'),
				accessor: 'extractiondate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Status'),
				accessor: 'status',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='16px'
						fontWeight='bold'
						color={REPORT_STATUS.color[value]}>
						{t(`${REPORT_STATUS.translateKey[value]}`)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Action'),
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<>
						{row.original.status === REPORT_STATUS.code.VALIDATED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='primary'
								disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
								elevation='none'
								onClick={() => {
									handleDownloadReport(row.original)
								}}
								data-cy='button-download-report'>
								{t('wms:DownloadReport')}
							</Button>
						) : null}
						{row.original.status === REPORT_STATUS.code.PENDING ||
						row.original.status === REPORT_STATUS.code.REJECTED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='secondary'
								onClick={() => {
									handleCheckReport(row.original)
								}}
								data-cy='button-check-report'>
								{t('wms:CheckReport')}
							</Button>
						) : null}
						<LoadingOverlay visible={downloadingReport} />
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, reviewType, t, selectedItem]
	)

	const millReceiptColumns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Name'),
				accessor: 'name',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:EntranceDate'),
				accessor: 'closuredate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Status'),
				accessor: 'status',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='16px'
						fontWeight='bold'
						color={REPORT_STATUS.color[value]}>
						{t(`${REPORT_STATUS.translateKey[value]}`)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Action'),
				width: 'auto',
				Cell: ({ cell: { value, row } }) => (
					<>
						{row.original.status === REPORT_STATUS.code.VALIDATED ? (
							<>
								<Button
									width='100%'
									fontSize='tiny'
									margin='4px 0'
									color='white'
									backgroundColor='primary'
									disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
									elevation='none'
									onClick={() => {
										handleDownloadReport(row.original)
									}}
									data-cy='button-download-report'>
									{t('wms:DownloadReport')}
								</Button>
								<LoadingOverlay visible={downloadingReport} />
							</>
						) : null}
						{row.original.status === REPORT_STATUS.code.PENDING ||
						row.original.status === REPORT_STATUS.code.REJECTED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='secondary'
								title={t('wms:AvailableSoon')}
								onClick={() => {
									handleCheckReport(row.original)
								}}
								data-cy='button-check-report'>
								{t('wms:CheckReport')}
							</Button>
						) : null}
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, reviewType, t, selectedItem]
	)

	const lists = () => {
		if (isReceiptNoteReport(reviewType)) return millReceiptColumns
		else return ageColumns
	}

	return (
		<Card data-cy='card-list-wrapper' width='calc(100% - 48px)' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingList} borderRadius='card' />
			{list && list.length ? (
				<Table
					margin='0'
					width='100%'
					textAlign='center'
					elevation='none'
					height={height}
					fontSize='14px'
					columns={lists()}
					data={data}
					sortable
					gridless
					data-cy={isReceiptNoteReport(reviewType) ? 'list-reports-mill' : 'list-reports-age'}
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='placeholder-reports-list'>
					{t('wms:NoReport')}
				</FlexView>
			)}
		</Card>
	)
}

export default ReviewReportList
