/** @format */

import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { FlexView, LoadingOverlay } from 'components/common'
import { Select, Input } from 'components/form'

import { UsageRightsContext } from '../../stores/UsageRightsStore'
import _ from 'lodash'
import { WMSContext } from 'apps/wms/stores/WmsStore'

const InfoInput = styled.div`
	> div div:last-child {
		font-size: 10px;
	}
`

const EndUserSourceFilter = props => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const {
		sourceType,
		levels,
		sourceOwnership,
		setSourceOwnership,
		sourceRack,
		setSourceRack,
		loadingRacks,
		valid,
		setValid,
		isValid,
		sourceEnduser,
		setSourceEnduser,
		endusers,
		loadingOrders,
		sourceCustomerOrder,
		setSourceCustomerOrder,
		sourceCustomerItem,
		setSourceCustomerItem,
		customerOrders,
		customerItems,
		sourceSalesOrder,
		setSourceSalesOrder,
		sourceSalesItem,
		setSourceSalesItem,
		salesOrders,
		salesItems,
		loadingOwnerships,
		getCustomerOrdersList,
		setCustomerOrders,
		getSalesOrdersList,
		setSalesOrders,
		setCustomerItems,
		setSalesItems,
		getEndUserByOrder,
		getRackList,
		setRackList,
		localEndUsers,
		setLocalEndUsers,
		setTotalSelectedPipes,
		sourceMaterialList,
		sourceMaterial,
		setSourceMaterial,
		loadingMaterials,
		getRackListByMaterial,
		setMaterialsByEndUser,
		materialsByEndUser
	} = useContext(UsageRightsContext)

	const [rackListByMaterial, setRackListByMaterial] = useState([])
	const [filteredMaterialList, setFilteredMaterialList] = useState([])

	useEffect(() => {
		if (sourceEnduser != null) {
			setSourceRack(null)
			getRackList()
				.then(rackList => {
					rackList && rackList.length && setRackList(rackList)
				})
				.catch(e => console.error(e))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceEnduser, warehouse])

	useEffect(() => {
		if (sourceEnduser != null && sourceType === 'RACK') {
			setSourceMaterial(null)
			let filtered = _.filter(sourceMaterialList, material => material.enduserid === sourceEnduser.id)
			filtered && filtered.length ? setFilteredMaterialList(filtered) : setFilteredMaterialList([])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceEnduser])

	useEffect(() => {
		setSourceCustomerOrder(null)
		sourceOwnership &&
			sourceType === 'CUSTOMER_ITEM' &&
			getCustomerOrdersList()
				.then(orders => orders && orders.length && setCustomerOrders(orders))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceOwnership, warehouse])

	useEffect(() => {
		setSourceSalesOrder(null)
		sourceOwnership &&
			sourceType === 'SALES_ITEM' &&
			getSalesOrdersList()
				.then(sales => sales && sales.length && setSalesOrders(sales))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceOwnership, warehouse])

	useEffect(() => {
		setSourceCustomerItem(null)
		sourceCustomerOrder
			? setCustomerItems(customerOrders.find(order => order.value === sourceCustomerOrder).items)
			: setCustomerItems([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceCustomerOrder])

	useEffect(() => {
		setSourceSalesItem(null)
		sourceSalesOrder
			? setSalesItems(salesOrders.find(sale => sale.value === sourceSalesOrder).items)
			: setSalesItems([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceSalesOrder])

	useEffect(() => {
		setSourceEnduser(null)
		;(sourceCustomerItem || sourceSalesItem) &&
			getEndUserByOrder()
				.then(endUsers => endUsers && setLocalEndUsers(endUsers))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceCustomerItem, sourceSalesItem])

	useEffect(() => {
		setSourceEnduser(null)
		setSourceOwnership(null)
		setSourceRack(null)
		setSourceCustomerOrder(null)
		setSourceCustomerItem(null)
		setSourceSalesOrder(null)
		setSourceSalesItem(null)
		setSourceMaterial(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setSourceMaterial(null)
		setRackListByMaterial([])
		setMaterialsByEndUser([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceEnduser])

	useEffect(() => {
		sourceMaterial &&
			getRackListByMaterial(sourceMaterial)
				.then(list => {
					setRackListByMaterial(list)
				})
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceMaterial])

	const inputMessage = () => {
		if (isValid === null) return t(`wms:ValidHelp`)
		else if (isValid) return t(`wms:ValidValidated`)
		else return t(`wms:InvalidValid`)
	}

	const inputMessageType = () => {
		if (isValid === null) return 'info'
		else if (isValid) return 'success'
		else return 'error'
	}

	return (
		<>
			{sourceType && sourceType === 'RACK' && (
				<>
					<FlexView width='100%' fontSize='small' fontWeight='bold' margin='0 0 8px 0'>
						{t('wms:SourceEndUser')}
					</FlexView>

					<Select
						placeholder={t(`wms:EndUser`)}
						value={sourceEnduser && sourceEnduser.id}
						options={endusers.map(({ id, name }) => ({
							value: id,
							label: name
						}))}
						onChange={value => {
							setSourceEnduser(endusers.find(eu => eu.id === value))
							setTotalSelectedPipes(0)
							setSourceMaterial(null)
						}}
						width='100%'
						inline={false}
						margin='0 0 8px 0'
						searchable
						data-cy='select-source-enduser'
					/>
					<FlexView style={{ position: 'relative' }}>
						<LoadingOverlay visible={loadingMaterials} borderRadius='card' />
						<FlexView width='100%' fontSize='small' fontWeight='bold' margin='0 0 8px 0'>
							{t('wms:SourceMaterial')}
						</FlexView>
						<Select
							placeholder={t('wms:SourceMaterial')}
							value={sourceMaterial}
							options={
								filteredMaterialList &&
								filteredMaterialList.map(value => ({
									label: value.formatted,
									value: value.mvid
								}))
							}
							onChange={value => {
								setSourceMaterial(value)
							}}
							width='calc(300px - 48px)'
							inline={false}
							margin='0'
							data-cy='select-source-material'
							searchable={true}
							disabled={sourceEnduser === null}
						/>
					</FlexView>
					<FlexView width='100%' fontSize='small' fontWeight='bold' margin='8px 0'>
						{t('wms:SourceRack')}
					</FlexView>
					<FlexView width='100%' margin='0' padding='0' position='relative'>
						<LoadingOverlay visible={loadingRacks} />
						<Select
							searchable
							placeholder={t('wms:SelectRack')}
							value={sourceRack}
							options={rackListByMaterial.map(rack => ({
								label: rack.fullname,
								value: rack.id
							}))}
							onChange={value => {
								setSourceRack(value)
								setTotalSelectedPipes(0)
							}}
							width='100%'
							margin='0'
							data-cy='select-source-rack'
							disabled={sourceMaterial === null}
						/>
					</FlexView>
				</>
			)}
			{sourceType && (sourceType === 'CUSTOMER_ITEM' || sourceType === 'SALES_ITEM') && (
				<>
					<Select
						searchable
						placeholder={t('wms:Ownership')}
						label={t('wms:Ownership')}
						options={_.map(levels, lv => {
							return { value: lv.id, label: lv.label }
						})}
						value={sourceOwnership}
						onChange={v => {
							setSourceOwnership(v)
							setTotalSelectedPipes(0)
						}}
						disabled={!levels || levels.length <= 0}
						width='100%'
						margin='0 0 8px 0'
						data-cy='select-source-level'
					/>

					{sourceType === 'CUSTOMER_ITEM' && (
						<FlexView width='100%' margin='0' padding='0' position='relative'>
							<LoadingOverlay visible={loadingOrders} />
							<Select
								disabled={!customerOrders.length}
								label={t(`wms:CustomerOrder`)}
								placeholder={t(`wms:CustomerOrder`)}
								value={sourceCustomerOrder}
								options={customerOrders.map(value => ({
									label: value.value,
									value: value.value
								}))}
								onChange={value => {
									setSourceCustomerOrder(value)
									setTotalSelectedPipes(0)
								}}
								width='100%'
								inline={false}
								margin='0'
								searchable
								data-cy='select-customer-order'
							/>
							<Select
								disabled={sourceCustomerOrder === null}
								label={t(`wms:CustomerItem`)}
								placeholder={t(`wms:CustomerItem`)}
								value={sourceCustomerItem}
								options={customerItems.map(value => ({
									label: value,
									value: value
								}))}
								onChange={value => {
									setSourceCustomerItem(value)
									setTotalSelectedPipes(0)
								}}
								width='100%'
								inline={false}
								margin='0'
								searchable
								data-cy='select-customer-item'
							/>
						</FlexView>
					)}
					{sourceType === 'SALES_ITEM' && (
						<FlexView width='100%' margin='0' padding='0' position='relative'>
							<LoadingOverlay visible={loadingOrders} />
							<Select
								disabled={!salesOrders.length}
								label={t(`wms:SalesOrder`)}
								placeholder={t(`wms:SalesOrder`)}
								value={sourceSalesOrder}
								options={salesOrders.map(value => ({
									label: value.value,
									value: value.value
								}))}
								onChange={value => {
									setSourceSalesOrder(value)
									setTotalSelectedPipes(0)
								}}
								width='100%'
								inline={false}
								margin='0'
								data-cy='select-sales-order'
							/>
							<Select
								disabled={sourceSalesOrder === null}
								label={t(`wms:SalesItem`)}
								placeholder={t(`wms:SalesItem`)}
								value={sourceSalesItem}
								options={salesItems.map(value => ({
									label: value,
									value: value
								}))}
								onChange={value => {
									setSourceSalesItem(value)
									setTotalSelectedPipes(0)
								}}
								width='100%'
								inline={false}
								margin='0'
								data-cy='select-sales-item'
							/>
						</FlexView>
					)}
					<FlexView width='100%' margin='0' padding='0' position='relative'>
						<LoadingOverlay visible={loadingOwnerships} />
						<Select
							label={t(`wms:EndUser`)}
							placeholder={t(`wms:EndUser`)}
							value={sourceEnduser && sourceEnduser.id}
							options={localEndUsers.map(({ id, name }) => ({
								value: id,
								label: name
							}))}
							onChange={value => {
								setSourceEnduser(localEndUsers.find(eu => eu.id === value))
								setTotalSelectedPipes(0)
							}}
							width='100%'
							inline={false}
							margin='0'
							data-cy='select-source-enduser'
						/>
					</FlexView>
					<FlexView width='100%' margin='0' padding='0' position='relative'>
						<LoadingOverlay visible={loadingMaterials} borderRadius='card' />
						<Select
							label={t('wms:SourceMaterial')}
							placeholder={t('wms:SourceMaterial')}
							value={sourceMaterial}
							options={
								materialsByEndUser &&
								materialsByEndUser.map(value => ({
									label: value.desc,
									value: value.id
								}))
							}
							onChange={value => {
								setSourceMaterial(value)
							}}
							width='100%'
							inline={false}
							margin='0'
							data-cy='select-source-material'
							searchable={true}
							disabled={sourceEnduser === null}
						/>
					</FlexView>
				</>
			)}
			{sourceType && sourceType === 'VALID' && (
				<InfoInput>
					<Input
						label='VALID'
						message={inputMessage()}
						messageType={inputMessageType()}
						placeholder={t(`wms:TypeValid`)}
						margin='0'
						minlength='11'
						maxlength='11'
						value={valid}
						onChange={e => {
							setValid(e.target.value)
							setTotalSelectedPipes(0)
						}}
						success={isValid}
						error={!isValid && isValid !== null}
						data-cy='input-valid'
					/>
				</InfoInput>
			)}
		</>
	)
}

export default EndUserSourceFilter
