/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, LoadingOverlay, Icon } from 'components/common'
import Button from 'apps/wms/components/commom/Button'
import DialogModal from 'apps/wms/components/forms/DialogModal'

import { DispatchContext } from '../../stores/DispatchStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'

import { useWindowSize } from 'apps/wms/utils/hooks'

const DispatchList = () => {
	const { t, i18n } = useTranslation()
	const {
		dispatchList,
		loadingDispatchList,
		selectedDispatch,
		setSelectedDispatch,
		deleteDispatch,
		setUpdateDispatchList,
		setNewDispatchModal,
		setDispatchModal
	} = useContext(DispatchContext)
	const [deleteWarning, setDeleteWarning] = useState(false)
	const [deleteID, setDeleteID] = useState(null)

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedDispatch && selectedDispatch.id && handleSelect(selectedDispatch.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatchList])

	const handleSelect = id => {
		let dispatch = dispatchList.find(item => item.id === id)
		id && setSelectedDispatch(dispatch !== undefined ? dispatch : null)
	}

	const handleDelete = id => {
		if (id !== null) {
			handleSelect(id)
			setDeleteWarning(true)
			setDeleteID(id)
		}
	}

	const removeDispatch = () => {
		setDeleteWarning(false)
		deleteID &&
			deleteDispatch(deleteID)
				.then(response => {
					setUpdateDispatchList(true)
					setSelectedDispatch(null)
				})
				.catch(e => console.error(e))
				.finally(() => {})
	}

	const handleNewDispatch = () => {
		setNewDispatchModal(true)
	}

	const handleDispatch = () => {
		setDispatchModal(true)
	}

	return (
		<Card width='calc(100% - 48px)' flex='1' ref={listRef} margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingDispatchList} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:DispatchList')}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{dispatchList && dispatchList.length ? (
					dispatchList.map((item, index) => (
						<FlexView
							key={item.id}
							backgroundColor={selectedDispatch && selectedDispatch.id === item.id ? 'offWhite' : 'default'}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelect(item.id)}>
							<FlexView flex='1' fontSize='small' margin='auto 0'>
								{`${item.id} (${DATE_FORMAT(item.expecteddate, i18n.language)})`}
							</FlexView>
							<Button
								fontSize='tiny'
								margin='0 0 0 8px'
								hoverBackgroundColor='secondary'
								hoverColor='white'
								backgroundColor='white'
								color='secondary'
								onClick={() => {
									handleDispatch(item.id)
								}}>
								<Icon name='play' color='secondary' height='12px' width='12px' margin='0 8px 0 0' />
								{t('wms:ExecuteDispatch')}
							</Button>
							<Button
								fontSize='tiny'
								margin='0 0 0 8px'
								color='white'
								backgroundColor='error'
								onClick={() => {
									handleDelete(item.id)
								}}>
								<Icon name='trash' color='white' height='12px' width='12px' margin='0 8px 0 0' />
								{t('wms:Delete')}
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'>
						{t('wms:NoDispatch')}
					</FlexView>
				)}
			</FlexView>
			<Button
				fontSize='small'
				margin='auto 0 0 auto'
				color='white'
				backgroundColor='secondary'
				disabled={false}
				onClick={handleNewDispatch}>
				{t('wms:Add')}
			</Button>
			<DialogModal
				isOpen={deleteWarning}
				text={t(`wms:ConfirmDispatchListDeletion`)}
				onConfirm={removeDispatch}
				onCancel={() => setDeleteWarning(false)}
			/>
		</Card>
	)
}

export default DispatchList
