/** @format */

import { InventoryContext } from 'apps/wms/stores/InventoryStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { listStatusTranslateKey } from '../../utils/enum'
import _ from 'lodash'
import { useWindowSize } from 'apps/wms/utils/hooks'
import Table from '../../components/inventory/Table'
import { RadioGroup } from 'components/form'

const GhostModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const {
		items,
		loadingGhosts,
		setOpenPrint,
		openPrint,
		loadingPrinters,
		printers,
		printerSelected,
		setPrinterSelected,
		putPrinters,
		setItemSelected,
		exportGhost
	} = useContext(InventoryContext)
	const [printing, setPrinting] = useState(false)
	const [extracting, setExtracting] = useState(false)

	const listDeadSpace = 115
	const windowSize = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		if (listRef.current !== null) setListHeight(listRef.current.offsetHeight - listDeadSpace)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listRef.current, windowSize])

	const handlePrint = useCallback(
		original => e => {
			e.stopPropagation()
			setItemSelected(original)
			setOpenPrint(true)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const exportToExcel = () => {
		setExtracting(true)
		exportGhost()
			.then(() => {})
			.catch(e => console.error(e))
			.finally(() => {
				setExtracting(false)
			})
	}

	const handleChange = e => {
		setPrinterSelected(e)
	}

	const closeModal = async () => {
		try {
			setPrinting(true)
			await putPrinters()
		} catch (e) {
			console.error(e)
			setOpenPrint(false)
			setPrinting(false)
		} finally {
			setOpenPrint(false)
			setPrinting(false)
		}
	}

	const closePrinters = () => {
		setOpenPrint(false)
	}

	const data = useMemo(
		() =>
			_.chain(items)
				.groupBy('fullname')
				.map((value, key) => ({
					fullname: key,
					valid: value[0].valid,
					puuid: value[0].puuid,
					formatted: value.length > 1 ? `${value.length} ${t('wms:Materials')}` : value[0].formatted,
					status: value[0].status,
					children: value || [],
					subRows: value.length > 1 ? value : []
				}))
				.value(),
		[items, t]
	)

	const columns = useMemo(
		() => [
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Print')}
					</FlexView>
				),
				accessor: 'print',
				width: 'auto',
				Cell: ({ cell: { row } }) => {
					return (
						<FlexView alignItems='center' width='30%' justifyContent='center' margin='0 auto'>
							<Icon
								name='print'
								color='secondary'
								fontWeight='bold'
								width='16px'
								height='16px'
								onClick={handlePrint(row.original)}
							/>
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Rack')}
					</FlexView>
				),
				accessor: 'fullname',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							alignItems='center'
							flexDirection='row'
							fontWeight='bold'
							color='metalic'
							justifyContent='center'
							{...row.getExpandedToggleProps({
								style: {
									paddingLeft: `${row.depth * 32}px`,
									cursor: row.canExpand ? 'pointer' : 'inherit'
								},
								title: ''
							})}>
							{value}
							{row.canExpand && (
								<Icon
									name={row.isExpanded ? 'chevron-up' : 'chevron-down'}
									width='12px'
									height='12px'
									margin='0 4px 0 8px'
								/>
							)}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						Valid
					</FlexView>
				),
				accessor: 'valid',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							alignItems='center'
							fontWeight='bold'
							color='metalic'
							margin='0'
							justifyContent='center'
							width='100%'
							style={{ alignSelf: 'center', textAlign: 'center' }}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Puuid')}
					</FlexView>
				),
				accessor: 'puuid',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							alignItems='center'
							fontWeight='bold'
							color='metalic'
							margin='0px'
							justifyContent='center'
							style={{ alignSelf: 'center' }}
							width='100%'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Material')}
					</FlexView>
				),
				accessor: 'formatted',
				width: '350px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							alignItems='center'
							fontWeight='bold'
							color='metalic'
							margin='0px'
							minWidth='100%'
							justifyContent='center'
							style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						Status
					</FlexView>
				),
				accessor: 'status',
				width: '80px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							alignItems='center'
							fontWeight='bold'
							color='metalic'
							margin='0px'
							style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
							{t(`${listStatusTranslateKey(value)}`)}
						</FlexView>
					)
				}
			}
		],
		[t, handlePrint]
	)
	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			{openPrint ? (
				<Card
					height='fit-content'
					style={{ alignSelf: 'center', justifyContent: 'space-between' }}
					data-cy='card-print-modal'>
					<LoadingOverlay visible={loadingPrinters} borderRadius='card' />
					<FlexView
						width='-webkit-fill-available'
						flexDirection='row'
						justifyContent='space-between'
						backgroundColor='info'
						fontSize='title'
						fontWeight='bold'
						color='white'
						margin='-16px -24px 8px'
						padding='8px'
						borderRadius='8px 8px 0 0'>
						{t('wms:SelectPrinter')}
					</FlexView>
					<RadioGroup
						name='printers'
						options={_.map(printers, p => ({ ...p, value: p.value, label: p.label }))}
						value={printerSelected}
						onChange={evt => handleChange(evt)}
						data-cy='radiogroup-printers'
					/>
					<FlexView width='100%' flexDirection='row' alignItems='center' justifyContent='center'>
						<Button
							backgroundColor='error'
							color='white'
							margin='0 8px 0 0'
							onClick={closePrinters}
							data-cy='button-cancel-print'>
							{t('wms:Cancel')}
						</Button>
						<Button
							disabled={printerSelected === null}
							backgroundColor='success'
							color='white'
							margin='0'
							onClick={closeModal}
							isLoading={printing}
							data-cy='button-print'>
							{t('wms:OK')}
						</Button>
					</FlexView>
				</Card>
			) : (
				<Card
					width='70%'
					height='80vh'
					ref={listRef}
					heightstyle={{ alignSelf: 'normal' }}
					data-cy='card-ghost-modal'>
					<LoadingOverlay visible={loadingGhosts} borderRadius='card' />
					<FlexView
						width='-webkit-fill-available'
						flexDirection='row'
						justifyContent='space-between'
						backgroundColor='info'
						fontSize='title'
						fontWeight='bold'
						color='white'
						margin='-16px -24px 8px'
						padding='8px 16px'
						borderRadius='8px 8px 0 0'>
						{t('wms:InventoryGhosts')}
						<Icon
							name='download'
							width='24px'
							height='24px'
							margin='8px'
							color='white'
							fontWeight='bold'
							onClick={() => exportToExcel()}
							tooltip={t('wms:ExportExcel')}
							tooltipPosition='top'
						/>
						<LoadingOverlay visible={extracting} borderRadius='card' />
					</FlexView>
					{data && (
						<Table
							margin='8px'
							width='calc(100% - 8px)'
							maxHeight={`${listHeight}px`}
							style={{ backgroundColor: 'white', elevation: 'low', alignSelf: 'center' }}
							columns={columns}
							data={data}
							paginated
							sortable
							data-cy='table-ghosts'></Table>
					)}
				</Card>
			)}
		</Modal>
	)
}

export default GhostModal
