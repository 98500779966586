/** @format */

import { GROUPING_BUTTONS } from 'apps/wms/utils/enum'
import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import _ from 'lodash'
import React, { useCallback, useContext, useState } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '../../components/content/Table'
import Button from 'apps/wms/components/commom/Button'
import ContentFIlterDisplay from './ContentFilterDisplay'
import ContentFilterModal from './ContentFilterModal'
import LevelContentModal from './LevelContentModal'
import { ContentContext } from 'apps/wms/stores/ContentStore'

const ContentTable = ({
	height,
	foundMaterial,
	setLevelId,
	setMid,
	pageLoading,
	attribute,
	getLevelContents,
	items
}) => {
	const { lastOwnership } = useContext(ContentContext)
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [groupingButton, setGroupingButton] = useState(1)
	const [rack, setRack] = useState(null)
	const [showModal, setShowModal] = useState(false)

	const toggleModal = () => setShowModal(currentState => !currentState)

	const handleClick = useCallback(
		original => e => {
			e.stopPropagation()
			let mvid = []
			if (groupingButton === GROUPING_BUTTONS.code.GROUP_BY_MATERIAL) {
				mvid.push(original.id)
			} else {
				_.forEach(original.children, materialIds => {
					mvid.push(materialIds.id)
				})
			}
			setMid(mvid)
			setLevelId(original.rackId)
			setRack({ id: original.rackId, fullname: original.fullname })
			getLevelContents(original.rackId, mvid, lastOwnership)
			setIsOpen(true)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[groupingButton, lastOwnership]
	)

	const rackData = useMemo(
		() =>
			_.chain(foundMaterial)
				.groupBy('fullname')
				.map((value, key) => ({
					rackId: value[0].rackId,
					fullname: key,
					formatted: value.length > 1 ? `${value.length} ${t('wms:Materials')}` : value[0].formatted,
					quantity: value.length > 1 ? _.sumBy(value, ({ quantity }) => Number(quantity)) : value[0].quantity,
					total: _.sumBy(value, ({ quantity }) => Number(quantity)),
					children: value || [],
					subRows: value.length > 1 ? value : []
				}))
				.value(),
		[foundMaterial, t]
	)

	const materialData = useMemo(
		() =>
			_.chain(foundMaterial)
				.groupBy('formatted')
				.map((value, key) => ({
					rackId: value.map(id => id.rackId),
					fullname: value.length > 1 ? `${value.length} ${t('wms:Rack')}s` : value[0].fullname,
					formatted: key,
					quantity: value.length > 1 ? _.sumBy(value, ({ quantity }) => Number(quantity)) : value[0].quantity,
					total: _.sumBy(value, ({ quantity }) => Number(quantity)),
					children: value || [],
					subRows: value.length > 1 ? value : []
				}))
				.value(),
		[foundMaterial, t]
	)

	const rackColumns = useMemo(
		() => [
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Rack')}
					</FlexView>
				),
				accessor: 'fullname',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='space-between'
							{...row.getExpandedToggleProps({
								style: {
									paddingLeft: `${row.depth * 32}px`,
									cursor: row.canExpand ? 'pointer' : 'inherit'
								},
								title: ''
							})}>
							{value}
							{row.canExpand && (
								<Button
									fontSize='12px'
									width='50px'
									height='30px'
									padding='4px 8px'
									margin='0px 8px 0px auto'
									backgroundColor='secondary'
									color='white'
									onClick={handleClick(row.original)}>
									{t('wms:View')}
								</Button>
							)}
							{row.canExpand && (
								<Icon
									name={row.isExpanded ? 'chevron-up' : 'chevron-down'}
									width='12px'
									height='12px'
									margin='0px 4px 0px 8px'
								/>
							)}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Material')}
					</FlexView>
				),
				accessor: 'formatted',
				width: '400px',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Quantity')}
					</FlexView>
				),
				accessor: 'quantity',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0px'
						style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Total')}
					</FlexView>
				),
				accessor: 'total',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0px'
						style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
						{value}
					</FlexView>
				)
			}
		],
		[handleClick, t]
	)

	const materialColumns = useMemo(
		() => [
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Material')}
					</FlexView>
				),
				accessor: 'formatted',
				width: '320px',
				Cell: ({ cell: { value } }) => (
					<FlexView
						flexDirection='row'
						alignItems='center'
						justifyContent='space-between'
						margin='0'
						padding='0'
						style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Rack')}
					</FlexView>
				),
				accessor: 'fullname',
				width: '50px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							margin='0'
							padding='0'
							style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
							{...row.getExpandedToggleProps({
								style: {
									paddingLeft: `${row.depth * 32}px`,
									cursor: row.canExpand ? 'pointer' : 'inherit'
								},
								title: ''
							})}>
							{value}
							{!row.canExpand && (
								<Button
									fontSize='12px'
									width='50px'
									height='30px'
									padding='4px 8px'
									margin='0px 8px 0px auto'
									backgroundColor='secondary'
									color='white'
									onClick={handleClick(row.original)}>
									{t('wms:View')}
								</Button>
							)}
							{row.canExpand && (
								<Icon
									name={row.isExpanded ? 'chevron-up' : 'chevron-down'}
									width='12px'
									height='12px'
									margin='0px 4px 0px 8px'
								/>
							)}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Quantity')}
					</FlexView>
				),
				accessor: 'quantity',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0px'
						style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Total')}
					</FlexView>
				),
				accessor: 'total',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0px'
						style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
						{value}
					</FlexView>
				)
			}
		],
		[handleClick, t]
	)

	return (
		<Card width='calc(100% - 32px)' flex='1' margin='0' style={{ position: 'relative' }}>
			<ContentFIlterDisplay options={attribute} onTagClick={toggleModal} />
			<FlexView width='100%' flexDirection='row' justifyContent='flex-end' alignItems='flex-end' data-cy='flex-view'>
				<Button
					fontSize='small'
					margin='0 0 16px 0'
					color='secondary'
					backgroundColor='white'
					hoverColor='darkGray'
					onClick={() => setGroupingButton(GROUPING_BUTTONS.code.GROUP_BY_RACK)}>
					{t('wms:GroupByRack')}
				</Button>
				<Button
					fontSize='small'
					margin='0 0 16px 8px'
					color='secondary'
					backgroundColor='white'
					hoverColor='darkGray'
					onClick={() => setGroupingButton(GROUPING_BUTTONS.code.GROUP_BY_MATERIAL)}>
					{t('wms:GroupByMaterial')}
				</Button>
				<Button
					fontSize='small'
					margin='0 0 16px 8px'
					color='secondary'
					backgroundColor='white'
					hoverColor='darkGray'
					onClick={toggleModal}>
					<Icon name='filter' width='12px' height='12px' margin='0 8px 0 0' />
					{t('wms:TableFilter')}
				</Button>
			</FlexView>
			<Table
				margin='0'
				width='100%'
				maxWidth='100%'
				height={height}
				elevation='none'
				columns={groupingButton === GROUPING_BUTTONS.code.GROUP_BY_RACK ? rackColumns : materialColumns}
				data={groupingButton === GROUPING_BUTTONS.code.GROUP_BY_RACK ? rackData : materialData}
				paginated
				sortable
				data-cy='table-content-materials'
			/>
			<LoadingOverlay visible={pageLoading} borderRadius='card' />
			<ContentFilterModal isOpen={showModal} onOutsideClick={toggleModal} options={attribute} />
			<LevelContentModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				onOutsideClick={() => setIsOpen(false)}
				rack={rack}
				items={items}
				loadingItems={pageLoading}
			/>
		</Card>
	)
}

export default ContentTable
