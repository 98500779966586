/** @format */

import { AssetsReportRefContext } from 'apps/wms/stores/AssetsReportRefStore'
import { reportTypeTranslateKey } from 'apps/wms/utils/enum'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { FlexView } from 'components/common'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import Icon from 'components/common/Icon'
import LoadingOverlay from 'components/common/LoadingOverlay'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import UpdateRefModal from './UpdateRefModal'

const ReportsList = () => {
	const { t } = useTranslation()
	const {
		loadingReports,
		reports,
		selectedReport,
		setSelectedReport,
		openUpdateModal,
		setOpenUpdateModal,
		setReference
	} = useContext(AssetsReportRefContext)

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedReport && handleSelect(selectedReport)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport])

	const handleSelect = report => {
		report && setSelectedReport(report)
		console.log(report)
	}

	const handleUpdate = type => {
		if (type !== null) {
			handleSelect(type)
			setOpenUpdateModal(true)
		}
	}

	const closeModal = () => {
		setOpenUpdateModal(false)
		setReference('')
	}

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-reports-list'>
			<LoadingOverlay visible={loadingReports} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:Reports')}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{reports && reports.length > 0 ? (
					_.map(reports, (rep, index) => (
						<FlexView
							key={rep.reporttype}
							backgroundColor={
								selectedReport && selectedReport.reporttype === rep.reporttype ? 'offwhite' : 'default'
							}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelect(rep)}
							data-cy='list-report'>
							<FlexView flex='1' fontSize='small' margin='auto 0' style={{ cursor: 'pointer' }}>
								{t(`${reportTypeTranslateKey(rep.reporttype)}`)}
							</FlexView>
							<Button
								backgroundColor='secondary'
								color='white'
								fontSize='tiny'
								margin='auto 8px auto 0'
								onClick={() => handleUpdate(rep.reporttype)}
								data-cy='button-update-report'>
								{t('wms:Update')}
								<Icon
									name='refresh'
									width='16px'
									height='16px'
									color='white'
									margin='0 0 0 8px'
									fontWeight='bold'
								/>
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='no-reports-list'>
						{t('wms:NoReports')}
					</FlexView>
				)}
			</FlexView>
			<UpdateRefModal isOpen={openUpdateModal} onOutsideClick={closeModal} />
		</Card>
	)
}

export default ReportsList
