/** @format */

import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, FlexView, Icon } from 'components/common'
import { Select } from 'components/form'
import { WMSContext } from '../stores/WmsStore'
import NavGrid from '../containers/home/NavGrid'
import { useHistory } from 'react-router'
import { genParams, assets } from '../utils/hubs'
import { UserContext } from 'stores/UserStore'
import { isMobileOnly, isAndroid } from 'react-device-detect'
import AppDownload from '../containers/home/AppDownload'

const Header = styled(FlexView)`
	margin: 0 0 24px;
	font-size: 32px;
	transform: scale(0.9);
	transition: all 0.5s ease-in-out;
	color: ${({ theme }) => theme.colors.gray};

	&.initial-state {
		margin: 15% 0;
		font-size: 40px;
		transform: scale(1);
	}

	& > span {
		max-height: 0px;
		transition: all 0.5s ease-in-out;
		opacity: 0;

		&.initial-state {
			opacity: 1;
			max-height: 100px;
			margin-bottom: 32px;
		}
	}

	& > Select {
		margin-top: 0px;
		transition: all 0.5s ease-out;

		&.initial-state {
			height: auto;
			margin-top: 32px;
		}
	}
`

const HomePage = () => {
	const { warehouse, setWarehouse, getLevelsList } = useContext(WMSContext)
	const { user } = useContext(UserContext)
	const { t } = useTranslation()
	const [openDownloadModal, setOpenDownloadModal] = useState(() => {
		return isMobileOnly && isAndroid
	})
	const [warehouseList, setWarehouseList] = useState([])
	const [warehouseID, setWarehouseID] = useState(() => {
		if (warehouse && warehouse.id) return warehouse.id
		else return null
	})

	const history = useHistory()
	const redirectTo = path => {
		history.push(path)
	}

	useEffect(() => {
		getLevelsList()
			.then(list => list && setWarehouseList(list))
			.catch(e => {
				console.error(e)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const closeDownloadModal = useCallback(() => {
		setOpenDownloadModal(false)
	}, [setOpenDownloadModal])

	const selectWarehouse = useCallback(
		warehouseID => {
			if (warehouseID !== null && warehouseID !== undefined) {
				setWarehouseID(warehouseID)
				setWarehouse(warehouseList.find(warehouse => warehouse.id === warehouseID))
			}
		},
		[setWarehouseID, setWarehouse, warehouseList]
	)

	const isAdmin = useMemo(() => !!user.roles.find(r => r === 'wmsadmin' || r === 'administrators'), [user])

	return (
		<FlexView
			width='100%'
			maxWidth='100%'
			flex='1'
			alignItems='center'
			margin='0'
			padding='0'
			backgroundColor='#F8F8F9'
			position='relative'>
			<FlexView width='100%' maxWidth='calc(100% - 160px)' flex='1' padding='24px 0' position='relative'>
				<FlexView
					flexDirection='row'
					padding='0'
					margin='0'
					position='absolute'
					style={{ right: '0', zIndex: '2' }}>
					<Button
						title={t('wms:FieldAppDownload')}
						margin='24px 16px'
						padding='0'
						backgroundColor='background'
						onClick={() => setOpenDownloadModal(true)}
						elevation='none'>
						<Icon name='download' width='32px' height='32px' margin='0px' />
					</Button>
					{isAdmin ? (
						<Button
							title={t('wms:WarehouseSettings')}
							margin='24px 16px'
							padding='0'
							backgroundColor='background'
							onClick={() => redirectTo(`/wms/assets${genParams(assets)}`)}
							elevation='none'>
							<Icon name='settings' width='32px' height='32px' margin='0px' />
						</Button>
					) : null}
				</FlexView>
				<Header
					width='100%'
					padding='0'
					justifyContent='space-between'
					alignItems='center'
					className={warehouseID === null ? 'initial-state' : null}>
					<span className={warehouseID === null ? 'initial-state' : null}>{t('wms:SelectTheWarehouse')}</span>
					<Select
						data-cy='select-warehouse'
						fontSize='24px'
						placeholder={t('wms:Warehouse')}
						value={warehouseID}
						options={warehouseList.map(warehouse => ({
							value: warehouse.id,
							label: `${warehouse.country} - ${warehouse.plant} - ${warehouse.name}`
						}))}
						onChange={value => {
							selectWarehouse(value)
						}}
						inline={false}
						width='60%'
					/>
				</Header>
				{warehouseID !== null ? <NavGrid data-cy='grid-navigation' /> : null}
			</FlexView>
			<AppDownload isOpen={openDownloadModal} onOutsideClick={closeDownloadModal} />
		</FlexView>
	)
}

export default HomePage
