/** @format */

import _ from 'lodash'

export const bundleOrItems = (warehouse, bundles) => {
	if (warehouse && bundles && bundles.length) {
		let items = undefined
		if (displayBundles(warehouse)) {
			items = bundles
		} else {
			items = []
			_.forEach(bundles, (b, i) => {
				let bundle = b
				let bItems = bundle.items
				if (bundle.hasOwnProperty('material')) {
					let aux = []
					_.forEach(bItems, (it, d) => {
						let item = Object.assign({}, it)
						item.material = bundle.material
						aux.push(item)
					})
					bItems = aux
				}
				if (bundle.hasOwnProperty('level')) {
					let aux = []
					_.forEach(bItems, (it, d) => {
						let item = Object.assign({}, it)
						item.level = bundle.level
						aux.push(item)
					})
					bItems = aux
				}
				if (bundle.hasOwnProperty('ownershipid')) {
					let aux = []
					_.forEach(bItems, (it, d) => {
						let item = Object.assign({}, it)
						item.ownershipid = bundle.ownershipid
						aux.push(item)
					})
					bItems = aux
				}
				items = _.concat(items, bItems)
			})
		}
		return items
	}
	return undefined
}

export const displayBundles = warehouse => {
	return warehouse.displayitem === 0
}

export const displayValids = warehouse => {
	return warehouse.displayitem === 1
}

export const displayPuuids = warehouse => {
	return warehouse.displayitem === 2
}

export const levelDesc = warehouse => {
	if (warehouse && warehouse.levels && warehouse.levels.length)
		return _.map(warehouse.levels, l => {
			return l.desc
		})
	return undefined
}
