/** @format */

import { AssetsUsageRightsContext } from 'apps/wms/stores/AssetsUsageRightsStore'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Input, Select } from 'components/form'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const EndUserSettings = () => {
	const { t } = useTranslation()
	const {
		localEndUsers,
		globalEndUsers,
		loadingGlobalEndUsers,
		newEndUser,
		setNewEndUser,
		importEndUserValue,
		addEndUserValue,
		deleteEndUser
	} = useContext(AssetsUsageRightsContext)

	const importEndUser = () => {
		importEndUserValue()
	}

	const addEndUser = () => {
		let payload = {
			globalid: -1,
			name: newEndUser.name,
			description: newEndUser.description
		}
		addEndUserValue(payload).catch(() => {})
	}

	const deleteEndUserValue = endUserId => {
		deleteEndUser(endUserId)
	}

	return (
		<FlexView width='100%' height='100%' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingGlobalEndUsers} borderRadius='card' />
			<FlexView width='100%' flexDirection='row' alignItems='stretch' margin='0'>
				<Card width='100%' height='100%' data-cy='card-end-user-settings'>
					<FlexView width='100%' fontSize='big' fontWeight='bold' margin='0 0 16px 0'>
						{t('wms:EndUsers')}
					</FlexView>
					<FlexView width='100%' alignItems='stretch' flexDirection='row' justifyContent='space-between'>
						<Card width='calc(50% - 16px)' padding='16px' margin='0 16px 0 0' maxHeight='95%'>
							<FlexView width='100%' flexDirection='row' alignItems='center' margin='0 0 24px 0'>
								<Select
									width='100%'
									placeholder={t('wms:EndUser')}
									value={newEndUser.value}
									options={
										globalEndUsers &&
										_.map(globalEndUsers, global => ({ value: global.id, label: global.name }))
									}
									onChange={v => setNewEndUser({ ...newEndUser, value: v })}
									margin='0 16px 0 0'
									searchable
									data-cy='select-end-user'></Select>
								<Button
									disabled={!newEndUser.value}
									backgroundColor='secondary'
									color='white'
									onClick={() => importEndUser()}
									data-cy='button-import-enduser'>
									{t('wms:ImportEndUser')}
									<Icon
										name='upload'
										fontWeight='bold'
										color='white'
										width='14px'
										height='14px'
										margin='0 0 0 10px'
									/>
								</Button>
							</FlexView>
							<FlexView fontSize='big' fontWeight='bold' width='100%'>
								{t('wms:EndUsers')}
							</FlexView>
							<FlexView
								width='100%'
								height='100%'
								flexDirection='column'
								margin='16px 0 0 0'
								padding='0'
								alignItems='stretch'
								justifyContent='center'>
								{localEndUsers ? (
									_.map(localEndUsers, enduser => (
										<FlexView key={enduser.id} flexDirection='row' padding='0 6px' margin='6px 0'>
											<Card width='100%'>
												<FlexView flexDirection='row' width='100%' justifyContent='space-between'>
													<FlexView>
														<FlexView fontSize='big' fontWeight='bold'>
															{enduser.name}
														</FlexView>
														<FlexView fontSize='medium'>{enduser.description}</FlexView>
													</FlexView>
													<Button
														fontSize='small'
														color='white'
														backgroundColor='error'
														onClick={() => {
															deleteEndUserValue(enduser.id)
														}}>
														<Icon
															name='trash'
															color='white'
															height='16px'
															width='16px'
															tooltip={t('wms:RemoveAttribute')}
														/>
													</Button>
												</FlexView>
											</Card>
										</FlexView>
									))
								) : (
									<FlexView
										height='100%'
										width='100%'
										fontWeight='bold'
										fontSize='24px'
										color='lightGray'
										margin='auto 0'
										alignItems='center'
										justifyContent='center'
										data-cy='placeholder-pipes-list'>
										<p>{t('wms:NoAvailableEndUser')}</p>
									</FlexView>
								)}
							</FlexView>
						</Card>
						<Card
							width='calc(70% - 32px)'
							padding='16px'
							margin='0 16px 0 0'
							height='95%'
							data-cy='card-create-end-user'>
							<FlexView width='100%' fontSize='big' fontWeight='bold' margin='0 0 8px 0'>
								{t('wms:CreateEndUser')}
							</FlexView>
							<FlexView flexDirection='column' alignItems='center' width='100%' height='95%'>
								<Input
									type='text'
									label={t('wms:EndUserName')}
									placeholder={t('wms:Name')}
									value={newEndUser.name}
									onChange={e => setNewEndUser({ ...newEndUser, name: e.target.value })}
									data-cy='input-end-user-name'
								/>
								<Input
									type='text'
									label={t('wms:EndUserDesc')}
									placeholder={t('wms:Description')}
									value={newEndUser.description}
									onChange={e => setNewEndUser({ ...newEndUser, description: e.target.value })}
									data-cy='input-end-user-description'
								/>
								<Button
									margin='auto 0 0 0'
									style={{ alignSelf: 'flex-end' }}
									backgroundColor='secondary'
									color='white'
									onClick={() => addEndUser()}
									disabled={!newEndUser.name || !newEndUser.description}
									data-cy='button-add-enduser'>
									{t('wms:Add')}
									<Icon
										name='add'
										fontWeight='bold'
										color='white'
										width='14px'
										height='14px'
										margin='0 0 0 10px'
									/>
								</Button>
							</FlexView>
						</Card>
					</FlexView>
				</Card>
			</FlexView>
		</FlexView>
	)
}

export default EndUserSettings
