/** @format */

import { AssetsLevelsContext } from 'apps/wms/stores/AssetsLevelsStore'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import FlexView from 'components/common/FlexView'
import Modal from 'components/common/Modal'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmDeletionModal = ({ isOpen, onOutsideClick, level, selected }) => {
	const { t } = useTranslation()
	const { deleteLevelSelected } = useContext(AssetsLevelsContext)

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card data-cy='card-confirm-deletion'>
				<FlexView width='100%' fontSize='subtitle'>
					{t('wms:ConfirmLevelDeletion')}
				</FlexView>
				<FlexView
					flexDirection='row'
					width='100%'
					justifyContent='flex-end'
					margin='16px 0 0 0'
					data-cy='button-cancel-deletion'>
					<Button backgroundColor='error' color='white' onClick={() => onOutsideClick()}>
						{t('wms:Cancel')}
					</Button>
					<Button
						margin='8px 0 0 16px'
						backgroundColor='success'
						color='white'
						onClick={() => deleteLevelSelected(level, selected)}
						data-cy='button-confirm-deletion'>
						{t('wms:OK')}
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default ConfirmDeletionModal
