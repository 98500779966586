import axios from 'axios'


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const getDataFromToken = token => {
  const splits = token.split('.')
  if (splits.length === 3) {
    const user = JSON.parse(decodeURIComponent(escape(Buffer.from(splits[1], 'base64').toString('ascii'))))
    const data = {
      token,
      user: {
        ...user,
        roles: user.roles.split(' ')
      }
    }
    return data
  }
  else {
    return null
  }
}

export default {
  signIn: (username, password) => new Promise((resolve, reject) => {
    instance.post(
      '/auth/login',
      {
        id: username,
        password
      }
    ).then(response => {
      if (response.status === 200) {
        const { token } = response.data
        if (token) {
          const data = getDataFromToken(token)
          data ? resolve(data) : reject(new Error('InvalidTokenException'))
        }
        else {
          reject(new Error('NoTokenFound'))
        }
      }
      else {
        reject(new Error('CouldNotConnect'))
      }
    }).catch(err =>  {
      reject(err.response || err)
    })
  }),
  changePassword: (username, password, newPassword) => new Promise((resolve, reject) => {
    instance.put(
      '/api/admin/users/users/updatepwd',
      {
        name: username,
        password,
        newpassword: newPassword
      }
    ).then(response => {
      if (response.status === 200) {
        resolve()
      }
      else {
        reject(new Error('CouldNotConnect'))
      }
    }).catch(err =>  {
      reject(err.response || err)
    })
  }),
  renew: (token) => new Promise((resolve, reject) => {
    instance.get(
      '/auth/renew',
      {
        params: {
          start: true
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const { token } = response.data
        if (token) {
          const data = getDataFromToken(token)
          data ? resolve(data) : reject(new Error('InvalidTokenException'))
        }
        else {
          reject(new Error('NoTokenFound'))
        }
      }
      else {
        reject(new Error('CouldNotConnect'))
      }
    }).catch(err =>  {
      reject(err.response || err)
    })
  }),
}
