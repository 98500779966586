/** @format */

import axios from 'axios'

import { isReceiptNoteReport } from '../containers/reportEditor/businessRules'
import { INSPECTION, REPORT_TYPE } from '../utils/enum'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json'
	}
})

export default {
	generateDailyReport: (wid, language, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`inspection/${wid}/activityreport/${language}`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getInspectionReportDetails: (wid, reportId, inspType, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/${INSPECTION.path[inspType]}${wid}/reportdetails/${reportId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.reportdetails)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getReportData: (wid, reportType, reportId, token) =>
		new Promise((resolve, reject) => {
			let url = `/${REPORT_TYPE.path[reportType]}${wid}/`

			if (isReceiptNoteReport(reportType)) url += `millreceipt/${reportId}/details`
			else url += `list/${reportId}`

			instance
				.get(url, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						if (isReceiptNoteReport(reportType)) resolve(response.data.millreceiptdetails)
						else resolve(response.data[REPORT_TYPE.path[reportType].slice(0, -1)])
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getRejectReasons: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/inspection/${wid}/defectlist`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.defectlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	rejectReport: (wid, reportType, reportId, payload, token) =>
		new Promise((resolve, reject) => {
			const url = `/${REPORT_TYPE.path[reportType]}${wid}/reject/${reportId}`
			instance
				.post(url, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.defectlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenReport: (wid, reportType, reportId, payload, token) =>
		new Promise((resolve, reject) => {
			const url = `/${REPORT_TYPE.path[reportType]}${wid}/reopen/${reportId}`
			instance
				.post(url, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.defectlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),

	reopenReceiptNote: (wid, listID, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/batchentrance/${wid}/reopen/${listID}`, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),

	validateInspection: (wid, type, listID, params, token) =>
		new Promise((resolve, reject) => {
			const url = `/${REPORT_TYPE.path[type]}${wid}/validate/${listID}`
			instance
				.post(url, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.defectlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	validateReport: (wid, type, listID, params, token) =>
		new Promise((resolve, reject) => {
			const url = `/${REPORT_TYPE.path[type]}${wid}/validate/${listID}`
			instance
				.post(url, params, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.defectlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateReport: (wid, reportType, reportId, payload, token) =>
		new Promise((resolve, reject) => {
			const url = `/${REPORT_TYPE.path[reportType]}${wid}/update/${reportId}`
			instance
				.post(url, payload, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.defectlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getEndUsers: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${wid}/enduserlist`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.enduserlist)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	downloadInspectionReports: (path, wid, reportId, language, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`${path}${wid}/excel/${reportId}/${language}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadBackload: (wid, reportId, language, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`rigreturn/${wid}/backload/${reportId}/${language}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadDeliveryNote: (path, wid, reportId, ownershipId, language, ponumber, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(
					`${path}${wid}/deliverynote/${reportId}/${ownershipId}/${language}?ponumber=${encodeURIComponent(
						ponumber
					)}`,
					{
						headers: {
							Authorization: `Bearer ${token}`
						},
						responseType: 'blob'
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadDispatchReport: (wid, reportId, language, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`dispatch/${wid}/excel/${reportId}/${language}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadModifProdReports: (wid, reportId, language, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`modifprod/${wid}/backload/${reportId}/${language}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadAgeMgmtReport: (wid, id, language, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`agemgmt/${wid}/excel/${id}/${language}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	downloadMillReceiptReport: (wid, reportId, language, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/excel/${reportId}/${language}`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
