/** @format */

import React, { useState, useContext, useEffect, useCallback } from 'react'
import { UserContext } from 'stores/UserStore'
import content from '../api/content'
import { bundleOrItems } from '../utils/warehouse'
import { WMSContext } from './WmsStore'
import common from '../api/common'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { REPORT_TYPE } from '../utils/enum'
import moment from 'moment'
import usageRights from '../api/usageRights'

const initialState = {
	attributeDescriptions: []
}

export const ContentContext = React.createContext(initialState)

export const ContentProvider = ({ children }) => {
	const { t, i18n } = useTranslation()
	const { token } = useContext(UserContext)
	const { warehouse, lengthUnit, weightUnit, convertToCurrentLengthUnit, displayBundles, getEnduserList } =
		useContext(WMSContext)
	const [options, setOptions] = useState([])
	const [searchMaterial, setSearchMaterial] = useState([])
	const [pageLoading, setPageLoading] = useState(true)
	const [attributeDescriptions, setAttributeDescriptions] = useState([])
	const [materialDescription, setMaterialDescription] = useState([])
	const [currentTab, setCurrentTab] = useState(0)
	const [searchValid, setSearchValid] = useState('')
	const [searchPuuid, setSearchPuuid] = useState('')
	const [searchItems, setSearchItems] = useState(false)
	const [result, setResult] = useState(null)
	const [filteredMvid, setFilteredMvid] = useState([])
	const [statusDash, setStatusDash] = useState([])
	const [total, setTotal] = useState(0)
	const [materials, setMaterials] = useState([])
	const [material, setMaterial] = useState(undefined)
	const [attribute, setAttribute] = useState([])
	const [materialSelected, setMaterialSelected] = useState(null)
	const [minLength, setMinLength] = useState(undefined)
	const [maxLength, setMaxLength] = useState(undefined)
	const [erpReference, setErpReference] = useState([])
	const [erpResult, setErpResult] = useState(undefined)
	const [response, setResponse] = useState(null)
	const [foundMaterial, setFoundMaterial] = useState([])
	const [showTable, setShowTable] = useState(false)
	const [search, setSearch] = useState(false)
	const [resultOwnership, setResultOwnership] = useState([])
	const [responseOwnership, setResponseOwnership] = useState(null)
	const [searchingByOwnership, setSearchingByOwnership] = useState(false)
	const [levelId, setLevelId] = useState(null)
	const [levelContent, setLevelContent] = useState(false)
	const [bundles, setBundles] = useState(null)
	const [mId, setMid] = useState([])
	const [pipe, setPipe] = useState(null)
	const [openPipeData, setOpenPipeData] = useState(false)
	const [pipeData, setPipeData] = useState(null)
	const [printers, setPrinters] = useState(null)
	const [allPrinters, setAllPrinters] = useState(false)
	const [printerSelected, setPrinterSelected] = useState(null)
	const [exportExcel, setExportExcel] = useState(false)
	const [exportItemsDash, setExportItemsDash] = useState(false)
	const [loadingMatFilter, setLoadingMatFilter] = useState(false)
	const [loadingTable, setLoadingTable] = useState(false)
	const [filters, setFilters] = useState({})
	const [tableData, setTableData] = useState([])
	const [downloading, setDownloading] = useState(false)
	const [fullLevelsList, setFullLevelsList] = useState([])
	const [endUsers, setEndUsers] = useState([])
	const [endUserSelected, setEndUserSelected] = useState(null)
	const [filteredMaterials, setFilteredMaterials] = useState([])
	const [loadingItems, setLoadingItems] = useState(false)
	const [levelsName, setLevelsName] = useState([])
	const [subLevels, setSubLevels] = useState([])
	const [firstLevel, setFirstLevel] = useState({})
	const [lastOwnership, setLastOwnership] = useState(null)
	const [filteredLevels, setFilteredLevels] = useState([])

	const getMaterials = () =>
		new Promise((resolve, reject) => {
			setShowTable(false)
			common
				.getMaterials(warehouse.id, token)
				.then(mat => {
					let matDescriptions = mat.materialdescriptions
					let atrDescriptions = mat.attributedescriptions
					let descriptions = []
					let data = {}
					let formattedMaterials = []
					atrDescriptions = _.concat(atrDescriptions, 'erp Reference')
					_.forEach(atrDescriptions, atr => {
						descriptions.push({ accessor: atr, label: atr })
					})
					_.forEach(matDescriptions, (material, i) => {
						data = {}
						_.forEach(material.descriptions, (mat, m) => {
							data[mat] = material.values[m]
							data[`erp Reference`] = material.erpreference
							data[`mvid`] = material.mvid
						})
						formattedMaterials.push(data)
					})
					setMaterials(matDescriptions)
					setTableData(formattedMaterials)
					setResponse(mat)
					setAttribute(descriptions)
					resolve()
				})
				.catch(e => {
					reject(e)
				})
		})

	const getItems = () =>
		new Promise((resolve, reject) => {
			if (searchValid) {
				content
					.getItemsByValid(warehouse.id, searchValid, token)
					.then(response => {
						if (response.length === 0) {
							toast.error(t('wms:InvalidItems'))
							setShowTable(false)
						} else {
							let materialResult = []
							_.forEach(response, res => {
								materialResult.push({
									id: res.material.id,
									formatted: res.material.formatted,
									rackId: res.level.id,
									fullname: res.level.fullname,
									quantity: res.itemcount
								})
							})
							setFoundMaterial(materialResult)
							setShowTable(true)
							resolve()
						}
					})
					.catch(e => {
						reject(e)
					})
			}
			if (searchPuuid) {
				content
					.getItemsByPuuid(warehouse.id, searchPuuid, token)
					.then(response => {
						if (response.length === 0) {
							toast.error(t('wms:InvalidItems'))
							setShowTable(false)
						} else {
							let materialResult = []
							_.forEach(response, res => {
								materialResult.push({
									id: res.material.id,
									formatted: res.material.formatted,
									rackId: res.level.id,
									fullname: res.level.fullname,
									quantity: res.itemcount
								})
							})
							setFoundMaterial(materialResult)
							setShowTable(true)
							resolve()
						}
					})
					.catch(e => {
						toast.error(e.data || t('wms:InvalidMaterial'))
						reject(e)
					})
			}
		})

	const generateDashboard = () =>
		new Promise((resolve, reject) => {
			content
				.generateDashaboard(warehouse.id, token)
				.then(response => {
					let total = 0
					setStatusDash(response)
					_.forEach(response, (st, i) => {
						total += st.quantity
					})
					setTotal(total)
					resolve()
				})
				.catch(e => {
					reject(e)
				})
		})

	const getMaterialBySearch = () =>
		new Promise((resolve, reject) => {
			let mvid = []
			let filteredTableData = _.filter(tableData, item => {
				let match = true
				_.chain(filters)
					.pickBy(filter => filter.length > 0)
					.each((filter, key) => {
						let itemValue = _.get(item, key)
						match = match & _.isEqual(filter, itemValue)
					})
					.value()
				return match
			})
			if (material) {
				mvid.push(material)
			} else {
				_.forEach(filteredTableData, mat => {
					mvid.push(mat.mvid)
				})
			}
			content
				.getMaterialsBySearch(
					warehouse.id,
					{
						filtermaterialids: mvid,
						minlengthmm: !isNaN(minLength) ? parseFloat(convertToCurrentLengthUnit(minLength, lengthUnit)) : null,
						maxlengthmm: !isNaN(maxLength) ? parseFloat(convertToCurrentLengthUnit(maxLength, lengthUnit)) : null
					},
					token
				)
				.then(response => {
					if (response.length === 0) {
						toast.error(t('wms:InvalidItems'))
						setShowTable(false)
					} else {
						endUserSelected
							? setFoundMaterial(
									response
										.filter(res => res.material.enduserid === endUserSelected)
										.map(mat => ({
											id: mat.material.id,
											formatted: mat.material.formatted,
											rackId: mat.level.id,
											fullname: mat.level.fullname,
											quantity: mat.itemcount,
											enduser: mat.material.enduserid
										}))
							  )
							: setFoundMaterial(
									response.map(res => ({
										id: res.material.id,
										formatted: res.material.formatted,
										rackId: res.level.id,
										fullname: res.level.fullname,
										quantity: res.itemcount,
										enduser: res.material.enduserid
									}))
							  )
					}
					resolve()
					setLoadingTable(false)
				})
				.catch(e => {
					reject(e)
				})
				.finally(() => setLoadingTable(false))
		})

	const getOwnershipBySearch = ownershipId =>
		new Promise((resolve, reject) => {
			setSearchingByOwnership(true)
			ownershipId &&
				content
					.getOwnershipBySearch(warehouse.id, ownershipId, token)
					.then(response => {
						if (response.length === 0) {
							toast.error(t('wms:InvalidItems'))
							setShowTable(false)
							setFoundMaterial([])
						} else {
							let materialResult = []
							_.forEach(response, (res, i) => {
								materialResult.push({
									id: res.material.id,
									formatted: res.material.formatted,
									rackId: res.level.id,
									fullname: res.level.fullname,
									quantity: res.itemcount
								})
							})
							setFoundMaterial(materialResult)
							setShowTable(true)
							setLastOwnership(ownershipId)
						}
						resolve()
						setLoadingTable(false)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingItemsByOwnership')} [${e.status}]: ${e.data}`)
						setSearchingByOwnership(false)
						console.error(e)
					})
					.finally(() => setSearchingByOwnership(false))
		})

	const getLevelContents = (levelId, mid, ownership) =>
		new Promise((resolve, reject) => {
			let materialids = materials.map(mat => mat.mvid)
			setLoadingItems(true)
			levelId != null &&
				content
					.getLevelContents(warehouse.id, levelId, ownership, token)
					.then(response => {
						setBundles(response)
						let bundle = response
						let items = bundleOrItems(warehouse, bundle)
						let filteredItems = _.filter(items, e => {
							return (
								(!materialids || materialids.indexOf(e.material.id) > -1) &&
								(!mid || mid.indexOf(e.material.id) > -1) &&
								(!minLength || e.lengthmm >= minLength) &&
								(!maxLength || e.lengthmm <= maxLength)
							)
						})
						_.forEach(filteredItems, (it, i) => {
							if (it.laststatus !== 'UnderInspection') {
								it.laststatusfeature = null
								it.laststatuslist = null
							}
							let ownership = _.find(fullLevelsList, o => o.id === it.ownershipid)
							return ownership ? (it.ownershipname = ownership.label) : (it.ownershipname = '*****')
						})
						resolve(filteredItems)
					})
					.catch(e => {
						reject(e)
						setLoadingItems(false)
					})
					.finally(() => setLoadingItems(false))
		})

	const getPipeData = () =>
		new Promise((resolve, reject) => {
			setPageLoading(true)
			common
				.getPipeData(warehouse.id, pipe.valid, pipe.puuid, token)
				.then(response => {
					response.pipedata.actuallength = parseFloat(response.pipedata.actuallength).toFixed(3)
					response.pipedata.actualweight = parseFloat(response.pipedata.actualweight).toFixed(3)
					let number = response.pipedata.od
					if (number !== null) {
						number = parseFloat(number).toFixed(3)
						let int
						let base = 128
						let numerator = number * base
						int = Math.floor(numerator / base)
						numerator = Math.floor(numerator - int * base)
						for (let i = 1; i <= numerator; i++) {
							if (Number.isInteger(numerator / i) && Number.isInteger(base / i)) {
								numerator = numerator / i
								base = base / i
								i = 1
							}
						}
						response.pipedata.od = `${int.toString()}${
							numerator ? ` ${numerator.toString()}/${base.toString()}` : ''
						} "`
					}
					resolve(response.pipedata)
					setPageLoading(false)
				})
				.catch(e => {
					reject(e)
					setPageLoading(false)
					setPipeData(null)
				})
				.finally(() => setPageLoading(false))
		})

	const _getReportsFromValid = (wid, valid, token) =>
		new Promise((resolve, reject) => {
			common
				.getReportsFromValid(wid, valid, token)
				.then(response =>
					resolve(
						response.map(r => ({ ...r, reportType: REPORT_TYPE.path.findIndex(p => p.slice(0, -1) === r.type) }))
					)
				)
				.catch(e => {
					toast.error(t(`${e.data}`))
					reject(e)
				})
		})

	const getReportsFromValid = useCallback(
		valid => {
			return _getReportsFromValid(warehouse.id, valid, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _downloadReport = (wid, reportType, reportId, reportName, i18n, token) =>
		new Promise((resolve, reject) => {
			setDownloading(true)
			reportId !== null &&
				content
					.downloadReport(REPORT_TYPE.reportDownloadURL[reportType](wid, reportId, i18n), token)
					.then(response => {
						let fileName = `${reportName}.xlsx`
						let a = document.createElement('a')
						let file = new Blob([response], {
							type: response.type
						})
						let fileUrl = window.URL.createObjectURL(file)
						a.href = fileUrl
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(
							`${t('wms:ErrorDownloadingReport')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
						)
						setDownloading(false)
					})
					.finally(() => setDownloading(false))
		})

	const downloadReport = useCallback(
		(reportType, reportId, reportName) =>
			_downloadReport(warehouse.id, reportType, reportId, reportName, i18n, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, i18n, token]
	)

	const getPrinters = () =>
		new Promise((resolve, reject) => {
			setPageLoading(true)
			common
				.getPrinters(token)
				.then(response => {
					const printList = _.map(response, (res, i) => ({ value: res, label: res }))
					setPrinters(printList)
					resolve()
				})
				.catch(e => {
					reject(e)
					setPageLoading(false)
				})
				.finally(() => setPageLoading(false))
		})

	const _printItems = (items, printer, token, t, displayBundles) =>
		new Promise((resolve, reject) => {
			if (displayBundles()) {
				let payload = {
					printer: printer,
					localids: [...items.map(it => it.localid)]
				}
				common
					.setPrintersByBundles(payload, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:Printing OK'))
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorPrinting')} [${e.status}]: ${e.data}`)
					})
			} else {
				let payload = {
					printer: printer,
					items: [...items]
				}
				common
					.setPrintersByItems(payload, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:Printing OK'))
						setPrinterSelected(null)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorPrinting')} [${e.status}]: ${e.data}`)
						setPrinterSelected(null)
					})
			}
		})

	const printItems = useCallback(
		(items, printer) => _printItems(items, printer, token, t, displayBundles),
		[token, t, displayBundles]
	)

	const exportToExcel = () =>
		new Promise((resolve, reject) => {
			setDownloading(true)
			content
				.exportExcel(
					warehouse.id,
					levelId,
					0,
					i18n.translator.language,
					lengthUnit === 'mm' ? 'meter' : 'feet',
					weightUnit === 'kg' ? 'kilogram' : 'pound',
					{
						filtermaterialids: mId,
						minlengthmm: !isNaN(parseFloat(minLength)) ? convertToCurrentLengthUnit(parseFloat(minLength)) : null,
						maxlengthmm: !isNaN(parseFloat(maxLength)) ? convertToCurrentLengthUnit(parseFloat(maxLength)) : null,
						phystransissued: false
					},
					token
				)
				.then(response => {
					content
						.downloadLevelContent(warehouse.id, response.data.local, response.data.filename, token)
						.then(res => {
							let fileName = `Rack Content Extract - ${response.data.local} (${moment(new Date()).format(
								'M-DD-YYYY'
							)}).xlsx`
							let a = document.createElement('a')
							let file = new Blob([res], {
								type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
							})
							let fileUrl = window.URL.createObjectURL(file)
							a.href = fileUrl
							a.target = '_blank'
							a.download = fileName
							a.click()
							resolve(true)
						})
				})
				.catch(e => {
					toast.error(
						`${t('wms:ErrorDownloadingRackContent')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
					)
					reject(e)
					setDownloading(false)
				})
		})

	const exportDashboard = () =>
		new Promise((resolve, reject) => {
			content
				.exportItems(
					warehouse.id,
					i18n.language,
					lengthUnit === 'mm' ? 'meter' : 'feet',
					weightUnit === 'kg' ? 'kilogram' : 'pound',
					token
				)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					toast.error(
						`${t('wms:ErrorExportingDashboard')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
					)
					reject(e)
				})
				.finally(() => {})
		})

	const downloadCertificate = () =>
		new Promise((resolve, reject) => {
			let req = { path: pipeData.certificatepath }
			content
				.downloadCertificate(req, token)
				.then(response => {
					const link = document.createElement('a')
					link.href = response
					link.target = '_blank'
					link.click()
					resolve(true)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorDownloadCertificate')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getOwnershipLevels = () =>
		new Promise((resolve, reject) => {
			usageRights
				.getOwnershipLevels(warehouse.id, token)
				.then(response => {
					const levelsWithParents = _.map(response, (res, index) => ({
						...res,
						level: index + 1
					}))
					getOwnershipList(levelsWithParents)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getOwnershipList = ownershipLevels =>
		new Promise((resolve, reject) => {
			ownershipLevels &&
				usageRights
					.getOwnershipsList(warehouse.id, token)
					.then(response => {
						let ownerDesc = response.ownershipdescriptions
						let subOwnerLevels = []
						let otherLevels = _.filter(ownershipLevels, owner => owner.level > 1)
						let otherOwnerDescLevels = _.filter(ownerDesc, owner => owner.level > 1)
						let initialLevel = ownershipLevels
							.filter(owner => owner.level === 1)
							.map(lv => ({
								id: lv.id,
								description: lv.description,
								options: ownerDesc
									.filter(owd => owd.level === lv.id)
									.map(lv => ({
										id: lv.id,
										label: lv.namepath[0]
									})),
								selectedOption: -1,
								selectedLevel: 0,
								level: lv.level
							}))
						setFirstLevel(initialLevel[0])
						_.forEach(otherLevels, lv => {
							subOwnerLevels.push({
								id: lv.id,
								description: lv.description,
								options: otherOwnerDescLevels
									.filter(owl => owl.level === lv.id)
									.map(owner => {
										let idx = _.findIndex(owner.idpath, i => i === owner.id)
										return {
											id: owner.id,
											parentIds: _.filter(owner.idpath, id => id !== owner.id),
											label: owner.namepath[idx]
										}
									})
									.concat({ id: -1, parentIds: [], label: 'All' }),
								level: lv.level,
								parentLevel: lv.level - 1,
								selectedOption: -1,
								selectedLevel: 0
							})
						})
						let levelList = []
						_.forEach(ownerDesc, res => {
							res.level === 1
								? levelList.push({ id: res.idpath[0], label: res.namepath[0] })
								: _.forEach(res.idpath, (id, idx) => {
										levelList.push({ id: id, label: res.namepath[idx] })
								  })
						})
						setFullLevelsList(_.uniqBy(levelList, 'id').sort((a, b) => a.id - b.id))
						setSubLevels(subOwnerLevels)
						resolve()
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
					})
		})

	/*************************************************
	 *                USE EFFECT
	 **************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getOwnershipLevels()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		let mat = material && _.find(materials, m => m.mvid === material)
		let endUser = mat && _.find(endUsers, eu => eu.id === mat.enduserid)
		endUser && setEndUserSelected(endUser.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [material])

	useEffect(() => {
		endUserSelected && setFilteredMaterials(_.filter(materials, mat => mat.enduserid === endUserSelected))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endUserSelected])

	useEffect(() => {
		getEnduserList()
			.then(list => setEndUsers(list))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setShowTable(false)
		setSearchValid('')
		setSearchPuuid('')
		setLastOwnership(null)
		setMaterial(null)
		setEndUserSelected(null)
		setFilteredLevels([])
	}, [warehouse, currentTab])

	useEffect(() => {
		if (erpResult) {
			let erp = _.find(erpReference, er => {
				return er.value === erpResult
			})
			let filteredMaterialsByRef = _.filter(materials, mat => {
				return mat.erpreference === erp.label
			})
			setMaterials(filteredMaterialsByRef)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [erpResult])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingMatFilter(true)
				await getMaterials()
			} catch (e) {
				console.error(e)
			} finally {
				setLoadingMatFilter(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (searchItems) {
			const hydrate = async () => {
				try {
					setLoadingTable(true)
					await getItems()
				} catch (e) {
					setLoadingTable(false)
					console.error(e)
					setSearchItems(false)
				} finally {
					setLoadingTable(false)
					setSearchItems(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchPuuid, searchValid, searchItems])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setPageLoading(true)
				await generateDashboard()
			} catch (e) {
				setPageLoading(false)
				console.error(e)
			} finally {
				setPageLoading(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (search) {
			const hydrate = async () => {
				try {
					setLoadingTable(true)
					await getMaterialBySearch()
				} catch (e) {
					setLoadingTable(false)
					console.error(e)
					setSearch(false)
				} finally {
					setLoadingTable(false)
					setSearch(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search])

	useEffect(() => {
		if (pipe) {
			const hydrate = async () => {
				try {
					setPageLoading(true)
					let pipeData = await getPipeData()
					const reports = await getReportsFromValid(pipe.valid)
					pipeData.reports = reports
					setPipeData(pipeData)
				} catch (e) {
					setPageLoading(false)
					toast.error(`${t('wms:ErrorGettingPipeData')} [${e.status}]: ${e.data}`)
					//setSearchPipeData(false)
					console.error(e)
				} finally {
					setPageLoading(false)
					//setSearchPipeData(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipe])

	useEffect(() => {
		if (allPrinters) {
			const hydrate = async () => {
				try {
					setPageLoading(true)
					await getPrinters()
				} catch (e) {
					setPageLoading(false)
					setAllPrinters(false)
					console.error(e)
				} finally {
					setPageLoading(false)
					setAllPrinters(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allPrinters])

	useEffect(() => {
		if (exportExcel) {
			const hydrate = async () => {
				try {
					setDownloading(true)
					await exportToExcel()
				} catch (e) {
					setExportExcel(false)
					console.error(e)
					setDownloading(false)
				} finally {
					setExportExcel(false)
					setDownloading(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exportExcel])

	useEffect(() => {
		setFoundMaterial([])
		setShowTable(false)
	}, [warehouse, endUserSelected])

	const renderStore = (
		<ContentContext.Provider
			value={{
				options,
				attributeDescriptions,
				materialDescription,
				pageLoading,
				currentTab,
				statusDash,
				total,
				searchMaterial,
				materials,
				attribute,
				result,
				materialSelected,
				minLength,
				maxLength,
				erpReference,
				response,
				showTable,
				foundMaterial,
				search,
				resultOwnership,
				responseOwnership,
				searchValid,
				searchPuuid,
				searchItems,
				levelContent,
				mId,
				bundles,
				pipeData,
				allPrinters,
				printers,
				exportExcel,
				printerSelected,
				material,
				openPipeData,
				setOpenPipeData,
				erpResult,
				setErpResult,
				setMaterial,
				setPrinterSelected,
				setExportExcel,
				setPrinters,
				setAllPrinters,
				setPipeData,
				setBundles,
				pipe,
				setPipe,
				setMid,
				setLevelContent,
				setLevelId,
				setSearchItems,
				setSearchValid,
				setSearchPuuid,
				setResponseOwnership,
				setResultOwnership,
				setSearch,
				setFoundMaterial,
				setShowTable,
				setResponse,
				setErpReference,
				setMaxLength,
				setMinLength,
				setMaterialSelected,
				setResult,
				setAttribute,
				setMaterials,
				setSearchMaterial,
				setStatusDash,
				setTotal,
				setCurrentTab,
				setPageLoading,
				setAttributeDescriptions,
				setMaterialDescription,
				setOptions,
				getMaterials,
				getPipeData,
				filteredMvid,
				setFilteredMvid,
				exportItemsDash,
				setExportItemsDash,
				loadingMatFilter,
				setLoadingMatFilter,
				loadingTable,
				setLoadingTable,
				filters,
				setFilters,
				tableData,
				setTableData,
				downloading,
				setDownloading,
				downloadCertificate,
				fullLevelsList,
				setFullLevelsList,
				downloadReport,
				printItems,
				endUsers,
				setEndUsers,
				endUserSelected,
				setEndUserSelected,
				filteredMaterials,
				setFilteredMaterials,
				loadingItems,
				setLoadingItems,
				getLevelContents,
				exportDashboard,
				levelsName,
				setLevelsName,
				subLevels,
				setSubLevels,
				firstLevel,
				setFirstLevel,
				getOwnershipBySearch,
				searchingByOwnership,
				setSearchingByOwnership,
				lastOwnership,
				setLastOwnership,
				filteredLevels,
				setFilteredLevels
			}}>
			{children}
		</ContentContext.Provider>
	)
	return renderStore
}
