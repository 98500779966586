/** @format */

import 'pdfmake/build/pdfmake'
import pdfFonts from './vfs_fonts'

const pdfMake = window['pdfMake']
pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
	Calibri: {
		normal: 'Calibri-Regular.ttf',
		bold: 'Calibri-Bold.ttf',
		italics: 'Calibri-Italic.ttf',
		bolditalics: 'Calibri-BoldItalic.ttf'
	}
}

export default pdfMake
