/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import _ from 'lodash'
import { FlexView, Card, Button } from 'components/common'
import { STATUS } from '../../utils/enum'
import { RecInspContext } from '../../stores/RecInspStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'
import theme from 'utils/theme'

const RecInspInfo = () => {
	const { selectedRI, materialList, handleReopen } = useContext(RecInspContext)

	const { t, i18n } = useTranslation()
	const listDeadSpace = 320
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)
	const { user } = useContext(UserContext)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const allowReopen = true

	const matFormatted = id => {
		return id !== null &&
			id !== undefined &&
			materialList &&
			materialList.length &&
			materialList.find(mat => mat.mvid === id)
			? materialList.find(mat => mat.mvid === id).formatted
			: ''
	}

	return (
		<Card
			data-cy='card-info-wrapper'
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:ReceivingInspectionInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:Name')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedRI.name}
					</FlexView>
				</FlexView>
				<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:DispatchExpectedDate')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(selectedRI.expecteddate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:Enduser')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedRI.content[0].enduser.name}
					</FlexView>
				</FlexView>
				{selectedRI && selectedRI.content[0].customerpo && selectedRI.content[0].customeritem ? (
					<>
						<FlexView
							width='66%'
							margin='0 0 16px 0'
							padding='0'
							flexDirection='column'
							data-cy='card-customer-info'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:CustomerOrder')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedRI.content[0].customerpo}
							</FlexView>
						</FlexView>
						<FlexView
							width='33%'
							margin='0 0 16px 0'
							padding='0'
							flexDirection='column'
							data-cy='card-customer-info'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:CustomerItem')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedRI.content[0].customeritem}
							</FlexView>
						</FlexView>
					</>
				) : null}
				{selectedRI && selectedRI.content[0].salesorder && selectedRI.content[0].salesitem ? (
					<>
						<FlexView
							width='66%'
							margin='0 0 16px 0'
							padding='0'
							flexDirection='column'
							data-cy='card-sales-info'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:SalesOrder')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedRI.content[0].salesorder}
							</FlexView>
						</FlexView>
						<FlexView
							width='33%'
							margin='0 0 16px 0'
							padding='0'
							flexDirection='column'
							data-cy='card-sales-info'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:SalesItem')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedRI.content[0].salesitem}
							</FlexView>
						</FlexView>
					</>
				) : null}
				<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:Material')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{matFormatted(selectedRI.content[0].mid)}
					</FlexView>
				</FlexView>
			</FlexView>

			<FlexView
				width='100%'
				height={`${listHeight}px`}
				style={{ overflowY: 'scroll', overflowX: 'auto' }}
				data-cy='content-list'>
				{selectedRI &&
					selectedRI.content.length &&
					selectedRI.content.map((content, index) => (
						<FlexView
							key={content.rilcid}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							data-cy='content-list-item'>
							<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
								<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
							</FlexView>
							<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
								{`${t('wms:Quantity')}: ${content.quantity}`}
							</FlexView>
							<FlexView
								width='35%'
								margin='0'
								padding='0'
								flexDirection='row'
								flexWrap='wrap'
								justifyContent='center'>
								<FlexView
									fontSize='tiny'
									color={STATUS.color[content.status]}
									style={{
										borderLeft: `2px solid ${theme.colors[STATUS.color[content.status]]}`,
										lineHeight: '24px'
									}}
									margin='0 0 0 auto'
									padding='8px 16px'>
									{t(`wms:${STATUS.literal[content.status]}`)}
								</FlexView>

								{allowReopen &&
								content.status === STATUS.code.RUNNING &&
								_.find(user.roles, u => u === 'wmsadmin') ? (
									<Button
										fontSize='tiny'
										margin='0px 0 0 auto'
										color='white'
										backgroundColor='secondary'
										disabled={false}
										onClick={() => {
											handleReopen(content.rilcid)
										}}>
										{t('wms:Reopen')}
									</Button>
								) : null}
							</FlexView>
						</FlexView>
					))}
			</FlexView>
		</Card>
	)
}

export default RecInspInfo
