/** @format */

import { BatchEntranceContext } from 'apps/wms/stores/BatchEntranceStore'
import { Button, Card, FlexView, Icon, Modal, LoadingOverlay } from 'components/common'
import { Input } from 'components/form'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { STATUS } from '../../utils/enum'
import { DATE_FORMAT } from '../../utils/dateformat'
import theme from 'utils/theme'

const UpdateListModal = ({ isOpen, onOutsideClick, setOpenUpdateModal }) => {
	const {
		updateContent,
		expectedQuantity,
		setExpectedQuantity,
		setConfirmUpdate,
		deletedContent,
		pageLoading,
		setUpdateContent,
		updateContentCp
	} = useContext(BatchEntranceContext)
	const { t, i18n } = useTranslation()
	const [btnDisable, setBtnDisable] = useState(true)

	const handleAddToBatchList = () => {
		let updateContentCopy = JSON.stringify(updateContent.content)
		let content = { quantity: expectedQuantity }
		updateContent.content.push(content)
		setExpectedQuantity(0)
		if (updateContentCopy !== JSON.stringify(updateContent.content)) {
			setBtnDisable(false)
		}
	}

	const handleConfirmUpdate = () => {
		setConfirmUpdate(true)
		setOpenUpdateModal(false)
		setBtnDisable(true)
	}

	const handleDelete = index => {
		let content = updateContent.content.splice(index, 1)
		content[0].status !== undefined && deletedContent.push(content[0])
		setUpdateContent({ ...updateContent, content: [...updateContent.content] })
		if (updateContentCp.content.length === updateContent.content.length || updateContent.content.length === 0)
			setBtnDisable(true)
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='85%' height='90%' position='relative' data-cy='card-update-modal'>
				<LoadingOverlay visible={pageLoading} borderRadius='16px' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:UpdateEntrance')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				{!!updateContent ? (
					<FlexView width='100%' flex='1' flexDirection='row'>
						<FlexView maxWidth='40%' minWidth='40%' minHeight='100%' maxHeight='100%' margin='0 16px 0 0'>
							<Card
								margin='0 0 4px 0'
								flex='1'
								minWidth='calc(100% - 48px)'
								maxWidth='calc(100% - 48px)'
								data-cy='card-list-info'>
								<FlexView fontSize='medium' fontWeight='bold' width='100%' margin='0px 0px 16px 0px'>
									{t('wms:Source')}
								</FlexView>
								<FlexView flexDirection='row' width='100%' margin='0 0 8px 0' data-cy='list-label'>
									<span style={{ marginRight: '8px' }}>
										{t('wms:EndUser')}
										{':'}
									</span>
									<strong>{updateContent.enduser.name}</strong>
								</FlexView>

								<FlexView flexDirection='row' width='100%' margin='0 0 8px 0' data-cy='list-label'>
									<span style={{ marginRight: '8px' }}>
										{updateContent.customerpo ? t('wms:CustomerPoNo') : t('wms:SalesOrder')}
										{':'}
									</span>
									<strong>
										{updateContent.customerpo ? updateContent.customerpo : updateContent.salesorder}
									</strong>
								</FlexView>
								<FlexView flexDirection='row' width='100%' margin='0 0 8px 0' data-cy='list-label'>
									<span style={{ marginRight: '8px' }}>
										{updateContent.customeritem ? t('wms:CustomerItem') : t('wms:SalesItem')}
										{':'}
									</span>
									<strong>
										{updateContent.customeritem ? updateContent.customeritem : updateContent.salesitem}
									</strong>
								</FlexView>
								<FlexView flexDirection='column' width='100%' margin='0 0 8px 0' data-cy='list-label'>
									<span style={{ marginRight: '8px' }}>
										{t('wms:Material')}
										{':'}
									</span>
									<strong>{updateContent.matdesc}</strong>
								</FlexView>

								<Input
									label={t('wms:ExpectedQuantity')}
									type='number'
									min='0'
									max='99999999'
									value={expectedQuantity}
									onChange={e => setExpectedQuantity(e.target.value)}
									margin='16px 0 0 0'
									width='100%'
									data-cy='input-expected-quantity'
								/>

								<FlexView
									flexDirection='row'
									width='100%'
									flexWrap='wrap'
									justifyContent='space-between'
									margin='auto 0 0 0'>
									<Button
										fontSize='medium'
										margin='auto 0 0 auto'
										color='white'
										backgroundColor='secondary'
										disabled={expectedQuantity <= 0}
										onClick={() => {
											handleAddToBatchList()
										}}>
										{t('wms:AddPart')}
									</Button>
								</FlexView>
							</Card>
						</FlexView>
						<FlexView
							maxWidth='calc(60% - 16px)'
							minWidth='calc(60% - 16px)'
							minHeight='100%'
							maxHeight='100%'
							margin='0'>
							<Card
								margin='0 0 4px 0'
								width='calc(100% - 48px)'
								minHeight='inherit'
								flex='1'
								data-cy='card-batch-info'>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
									{t('wms:EntranceInfo')}
								</FlexView>
								<FlexView
									width='100%'
									flexDirection='row'
									margin='8px 0'
									flexWrap='wrap'
									justifyContent='space-between'>
									<FlexView width='50%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView
											width='100%'
											fontWeight='bold'
											fontSize='tiny'
											color='metalic'
											margin='0'
											data-cy='batch-info'>
											{t('wms:Name')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{updateContent.name}
										</FlexView>
									</FlexView>
									<FlexView width='50%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView
											width='100%'
											fontWeight='bold'
											fontSize='tiny'
											color='metalic'
											margin='0'
											data-cy='batch-info'>
											{t('wms:ExpectedDate')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{DATE_FORMAT(updateContent.expected, i18n.language)}
										</FlexView>
									</FlexView>
								</FlexView>

								<FlexView width='100%' height='430px' style={{ overflowY: 'scroll', overflowX: 'auto' }}>
									{updateContent && updateContent.content.length ? (
										updateContent.content.map((content, index) => (
											<FlexView
												key={index}
												width='100%'
												margin='0'
												flexDirection='row'
												padding='8px 0'
												style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
												<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
													<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
												</FlexView>
												<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
													{`${t('wms:ExpectedQuantity')}: ${content.quantity}`}
												</FlexView>
												<FlexView
													fontSize='tiny'
													color={STATUS.color[content.status]}
													style={{
														borderLeft: `2px solid ${theme.colors[STATUS.color[content.status]]}`,
														lineHeight: '24px'
													}}
													margin='0 4px 0 auto'
													padding='8px 16px'>
													{t(`wms:${STATUS.literal[content.status]}`)}
												</FlexView>
												<Button
													fontSize='small'
													margin='auto 0 auto auto'
													padding='8px 16px'
													color='white'
													backgroundColor='error'
													disabled={
														content.status !== STATUS.code.NOTSTARTED && content.status !== undefined
													}
													onClick={() => {
														handleDelete(index)
													}}
													data-cy='button-remove-content'>
													<Icon
														name='trash'
														color='white'
														height='14px'
														width='14px'
														margin='0'
														tooltip={`${t('wms:RemovePart')}`}
													/>
												</Button>
											</FlexView>
										))
									) : (
										<></>
									)}
								</FlexView>
							</Card>
						</FlexView>
					</FlexView>
				) : null}

				<FlexView width='100%' flexDirection='row' margin='16px 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='secondary'
						disabled={btnDisable}
						onClick={() => {
							handleConfirmUpdate()
						}}
						data-cy='button-update-batch'>
						{t('wms:Update')}
						<Icon name='refresh' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default UpdateListModal
