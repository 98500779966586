/** @format */

import { Button, FlexView, LoadingOverlay } from 'components/common'
import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AssetsPrinterContext } from 'apps/wms/stores/AssetsPrinterStore'
import _ from 'lodash'
import { Select } from 'components/form'
import Table from 'apps/wms/components/assets/Table'

const PrintersTable = () => {
	const { t } = useTranslation()
	const {
		data,
		loadingPrinters,
		setSize,
		setSavePrinter,
		setTempSelected,
		setNewConfig,
		testConfig,
		setTestConfig,
		setPrinterTest
	} = useContext(AssetsPrinterContext)

	//const[rfid, setRFID] = useState(null)

	const isRFID = [
		{
			value: false,
			label: `${t('wms:No')}`
		},
		{
			value: true,
			label: `${t('wms:Yes')}`
		}
	]

	const selectRFID = useCallback((value, row) => {
		row.original.rfid = value
		//setRFID(value)
	}, [])

	const selectTemplate = useCallback(
		(value, row) => {
			row.original.tagname = value
			let type = _.find(row.original.printertemplate, p => p.name === value)
			row.original.tagtype = type.type
			setTempSelected(value)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data]
	)

	const getOptions = row => {
		let values = _.find(row.original.printertemplate, tags => tags.name === row.original.tagname)
		return _.map(values ? values.sizes : [], t => {
			return { value: t, label: t }
		})
	}

	const selectSize = useCallback((value, row) => {
		row.original.printersize = value
		setSize(value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const savePrinterConfig = useCallback(
		row => e => {
			e.stopPropagation()
			setNewConfig(row.original)
			setSavePrinter(true)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const testPrinter = useCallback(
		row => e => {
			e.stopPropagation()
			setPrinterTest(row.original)
			setTestConfig(true)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const tableData = useMemo(
		() =>
			_.chain(data)
				.map(value => ({
					printer: value.printer,
					tagname: value.tagname,
					printertemplate: value.templates,
					printersize: value.tagsize,
					rfid: value.rfid,
					tagtype: value.tagtype
				}))
				.value(),
		[data]
	)

	const columns = useMemo(
		() => [
			{
				Header: (
					<FlexView
						fontSize='big'
						fontWeight='bold'
						color='metalic'
						style={{ alignItems: 'center', texAlign: 'center' }}>
						{t('wms:Printer')}
					</FlexView>
				),
				accessor: 'printer',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' width='100%' margin='0 auto'>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterTemplate')}
					</FlexView>
				),
				accessor: 'printertemplate',
				Cell: ({ cell: { value, row } }) => (
					<Select
						width='100%'
						placeholder={t('wms:SelectOption')}
						value={row.original.tagname}
						options={
							value &&
							_.map(value, v => {
								return { value: v.name, label: v.name }
							})
						}
						onChange={v => selectTemplate(v, row)}></Select>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterSize')}
					</FlexView>
				),
				accessor: 'printersize',
				Cell: ({ cell: { value, row } }) => (
					<Select
						width='100%'
						placeholder={t('wms:SelectOption')}
						value={row.original.printersize}
						options={getOptions(row)}
						onChange={v => selectSize(v, row)}></Select>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterRFID')}
					</FlexView>
				),
				accessor: 'rfid',
				Cell: ({ cell: { value, row } }) => (
					<FlexView width='100%' style={{ alignSelf: 'center' }} alignItems='center'>
						<Select
							width='100%'
							placeholder={t('wms:SelectOption')}
							options={isRFID}
							value={row.original.rfid}
							onChange={v => selectRFID(v, row)}></Select>
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterSaveConfig')}
					</FlexView>
				),
				accessor: 'saveprinterconfig',
				Cell: ({ cell: { value, row } }) => (
					<FlexView width='100%' alignItems='center'>
						<Button
							margin='8px 0 16px 0'
							backgroundColor='secondary'
							color='white'
							onClick={savePrinterConfig(row)}>
							{t('wms:PrinterSaveConfig')}
						</Button>
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterTest')}
					</FlexView>
				),
				accessor: 'printertest',
				Cell: ({ cell: { value, row } }) => (
					<FlexView width='100%' alignItems='center'>
						<Button margin='8px 0 16px 0' backgroundColor='secondary' color='white' onClick={testPrinter(row)}>
							{t('wms:PrinterTest')}
							<LoadingOverlay visible={testConfig} />
						</Button>
					</FlexView>
				)
			}
		],
		[t, isRFID, savePrinterConfig, selectSize, selectTemplate, testPrinter, selectRFID, testConfig]
	)

	return (
		<>
			<LoadingOverlay visible={loadingPrinters} borderRadius='card' />
			<Table columns={columns} data={tableData} width='100%' height='100%' data-cy='table-printers'></Table>
		</>
	)
}

export default PrintersTable
