/** @format */

import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import usageRights from '../api/usageRights'

import { WMSContext } from './WmsStore'
import { UserContext } from 'stores/UserStore'
import inspection from '../api/inspection'
import _ from 'lodash'
import { useCallback } from 'react'

export const UsageRightsContext = React.createContext()

export const UsageRightsProvider = ({ children }) => {
	const {
		warehouse,
		bundlesOrItems,
		displayBundles,
		displayValids,
		displayPuuids,
		getLevelsData,
		getContentByOwnership,
		getContentByEndUser,
		getEnduserList,
		getReportList,
		getReportItems,
		getMaterialsDescpritions
	} = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const [sourceType, setSourceType] = useState(null)
	const [typeOfRight, setTypeOfRight] = useState(null)
	const [loadingPipes, setLoadingPipes] = useState(false)
	const [changingUsageRights, setChangingUsageRights] = useState(false)
	const [availablePipes, setAvailablePipes] = useState([])
	const [selectedPipes, setSelectedPipes] = useState([])
	const [openConfirmModal, setConfirmModal] = useState(false)
	const [levels, setLevels] = useState([])
	const [sourceOwnership, setSourceOwnership] = useState(null)
	const [targetRight, setTargetRight] = useState(null)
	const [rackList, setRackList] = useState([])
	const [sourceRack, setSourceRack] = useState(null)
	const [loadingRacks, setLoadingRacks] = useState(false)
	const [valid, setValid] = useState('')
	const [isValid, setIsValid] = useState(null)
	const [sourceEnduser, setSourceEnduser] = useState(null)
	const [endusers, setEndusers] = useState([])
	const [allEndusers, setAllEndusers] = useState([])
	const [loadingOrders, setLoadingOrders] = useState(false)
	const [sourceCustomerOrder, setSourceCustomerOrder] = useState()
	const [sourceCustomerItem, setSourceCustomerItem] = useState()
	const [customerOrders, setCustomerOrders] = useState([])
	const [customerItems, setCustomerItems] = useState([])
	const [sourceSalesOrder, setSourceSalesOrder] = useState()
	const [sourceSalesItem, setSourceSalesItem] = useState()
	const [salesOrders, setSalesOrders] = useState([])
	const [salesItems, setSalesItems] = useState([])
	const [loadingOwnerships, setLoadingOwnerships] = useState(false)
	const [ownershipList, setOwnershipList] = useState([])
	const [materialsList, setMaterialsList] = useState([])
	const [loadingMaterials, setLoadingMaterials] = useState(false)
	const [sourceMaterial, setSourceMaterial] = useState(null)
	const [targetMaterial, setTargetMaterial] = useState(null)
	const [localEndUsers, setLocalEndUsers] = useState([])
	const [reports, setReports] = useState(null)
	const [selectedReport, setSelectedReport] = useState(null)
	const [selectedType, setSelectedType] = useState(null)
	const [loadingReports, setLoadingReports] = useState(false)
	const [sourceMaterialList, setSourceMaterialList] = useState([])
	const [totalSelectedPipes, setTotalSelectedPipes] = useState(0)
	const [materialsByEndUser, setMaterialsByEndUser] = useState([])
	const [filteredPipes, setFilteredPipes] = useState([])
	const [materialsByReport, setMaterialsByReport] = useState([])

	const getRackList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingRacks(true)
			const req =
				typeOfRight === 'OWNERSHIP'
					? getContentByOwnership(sourceOwnership, false)
					: sourceEnduser.id
					? getContentByEndUser(sourceEnduser.id, false)
					: setLoadingRacks(false) && null
			req &&
				req
					.then(response => {
						let rackList = response
							.map(item => ({
								id: item.level.id,
								fullname: item.level.fullname,
								qnty: item.itemcount
							}))
							.filter((thing, index, self) => index === self.findIndex(t => t.id === thing.id))
						resolve(rackList)
					})
					.catch(e => {
						toast.error(t(`wms:ErrorGettingContent`))
						reject(e)
					})
					.finally(() => {
						setLoadingRacks(false)
					})
		})

	const getCustomerOrdersList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingOrders(true)
			const req =
				typeOfRight === 'OWNERSHIP'
					? usageRights.getCustomerOrdersListByEndUser(warehouse.id, sourceEnduser.id, token)
					: usageRights.getCustomerOrdersListByOwnership(warehouse.id, sourceOwnership, token)
			req.then(response => {
				if (response.length) {
					let data = _.filter(response, res => res.number !== null)
					let orders = []
					data.forEach(item => {
						let index = orders.findIndex(order => order.value === item.number)
						if (index === -1) {
							orders.unshift({ value: item.number, items: [] })
							index = 0
						}
						orders[index].items.push(item.item)
					})
					resolve(orders)
				} else resolve(response)
			})
				.catch(e => {
					toast.error(t(`wms:ErrorGettingProductionOrderList`))
					reject(e)
				})
				.finally(() => {
					setLoadingOrders(false)
				})
		})

	const getSalesOrdersList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingOrders(true)
			const req =
				typeOfRight === 'OWNERSHIP'
					? usageRights.getSalesOrdersListByEndUser(warehouse.id, sourceEnduser.id, token)
					: usageRights.getSalesOrdersListByOwnership(warehouse.id, sourceOwnership, token)
			req.then(response => {
				if (response.length) {
					let sales = []
					let data = _.filter(response, res => res.number !== null)
					data.forEach(item => {
						let index = sales.findIndex(sale => sale.value === item.number)
						if (index === -1) {
							sales.unshift({ value: item.number, items: [] })
							index = 0
						}
						sales[index].items.push(item.item)
					})
					resolve(sales)
				} else resolve(response)
			})
				.catch(e => {
					toast.error(t(`wms:ErrorGettingSalesOrderList`))
					reject(e)
				})
				.finally(() => {
					setLoadingOrders(false)
				})
		})

	const validateValid = () =>
		new Promise((resolve, reject) => {
			setIsValid(null)
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			usageRights
				.checkValid(warehouse.id, valid, token)
				.then(response => {
					if (response.length) {
						typeOfRight === 'END_USER' && setSourceMaterial(response[0].material.id)
						setIsValid(true)
					} else setIsValid(false)
					resolve(isValid)
				})
				.catch(e => {
					setIsValid(false)
					toast.error(t(`wms:ErrorGettingContent`))
					reject(e)
				})
				.finally(() => {})
		})

	const getOwnershipByOrder = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingOwnerships(true)
			let req = null
			if (sourceType === 'CUSTOMER_ITEM' && sourceEnduser && sourceCustomerOrder && sourceCustomerItem)
				req = usageRights.getOwnershipsByPipedata(
					warehouse.id,
					sourceEnduser.id,
					sourceCustomerOrder,
					sourceCustomerItem,
					0,
					token
				)
			else if (sourceType === 'SALES_ITEM' && sourceEnduser && sourceSalesOrder && sourceSalesItem)
				req = usageRights.getOwnershipsByPipedata(
					warehouse.id,
					sourceEnduser.id,
					sourceSalesOrder,
					sourceSalesItem,
					1,
					token
				)
			else {
				setLoadingOwnerships(false)
				resolve(null)
			}
			req.then(response => response.length && resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorGettingOwnershipEndUser`))
					reject(e)
				})
				.finally(() => {
					setLoadingOwnerships(false)
				})
		})

	const getEndUserByOrder = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingOwnerships(true)
			let req = null
			if (sourceType === 'CUSTOMER_ITEM' && sourceOwnership && sourceCustomerOrder && sourceCustomerItem)
				req = usageRights.getEndUsersByPipedata(
					warehouse.id,
					sourceOwnership,
					sourceCustomerOrder,
					sourceCustomerItem,
					0,
					token
				)
			else if (sourceType === 'SALES_ITEM' && sourceOwnership && sourceSalesOrder && sourceSalesItem)
				req = usageRights.getEndUsersByPipedata(
					warehouse.id,
					sourceOwnership,
					sourceSalesOrder,
					sourceSalesItem,
					1,
					token
				)
			else {
				setLoadingOwnerships(false)
				resolve(null)
			}
			req.then(response => response && resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorGettingEndUser`))
					reject(e)
				})
				.finally(() => {
					setLoadingOwnerships(false)
				})
		})

	const getAvailablePipes = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			setLoadingPipes(true)
			let req = null
			if (sourceType === 'CUSTOMER_ITEM')
				req = usageRights.getPipesByOrderItem(
					warehouse.id,
					sourceEnduser.id,
					sourceCustomerOrder,
					sourceCustomerItem,
					sourceOwnership,
					0,
					token
				)
			else if (sourceType === 'SALES_ITEM')
				req = usageRights.getPipesByOrderItem(
					warehouse.id,
					sourceEnduser.id,
					sourceSalesOrder,
					sourceSalesItem,
					sourceOwnership,
					1,
					token
				)
			else if (sourceType === 'RACK') req = usageRights.getPipesByLevel(warehouse.id, sourceRack, token)
			req.then(contentList => {
				let items = bundlesOrItems(contentList)
				items = items.map(item => {
					if (displayBundles())
						return {
							...item,
							id: item.bundlelocalid,
							weight: 0,
							erpRef: item.material.formatted.substring(0, item.material.formatted.indexOf(' '))
						}
					if (displayValids())
						return {
							...item,
							id: item.valid,
							weight: item.weightkg,
							erpRef: item.material.formatted.substring(0, item.material.formatted.indexOf(' '))
						}
					if (displayPuuids())
						return {
							...item,
							id: item.puuid,
							weight: item.weightkg,
							erpRef: item.material.formatted.substring(0, item.material.formatted.indexOf(' '))
						}
					return item
				})
				resolve(items)
			})
				.catch(e => {
					console.error(e)
					toast.error(t(`wms:ErrorReadingContent`))
					reject(e)
				})
				.finally(() => {
					setLoadingPipes(false)
				})
		})

	const getAllEnduserList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				usageRights
					.getAllEndusersList(token)
					.then(response => response.length && resolve(response))
					.catch(e => {
						toast.error(t(`wms:ErrorGettingEndUser`))
						reject(e)
					})
					.finally(() => {})
		})

	const getSourceMaterialByEnduser = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			inspection
				.getMaterialsByEU(warehouse.id, sourceEnduser.id, token)
				.then(response => response && resolve(response))
				.catch(e => {
					toast.error(t(`wms:ErrorGettingMatListEndUser`))
					reject(e)
				})
				.finally(() => {
					setLoadingOwnerships(false)
				})
		})

	const changeOwnership = () =>
		new Promise((resolve, reject) => {
			setConfirmModal(false)
			if (!(!!targetRight && selectedPipes.length !== 0)) {
				toast.error(t(`wms:InvalidMoveSelection`))
				return
			}
			setChangingUsageRights(true)
			let payload = { secondsago: 0 }
			if (displayBundles()) payload.bundlelocalids = selectedPipes
			else if (displayValids()) payload.valids = selectedPipes
			else payload.puuids = selectedPipes
			usageRights
				.changeOwnership(warehouse.id, targetRight, payload, token)
				.then(response => {
					toast.success(t(`wms:MoveSuccessful`))
					setSourceType(null)
					resolve()
				})
				.catch(e => {
					console.error(e)
					toast.error(t(`${e.data}`) || t(`wms:ErrorReadingContent`))
					reject(e)
				})
				.finally(() => {
					setChangingUsageRights(false)
				})
		})

	const changeEndUser = () =>
		new Promise((resolve, reject) => {
			setConfirmModal(false)
			if (!(!!targetRight && !!targetMaterial && selectedPipes.length !== 0)) {
				toast.error(t(`wms:InvalidMoveSelection`))
				return
			}
			setChangingUsageRights(true)
			let payload = { secondsago: 0 }
			if (displayBundles()) payload.bundlelocalids = selectedPipes
			else if (displayValids()) payload.valids = selectedPipes
			else payload.puuids = selectedPipes
			usageRights
				.changeEnduser(targetRight, targetMaterial, payload, token)
				.then(response => {
					toast.success(t(`wms:MoveSuccessful`))
					setSourceType(null)
					resolve()
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorMovingPipes')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => {
					setChangingUsageRights(false)
				})
		})

	const _getOwnershipByMaterial = (material, wid, token) =>
		new Promise((resolve, reject) => {
			usageRights
				.getOwnershipByMaterial(wid, material, token)
				.then(response => {
					let lastLevel = Math.max.apply(
						Math,
						response.ownershipdescriptions.map(res => res.level)
					)
					let oData = response.ownershipdescriptions
					let level = []
					let lastLevelDesc = _.filter(oData, owner => {
						return owner.level === lastLevel
					})
					_.forEach(lastLevelDesc, owner => {
						level.push({ id: owner.id, label: owner.namepath.join('.') })
					})
					resolve(level)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getOwnershipByMaterial = useCallback(
		material => _getOwnershipByMaterial(material, warehouse.id, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _getRackListByMaterial = (mid, wid, token) =>
		new Promise((resolve, reject) => {
			usageRights
				.getContentByMaterial(mid, wid, token)
				.then(response => {
					let rackList = response
						.map(item => ({
							id: item.level.id,
							fullname: item.level.fullname,
							qnty: item.itemcount,
							material: item.material
						}))
						.filter((thing, index, self) => index === self.findIndex(t => t.id === thing.id))
					resolve(rackList)
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorGettingContentByMaterial')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const getRackListByMaterial = useCallback(
		material => _getRackListByMaterial(material, warehouse.id, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	useEffect(() => {
		setLoadingMaterials(true)
		getLevelsData()
			.then(levels => levels && setLevels(levels))
			.catch(e => console.error(e))
		getEnduserList()
			.then(endusersList => endusersList && setEndusers(endusersList))
			.catch(e => console.error(e))
		getAllEnduserList()
			.then(endusersList => endusersList && setAllEndusers(endusersList))
			.catch(e => console.error(e))
		getMaterialsDescpritions()
			.then(list => {
				setSourceMaterialList(list)
				setMaterialsList(list)
			})
			.catch(e => console.error(e))
			.finally(() => setLoadingMaterials(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setSelectedPipes([])
		setAvailablePipes([])
		setSourceOwnership(null)
		setSourceRack(null)
		setValid('')
		setIsValid(null)
		setSourceEnduser(null)
		setCustomerOrders([])
		setSourceCustomerOrder(null)
		setCustomerItems([])
		setSourceCustomerItem(null)
		setSourceOwnership(null)
		setSourceSalesOrder(null)
		setSourceSalesItem(null)
		setSalesOrders([])
		setSalesItems([])
		setOwnershipList([])
		setSelectedReport(null)
		setSelectedType(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceType, typeOfRight, warehouse])

	useEffect(() => {
		setSelectedPipes([])
	}, [availablePipes])

	useEffect(() => {
		if (valid.length === 11)
			validateValid().then(() => {
				setSelectedPipes([valid])
			})
		else setSelectedPipes([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valid])

	useEffect(() => {
		setSourceRack(null)
		setAvailablePipes([])
	}, [sourceMaterial])

	useEffect(() => {
		setAvailablePipes([])
		;(typeOfRight === 'OWNERSHIP'
			? sourceOwnership &&
			  ((sourceType === 'CUSTOMER_ITEM' && sourceCustomerOrder && sourceCustomerItem && sourceEnduser) ||
					(sourceType === 'SALES_ITEM' && sourceSalesOrder && sourceSalesItem && sourceEnduser) ||
					(sourceType === 'RACK' && sourceRack))
			: (sourceEnduser &&
					((sourceType === 'CUSTOMER_ITEM' && sourceCustomerOrder && sourceCustomerItem && sourceOwnership) ||
						(sourceType === 'SALES_ITEM' && sourceSalesOrder && sourceSalesItem && sourceOwnership))) ||
			  (sourceType === 'RACK' && sourceRack)) &&
			getAvailablePipes()
				.then(
					pipeList =>
						pipeList &&
						pipeList.length &&
						setAvailablePipes(
							typeOfRight === 'OWNERSHIP' && sourceType === 'RACK'
								? pipeList.filter(p => p.ownershipid === sourceOwnership && p.material.id === sourceMaterial)
								: typeOfRight === 'END_USER' &&
								  (sourceType === 'RACK' || sourceType === 'CUSTOMER_ITEM' || sourceType === 'SALES_ITEM')
								? pipeList.filter(p => p.material.id === sourceMaterial)
								: pipeList
						)
				)
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceOwnership, sourceRack, sourceEnduser, sourceMaterial])

	//End User useEffects
	useEffect(() => {
		if (
			typeOfRight === 'END_USER' &&
			(sourceType === 'RACK' || sourceType === 'CUSTOMER_ITEM' || sourceType === 'SALES_ITEM') &&
			sourceEnduser != null
		) {
			setSourceMaterial(null)
			setLoadingMaterials(true)
			getSourceMaterialByEnduser()
				.then(materialList => materialList && materialList.length && setMaterialsByEndUser(materialList))
				.catch(e => console.error(e))
				.finally(() => setLoadingMaterials(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeOfRight, sourceType, sourceEnduser])

	useEffect(() => {
		if (selectedType != null) {
			setLoadingReports(true)
			getReportList(selectedType)
				.then(list => {
					setReports(list)
				})
				.catch(e => {
					console.error(e)
					setLoadingReports(false)
				})
				.finally(() => setLoadingReports(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType])

	useEffect(() => {
		if (selectedType != null && selectedReport != null && typeOfRight === 'END_USER') {
			setLoadingMaterials(true)
			getReportItems(selectedType, selectedReport)
				.then(list => {
					if (list && list.length) {
						let filteredMaterials = []
						_.forEach(sourceMaterialList, mat => {
							_.some(list, ls => ls.material.id === mat.mvid) && filteredMaterials.push(mat)
						})
						setMaterialsByReport(filteredMaterials)
						setAvailablePipes(
							list.map(l => ({
								...l,
								erpRef: l.material.formatted.substring(0, l.material.formatted.indexOf(' '))
							}))
						)
					}
				})
				.catch(e => {
					setLoadingMaterials(false)
					console.error(e)
				})
				.finally(() => setLoadingMaterials(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType, selectedReport, typeOfRight])

	useEffect(() => {
		if (selectedType != null && selectedReport != null && sourceMaterial != null && typeOfRight === 'END_USER') {
			setFilteredPipes(_.filter(availablePipes, pipes => pipes.material.id === sourceMaterial))
		} else if (selectedType != null && selectedReport != null && typeOfRight === 'OWNERSHIP') {
			setLoadingPipes(true)
			getReportItems(selectedType, selectedReport)
				.then(list => {
					if (list && list.length) setAvailablePipes(list)
				})
				.catch(e => {
					setLoadingPipes(false)
					console.error(e)
				})
				.finally(() => setLoadingPipes(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType, selectedReport, sourceMaterial, typeOfRight])

	useEffect(() => setTotalSelectedPipes(0), [typeOfRight])

	useEffect(() => {
		setSourceRack(null)
	}, [sourceMaterial])

	useEffect(() => {
		setSourceMaterial(null)
		setFilteredPipes([])
	}, [selectedReport, selectedType])

	useEffect(() => setTotalSelectedPipes(0), [typeOfRight])
	useEffect(() => {
		setSourceMaterialList([])
	}, [sourceOwnership])

	useEffect(() => {
		setAvailablePipes([])
	}, [selectedType])

	const renderStore = (
		<UsageRightsContext.Provider
			value={{
				sourceType,
				setSourceType,
				typeOfRight,
				setTypeOfRight,
				availablePipes,
				selectedPipes,
				setSelectedPipes,
				loadingPipes,
				openConfirmModal,
				setConfirmModal,
				levels,
				sourceOwnership,
				setSourceOwnership,
				targetRight,
				setTargetRight,
				changeOwnership,
				changingUsageRights,
				rackList,
				sourceRack,
				setSourceRack,
				loadingRacks,
				valid,
				setValid,
				isValid,
				setIsValid,
				sourceEnduser,
				setSourceEnduser,
				endusers,
				allEndusers,
				loadingOrders,
				sourceCustomerOrder,
				setSourceCustomerOrder,
				sourceCustomerItem,
				setSourceCustomerItem,
				customerOrders,
				customerItems,
				sourceSalesOrder,
				setSourceSalesOrder,
				sourceSalesItem,
				setSourceSalesItem,
				salesOrders,
				salesItems,
				ownershipList,
				loadingOwnerships,
				changeEndUser,
				materialsList,
				loadingMaterials,
				targetMaterial,
				setTargetMaterial,
				getCustomerOrdersList,
				setCustomerOrders,
				getSalesOrdersList,
				setSalesOrders,
				setCustomerItems,
				setSalesItems,
				getOwnershipByOrder,
				getEndUserByOrder,
				setOwnershipList,
				getRackList,
				setRackList,
				localEndUsers,
				setLocalEndUsers,
				reports,
				setReports,
				selectedReport,
				setSelectedReport,
				selectedType,
				setSelectedType,
				loadingReports,
				setLoadingReports,
				sourceMaterialList,
				setSourceMaterialList,
				getOwnershipByMaterial,
				totalSelectedPipes,
				setTotalSelectedPipes,
				sourceMaterial,
				setSourceMaterial,
				getRackListByMaterial,
				setAvailablePipes,
				materialsByEndUser,
				setMaterialsByEndUser,
				filteredPipes,
				setFilteredPipes,
				materialsByReport,
				setMaterialsByReport
			}}>
			{children}
		</UsageRightsContext.Provider>
	)
	return renderStore
}
