/** @format */

import { PrepareEntranceContext } from 'apps/wms/stores/PrepareEntranceStore'
import { Button, Card, FlexView, Icon } from 'components/common'
import { Input } from 'components/form'
import Select from '../../../components/prepareEntrance/Select'
import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { consignmentType } from 'apps/wms/utils/enum'
import { useWindowSize } from 'apps/wms/utils/hooks'

const MaterialInfo = () => {
	const { t } = useTranslation()
	const { newMatList, setNewMatList, erpSelected, setStep, suppliers } = useContext(PrepareEntranceContext)

	const [list, setList] = useState(null)

	const listDeadSpace = 85
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		setList(newMatList)
	}, [newMatList])

	const handleInputQty = (value, index) => {
		newMatList[index].quantity = value
		setNewMatList([...newMatList])
	}

	const handleInputCO = (value, index) => {
		newMatList[index].customerorder = value
		setNewMatList([...newMatList])
	}

	const handleInputCI = (value, index) => {
		newMatList[index].customeritem = value
		setNewMatList([...newMatList])
	}

	const handleChangeConsignment = (value, index) => {
		newMatList[index].consignmenttype = value
		setNewMatList([...newMatList])
	}

	const handleInputSO = (value, index) => {
		newMatList[index].salesorder = value
		setNewMatList([...newMatList])
	}

	const handleInputSI = (value, index) => {
		newMatList[index].salesitem = value
		setNewMatList([...newMatList])
	}

	const goToNext = () => {
		setStep(3)
	}

	const goBack = () => {
		setStep(1)
	}

	const checkFields = () => {
		let isDisabled = true
		let hasNoMaterial =
			list === null ||
			(list !== null &&
				_.some(
					list,
					mat =>
						mat.supplier === null ||
						mat.quantity === '' ||
						mat.customerorder === '' ||
						mat.customeritem === '' ||
						mat.consignmenttype === null ||
						(mat.salesorder !== '' && mat.salesitem === '') ||
						(mat.salesitem !== '' && mat.salesorder === '')
				)) ||
			list === undefined
		isDisabled = isDisabled && hasNoMaterial
		return isDisabled
	}

	const checkSucces = material => {
		return material.consignmenttype ? true : false
	}

	const checkError = material => {
		return !material.consignmenttype ? true : false
	}

	const checkInputQtySuccess = material => {
		return material.quantity ? true : false
	}

	const checkInputQtyError = material => {
		return !material.quantity || material.quantity <= 0 ? true : false
	}

	const checkCOSuccess = material => {
		return material.customerorder ? true : false
	}

	const checkCOError = material => {
		return !material.customerorder ? true : false
	}

	const checkCISuccess = material => {
		return material.customeritem ? true : false
	}

	const checkCIError = material => {
		return !material.customeritem ? true : false
	}

	const handleChangeCSupplier = (value, index) => {
		newMatList[index].supplier = value
		setNewMatList([...newMatList])
	}

	return (
		<FlexView width='calc(100% - 48px)' flexDirection='column' height='100%' margin='16px 0 0 0'>
			<Card width='100%' height='100%' data-cy='card-material-info' ref={listRef} style={{ position: 'relative' }}>
				<FlexView width='100%' fontSize='title' fontWeight='bold'>
					{t('wms:MaterialData')}
				</FlexView>
				<FlexView
					flexDirection='column'
					width='100%'
					height={`${listHeight}px`}
					style={{ overflowY: 'auto' }}
					margin='8px 0 0 0'>
					{erpSelected.length > 0 ? (
						_.map(list, (material, index) => (
							<FlexView key={index} flexDirection='column' width='100%'>
								<Card width='calc(100% - 70px)' margin='16px 0 16px 8px' data-cy='card-material'>
									<FlexView
										width='100%'
										fontSize='small'
										fontWeight='bold'
										color='metalic'
										margin='0 0 16px 0'>
										{t('wms:Material')} {index + 1}: {material.formatted}
									</FlexView>
									<FlexView flexDirection='column' width='100%'>
										<FlexView flexDirection='column'>
											<FlexView fontSize='small' fontWeight='bold' color='error'>
												{t('wms:MandatoryFields')}
											</FlexView>
											<FlexView flexDirection='row' width='100%'>
												<Select
													success={material.supplier !== null}
													error={material.supplier === null}
													width='145px'
													label={t('wms:Supplier')}
													placeholder={t('wms:Supplier')}
													value={material.supplier}
													options={_.map(suppliers, sup => {
														return { value: sup.id, label: sup.description }
													})}
													onChange={v => handleChangeCSupplier(v, index)}
													data-cy='select-supplier'
												/>
												<Input
													width='170px'
													success={checkInputQtySuccess(material)}
													error={checkInputQtyError(material)}
													margin='8px 0 0 12px'
													type='number'
													placeholder='0'
													label={t('wms:PipeQuantity')}
													min={material.pipes.length}
													max='999999999'
													value={material.quantity}
													pattern='/^-?[0-9][^\.]*$/'
													onChange={e => handleInputQty(e.target.value, index)}
													data-cy='input-pipe-quantity'
												/>
												<Input
													width='180px'
													success={checkCOSuccess(material)}
													error={checkCOError(material)}
													margin='8px 0 0 12px'
													type='text'
													placeholder={t('wms:CustomerOrder')}
													label={t('wms:CustomerOrder')}
													value={material.customerorder}
													onChange={e => handleInputCO(e.target.value, index)}
													data-cy='input-customer-order'
												/>
												<Input
													width='155px'
													success={checkCISuccess(material)}
													error={checkCIError(material)}
													margin='8px 0 0 12px'
													type='text'
													placeholder={t('wms:CustomerItem')}
													label={t('wms:CustomerItem')}
													value={material.customeritem}
													onChange={e => handleInputCI(e.target.value, index)}
													data-cy='input-customer-item'
												/>
												<Select
													success={checkSucces(material)}
													error={checkError(material)}
													width='190px'
													label={t('wms:ConsignmentType')}
													placeholder={t('wms:ConsignmentType')}
													margin='8px 0 0 12px'
													value={material.consignmenttype}
													options={_.map(consignmentType, consignment => {
														return { value: consignment, label: consignment }
													})}
													onChange={v => handleChangeConsignment(v, index)}
													data-cy='select-consignment-type'
												/>
											</FlexView>
										</FlexView>
										<FlexView
											flexDirection='column'
											width='30%'
											justifyContent='space-between'
											flexWrap='wrap'>
											<FlexView fontSize='small' fontWeight='bold' color='warning'>
												{t('wms:OptionalFields')}
											</FlexView>
											<FlexView flexDirection='row' width='100%'>
												<Input
													width='175px'
													type='text'
													placeholder={t('wms:SalesOrder')}
													label={t('wms:SalesOrder')}
													value={material.salesorder}
													onChange={e => handleInputSO(e.target.value, index)}
													data-cy='input-sales-order'
												/>
												<Input
													width='150px'
													margin='8px 0 0 12px'
													type='text'
													placeholder={t('wms:SalesItem')}
													label={t('wms:SalesItem')}
													value={material.salesitem}
													onChange={e => handleInputSI(e.target.value, index)}
													data-cy='input-sales-item'
												/>
											</FlexView>
										</FlexView>
									</FlexView>
								</Card>
							</FlexView>
						))
					) : (
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='24px'
							color='lightGray'
							margin='auto 0'
							alignItems='center'
							justifyContent='center'
							data-cy='no-material-list'>
							{t('wms:EmptyList')}. {t('wms:AddMaterialsToList')}
						</FlexView>
					)}
					<FlexView flexDirection='row' width='100%' justifyContent='space-between' margin='auto 0 0 auto'>
						<Button
							backgroundColor='primary'
							color='white'
							margin='auto 0 auto 0'
							onClick={() => goBack()}
							data-cy='button-back-step'>
							<Icon name='arrow-left' color='white' width='14px' height='14px' margin='0 8px 0 0' />
							{t('wms:Back')}
						</Button>
						<Button
							backgroundColor='primary'
							color='white'
							margin='auto 0 0 auto'
							onClick={() => goToNext()}
							disabled={checkFields()}
							data-cy='button-next-step'>
							{t('wms:Next')}
							<Icon name='arrow-right' color='white' width='14px' height='14px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
				</FlexView>
			</Card>
		</FlexView>
	)
}

export default MaterialInfo
