/** @format */

import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { REPORT_TYPE } from 'apps/wms/utils/enum'
import { FlexView } from 'components/common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'

const ReportHeader = ({ reportType, reportData, instrumentList, ltpa, zoom }) => {
	const { t, i18n } = useTranslation()

	const rigPrepHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
						{reportData.revision !== 0 ? ` - Rev. ${reportData.revision}` : ''}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:FieldName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.field}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:RigName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.rig}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseitem}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:WellName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.well}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:SalesOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:SalesItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesitem}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:NumberLTPA')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.ltpa}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Material')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.materialdescription}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:TotalJoints')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totaljoints}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, t, i18n]
	)

	//$ctrl.inspectiontype == $ctrl.INSPECTION_TYPE.tpartywork || $ctrl.inspectiontype == $ctrl.INSPECTION_TYPE.matmodif
	const thirdPartyHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
						{reportData.revision !== 0 ? ` - Rev. ${reportData.revision}` : ''}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:ThirdPartyName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.tpartyname}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='55%'>
						{t('wms:ThirdPartyLocation')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.tpartyloc}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='36%'>
						{t('wms:FieldName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.field}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:RigName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.rig}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:WellName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.well}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='55%'>
						{t('wms:ReferenceMR')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.mrref}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseitem}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='55%'>
						{t('wms:NumberLTPA')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.ltpa}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:SalesOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:SalesItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesitem}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Material')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.materialdescription}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='17%'>
						{t('wms:TotalJoints')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totaljoints}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, t, i18n]
	)

	const physicalTransferHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
						{reportData.revision !== 0 ? ` - Rev. ${reportData.revision}` : ''}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='30%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:DeliveryLocation')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.tpartyloc}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='55%'>
						{t('wms:ThirdPartyLocation')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.tpartyloc}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:FieldName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.field}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='30%'>
						{t('wms:RigName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.rig}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:WellName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.well}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='55%'>
						{t('wms:ReferenceMR')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.mrref}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseitem}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='55%'>
						{t('wms:NumberLTPA')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.ltpa}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:SalesOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:SalesItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesitem}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Material')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.materialdescription}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:TotalJoints')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totaljoints}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, t, i18n]
	)

	const receivingInspectionHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
						{reportData.revision !== 0 ? ` - Rev. ${reportData.revision}` : ''}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:ErpReference')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.catid}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseitem}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:SalesOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:SalesItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesitem}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Material')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.materialdescription}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:TotalJoints')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totaljoints}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, t, i18n]
	)

	//User for spot inspection as well
	const periodicalInspectionHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
						{reportData.revision !== 0 ? ` - Rev. ${reportData.revision}` : ''}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseitem}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:SalesOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:SalesItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesitem}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Material')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.materialdescription}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:TotalJoints')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totaljoints}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, t, i18n]
	)

	const rigReturnHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
						{reportData.revision !== 0 ? ` - Rev. ${reportData.revision}` : ''}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:FieldName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.field}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:RigName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.rig}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.copurchaseitem}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:WellName')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.well}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:SalesOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesorder}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:SalesItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.numsalesitem}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:Untraceable')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.ntquantity}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Material')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.materialdescription}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:TotalJoints')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totaljoints}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, t, i18n]
	)

	const modifiedProdcutsInspectionHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
						{reportData.revision !== 0 ? ` - Rev. ${reportData.revision}` : ''}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='10%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:ReferenceMP')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.erref}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:ErpReference')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.catid}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='20%'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.customerpo}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.customeritem}
					</FlexView>
				</FlexView>
				<FlexView width='50%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:TargetMaterial')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.materialdescription}
					</FlexView>
				</FlexView>
				<FlexView width='25%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:TotalJoints')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totaljoints}
					</FlexView>
				</FlexView>
				{reportData.totaluntraceable > 0 && (
					<FlexView width='25%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
							{t('wms:UntraceablePipes')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.totaluntraceable}
						</FlexView>
					</FlexView>
				)}
			</>
		),
		[reportData, t, i18n]
	)

	const ageManagementHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='10%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.extractiondate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='30%'>
						{t('wms:EndUser')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.enduser}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{t('wms:NumberLTPA')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{ltpa}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, t, ltpa, i18n]
	)

	const receiptNoteHeader = useMemo(
		() => (
			<>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='25%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='32%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='25%'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.customerorders}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='32%'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.customeritems}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='25%'>
						{t('wms:SalesOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.salesorders}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='32%'>
						{t('wms:SalesItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.salesitems}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='25%'>
						{t('wms:TotalJoints')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totaljoints}
					</FlexView>
				</FlexView>
				<FlexView width='20%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='60%'>
						{t('wms:TotalLength')} [mm]:
					</FlexView>
					<FlexView
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totallength}
					</FlexView>
				</FlexView>
				<FlexView width='20%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='43%'>
						{t('wms:TotalWeight')} [kg]:
					</FlexView>
					<FlexView
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totalweight}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, i18n, t]
	)

	const defaultHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
						{reportData.revision !== 0 ? ` - Rev. ${reportData.revision}` : ''}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, t, i18n]
	)

	const getReportHeader = () => {
		if (reportType === REPORT_TYPE.code.RIGPREP) return rigPrepHeader
		else if (reportType === REPORT_TYPE.code.TPWORKSHOP || reportType === REPORT_TYPE.code.MATMODIF)
			return thirdPartyHeader
		else if (reportType === REPORT_TYPE.code.PHYSTRANS) return physicalTransferHeader
		else if (reportType === REPORT_TYPE.code.RECINSP) return receivingInspectionHeader
		else if (reportType === REPORT_TYPE.code.PERIODICAL || reportType === REPORT_TYPE.code.SPOT)
			return periodicalInspectionHeader
		else if (reportType === REPORT_TYPE.code.RIGRETURN) return rigReturnHeader
		else if (reportType === REPORT_TYPE.code.MODIF_PRODUCTS) return modifiedProdcutsInspectionHeader
		else if (reportType === REPORT_TYPE.code.AGE_EXTRACTS) return ageManagementHeader
		else if (reportType === REPORT_TYPE.code.MILL_RECEIPT) return receiptNoteHeader
		else return defaultHeader
	}

	const getInstrumentsHeader = () => {
		return instrumentList && Object.values(instrumentList).length > 0 ? (
			<FlexView width='100%' flexDirection='row' fontSize='small' margin='0'>
				<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='7%'>
					{t('wms:Instruments')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{instrumentList.map(inst => (
						<FlexView key={inst.id} width='100%' flexDirection='row' color='dark-gray' fontSize='small'>
							{`${t('wms:CaseId')}: ${inst.id} - `}
							<FlexView color='dark-gray' fontSize='small' margin='0 0 0 3px'>
								{inst.list.map(instCase => `${instCase.reference} [${instCase.type}], `)}
							</FlexView>
						</FlexView>
					))}
				</FlexView>
			</FlexView>
		) : null
	}

	return (
		<FlexView
			style={{ zoom: zoom }}
			flexDirection='row'
			flexWrap='wrap'
			width='100%'
			fontSize='small'
			margin='0 0 24px 0'>
			{getReportHeader()}
			{getInstrumentsHeader()}
		</FlexView>
	)
}

export default ReportHeader
