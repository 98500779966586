import axios from 'axios'


const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/status`,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default {
  getReports: (startDate, endDate, interval, token) => new Promise((resolve, reject) => {
    instance.post(
      '/report',
      {
        from: startDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
        to: endDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
        interval
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
      else {
        reject(new Error('CouldNotConnect'))
      }
    }).catch(err =>  {
      reject(err.response || err)
    })
  }),
  getAppStatus: (startDate, endDate, interval, app, token) => new Promise((resolve, reject) => {
    instance.post(
      `/reportprocess/${app}`,
      {
        from: startDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
        to: endDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
        interval
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
      else {
        reject(new Error('CouldNotConnect'))
      }
    }).catch(err =>  {
      reject(err.response || err)
    })
  }),
  executeCommand: (url, token) => new Promise((resolve, reject) => {
    instance.post(
      `/${url}`,
      null,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve()
      }
      else {
        reject(new Error('CouldNotConnect'))
      }
    }).catch(err =>  {
      reject(err.response || err)
    })
  })
}